import { Link } from "react-router-dom";

export default function FreelanceCard3() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 items-center border-[4px] rounded-[8px] px-4 py-6 my-8">
      <div className="col-span-2 font-medium">
        <h1 className="text-[32px]">Vous souhaitez externaliser votre tenue comptable et la gestion de votre société ?</h1>
        <p className="text-[16px] font-medium mt-4">MyFinPlace vous accompagne à l’année dans la gestion de votre société (comptable, administrative, juridique, fiscale et sociale).</p>
        <Link to="/RendezVous" className="bg-[#5B9ECF] hover:bg-[#2D77AD] text-white py-2 px-4 rounded-[5px] inline-block mt-6">Je souhaite confier la comptabilité de ma société</Link>
      </div>
      <div className="mt-8 md:mt-0 text-center md:text-right">
        <h1 className="text-[#5B9ECF] text-[48px] my-auto">1 920€ <span className="text-[36px]">HT</span> /an</h1>
      </div>
    </div>
  )
}