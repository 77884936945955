
import { React } from 'react'

function CandidatureSvg(props) {


    return (
        <>
            <svg width="11" height="15" viewBox="0 0 11 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.41667 3.98438V0H0.6875C0.30651 0 0 0.313477 0 0.703125V14.2969C0 14.6865 0.30651 15 0.6875 15H10.3125C10.6935 15 11 14.6865 11 14.2969V4.6875H7.10417C6.72604 4.6875 6.41667 4.37109 6.41667 3.98438ZM8.25 10.8984C8.25 11.0918 8.09531 11.25 7.90625 11.25H3.09375C2.90469 11.25 2.75 11.0918 2.75 10.8984V10.6641C2.75 10.4707 2.90469 10.3125 3.09375 10.3125H7.90625C8.09531 10.3125 8.25 10.4707 8.25 10.6641V10.8984ZM8.25 9.02344C8.25 9.2168 8.09531 9.375 7.90625 9.375H3.09375C2.90469 9.375 2.75 9.2168 2.75 9.02344V8.78906C2.75 8.5957 2.90469 8.4375 3.09375 8.4375H7.90625C8.09531 8.4375 8.25 8.5957 8.25 8.78906V9.02344ZM8.25 6.91406V7.14844C8.25 7.3418 8.09531 7.5 7.90625 7.5H3.09375C2.90469 7.5 2.75 7.3418 2.75 7.14844V6.91406C2.75 6.7207 2.90469 6.5625 3.09375 6.5625H7.90625C8.09531 6.5625 8.25 6.7207 8.25 6.91406ZM11 3.57129V3.75H7.33333V0H7.50807C7.69141 0 7.86615 0.0732422 7.99505 0.205078L10.7995 3.07617C10.9284 3.20801 11 3.38672 11 3.57129Z" fill="currentColor" />
            </svg>

        </>
    )
}

export default CandidatureSvg