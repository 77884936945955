import { GoClockFill } from "react-icons/go";
import { FaFile } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaHandshakeSimple } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

export default function AppointmentAccept(props) {
  const appointment = props.data;
  const navigate = useNavigate()

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}h${formattedMinutes}`;
  };

  return (
    <>
      <button onClick={() => navigate("/mon-espace/mes-entretiens")} className="block text-[#2563EB] font-extrabold hover:underline hover:cursor-pointer">{"<"} Retour</button>
      <span className='text-[#00C455] bg-[#DCFCE6] inline-block p-2 rounded-full my-2'>
        <FaHandshakeSimple size={28} />
      </span>
      {appointment.id_user &&
        <p className="font-medium">{appointment.id_user.roles[0]} retenu le {appointment.apply && " suite à votre candidature"}</p>
      }
      {appointment.id_client &&
        <p className="font-medium">{appointment.id_client.company} vous a retenu</p>
      }
      {appointment &&
        <p className="text-2xl font-extrabold mb-4">{new Date(appointment.date_choice).toLocaleDateString("fr-FR", {
          weekday: "long",
          day: "2-digit",
          month: "long",
          year: "numeric",
        })} à {formatTime(new Date(appointment.date_choice))}</p>
      }
      {appointment.offre &&
        <div className="flex gap-2 text-[#B7B7B7] items-center">
          <FaFile />
          <p className="font-medium w-[120px]">Poste à pouvoir</p>
          <a className="text-black font-medium flex items-center gap-2 underline" href={`/les-opportunites/${appointment.offre.id}`} target="_blank">{appointment.offre.intitule}
            <FaExternalLinkAlt />
          </a>
        </div>
      }
      <div className="flex gap-2 text-[#B7B7B7] items-center">
        {appointment.id_client &&
          <>
            <FaBuilding />
            <p className="font-medium w-[120px]">Entreprise</p>
            <p className="text-[#0F6FDE] font-medium">{appointment.id_client.company}</p>
          </>
        }
        {appointment.id_user &&
          <>
            <FaUser />
            <p className="font-medium w-[120px]">Profil</p>
            <a className="text-[#0F6FDE] font-medium flex items-center gap-2 underline" href={`/dossier-de-competences/${appointment.id_user.id}`} target="_blank">{appointment.id_user.first_name + " " + appointment.id_user.last_name}
              <FaExternalLinkAlt />
            </a>
          </>
        }
      </div>
    </>
  )
}