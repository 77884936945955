import { React } from 'react'
import Entretien from './assets/Entretien/Entretien'
import Kpi from './assets/Kpi/Kpi'
import PrimaryButton from 'Components/Buttons/PrimaryButton'
import { useDispatch,useSelector } from "react-redux";

function AppDashboard() {

  const dispatch = useDispatch()
  return (
    <>
      <div className='flex flex-col p-8 mb-8'>
        <div>
          <div>
            <h1>Tableau de bord</h1>
          </div>
        </div>
        <div>
          <Entretien />
          {/* <PrimaryButton text={"cedric"} onClick={() => dispatch({type:'socket/myEmit',payload:"zerzerzerzer"})}/> */}

        </div>
        {/* <div>
            <Kpi/>
            </div> */}
      </div>
    </>
  )
}

export default AppDashboard