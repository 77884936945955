import { useEffect, useContext } from 'react';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import SmallPrimaryButton from 'Components/Buttons/SmallPrimaryButton';
import { format } from "date-fns";
import { fr } from 'date-fns/locale'

import { IoTrashOutline } from "react-icons/io5";
import { IoPencil } from "react-icons/io5";
import { FaBuilding } from "react-icons/fa";


import { AuthContext } from "Context/AuthProvider";
import { ModalContext } from 'Context/ModalProvider';

import Cookies from "js-cookie"
import axios from "axios";

import DeleteExperienceModal from 'Modals/Experience/DeleteExperienceModal';
import CreateExperienceModal from 'Modals/Experience/CreateExperienceModal';



function PersonalExperiences() {

  const params = useParams();
  const navigate = useNavigate();
  const { openModal, close } = useContext(ModalContext);

  const [userInformation, setUserInformation, filters, getProfile, pourcentage, getPourcentage] = useOutletContext();

  const handleEdit = (id) => {
    navigate(`/mon-espace/mon-profil/experiences/edit/${id}`);
  }

  const handleDelete = (id) => {
    navigate(`/mon-espace/mon-profil/experiences/delete/${id}`);
  }

  const handleCreate = (id) => {
    navigate(`/mon-espace/mon-profil/experiences/create`);
  }

  const openModalHandler = (exp = null) => {
    openModal(exp ? "Modifier mon expérience" : " Ajouter une éxperience",
      <CreateExperienceModal
        experience={exp}
        setUserInformation={setUserInformation}
        close={close}
        getPourcentage={getPourcentage}
      />
    )
  }

  const deleteHandler = (id) => {
    openModal("Supprimer une experience",
      <DeleteExperienceModal
        id={id}
        close={close}
        setUserInformation={setUserInformation}
        getPourcentage={getPourcentage}
      />
    )
  }


  return (
    <div className=''>
      <div className='mb-4 p-4 flex justify-end items-center'>
        {/* <h2>Experiences</h2> */}
        <SmallPrimaryButton text={"Ajouter une éxperience"} onClick={() => openModalHandler()} />
      </div>
      <div className='p-4 bg-white border'>
        <div class="overflow-auto">
          <table class="table-auto min-w-full">
            <thead className='border-b'>
              <tr class="">
                <th class="px-4 py-2 text-left">Entreprise</th>
                <th class="px-4 py-2 text-left">Poste</th>
                <th class="px-4 py-2 text-left">Date de debut</th>
                <th class="px-4 py-2 text-left">Date de fin</th>
                <th class="px-4 py-2 text-left"></th>
              </tr>
            </thead>
            <tbody>
              {
                userInformation?.experience.map(exp => (
                  <tr className='rounded border' key={exp.id}>
                    <td class="px-6 py-4 font-bold"><div className='flex gap-2 items-center'><span className='p-2 bg-[#DCEDF8] rounded'><FaBuilding /></span>{exp.name}</div></td>
                    <td class="px-6 py-4 font-bold">{exp.poste}</td>
                    <td class="px-6 py-4">{format(Date.parse(exp.date_start), "LLLL yyyy", { locale: fr })}</td>
                    <td class="px-6 py-4">{exp.in_progress ? <p className='bg-[#F7F9FC] p-1 rounded border-[#636363] text-center text-[#636363] text-sm font-medium'>En cours</p> : format(Date.parse(exp.date_end), "LLLL yyyy", { locale: fr })}</td>
                    <td class="px-6 py-6 flex gap-2 items-center"><span className='text-red-500 cursor-pointer bg-[#F7F9FC]  hover:bg-[#DCEDF8] border p-1 rounded' onClick={() => deleteHandler(exp.id)}><IoTrashOutline /></span> <span className='text-[#f97316] cursor-pointer bg-[#F7F9FC]  hover:bg-[#DCEDF8] border p-1 rounded' onClick={() => openModalHandler(exp)}><IoPencil /></span></td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default PersonalExperiences