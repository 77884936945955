import React from 'react'

function Vector(props) {
    return (
        <svg className={props.classProps ? props.classProps : ""} 
        width={props.width ? props.width : "24"} height={props.height ? props.height : "26"} viewBox="0 0 24 26" 
        fill="none" xmlns="http://www.w3.org/2000/svg">

            <path fillRule="evenodd" clipRule="evenodd" d="M12 24.0005C10.4241 24.0005 8.86373 23.6901 7.40773 23.087C5.95187 22.484 4.62907 21.6001 3.51467 20.4858C2.4004 19.3714 1.51653 18.0486 0.913467 16.5928C0.3104 15.1368 0 13.5764 0 12.0005C0 10.4246 0.3104 8.86419 0.913467 7.40829C1.51653 5.95238 2.4004 4.62952 3.51467 3.51521C4.62907 2.4009 5.95187 1.517 7.40773 0.913928C8.86373 0.310875 10.4241 0.000488281 12 0.000488281C15.1826 0.000488281 18.2348 1.26477 20.4853 3.51521C22.7357 5.76565 24 8.81789 24 12.0005C24 15.183 22.7357 18.2353 20.4853 20.4858C18.2348 22.7362 15.1826 24.0005 12 24.0005ZM15.0573 9.72449C15.1803 9.59716 15.3275 9.49555 15.4901 9.42569C15.6528 9.35582 15.8278 9.31902 16.0048 9.31749C16.1818 9.31596 16.3574 9.34969 16.5213 9.41675C16.6851 9.48382 16.834 9.58276 16.9592 9.70795C17.0844 9.83315 17.1834 9.98196 17.2504 10.1458C17.3175 10.3097 17.3512 10.4853 17.3497 10.6624C17.3481 10.8394 17.3113 11.0144 17.2415 11.177C17.1716 11.3397 17.07 11.4869 16.9427 11.6098L12.9427 15.6098C12.6927 15.8598 12.3536 16.0002 12 16.0002C11.6464 16.0002 11.3073 15.8598 11.0573 15.6098L7.05733 11.6098C6.8144 11.3584 6.68 11.0216 6.68307 10.672C6.68613 10.3224 6.8264 9.98796 7.0736 9.74076C7.3208 9.49356 7.6552 9.35329 8.0048 9.35022C8.3544 9.34715 8.6912 9.48156 8.94267 9.72449L12 12.7818L15.0573 9.72449Z" 
            fill={props.color ? props.color : "#5B9ECF"}
             />
        </svg>
    )
}

export default Vector

