//Dépendances
import React, { useState, useEffect } from 'react';
import { useRoomConnection, useLocalMedia } from "@whereby.com/browser-sdk/react";
import axios from "axios";
import Cookies from "js-cookie"
//Components
import Buttons from './Components/Buttons';
import ChatDisplay from './Components/ChatDisplay';
import VideoDisplay from './Components/VideoDiplay';
import Participants from './Components/Participants';
import Ressources from './Components/Ressources';
import Logo from "assets/img/Logo1.png";
import { IoOptions } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";

function GetRoomData(props) {
  const [isLocalScreenActive, setIsLocalScreenshareActive] = useState(false);
  const token = Cookies.get("token");
  const [visibleResponsive, setVisibleResponsive] = useState(false)

  const localMedia = useLocalMedia({ audio: true, video: true });
  const { state, actions, components } = useRoomConnection(props.data.url, {
    localMediaOptions: {
      audio: true,
      video: localMedia.state.currentCameraDeviceId ? true : false
    },
    displayName: props.data.displayName
  });
  const { remoteParticipants, screenshares, localParticipant } = state;

  if (localMedia.state.currentCameraDeviceId === undefined && localParticipant !== undefined) {
    localParticipant.isVideoEnabled = false;
  }

  function PatchAppointementRealiser() {
    axios.patch(
      process.env.REACT_APP_API + `entretien/realiser/${props.id}`, {},
      {
        headers: {
          "Content-Type": "application/merge-patch+json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
      })
      .catch((error) => {
      });
  }

  useEffect(() => {
    const partner = remoteParticipants.filter((x) => x.displayName.startsWith("remote"))
    const timeoutId = setTimeout(() => {
      if (partner.length > 0) {
        PatchAppointementRealiser();
      } else {
        // Aucune action afin de supprimer la clock
      }
    }, 5000 * 60); // 5 minutes

    // A chaque modification de remoteParticipants, on reset la clock, cette dernière à 2 états
    // 1 - Si le partner est là, on appel la function pour mettre l'entretien en "réalisé"
    // 2 - Aucune action, cela permet d'annuler la première function si elle était active
    return () => {
      clearTimeout(timeoutId);
    };
  }, [remoteParticipants])

  useEffect(() => {
    setIsLocalScreenshareActive(false);
    if (screenshares !== null) {
      screenshares.map((s) => (
        s.isLocal && (setIsLocalScreenshareActive(true))
      ))
    }
  }, [screenshares]);

  return (
    <div className='h-[100vh] lg:grid lg:grid-cols-5 bg-[#F7F9FC]'>
      <div className='flex flex-col col-span-4'>
        <img className="w-20 lg:w-20 m-8" src={Logo} alt="MyFinPlace.com" />
        <VideoDisplay components={components} state={state} localMedia={localMedia} />
      </div>
      <div className='inline-block lg:hidden fixed top-6 right-6 hover:cursor-pointer' onClick={() => setVisibleResponsive(true)}>
        <IoOptions size={30} className='text-[#5A9ECF]' />
      </div>
      <div className={`lg:inline-block z-10 ${visibleResponsive ? "fixed right-0 top-0" : "hidden"}`}>
        <div className="flex flex-col gap-6 bg-white px-2 py-4">
          <RxCross1 size={20} className='text-red-500 inline-block cursor-pointer lg:hidden w-fit ml-auto' onClick={() => setVisibleResponsive(false)} />
          <Participants state={state} />
          <Ressources idDossierCompetence={props.data.idDossierCompetence} idOffre={props.data.idOffre} />
          <ChatDisplay state={state} actions={actions} />
        </div>
      </div>
      <Buttons actions={actions} state={state} isLocalScreenActive={isLocalScreenActive} idDossierCompetence={props.data.idDossierCompetence} localParticipant={localParticipant} localMedia={localMedia} />
    </div >
  )
}

export default GetRoomData;