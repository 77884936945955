import React, { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import { useCreatePath } from "react-admin";
import axios from "axios";
import cookie from "js-cookie";
import { add } from "date-fns";
import SecondaryButton from "Components/Buttons/SecondaryButton";
import PrimaryButton from "Components/Buttons/PrimaryButton";
import { ModalContext } from 'Context/ModalProvider';
import { IoMailSharp } from "react-icons/io5";
import { LuLoader2 } from "react-icons/lu";

const formatTime = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}h${formattedMinutes}`;
};

function Popup({ clientName, profilName }) {
  const { close } = useContext(ModalContext);

  return (
    <div>
      Envoyé le mail à <span className="text-[#5B9ECF]">{clientName}</span> et <span className="text-[#5B9ECF]">{profilName}</span> ?
      <div className='flex flex-col md:flex-row gap-2 justify-end mt-4'>
        <SecondaryButton text={"Annuler"} onClick={close} />
        <PrimaryButton text={"Envoyer le mail (EN ATTENTE DU TEXTE)"} />
      </div>
    </div>
  )
}

function EntretiensNonRealises() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { openModal } = useContext(ModalContext);

  const createPath = useCreatePath();
  const CreateLink = (resource, id, text) => {
    return <Link to={createPath({ resource: resource, type: 'show', id: id })} className="text-[#0091FF] hover:underline">{text}</Link>
  };

  useEffect(() => {
    setIsLoading(true);
    axios.get(process.env.REACT_APP_API + `GetStatusEntretien?status=programmés`, {
      headers: {
        Authorization: `Bearer ${cookie.get("token")}`
      }
    })
      .then((response) => {
        setData(response.data["hydra:member"].reverse().slice(0).filter(item => add(new Date(item.date_choice), { hours: 2 }) < new Date()));
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  const MailEntretienNonRealiser = (clientName, profilName) => {
    openModal("Mail de relance", <Popup clientName={clientName} profilName={profilName} />)
  }

  return (
    <>
      <h3 className="text-center mb-4">Entretien non réalisés</h3>
      {isLoading ?
        <div className="min-h-[calc(100vh-170px)] flex justify-center items-center">
          <div className="text-[#2563EB]">
            <LuLoader2 size={48} className='animate-spin' />
          </div>
        </div>
        :
        data.length === 0 ?
          <p>Aucun entretiens programmés</p>
          :
          data.map((e, key) => (
            <div key={key} className="border border-[#D6D3D3] rounded-[25px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] mb-[20px]">
              <div className="text-center bg-[#3D88F8] text-white py-2 rounded-t-[25px] text-[20px]">
                <b>{new Date(e.date_choice).toLocaleDateString("fr-FR", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                })} à {formatTime(new Date(e.date_choice))}</b>
              </div>
              <div className="flex flex-row">
                <div className="w-1/5 flex justify-center items-center gap-4">
                  <div className="text-center flex items-center gap-2 text-[#38a9b4]" onClick={() => MailEntretienNonRealiser(e.id_client.first_name + " " + e.id_client.last_name, e.id_user.first_name + " " + e.id_user.last_name)}>
                    <IoMailSharp size={18} />
                    <span>Envoyer mail de relance</span>
                  </div>
                </div>
                <span className="border border-[#D6D3D3]"></span>
                <div className="w-2/5 flex flex-col py-2 items-center">
                  <div>
                    <div className="grid grid-cols-2 gap-x-4">
                      <p><b>Client ID :</b> {CreateLink('clients', e.id_client["@id"], e.id_client.id)}</p>
                      <p>{e.id_client.first_name} {e.id_client.last_name}</p>
                      <p>{e.id_client.roles[0]}</p>
                      <p>{e.id_client.mobile}</p>
                    </div>
                    <p>{e.id_client.email}</p>
                  </div>
                </div>
                <span className="border border-[#D6D3D3]"></span>
                <div className="w-2/5 flex flex-col py-2 items-center">
                  <div>
                    <div className="grid grid-cols-2 gap-x-4">
                      <p><b>Profil ID :</b> {CreateLink('users', e.id_user["@id"], e.id_user.anonymous)}</p>
                      <p>{e.id_user.first_name} {e.id_user.last_name}</p>
                      <p>{e.id_user.roles[0]}</p>
                      <p>{e.id_user.mobile}</p>
                    </div>
                    <p>{e.id_user.email}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
    </>
  );
}

export default EntretiensNonRealises;