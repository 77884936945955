import React, { useState, useEffect, useContext } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { AuthContext } from "../../../Context/AuthProvider";
import Cookies from 'js-cookie';

export default function RegistrationProcess() {

    const navigate = useNavigate()
    const { value } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true)

    const checkConnexion = () => {
        if (!Cookies.get("token")) {
            navigate('/Connexion')
        } else {
            setIsLoading(false)
        }
    }

    const [data, setData] = useState({
        titre: "",
        department: "",
        timeExperience: "",
        tags: [],
        idgrade: [],
        localisation: [],
        disponibility: "",
        availabilityDate: "",
        workExperience: [],
        jtm: "",
        salaire: "",
        tauxHoraire: "",
        visibilite: true,
        remote: "",
        workTime: [],
        daysWork: [],
        expertComptable: null,
        inscritOrdre: null,
        ordreRegional: null,
        commissaireCompte: null,
        inscritCNCC: null,
        compagnieRattache: null,
        supra: null
    })

    const [experiences, setExperiences] = useState([])
    const [formations, setFormations] = useState([])

    useEffect(() => {
        checkConnexion()
    }, [])

    return (
        <div className='flex min-h-[calc(100vh-10rem)]'>
            <div className='flex flex-1 containerxl justify-center items-center'>
                {
                    !isLoading ?
                        <Outlet context={[data, setData, formations, setFormations, experiences, setExperiences]} />
                        :
                        null
                }
            </div>
        </div>
    )
}
