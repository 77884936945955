import React, { Component } from 'react'
import ImgProfile1 from '../../../../assets/img/Accueil-Experience-1.png'
import ImgProfile2 from '../../../../assets/img/Accueil-Experience-2.png'
import ImgProfile3 from '../../../../assets/img/Accueil-Experience-3.png'
import Quote from 'assets/svg/Quote'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function Review() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <section className="containerxl text-center">
            <h2 className="pb-8 lg:w-[80%] m-[auto] inline-block">
                Retours d'expérience</h2>
            <h4 className="md:m-[auto] inline-block">
                Nos utilisateurs partagent des retours positifs sur les expériences qu’ils vivent au travers de MyFinPlace.
            </h4>
            <Slider {...settings}>
                {/* ***************** 1er silde ***************** */}
                <div>
                    <div className="flex mx-8 gap-x-10 mt-24 mb-[90px] justify-center">
                        <div>
                            <img src={ImgProfile1} className="w-[258px] h-[258px] rounded-[10px]" alt='Profile' />
                        </div>
                        <div className="">
                            <div className='flex sm:border-l-2 border-[#263238] pl-10 h-[258px]'>
                                <Quote className='' />
                                <p className="w-[305px] py-11 text-justify recursive">
                                    Le secteur de l’immobilier connaît une très forte tension RH pour
                                    recruter des talents dédiés à nos métiers. Cette plateforme me permet
                                    efficacement de sélectionner nos futurs collaborateurs et de leur proposer un projet adapté.
                                </p>
                                <div className='place-self-end rotate-180'>
                                    <Quote />
                                </div>
                            </div>
                            <div className='text-left pl-20'>
                                <p className="mb-3 font-medium	">Directrice des ressources humaines</p>
                                <p className="font-medium">Immobilier</p>
                            </div>
                        </div>
                    </div>

                </div>
                {/* ******************** 2eme slide ******************** */}
                <div>
                    <div className="flex mx-8 gap-x-10 mt-24 justify-center">
                        <div>
                            <img src={ImgProfile2} className="w-[258px] h-[258px] rounded-[10px]" alt='Profile' />
                        </div>
                        <div className="gap-x-10">
                            <div className='flex sm:border-l-2 border-[#263238] pl-10 h-[258px]'>
                                <Quote className='' />
                                <p className="w-[305px] pb-11 pt-6 text-justify recursive">
                                    Dans un marché très concurrentiel, les profils financiers sont extrêmement
                                    sollicités. MyFinPlace est un véritable outil permettant d’avoir recours à des
                                    compétences reconnues en finance. Nous utilisons dorénavant les services de cette
                                    plateforme pour renforcer nos équipes.
                                </p>
                                <div className='place-self-end rotate-180'>
                                    <Quote />
                                </div>
                            </div>
                            <div className='text-left pl-20'>
                                <p className="mb-3 font-medium">Président directeur général</p>
                                <p className="font-medium">Services</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ****************** 3eme slide ***************** */}
                <div>
                    <div className="flex mx-8 gap-x-10 mt-24 justify-center">
                        <div>
                            <img src={ImgProfile3} className="w-[258px] h-[258px] rounded-[10px]" alt='Profile' />
                        </div>
                        <div className="gap-x-10">
                            <div className='flex sm:border-l-2 border-[#263238] pl-10 h-[258px]'>
                                <Quote className='' />
                                <p className="w-[305px] py-11 text-justify recursive">
                                    MyFinPlace est un outil parfaitement adapté à nos process RH qui permet
                                    d’identifier et trouver nos futurs collaborateurs/consultants. Les profils
                                    sont de qualité et la mise en relation est très simple, ce qui n’est pas négligeable
                                    en période d’arrêté.
                                </p>
                                <div className='place-self-end rotate-180'>
                                <Quote />
                                </div>
                            </div>
                            <div className='text-left pl-20'>
                                <p className="mb-3 font-medium">Directeur comptable</p>
                                <p className="font-medium">Banque</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </section>
    )
}