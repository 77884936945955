import { useContext, useState, useEffect, useRef } from 'react'
import Location from "assets/svg/Location";
import axios from 'axios';
import Cookies from "js-cookie"
import Mobile from "assets/svg/Mobile";
import LikeButton from "Pages/Statics/DossierCompetences/Component/LikeButton";
import FavoriteButton from "Pages/Statics/DossierCompetences/Component/FavoriteButton";
import { AlertMessageContext } from 'Context/AlertMessageProvider';
import { GoKebabHorizontal } from "react-icons/go";
import { FaTrash } from "react-icons/fa";
import { FaMagnifyingGlass } from "react-icons/fa6";
import ContextMenu from 'Components/ContextMenu/ContextMenu';
import ContextItem from 'Components/ContextMenu/ContextItem';
import AvaibilityBadge from 'Components/AvaibilityBadge/AvaibilityBadge';
import  ProfilNoPicture from "assets/img/profilnodata.png";


export default function Card(props) {
  const user = props.user;
  const token = Cookies.get("token");
  const { alert } = useContext(AlertMessageContext);
  const [kebabMenuOpen, setKebabMenuOpen] = useState(false);

  const disponibilityFormatter = (e) => {
    switch (e.disponibility) {
      case "immédiate":
        return (
          <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
            <div className="rounded-full bg-[#00B553] h-2 w-2 mr-2"></div>
            <p className="text-xs">{e.disponibility}</p>
          </span>
        );
      case "Immédiate":
        return (
          <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
            <div className="rounded-full bg-[#00B553] h-2 w-2 mr-2"></div>
            <p className="text-xs">{e.disponibility}</p>
          </span>
        );
      case "Préavis de 3 mois":
        return (
          <span className='inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10'>
            <div className="rounded-full bg-[#D80027] h-2 w-2 mr-2"></div>
            <p className="text-xs">{e.disponibility}</p>
          </span>
        );
      case "Préavis de 2 mois":
        return (
          <span className='inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10'>
            <div className="rounded-full bg-[#D80027] h-2 w-2 mr-2"></div>
            <p className="text-xs">{e.disponibility}</p>
          </span>
        );
      case "Préavis de 1 mois":
        return (
          <span className='inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10'>
            <div className="rounded-full bg-[#D80027] h-2 w-2 mr-2"></div>
            <p className="text-xs">{e.disponibility}</p>
          </span>
        );
      default:
        let date = "";
        if (e.disponibility === "Date connue") {
          if (e.availability_date !== null) {
            //La date connu est dans le passé, il est maintenant disponible
            if (new Date(e.availability_date) < new Date()) {
              return (
                <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
                  <div className="rounded-full bg-[#00B553] h-2 w-2 mr-2"></div>
                  <p className="text-xs">Immédiate</p>
                </span>

              );
            } else {
              //La date connu est dans le future, il faut affiché la date
              date = new Date(e.availability_date)
                .toLocaleDateString("fr-FR", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                });
              return (
                <span className='inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20'>
                  <div className="rounded-full bg-[#FFD600] h-2 w-2 mr-2"></div>
                  <p className="text-xs">{date}</p>
                </span>

              );
            }
          } else {
            //La valeur de la date connu est null
            return (
              <>
                <div className="rounded-full bg-[#FFD600] h-2 w-2 mr-2"></div>
                <p className="text-xs">Date non indiqué</p>
              </>
            );
          }
        }
        return (
          <>
            <div className="rounded-full bg-[#FFD600] h-2 w-2 mr-2"></div>
            <p className="text-xs">{e.disponibility}</p>
          </>
        );
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setKebabMenuOpen(false);
    }
  };

  const menuRef = useRef(null);

  function openProfil() {
    // Passer la candidature en "en cours"
    if (props.postuler) {
      axios.patch(process.env.REACT_APP_API + `postuler/encours/${props.postuler.id}`, {},
        {
          headers: {
            "Content-Type": "application/merge-patch+json",
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((res) => { })
        .catch((error) => { })
      // Ouvrir le dossier de compétence
      window.open(`/dossier-de-competences/${user.id}${props.idOffre}`, '_blank')
    } else {
      window.open(`/dossier-de-competences/${user.id}`, '_blank')
    }
  }

  function deleteFavoris() {
    axios.delete(
      process.env.REACT_APP_API + `client/favoris/delete/${props.favoris.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        props.fetchProfil();
      })
      .catch((error) => {
      });
  }

  function deleteRecommendation() {
    axios.delete(
      process.env.REACT_APP_API + `client/recommendation/delete/${props.favoris.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        props.fetchProfil()
      })
      .catch((error) => {
      });
  }

  return (
    <>
      <section className="bg-white p-2 rounded border border-white hover:border-[#cbd5e1] hover:shadow-md md:w-[350px] ">
        <div className='flex gap-2 justify-between items-start'>
          <div className='flex gap-2'>
            <img
              className={` object-cover h-[75px] w-[75px] rounded-full self-center mb-1`}
              src={
                user.confidentiel ?
                  {ProfilNoPicture}
                  :
                  user.fileUrl ? user.fileUrl : ProfilNoPicture}
            />
            <div className='flex flex-col items-start gap-1'>
              <p className="font-bold">{user.titre}</p>
              <p><AvaibilityBadge avaibility={user}/></p>
              <p className="text-sm">{user.department}</p>
              <div className="flex flex-wrap gap-2.5">
                {user.work_experience.length > 0
                  ? user.work_experience.map((experience, key) => {
                    return (
                      <small
                        key={key}
                        className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
                      >
                        {experience}
                      </small>
                    );
                  })
                  : ""}
              </div>
            </div>

          </div>
          <ContextMenu>
            <ContextItem text={"Voir le profil"} icon={<FaMagnifyingGlass />} callback={openProfil} />
            {
              props.redButton === "favoris" ?
                <ContextItem text={"Retirer des favoris"} icon={<FaTrash />} callback={deleteFavoris} style={"text-red-500"} />
                :
                <ContextItem text={"Retirer des recommendation"} icon={<FaTrash />} callback={deleteRecommendation} style={"text-red-500"} />

            }
          </ContextMenu>
        </div>
        {/* <div className="flex flex-col  gap-4">
          <div className="flex flex-col justify-start bg-greylight p-2 rounded">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <LikeButton id={user.id} />
                <FavoriteButton id={user.id} />
              </div>
              {user.date_time_creation.date > new Date() ? (
                <p className="bg-darkerblue text-white text-[11px] rounded-[10px] px-[29px] py-[3px]">
                  New
                </p>
              ) : (
                ""
              )}
              {props.filteredAppointment && props.filteredAppointment.length > 0 &&
                <div className="bg-darkerblue text-white text-[11px] rounded-[10px] px-4 py-[3px]">
                  Entretien {props.filteredAppointment[0].status}
                </div>
              }
            </div>
            <img
              className={` object-cover h-[110px] w-[110px] rounded-full self-center mb-1`}
              src={
                user.confidentiel ?
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQg2UYZW2xYYydvt-77cKTzCc8Sf-xSyL3_1Afnozh1gObPXgNk"
                  :
                  user.fileUrl ? user.fileUrl : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQg2UYZW2xYYydvt-77cKTzCc8Sf-xSyL3_1Afnozh1gObPXgNk"}
            />
          </div>
          <div className="flex flex-col items-start gap-2">
            {user.idgrade.slice(0, 1).map((grade) => (<small key={grade.id} className="bg-[#d4e4f9] p-1 rounded">{grade.grade_type}</small>))}
            <p className="font-bold">{user.titre}</p>
            <p className="text-[11px]">{user.time_experience}</p>
            <div className="flex flex-wrap gap-2.5">
              {user.work_experience.length > 0
                ? user.work_experience.map((experience, key) => {
                  return (
                    <small
                      key={key}
                      className="bg-greylight rounded py-[5.5px] px-3.5"
                    >
                      {experience}
                    </small>
                  );
                })
                : ""}
            </div>
            <div className="flex items-center">
              {
                disponibilityFormatter(user)
              }
            </div>
            <div className="flex gap-4">
              {user.mobility ? (
                <div className="flex items-start">
                  <Mobile />
                  <p className="text-[11px] leading-3 pl-1.5">Mobile</p>
                </div>
              ) : (
                ""
              )}
              <small className="flex items-center gap-1 underline decoration-dotted"><Location /> {user.department}</small>
            </div>

          </div>
        </div>
        <div className="flex flex-col gap-2 justify-self-end">
          <div onClick={clickOnProfil} target="_blank" >
            <p className="border border-[#5B9ECF] text-center rounded p-2 text-[#5B9ECF] cursor-pointer hover:text-white hover:bg-[#5B9ECF]">voir profil</p>
          </div>
          {props.redButton === "postuler" &&
            <div onClick={refuserProfil} target="_blank" >
              <p className="border border-[#FF0000] text-center rounded p-2 text-[#FF0000] cursor-pointer hover:text-white hover:bg-[#FF0000]">Refuser le profil</p>
            </div>
          }
          {props.redButton === "favoris" &&
            <div onClick={deleteFavoris} target="_blank" >
              <p className="border border-[#FF0000] text-center rounded p-2 text-[#FF0000] cursor-pointer hover:text-white hover:bg-[#FF0000]">Supprimer des favoris</p>
            </div>
          }
          {props.redButton === "recommendation" &&
            <div onClick={deleteRecommendation} target="_blank" >
              <p className="border border-[#FF0000] text-center rounded p-2 text-[#FF0000] cursor-pointer hover:text-white hover:bg-[#FF0000]">Supprimer des recommendations</p>
            </div>
          }
        </div> */}
      </section>
    </>
  )
}