import { FaCheckCircle } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";
import tarif_ecole from "assets/img/tarif_ecole.png";
import { Link } from "react-router-dom";

export default function Ecole() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <div className="flex flex-col border rounded-[8px] border-[#2632384D] border-[4px] p-2">
      <p className="text-right">Le HUB</p>
      <h1 className="font-medium text-[40px] text-center mt-6 mb-8">Ecole</h1>
      <img className="mx-auto h-[110px]" src={tarif_ecole} alt="" srcSet="" />
      <p className="text-[17px] font-medium text-center mt-8">Vous recherchez un <span className="text-[#5B9ECF]">étudiant (Stage ou alternance)*</span> ?</p>
      <h1 className="text-[40px] text-center mt-12">0 €*</h1>
      <p className="font-medium text-center mb-12">Pas de facturation</p>
      <div className="flex items-center gap-4">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Inscription</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Navigation libre</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Téléchargement du dossier de compétences</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Gestion des rendez-vous</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Visioconférence illimitée</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
      <IoCloseCircle className="shrink-0 text-[#F57167] w-[26px] h-[26px]" />
      <p className="text-[16px] font-medium">Présence d’un associé lors de l’entretien en visioconférence</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <IoCloseCircle className="shrink-0 text-[#F57167] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">On-Boarding du profil</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <IoCloseCircle className="shrink-0 text-[#F57167] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Suivi du profil</p>
      </div>
      <div className="mt-auto">
        <div className="text-center mt-8">
          <Link to="/les-profils?type=ecole" onClick={scrollToTop} className="bg-[#5B9ECF] hover:bg-[#2D77AD] text-white py-2 px-4 rounded-[5px] inline-block">Je souhaite intégrer un étudiant</Link>
        </div>
        <div className="mt-8 text-center">
          <p className="text-[12px] font-medium">*MyFinPlace permet à une société ou un cabinet de trouver un profil qui est à la recherche d’un stage ou d’une alternance en finance.</p>
          <p className="text-[12px] font-medium mt-4">Il appartient à la société ou au cabinet de séléctionner, rencontrer et contractualiser directement avec l'école acceuillant l'étudiant.</p>
        </div>
      </div>
    </div>
  )
}