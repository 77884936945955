export function verifComptable(titre, inscrit_ordre = false, inscrit_cncc = false, expertComptable = false) {
  let flag = false;
  const titreNormalized = titre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  ["comptable", "comptabilite", "consolideur"].map(item => {
    return titreNormalized.includes(item) && (flag = true)
  })
  if (!flag) {
    flag = expertComptable;
  }
  if (flag) {
    flag = !(inscrit_ordre || inscrit_cncc)
  }
  return flag;
}