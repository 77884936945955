import React, { useContext, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import { AlertMessageContext } from "Context/AlertMessageProvider";
import { FaHandshakeSimpleSlash } from "react-icons/fa6";
import { LuLoader2 } from "react-icons/lu";

export default function Refuser(props) {
  const appointment = props.appointment
  const token = cookie.get("token");
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState("");
  const { alert } = useContext(AlertMessageContext);
  const navigate = useNavigate()

  function onSubmit() {
    setIsLoading(true)
    axios.patch(
      process.env.REACT_APP_API + `client/entretien/refuse/${appointment.id}`,
      {
        "reason": reason
      },
      {
        headers: {
          "Content-Type": "application/merge-patch+json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        alert.successAlert("Le profil a été refusé avec succès !");
        props.getAppoitment();
        setIsLoading(false)
        navigate(`/mon-espace/mes-entretiens`)
        closeRefuser();
      })
      .catch((error) => {
        alert.errorAlert("Erreur lors du refus du profil !");
        setIsLoading(false)
      });
  }

  function closeRefuser() {
    props.setPopupRefuser(false)
  }
  function handleReason(e) {
    setReason(e.target.value)
  }

  return (
    <>
      <div className="fixed h-full w-full top-0 left-0 bg-black opacity-75 z-30"></div>
      <div className="fixed w-[80vw] md:w-[60vw] xl:w-[40vw] top-[25vh] left-[10vw] md:left-[20vw] xl:left-[30vw] bg-white rounded-[5px] z-40 py-8 px-12">
        <div className="flex w-full justify-center">
          <span className='text-[#FF0000] bg-[#FCDCDC] inline-block rounded-full p-4'>
            <FaHandshakeSimpleSlash size={72} />
          </span>
        </div>
        <p className="font-medium text-black text-2xl text-center mt-4">Refuser le profil</p>
        <p className="text-[#6B7280] text-center mt-2">Vous ne souhaitez pas travailler avec
          <span className="text-[#0F6FDE] font-bold">
            {appointment.id_user && " " + appointment.id_user.first_name + " " + appointment.id_user.last_name + " "}
          </span>
        </p>
        <div className="flex flex-col items-center mt-4 mb-6">
          <p className="w-[80%]">Raison de votre refus (optionnel)</p>
          <textarea className="border rounded-[5px] px-4 py-2 w-[80%] outline-none" onChange={handleReason}></textarea>
        </div>
        <div className="flex w-full justify-center gap-2 md:gap-6 mt-4">
          <div onClick={closeRefuser} className="text-center max-w-[250px] border border-[#2563EB] text-[#2563EB] hover:bg-[#2563EB] hover:text-white hover:cursor-pointer py-2 w-full">
            Annuler
          </div>
          <button onClick={onSubmit} disabled={isLoading} className="text-center max-w-[250px] flex justify-center items-center border border-[#FF0000] bg-[#FF0000] text-white hover:bg-white hover:text-[#FF0000] hover:cursor-pointer disabled:bg-[#ADADAD] disabled:cursor-wait disabled:text-white disabled:border-none py-2 w-full">
            {isLoading ? <LuLoader2 className='animate-spin' /> : "Confirmer"}
          </button>
        </div>
      </div>
    </>
  )
}