import React from 'react'

function DetailOffreTitle({ title, icon = null, fontSize }) {
    return (
        <div className={`flex items-center gap-1 mb-2 ${fontSize}`}>
            <span className="">
                {icon}
            </span>
            <span className="font-bold">{title}</span>
        </div>
    )
}

export default DetailOffreTitle