import React, { useState, useEffect, useContext } from "react";
import { CheckboxContext } from "Pages/Statics/LesOpportunite/Context/CheckboxProvider";
import Vector from "assets/svg/Vector";


export default function FiltreBase(props) {
    const { checkBoxValues, setCheckboxes } = useContext(CheckboxContext);
    const [isActive, setActive] = useState({});
    const [data, setData] = useState([])

    useEffect(() => {
        props.onField({ data: data, type: "base" })
    }, [data]);

    useEffect(() => {
        setData([]);
    }, [props.empty]);

    useEffect(() => {
        if (props.base) {
            setData(props.base);
        }
    }, [props.base]);



    function handleData(e, type) {
        let filterSelected = checkBoxValues.checkboxes[type]
        if (e.target.checked) {
            filterSelected.push(+e.target.value)

            setCheckboxes(prevData => ({
                ...prevData,
                [type]: filterSelected
            }))
        } else {
            let newFilter = filterSelected.filter(t => t !== +e.target.value)
            setCheckboxes(prevData => ({
                ...prevData,
                [type]: newFilter
            }))
        }
    }

    return (
        <main className="py-10 border-b space-y-6 ">
            {/*~~~~~~~~~~~~~~~~~~~~ filtre Contrat ~~~~~~~~~~~~~~~~~~~~~~~~~~~*/}
            <section className="">
                <div className="flex items-center justify-between"
                    onClick={() => setActive({ Contrat: !isActive.Contrat })} >
                    <p>Contrat recherché</p>
                    <div id="plus1">
                        <Vector classProps={isActive.Contrat ? "rotate-180 transition duration-700" : "transition duration-700"} alt="+" />
                    </div>
                </div>
                <article className={`${isActive.Contrat ? "animation-box-visible" : "animation-box-hidden"} `}>
                    <div className="animation-content ">
                        {props.field.contrat && props.field.contrat.map((e) => {
                            if (props.isComptable && e.name === "Mission") { return }
                            return <label className="flex gap-3.5 mt-3" key={e.id} >
                                <input onChange={(e) => handleData(e, "contrat")}
                                    type="checkbox"
                                    className="border-8"
                                    value={e.id}
                                    checked={checkBoxValues.checkboxes.contrat.includes(e.id)}
                                />
                                {e.name}
                            </label>
                        })}
                    </div>
                </article>
            </section>

            <section>
                <div className="flex items-center justify-between" onClick={() => setActive({ experiences: !isActive.experiences })} >
                    <p>Années d'expérience</p>
                    <div id="plus1">
                        <Vector classProps={isActive.experiences ? "rotate-180 transition duration-700" : "transition duration-700"} alt="+" />
                    </div>
                </div>
                <article className={`${isActive.experiences ? "animation-box-visible" : "animation-box-hidden"} `}>
                    <div className="animation-content ">
                        {props.field.timeExperience && props.field.timeExperience.map((e) => (
                            <label className="flex gap-3.5 mt-3" key={e.id} >
                                <input onChange={(e) => handleData(e, "timeExperience")}
                                    type="checkbox" className="border-8"
                                    value={e.id}
                                    checked={checkBoxValues.checkboxes.timeExperience.includes(e.id)}

                                />
                                {e.name}
                            </label>
                        ))}
                    </div>
                </article>
            </section>

            {/*~~~~~~~~~~~~~~~~~~~~ filtre Seniorité ~~~~~~~~~~~~~~~~~~~~~~*/}
            <section>
                <div className="flex items-center justify-between"
                    onClick={() => setActive({ Seniorité: !isActive.Seniorité })} >
                    <p>Séniorité</p>
                    <div id="plus1">
                        <Vector classProps={isActive.Seniorité ? "rotate-180 transition duration-700" : "transition duration-700"} alt="+" />
                    </div>
                </div>
                <article className={`${isActive.Seniorité ? "animation-box-visible" : "animation-box-hidden"} `}>
                    <div className="animation-content ">
                        {props.field.grade && props.field.grade.map((e) => (
                            <label className="flex gap-3.5 mt-3" key={e.id} >
                                <input onChange={(e) => handleData(e, "grade")}
                                    type="checkbox" className="border-8"
                                    value={e.id}
                                    checked={checkBoxValues.checkboxes.grade.includes(e.id)}
                                />
                                {e.name}
                            </label>
                        ))}
                    </div>
                </article>
            </section>

            <section>
                <div className="flex items-center justify-between"
                    onClick={() => setActive({ intervention: !isActive.intervention })} >
                    <p>Type d'intervention</p>
                    <div id="plus1">
                        <Vector classProps={isActive.intervention ? "rotate-180 transition duration-700" : "transition duration-700"} alt="+" />
                    </div>
                </div>

                <article className={`${isActive.intervention ? "animation-box-visible" : "animation-box-hidden"} `}>
                    <div className="animation-content ">
                        {props.field.intervention && props.field.intervention.map((e) => (
                            <label className="flex gap-3.5 mt-3" key={e.id} >
                                <input onChange={(e) => handleData(e, "tag")}
                                    type="checkbox" className="border-8"
                                    value={e.id}
                                    checked={checkBoxValues.checkboxes.tag.includes(e.id)}
                                />
                                {e.name}
                            </label>
                        ))}
                    </div>
                </article>
            </section>

        </main>
    );
}