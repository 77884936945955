import { useState, useEffect } from 'react'
import { useOutletContext } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import jwt from "jwt-decode";

import RadioButton from 'Components/Forms/RadioButton/RadioButton'
import Checkbox from 'Components/Forms/CheckBox/Checkbox'
import Badge from 'Components/Badge/Badge'
import PrimaryButton from 'Components/Buttons/PrimaryButton'
import SecondaryButton from 'Components/Buttons/SecondaryButton'
import { LuLoader2 } from "react-icons/lu";




function ContractType() {

    const navigate = useNavigate()
    const [data, setData] = useOutletContext()
    const decodedToken = jwt(Cookies.get("token"));

    const [seniorityTags, setSeniorityTags] = useState(null)
    const [contractTags, setContractTags] = useState(null)
    const [interventionTags, setInterventionTags] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const [formNotValid, setFormNotValid] = useState(true)

    const next = () => navigate("/parcours-inscription/mobilite")
    const prev = () => navigate("/parcours-inscription")

    const getTags = () => {
        const options = { method: 'GET' };
        fetch(process.env.REACT_APP_API + "filtres", options)
            .then(res => res.json())
            .then(data => {
                setSeniorityTags(data.grade)
                setContractTags(data.contrat)
                setInterventionTags(data.intervention)
            })
            .finally(() => {

            })
            .catch(err => console.log())
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        getTags()
    }, [])


    useEffect(() => {
        checkForm(data)
    }, [data])


    const seniorityHandler = (e) => {
        let newData = { ...data };
        newData["idgrade"] = [`api/grades/${e.target.value}`];
        setData(newData);
    }

    const contractHandler = (e) => {
        let value = e.target.value
        let newData = { ...data };

        if (!data.workExperience.includes(value)) {

            if (data.workExperience.length == 3) {
                return;
            }

            newData.workExperience = [...newData.workExperience, value]
        } else {
            newData.workExperience = newData.workExperience.filter(x => x !== value)
        }

        setData(newData)
    }

    const interventionHandler = (e) => {
        let value = `api/tags/${e.target.value}`
        let newData = { ...data }

        if (data.tags.filter(item => item.tag === value).length === 0) {
            newData.tags = [...newData.tags, {
                user: `api/users/${decodedToken.id}`,
                tag: value,
                level: 1
            }]
        } else {
            newData.tags = data.tags.filter(x => x.tag !== value);
        }
        setData(newData);
    }


    const checkForm = (state) => {

        if (state.workExperience.length === 0) {
            setFormNotValid(true)
            return
        }


        if (state.idgrade.length === 0) {
            setFormNotValid(true)
            return
        }

        if ((state.tags.filter(item => item.tag === "api/tags/10").length > 0) || (state.tags.filter(item => item.tag === "api/tags/11").length > 0) || (state.tags.filter(item => item.tag === "api/tags/12").length > 0)) {
            setFormNotValid(false)
        } else {
            setFormNotValid(true);
            return;
        }

        setFormNotValid(false)

    }

    return (
        <div className={`flex flex-col my-4 p-6 rounded ${isLoading ? "" : "bg-white"} gap-6`}>
            {
                isLoading ?
                    <div className="text-[#2563EB] mt-8">
                        <LuLoader2 size={48} className='animate-spin' />
                    </div>
                    :
                    <>
                        <div>
                            <h2 className='max-sm:text-center'>Type de contrat</h2>
                        </div>
                        <div className='mb-4'>
                            <h5 className='text-3xl mb-2'>Séniorité *</h5>
                            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
                                {
                                    seniorityTags ?
                                        seniorityTags.map((value, key) => (
                                            <RadioButton
                                                onChange={(e) => seniorityHandler(e)}
                                                key={key}
                                                id={"grade" + value.id}
                                                label={value.name}
                                                name={"seniority"}
                                                value={value.id}
                                                checked={data.idgrade.includes(`api/grades/${value.id}`)} />
                                        )) :
                                        null
                                }
                            </div>
                        </div>

                        <div className='mb-4'>
                            <div className='flex flex-col sm:flex-row items-center gap-1 mb-2'>
                                <h5 className='text-3xl'>Contrat recherché *</h5>
                                <Badge mode={"info"} name={"Plusieurs choix possible"} />
                                <Badge mode={"info"} name={"Max : 3"} />
                            </div>
                            <div className='grid grid-cols-1 sm:grid-cols-2 xl:flex'>

                                {
                                    contractTags ?
                                        contractTags.map((value, key) => (
                                            <Checkbox
                                                onChange={(e) => contractHandler(e)}
                                                key={key}
                                                id={"contrat" + value.id}
                                                label={value.name}
                                                name={value.name}
                                                value={value.name}
                                                checked={data.workExperience.includes(`${value.name}`)}
                                                defaultDesign={true}
                                            />)) :


                                        null
                                }
                            </div>
                        </div>

                        <div className='mb-4'>
                            <div className='flex flex-col sm:flex-row items-center gap-1 mb-2'>
                                <h5 className='text-3xl'>Type d'intervention *</h5>
                                <Badge mode={"info"} name={"Plusieurs choix possible"} />
                            </div>
                            <div className='grid grid-cols-1 sm:grid-cols-2 xl:flex'>

                                {
                                    interventionTags ?
                                        interventionTags.map((value, key) => (
                                            <Checkbox
                                                onChange={interventionHandler}
                                                key={key}
                                                id={"tag" + value.id}
                                                label={value.name}
                                                name={"intervention"}
                                                value={value.id}
                                                checked={data.tags.filter(item => item.tag === `api/tags/${value.id}`).length > 0}
                                                defaultDesign={true}
                                            />
                                        )) :
                                        null
                                }
                            </div>
                        </div>
                        <div className='flex justify-between'>
                            <SecondaryButton onClick={prev} text={"précédent"} />
                            <PrimaryButton disabled={formNotValid} onClick={next} text={"suivant"} />
                        </div>
                    </>
            }
        </div>
    )
}

export default ContractType