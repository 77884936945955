import React from 'react'

export default function PartieBasse() {
    return (

        <section className='containerxl'>

            <div className='flex flex-col items-center sm:items-stretch sm:flex-row justify-center'>
                <article className='sm:border-y-2 rounded-t-[10px] border-2 border-lightblue sm:rounded-tr-[0px] sm:rounded-l-[10px] max-w-[500px]'>
                    <div className='grid gap-7 text-center pt-12 pb-20 px-4'>
                        <h3 className='bigscreen:text-[30px]'>PRESSE</h3>
                        <p className='bigscreen:text-[20px]'> Vous travaillez dans l'univers des médias et vous souhaitez communiquer au sujet de MyFinPlace ? </p>
                        <p className='bigscreen:text-[20px]'> Veuillez nous écrire en inscrivant "Presse" en objet :</p>
                    </div>
                </article>
                <article className='bg-greylight rounded-b-[10px]  sm:border-y-2 sm:border-r-2 border-lightblue sm:rounded-r-[10px] max-w-[500px]'>
                    <div className='grid gap-7 text-center pt-12 pb-20 px-4'>
                        <h3 className='text-hover bigscreen:text-[30px]'>INVESTISSEURS</h3>
                        <p className='bigscreen:text-[20px]'>MyFinPlace est à la recherche d'investisseurs
                            afin d’accompagner sa croissance et son développement. </p>
                        <p className='bigscreen:text-[20px]'> Si vous êtes intéressé par le modèle, veuillez nous écrire en inscrivant "Investisseurs" en objet :</p>
                    </div>
                </article>
            </div>
            <div className='w-fit m-auto -mt-4'>

                <a className='bg-ligthblue hover:bg-hover text-white font-semibold rounded px-7 py-5 bigscreen:text-[20px]' href="mailto: contact@myfinplace.com">
                    Nous contacter
                </a>
            </div>


        </section>

    )
}