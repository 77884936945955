import { useState, useContext } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { AlertMessageContext } from "Context/AlertMessageProvider";
import { HiMiniLockOpen } from "react-icons/hi2";
import { LuLoader2 } from "react-icons/lu";

export default function PopupDearchiver(props) {
  const [isLoading, setIsLoading] = useState(false)
  const offre = props.offre;
  const token = Cookies.get("token");
  const { alert } = useContext(AlertMessageContext);

  function onSubmit() {
    setIsLoading(true);
    axios.patch(process.env.REACT_APP_API + `client/offre/archiver/${offre.id}`,
      { archiver: null },
      {
        headers: {
          "Content-Type": "application/merge-patch+json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        setIsLoading(false);
        alert.successAlert("L'opportunité a été désarchivée avec succès !");
        props.closeDearchiver();
        props.fetchOffre && props.fetchOffre();
      })
      .catch((error) => {
        setIsLoading(false);
        alert.errorAlert("Une erreur s'est produite lors du désarchivage de l'opportunité !");
      });
  }

  return (
    <>
      <div className="fixed h-full w-full top-0 left-0 bg-black opacity-75 z-30" ></div >
      <div className="fixed w-[80vw] md:w-[60vw] xl:w-[40vw] top-[25vh] left-[10vw] md:left-[20vw] xl:left-[30vw] bg-white rounded-[5px] z-40 py-8 px-12">
        <div className="flex w-full justify-center">
          <span className='text-[#5B9ECF] bg-[#CCE9FF] inline-block rounded-full p-4'>
            <HiMiniLockOpen size={72} />
          </span>
        </div>
        <p className="font-medium text-black text-2xl text-center mt-4">Désarchiver l'offre <span className="text-[#5B9ECF]">{offre.intitule}</span></p>
        <div className="flex w-full justify-center gap-2 md:gap-6 mt-4">
          <div onClick={props.closeDearchiver} className="text-center max-w-[250px] border border-[#2563EB] text-[#2563EB] hover:bg-[#2563EB] hover:text-white hover:cursor-pointer py-2 w-full">
            Annuler
          </div>
          <button onClick={onSubmit} disabled={isLoading} className="flex justify-center items-center text-center max-w-[250px] border border-[#5B9ECF] bg-[#5B9ECF] text-white hover:bg-white hover:text-[#5B9ECF] hover:cursor-pointer disabled:bg-[#ADADAD] disabled:cursor-wait disabled:text-white disabled:border-none py-2 w-full">
            {isLoading ? <LuLoader2 className='animate-spin' /> : "Confirmer"}
          </button>
        </div>
      </div>
    </>
  )
}