import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import PieChart from "./Camembert";

function Kpi() {
  //Fetch la donnée
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.get(process.env.REACT_APP_API + `iris/kpiadmin`, {
      headers: {
        Authorization: `Bearer ${cookie.get("token")}`
      }
    })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
      });
  }, []);
  return (
    <>
      <h1>KPIs</h1>
      {data.length === 0 ? (
        <p>Chargement</p>
      ) : (
        <div className="flex flex-row gap-6 w-full">
          {/* Div de gauche */}
          <div className="w-1/2 flex flex-col gap-6">
            {/* Inscription */}
            <div className="border border-[#D6D3D3] rounded-[25px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)">
              <div className="text-center bg-[#3D88F8] text-white py-2 rounded-t-[25px] text-[20px]"><b>Nombre total d'inscription :</b> <i>{data.TotalClient + data.TotalUser}</i></div>
              <div className="flex flex-row">
                <PieChart
                  data={[data.TotalEntreprise, data.TotalCabinetAudit, data.TotalCabinetConseil]}
                  colors={["#F67517", "#9417F6", "#3FC500"]}
                  texts={["Entreprise", "Cabinets d'audit", "Cabinets de conseil"]}
                  showTotal={true} />
                <PieChart
                  data={[data.TotalCandidat, data.TotalConsultant, data.TotalEtudiant]}
                  colors={["#7997FF", "#0028B7", "#9417F6"]}
                  texts={["Candidats", "Consultants", "Etudiant"]}
                  showTotal={true} />
              </div>
            </div>
            {/* Moyenne des inscriptions */}
            <div className="border border-[#D6D3D3] rounded-[25px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)">
              <div className="text-center bg-[#3D88F8] text-white py-2 rounded-t-[25px] text-[20px]"><b>Détail des inscriptions</b></div>
              <div className="flex flex-row text-center">
                <div className="w-1/4 py-4">
                  <p className="font-bold text-[18px]">Journalier ({new Date().getFullYear()})</p>
                  <p>{data.JournalierSurAnnee}</p>
                </div>
                <span className="border border-[#D6D3D3]"></span>
                <div className="w-1/4 py-4">
                  <p className="font-bold text-[18px]">Hier</p>
                  <p>{data.MoyenneHier}</p>
                </div>
                <span className="border border-[#D6D3D3]"></span>
                <div className="w-1/4 py-4">
                  <p className="font-bold text-[18px]">Aujourd'hui</p>
                  <p>{data.MoyenneParJour}</p>
                </div>
                <span className="border border-[#D6D3D3]"></span>
                <div className="w-1/4 py-4">
                  <p className="font-bold text-[18px]">30 dernier jours</p>
                  <p>{data.MoyenneParMois}</p>
                </div>
                <span className="border border-[#D6D3D3]"></span>
                <div className="w-1/4 py-4">
                  <p className="font-bold text-[18px]">365 dernier jours</p>
                  <p>{data.MoyenneParAn}</p>
                </div>
              </div>
            </div>
            {/* Total d'entretien */}
            <div className="border border-[#D6D3D3] rounded-[25px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25) relative">
              <div className="text-center bg-[#3D88F8] text-white py-2 rounded-t-[25px] text-[20px]"><b>Nombre total d'entretien :</b> <i>{data.TotalEntretien}</i></div>
              <p className="font-bold text-[20px] ml-4 mt-2">Entretien en cours : {data.EntretienGroupBy.planifier + data.EntretienGroupBy.programmés + data.EntretienGroupBy.réaliser}</p>
              <div className="absolute right-0 top-[46px] border-b border-l border-[#D6D3D3] rounded-bl-[25px] p-4">
                Entretiens en 2023 : {data.TotalEntretienThisYear}
              </div>
              <div className="flex justify-center">
                <PieChart
                  data={[
                    data.EntretienGroupBy.planifier,
                    data.EntretienGroupBy.programmés,
                    data.EntretienGroupBy.réaliser + data.EntretienGroupBy.archiver_win + data.EntretienGroupBy.archiver]}
                  colors={["#0095C5", "#0043C5", "#3FC500"]}
                  texts={["À planifier", "Programmés", "Réalisés"]} />
              </div>
            </div>
            {/* Taux de conversion des profils retenu */}
            <div className="border border-[#D6D3D3] rounded-[25px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25) relative">
              <div className="text-center bg-[#3D88F8] text-white py-2 rounded-t-[25px] text-[20px]"><b>Taux de conversion des profils retenu</b></div>
              {((data.EntretienGroupBy.archiver_win == 0 && data.EntretienGroupBy.archiver == 0 && data.EntretienGroupBy.réaliser == 0)) ?
                (<div className="text-center py-8">Aucun entretien n'a été réalisé</div>)
                :
                (
                  <div className="flex justify-center">
                    <PieChart
                      data={[
                        data.EntretienGroupBy.archiver_win ? data.EntretienGroupBy.archiver_win : 0,
                        data.EntretienGroupBy.archiver ? data.EntretienGroupBy.archiver : 0,
                        data.EntretienGroupBy.réaliser ? data.EntretienGroupBy.réaliser : 0
                      ]}
                      colors={["#3FC500", "#F85757", "#C9C9C9"]}
                      texts={["Profil retenu", "Profil refusé", "Non décidé"]}
                      pourcentage={data.EntretienTauxConversion} />
                  </div>
                )}
            </div>
            {/* Répartion des profil par département */}
            <div className="border border-[#D6D3D3] rounded-[25px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)">
              <div className="text-center bg-[#3D88F8] text-white py-2 rounded-t-[25px] text-[20px]"><b>Répartion des contrats</b></div>
              <div className="flex justify-center py-6">
                <PieChart
                  data={data.ProfilByDepartement.map(item => { return item.counted })}
                  texts={data.ProfilByDepartement.map(item => { return item.department })}
                  colors={data.ProfilByDepartement.map(item => { return '#' + (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0') })}
                  dimension={200} />
              </div>
            </div>
          </div>
          {/* Div de droite */}
          <div className="w-1/2 flex flex-col gap-6">
            {/* Taux d'affichage des vignettes */}
            <div className="border border-[#D6D3D3] rounded-[25px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)">
              <div className="text-center bg-[#3D88F8] text-white py-2 rounded-t-[25px] text-[20px]"><b>Taux d'affichage des vignettes</b></div>
              <div className="flex justify-center">
                <PieChart
                  data={[data.TauxAffichageVignette, 100 - data.TauxAffichageVignette]}
                  colors={["#67E53A", "#F85757"]}
                  pourcentage={data.TauxAffichageVignette}
                  dimension={200} />
              </div>
            </div>
            {/* status des profils */}
            <div className="border border-[#D6D3D3] rounded-[25px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)">
              <div className="text-center bg-[#3D88F8] text-white py-2 rounded-t-[25px] text-[20px]"><b>Status des profils</b></div>
              <div className="flex flex-row text-center">
                <div className="w-1/4 py-4">
                  <p className="font-bold text-[18px]">Experts comptable</p>
                  <p>{data.nbExpertComptable}</p>
                </div>
                <span className="border border-[#D6D3D3]"></span>
                <div className="w-1/4 py-4">
                  <p className="font-bold text-[18px]">Commissaires aux comptes</p>
                  <p>{data.nbCommissaireCompte}</p>
                </div>
                <span className="border border-[#D6D3D3]"></span>
                <div className="w-1/4 py-4">
                  <p className="font-bold text-[18px]">Profils Magement de transition</p>
                  <p>{data.nbManagerTransition}</p>
                </div>
                <span className="border border-[#D6D3D3]"></span>
                <div className="w-1/4 py-4">
                  <p className="font-bold text-[18px]">Profils disponible</p>
                  <p>{data.nbProfilImmediat}</p>
                </div>
              </div>
            </div>
            {/* Répartion des contrats */}
            <div className="border border-[#D6D3D3] rounded-[25px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)">
              <div className="text-center bg-[#3D88F8] text-white py-2 rounded-t-[25px] text-[20px]"><b>Répartion des contrats</b></div>
              <div className="flex justify-center py-6">
                <PieChart
                  data={[data.NbStage, data.NbCDD, data.NbCDI, data.NbAlternance, data.NbMission]}
                  texts={["Stage", "CDD", "CDI", "Alternance", "Mission"]}
                  colors={["#0047FF", "#3D6B2D", "#47C04C", "#5797E2", "#B157F8"]}
                  dimension={200} />
              </div>
            </div>
            {/* Total d'entretien */}
            <div className="border border-[#D6D3D3] rounded-[25px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25) relative">
              <div className="text-center bg-[#3D88F8] text-white py-2 rounded-t-[25px] text-[20px]"><b>Répartition des opportunités</b></div>
              <p className="font-bold text-[20px] ml-4 mt-2">Opportunités en cours : {data.nbOpportunitesEnCours}</p>
              <div className="absolute right-0 top-[46px] border-b border-l border-[#D6D3D3] rounded-bl-[25px] p-4">
                Opportunités archivés : {data.nbOpportunitesArchive}
              </div>
              <div className="flex justify-center">
                <PieChart
                  data={[
                    data.RepartitionOffreByClient.entreprise,
                    data.RepartitionOffreByClient.cabinetAudit,
                    data.RepartitionOffreByClient.cabinetConseil]}
                  colors={["#F67517", "#9417F6", "#3FC500"]}
                  texts={["Entreprise", "Cabinets d'audit", "Cabinets de conseil"]} />
              </div>
            </div>
            {/* Répartion des profil par Bac */}
            <div className="border border-[#D6D3D3] rounded-[25px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)">
              <div className="text-center bg-[#3D88F8] text-white py-2 rounded-t-[25px] text-[20px]"><b>Répartion des profils par étude</b></div>
              <div className="flex justify-center py-6">
                <PieChart
                  data={data.ProfilByBac.map(item => { return item.counted })}
                  texts={data.ProfilByBac.map(item => { return "Bac+" + item.bac })}
                  colors={["#0047FF", "#3D6B2D", "#47C04C", "#5797E2", "#B157F8", "#0047FF", "#3D6B2D", "#47C04C", "#5797E2"]}
                  dimension={200} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Kpi;