import { useEffect, useContext, useState } from "react"
import { useNavigate, Outlet, useOutletContext } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthProvider"
import { isClient, isUser } from 'utils/roles';

export default function Offers() {
  const { value } = useContext(AuthContext);
  const redirection = useNavigate();

  const [userInformation, setUserInformation, filters] = useOutletContext();

  useEffect(() => {
    if (value.isLoggedIn && isUser(value.user)) {
      redirection("/mon-espace")
    }
  }, [value.user])

  if (isClient(value.user)) {
    return (
      <Outlet context={[filters]} />
    )
  }
}