import React, { useEffect, useState } from 'react'
import DetailsOffre from '../LesOpportunite/Components/DetailsOffre'

function ViewOffer() {


	return (
		<div className="max-w-[800px] m-auto px-4 py-4 gap-4">
			<DetailsOffre />
		</div>
	)
}

export default ViewOffer