import React from "react";


function Postuler() {
 

    return (
        <div className='my-2 bg-[#EBF4FF] rounded-full' >
        <p className='p-2 font-bold text-[#0F6FDE] text-xs whitespace-nowrap'>Candidature envoyée</p>
        </div>
    );
}

export default Postuler;
