import React, { useContext, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { AlertMessageContext } from "Context/AlertMessageProvider";
import { AuthContext } from "Context/AuthProvider";
import { isClient, isUser } from "utils/roles";
import { IoIosWarning } from "react-icons/io";
import { LuLoader2 } from "react-icons/lu";

export default function Annuler(props) {
  const appointment = props.appointment
  const token = cookie.get("token");
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState("");
  const { alert } = useContext(AlertMessageContext);
  const { value } = useContext(AuthContext);

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}h${formattedMinutes}`;
  };

  function closeAnnuler() {
    props.setPopupAnnuler(false)
  }

  function onSubmit() {
    const type = isUser(value.user) ? "user" : isClient(value.user) ? "client" : null;
    if (type === null) {
      // Si le type n'est pas définie, on ne fait pas de request
      return
    }
    setIsLoading(true)
    axios.patch(
      process.env.REACT_APP_API + `${type}/entretien/cancel/${appointment.id}`,
      {
        "reason": reason
      },
      {
        headers: {
          "Content-Type": "application/merge-patch+json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        alert.successAlert("L'entretien a été annulé avec succès !");
        props.getAppoitment();
        setIsLoading(false)
        closeAnnuler();
      })
      .catch((error) => {
        alert.errorAlert("Erreur lors de la suppression de l'entretien !");
        setIsLoading(false)
      });
  }

  return (
    <>
      <div className="fixed h-full w-full top-0 left-0 bg-black opacity-75 z-30"></div>
      <div className="fixed w-[80vw] md:w-[60vw] xl:w-[40vw] top-[25vh] left-[10vw] md:left-[20vw] xl:left-[30vw] bg-white rounded-[5px] z-40 py-8 px-12">
        <div className="flex w-full justify-center">
          <span className='text-[#FF0000] bg-[#FCDCDC] inline-block rounded-full p-4'>
            <IoIosWarning size={72} />
          </span>
        </div>
        <p className="font-medium text-black text-2xl text-center mt-4">Annuler l'entretien</p>
        <p className="text-[#6B7280] text-center mt-2">Confirmer vous l'annulation de l’entretien avec
          <span className="text-[#0F6FDE] font-bold">
            {appointment.id_client && " " + appointment.id_client.company + " "}
            {appointment.id_user && " " + appointment.id_user.first_name + " " + appointment.id_user.last_name + " "}
          </span>
          le <span className="text-black font-bold">{appointment && new Date(appointment.date_choice).toLocaleDateString("fr-FR", {
            weekday: "long",
            day: "2-digit",
            month: "long",
            year: "numeric",
          })}
            <span className='font-normal'> à </span>
            {appointment && formatTime(new Date(appointment.date_choice))}
          </span>
        </p>
        <div className="flex flex-col items-center mt-4 mb-6">
          <p className="text-[#6B7280] w-[80%]">Raison de votre refus (optionnel)</p>
          <textarea className="border rounded-[5px] px-4 py-2 w-[80%] outline-none" onChange={(e) => setReason(e.target.value)}></textarea>
        </div>
        <div className="flex w-full justify-center gap-2 md:gap-6 mt-4">
          <div onClick={closeAnnuler} className="text-center max-w-[250px] border border-[#2563EB] text-[#2563EB] hover:bg-[#2563EB] hover:text-white hover:cursor-pointer py-2 w-full">
            Annuler
          </div>
          <button onClick={onSubmit} disabled={isLoading} className="text-center max-w-[250px] flex justify-center items-center border border-[#FF0000] bg-[#FF0000] text-white hover:bg-white hover:text-[#FF0000] hover:cursor-pointer disabled:bg-[#ADADAD] disabled:cursor-wait disabled:text-white disabled:border-none py-2 w-full">
            {isLoading ? <LuLoader2 className='animate-spin' /> : "Confirmer"}
          </button>
        </div>
      </div>
    </>
  )
}