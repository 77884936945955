import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { format } from "date-fns";
import { fr } from 'date-fns/locale'
import { AlertMessageContext } from "Context/AlertMessageProvider";
import { AuthContext } from "Context/AuthProvider";
import CalendarComponentMinDate from 'Components/Calendar/CalendarComponentMinDate';
import Input from 'Components/Forms/Input/Input'
import { isClient, isUser } from "utils/roles";
import { GoClockFill } from "react-icons/go";
import { LuLoader2 } from "react-icons/lu";
import { FaCalendarAlt } from "react-icons/fa";
import { sub, add } from "date-fns";

export default function Replanifier(props) {
  const appointment = props.appointment
  const [dateSelected, setDateSelected] = useState(appointment && format(new Date(appointment.date_choice), 'dd/MM/yyyy'));
  const [timeSelected, setTimeSelected] = useState(null);
  const [hoursTaken, setHoursTaken] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [isDateSelected, setIsDateSelected] = useState('disabled');
  const token = cookie.get("token");
  const { alert } = useContext(AlertMessageContext);
  const { value } = useContext(AuthContext);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API + `entretien/bothhourstaken/${appointment.id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then((res) => {
        setHoursTaken(res.data.hoursTaken)
      })
      .catch((error) => {
        // nothing
      })
  }, [])

  function handleChangeTime(e) {
    setTimeSelected(e.target.value)
  }
  function closeReplanifier() {
    props.setPopupReplanifier(false)
  }

  function isDisableOption(hour, formathoursTaken, isHalfHour) {
    var dateParts = dateSelected.split("/");
    var dateSelectedEng = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    var dateOption = new Date(dateSelectedEng).setHours(hour);
    if (isHalfHour) {
      dateOption = new Date(dateOption).setMinutes(30);
    }
    dateOption = new Date(dateOption);
    var Before30 = sub(dateOption, { hours: 2 });
    var After30 = add(dateOption, { minutes: 30 });
    var flag = false;
    formathoursTaken.map((hourTaken) => {
      if (Before30 <= new Date(hourTaken) && new Date(hourTaken) <= After30) {
        return flag = true
      }
    })
    return flag
  }

  const renderOptions = () => {
    const options = [];
    // Cachement des heures passé si l'utilsateur à choisi aujourd'hui
    var formatSelectedDay = null;
    var formathoursTaken = [];
    if (dateSelected !== null) {
      var dateParts = dateSelected.split("/");
      var dateSelectedEng = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      formatSelectedDay = format(Date.parse(dateSelectedEng), "yyyy-MM-dd", { locale: fr })
      formathoursTaken = hoursTaken.filter((x) => x.startsWith(format(Date.parse(dateSelectedEng), "yyyy-MM-dd", { locale: fr })))
    }
    const isToday = formatSelectedDay === new Date().toISOString().split('T')[0];
    const todayHour = new Date().getHours();
    // Disable les heures déjà utiliser par d'autre appointment
    options.push(
      <option key="select" value="">Sélectionner une heure</option>
    );
    for (let hour = 7; hour <= 22; hour++) {
      const formattedHour = hour.toString().padStart(2, '0');
      options.push(
        <option value={`${formattedHour}:00`} hidden={isToday && todayHour >= hour} disabled={isDisableOption(hour, formathoursTaken, false)} key={formattedHour} className='disabled:text-[#BFBFBF]'>{`${formattedHour}:00`}</option>
      );
      if (formattedHour < 22) {
        options.push(<option value={`${formattedHour}:30`} hidden={isToday && todayHour >= hour} disabled={isDisableOption(hour, formathoursTaken, true)} key={`${formattedHour}:30`} className='disabled:text-[#BFBFBF]'>{`${formattedHour}:30`}</option>);
      }
    }
    return options;
  };


  function onSubmit() {
    const type = isUser(value.user) ? "user" : isClient(value.user) ? "client" : null;
    if (type === null) {
      // Si le type n'est pas définie, on ne fait pas de request
      return
    }
    var dateParts = dateSelected.split("/");
    var dateFormat = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
    setIsLoading(true)
    axios.patch(
      process.env.REACT_APP_API + `${type}/entretien/modify/${appointment.id}`,
      { dateChoice: dateFormat + " " + timeSelected },
      {
        headers: {
          "Content-Type": "application/merge-patch+json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        alert.successAlert("La date d'entretien a été modifiée avec succès !");
        props.getAppoitment();
        setIsLoading(false)
        closeReplanifier();
      })
      .catch((error) => {
        alert.errorAlert("Erreur lors de la modification de la date !");
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (new Date(dateSelected) < new Date()) {
      setDateSelected(format(new Date(), 'dd/MM/yyyy'))
    }
  }, [])

  const handleDateSelect = (date) => {
    setDateSelected(format(date, 'dd/MM/yyyy'));
    setIsDateSelected("")
    setShowCalendar(false);
  };

  const handleDateClick = () => {
    setShowCalendar(!showCalendar);
  };

  return (
    <>
      <div className="fixed h-full w-full top-0 left-0 bg-black opacity-75 z-30"></div>
      <div className="fixed w-[80vw] md:w-[60vw] xl:w-[40vw] top-[25vh] left-[10vw] md:left-[20vw] xl:left-[30vw] bg-white rounded-[5px] z-40 py-8 px-12">
        <div className="flex w-full justify-center">
          <span className='text-[#FFA500] bg-[#FDF5E1] inline-block rounded-full p-4'>
            <GoClockFill size={72} />
          </span>
        </div>
        <p className="font-medium text-black text-2xl text-center mt-4">Proposition d'un nouvel horaire</p>
        <div className="flex justify-center gap-6 my-6">
          {/* <input type="date" onChange={handleChangeDate} min={new Date().toISOString().split('T')[0]} value={dateSelected} className="px-[15px] py-[10px] bg-white focus:outline-none rounded-[5px] text-[14px] border border-gray-200" /> */}
          <div className='flex' onClick={handleDateClick}>
            <Input name={"date"} isReadOnly={true} value={dateSelected ? dateSelected : 'jj/mm/aaaa'} />
            <div className='flex items-center w-[60px] justify-center bg-greylight p-2 border-t border-b border-r border-lightblue cursor-pointer hover:bg-[#5B9ECF] hover:text-white' >
              <FaCalendarAlt />
            </div>

          </div>
          {
            showCalendar ?
              <CalendarComponentMinDate onDate={handleDateSelect} />
              :
              null

          }
          <select disabled={isDateSelected} name="time" onChange={handleChangeTime} className="outline-none py-[10px] px-[10px] bg-greylight border border-lightblue rounded-[3px] w-[277px]">
            {renderOptions()}
          </select>
        </div>
        <div className="flex w-full justify-center gap-2 md:gap-6 mt-4">
          <div onClick={closeReplanifier} className="text-center max-w-[250px] border border-[#2563EB] text-[#2563EB] hover:bg-[#2563EB] hover:text-white hover:cursor-pointer py-2 w-full">
            Annuler
          </div>
          <button onClick={onSubmit} disabled={isLoading} className="text-center max-w-[250px] flex justify-center items-center border border-[#FFA500] bg-[#FFA500] text-white hover:bg-white hover:text-[#FFA500] hover:cursor-pointer disabled:bg-[#ADADAD] disabled:cursor-wait disabled:text-white disabled:border-none py-2 w-full">
            {isLoading ? <LuLoader2 className='animate-spin' /> : "Confirmer"}
          </button>
        </div>
      </div>
    </>
  )
}