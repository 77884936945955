import { useState, useEffect, useRef } from "react";
import { format } from "date-fns";
import { fr } from 'date-fns/locale'
import { useNavigate } from "react-router-dom";

import ContextMenu from "Components/ContextMenu/ContextMenu";

// Popups
import PopupArchiver from "./PopupArchiver";
import PopupDearchiver from "./PopupDearchiver";
import PopupDelete from "./PopupDelete";
//Icon
import { FaArchive } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";




export default function CardOffre(props) {
  const offre = props.offre;
  const [popupArchiver, setPopupArchiver] = useState(false);
  const [popupDearchiver, setPopupDearchiver] = useState(false);
  const [popupDelete, setPopupDelete] = useState(false);
  const navigate = useNavigate()

  function openArchiver() {
    setPopupArchiver(true);
  }
  function closeArchiver() {
    setPopupArchiver(false);
  }
  function openDearchiver() {
    setPopupDearchiver(true);
  }
  function closeDearchiver() {
    setPopupDearchiver(false);
  }
  function openDelete() {
    setPopupDelete(true);
  }
  function closeDelete() {
    setPopupDelete(false);
  }
  
  return (
    <div className="p-3 pb-4 border rounded bg-white hover:shadow-lg lg:w-[326px] lg:h-auto flex flex-col justify-between">
      <div className="h-full flex flex-col justify-between">
        <div className="mb-1 flex justify-between items-center">
          <div>
            <p>
              Date de création : <span class="items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"> {format(Date.parse(offre.date_time_create), "iiii d LLLL yyyy", { locale: fr })}</span>
            </p>
          </div>
          <ContextMenu>
            {offre.archiver ?
              <>
                <p className="text-sm px-2 py-1 flex gap-1 items-center cursor-pointer hover:bg-[#f1f5f9]" onClick={() => navigate(`/mon-espace/mes-offres/modification-offre/${offre.id}/recapitulatif`)}><span className="text-[#fb923c]"><FaRegEdit /></span>Modifier</p>
                <p className="text-sm px-2 py-1 flex gap-1 items-center cursor-pointer hover:bg-[#f1f5f9]" onClick={openDearchiver}><span><FaArchive /></span>Desarchiver</p>
                <p className="text-sm px-2 py-1 flex gap-1 items-center cursor-pointer text-red-500 hover:bg-[#f1f5f9]" onClick={openDelete}><span><FaTrash /></span>Supprimer</p>
              </>
              :
              <>
                <p className="text-sm px-2 py-1 flex gap-1 items-center cursor-pointer hover:bg-[#f1f5f9]" onClick={() => navigate(`/mon-espace/mes-offres/modification-offre/${offre.id}/recapitulatif`)}><span className="text-[#fb923c]"><FaRegEdit /></span>Modifier</p>
                <p className="text-sm px-2 py-1 flex gap-1 items-center cursor-pointer hover:bg-[#f1f5f9]" onClick={openArchiver}><span><FaArchive /></span>Archiver</p>
              </>
            }
          </ContextMenu>
        </div>

        <p className="font-medium text-[20px] text-left mb-1">{offre.intitule}</p>
        <div className="flex justify-between items-center">
          <div>
            {
              offre.availability.includes("Date connue") && offre.availability_date !== undefined ?
                new Date(offre.availability_date) < new Date() ?
                  <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
                    <div className="rounded-full bg-[#00B553] h-2 w-2 mr-2"></div>
                    <p className="text-xs">{"Immédiate"}</p>
                  </span>
                  :
                  <>
                    <span class="items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{format(Date.parse(offre.availability_date), "iiii d LLLL yyyy", { locale: fr })}</span>
                  </>
                :
                offre.availability.includes("Immédiate") &&
                <div className="flex items-center gap-1">
                  <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
                    <div className="rounded-full bg-[#00B553] h-2 w-2 mr-2"></div>
                    <p className="text-xs">{"Immédiate"}</p>
                  </span>
                </div>
            }
          </div>

        </div>
        <div className="mb-2">
          {offre.localisation.map((name) => <span className="text-[12px]">{name}</span>)}
        </div>
        <div className="mb-2 gap-1 flex flex-wrap">
          {offre.id_contract.map((el) => <div className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{el.name}</div>)}
        </div>
        <div className="mb-2 gap-1 flex flex-wrap">
          {offre.tags.filter(x => x.tag.id_tag_level.level === "Type d'intervention").map((el) => <div className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{el.tag.name}</div>)}
        </div>
        <div className="mb-2 gap-1 flex flex-wrap">
          {offre.id_timeexperience.map((el) => <div className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{el.name}</div>)}
        </div>
     
      <div className="flex justify-between items-center">
        <div><p>{offre.postuler ?
          offre.postuler.length >= 1 ?
            offre.postuler.filter((candidature) => candidature.status !== 'refuser').length + " candidats"
            :
            offre.postuler.length + " candidat"
          :
          0 + " candidat"}</p></div>
        <div className="bg-[#2563EB] flex justify-center items-center p-2 rounded cursor-pointer" onClick={() => navigate(`/mon-espace/mes-offres/detail/${offre.id}`)}>
          <span className="text-white"><FaChevronRight /></span>
        </div>
      </div>
      </div>
      {popupArchiver && <PopupArchiver offre={offre} closeArchiver={closeArchiver} fetchOffre={props.fetchOffre} />}
      {popupDearchiver && <PopupDearchiver offre={offre} closeDearchiver={closeDearchiver} fetchOffre={props.fetchOffre} />}
      {popupDelete && <PopupDelete offre={offre} closeDelete={closeDelete} fetchOffre={props.fetchOffre} />}
    </div>
  )
}