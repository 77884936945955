import { Link } from "react-router-dom";

export default function FreelanceCard2() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 items-center border-[4px] rounded-[8px] px-4 py-6 mt-8">
      <div className="col-span-2 font-medium">
        <h1 className="text-[32px]">Vous souhaitez créer votre société et vous lancez à votre compte ?</h1>
        <p className="text-[16px] font-medium mt-4">MyFinPlace vous accompagne lors de vos démarches de création.</p>
        <p className="text-[16px] font-medium mt-4">Mais avant, prenons rendez-vous afin de nous parler de votre projet</p>
        <Link to="/RendezVous" className="bg-[#5B9ECF] hover:bg-[#2D77AD] text-white py-2 px-4 rounded-[5px] inline-block mt-6">Je souhaite créer ma société</Link>
      </div>
      <div className="mt-8 md:mt-0 text-center md:text-right">
        <h1 className="text-[#5B9ECF] text-[48px] my-auto">500 € <span className="text-[36px]">HT</span></h1>
      </div>
    </div>
  )
}