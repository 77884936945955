//Dépendances
import React, { useEffect, useState } from 'react';
//Icon
import { BiMicrophoneOff } from "react-icons/bi";

function VideoDisplay(props) {
  const { remoteParticipants, localParticipant, screenshares } = props.state;
  const { VideoView } = props.components;
  const [ratioViewer, setRatioViewer] = useState(1)

  function ViewForParticipant({ participant, muted = false, display }) {
    const name = participant.displayName.split("|")[1].split(" ");

    return (
      <div className='relative rounded-[25px] border-[5px] border-[#030F30] overflow-hidden' style={{ width: participant.isVideoEnabled ? 'auto' : 615 * ratioViewer, height: 300 * ratioViewer }}>
        {participant.isVideoEnabled ? (
          <VideoView muted={muted} stream={participant.stream} />
        ) : (
          <div className='bg-[#D9D9D9] flex justify-center items-center w-full h-full'>
            <VideoView muted={muted} stream={participant.stream} style={{ display: "none" }} />
            <div className="bg-[#D1E6F4] p-2 font-bold flex justify-center items-center text-[#5A9ECF] rounded-full" style={{ width: 100 * ratioViewer, height: 100 * ratioViewer, fontSize: 38 * ratioViewer }}>
              {name[0][0]}{name[1][0]}
            </div>
          </div>
        )}
        <DisplayName participant={participant} name={display} />
      </div>
    );
  }

  function DisplayName({ participant, name }) {
    return (
      <p className='absolute flex gap-2 bottom-[5px] left-[10px] px-2 py-1 items-center rounded-lg text-center text-white bg-[#141313]' style={{ fontSize: ratioViewer === 1 ? 16 : 12 }}>
        <span>{name}</span>
        {!participant.isAudioEnabled &&
          <BiMicrophoneOff color={"#FF0303"} size={ratioViewer === 1 ? 20 : 15} />
        }
      </p>
    );
  }

  useEffect(() => {
    screenshares.length > 0 ?
      setRatioViewer(0.4)
      :
      setRatioViewer(1)
  }, [screenshares])

  return (
    <div className='flex flex-col justify-center items-center'>
      {//screenshares
        screenshares.map((s) => (
          <div className='w-[70vw] h-auto rounded-[25px] border-[5px] border-[#030F30]'>
            <VideoView stream={s.stream} style={{ height: "auto", Width: "auto", borderRadius: 15 }} />
          </div>
        ))
      }
      <div className='flex flex-wrap justify-center px-6 gap-6 pt-8'>
        {//Remote
          remoteParticipants.map((p) => {
            var displayName = p.displayName.split("|")[1];
            if (p.displayName.startsWith("admin")) {
              displayName += " (administrateur)"
            }
            return (
              <ViewForParticipant participant={p} display={displayName} />
            )
          })
        }
        {//Local
          localParticipant?.stream &&
          <ViewForParticipant participant={localParticipant} muted={true} display={localParticipant.displayName.split("|")[1] + " (vous)"} />
        }
      </div>
    </div>
  )
}

export default VideoDisplay;