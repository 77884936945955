import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import PopupDeleteEspace from "./PopupDeleteEspace";
import axios from "axios";
import cookie from "js-cookie";
import { isClient, isUser } from "utils/roles";
import { AuthContext } from "Context/AuthProvider";
import { AlertMessageContext } from "Context/AlertMessageProvider";
import PrimaryButton from "Components/Buttons/PrimaryButton";
import Eye from "assets/svg/Eye";
import EyeCrossed from "assets/svg/EyeCrossed";
import { ModalContext } from 'Context/ModalProvider';
import DeleteModal from "Modals/DeleteModal";


function Privacy() {
	const { alert } = useContext(AlertMessageContext);
	const { value } = useContext(AuthContext);
	const [isActive, setIsActive] = useState({});
	const [password, setPassword] = useState("");
	const [isEyeCurrent, setisEyeCurrent] = useState(false);
	const [isEyeCrossed, setisEyeCrossed] = useState(false);
	const [isEyeCrossedVerif, setisEyeCrossedVerif] = useState(false);
	const { openModal, close } = useContext(ModalContext);

	const DeleteAccountModalHandler = () => {
		openModal("Suppression de compte", <DeleteModal />)
	}

	// variable pour définir l'input du password comme étant "error" dès le début quand l'input est vide
	const [err, setErr] = useState(true);
	const [showComposants, setShowComposants] = useState(false);

	// variables pour la gestion des conditions de validation du password
	const [lowerValidated, setLowerValidated] = useState(false);
	const [upperValidated, setUpperValidated] = useState(false);
	const [specialValidated, setSpecialValidated] = useState(false);
	const [numberValidated, setNumberValidated] = useState(false);
	const [lengthValidate, setLengthValidate] = useState(false);
	const token = cookie.get("token");
	const {
		register,
		handleSubmit,
		getValues,
		reset,
		formState: { errors },
	} = useForm({
		mode: "onChange",
		defaultValues: {
			"lastPassword": "",
			"newPassword": "",
			"verifPassword": "",
		},
	});

	// Envoie du formulaire
	const onSubmit = async (data) => {
		const type = isUser(value.user) ? "user" : isClient(value.user) ? "client" : null;
		if (type === null) {
			// Si le type n'est pas définie, on ne fait pas de request
			return
		}
		axios.patch(process.env.REACT_APP_API + `${type}/password`,
			JSON.stringify(data),
			{
				headers: {
					"Content-Type": "application/merge-patch+json",
					"Authorization": `Bearer ${token}`
				}
			}
		)
			.then(res => {
				alert.successAlert("Votre mot de passe a été modifié avec succès !");
				reset();
			})
			.catch(err => {
				if (Object.keys(err.response.data).includes("error")) {
					alert.errorAlert(err.response.data.error);
				} else {
					alert.errorAlert("Une erreur est survenue lors de la modification de votre mot de passe !");
				}
			})
	}

	// ckeck password
	// regex
	const lower = new RegExp('(?=.*[a-z])');
	const upper = new RegExp('(?=.*[A-Z])');
	const special = new RegExp('(?=.*[\\W_])');
	const number = new RegExp('(?=.*[0-9])');
	const minLength = new RegExp('(?=.{8,})');

	const handlePassword = (e) => {
		const objectNewContent = e.target.value
		setPassword(objectNewContent);
	}

	// validation erreur mdp
	// taille
	useEffect(() => {
		if (errors.newPassword || password !== "") {
			if (minLength.test(password)) {
				setLengthValidate(true);
			}
			else {
				setLengthValidate(false);
			}
			setErr(false);
		}
		else
			setErr(true);
	}, [password, minLength, errors.newPassword])
	// minuscule
	useEffect(() => {
		if (errors.newPassword || password !== "") {
			if (lower.test(password)) {
				setLowerValidated(true);
			}
			else {
				setLowerValidated(false);
			}
			setErr(false);
		}
		else
			setErr(true);
	}, [password, lower, errors.newPassword, lowerValidated])
	// majuscule
	useEffect(() => {
		if (errors.newPassword || password !== "") {
			if (upper.test(password)) {
				setUpperValidated(true);
			}
			else {
				setUpperValidated(false);
			}
			setErr(false);

		}
		else
			setErr(true);
	}, [password, upper, errors.newPassword, upperValidated])
	// special
	useEffect(() => {
		if (errors.newPassword || password !== "") {
			if (special.test(password)) {
				setSpecialValidated(true);
			}
			else {
				setSpecialValidated(false);
			}
			setErr(false);

		}
		else
			setErr(true);
	}, [password, special, errors.newPassword, specialValidated])
	// number
	useEffect(() => {
		if (errors.newPassword || password !== "") {
			if (number.test(password)) {
				setNumberValidated(true);
			}
			else {
				setNumberValidated(false);
			}
			setErr(false);

		}
		else
			setErr(true);
	}, [password, number, errors.newPassword, numberValidated])

	function toggleEye(input) {
		const inputPassword = document.getElementById(input);
		if (inputPassword.type === "password") {
			inputPassword.type = "text";
			input === "lastPassword" ? setisEyeCurrent(true) : setisEyeCurrent(false)
			input === "newPassword" ? setisEyeCrossed(true) : setisEyeCrossed(false)
			input === "verifPassword" ? setisEyeCrossedVerif(true) : setisEyeCrossedVerif(false)
		} else {
			inputPassword.type = "password";
			input === "lastPassword" ? setisEyeCurrent(false) : setisEyeCurrent(true)
			input === "newPassword" ? setisEyeCrossed(false) : setisEyeCrossed(true)
			input === "verifPassword" ? setisEyeCrossedVerif(false) : setisEyeCrossedVerif(true)
		}
	}
	return (
		<>
			<div className=''>
				<div className='px-4 py-4 gap-4'>
					<div >
						<form className='grid gap-x-6 gap-y-8 grid-cols-6 bg-white p-4' method="post" onSubmit={handleSubmit(onSubmit)}>
							<div className='col-span-6'>
								<label htmlFor="lastPassword" className={`block font-medium leading-6 text-gray-900 `}>Mot de passe actuel</label>
								<div className="relative flex flex-row">
									<input name='lastPassword' id='lastPassword' className={`py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2 ${errors.lastPassword && ""}`} type="password" {...register("lastPassword", { required: true })} />
									<div className="hover:cursor-pointer absolute  right-4 top-2 w-[25px] h-[25px]" onClick={() => {
										toggleEye("lastPassword");
									}}>
										{isEyeCurrent ? (<Eye color={"#5B9ECF"} />) : (<EyeCrossed color={"#5B9ECF"} />)}
									</div>
								</div>
								{errors.lastPassword && (
									<span className="xl:ml-[45px] text-red-500 block">
										Ce champ est requis
									</span>
								)}
							</div>
							<div className='col-span-6'>
								<label htmlFor="newPassword" className='block font-medium leading-6 text-gray-900'>Nouveau mot de passe</label>
								<div className="relative flex flex-row">
									<input name='newPassword' id='newPassword' className={`py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2 ${errors.lastPassword && ""}`} type="password"
										onInput={handlePassword}
										{...register("newPassword", {
											required: "Ce champ est requis",
											pattern: {
												value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-+!*$@%_;.?:,])(?=.*[\W_]).*$/,
												message:
													"Votre mot de passe doit contenir obligatoirement une majuscule, un chiffre et un caractère spécial",
											},
											minLength: {
												value: 8,
												message: "Minimum 8 caractères",
											},
										})}
									/>
									<div className="hover:cursor-pointer absolute  right-4 top-2 w-[25px] h-[25px]" onClick={() => {
										toggleEye("newPassword");
									}}>
										{isEyeCrossed ? (<Eye color={"#5B9ECF"} />) : (<EyeCrossed color={"#5B9ECF"} />)}
									</div>
								</div>
								{errors.newPassword && (
									<span className="xl:ml-[45px] text-red-500 block">
										{errors.newPassword.message}
									</span>
								)}

							</div>
							<ul className="list-none mdpCondition grid grid-cols-2 sm:grid-cols-4 grid-flow-row-dense gap-x-2 gap-y-2 pt-2 col-span-6">
								{!lengthValidate ? (
									<li className="text-[11px] italic">
										<span>{">"}</span> 8 caractères
									</li>
								) : (
									<li className="text-[11px] italic line-through">
										<span>{">"}</span> 8 caractères
									</li>
								)}
								{!upperValidated ? (
									<li className="text-xs italic">1 majuscule</li>
								) : (
									<li className="text-xs italic line-through">
										1 majuscule
									</li>
								)}
								{!lowerValidated ? (
									<li className="text-xs italic">1 minuscule</li>
								) : (
									<li className="text-xs italic line-through">
										1 minuscule
									</li>
								)}
								{!numberValidated ? (
									<li className="text-xs italic">1 chiffre</li>
								) : (
									<li className="text-xs italic line-through">
										1 chiffre
									</li>
								)}
								{!specialValidated ? (
									<li className="text-xs italic col-start-1 col-end-7">
										{`1 caractère spécial (#, -, +, !, $, @, %, _ , ?  , : , ~, <, >, |,)`}
									</li>
								) : (
									<li className="text-xs italic  col-start-1 col-end-7 line-through">
										{`1 caractère spécial (#, -, +, !, $, @, %, _ , ?  , : , ~, <, >, |,)`}
									</li>
								)}
							</ul>
							<div className='col-span-6'>
								<label htmlFor="verifPassword" className='block font-medium leading-6 text-gray-900'>Confirmation du nouveau mot de passe*</label>
								<div className="relative flex flex-row">
									<input name='verifPassword' id='verifPassword' className={`py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2 ${errors.lastPassword && ""}`} type="password"
										{...register("verifPassword", {
											required: "Ce champ est requis",
											validate: (value) =>
												value === getValues("newPassword") ||
												"Les mots de passes ne correspondent pas",
										})}
									/>
									<div className="hover:cursor-pointer absolute  right-4 top-2 w-[25px] h-[25px]" onClick={() => {
										toggleEye("verifPassword");
									}}>
										{isEyeCrossedVerif ? (<Eye color={"#5B9ECF"} />) : (<EyeCrossed color={"#5B9ECF"} />)}
									</div>
								</div>
								{errors.verifPassword && (
									<span className="xl:ml-[45px] text-red-500 block">
										{errors.verifPassword.message}
									</span>
								)}
							</div>
							<div className='col-span-6  gap-4 flex  justify-end'><PrimaryButton text={"Changer mon mot de passe"} /></div>
						</form>
						<div className="text-right bg-white mt-4 p-4">
							<button onClick={DeleteAccountModalHandler} className=" font-medium py-2.5 px-3  rounded-md text-center max-w-[250px] border border-[#FF0000] bg-[#FF0000] text-white hover:bg-white hover:text-[#FF0000] hover:cursor-pointer disabled:bg-[#ADADAD] disabled:cursor-wait disabled:text-white disabled:border-none">
								Supprimer mon compte
							</button>
						</div>
						<PopupDeleteEspace setIsActive={setIsActive} isActive={isActive} />
					</div>
				</div>
			</div>
		</>
	);
}

export default Privacy