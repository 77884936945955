import { Outlet } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import cookie from "js-cookie";
import jwt from "jwt-decode";
import ScrollToTop from "Components/Scroll/ScrollToTop";
import { AuthContext } from "./Context/AuthProvider";
import ModalBase from "Modals/ModalBase";
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Helmet } from "react-helmet";  
import ChatRobot from "Components/ChatBot/ChatRobot";
import { useDispatch,useSelector } from "react-redux";


function App() {
    const { value } = useContext(AuthContext);
    const token = cookie.get("token");
    
    // const token = value.token;
    const [search, setSearch] = useState()
    const [localisation, setLocalisation] = useState()

    const dispatch = useDispatch()




    useEffect(() => {
        if (token) {
            // Vérifier la signature du token JWT


            dispatch({type:'socket/connect'})
            try {
                const decodedToken = jwt(token);
                if (decodedToken.roles && decodedToken.username) {
                     
                    value.setUser(decodedToken);
                    value.setIsLoggedIn(true);
                } else {
                    value.setIsLoggedIn(false);
                    value.setPatchKpi(false);
                    value.setUser();
                }
            } catch (err) {
                console.error(err);
            }
        } else {
            value.setIsLoggedIn(false);
            value.setPatchKpi(false);
            value.setUser({ "roles": "guest" });
        }
    }, []);

    // Pour Search profil
    function handleSearch(event) {
        setSearch(event);
    }
    function handleLocalisation(e) {
        setLocalisation(e);
    }

    return (
        <div className="App">
      <Helmet>  
        <html lang="fr" />  
        <title>MyFinPlace</title>  
        <meta name="description" content="Myfinplace" />  
      </Helmet>  
            <ScrollToTop />
                <Outlet /> 
            <ModalBase />
            <SpeedInsights/>
            <ChatRobot />
        </div>
    );
}

export default App;
