import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { AuthContext } from "../../../Context/AuthProvider"
import { isClient, isUser } from 'utils/roles';
import { LuLoader2 } from "react-icons/lu";
import CardCandidature from "./CardCandidature";
import Homme_recherche_travail from "assets/img/missdata/Homme_recherche_travail.png";



function AppCandidature() {

    const { id } = useParams();
    const { value } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [optionsSelected, setOptionsSelected] = useState(false);
    const [displayApply, setDisplayApply] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const token = cookie.get("token");
    const navigate = useNavigate()


    function handleSelect(event) {
        setOptionsSelected(event.target.value)
    }
    const getCandidatures = () => {
        axios
            .get(process.env.REACT_APP_API + "user/candidature", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {

                if (optionsSelected == "archiver") {
                    setData(response.data.filter(x => x.archiver == 1));
                } else {
                    setData(response.data.filter(x => x.archiver == 0));
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setIsLoading(false)
            });
    }

    useEffect(() => {
        getCandidatures();
    }, [optionsSelected]);


    useEffect(() => {
        setDisplayApply(data.filter(x => x.id === parseInt(id))[0])
    }, [id, data]);

    useEffect(() => {
        if (value.isLoggedIn && isClient(value.user)) {
            navigate("/mon-espace")
        }
    }, [value.user])

    if (isUser(value.user)) {
        return (
            <>
                <div className='flex flex-col p-8 pt-0 mb-8 min-h-[calc(100vh-100px)]'>
                    <div className="flex max-md:flex-col flex-row items-center justify-between my-4">
                        <div>
                            <h2 className='font-bold'>Mes candidatures</h2>
                        </div>
                        <div className="">
                            <select id="Entretien" class="p-2 bg-white border border-middleblue text-middleblue text-sm rounded-lg focus:ring-middleblue focus:border-middleblue block w-max px-2" onChange={handleSelect}>
                                <option disabled selected>Toutes les candidatures en cours</option>
                                <option value="archiver">Candidatures archivées</option>
                                <option value="encours">Candidatures en cours</option>
                            </select>
                        </div>
                    </div>
                    <hr className="mb-4" />
                    {
                        isLoading ?
                            <div className="flex justify-center">
                                <div className="w-[280px] h-[280px] flex justify-center items-center cursor-pointer">
                                    <div className="text-[#2563EB]">
                                        <LuLoader2 size={48} className='animate-spin' />
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                    {
                        data.length > 0 ?
                            <div className="border rounded shadow-md bg-white overflow-y-auto">
                                <table className="w-full divide-y ">
                                    <tr className="bg-greylight text-left">
                                        <th><p className="font-medium p-2 mr-1">Poste</p></th>
                                        <th><p className="font-medium p-2 mr-1">Secteur d'activité</p></th>
                                        <th><p className="font-medium p-2 mr-1">Localisation</p></th>
                                        <th><p className="font-medium p-2 mr-1 whitespace-nowrap">Date de candidature</p></th>
                                        <th><p className="font-medium p-2 mr-1">Statut</p></th>
                                        <th><p className="font-medium p-2 mr-1"></p></th>
                                    </tr>
                                    {
                                        data.map((el, key) => (
                                            <CardCandidature el={el} key={key} getCandidatures={getCandidatures} />
                                        ))
                                    }
                                </table>
                            </div>
                            :
                            <div className="flex flex-col items-center justify-around mt-16 my-2">
                                <img className="w-auto max-h-[250px] opacity-70" src={Homme_recherche_travail} alt="MyFinPlace.com" />
                                <div >
                                    <h3 className=" px-2 mx-2 font-bold">Aucune candidature pour le moment</h3>
                                </div>
                            </div>
                    }
                </div>
            </>
        )
    }
}

export default AppCandidature