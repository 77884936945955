
import { IoChevronDownOutline } from "react-icons/io5";

import inscription1 from "assets/img/Hub/Card3/Entretien 1.png";
import inscription2 from "assets/img/Hub/Card3/Entretien2.png";
import inscription3 from "assets/img/Hub/Card3/Entretien3.png";
import inscription4 from "assets/img/Hub/Card3/Entretien4.png";
import inscription5 from "assets/img/Hub/Card3/Entretien5.png";
export default function Card3({ isActive, setActive }) {
  return (
    <div className="border rounded-[8px] border-[#2632384D] border-[4px] py-4 px-6 mt-16">
      <h1 onClick={() => setActive({ Question3: !isActive.Question3 })} className="font-semibold text-[#263238] cursor-pointer flex gap-2  "  >
        <div className="flex justify-between w-full items-center">
          <p className="text-[32px] text-black font-bold">Quelles sont les étapes de la programmation d’un <span className="text-[#5B9ECF]">entretien</span> ?</p>
          <div className={isActive.Question3 ? "rotate-180 transition duration-500" : "transition duration-500"}>
            <IoChevronDownOutline className="text-[#5B9ECF]" />
          </div>
        </div>
      </h1>
      <div className={isActive.Question3 ? "animation-box-visible" : "animation-box-hidden "} id="box1">
        <div className="pr-5 text-justify animation-content" id="content2">
          <p className="text-[24px] text-black font-bold py-12">En tant que  <span className="text-[#5B9ECF]">client</span>, vous pouvez rencontrer un profil de deux façons possibles :</p>
          <div className="flex flex-row mt-16 items-center justify-between ">
            <div>
              <h3>. En choisissant de rencontrer un profil directement sur sa page profil, accessible depuis la page “Les profils”.</h3>
              <br />
            </div>
            <div className="contents">
              <img className="w-1/3" src={inscription1} alt="" />
            </div>
          </div>
          <h1>OU</h1>
          <div className="flex flex-row  items-center justify-between">
            <div>
              <h3>. En choisissant de rencontrer un profil qui a postulé à une de vos opportunités. Pour cela, rendez-vous dans votre espace personnel, cliquez sur l’onglet “Mes opportunités” puis sur l’opportunité souhaitée. Vous trouverez à droite de votre écran la liste de l’ensemble des profils ayant postulé. Vous pourrez les analyser et proposer une recontre aux profils les plus pertinents.</h3>
              <br />
            </div>
            <div className="contents">
              <img className="w-1/3" src={inscription2} alt="" />
            </div>
          </div>
          <p className="text-[24px] my-12 text-black font-bold py-12">En tant que  <span className="text-[#5B9ECF]">profil</span>,  vous ne pouvez pas rencontrer directectement un client.</p>
          <div className="flex flex-row  items-center justify-between ">
            <div>
              <h3>. Seul le client peut vous renconter lorsque vous êtes visible sur la page “Les opportunités”</h3>
              <h1 className="py-8">OU</h1>
              <h3>. Lorsque vous avez postulé à une opportunité.</h3>
              <br />
            </div>
            <div className="contents">
              <img className="w-1/3" src={inscription3} alt="" />
            </div>
          </div>

          <div className="flex flex-row  items-center justify-between">
            <div>
            <h3>. Une fois la proposition faite par le client, vous avez la possibilité d’accepter, de refuser ou de reprogrammer l’entretien selon vos disponibilités.</h3>
              <br />
            </div>
            <div className="contents">
              <img className="w-1/3" src={inscription4} alt="" />
            </div>
          </div>
          <div className="flex flex-row  items-center justify-between">
            <div>
            <h3>. Lorsque le client et le profil ont confirmé la date de l’entretien, ils reçoivent tous les deux un mail de confirmation avec le lien pour accéder à la visioconférence ainsi que l’identité de la personne qu’ils vont rencontrer. Ils pourront également accéder à ces informations via leur espace personnel.</h3>
              <br />
              <h3 className="font-semibold">NB : L’accès à la visioconférence ne peut se faire que 15 minutes avant l’heure prévue de l’entretien.</h3>

            </div>
            <div className="contents">
              <img className="w-1/3" src={inscription5} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}