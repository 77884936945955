import { Svg, Path } from '@react-pdf/renderer';

const TempsPlein = () => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="#263238"
    xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M16.24 7.75A5.974 5.974 0 0 0 12 5.99v6l-4.24 4.24c2.34 2.34 6.14 2.34 8.49 0a5.99 5.99 0 0 0-.01-8.48zM12 1.99c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
      fill="black" />
  </Svg>
)

export default TempsPlein