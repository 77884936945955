import { useEffect, useState } from "react";

function MonthPicker({ setValue, labelValue, idInput }) {
  const [yearSelected, setYearSelected] = useState(null)
  const input = document.getElementById(idInput);
  const todayYear = new Date().getFullYear()
  const defaultYear = input ? input.value.slice(3, 7) ? input.value.slice(3, 7) : todayYear : todayYear
  const options = [];
  var i = 1980
  while (i <= todayYear + 3) {
    options.push(
      <option value={i} selected={i === parseInt(defaultYear)}>{i}</option>
    );
    i++;
  }
  options.reverse()

  useEffect(() => {
    setYearSelected(defaultYear)
  }, [defaultYear])

  const months = [
    { label: "Janvier", value: "01" },
    { label: "Février", value: "02" },
    { label: "Mars", value: "03" },
    { label: "Avril", value: "04" },
    { label: "Mai", value: "05" },
    { label: "Juin", value: "06" },
    { label: "Juillet", value: "07" },
    { label: "Août", value: "08" },
    { label: "Septembre", value: "09" },
    { label: "Octobre", value: "10" },
    { label: "Novembre", value: "11" },
    { label: "Décembre", value: "12" },
  ]

  function handleClick(month) {
    setValue(labelValue, month.value + "/" + yearSelected)
    document.getElementById('root').click();
  }

  return (
    <div className="relative">
      <div className="z-10 absolute right-0 w-[250px] bg-white border border-lightblue rounded-md p-2">
        <select className="w-full bg-white py-[10px] px-[10px] border-[1.5px] border-lightblue rounded" onChange={(e) => setYearSelected(e.target.value)}>
          {options}
        </select>
        <div className="flex flex-wrap justify-center mt-2">
          {months.map(month => {
            return <span onClick={() => handleClick(month)}
              className="hover:bg-[#EFF5FA] px-2 py-1 hover:cursor-pointer rounded">
              {month.label}
            </span>
          })}
        </div>
      </div>
    </div>
  )
}

function HandleShowMonth(id) {
  function hideShow(event) {
    if (CiCalendar.contains(event.target)) {
      MonthPicker.className = "";
    } else {
      this.removeEventListener('click', hideShow);
      MonthPicker.className = "hidden";
    }
  }
  const CiCalendar = document.getElementById('CiCalendar' + id);
  const MonthPicker = document.getElementById('MonthPicker' + id);
  document.addEventListener('click', hideShow);
}

export { MonthPicker, HandleShowMonth }