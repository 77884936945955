import React, { useState } from 'react'
import QuestionTop from "./Components/QuestionTop";
import QuestionBottom from "./Components/QuestionBottom";
import { Helmet } from "react-helmet";  

import Vectors from 'assets/svg/Vectors'
import Arrow from 'assets/svg/Arrow'
import At from 'assets/svg/At'




function Faq() {

    return (

        <section className="py-20">
            <Helmet>  
<title>MyFinPlace - FAQ  </title>  
</Helmet>  
            <article className="w-fit mx-auto">
                <div className="relative flex justify-center z-20 mr-10">
                    <Vectors classProps={"-mt-3.5 -mr-1"} />
                    <h1 className="font-medium text-4xl robotoSlab">Questions fréquemment posées</h1>
                </div>
            </article>

            <article>
                {/* <div>
                    <QuestionTop />
                </div> */}
                <div>
                    <QuestionBottom />
                </div>
            </article>

            <article className='mt-24 w-fit mx-auto'>
                <p className=' font-medium xl:ml-1 ml-4'><i>Si vous ne trouvez pas la réponse à votre question</i></p>
                <div className='mt-5'>
                    <div className='flex items-center justify-center gap-4'>
                        <div className="text-[#5B9ECF] h-6 w-6">
                            <At />
                        </div>
                        <a className=' font-medium' href="mailto: contact@myfinplace.com"><i>contact@myfinplace.com</i></a>
                    </div>
                    {/* <div > */}
                    <Arrow classProps={'ml-auto -mt-8'} />
                    {/* </div> */}
                </div>
            </article>

        </section>
    )
}

export default Faq