import TagLevel from 'Components/TagLevel/TagLevel'
import React from 'react'

export default function SkillsContainer({ title, skills, type }) {
    return (
        <div className="bg-white border p-4 rounded">
            <p className="font-medium mb-2">{title}</p>
            <div className="flex flex-wrap gap-2">
                {
                    skills ?
                        skills?.map((skill, key) => (
                            <TagLevel name={skill.name} level={skill.level} type={type} key={key} />
                        ))
                        :
                        null
                }
            </div>
        </div>
    )
}
