import React from "react";
import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext } from "Context/AuthProvider";
import axios from "axios";
import Cookies from "js-cookie";

import { FaCalendarAlt } from "react-icons/fa";
import { GiPositionMarker } from "react-icons/gi";
import { FaCheck } from "react-icons/fa";
import { PiBuildingsFill } from "react-icons/pi";
import { AiFillEuroCircle } from "react-icons/ai";
import TagLevel from "Components/TagLevel/TagLevel";

export default function OffreCard({ card, setHistoryId, historyId, consultHistory, isComptable }) {
    const navigate = useNavigate()
    const { id } = useParams()
    const { value } = useContext(AuthContext);
    const tokenId = Cookies.get("id");
    const options = { year: "numeric", month: "long" };
    var date;
    if (card.date_modify === null) {
        date = new Date(card.date_creation.date);
    } else {
        date = new Date(card.date_modify.date);
    }
    const limitNew = new Date();
    limitNew.setDate(limitNew.getDate() - 7);

    const contracts = card.contract.map((contract, i) => {
        return <small className="bg-greylight p-2" key={i}> {contract.name}</small>;
    });

    const selectCard = (id) => {
        // navigate(`/les-opportunites/${id}`)
        window.history.replaceState(window.history.state, '', `/les-opportunites/${id}`)
        setHistoryId(id)
        if (window.innerWidth <= 1280) {
            window.open(`/offre/${id}`, '_blank');
        }
    }

    function colorContrat(contrat) {
        // const colorContrat = { "Stage": "#B3D8F2", "Alternance": "#B3D8F2", "CDD": "#B3E7FC", "CDI": "#B3E7FC", "Mission": "#7CC8FF" }
        switch (contrat) {
            case "Stage":
                return "bg-[#B3D8F2]"
            case "Alternance":
                return "bg-[#B3D8F2]"
            case "CDD":
                return "bg-[#B3E7FC]"
            case "CDI":
                return "bg-[#B3E7FC]"
            case "Mission":
                return "bg-[#7CC8FF]"
                      case "Portage salarial":
                return "bg-[#7CC8FF]"
            default:
                break;
        }
    }

    return (
        <div className={`${historyId === null ? id == card.id ? "bg-[#EBF4FF]" : "bg-white" : historyId == card.id ? "bg-[#EBF4FF]" : "bg-white"} rounded p-4 hover:bg-[#EBF4FF] cursor-pointer`} onClick={() => selectCard(card.id)}>
            <div className="flex justify-between items-center">
                <p className="text-[#5B9ECF] text-[16px] font-bold mb-2">{card.intitule}</p>
                {new Date(card.date_creation.date) > limitNew &&
                    <div className="bg-[#5B9ECF] text-[12px] text-white px-4 py-1 rounded-full">New</div>}
            </div>
            <div className="flex gap-2 items-center mb-4">
                <p>Secteur</p>
                {/* <TagLevel name={card.id_tags.secteur[0]?.name} level={card.id_tags.secteur[0]?.level} type={"sectorielle"} /> */}
                {/* <small className="text-[12px] px-4 py-2 bg-sectoriellepastel text-sectorielledark">{card.id_tags.secteur[0]?.name}</small> */}
                <div className={`flex flex-col gap-1 px-4 py-2 rounded bg-sectoriellepastel`}>
                    <span className={`text-[12px] text-sectorielledark`}>{card.id_tags.secteur[0]?.name}</span>
                    <div className={`flex flex-row gap-[4px]`}>
                        <hr className={`w-[14px] h-[2px] font-medium ${card.id_tags.secteur[0]?.level >= 1 ? "bg-sectoriellelevel" : "bg-[#D9D9D9]"}`} />
                        <hr className={`w-[14px] h-[2px] font-medium ${card.id_tags.secteur[0]?.level >= 2 ? "bg-sectoriellelevel" : "bg-[#D9D9D9]"}`} />
                        <hr className={`w-[14px] h-[2px] font-medium ${card.id_tags.secteur[0]?.level >= 3 ? "bg-sectoriellelevel" : "bg-[#D9D9D9]"}`} />
                        <hr className={`w-[14px] h-[2px] font-medium ${card.id_tags.secteur[0]?.level >= 4 ? "bg-sectoriellelevel" : "bg-[#D9D9D9]"}`} />
                    </div>
                </div>

            </div>
            <div className="flex gap-2 items-center mb-4">
                <p>Type de contrat recherché</p>
                <div className="flex flex-wrap gap-2.5">
                    {card.contract.length > 0 ? card.contract.map((experience, key) => {
                        if (experience.name === "Mission" && isComptable) {
                            return
                        }
                        return (
                            <small key={key} className={colorContrat(experience.name) + ` rounded py-[5.5px] px-3.5`}>
                                {experience.name}
                            </small>
                        );
                    })
                        : <small key={"contract_empty"} className={`bg-greylight rounded py-[5.5px] px-3.5`}>
                            Non indiqué
                        </small>}
                </div>
            </div>
            <div className="flex gap-2 items-center mb-4">
                <p>Compétences</p>
                <div className="flex flex-wrap gap-2.5">
                    {card.id_tags.fonctionnelle.length > 0 ? card.id_tags.fonctionnelle.map((fonctionnelle, key) => {
                        return (
                            <small key={key} className="bg-fonctionnellepastel text-fonctionnelledark rounded py-[5.5px] px-3.5">
                                {fonctionnelle.name}
                            </small>
                        );
                    })
                        : <small key={"fonctionnelle_empty"} className={`bg-greylight rounded py-[5.5px] px-3.5`}>
                            Non indiqué
                        </small>}
                </div>
            </div>
            <div className="flex gap-2 items-center mb-4">
                <PiBuildingsFill size={17} className="text-[#497EA6]" />
                <div className="flex flex-wrap gap-2.5">
                    {card.timeExperience.length > 0 ? card.timeExperience.map((timeExperience, key) => {
                        return (
                            <small key={key} className="bg-greylight rounded py-[5.5px] px-3.5">
                                {timeExperience.name}
                            </small>
                        );
                    })
                        : <small key={"timeExperience_empty"} className={`bg-greylight rounded py-[5.5px] px-3.5`}>
                            Non indiqué
                        </small>}
                </div>
            </div>
            <div className="flex justify-between items-center gap-4">
                <div className="flex items-center gap-1">
                    <AiFillEuroCircle className="text-[#497EA6]" />
                    <small>{card.salaire ? card.salaire + " €" : "Non indiqué"}</small>
                </div>
                <div className="flex items-center gap-1">
                    <FaCalendarAlt className="text-[#497EA6]" />
                    <small>{date.toLocaleDateString("fr-FR", options)}</small>
                </div>
                <div className="flex items-center gap-1">
                    <GiPositionMarker className="text-[#497EA6]" />
                    <small>{card.localisation.length ? card.localisation[0] : "Non indiqué"}</small>
                </div>
                {consultHistory && consultHistory.includes(card.id) &&
                    <div className="flex items-center gap-2 text-[12px] text-[#37B47A]">
                        <FaCheck size={17} />
                        Vue
                    </div>}
            </div>
        </div>
    );
}
