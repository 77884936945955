import React from 'react'
import parse from "html-react-parser";
import Pen from "assets/svg/Pen";
import Trash from "assets/svg/Trash";

function CardFormation({ formation, selectFormation, deleteFormation }) {
    return (

        <div className='bg-greylight rounded '>
            <div className='flex justify-between p-4 items-center '>
                <div>
                    <h3>{formation.school}</h3>
                    <p>{formation.date_start} - {formation.date_end}</p>
                    <h5>{formation.diplome}</h5>
                </div>
                <div className='flex gap-6'>
                    <p className='cursor-pointer' onClick={() => selectFormation(formation.id)}><Pen /></p>
                    <p className='cursor-pointer' onClick={() => deleteFormation(formation.id)}><Trash /></p>
                </div>
            </div>
            <div className='px-4 mb-4'>
                <h5 className='break-words'>{parse(formation.description)}</h5>
            </div>
        </div>
    )
}

export default CardFormation