import { useEffect, useState } from 'react'
import { FaBuilding } from "react-icons/fa";
import parse from "html-react-parser";
import { IoIosMore } from "react-icons/io";
import { fr } from 'date-fns/locale'
const { intervalToDuration } = require("date-fns");

function TabExperiencesCard({ experience }) {

    const [experienceDetailOpen, setExperienceDetailOpen] = useState(false)

    const detailHandler = () => {
        setExperienceDetailOpen(!experienceDetailOpen)
    }


    const dateFormatter = (experience) => {
        return experience.in_progress
            ? new Date(experience.date_start).toLocaleDateString(
                "fr-FR",
                { month: "long", year: "numeric" }
            ) + " - Aujourd'hui"
            : new Date(experience.date_start).toLocaleDateString(
                "fr-FR",
                { month: "long", year: "numeric" }
            ) ==
                new Date(experience.date_end).toLocaleDateString(
                    "fr-FR",
                    { month: "long", year: "numeric" }
                )
                ? new Date(experience.date_start).toLocaleDateString(
                    "fr-FR",
                    { month: "long", year: "numeric" }
                )
                : new Date(experience.date_start).toLocaleDateString(
                    "fr-FR",
                    { month: "long", year: "numeric" }
                ) +
                " - " +
                new Date(experience.date_end).toLocaleDateString(
                    "fr-FR",
                    { month: "long", year: "numeric" }
                )
    }

    function calcTime(experience) {
        var intVal = {}
        if (experience.in_progress) {
            // Expérience jusqu'à aujourd'hui
            intVal = intervalToDuration({ start: new Date(), end: new Date(experience.date_start) })
        } else {
            // Expérience terminé
            intVal = intervalToDuration({ start: new Date(experience.date_end), end: new Date(experience.date_start) })
        }
        var timeStr = intVal.years !== 0 ? intVal.years + " ans" : "";
        timeStr += intVal.years !== 0 && intVal.months !== 0 ? " et " : "";
        timeStr += intVal.months !== 0 ? intVal.months + " mois" : "";
        if (intVal.years === 0 && intVal.months === 0) {
            timeStr = intVal.days + " jours";
        }
        return timeStr;
    }


    return (
        <div className="border rounded p-2">
            <div className="flex items-start gap-2">
                <span className="text-black text-[30px] bg-[#EBF4FF] p-4 rounded-full"><FaBuilding /></span>
                <div>
                    <p className="font-bold text-[#5B9ECF] text-[24px]">{experience.name}</p>
                    <p className="font-medium">{experience.poste}</p>
                    <p className="">{dateFormatter(experience)}
                        <span className='ml-2 text-[12px] italic'>({calcTime(experience)})</span></p>
                    {
                        experienceDetailOpen ?
                            <p className='mt-2 break-word'>{parse(experience.description)}</p>
                            :
                            null
                    }
                </div>

            </div>
            <div className='cursor-pointer flex justify-center text-[1.8rem] rounded' onClick={detailHandler}><IoIosMore className='text-[#497EA6]' /></div>
        </div>
    )
}

export default TabExperiencesCard