// import { Link } from "react-router-dom";
import { useState, useEffect, useContext } from 'react'
// import LastOffres from './Components/Carousel/LastOffres'
// import LastProfils from './Components/Carousel/LastProfils'

import Banniere from './Components/Banniere'
import PremiereMarket from './Components/PremiereMarket'
import Expertises from './Components/Expertises'
import SimpleUtilisation from './Components/SimpleUtilisation'
import PourquoiMFP from './Components/PourquoiMFP'
import Retours from './Components/Retours'
import Partenaire from './Components/Partenaires'

export default function Home(props) {

    // function handleSearch(event) {
    //     props.onSearchHome(event)
    // }

    // function handleLocalisation(e) {
    //     props.onSearchLocalisation(e)
    // }
    return (
        <>
            <div className='bg-white'>
                <div className="containerxl">
                    {/* -- Banniere --*/}
                    <div className='pt-8 mb-9'>
                        <Banniere />
                    </div>
                    {/* -- Premièr  Hub -- */}
                    <div className='mb-[185px]'>
                        <PremiereMarket />
                    </div>

                    {/* -- Les Domaines d'expertise -- */}
                    <div className='mb-9'>
                        <Expertises />
                    </div>
                </div>

                <div className='bg-greylight mb-12'>
                    <SimpleUtilisation />
                </div>

                <div className='containerxl'>

                    {/* <!-- Block pourquoi choisir myfinplace --> */}
                    <div className='mb-[185px]'>
                        <PourquoiMFP />
                    </div>

                    {/* <!-- Retours d'expérience --> */}
                    <div className="hidden md:block mb-[100px]">
                        <Retours />
                    </div>

                    {/* <!-- Block partenaire --> */}

                    <div className="">
                        <Partenaire />
                    </div>
                    {/* </div> */}
                </div>

                </div>


        </>
    );
}