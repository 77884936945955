import React, { useState, useEffect, useContext } from "react";
import Vector from "assets/svg/Vector";
import { CheckboxContext } from "Pages/Statics/LesOpportunite/Context/CheckboxProvider";



export default function FiltreCompetences(props) {
    const { checkBoxValues, setCheckboxes } = useContext(CheckboxContext);
    const [isActive, setActive] = useState({});
    const [data, setData] = useState([])
    const [searchFieldSecteur, setSearchFieldSecteur] = useState("");
    const [searchFieldFonctionnelles, setSearchFieldFonctionnelles] = useState("");
    const [searchFieldInformatiques, setSearchFieldInformatiques] = useState("");
    const [searchFieldLinguistiques, setSearchFieldLinguistiques] = useState("");

    const removeAccents = str => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

    useEffect(() => {
        props.onField({ data: data, type: "competence" })
    }, [data]);

    useEffect(() => {
        setData([])
    }, [props.empty]);


    function handleData(e, type) {
        let filterSelected = checkBoxValues.checkboxes[type]
        if (e.target.checked) {
            filterSelected.push(+e.target.value)

            setCheckboxes(prevData => ({
                ...prevData,
                [type]: filterSelected
            }))
        } else {
            let newFilter = filterSelected.filter(t => t !== +e.target.value)
            setCheckboxes(prevData => ({
                ...prevData,
                [type]: newFilter
            }))
        }
        setSearchFieldSecteur("")
        setSearchFieldFonctionnelles("")
        setSearchFieldInformatiques("")
        setSearchFieldLinguistiques("")
    }

    function DisplayLevelCompetence({ text, level }) {
        return (
            <label className="flex gap-2" key={`levelCompetence${level}`}>
                <input onChange={(e) => handleData(e, "levelCompetence")}
                    type="checkbox" className="border-8"
                    value={level}
                    name="levelCompetence"
                    checked={checkBoxValues.checkboxes.levelCompetence.includes(level)}
                />
                {text}
            </label>
        )
    }

    return (
        <main className="py-10 border-b space-y-6 ">

            <h6 className="font-medium underline">Compétences requises</h6>

            <div>
                <p>Niveaux des compétences</p>
                <div className="grid grid-cols-2 mt-1">
                    <DisplayLevelCompetence text={"Débutant"} level={1} />
                    <DisplayLevelCompetence text={"Intermédiaire"} level={2} />
                    <DisplayLevelCompetence text={"Confirmé"} level={3} />
                    <DisplayLevelCompetence text={"Expert"} level={4} />
                </div>
            </div>

            {/*~~~~~~~~~~~~~~~~~~~~ filtre Compétences sectorielles ~~~~~~~~~~~~~~~~~~~~~~~~~~~*/}
            <section className="">
                <div className="flex items-center justify-between"
                    onClick={() => setActive({ sectorielles: !isActive.sectorielles })} >
                    <p>Compétences sectorielles</p>
                    <div id="plus1">
                        <Vector classProps={isActive.sectorielles ? "rotate-180 transition duration-700" : "transition duration-700"} alt="+" />
                    </div>
                </div>
                <article className={`${isActive.sectorielles ? "animation-box-visible" : "animation-box-hidden"} `}>

                    <div className="animation-content ">
                        <div className='mr-2 my-2 ml-0.5'>
                            <input placeholder='Recherche' name='recherche' id='recherche' className='p-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" value={searchFieldSecteur} onChange={(e) => setSearchFieldSecteur(e.target.value)} />
                        </div>
                        {/* ici ce sera un map */}
                        {props.field.secteur ?
                            props.field.secteur.filter(x => removeAccents(x.name.toLowerCase().replace(/\/ /g, '')).includes(removeAccents(searchFieldSecteur.toLowerCase().replace(/\/ /g, '')))).map((e) => (
                                <label className="flex gap-3.5 mt-3" key={e.id} >
                                    <input onChange={(e) => handleData(e, "tag")}
                                        type="checkbox"
                                        className="border-8"
                                        value={e.id}
                                        checked={checkBoxValues.checkboxes.tag.includes(e.id)}
                                    />
                                    {e.name}
                                </label>
                            ))
                            :
                            null
                        }
                    </div>
                </article>
            </section>

            {/*~~~~~~~~~~~~~~~~~~~~ filtre Compétences fonctionelles ~~~~~~~~~~~~~~~~~~~~~~~~~~~*/}
            <section className="">
                <div className="flex items-center justify-between"
                    onClick={() => setActive({ fonctionelles: !isActive.fonctionelles })} >
                    <p>Compétences fonctionnelles</p>
                    <div id="plus1">
                        <Vector classProps={isActive.fonctionelles ? "rotate-180 transition duration-700" : "transition duration-700"} alt="+" />
                    </div>
                </div>
                <article className={`${isActive.fonctionelles ? "animation-box-visible" : "animation-box-hidden"} `}>
                    <div className="animation-content ">
                        <div className='mr-2 my-2 ml-0.5'>
                            <input placeholder='Recherche' name='recherche' id='recherche' className='p-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" value={searchFieldFonctionnelles} onChange={(e) => setSearchFieldFonctionnelles(e.target.value)} />
                        </div>
                        {/* ici ce sera un map */}
                        {props.field.fonctionnelle ?
                            props.field.fonctionnelle.filter(x => removeAccents(x.name.toLowerCase().replace(/\/ /g, '')).includes(removeAccents(searchFieldFonctionnelles.toLowerCase().replace(/\/ /g, '')))).map((e) => (
                                <label className="flex gap-3.5 mt-3" key={e.id} >
                                    <input onChange={(e) => handleData(e, "tag")}
                                        type="checkbox"
                                        className="border-8"
                                        value={e.id}
                                        checked={checkBoxValues.checkboxes.tag.includes(e.id)}
                                    />
                                    {e.name}
                                </label>
                            ))
                            :
                            null
                        }
                    </div>
                </article>
            </section>

            {/*~~~~~~~~~~~~~~~~~~~~ filtre Compétences informatiques ~~~~~~~~~~~~~~~~~~~~~~~~~~~*/}
            <section className="">
                <div className="flex items-center justify-between"
                    onClick={() => setActive({ informatiques: !isActive.informatiques })} >
                    <p>Compétences informatiques</p>
                    <div id="plus1">
                        <Vector classProps={isActive.informatiques ? "rotate-180 transition duration-700" : "transition duration-700"} alt="+" />
                    </div>
                </div>
                <article className={`${isActive.informatiques ? "animation-box-visible" : "animation-box-hidden"} `}>
                    <div className="animation-content ">
                        <div className='mr-2 my-2 ml-0.5'>
                            <input placeholder='Recherche' name='recherche' id='recherche' className='p-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" value={searchFieldInformatiques} onChange={(e) => setSearchFieldInformatiques(e.target.value)} />
                        </div>
                        {/* ici ce sera un map */}
                        {props.field.informatique ?
                            props.field.informatique.filter(x => removeAccents(x.name.toLowerCase().replace(/\/ /g, '')).includes(removeAccents(searchFieldInformatiques.toLowerCase().replace(/\/ /g, '')))).map((e) => (
                                <label className="flex gap-3.5 mt-3" key={e.id} >
                                    <input onChange={(e) => handleData(e, "tag")}
                                        type="checkbox"
                                        className="border-8"
                                        value={e.id}
                                        checked={checkBoxValues.checkboxes.tag.includes(e.id)}
                                    />
                                    {e.name}
                                </label>
                            ))
                            :
                            null
                        }
                    </div>
                </article>
            </section>

            {/*~~~~~~~~~~~~~~~~~~~~ filtre Compétences linguistiques ~~~~~~~~~~~~~~~~~~~~~~~~~~~*/}
            <section className="">
                <div className="flex items-center justify-between"
                    onClick={() => setActive({ linguistiques: !isActive.linguistiques })} >
                    <p>Compétences linguistiques</p>
                    <div id="plus1">
                        <Vector classProps={isActive.linguistiques ? "rotate-180 transition duration-700" : "transition duration-700"} alt="+" />
                    </div>
                </div>
                <article className={`${isActive.linguistiques ? "animation-box-visible" : "animation-box-hidden"} `}>
                    <div className="animation-content ">
                        <div className='mr-2 my-2 ml-0.5'>
                            <input placeholder='Recherche' name='recherche' id='recherche' className='p-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" value={searchFieldLinguistiques} onChange={(e) => setSearchFieldLinguistiques(e.target.value)} />
                        </div>
                        {/* ici ce sera un map */}
                        {props.field.linguistique ?
                            props.field.linguistique.filter(x => removeAccents(x.name.toLowerCase().replace(/\/ /g, '')).includes(removeAccents(searchFieldLinguistiques.toLowerCase().replace(/\/ /g, '')))).map((e) => (
                                <label className="flex gap-3.5 mt-3" key={e.id} >
                                    <input onChange={(e) => handleData(e, "tag")}
                                        type="checkbox"
                                        className="border-8"
                                        value={e.id}
                                        checked={checkBoxValues.checkboxes.tag.includes(e.id)}
                                    />
                                    {e.name}
                                </label>
                            ))
                            :
                            null
                        }
                    </div>
                </article>
            </section>

        </main>
    );
}