import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Planifier from "./StatusCaseAppoitment/Planifier";
import Programmes from "./StatusCaseAppoitment/Programmes";
import Realiser from "./StatusCaseAppoitment/Realiser";
import Delete from "./StatusCaseAppoitment/Delete";
import { FaHandshakeSimple } from "react-icons/fa6";
import { FaHandshakeSimpleSlash } from "react-icons/fa6";
import { add, differenceInDays, endOfMonth, format, setDate, startOfMonth, sub } from "date-fns";
import { fr } from 'date-fns/locale'

function Cell(props) {
  const [data, setData] = useState();
  const [isActiveStatusCell, setIsActiveStatusCell] = useState(false)
  const [objectIcon, setObjectIcon] = useState({})

  useEffect(() => {
    if (props.eventCalendar) {
      var listIcon = { "programmés": false, "réaliser": false, "planifier": false, "cancel": false, "archiver": false, "archiver_win": false }
      props.eventCalendar.map((x) => {
        if (format(Date.parse(x.date_choice), "yyyy LLLL d", { locale: fr }) == props.years + " " + props.month + " " + props.day) {
          listIcon[x.status] = true;
        }
      })
      setObjectIcon(listIcon);
    }
  }, [props.eventCalendar]);

  return (
    <div onClick={props.isActive ? undefined : props.onClick} className={clsx(
      'h-14 flex flex-col items-center justify-start m-1  ',
      { "border-gray-200 border border-2 border-t-0 rounded": props.isStatus && !undefined },
      { "border-middleblue  !bg-white ": props.isStatus && props.isActive },
      { "text-white bg-middleblue rounded ": props.isActive },
      { "hover:bg-middleblue hover:text-white hover:rounded": !!(!props.isActive && props.onClick) }, props.className)}>


      <div className={clsx("",
        { " text-white bg-middleblue  rounded gap-2  w-full justify-start": props.isStatus },
        { " text-white bg-middleblue   rounded-none gap-2  w-full justify-start": props.isStatus && props.isActive }, props.className)}>
        {props.children}
      </div>

      <div className={clsx("flex flex-row gap-0.5 h-4 p-1  hidden",
        { "!flex": props.isStatus },
      )}>
        {/* condition affichage comparaison pour le status de lanner jours et mois */}
        {objectIcon.planifier && <Planifier />}
        {objectIcon.programmés && <Programmes />}
        {objectIcon.réaliser && <Realiser />}
        {objectIcon.cancel && <Delete />}
        {objectIcon.archiver && <span className="text-[#FF0000]"><FaHandshakeSimpleSlash /></span>}
        {objectIcon.archiver_win && <span className="text-[#00C455]"><FaHandshakeSimple /></span>}
      </div>
    </div>
  )
}
export default Cell;