import { NavLink } from 'react-router-dom';

function NavItem({ href, icon = null, text,bgColor = "#F3F7FF",color = "#2563EB",setMenuIsOpen}) {

    return (
        <NavLink to={href} onClick={() => {setMenuIsOpen(false)}}>
            {({ isActive }) => (
                <div className={`${isActive ? `bg-[${bgColor}] text-[${color}] font-bold` : "text-[#9C9B9F]"} p-2 rounded flex gap-2 items-center hover:bg-[${bgColor}] hover:text-[${color}] justify-center sm:justify-start`}>
                    <span>{icon}</span>
                    <span>{text}</span>
                </div>
            )}
        </NavLink>
    )
}




export default NavItem