import React from 'react'

function fleche(props) {
    return (
        <>
            <svg className={props.classProps ? props.classProps : ""} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3333 4.16666C13.3333 4.78499 13.9442 5.70832 14.5625 6.48332C15.3575 7.48332 16.3075 8.35582 17.3967 9.02166C18.2133 9.52082 19.2033 9.99999 20 9.99999M20 9.99999C19.2033 9.99999 18.2125 10.4792 17.3967 10.9783C16.3075 11.645 15.3575 12.5175 14.5625 13.5158C13.9442 14.2917 13.3333 15.2167 13.3333 15.8333M20 9.99999H4.76837e-07"
                 stroke={props.color ? props.color : "#5B9ECF"} />
            </svg>

        </>
    )
}

export default fleche