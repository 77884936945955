import { useState } from "react";
import axios from 'axios';
import cookie from "js-cookie";
import jwtDecode from "jwt-decode";


function RadioNumber({ question, value, id, label, categorie,checked,updateSoftSkill,softSkillHandler }) {

    const token = cookie.get("token");
    const tokenDecode = jwtDecode(token)

    const [isLoading,setIsLoading] = useState(false)

    const changeSoftSkill = async () => {
        if(isLoading) {
            return 
        }
        const data = {
            user:`api/users/${tokenDecode.id}`,
            numberQuestion: parseInt(question.split("question")[1]),
            value: parseInt(value),
            label,
            categorie
        }
        softSkillHandler(data)
        setIsLoading(false)    
    }
    return (

        <>
            <div>
                <input checked={checked} type="radio" onChange={changeSoftSkill} name={question} id={id} value={value} className="peer hidden" />
                <label htmlFor={id} className="block cursor-pointer select-none rounded-xl p-2 text-center peer-checked:bg-darkblue peer-checked:font-bold peer-checked:text-white">{value}</label>
            </div>
        </>
    )
}


export default RadioNumber