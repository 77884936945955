import { useState, useEffect } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import Cookies from 'js-cookie'
import jwt from "jwt-decode";
import PrimaryButton from 'Components/Buttons/PrimaryButton'
import SecondaryButton from 'Components/Buttons/SecondaryButton'
import Accordion from 'Components/Accordion/Accordion'
import { LuLoader2 } from "react-icons/lu";
import Checkbox from 'Components/Forms/CheckBox/Checkbox';
import Badge from 'Components/Badge/Badge';
import TagForCard from 'Components/TagLevel/TagForCard';


export default function Skills() {

    const navigate = useNavigate()
    const next = () => navigate("/parcours-inscription/remuneration")
    const prev = () => navigate("/parcours-inscription/mobilite")
    const decodedToken = jwt(Cookies.get("token"));


    const [data, setData] = useOutletContext()

    const [sectorialSkillsTags, setSectorialSkillsTags] = useState([])
    const [languageSkillsTags, setLanguageSkillsTags] = useState([])
    const [fonctionalSkillsTags, setFonctionalSkillsTags] = useState([])
    const [computerSkillsTags, setComputerSkillsTags] = useState([])
    const [formIsNotValid, setFormIsNotValid] = useState(true)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        formIsValid()
    }, [data.tags])

    const [skillCounter, setSkillCounter] = useState({
        sectorialSkills: 0,
        fonctionalSkills: 0,
        computerSkills: 0,
        languageSkills: 0
    })

    const formIsValid = () => {
        let flag = false;
        if (skillCounter.sectorialSkills === 0 || skillCounter.fonctionalSkills === 0 || skillCounter.computerSkills === 0 || skillCounter.languageSkills === 0) {
            flag = true;
        }

        if (data.tags.filter(item => item.forCard === true).length === 0) {
            flag = true;
        }

        setFormIsNotValid(flag)
    }


    const [skillOpen, setSkillOpen] = useState({
        sectorialSkills: true,
        fonctionalSkills: false,
        computerSkills: false,
        languageSkills: false
    })


    const handleOpen = (key) => {
        let newState = { ...skillOpen };

        for (const clef in newState) {
            if (clef !== key) {
                newState[clef] = false;
            }
        }
        newState[key] = !newState[key]
        setSkillOpen(newState)
    }


    const handleTag = (e) => {
        let newTag = {
            user: `api/users/${decodedToken.id}`,
            tag: `api/tags/${e.target.value}`,
            level: 1
        }
        let newState = {}
        let newCounterState = {}
        if (data.tags.filter(item => item.tag === newTag.tag).length === 0) {

            if (skillCounter[e.target.name] >= 10) {
                return
            }

            newState = {
                ...data,
                tags: [...data.tags, newTag],
            };

            newCounterState = {
                ...skillCounter,
                [e.target.name]: skillCounter[e.target.name] + 1,
            };

        } else {
            newState = {
                ...data,
                tags: data.tags.filter(item => item.tag !== `api/tags/${e.target.value}`),
            }

            newCounterState = {
                ...skillCounter,
                [e.target.name]: skillCounter[e.target.name] - 1,
            };

        }

        setData(newState)
        setSkillCounter(newCounterState)
    }

    function handleLevel(e, level) {
        let newState = { ...data };
        const index = data.tags.findIndex(item => item.tag === `api/tags/${e}`);
        newState.tags[index].level = level;
        setData(newState)
    }

    function handleFavTag(usertag) {
        let newState = { ...data };
        const index = data.tags.findIndex(item => item.tag === usertag.tag);
        if (usertag.forCard) {
            newState.tags[index].forCard = false;
            setData(newState)
        } else {
            if (data.tags.filter(item => item.forCard === true).length < 4) {
                newState.tags[index].forCard = true;
                setData(newState)
            }
        }
        formIsValid()
    }

    const getTags = () => {
        const options = { method: 'GET' };
        fetch(process.env.REACT_APP_API + "filtres", options)
            .then(res => res.json())
            .then(data => {
                setSectorialSkillsTags(data.secteur)
                setFonctionalSkillsTags(data.fonctionnelle)
                setComputerSkillsTags(data.informatique)
                setLanguageSkillsTags(data.linguistique)
            })
            .catch(err => console.log())
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getTags()
    }, [])



    useEffect(() => {

        if (languageSkillsTags.length > 0) {
            let languageCounter = 0
            let functionalCounter = 0
            let computeurSkillCounter = 0
            let sectorialSkillCounter = 0

            data.tags.forEach(tag => {
                sectorialSkillsTags.forEach(skillTag => {
                    if (skillTag.id === parseInt(tag.tag.slice(9))) {
                        sectorialSkillCounter += 1;
                    }
                })

                fonctionalSkillsTags.forEach(skillTag => {
                    if (skillTag.id === parseInt(tag.tag.slice(9))) {
                        functionalCounter += 1;
                    }
                })

                computerSkillsTags.forEach(skillTag => {
                    if (skillTag.id === parseInt(tag.tag.slice(9))) {
                        computeurSkillCounter += 1;
                    }
                })

                languageSkillsTags.forEach(skillTag => {
                    if (skillTag.id === parseInt(tag.tag.slice(9))) {
                        languageCounter += 1;
                    }
                })

                let newState = {
                    sectorialSkills: sectorialSkillCounter,
                    fonctionalSkills: functionalCounter,
                    computerSkills: computeurSkillCounter,
                    languageSkills: languageCounter
                }

                setSkillCounter(newState)
                formIsValid()


            });
        }

    }, [languageSkillsTags])

    return (
        <div className={`flex flex-col my-4 p-6 rounded ${isLoading ? "" : "bg-white"} gap-6`}>
            {
                isLoading ?
                    <div className="text-[#2563EB] mt-4">
                        <LuLoader2 size={48} className='animate-spin' />
                    </div>
                    :
                    <>
                        <div>
                            <h2>Compétences *</h2>
                            <p>Indiquez vos compétences les plus pertinentes</p>
                            <div className='flex flex-row py-4'>
                            <p className='w-fit text-sm text-red-700 px-[5px] rounded-lg'>Sélectionner votre niveau de compétence :</p>
                            <p className='w-fit text-sm text-red-700 px-[5px] rounded-lg'> 1 _ : Connaissance </p>
                            <p className='w-fit text-sm text-red-700 px-[5px] rounded-lg'> 2 _ _  : Pratique </p>
                            <p className='w-fit text-sm text-red-700 px-[5px] rounded-lg'> 3 _ _ _ _ : Maitrise </p>
                            <p className='w-fit text-sm text-red-700 px-[5px] rounded-lg'> 4 _ _ _ _ : Expert </p>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-col'>
                                <Accordion
                                    handleOpen={handleOpen}
                                    open={skillOpen.sectorialSkills}
                                    state={data.tags} onChange={handleTag}
                                    data={sectorialSkillsTags}
                                    title={"Compétences sectorielles"}
                                    name={"sectorialSkills"}
                                    handleLevel={handleLevel}
                                />
                                <Accordion
                                    handleOpen={handleOpen}
                                    open={skillOpen.fonctionalSkills}
                                    state={data.tags} onChange={handleTag}
                                    data={fonctionalSkillsTags}
                                    title={"Compétences fonctionnelles"}
                                    name={"fonctionalSkills"}
                                    handleLevel={handleLevel}
                                />
                                <Accordion
                                    handleOpen={handleOpen}
                                    open={skillOpen.computerSkills}
                                    state={data.tags}
                                    onChange={handleTag}
                                    data={computerSkillsTags}
                                    title={"Compétences informatiques"}
                                    name={"computerSkills"}
                                    handleLevel={handleLevel}
                                />
                                <Accordion
                                    handleOpen={handleOpen}
                                    open={skillOpen.languageSkills}
                                    state={data.tags}
                                    onChange={handleTag}
                                    data={languageSkillsTags}
                                    title={"Compétences linguistique"}
                                    name={"languageSkills"}
                                    handleLevel={handleLevel} />
                            </div>
                        </div>
                        <div className='mt-10 max-w-[750px]'>
                            <h2>Sélection de vos compétences*</h2>
                            <p>Veuillez choisir les tags des compétences que vous souhaitez voir apparaitre sur votre vignette de la page LES PROFILS</p>
                            <p className='flex gap-3'>
                                <Badge mode="info" name="min : 1" />
                                <Badge mode="warning" name="max : 4" />     
                            </p>
                        </div>
                        <div className="flex gap-1 items-center flex-wrap max-w-[750px]">
                            {data.tags.length === 0 && <div className='italic text-sm'>
                                Veuillez sélectionner vos compétences au-dessus.
                            </div>}
                            {data.tags.map(usertag => {
                                var tagFiltered = sectorialSkillsTags.filter(item => item.id === parseInt(usertag.tag.split('/')[2]));
                                var type = "sectorialSkills";
                                if (tagFiltered.length === 0) {
                                    tagFiltered = fonctionalSkillsTags.filter(item => item.id === parseInt(usertag.tag.split('/')[2]))
                                    type = "fonctionalSkills";
                                }
                                if (tagFiltered.length === 0) {
                                    tagFiltered = computerSkillsTags.filter(item => item.id === parseInt(usertag.tag.split('/')[2]))
                                    type = "computerSkills";
                                }
                                if (tagFiltered.length === 0) {
                                    tagFiltered = languageSkillsTags.filter(item => item.id === parseInt(usertag.tag.split('/')[2]))
                                    type = "languageSkills";
                                }
                                if (tagFiltered.length > 0) {
                                    return <TagForCard forCard={usertag.forCard} level={usertag.level} usertag={usertag} type={type} name={tagFiltered[0].name} handleFavTag={handleFavTag} />
                                }
                            })}
                        </div>
                        <div className='flex justify-between'>
                            <SecondaryButton onClick={prev} text={"précédent"} />
                            <PrimaryButton disabled={formIsNotValid} onClick={next} text={"suivant"} />
                        </div>
                    </>
            }
        </div>
    )
}
