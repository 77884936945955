import React from 'react'
// import Logo from '../../assets/logoFooter.jpg'
// import Linkedin from '../../assets/logoLinkeden.png'
import { Link } from 'react-router-dom'
import Logo from "../../assets/img/Logo1.png";
import Linkedin from 'assets/svg/Linkedin'

import AlertPopup from 'Components/AlertPopup/AlertPopup';




function Footer() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    return (
        <section id="footer" className="bg-[#EFF5FA] ">
            <AlertPopup />
            <section className="containerxl containerfix">
                <section className="grid grid-cols-1 gap-2 md:grid-cols-2 xl:grid-cols-3 justify-center w-full">
                    <div className='mt-24 flex flex-col items-center justify-center sm:block'>
                        <ul className='list-none'>
                            <li className="m-auto sm:m-0 w-[98px] h-[97.82px]">
                                <Link to="">
                                    <img
                                        className="w-24 lg:w-26 xl:w-32"
                                        src={Logo}
                                        alt="MyFinPlace.com"
                                    />
                                </Link>
                            </li>
                            <li className="">
                                <p className="mt-7 text-center text-base sm:text-left bigscreen:text-[20px]">
                                    Premier <i>hub</i> des métiers de la finance
                                </p>
                            </li>
                            <li className="mt-8 hidden md:block">
                                <Link to="https://www.linkedin.com/in/myfinplace-le-hub-et-le-cab-661b44238/" target='_blank'>
                                    <div className='text-[#5B9ECF] hover:text-[#497EA6]'>
                                        <Linkedin color='#5B9ECF' />
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    {/* {# =========================================== #}
                      {#                 Informations                #}
                      {# =========================================== #} */}
                    <div className='text-center sm:text-left mr-4 mt-24 md:ml-0' >
                        <h5 className="mb-3 font-poppins title-h1">
                            Informations
                        </h5>
                        <hr className="" />
                        <ul className="list-none my-4">
                            <p className="pt-6 font-poppins scroll-to-top bigscreen:text-[20px]" onClick={scrollToTop} >
                                <Link to='/qui-somme-nous'>Qui sommes-nous ?</Link>
                            </p>
                            <p className="pt-6 font-poppins scroll-to-top bigscreen:text-[20px]" onClick={scrollToTop} >
                                <Link to='Le-HUB'>Le HUB</Link>
                            </p>
                            <p className="pt-6 font-poppins scroll-to-top bigscreen:text-[20px]" onClick={scrollToTop} >
                                <Link to='/Le-cab'>Le CAB</Link>
                            </p>
                            <p className="pt-6 font-poppins scroll-to-top bigscreen:text-[20px]" onClick={scrollToTop} >
                                <Link to='/FAQ'>FAQ</Link>
                            </p>
                            <p className="pt-6 font-poppins scroll-to-top bigscreen:text-[20px]" onClick={scrollToTop} >
                                <Link to='/CGU'>CGU</Link>
                            </p>
                            <p className="pt-6 font-poppins scroll-to-top bigscreen:text-[20px]" onClick={scrollToTop} >
                                <Link to='/mentions-legales'>Mentions légales</Link>
                            </p>
                            <p className="pt-6 font-poppins  scroll-to-top bigscreen:text-[20px]" onClick={scrollToTop} >
                                <Link to='/politique-de-confidentialite'>Politique de confidentialité</Link>
                            </p>
                        </ul>
                    </div>
                    {/* {# =========================================== #}
                      {#                Nous écrire               #}
                      {# =========================================== #} */}
                    <div className='text-center sm:text-left mr-4 md:ml-0 mt-24'>
                        <h5 className="mb-3 font-poppins title-h1">
                            Nous écrire
                        </h5>
                        <hr className="" />
                        <ul className="list-none mt-4">
                            <li className="pb-6 pt-6 font-poppins scroll-to-top" onClick={scrollToTop} >
                                <button className='bg-[#5B9ECF] font-medium hover:bg-[#497EA6]  text-[#FFFFFF] w-[107px] h-[39px] rounded  py-2 px-4'>
                                    <Link className="" to="/contact">Contact</Link>
                                </button>
                            </li>
                            <Link to="mailto:contact@myfinplace.com">
                                <p className="font-poppins bigscreen:text-[20px]">contact@myfinplace.com</p>
                            </Link>
                            <li className="flex justify-center sm:block mt-4 md:hidden">
                                <Link to="https://www.linkedin.com/in/myfinplace-le-hub-et-le-cab-661b44238/" target='_blank'>
                                    <Linkedin />
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <section className="mt-8 mb-8 md:col-span-2 lg:col-span-4">
                        <div className='h-[19px]'>
                            <p className="text-center">Copyright © 2022 – MyFinPlace - Tous droits réservés.</p>
                        </div>
                    </section>
                </section>
            </section>
        </section>
    )
}

export default Footer