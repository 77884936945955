import { useEffect, useState, useRef, useContext } from "react";
import { GoKebabHorizontal } from "react-icons/go";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import axios from 'axios';
import Cookies from "js-cookie"
import ContextMenu from "Components/ContextMenu/ContextMenu";
import ContextItem from "Components/ContextMenu/ContextItem";
import { AlertMessageContext } from 'Context/AlertMessageProvider';
import RefuseCandidatureModal from "Modals/RefuseCandidatureModal";
import AvaibilityBadge from "Components/AvaibilityBadge/AvaibilityBadge";
import  ProfilNoPicture from "assets/img/profilnodata.png";
import { format } from "date-fns";
import { fr } from 'date-fns/locale'

export default function UserItem(props) {
    const [popupRefuser, setPopupRefuser] = useState(false)
    const user = props.postuler.id_user;
    const token = Cookies.get("token");
    const { alert } = useContext(AlertMessageContext);
    function openProfil() {
        // Passer la candidature en "en cours"
        if (props.postuler) {
            axios.patch(process.env.REACT_APP_API + `postuler/encours/${props.postuler.id}`, {},
                {
                    headers: {
                        "Content-Type": "application/merge-patch+json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            ).then((res) => { })
                .catch((error) => { })
            // Ouvrir le dossier de compétence
            window.open(`/dossier-de-competences/${user.id}${props.idOffre}`, '_blank')
        } else {
            window.open(`/dossier-de-competences/${user.id}`, '_blank')
        }
    }

    function displayStatus() {
        switch (props.postuler.status) {
            case "postuler":
                return <div className='my-2 bg-[#EBF4FF] rounded' >
                    <p className='p-2 font-bold text-[#0F6FDE] text-xs whitespace-nowrap'>En attente d'anaylse</p>
                </div>
                break;
            case "encours":
                return <div className='my-2 bg-[#FDF5E1] rounded' >
                    <p className='p-2 font-bold text-[#FFA500] text-xs whitespace-nowrap'>Déjà vue</p>
                </div>
                break;
            case "accepter":
                return <div className='my-2 bg-[#DCFCE6] rounded' >
                    <p className='p-2 font-bold text-[#00C455] text-xs whitespace-nowrap'>Retenue</p>
                </div>
                break;
            case "entretien":
                return <div className='my-2 bg-[#FDF5E1] rounded' >
                    <p className='p-2 font-bold text-[#FFA500] text-xs whitespace-nowrap'>Entretien en cours</p>
                </div>
                break;

            default:
                break;
        }
    }

    return (
        <div className="bg-white p-4 cursor-pointer">
            <div className="flex gap-5 items-start justify-between">
                <div className="flex gap-2">
                    <img src={ProfilNoPicture} className="w-[40px] h-[40px] rounded-full" alt="" />
                    <div>
                        <p className="font-medium">{props.title.substring(0, 25)}</p>
                        <div className="flex items-center justify-between gap-2">
                            <p><AvaibilityBadge avaibility={user} /></p>
                            <p>{displayStatus()}</p>
                        </div>
                        <p>
               <span class="items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"> {format(Date.parse(props.postuler.dateTimeCreation), "iiii d LLLL yyyy", { locale: fr })}</span>
            </p>
                    </div>
                </div>
                <ContextMenu>
                    <ContextItem text={"Voir le profil"} icon={<FaMagnifyingGlass />} callback={openProfil} />
                    <ContextItem text={"Refuser le profil"} icon={<MdCancel />} style={"text-red-500"} callback={() => { setPopupRefuser(true) }} />
                </ContextMenu>
            </div>
            {popupRefuser && <RefuseCandidatureModal fetchOffre={props.fetchOffre} postuler={props.postuler} setPopupRefuser={setPopupRefuser} title={props.title} />}
        </div>
    )
}


// u.id_user.titre
// u.id_user.disponibility
