import React, { useState, useContext } from "react";
import { ModalContext } from 'Context/ModalProvider';
import { IoIosClose } from "react-icons/io";


export default function ModalBase() {


  const { isOpen, content, title } = useContext(ModalContext);
  const { close } = useContext(ModalContext);

  return (
    <div className={`${isOpen ? "fixed z-50 inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center border" : "hidden"}`}>
      <div className='w-[90%] md:w-[50%] lg:w-[40%] bg-white rounded overflow-auto shadow-md  '>
        <div className="flex items-center justify-between py-2 px-6">
          <h3 className="font-bold">{title}</h3>
          <div className="font-bold text-[2rem] cursor-pointer hover:text-red-400" onClick={() => close()}>
            <IoIosClose />
          </div>
        </div>
        <div className="p-6 overflow-auto max-h-[675px]">
          {content}

        </div>
      </div>
    </div>
  )
}
