import React from 'react'

function france() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 2.375H0.5L0.5 13.625H5.5L5.8125 8.31247L5.5 2.375Z" fill="#0053B5" />
            <path d="M15.5 2.375H10.5L10.1875 8L10.5 13.625H15.5L15.5 2.375Z" fill="#D80027" />
            <path d="M5.5 13.625H10.5L10.5 2.375H5.5L5.5 13.625Z" fill="white" />
        </svg>
    )
}

export default france