import { BiMicrophone } from "react-icons/bi";
import { BiMicrophoneOff } from "react-icons/bi";

export default function Participants(props) {
  const { remoteParticipants, localParticipant } = props.state;

  function displayIcon(displayName) {
    const name = displayName.split("|")[1].split(" ");
    return (
      <div className="bg-[#D1E6F4] p-2 w-[45px] h-[45px] font-bold flex justify-center items-center text-lg text-[#5A9ECF] rounded-full">
        {name[0][0]}{name[1][0]}
      </div>
    )
  }

  return (
    <div className="border border-[#BFBFBF] rounded-[5px]">
      <h4 className="p-2 font-bold">Participants</h4>
      <span className="border-t border-[#BFBFBF] block w-full" />
      <div className="px-4 py-4 flex flex-col gap-4">
        {localParticipant && <div className="flex gap-4 justify-between items-center">
          {displayIcon(localParticipant.displayName)}
          <p>{localParticipant.displayName.split("|")[1]}</p>
          <div>
            {localParticipant.isAudioEnabled ?
              <BiMicrophone className="text-[#5A9ECF]" size={30} />
              :
              <BiMicrophoneOff className="text-[#FF0000]" size={30} />
            }
          </div>
        </div>}
        {remoteParticipants.map((participant) => {
          return (
            <div className="flex gap-4 justify-between items-center">
              {displayIcon(participant.displayName)}
              <p>{participant.displayName.split("|")[1]}</p>
              <div>
                {participant.isAudioEnabled ?
                  <BiMicrophone className="text-[#5A9ECF]" size={30} />
                  :
                  <BiMicrophoneOff className="text-[#FF0000]" size={30} />
                }
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}