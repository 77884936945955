import { FaCheckCircle } from "react-icons/fa";
import tarif_recrutement from "assets/img/tarif_recrutement.png";
import { Link } from "react-router-dom";

export default function Recrutement() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <div className="flex flex-col border rounded-[8px] border-[#2632384D] border-[4px] p-2">
      <p className="text-right">Le HUB</p>
      <h1 className="font-medium text-[40px] text-center mt-6 mb-8">Recrutement</h1>
      <img className="mx-auto h-[110px]" src={tarif_recrutement} alt="" srcSet="" />
      <p className="text-[17px] font-medium text-center mt-8">Vous recherchez un <span className="text-[#5B9ECF]">candidat à recruter (en CDD ou en CDI)</span> ?</p>
      <h1 className="text-[40px] text-center mt-12">15 %*</h1>
      <p className="font-medium text-center mb-12">de la rémunération brute annuelle attendue par le candidat (année 1)</p>
      <div className="flex items-center gap-4">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Inscription</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Navigation libre</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Téléchargement du dossier de compétences</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Gestion des rendez-vous</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Visioconférence illimitée</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Présence d’un associé lors de l’entretien en visioconférence</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">On-Boarding du profil</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Facturation J+30</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Assurance “Profil de remplacement**”</p>
      </div>
      <div className="mt-auto">
        <div className="text-center mt-8">
          <Link to="/les-profils?type=recrutement" onClick={scrollToTop} className="bg-[#5B9ECF] hover:bg-[#2D77AD] text-white py-2 px-4 rounded-[5px] inline-block">Je souhaite recruter un collaborateur</Link>
        </div>
        <div className="mt-8 text-center">
          <p className="text-[12px] font-medium">*Honoraires dus à compter de la signature d’un contrat de travail par le candidat et payable en une seule fois</p>
          <p className="text-[12px] font-medium mt-4">** Dans le cas ou la période d’essai serait rompue avant sa fin, le client pourra choisir un nouveau candidat (profil de remplacement) sans facturation complémentaire.</p>
        </div>
      </div>
    </div>
  )
}