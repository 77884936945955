
import { IoChevronDownOutline } from "react-icons/io5";
import inscription1 from "assets/img/Hub/Card2/Compléter son profil 1.png";
import inscription2 from "assets/img/Hub/Card2/Compléter son profil 2.png";
import inscription3 from "assets/img/Hub/Card2/Compléter son profil 3.png";

export default function Card2({ isActive, setActive }) {
  return (
    <div className="border rounded-[8px] border-[#2632384D] border-[4px] py-4 px-6 mt-16">
      <h1 onClick={() => setActive({ Question2: !isActive.Question2 })} className="font-semibold text-[#263238] cursor-pointer flex gap-2  "  >
        <div className="flex justify-between w-full items-center">
          <p className="text-[32px] text-black font-bold">Comment <span className="text-[#5B9ECF]">compléter / modifier</span> votre profil ? </p>
          <div className={isActive.Question2 ? "rotate-180 transition duration-500" : "transition duration-500"}>
            <IoChevronDownOutline className="text-[#5B9ECF]" />
          </div>
        </div>
      </h1>
      <div className={isActive.Question2 ? "animation-box-visible" : "animation-box-hidden "} id="box1">
      <div className="pr-5 text-justify animation-content" id="content2">

      <div className="flex flex-row my-16 items-center justify-between "> 
                  <div>
                      <h3>1. Connectez-vous à votre espace personnel et rendez-vous dans l’onglet “Mon profil” dans le menu situé à gauche de votre espace personnel ;</h3>
                  </div>
                  <div className="contents">
                      <img className="w-1/3" src={inscription1} alt="" />
                  </div>
              </div>
              <div className="flex flex-row my-16 items-center justify-between"> 
                  <div>
                      <h3>2. Sélectionnez l’onglet dans lequel vous souhaitez compléter ou modifier vos informations. Modifiez les informations, puis cliquez sur le bouton “Sauvegarder” ;</h3>
                      <br />
                      <h3 className="font-semibold">NB : Il n’est pas possible de modifier l’adresse mail liée à votre compte.</h3>
                  </div>
                  <div className="contents">
                      <img className="w-1/3" src={inscription2} alt="" />
                  </div>
              </div>
              <div className="flex flex-row my-16 items-center justify-between"> 
                  <div>
                      <h3>3. Si vous possédez un compte étudiant, candidat ou consultant, nous vous invitons à rendre votre vignette visible une fois les modifications effectuées.</h3>
                       <br />
                      <h3 className="font-semibold"> NB : Seule une entreprise ou un cabinet peut rencontrer un profil.</h3>
                  </div>
                  <div className="contents">
                      <img className="w-1/3" src={inscription3} alt="" />
                  </div>
              </div>
      </div>
      </div>
    </div>
  )
}