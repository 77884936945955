import React from 'react'

function trash(props) {
    return (
        <svg 
        className={props.classProps ? props.classProps : ""} 
        width={props.width ? props.width : "18"} height={props.height ? props.height : "18"} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3837_6953)">
                <path d="M1.5 3H5.25V1.5C5.25 1.10218 5.40804 0.720644 5.68934 0.43934C5.97064 0.158035 6.35218 0 6.75 0L11.25 0C11.6478 0 12.0294 0.158035 12.3107 0.43934C12.592 0.720644 12.75 1.10218 12.75 1.5V3H16.5V4.5H15V15.75C15 16.3467 14.7629 16.919 14.341 17.341C13.919 17.7629 13.3467 18 12.75 18H5.25C4.65326 18 4.08097 17.7629 3.65901 17.341C3.23705 16.919 3 16.3467 3 15.75V4.5H1.5V3ZM11.25 1.5H6.75V3H11.25V1.5ZM4.5 15.75C4.5 15.9489 4.57902 16.1397 4.71967 16.2803C4.86032 16.421 5.05109 16.5 5.25 16.5H12.75C12.9489 16.5 13.1397 16.421 13.2803 16.2803C13.421 16.1397 13.5 15.9489 13.5 15.75V4.5H4.5V15.75Z" fill="black" />
                <path d="M9.75 7.5H11.25V13.5H9.75V7.5Z" fill={props.color ? props.color : "#5B9ECF"} />
                <path d="M6.75 7.5H8.25V13.5H6.75V7.5Z" fill={props.color ? props.color : "#5B9ECF"} />
            </g>
            <defs>
                <clipPath id="clip0_3837_6953">
                    <rect width={props.width ? props.width : "18"} height={props.height ? props.height : "18"} fill="white" transform="matrix(-1 0 0 1 18 0)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default trash