import { useState, useContext, useEffect } from 'react'
import { useParams, Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import Input from 'Components/Forms/Input/Input'
import { FaCalendarAlt } from "react-icons/fa";
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import { ModalContext } from 'Context/ModalProvider';
import CalendarComponentMinDate from 'Components/Calendar/CalendarComponentMinDate';
import { AlertMessageContext } from 'Context/AlertMessageProvider';
import { add, differenceInDays, endOfMonth, format, setDate, startOfMonth, sub } from "date-fns";
import { fr } from 'date-fns/locale'

export default function AppointmentModal() {

    const { close } = useContext(ModalContext);
    const { alert } = useContext(AlertMessageContext);
    const [isDateSelected, setIsDateSelected] = useState('disabled');

    const [isChecked, setIsChecked] = useState(false);
    const [selectedTime, setSelectedTime] = useState('');
    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [offresPostuler, setOffresPostuler] = useState(null);
    const [offresClient, setOffresClient] = useState(null);
    const [offresAppointment, setOffresAppointment] = useState(null);
    const [hoursTaken, setHoursTaken] = useState([]);
    const [queryParameters] = useSearchParams()
    const idOffre = queryParameters.get("offre");
    const [context, setContext] = useState(idOffre !== null ? "candidature" : "spontaner")
    const [selectSpontaner, setSelectSpontaner] = useState(null)
    const [selectCandidature, setSelectCandidature] = useState(idOffre);
    const { id } = useParams();
    const token = cookie.get("token");

    useEffect(() => {
        axios.get(process.env.REACT_APP_API + `userCandidatureForClient/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((res) => {
                setOffresPostuler(res.data.found)
                setOffresClient(res.data.offresClient.reverse())
                setOffresAppointment(res.data.offresAppointment)
                setHoursTaken(res.data.hoursTaken)
                if (idOffre !== null) {
                    if ((res.data.found.filter((x) => x.id == idOffre)).length > 0) {
                        setContext("candidature");
                    } else {
                        setContext("spontaner");
                        setSelectCandidature(null);
                    }
                } else {
                    setContext("spontaner")
                }
            })
            .catch((error) => {
                // nothing
            })
    }, [])


    const handleDateSelect = (date) => {
        setSelectedDate(date);
        setIsDateSelected("")
        setShowCalendar(false);
    };


    const handleTimeSelect = (e) => {
        setSelectedTime(e.target.value);
    };

    const handleDateClick = () => {
        setShowCalendar(!showCalendar);
    };


    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };
    const handleSelectContext = (e) => {
        setContext(e.target.value)
    };
    const handleSelectCandidature = (e) => {
        setSelectCandidature(e.target.value)
    };
    const handleSelectSpontaner = (e) => {
        setSelectSpontaner(e.target.value)
    };



    const hancleCreate = () => {
        if (selectedDate && selectedTime && format(selectedDate, "yyyy-MM-dd", { locale: fr }) >= format((new Date()), "yyyy-MM-dd", { locale: fr })) {
            var idOffreSent = null;
            switch (context) {
                case "candidature":
                    idOffreSent = "api/offres/" + selectCandidature;
                    break;
                case "spontaner":
                    idOffreSent = selectSpontaner !== null ? "api/offres/" + selectSpontaner : null;
                    break;
            }
            setIsLoading(true);
            axios.post(process.env.REACT_APP_API + "client/entretien/create",
                {
                    "offre": idOffreSent,
                    "apply": context === "candidature",
                    "idUser": `api/users/${id}`,
                    "dateChoice": format(selectedDate, "yyyy-MM-dd ", { locale: fr }) + selectedTime
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }).then((res) => {
                    setIsLoading(false);
                    alert.successAlert("Demande d'entretien envoyé avec succès !");
                    close()
                }).catch((error) => {
                    setIsLoading(false);
                    if (error.response.status === 401) {
                        alert.errorAlert(error.response.data.error)
                    } else {
                        alert.errorAlert("Une erreur s'est produite durant la création de l'entretien.")
                    }
                })
        }
    }


    function isDisableOption(hour, formathoursTaken, isHalfHour) {
        var dateOption = new Date(selectedDate).setHours(hour);
        if (isHalfHour) {
            dateOption = new Date(dateOption).setMinutes(30);
        }
        dateOption = new Date(dateOption);
        var Before30 = sub(dateOption, { hours: 2 });
        var After30 = add(dateOption, { minutes: 30 });
        var flag = false;
        formathoursTaken.map((hourTaken) => {
            if (Before30 <= new Date(hourTaken) && new Date(hourTaken) <= After30) {
                return flag = true
            }
        })
        return flag
    }


    const renderOptions = () => {
        const options = [];
        // Cachement des heures passé si l'utilsateur à choisi aujourd'hui
        var formatSelectedDay = null;
        var formathoursTaken = [];
        if (selectedDate !== null) {
            formatSelectedDay = format(Date.parse(selectedDate), "yyyy-MM-dd", { locale: fr })
            formathoursTaken = hoursTaken.filter((x) => x.startsWith(format(Date.parse(selectedDate), "yyyy-MM-dd", { locale: fr })))
        }
        const isToday = formatSelectedDay === new Date().toISOString().split('T')[0];
        const todayHour = new Date().getHours();
        // Disable les heures déjà utiliser par d'autre appointment
        options.push(
            <option key="select" value="">Sélectionnez une heure</option>
        );
        for (let hour = 7; hour <= 22; hour++) {
            const formattedHour = hour.toString().padStart(2, '0');
            options.push(
                <option value={`${formattedHour}:00`} hidden={isToday && todayHour >= hour} disabled={isDisableOption(hour, formathoursTaken, false)} key={formattedHour} className='disabled:text-[#BFBFBF]'>{`${formattedHour}:00`}</option>
            );
            if (formattedHour < 22) {
                options.push(<option value={`${formattedHour}:30`} hidden={isToday && todayHour >= hour} disabled={isDisableOption(hour, formathoursTaken, true)} key={`${formattedHour}:30`} className='disabled:text-[#BFBFBF]'>{`${formattedHour}:30`}</option>);
            }
        }
        return options;
    };

    return (
        <div>
            <div>
                <p className="mb-4">Dans quel cadre rencontrez vous ce profil ?</p>
                <select value={context} onChange={handleSelectContext} className='outline-none py-[10px] px-[10px] bg-greylight border border-lightblue rounded-[3px] h-full w-full'>
                    <option value="spontaner">Entretien spontané</option>
                    <option value="candidature">Entretien suite à candidature</option>
                </select>
                <div className='my-4'>
                    {context === "candidature" ?
                        <>
                            <p className='mb-4'>Sélectionnez une opportunité</p>
                            <div>
                                <select onChange={handleSelectCandidature} value={selectCandidature} className='outline-none py-[10px] px-[10px] bg-greylight border border-lightblue rounded-[3px] h-full w-full'>
                                    <option hidden value="">--- Sélectionnez une opportunité ---</option>
                                    {offresPostuler && offresPostuler.map((offre) =>
                                        <option value={offre.id} key={offre.id}>{offre.intiule}</option>
                                    )}
                                </select>
                                {offresAppointment && offresAppointment.includes(parseInt(selectCandidature)) &&
                                    <p className='text-red-500'>Vous avez déjà proposer un entretien à ce profil avec cette opportunité</p>
                                }
                            </div>
                        </>
                        :
                        <>
                            <p className='mb-4'>Sélectionnez une opportunité à présenter à ce profil (optionnel)</p>
                            <div>
                                <select onChange={handleSelectSpontaner} value={selectSpontaner} className='outline-none py-[10px] px-[10px] bg-greylight border border-lightblue rounded-[3px] h-full w-full'>
                                    <option hidden value="">--- Sélectionner une opportunité ---</option>
                                    {offresClient && offresClient.map((offre) =>
                                        <option value={offre.id}>{offre.intiule}</option>
                                    )}
                                </select>
                            </div>
                        </>
                    }
                </div>
            </div>
            <p className="mb-4">Quand souhaitez-vous rencontrer ce profil ?</p>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4'>
                <div className='flex' onClick={handleDateClick}>
                    <Input name={"date"} isReadOnly={true} value={selectedDate ? format(selectedDate, 'dd/MM/yyyy') : 'jj/mm/aaaa'} />
                    <div className='flex items-center w-[60px] justify-center bg-greylight p-2 border-t border-b border-r border-lightblue cursor-pointer hover:bg-[#5B9ECF] hover:text-white' >
                        <FaCalendarAlt />
                    </div>

                </div>
                {
                    showCalendar ?
                        <CalendarComponentMinDate onDate={handleDateSelect} />
                        :
                        null

                }
                <div>
                    <select disabled={isDateSelected} name="hours" onChange={handleTimeSelect} id="pet-select" className='outline-none py-[10px] px-[10px] bg-greylight border border-lightblue rounded-[3px] h-full w-full'>
                        {
                            renderOptions()
                        }
                    </select>
                </div>
            </div>
            <div className='mb-4'>
                <p className='mb-4'>J'ai lu et j'accepte les <Link to="/CGU" target="_blank" className="text-middleblue hover:text-[#497EA6]">CGU</Link></p>
                { }
                <label className={`switch inline-flex relative ${showCalendar ? "opacity-0" : "opacity-1"}`}>
                    <input type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange} />
                    <span className="slider"></span>
                </label>
            </div>
            <div className='flex flex-col md:flex-row gap-2 justify-end'>
                <SecondaryButton text={"Annuler"} onClick={close} />
                <PrimaryButton isLoading={isLoading} disabled={!isChecked || !selectedDate || !selectedTime || (context === "candidature" && offresAppointment.includes(parseInt(selectCandidature)))} text={"Envoyer la demande"} onClick={hancleCreate} />
            </div>
        </div>
    )
}
