import {useState,useEffect,useRef } from 'react'
import { GoKebabHorizontal } from "react-icons/go";


function ContextMenu({children}) {

    const [kebabMenuOpen, setKebabMenuOpen] = useState(false);
    
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setKebabMenuOpen(false);
        }
    };

  const menuRef = useRef(null);

  return (
            <div className="relative">
                <div  ref={menuRef} className='p-1 border rounded hover:bg-[#EFF5FA] cursor-pointer' onClick={() => setKebabMenuOpen(!kebabMenuOpen)}>
                    <GoKebabHorizontal />
                </div>
                {   
                    kebabMenuOpen ?
                    <div className="absolute right-0 z-10 mt-1 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none whitespace-nowrap">
                        { children }
                    </div>
                    :
                    null
                }
            </div>
  )
}

export default ContextMenu