export function isUserOrAnonymous(user) {
    return user === undefined || user.roles?.includes("ROLE_USER");
}


export function isClient(user) {
    if(user) {
        return user.roles?.includes("ROLE_CLIENT");
    }

    return false;
}


export function isUser(user) {
    if(user) {
        return user.roles?.includes("ROLE_USER");
    }

    return false;
}