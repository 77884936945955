import React from "react";
import ReactDOM from "react-dom/client";
import "./css/main.css";
import App from "./App";
import Footer from "./Components/Footer/Footer";
import ResponsiveNavBar from "Components/ResponsiveNavBar/ResponsiveNavBar";
import { CheckboxProvider } from "Pages/Statics/LesOpportunite/Context/CheckboxProvider";
import { AuthProvider } from "Context/AuthProvider";
import { AlertMessageProvider } from "Context/AlertMessageProvider";
import { RouterProvider } from "react-router-dom";
import { CardProvider } from "Pages/Statics/LesOpportunite/Context/CardProvider";
import { ModalProvider } from "Context/ModalProvider";
import router from "router";
import { store } from "app/store";
import { Provider } from "react-redux";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <AuthProvider>
                <AlertMessageProvider>
                    <ModalProvider>
                        <CheckboxProvider>
                            <CardProvider>
                                <RouterProvider router={router} />
                            </CardProvider>
                        </CheckboxProvider>
                    </ModalProvider>
                </AlertMessageProvider>
            </AuthProvider>
        </Provider>
    </React.StrictMode>
);