import React from 'react'

function linkedin(props) {
    return (
        <svg className={props.classProps ? props.classProps : ""} width={props.width ? props.width : "34"} height={props.height ? props.height : "35"} viewBox="0 0 34 35" 
        fill={props.color ? props.color : "#263238"} xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3087_1663)">
                <path d="M27.9188 33.483C20.6629 34.2571 13.3452 34.2571 6.08928 33.483C4.68024 33.3342 3.36478 32.707 2.3622 31.7058C1.35962 30.7046 0.730506 29.39 0.579732 27.9812C-0.194383 20.7253 -0.194383 13.4076 0.579732 6.15166C0.728528 4.74262 1.3558 3.42716 2.35697 2.42458C3.35815 1.422 4.67273 0.792884 6.08155 0.64211C13.3375 -0.132006 20.6552 -0.132006 27.9111 0.64211C29.3201 0.790906 30.6356 1.41818 31.6382 2.41935C32.6407 3.42053 33.2699 4.7351 33.4206 6.14393C34.1948 13.3999 34.1948 20.7175 33.4206 27.9735C33.2718 29.3825 32.6446 30.698 31.6434 31.7005C30.6422 32.7031 29.3276 33.3322 27.9188 33.483Z" 
                fill={props.color ? props.color : "#263238"} />
                <path d="M12.2477 9.33556C12.2477 9.90874 12.0778 10.4691 11.7594 10.9456C11.441 11.4222 10.9884 11.7937 10.4589 12.013C9.92942 12.2324 9.34678 12.2898 8.78468 12.1779C8.22258 12.0661 7.70625 11.7901 7.301 11.3848C6.89574 10.9795 6.61976 10.4631 6.50795 9.90094C6.39614 9.33877 6.45353 8.75607 6.67285 8.22652C6.89217 7.69697 7.26358 7.24435 7.74011 6.92591C8.21664 6.60747 8.77688 6.4375 9.35 6.4375C10.1185 6.4375 10.8556 6.74283 11.399 7.28632C11.9424 7.82981 12.2477 8.56695 12.2477 9.33556Z" 
                fill="white" />
                <path d="M11.5909 14.1647V26.9668C11.5914 27.0613 11.5732 27.1549 11.5374 27.2423C11.5016 27.3297 11.4489 27.4092 11.3823 27.4761C11.3157 27.5431 11.2365 27.5962 11.1493 27.6325C11.0621 27.6687 10.9686 27.6874 10.8742 27.6874H7.81996C7.72551 27.6877 7.63195 27.6692 7.54465 27.6332C7.45734 27.5972 7.37802 27.5443 7.31124 27.4775C7.24445 27.4107 7.19153 27.3314 7.15551 27.2441C7.11948 27.1568 7.10107 27.0632 7.10132 26.9688V14.1647C7.10132 13.9741 7.17703 13.7913 7.31181 13.6565C7.44658 13.5218 7.62936 13.446 7.81996 13.446H10.8742C11.0644 13.4466 11.2467 13.5225 11.3811 13.6572C11.5154 13.7919 11.5909 13.9744 11.5909 14.1647Z" 
                fill="white" />
                <path d="M27.5555 20.8296V27.0268C27.5557 27.1137 27.5388 27.1997 27.5057 27.28C27.4726 27.3602 27.4239 27.4332 27.3625 27.4946C27.3011 27.556 27.2282 27.6046 27.1479 27.6377C27.0676 27.6708 26.9816 27.6878 26.8948 27.6875H23.6107C23.5239 27.6878 23.4378 27.6708 23.3576 27.6377C23.2773 27.6046 23.2043 27.556 23.1429 27.4946C23.0815 27.4332 23.0329 27.3602 22.9998 27.28C22.9667 27.1997 22.9498 27.1137 22.95 27.0268V21.0208C22.95 20.1244 23.2127 17.0953 20.6067 17.0953C18.588 17.0953 18.1765 19.1682 18.0953 20.0993V27.0268C18.0954 27.2004 18.0271 27.367 17.9053 27.4906C17.7834 27.6142 17.6179 27.685 17.4443 27.6875H14.2723C14.1856 27.6875 14.0998 27.6704 14.0197 27.6372C13.9397 27.604 13.867 27.5553 13.8058 27.4939C13.7446 27.4325 13.6961 27.3597 13.6631 27.2795C13.6301 27.1994 13.6133 27.1135 13.6135 27.0268V14.1088C13.6133 14.0221 13.6301 13.9362 13.6631 13.8561C13.6961 13.7759 13.7446 13.7031 13.8058 13.6417C13.867 13.5803 13.9397 13.5316 14.0197 13.4984C14.0998 13.4652 14.1856 13.4481 14.2723 13.4481H17.4443C17.6195 13.4481 17.7876 13.5177 17.9115 13.6416C18.0354 13.7655 18.105 13.9335 18.105 14.1088V15.2253C18.8546 14.101 19.9653 13.2336 22.3357 13.2336C27.5864 13.2336 27.5555 18.1366 27.5555 20.8296Z" 
                fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_3087_1663">
                    <rect width={props.width ? props.width : "34"} height={props.height ? props.height : "34"}
                    fill="white" transform="translate(0 0.0625)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default linkedin