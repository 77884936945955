import { FieldGuesser, ListGuesser, InputGuesser, ShowGuesser, CreateGuesser, EditGuesser } from "@api-platform/admin";
import { usePermissions, DeleteWithConfirmButton, ReferenceField, ReferenceInput, SelectInput, TextField, TextInput } from "react-admin";

const ListFilters = [
  <TextInput label="Nom" source="name" alwaysOn />,
  <SelectInput source="idTagLevel" choices={[
    { id: `${process.env.REACT_APP_API}tag_levels/1`, name: "Compétences sectorielles" },
    { id: `${process.env.REACT_APP_API}tag_levels/2`, name: "Type d'intervention" },
    { id: `${process.env.REACT_APP_API}tag_levels/3`, name: "Compétences fonctionnelles" },
    { id: `${process.env.REACT_APP_API}tag_levels/4`, name: "Compétences informatiques" },
    { id: `${process.env.REACT_APP_API}tag_levels/5`, name: "Compétences linguistiques" }
  ]} />
];

const NotAllowedPlaceholder = (props) => (
  <CreateGuesser {...props} component="div">
    <p>Vous n'avez pas les droits pour créer cet élément</p>
  </CreateGuesser>
);
const TagsList = (props) => {
  const { permissions } = usePermissions();
  return (
    <ListGuesser {...props} perPage={25} filters={ListFilters}>
      <FieldGuesser source="name" />
      <ReferenceField label="Secteur" source="idTagLevel" reference="tag_levels">
        <TextField source="level" />
      </ReferenceField>
      {permissions === "ROLE_SUPER_ADMIN" &&
        <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" />
      }
    </ListGuesser>
  );
}
const TagsShow = (props) => {
  const { permissions } = usePermissions();
  return (
    <ShowGuesser {...props}>
      {permissions === "ROLE_SUPER_ADMIN" &&
        <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" />
      }
      <FieldGuesser source="name" />
      <ReferenceField label="Secteur" source="idTagLevel" reference="tag_levels">
        <TextField source="level" />
      </ReferenceField>
    </ShowGuesser>
  );
};
const TagsSuperEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="name" label="Nom" />
  </EditGuesser>
);
const TagsSuperCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="name" />
    <ReferenceInput source="idTagLevel" reference="tag_levels">
      <SelectInput optionText="level" label="Secteur" />
    </ReferenceInput>
  </CreateGuesser>
);

export { TagsList, TagsShow, TagsSuperCreate, TagsSuperEdit, NotAllowedPlaceholder };