import { FaExternalLinkAlt } from "react-icons/fa";

export default function Ressources(props) {
  return (
    <div className="border border-[#BFBFBF] rounded-[5px]">
      <h4 className="p-2 font-bold">Ressources</h4>
      <span className="border-t border-[#BFBFBF] block w-full" />
      <div className="px-4 py-4 flex flex-col gap-4">
        <a href={window.location.origin + "/dossier-de-competences/" + props.idDossierCompetence} target="_blank" className="text-[#0091FF] hover:text-[#006EBC] hover:cursor-pointer flex items-center gap-2 inline-block">
          <p className="underline">Dossier de compétence</p>
          <FaExternalLinkAlt size={15} />
        </a>
        {props.idOffre &&
          <a href={window.location.origin + "/offre/" + props.idOffre} target="_blank" className="text-[#0091FF] hover:text-[#006EBC] hover:cursor-pointer flex items-center gap-2 inline-block">
            <p className="underline">Oppotunité</p>
            <FaExternalLinkAlt size={15} />
          </a>
        }
      </div>
    </div>
  )
}