import { Text, View } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import Office from "./Svg/Office";
import { styles, SizeText } from "./../Css/styles";

const dayToDate = (totalDay) => {
  var string = "";
  //Affichage des années
  if (totalDay >= 365) {
    const year = Math.floor(totalDay / 365);
    string += year + " années";
    totalDay -= year * 365;
    if (totalDay > 30) { string += " " }
  }
  //Affichage des mois
  if (totalDay >= 30) {
    const month = Math.floor(totalDay / 30);
    string += month + " mois";
  }
  return string;
}

const Experience = ({ data }) => {
  //Calcul du nombre total de jour des expériences
  var diff = 0;
  data.experience.forEach((exp) => {
    if (exp.date_end === undefined) {
      var diffTime = Math.abs(new Date().setDate(1) - new Date(exp.date_start).setDate(1));
    } else {
      diffTime = Math.abs(new Date(exp.date_end).setDate(1) - new Date(exp.date_start).setDate(1));
    }
    var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    diff += diffDays;
  })
  const countTimeExperience = dayToDate(diff);
  return (
    <View>
      <Text style={styles.TitleGeneral}>Expérience ({countTimeExperience})</Text>
      <View style={{ display: "flex", flexDirection: "column", gap: 12 }}>
        {data.experience && data.experience.map(
          (experience, key) =>
            <View style={{ display: "flex", flexDirection: "row", gap: 6 }} key={key} wrap={false}>
              <Office />
              <View>
                <Text style={styles.TitreExpForma}>{experience.name}</Text>
                <Text style={{ fontSize: SizeText, color: "#5B9ECF", marginVertical: 2 }}>{experience.poste}</Text>
                <Text style={{ fontSize: SizeText, marginBottom: 12 }}>
                  {/* Affichage des date de début et de fin */}
                  {new Date(experience.date_start).toLocaleDateString(
                    "fr-FR",
                    { month: "long", year: "numeric" }
                  )}
                  {" - "}
                  {experience.in_progress ?
                    "Aujourd'hui"
                    :
                    new Date(experience.date_end).toLocaleDateString(
                      "fr-FR",
                      { month: "long", year: "numeric" }
                    )
                  }
                  {/* Affichage de la durée de l'expérience */}
                  {experience.in_progress ?
                    " (" + dayToDate(Math.ceil(Math.abs(new Date() - new Date(experience.date_start).setDate(1)) / (1000 * 60 * 60 * 24))) + ")"
                    :
                    " (" + dayToDate(Math.ceil(Math.abs(new Date(experience.date_end).setDate(1) - new Date(experience.date_start).setDate(1)) / (1000 * 60 * 60 * 24))) + ")"
                  }
                </Text>
                <Html style={{ fontSize: SizeText }}>
                  {"<div style='max-width:90%;'>" + experience.description.replaceAll("\n", "<br/>") + "</div>"}
                </Html>
              </View>
            </View>
        )}
      </View>
    </View>
  );
}

export default Experience;