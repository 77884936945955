import { fetchHydra as baseFetchHydra, hydraDataProvider as baseHydraDataProvider } from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";
import cookie from "js-cookie";

const entrypoint = process.env.REACT_APP_API;

const getHeaders = () => cookie.get("token") ? {
  Authorization: `Bearer ${cookie.get("token")}`,
} : {};
const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: getHeaders,
  });
const apiDocumentationParser = () => async () => {
  try {
    return await parseHydraDocumentation(entrypoint, { headers: getHeaders });
  } catch (result) {
    const { api, response, status } = result;
    if (status !== 401 || !response) {
      throw result;
    }

    // Prevent infinite loop if the token is expired
    cookie.remove("token");

    return {
      api,
      response,
      status,
    };
  }
};
const dataProvider = () => baseHydraDataProvider({
  entrypoint: entrypoint,
  httpClient: fetchHydra,
  apiDocumentationParser: apiDocumentationParser()
});

export default dataProvider;