import { FaCheckCircle } from "react-icons/fa";
import tarif_expertise from "assets/img/tarif_expertise.png";
import { Link } from "react-router-dom";

export default function Expertise() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <div className="flex flex-col border rounded-[8px] border-[#2632384D] border-[4px] p-2">
      <p className="text-right">Le CAB</p>
      <h1 className="font-medium text-[40px] text-center mt-6">Expertise</h1>
      <p className="text-[#5B9ECF] text-[12px] mb-8 mt-0 text-right mr-[30%] h-0">en comptabilité</p>
      <img className="mx-auto h-[110px]" src={tarif_expertise} alt="" srcSet="" />
      <p className="text-[17px] font-medium text-center mt-8">Vous recherchez un <span className="text-[#5B9ECF]">profil avec une expertise en comptabilité</span> (renfort d’équipe ou externalisation) ?</p>
      <h1 className="text-[40px] text-center mt-12">HONORAIRES 2024</h1>
      <a href="#grilleAchat" className="underline font-medium text-center mb-12">Grille achat MyFinPlace</a>
      <div className="flex items-center gap-4">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Inscription</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Navigation libre</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Téléchargement du dossier de compétences</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Gestion des rendez-vous</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Visioconférence illimitée</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Présence d’un associé lors de l’entretien en visioconférence</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">On-Boarding du profil</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Gestion du compte-rendu d’activité (CRA)</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Facturation J+30</p>
      </div>
      <div className="flex items-center gap-4 mt-8">
        <FaCheckCircle className="shrink-0 text-[#73CBA2] w-[26px] h-[26px]" />
        <p className="text-[16px] font-medium">Suivi de mission</p>
      </div>
      <div className="mt-auto">
        <div className="text-center mt-8">
          <Link to="/les-profils?searchTerm=comptabilité " className="bg-[#5B9ECF] hover:bg-[#2D77AD] text-white py-2 px-4 rounded-[5px] inline-block">Je sollicite un profil spécialisé en comptabilité</Link>
        </div>
        <div className="mt-8 text-center">
          <p className="text-[12px] font-medium">MyFinPlace “Le CAB” vous propose des profils spécialisés en comptabilité pour intervenir en renfort d’équipe dans le cadre de missions en comptabilité et en audit.</p>
          <p className="text-[12px] font-medium mt-4">Le CAB vous propose également d’externaliser votre comptabilité à nos cabinets partenaires</p>
        </div>
      </div>
    </div>
  )
}