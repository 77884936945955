import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";

import { AuthContext } from "Context/AuthProvider";


import {
    getInterventionType,
    getSectorialSkillTag,
    getFonctionalSkillTag,
    getComputerSkillTag,
    getLanguageSkillTag,
    avaibilityDateFormatter
} from "../../../../utils/formatting.js"
import { isClient, isUser } from "utils/roles";


import { IoDocumentText } from "react-icons/io5";
import { LuLoader2 } from "react-icons/lu";

import parse from "html-react-parser";
import Cookies from "js-cookie";
import jwt from "jwt-decode";
import DetailOffreHeader from "./DetailOffreHeader.jsx";
import DetailOffreTitle from "./DetailOffreTitle.jsx";
import DetailOffreRequirement from "./DetailOffreRequirement.jsx";
import Modality from "./Modality.jsx";

export default function DetailsOffre({ historyId, isComptable }) {


    const [details, setDetails] = useState(null);
    const token = Cookies.get("token");
    const [isLoading, setIsLoading] = useState(false);
    const [decodedToken, setDecodedToken] = useState();
    const { id } = useParams();

    const { value } = useContext(AuthContext);


    async function getDetails(id) {
        setIsLoading(true)
        if (isUser(value.user)) {
            var requestOptions = {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };
        } else {
            var requestOptions = {
                method: "GET",
            };
        }

        await fetch(
            process.env.REACT_APP_API + `viewOffre/${id}`,
            requestOptions
        )
            .then((res) => res.json())
            .then((res) => {

                setDetails(res);
            })
            .catch((error) => error);
        setIsLoading(false)
    }

    useEffect(() => {
        if (token) {
            setDecodedToken(jwt(token));
        }
    }, [])

    useEffect(() => {
        if (id) {
            getDetails(id);
        }
    }, [id]);

    useEffect(() => {
        if (historyId) {
            getDetails(historyId);
        }
    }, [historyId]);



    return (
        <>
            {isLoading ?
                <div className="flex-1 md:flex col sticky top-[114px] h-[500px] items-center justify-center p-4 text-center">
                    <LuLoader2 className='animate-spin text-[#5B9ECF]' size={40} />
                </div>
                :
                details ? (
                    <div className="bg-white xl:max-h-[80vh] xl:overflow-auto rounded border">
                        <DetailOffreHeader
                            title={details.intitule}
                            sector={getSectorialSkillTag(details.tags)[0]}
                            date_time_create={details.date_time_create}
                            dateModify={details.dateModify}
                            details={details}
                        />
                        <div className="bg-[#F7F9FC]">
                            <div className="mb-4 p-4">
                                <Modality
                                    salary={details.salaire}
                                    jtm={details.jtm}
                                    contract={details.id_contract}
                                    interventionTypeTags={getInterventionType(details.tags)}
                                    availability={avaibilityDateFormatter(details.availability, details.availability_date)}
                                    localisation={details.localisation}
                                    remote={details.remote}
                                    workTime={details.workTime}
                                    isComptable={isComptable}
                                />
                            </div>
                            <div className="p-4 mb-4">
                                <DetailOffreRequirement
                                    sectorialSkillTag={getSectorialSkillTag(details.tags)}
                                    computerSkillTag={getComputerSkillTag(details.tags)}
                                    fonctionalSkillTag={getFonctionalSkillTag(details.tags)}
                                    seniority={details.id_grade}
                                    languageSkillTag={getLanguageSkillTag(details.tags)}
                                    timeExperience={details.id_timeexperience}
                                />
                            </div>
                            <div className="p-4 mb-4">
                                <DetailOffreTitle title={"Descriptif de l'opportunité"} icon={<IoDocumentText />} fontSize={"text-[1.5rem]"} />
                                <div className="bg-greylight rounded p-2">
                                    <p>{parse(details?.description ?? "")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ) :
                    <div className="flex-1 hidden md:flex col border border-dashed rounded sticky top-[114px] h-[500px] items-center justify-center p-4 text-center">
                        <h1 className="text-[#e2e8f0]">Sélectionner une opportunité</h1>
                    </div>
            }
        </>
    );
}
