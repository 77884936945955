import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import parse from "html-react-parser";
//Composants
import ProfilRechercher from "./ProfilRechercher";
import PopupArchiver from "../IndexOffre/PopupArchiver";
//Icons
import { FaRegEdit } from "react-icons/fa";
import { LuLoader2 } from "react-icons/lu";
import { FaMapMarkerAlt } from "react-icons/fa";
import { GoKebabHorizontal } from "react-icons/go";
import { IoArchive } from "react-icons/io5";

import PopupDearchiver from "../IndexOffre/PopupDearchiver";
import PopupDelete from "../IndexOffre/PopupDelete";
import { FaTrash } from "react-icons/fa";
import UserItem from "./UserItem";
import ContextMenu from "Components/ContextMenu/ContextMenu";
import ContextItem from "Components/ContextMenu/ContextItem";
import AvaibilityBadge from "Components/AvaibilityBadge/AvaibilityBadge";

import { format } from "date-fns";
import { fr } from 'date-fns/locale'

export default function DetailOffre() {
  const [offre, setOffre] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [displayCandidat, setDisplayCandidat] = useState(true);
  const [popupArchiver, setPopupArchiver] = useState(false);
  const [popupDearchiver, setPopupDearchiver] = useState(false);
  const [popupDelete, setPopupDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const token = cookie.get("token");



  function fetchOffre() {
    axios.get(process.env.REACT_APP_API + `client/offre/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        console.log(response)
        setOffre(response.data)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (error.response.data.error === "L'id entré est incorrect") {
            navigate("/mon-espace")
          }
        }
      })
      .finally(() => {
        setIsLoading(false)
      });
  }

  function fetchAppointment() {
    axios.get(process.env.REACT_APP_API + `client/entretien`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        setAppointments(response.data)
      })
      .catch((error) => { });
  }

  useEffect(() => {
    fetchOffre();
    fetchAppointment();
  }, [])

  function DisplayCandidat() {
    setDisplayCandidat(true);
  }
  function CloseCandidat() {
    setDisplayCandidat(false);
  }
  function openArchiver() {
    setPopupArchiver(true);
  }
  function closeArchiver() {
    setPopupArchiver(false);
  }
  function openDearchiver() {
    setPopupDearchiver(true);
  }
  function closeDearchiver() {
    setPopupDearchiver(false);
  }
  function openDelete() {
    setPopupDelete(true);
  }
  function closeDelete() {
    setPopupDelete(false);
  }
  return (
    <div className="p-8 mb-8 min-h-[calc(100vh-100px)]">
      {
        isLoading ?
          <div className="min-h-[calc(100vh-170px)] flex justify-center items-center">
            <div className="text-[#2563EB]">
              <LuLoader2 size={48} className='animate-spin' />
            </div>
          </div>
          :
          <div className="max-w-[1000px] m-auto">
            <button onClick={() => navigate("/mon-espace/mes-offres")} className="block text-[#2563EB] font-extrabold hover:underline hover:cursor-pointer mb-4">{"<"} Retour</button>
            <div className="flex-col lg:flex-row flex gap-4 lg:items-start">
              <div className="flex-1 flex flex-col gap-4">
                <div className="bg-white p-4 rounded border">
                  <div className="mb-2 flex items-start md:items-center justify-between">
                    <p className="font-extrabold text-2xl sm:text-3xl text-[#0f172a]">{offre.intitule}</p>
                    <ContextMenu>
                      <ContextItem text={"Modifier"} callback={() => navigate(`/mon-espace/mes-offres/modification-offre/${offre.id}/recapitulatif`)} icon={<FaRegEdit />} />
                      <ContextItem text={offre.archiver ? "Désarchiver" : "Archiver"} callback={() => offre.archiver ? openDearchiver() : openArchiver()} icon={<IoArchive />} />
                      {
                        offre.archiver ?
                          <ContextItem text={"Supprimer"} callback={() => console.log("FIRE")} icon={<FaTrash />} />
                          :
                          null
                      }
                    </ContextMenu>
                  </div>
                  <div className="flex flex-col items-start gap-2">
                    <span class="inline-flex items-center rounded-md bg-[#EFF6FF] text-sm px-2 py-1 font-medium text-[#1D4ED8] ring-1 ring-inset ring-blue-700/10">{offre.tags.filter(t => t.tag.id_tag_level.id === 1)[0].tag.name}</span>
                    <div className="flex items-center gap-1 text-[#94a3b8] text-sm"><span><FaMapMarkerAlt /></span><span>{offre.localisation}</span></div>
                    <p>
              Date de création : <span class="items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"> {format(Date.parse(offre.date_time_create), "iiii d LLLL yyyy", { locale: fr })}</span>
            </p>
                  </div>
                </div>
                <div>
                  <p className="font-bold text-[20px] text-slate-900">Modalités</p>
                  <div className="flex flex-col lg:flex-row gap-2">
                    <div className="border p-2 rounded bg-white flex-1">
                      <small className="font-medium">Type de contrat</small>
                      <div className="flex flex-wrap gap-1">
                        {
                          offre.id_contract.map((contract, key) => (
                            <span class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{contract.name}</span>
                          ))
                        }
                      </div>
                    </div>

                    <div className="border p-2 rounded bg-white flex-1">
                      <small className="font-medium">Date de début</small>
                      <div className="flex flex-wrap gap-1">
                        <span className="flex items-center">
                          {offre && <AvaibilityBadge avaibility={{ "disponibility": offre.availability[0], "availability_date": offre.availability_date }} />}
                        </span>
                      </div>
                    </div>
                    <div className="border p-2 rounded bg-white flex-1">
                      <small className="font-medium">Rémunération</small>
                      <div className="flex flex-wrap gap-1">
                        {
                          offre.jtm ?
                            <span class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{offre.jtm} €</span>
                            :
                            null
                        }
                        {
                          offre.salaire ?
                            <span class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{offre.salaire} €</span>
                            :
                            null
                        }
                        {
                          offre.salaire == null || offre.jtm == null ?
                            <p>Non précisé</p>
                            : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="font-bold text-[20px] text-slate-900">Profil recherché</p>
                  <div className="bg-white p-4 border rounded">
                    <ProfilRechercher offre={offre} />
                  </div>
                </div>
                <div>
                  <p className="font-bold text-[20px] text-slate-900">Description</p>
                  <div className="bg-white p-4 border rounded">
                    <div className="bg-[#EFF5FA] rounded-[5px] py-1 px-2 text-[14px] mt-4">
                      {parse(offre.description ?? "")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded">
                <p className="font-bold text-[20px] text-slate-900">Candidatures</p>
                {
                  offre.postuler.length > 0 ?
                    <div className="flex flex-col divide-y border rounded">
                      {
                        offre.postuler.map((u, key) => (
                          u.status !== "refuser" && <UserItem key={key} postuler={u} fetchOffre={fetchOffre} idOffre={`?offre=${id}`} title={u.id_user.titre} avaibility={u.id_user.disponibility} />
                        ))
                      }
                    </div>
                    :
                    <div className="border-2 border-dotted p-4 rounded text-center text-[#cbd5e1]">
                      Aucune candidature pour cette opportunité
                    </div>

                }
              </div>
            </div>
          </div>
      }
      {popupArchiver && <PopupArchiver offre={offre} closeArchiver={closeArchiver} fetchOffre={fetchOffre} />}
      {popupDearchiver && <PopupDearchiver offre={offre} closeDearchiver={closeDearchiver} fetchOffre={fetchOffre} />}
      {popupDelete && <PopupDelete offre={offre} closeDelete={closeDelete} />}
    </div>
  )
}