import React from 'react'

function customer() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3087_951)">
                <path d="M36.1248 9.25091C37.7129 7.043 36.0865 3.87546 33.3465 3.89093C30.6067 3.87538 28.9801 7.04355 30.5684 9.25099C29.0891 9.83363 28.039 11.2757 28.039 12.9593V15.5529C28.039 16.0275 28.4238 16.4123 28.8985 16.4123H37.7947C38.2693 16.4123 38.6541 16.0275 38.6541 15.5529V12.9593C38.6542 11.2756 37.604 9.83355 36.1248 9.25091ZM33.3466 5.60979C34.2742 5.60979 35.0289 6.36449 35.0289 7.29209C34.9365 9.52391 31.7563 9.52328 31.6642 7.29209C31.6643 6.36449 32.419 5.60979 33.3466 5.60979ZM36.9353 14.6934H29.7581V12.9593C29.7581 11.7099 30.7746 10.6934 32.0239 10.6934H34.6694C35.9188 10.6934 36.9353 11.7099 36.9353 12.9593V14.6934Z" fill="#263238" />
                <path d="M9.35668 9.25093C10.9448 7.04302 9.3184 3.87547 6.57848 3.89087C3.83879 3.87532 2.21205 7.04349 3.80027 9.25085C2.32104 9.83349 1.27085 11.2756 1.27085 12.9592V15.5528C1.27085 16.0275 1.65566 16.4123 2.13032 16.4123H11.0265C11.5011 16.4123 11.886 16.0275 11.886 15.5528V12.9592C11.886 11.2757 10.8359 9.83357 9.35668 9.25093ZM6.57848 5.60981C7.50608 5.60981 8.26078 6.3645 8.26078 7.29211C8.16835 9.52392 4.98822 9.5233 4.8961 7.29211C4.89618 6.3645 5.65087 5.60981 6.57848 5.60981ZM10.1671 14.6934H2.98987V12.9593C2.98987 11.71 4.00639 10.6934 5.25575 10.6934H7.90121C9.15057 10.6934 10.1671 11.71 10.1671 12.9593V14.6934Z" fill="#263238" />
                <path d="M22.7801 28.9495C24.3689 26.7419 22.7421 23.5742 20.0023 23.5897C17.2627 23.5742 15.6355 26.7424 17.2245 28.9495C15.7451 29.5321 14.6947 30.9743 14.6947 32.6581V35.2517C14.6947 35.7263 15.0795 36.1111 15.5542 36.1111H24.4504C24.9251 36.1111 25.3099 35.7263 25.3099 35.2517V32.6581C25.3099 30.9742 24.2595 29.532 22.7801 28.9495ZM20.0023 25.3086C20.9299 25.3086 21.6846 26.0633 21.6846 26.9909C21.5921 29.2227 18.412 29.2221 18.3199 26.9909C18.32 26.0633 19.0747 25.3086 20.0023 25.3086ZM23.591 34.3922H16.4136V32.6581C16.4136 31.4087 17.4301 30.3922 18.6795 30.3922H21.325C22.5743 30.3922 23.5909 31.4087 23.5909 32.6581V34.3922H23.591Z" fill="#263238" />
                <path d="M22.7805 9.25091C24.3685 7.043 22.7422 3.87546 20.0022 3.89093C17.2625 3.87538 15.6358 7.04355 17.224 9.25091C15.7448 9.83355 14.6946 11.2757 14.6946 12.9593V15.5529C14.6946 16.0275 15.0794 16.4123 15.554 16.4123H24.4504C24.9251 16.4123 25.3099 16.0275 25.3099 15.5529V12.9593C25.3099 11.2756 24.2597 9.83355 22.7805 9.25091ZM20.0023 5.60979C20.9299 5.60979 21.6846 6.36449 21.6846 7.29209C21.5921 9.52391 18.412 9.52328 18.3199 7.29209C18.32 6.36449 19.0747 5.60979 20.0023 5.60979ZM23.591 14.6934H16.4136V12.9593C16.4136 11.7099 17.4301 10.6934 18.6795 10.6934H21.325C22.5744 10.6934 23.5909 11.7099 23.5909 12.9593V14.6934H23.591Z" fill="#5B9ECF" />
                <path d="M36.1244 28.9495C37.7132 26.7419 36.0864 23.5742 33.3466 23.5897C30.607 23.5742 28.9798 26.7424 30.5688 28.9496C29.0894 29.5322 28.0391 30.9744 28.0391 32.6581V35.2517C28.0391 35.7263 28.4239 36.1111 28.8986 36.1111H37.7947C38.2694 36.1111 38.6542 35.7263 38.6542 35.2517V32.6581C38.6542 30.9742 37.6039 29.532 36.1244 28.9495ZM33.3466 25.3086C34.2742 25.3086 35.0289 26.0633 35.0289 26.9909C34.9365 29.2227 31.7563 29.2221 31.6642 26.9909C31.6643 26.0633 32.419 25.3086 33.3466 25.3086ZM36.9353 34.3922H29.7581V32.6581C29.7581 31.4087 30.7746 30.3922 32.0239 30.3922H34.6694C35.9188 30.3922 36.9353 31.4087 36.9353 32.6581V34.3922Z" fill="#5B9ECF" />
                <path d="M9.3563 28.9496C10.9451 26.7418 9.31832 23.5743 6.57848 23.5897C3.83887 23.5741 2.21174 26.7424 3.80075 28.9495C2.32128 29.532 1.27093 30.9742 1.27093 32.658V35.2516C1.27093 35.7263 1.65574 36.1111 2.1304 36.1111H11.0266C11.5012 36.1111 11.886 35.7263 11.886 35.2516V32.658C11.886 30.9743 10.8357 29.5321 9.3563 28.9496ZM6.57848 25.3086C7.50609 25.3086 8.26078 26.0633 8.26078 26.9909C8.16835 29.2227 4.98822 29.2221 4.8961 26.9909C4.89618 26.0633 5.65088 25.3086 6.57848 25.3086ZM10.1671 34.3922H2.98987V32.6581C2.98987 31.4087 4.00639 30.3922 5.25575 30.3922H7.90121C9.15057 30.3922 10.1671 31.4087 10.1671 32.6581V34.3922Z" fill="#5B9ECF" />
                <path d="M39.1451 20.8604H0.859484C0.384821 20.8604 1.14441e-05 20.4756 1.14441e-05 20.001C1.14441e-05 19.5263 0.384821 19.1415 0.859484 19.1415H39.1451C40.2728 19.1815 40.2972 20.8094 39.1451 20.8604Z" fill="#263238" />
            </g>
            <defs>
                <clipPath id="clip0_3087_951">
                    <rect width="40" height="40" fill="white" transform="translate(0 0.000976562)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default customer