import React from 'react'
import Quote from 'assets/svg/Quote'


export default function PartieCentral() {
    return (

        <section className='containerxl py-24 grid gap-9'>

            <article className=' p-8 hover:text-[#497EA6] border-[1.5px] border-greylight hover:border-lightblue rounded-xl bg-greylight '>
                <div className='flex flex-col gap-5 md:flex-row'>
                    <div className='grid items-center justify-center md:w-[40%]'>
                        <h5 className='italic hover:text-[#497EA6] font-medium text-xl bigscreen:text-[25px]'>Les fondateurs</h5>
                    </div>
                    <div className='flex gap-5 md:w-[60%]'>
                        <div>
                            <Quote className='w-6 h-6' />
                        </div>
                        <div className='grid gap-3 text-black'>
                            <p className='bigscreen:text-[20px] text-justify'>Issus de grands cabinets de conseil et de recrutement,
                                les deux associés-fondateurs font bénéficier la plateforme de
                                leur connaissance approfondie des métiers de la finance.</p>

                            <p className='bigscreen:text-[20px]'> Doté d'un fort esprit entrepreneurial  et d'un regard visionnaire sur le secteur,
                                ils ont crée ce modèle disruptif de conseil et de recrutement digital.</p>

                            <p className='bigscreen:text-[20px]'>Ils travaillent depuis plusieurs années sur la construction
                                d'un<i> data center </i>connecté au <i>hub</i>.</p>

                            <p className='bigscreen:text-[20px]'>Ainsi, la plateforme adresse un vivier de plus de 18 000 profils ayant
                                des compétences en finance, ce qui en fait le premier <i>hub </i>
                                de France sur son secteur.</p>
                        </div>
                    </div>

                </div>
            </article>

            <article className=' p-8 hover:text-[#497EA6] border-[1.5px] border-greylight hover:border-lightblue rounded-xl bg-greylight '>
                <div className='flex flex-col gap-5 md:flex-row'>

                    <div className='grid items-center justify-center md:w-[40%]'>
                        <h5 className='italic hover:text-[#497EA6] font-medium text-xl bigscreen:text-[25px]'>L’équipe MyFinPlace</h5>
                    </div>
                    <div className='flex gap-5 md:w-[60%]'>
                        <div>
                            <Quote className='w-6 h-6' />
                        </div>
                        <div className='grid gap-3 text-justify text-black'>
                            <p className='bigscreen:text-[20px]'>La plateforme est développée en interne par une équipe composée
                                d'une dizaine de spécialistes de la <i> tech </i>:
                                developpeurs, UX designers, <i> data conceptors </i>, etc.</p>
                            <p className='bigscreen:text-[20px]'>MyFinPlace recrute de nouveaux collaborateurs pour étoffer cette équipe pluridisciplinaire :</p>
                            <a href="mailto:contact@myfinplace.com" className='w-fit mt-12'>
                                <button className='bg-ligthblue hover:bg-hover text-white font-semibold rounded px-6 py-2.5 bigscreen:text-[20px]'>Nous rejoindre</button>
                            </a>
                        </div>
                    </div>

                </div>
            </article>

            <article className=' p-8 hover:text-[#497EA6] border-[1.5px] border-greylight hover:border-lightblue rounded-xl bg-greylight '>
                <div className='flex flex-col gap-5 md:flex-row'>

                    <div className='grid items-center justify-center md:w-[40%]'>
                        <h5 className='italic hover:text-[#497EA6] font-medium text-xl bigscreen:text-[25px]'>Les ambitions</h5>
                    </div>
                    <div className='flex gap-5 md:w-[60%]'>
                        <div>
                            <Quote className='w-6 h-6' />
                        </div>
                        <div className='grid gap-3 text-justify text-black'>
                            <p className='bigscreen:text-[20px]'> L'objectif de MyFinPlace est de devenir un acteur incontournable
                                du conseil et du recrutement en France et en Europe.</p>

                            <p className='bigscreen:text-[20px]'>La digitalisation des processus conseil et du recrutement permet à tous les secteurs de trouver
                                des profils disponibles et d'interagir directement avec eux,
                                apportant ainsi à la fois agilité et efficacité.</p>
                            <p className='bigscreen:text-[20px]'> Par ailleurs, l'utilisation de la technologie permet de proposer
                                des prestations extrêmement compétitives en termes de coût à supporter
                                par le client. </p>
                            <p className='bigscreen:text-[20px]'>Au plaisir de nous rencontrer, car notre avenir s'écrit en commun.</p>

                        </div>
                    </div>

                </div>
            </article>



        </section>

    )
}