import React from "react";
import { GoClockFill } from "react-icons/go";


function Planifier() {
 

    return (
        <div>
        <span className='text-sm text-[#FFA500]'><GoClockFill /></span>
        </div>
    );
}

export default Planifier;
