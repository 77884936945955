
import { React } from 'react'
function DashboardSvg(props) {


    return (
        <>
            <svg width="13" height="12" viewBox="0 0 13 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_135_282)">
                    <path d="M5.85498 0.122266C6.1848 -0.0394531 6.56553 -0.0394531 6.89534 0.122266L11.7341 2.48945C11.9223 2.58086 12.0418 2.78008 12.0418 3.00039C12.0418 3.2207 11.9223 3.41992 11.7341 3.51133L6.89534 5.87852C6.56553 6.04023 6.1848 6.04023 5.85498 5.87852L1.01618 3.51133C0.828027 3.41758 0.708496 3.21836 0.708496 3.00039C0.708496 2.78242 0.828027 2.58086 1.01618 2.48945L5.85498 0.122266ZM10.5565 4.91289L11.7341 5.48945C11.9223 5.58086 12.0418 5.78008 12.0418 6.00039C12.0418 6.2207 11.9223 6.41992 11.7341 6.51133L6.89534 8.87852C6.56553 9.04023 6.1848 9.04023 5.85498 8.87852L1.01618 6.51133C0.828027 6.41758 0.708496 6.21836 0.708496 6.00039C0.708496 5.78242 0.828027 5.58086 1.01618 5.48945L2.19378 4.91289L5.55837 6.5582C6.07633 6.81133 6.67399 6.81133 7.19196 6.5582L10.5565 4.91289ZM7.19196 9.5582L10.5565 7.91289L11.7341 8.48945C11.9223 8.58086 12.0418 8.78008 12.0418 9.00039C12.0418 9.2207 11.9223 9.41992 11.7341 9.51133L6.89534 11.8785C6.56553 12.0402 6.1848 12.0402 5.85498 11.8785L1.01618 9.51133C0.828027 9.41758 0.708496 9.21836 0.708496 9.00039C0.708496 8.78242 0.828027 8.58086 1.01618 8.48945L2.19378 7.91289L5.55837 9.5582C6.07633 9.81133 6.67399 9.81133 7.19196 9.5582Z" fill="currentColor" />
                </g>
                <defs>
                    <clipPath id="clip0_135_282">
                        <rect width="12.75" height="12" fill="currentColor" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}

export default DashboardSvg