import React from 'react'

function arrow(props) {
    return (
        <svg className={props.classProps ? props.classProps : ""} width={props.width ? props.width : "32"} height={props.height ? props.height : "32"} viewBox="0 0 32 30"
            fill={props.color ? props.color : "#5B9ECF"} xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M31.0649 7.44111C31.0879 19.148 24.6709 23.932 11.0549 22.368C8.91595 22.115 8.91595 22.092 10.6179 23.288C12.5499 24.645 16.6899 28.67 16.4829 29.015C15.9079 29.935 14.1139 29.0842 11.4689 26.6692C8.96195 24.3692 5.97195 22.345 3.90195 21.563C0.336946 20.206 -0.00805092 18.987 3.02795 18.527C5.44295 18.159 7.02995 17.4232 10.8479 14.8242C13.9529 12.7081 14.6429 12.5241 16.1149 13.2831C18.1159 14.3181 16.989 15.698 11.4919 18.941C9.72095 19.976 9.90495 20.091 13.6769 20.091C24.096 20.091 28.9949 13.0991 26.8789 1.32309C26.5339 -0.539913 31.0419 -1.06889 31.0649 7.44111Z"
                fill={props.color ? props.color : "#5B9ECF"} />
        </svg>
    )
}

export default arrow