import React from 'react'

function ResponsiveBurger({ setOpenMenu, openMenu }) {

    const handleOpenMenu = () => {
        setOpenMenu(!openMenu)
    }

    window.addEventListener("resize", () => {
        setOpenMenu(false)
    });


    return (
        <button onClick={handleOpenMenu} className="flex flex-col justify-center gap-3 xl:hidden">
            {
                !openMenu ?
                    <>

                        <div className="bg-middleblue w-16 h-2"></div>
                        <div className="bg-middleblue w-16 h-2"></div>
                        <div className="bg-middleblue w-16 h-2"></div>
                    </> :
                    <>
                        <svg className="h-16 w-16 text-middleblue" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>

                    </>
            }
        </button>

    );
}

export default ResponsiveBurger