import React from 'react'

function AvaibilityBadge({ avaibility }) {

  const disponibilityFormatter = (e) => {
    switch (e.disponibility) {
      case "immédiate":
        return (
          <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
            <div className="rounded-full bg-[#00B553] h-2 w-2 mr-2"></div>
            <p className="text-xs">{e.disponibility}</p>
          </span>
        );
      case "Immédiate":
        return (
          <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
            <div className="rounded-full bg-[#00B553] h-2 w-2 mr-2"></div>
            <p className="text-xs">{e.disponibility}</p>
          </span>
        );
      case "Préavis de 3 mois":
        return (
          <span className='inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10'>
            <div className="rounded-full bg-[#D80027] h-2 w-2 mr-2"></div>
            <p className="text-xs">{e.disponibility}</p>
          </span>
        );
      case "Préavis de 2 mois":
        return (
          <span className='inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10'>
            <div className="rounded-full bg-[#D80027] h-2 w-2 mr-2"></div>
            <p className="text-xs">{e.disponibility}</p>
          </span>
        );
      case "Préavis de 1 mois":
        return (
          <span className='inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10'>
            <div className="rounded-full bg-[#D80027] h-2 w-2 mr-2"></div>
            <p className="text-xs">{e.disponibility}</p>
          </span>
        );
      default:
        let date = "";
        if (e.disponibility === "Date connue") {
          if (e.availability_date !== null) {
            //La date connu est dans le passé, il est maintenant disponible
            if (new Date(e.availability_date) < new Date()) {
              return (
                <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
                  <div className="rounded-full bg-[#00B553] h-2 w-2 mr-2"></div>
                  <p className="text-xs">Immédiate</p>
                </span>

              );
            } else {
              //La date connu est dans le future, il faut affiché la date
              date = new Date(e.availability_date)
                .toLocaleDateString("fr-FR", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                });
              return (
                <span className='inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20'>
                  <div className="rounded-full bg-[#FFD600] h-2 w-2 mr-2"></div>
                  <p className="text-xs">{date}</p>
                </span>

              );
            }
          } else {
            //La valeur de la date connu est null
            return (
              <>
                <div className="rounded-full bg-[#FFD600] h-2 w-2 mr-2"></div>
                <p className="text-xs">Date non indiqué</p>
              </>
            );
          }
        }
        return (
          <>
            <div className="rounded-full bg-[#FFD600] h-2 w-2 mr-2"></div>
            <p className="text-xs">{e.disponibility}</p>
          </>
        );
    }
  }
  return (
    <div>
      {disponibilityFormatter(avaibility)}
    </div>
  )
}

export default AvaibilityBadge