import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./ckeditor.css";

export default function Wysiwig(props) {
  const [text, setText] = useState("");

  useEffect(() => {
    if (props.setText) {
      props.setText(text);
    }
  }, [text])

  useEffect(() => {
    if (props.text) {
      setText(props.text)
    } else {
      setText("")
    }
  }, [props.text]);
  return (
    <div className="editor" >
      <CKEditor
        editor={ClassicEditor}
        data={text}
        config={{
          toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "bulletedList",
            "numberedList",
            "undo",
            "redo"
          ],
          language: "fr",
          mediaEmbed: { providers: [] }
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setText(data);
        }}
      />
    </div>
  );
}


// import { CKEditor } from "@ckeditor/ckeditor5-react";

// export default function Wysiwig() {

//     const [text,setText] = useState("")

//     return (
//         <>
//             <div className="editor">

//                 <CKEditor
//                 editor={ClassicEditor}
//                 data={text}
//                 onChange={(event,editor) =>{
//                     const data = editor.getData()
//                     setText(data)}}
//                 />
//             </div>

//         </>
//     );
// }
