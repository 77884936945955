import { Svg, Path } from '@react-pdf/renderer';

const Office = () => (
  <Svg
    width="18"
    height="18"
    viewBox="0 0 32 32"
    fill="white"
    xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M3 28H29M5 4V28M19 4V28M27 10V28M9 9H10M9 13H10M9 17H10M14 9H15M14 13H15M14 17H15M9 28V23.5C9 22.672 9.672 22 10.5 22H13.5C14.328 22 15 22.672 15 23.5V28M4 4H20M19 10H28M23 15H23.0107V15.0107H23V15ZM23 19H23.0107V19.0107H23V19ZM23 23H23.0107V23.0107H23V23Z"
      stroke="#BDD8EC"
      strokeWidth="1.5"
    />
  </Svg>
)

export default Office