import React from 'react'
import { LuLoader2 } from "react-icons/lu";

function SmallPrimaryButton({ onClick, text, disabled, isLoading }) {
    const disabledStyle = "border border-[#f0f0f0] bg-[#f0f0f0] text-[#999] cursor-not-allowed"


    return (
        <button
            disabled={disabled}
            className={`font-regular py-2 px-3 flex justify-center rounded-[5px] text-sm ${disabled === true ? disabledStyle : "border border-[#5B9ECF] bg-[#5B9ECF] text-white hover:bg-[#497EA6]"}  `}
            onClick={() => onClick()}>
            {isLoading ? <LuLoader2 className='animate-spin'/> :  text }
        </button>
    )
}

export default SmallPrimaryButton