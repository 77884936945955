import { React, useState } from "react";
import 'moment/locale/fr';
import Cell from "./Cell";
import { add, differenceInDays, endOfMonth, format, setDate, startOfMonth, sub } from "date-fns";
import { fr } from 'date-fns/locale'
import { useNavigate } from "react-router-dom";
function AppoitmentCalendar(props) {
  const navigate = useNavigate()

  const dateOfDay = Date.parse(props.value)
  const todayDate = Date.parse(new Date());

  const month = format(dateOfDay, "LLLL", { locale: fr })
  const years = format(dateOfDay, "yyyy", { locale: fr })


  const dayOfWeek = ['Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.', 'Dim.']
  const startDate = startOfMonth(dateOfDay)
  const endDate = endOfMonth(dateOfDay)
  const numDays = differenceInDays(endDate, startDate) + 1

  const prefixDays = startDate.getDay() - 1;
  const suffixDays = 6 - endDate.getDay()

  const prevMonth = () => props.onChange && sub(todayDate, { years: 1 }) < dateOfDay && props.onChange(sub(dateOfDay, { months: 1 }))
  const nextMonth = () => props.onChange && add(todayDate, { years: 1 }) > add(dateOfDay, { days: 1 }) && props.onChange(add(dateOfDay, { months: 1 }))

  const prevYears = () => props.onChange && format(todayDate, "yyyy/M") <= format(dateOfDay, "yyyy/M") ? props.onChange(sub(dateOfDay, { years: 1 })) : props.onChange(sub(todayDate, { years: 1 }))
  const nextYears = () => props.onChange && format(todayDate, "yyyy/M") >= format(dateOfDay, "yyyy/M") ? props.onChange(add(dateOfDay, { years: 1 })) : props.onChange(add(todayDate, { years: 1 }))

  const handleClickDate = (index) => {
    const date = setDate(dateOfDay, index)
    props.onChange && props.onChange(date)
    navigate("/mon-espace/mes-entretiens")
  }
  return (
    <div className="flex flex-col mt-16 items-center">
      <div className="w-full max-w-[650px] xl:max-w-none pb-6 border-2 border-middleblue rounded">
        <div className="bg-middleblue grid grid-cols-7 items-center justify-center text-center  text-white">
          <Cell className="justify-center hover:cursor-pointer" onClick={prevYears}>{'<<'}</Cell>
          <Cell className="justify-center hover:cursor-pointer" onClick={prevMonth}>{'<'}</Cell>
          <Cell className="justify-center col-span-3">{format(dateOfDay, " LLLL yyyy", { locale: fr })}</Cell>
          <Cell className="justify-center hover:cursor-pointer" onClick={nextMonth}>{'>'}</Cell>
          <Cell className="justify-center hover:cursor-pointer" onClick={nextYears}>{'>>'}</Cell>
        </div>
        <div className="grid grid-cols-7 items-center justify-center text-center md:gap-2">
          {dayOfWeek.map((day) => (
            <Cell key={day} className="font-bold justify-center ">{day}</Cell>
          ))}

          {/* index des date */}
          {Array.from({ length: prefixDays }).map((_, index) => (
            <Cell key={index} />
          ))}

          {Array.from({ length: numDays }).map((_, index) => {
            const date = index + 1;
            const isCurrentDate = date === props.value.getDate()
            return (
              <Cell eventCalendar={props.data} day={date} month={month} years={years} isStatus={true} isActive={isCurrentDate} onClick={() => handleClickDate(date)} key={date}>{date}</Cell>
            )
          })}

          {/* index des date */}
          {Array.from({ length: suffixDays }).map((_, index) => (
            <Cell key={index} />
          ))}
        </div>
      </div>
    </div>
  )
}
export default AppoitmentCalendar;