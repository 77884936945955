import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useCreatePath } from "react-admin";
import cookie from "js-cookie";
import axios from "axios";

const formatTime = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}h${formattedMinutes}`;
};

export default function EntretiensRealiser() {
  const [data, setData] = useState([]);
  const [nb, setNb] = useState();
  useEffect(() => {
    axios.get(process.env.REACT_APP_API + `GetStatusEntretien?status=réaliser`, {
      headers: {
        Authorization: `Bearer ${cookie.get("token")}`
      }
    })
      .then((response) => {
        setData(response.data["hydra:member"]);
        setNb(response.data["hydra:totalItems"])
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const createPath = useCreatePath();
  const CreateLink = (resource, id, text, type) => {
    return <Link to={createPath({ resource: resource, type: type, id: id })} className="text-[#497EA6] hover:underline">{text}</Link>
  };

  return (
    <>
      <section className="max-h-[50vh] overflow-y-auto smaller-scroll-bar mb-6">
        <p className="text-red-500 pb-4">Entretiens réalisés ({nb})</p>
        {nb === undefined ? (
          <p>Chargement</p>
        ) : (
          nb === 0 ? (
            <p className="">Aucun entretien</p>
          ) : (
            <table className="bg-white drop-shadow w-full">
              <tr className="text-[14px]">
                <td className="py-2 px-4 font-medium text-[15px]">Id entretien</td>
                <td className="py-2 px-4 font-medium text-[15px]">Id du client</td>
                <td className="py-2 px-4 font-medium text-[15px]">Prénom</td>
                <td className="py-2 px-4 font-medium text-[15px]">Nom</td>
                <td className="py-2 px-4 font-medium text-[15px]">Identité</td>
                <td className="py-2 px-4 font-medium text-[15px]">Adresse mail</td>
                <td className="py-2 px-4 font-medium text-[15px]">Candidat/consultant</td>
                <td className="py-2 px-4 font-medium text-[15px]">Prénom</td>
                <td className="py-2 px-4 font-medium text-[15px]">Nom</td>
                <td className="py-2 px-4 font-medium text-[15px]">Identité</td>
                <td className="py-2 px-4 font-medium text-[15px]">Adresse mail</td>
                <td className="py-2 px-4 font-medium text-[15px]">Date de l'entretien</td>
              </tr>
              {data.map((e, index) => (
                <tr className="text-[12px]">
                  <td className="py-2 px-4 border-b">{CreateLink('appointments', e["@id"], e.id, 'edit')}</td>
                  <td className="py-2 px-4 border-b">{CreateLink('clients', e.id_client["@id"], e.id_client.id, 'show')}</td>
                  <td className="py-2 px-4 border-b">{e.id_client.first_name}</td>
                  <td className="py-2 px-4 border-b">{e.id_client.last_name}</td>
                  <td className="py-2 px-4 border-b">{e.id_client.roles[0]}</td>
                  <td className="py-2 px-4 border-b">{e.id_client.email}</td>
                  <td className="py-2 px-4 border-b">{CreateLink('users', e.id_user["@id"], e.id_user.id, 'show')}</td>
                  <td className="py-2 px-4 border-b">{e.id_user.first_name}</td>
                  <td className="py-2 px-4 border-b">{e.id_user.last_name}</td>
                  <td className="py-2 px-4 border-b">{e.id_user.roles[0]}</td>
                  <td className="py-2 px-4 border-b">{e.id_user.email}</td>
                  <td className="py-2 px-4 border-b">{new Date(e.date_choice).toLocaleDateString("fr-FR", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  })} à {formatTime(new Date(e.date_choice))}</td>
                </tr>
              ))}
            </table>
          )
        )}
      </section>
    </>
  );
}