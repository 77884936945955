import React, { useContext, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { AlertMessageContext } from "Context/AlertMessageProvider";
import { AuthContext } from "Context/AuthProvider";
import { isClient, isUser } from "utils/roles";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { LuLoader2 } from "react-icons/lu";

export default function Confirmer(props) {
  const appointment = props.appointment
  const [isLoading, setIsLoading] = useState(false);
  const token = cookie.get("token");
  const { alert } = useContext(AlertMessageContext);
  const { value } = useContext(AuthContext);

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}h${formattedMinutes}`;
  };

  function closeConfirmer() {
    props.setPopupConfirmer(false)
  }

  function onSubmit() {
    const type = isUser(value.user) ? "user" : isClient(value.user) ? "client" : null;
    if (type === null) {
      // Si le type n'est pas définie, on ne fait pas de request
      return
    }
    setIsLoading(true)
    axios.patch(
      process.env.REACT_APP_API + `${type}/entretien/validate/${appointment.id}`, {},
      {
        headers: {
          "Content-Type": "application/merge-patch+json",
          Authorization: `Bearer ${token}`
        }
      }
    )
      .then((res) => {
        alert.successAlert("La date d'entretien a été validée avec succès !");
        props.getAppoitment();
        setIsLoading(false)
        closeConfirmer();
      })
      .catch((error) => {
        alert.errorAlert("Erreur lors de la validation de la date d’entretien !");
        setIsLoading(false)
      });
  }

  return (
    <>
      <div className="fixed h-full w-full top-0 left-0 bg-black opacity-75 z-30"></div>
      <div className="fixed w-[80vw] md:w-[60vw] xl:w-[40vw] top-[25vh] left-[10vw] md:left-[20vw] xl:left-[30vw] bg-white rounded-[5px] z-40 py-8 px-12">
        <div className="flex w-full justify-center">
          <span className='text-[#DCFCE6] bg-[#00C455] inline-block rounded-full'>
            <IoIosCheckmarkCircle size={72} />
          </span>
        </div>
        <p className="font-medium text-black text-2xl text-center mt-4">Confirmation de l'entretien</p>
        <p className="text-[#6B7280] text-center mt-2">Confirmer vous l’entretien avec
          <span className="text-[#0F6FDE] font-bold">
            {appointment.id_client && " " + appointment.id_client.company + " "}
            {appointment.id_user && " " + appointment.id_user.first_name + " " + appointment.id_user.last_name + " "}
          </span>
          le <span className="text-black font-bold">{appointment && new Date(appointment.date_choice).toLocaleDateString("fr-FR", {
            weekday: "long",
            day: "2-digit",
            month: "long",
            year: "numeric",
          })}
            <span className='font-normal'> à </span>
            {appointment && formatTime(new Date(appointment.date_choice))}
          </span>
        </p>
        <div className="flex w-full justify-center gap-2 md:gap-6 mt-4">
          <div onClick={closeConfirmer} className="text-center max-w-[250px] border border-[#2563EB] text-[#2563EB] hover:bg-[#2563EB] hover:text-white hover:cursor-pointer py-2 w-full">
            Annuler
          </div>
          <button onClick={onSubmit} disabled={isLoading} className="text-center max-w-[250px] flex justify-center items-center border border-[#2563EB] bg-[#2563EB] text-white hover:bg-white hover:text-[#2563EB] hover:cursor-pointer disabled:bg-[#ADADAD] disabled:cursor-wait disabled:text-white disabled:border-none py-2 w-full">
            {isLoading ? <LuLoader2 className='animate-spin' /> : "Confirmer"}
          </button>
        </div>
      </div>
    </>
  )
}