import { useState, useEffect } from 'react'
import Vector from '../../assets/svg/Vector'
import Checkbox from 'Components/Forms/CheckBox/Checkbox'
import Badge from 'Components/Badge/Badge'


function Accordion({ title, data, onChange, name, state, open, handleOpen, handleLevel }) {
    const [search, setSearch] = useState("")
    const removeAccents = str => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    const selectTagHandler = (e) => {
        setSearch("")
        onChange(e)
    }

    return (
        <div className='max-w-[750px]'>
            <div className={`flex items-center justify-between p-3 border cursor-pointer hover:bg-greylight ${open ? "bg-greylight" : null}`} onClick={() => { handleOpen(name) }}>
                <h5 className='text-sm flex gap-3'>{title} 
                    <Badge mode="info" name="min : 1" />
                <Badge mode="warning" name="max : 10" /> 
        </h5>
        
                <div id="plus1">
                    <Vector classProps={open ? "rotate-180 transition duration-500" : "transition duration-500"} alt="+" />
                </div>
            </div>
            <div className={`${open ? "animation-box-visible my-4" : "animation-box-hidden"}`}>
                <div className="animation-content">
                    <div className='p-1 pb-4'>
                        <input name='firstname' id='firstname' placeholder='Rechercher votre competence' className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" onChange={(e) => setSearch(e.target.value)} />

                    </div>
                    <div className="flex gap-1 items-center flex-wrap">
                        {
                            data.filter(x => removeAccents(x.name.toLowerCase().replace(/\/ /g, '')).includes(removeAccents(search.toLowerCase().replace(/\/ /g, '')))).map(skill => (
                                <Checkbox
                                    tagLevel={name}
                                    key={skill.id}
                                    id={skill.id}
                                    name={name}
                                    label={skill.name}
                                    value={skill.id}
                                    checked={state.filter(item => item.tag === `api/tags/${skill.id}`).length > 0}
                                    level={state.filter(item => item.tag === `api/tags/${skill.id}`).length > 0 ? state.filter(item => item.tag === `api/tags/${skill.id}`)[0].level : 1}
                                    onChange={(e) => selectTagHandler(e)}
                                    handleLevel={handleLevel} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Accordion