import React from 'react'

function pana() {
    return (
        <svg width="312" height="192" viewBox="0 0 312 192" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M197.71 86.5466L203.234 88.726C204.735 93.128 207.828 96.8094 211.906 99.0465C215.983 101.284 220.75 101.915 225.27 100.816C229.789 99.7166 233.734 96.9669 236.329 93.1071C238.924 89.2473 239.981 84.5565 239.293 79.9567C238.605 75.3569 236.221 71.1807 232.611 68.249C229 65.3174 224.423 63.8422 219.78 64.1135C215.137 64.3848 210.764 66.383 207.519 69.7153C204.274 73.0475 202.393 77.4729 202.246 82.1215L197.71 86.5466Z" fill="white" />
            <path d="M212.669 82.9808L215.167 80.4826L219.987 84.7659L227.876 76.5757L230.197 79.074L219.632 89.5853L212.669 82.9808Z" fill="#263238" />
            <path d="M69.8288 157.517C71.6246 156.676 73.6422 156.431 75.5872 156.817C76.637 156.99 77.7267 157.304 78.4399 158.088C79.153 158.872 79.2859 160.259 78.4664 160.937C77.9373 161.286 77.3036 161.443 76.6725 161.379C75.1443 161.379 73.4522 161.092 72.2429 162.008C71.5475 162.531 71.0868 163.404 70.254 163.661C69.8382 163.758 69.4019 163.717 69.0116 163.543C68.6214 163.37 68.2986 163.074 68.0924 162.699C67.6959 161.935 67.6087 161.046 67.8488 160.219C67.9486 159.647 68.1791 159.105 68.5226 158.636C68.866 158.168 69.313 157.784 69.8288 157.517Z" fill="#455A64" />
            <path d="M65.8155 149.774C66.8874 151.307 68.9737 150.797 69.9394 149.863C70.905 148.928 71.317 147.573 71.7112 146.279C72.5617 143.325 73.4166 140.224 72.7876 137.216C72.6346 136.304 72.233 135.452 71.627 134.753C71.3219 134.408 70.9311 134.149 70.494 134.003C70.057 133.857 69.5892 133.828 69.1376 133.921C68.0834 134.2 67.4323 135.25 66.9583 136.211C65.5685 139.105 64.8392 142.271 64.8232 145.482C64.8232 146.975 64.996 148.543 65.8376 149.774" fill="#455A64" />
            <path d="M62.8563 151.971C64.0257 150.589 64.2428 148.574 63.7422 146.842C63.1571 145.113 62.1055 143.58 60.7035 142.412C59.1145 140.999 57.2654 139.908 55.2596 139.201C54.484 138.888 53.65 138.746 52.8145 138.784C52.3963 138.808 51.9903 138.934 51.632 139.151C51.2738 139.368 50.9741 139.669 50.7592 140.029C50.1833 141.119 50.7326 142.448 51.3306 143.528C52.377 145.434 53.5723 147.255 54.9052 148.972C55.9772 150.354 57.2042 151.714 58.8343 152.339C60.4643 152.963 62.1653 152.892 63.0335 151.758" fill="#455A64" />
            <path d="M65.7312 172.896C65.7893 172.669 65.822 172.436 65.8287 172.201C65.8774 171.692 65.9394 171.054 66.0147 170.296C66.1129 169.36 66.2608 168.43 66.4577 167.51C66.7074 166.388 67.0451 165.288 67.4676 164.219C67.8854 163.152 68.4521 162.15 69.1508 161.242C69.7178 160.501 70.4426 159.896 71.2726 159.47C71.8313 159.195 72.4288 159.007 73.0444 158.912C73.2789 158.903 73.5118 158.87 73.7399 158.815C73.5058 158.789 73.2696 158.789 73.0356 158.815C72.3884 158.865 71.7554 159.03 71.1663 159.302C70.2932 159.713 69.5263 160.319 68.9249 161.074C68.1981 161.995 67.6115 163.019 67.1841 164.113C66.7532 165.196 66.4168 166.315 66.1786 167.457C65.9987 168.389 65.8744 169.331 65.8065 170.279C65.7489 171.089 65.7268 171.74 65.7135 172.192C65.6966 172.427 65.7025 172.663 65.7312 172.896Z" fill="#263238" />
            <path d="M65.8639 171.435C65.8771 171.327 65.8771 171.219 65.8639 171.111V170.185C65.8639 169.384 65.8639 168.223 65.9038 166.788C65.9747 163.922 66.2227 160.839 66.6967 156.489C67.1397 152.401 67.7554 147.63 68.3932 144.529C68.5305 143.83 68.6723 143.201 68.8008 142.638C68.9292 142.075 69.0311 141.606 69.133 141.216L69.35 140.33C69.3802 140.226 69.398 140.119 69.4032 140.011C69.357 140.11 69.3199 140.212 69.2924 140.317L69.0222 141.203C68.9071 141.584 68.783 142.062 68.6457 142.616C68.5084 143.169 68.3445 143.798 68.2028 144.503C67.4133 148.16 66.8218 151.856 66.4309 155.577C65.9525 159.935 65.7355 163.904 65.7178 166.779C65.7178 168.214 65.7178 169.375 65.7665 170.181C65.7931 170.553 65.8108 170.859 65.8241 171.102C65.8255 171.214 65.8389 171.326 65.8639 171.435Z" fill="#263238" />
            <path d="M65.8818 163.78C65.9089 163.459 65.9089 163.136 65.8818 162.815C65.827 161.938 65.728 161.064 65.5851 160.197C65.3737 158.912 65.0898 157.64 64.7346 156.387C64.3199 154.845 63.7868 153.338 63.1399 151.878C62.0352 149.186 60.4503 146.717 58.4623 144.591C58.1168 144.227 57.7484 143.885 57.3594 143.568C57.191 143.426 57.0404 143.28 56.8898 143.17L56.4469 142.873C56.1902 142.677 55.9175 142.504 55.6318 142.354C56.567 143.091 57.4547 143.885 58.2896 144.733C60.2127 146.876 61.7557 149.331 62.852 151.993C63.4951 153.444 64.0412 154.935 64.4865 156.458C64.8447 157.701 65.1404 158.96 65.3724 160.232C65.5718 161.322 65.6869 162.208 65.7578 162.823C65.7772 163.145 65.8186 163.465 65.8818 163.78Z" fill="#263238" />
            <path d="M58.4844 177.282L59.2507 187.789H72.752L73.5183 177.282H58.4844Z" fill="#263238" />
            <path d="M75.1302 173.14H57.1816V177.282H75.1302V173.14Z" fill="#263238" />
            <path d="M57.1816 187.385V188.533H58.0233H74.829V187.385H57.1816Z" fill="#455A64" />
            <path d="M58.8518 190.553L58.0234 188.533H74.1869L73.2257 190.553H58.8518Z" fill="#455A64" />
            <path d="M73.2654 180.715C73.2654 180.715 73.2654 180.75 73.2033 180.812L73.004 181.078L72.2155 182.083L72.1801 182.132L72.1491 182.083L70.2134 179.426H70.3241C69.7527 180.241 69.1104 181.144 68.4238 182.119L68.3663 182.203L68.2998 182.123L66.0407 179.439H66.1736L66.0496 179.594L64.043 182.11L63.9766 182.194L63.9146 182.105L62.0098 179.412H62.1206C61.1859 180.484 60.3886 181.388 59.7773 182.07L59.7375 182.119L59.7065 182.066L58.9712 180.83L58.7851 180.502C58.76 180.467 58.7406 180.428 58.7275 180.387C58.7557 180.418 58.7809 180.452 58.8028 180.489L59.011 180.808C59.2015 181.1 59.454 181.507 59.7818 182.017H59.7109L62.0143 179.31L62.0763 179.244L62.125 179.319L64.043 182.012H63.9146L65.9256 179.479L66.0496 179.324L66.1116 179.244L66.1781 179.324L68.4283 182.012H68.3042C68.9953 181.042 69.6331 180.143 70.2222 179.332L70.2798 179.253L70.333 179.332L72.2244 182.035H72.158L72.9774 181.056L73.1945 180.803C73.216 180.772 73.2397 180.742 73.2654 180.715Z" fill="#E0E0E0" />
            <path d="M73.7047 177.211C73.7047 177.259 70.325 177.295 66.1523 177.295C61.9797 177.295 58.6221 177.259 58.6221 177.211C58.6221 177.162 62.0018 177.126 66.1523 177.126C70.3028 177.126 73.7047 177.166 73.7047 177.211Z" fill="#455A64" />
            <path d="M74.3862 188.648C74.3862 188.692 70.7097 188.732 66.1782 188.732C61.6468 188.732 57.9658 188.692 57.9658 188.648C57.9658 188.604 61.6424 188.564 66.1782 188.564C70.7141 188.564 74.3862 188.599 74.3862 188.648Z" fill="#263238" />
            <path d="M254.43 169.649C270.092 169.649 282.788 156.981 282.788 141.353C282.788 125.726 270.092 113.057 254.43 113.057C238.769 113.057 226.072 125.726 226.072 141.353C226.072 156.981 238.769 169.649 254.43 169.649Z" fill="white" />
            <path d="M242.759 150.943L249.301 150.39L265.292 153.083L267.175 165.109L266.918 166.77C263.237 168.557 259.214 169.529 255.124 169.62C251.033 169.711 246.971 168.919 243.215 167.297L242.772 150.943" fill="#5B9ECF" />
            <path d="M261.872 152.888C263.457 152.658 265.074 152.878 266.54 153.523C268.006 154.167 269.262 155.21 270.164 156.533C271.414 158.331 272.564 160.196 273.61 162.119C270.861 164.692 267.618 166.681 264.078 167.966L261.872 152.888Z" fill="#5B9ECF" />
            <path d="M242.759 150.943C242.759 150.943 238.431 151.475 236.752 155.16C235.808 157.178 235.034 159.27 234.436 161.415C237.053 163.935 239.915 166.022 243.215 167.297C243.215 167.297 244.057 157.792 244.163 155.16C244.278 152.281 244.389 150.873 242.759 150.943Z" fill="#5B9ECF" />
            <path d="M246.719 127.312C247.808 125.917 249.197 124.784 250.782 123.997C252.368 123.21 254.109 122.788 255.879 122.763C257.961 122.727 258.825 123.161 260.309 123.564C261.809 123.923 263.135 124.798 264.056 126.036C265.115 127.542 266.009 129.225 266.209 131.055C266.471 132.924 266.56 134.813 266.475 136.698C266.385 138.599 265.772 140.439 264.703 142.013L259.919 142.855C257.261 142.944 254.577 143.032 251.99 142.412C249.403 141.792 246.891 140.423 245.505 138.16C243.583 135.032 244.38 130.164 246.719 127.312Z" fill="#263238" />
            <path d="M263.271 144.72C263.271 144.72 263.351 144.839 263.559 144.972C263.859 145.157 264.197 145.271 264.547 145.305C265.076 145.364 265.61 145.257 266.075 144.999C266.639 144.657 267.049 144.112 267.222 143.477C267.394 142.841 267.315 142.163 267.001 141.584C266.729 141.127 266.322 140.765 265.836 140.547C265.514 140.404 265.165 140.333 264.813 140.339C264.565 140.339 264.436 140.397 264.441 140.414C264.901 140.432 265.352 140.54 265.77 140.733C266.198 140.954 266.553 141.294 266.793 141.712C267.06 142.233 267.123 142.835 266.97 143.401C266.817 143.966 266.458 144.454 265.965 144.769C265.548 145.013 265.069 145.13 264.587 145.105C264.127 145.064 263.68 144.933 263.271 144.72Z" fill="#E0E0E0" />
            <path d="M242.059 141.234C242.107 140.69 242.279 140.164 242.561 139.696C242.844 139.229 243.23 138.832 243.689 138.536C244.157 138.254 244.702 138.127 245.246 138.174C245.791 138.221 246.306 138.439 246.719 138.798L247.476 139.683C248.059 139.956 248.543 140.403 248.86 140.962C249.178 141.522 249.313 142.166 249.248 142.806C249.159 143.444 248.89 144.044 248.472 144.535C248.055 145.026 247.507 145.388 246.892 145.579C246.27 145.755 245.614 145.768 244.987 145.617C244.359 145.465 243.781 145.155 243.308 144.715C242.848 144.269 242.497 143.723 242.28 143.119C242.064 142.516 241.988 141.871 242.059 141.234Z" fill="#263238" />
            <path d="M242.998 144.467C242.815 144.184 242.725 143.851 242.741 143.515C242.895 142.706 243.276 141.958 243.839 141.358C244.379 140.727 245.033 140.204 245.766 139.816C246.329 139.515 246.719 139.409 246.705 139.373C246.35 139.403 246.005 139.505 245.691 139.674C244.909 140.03 244.216 140.555 243.662 141.212C243.354 141.57 243.092 141.966 242.883 142.39C242.698 142.738 242.592 143.122 242.573 143.515C242.567 143.784 242.642 144.048 242.79 144.272C242.909 144.427 242.998 144.481 242.998 144.467Z" fill="#455A64" />
            <path d="M245.337 145.885C245.08 143.746 245.645 141.59 246.918 139.852C246.666 140.049 246.451 140.291 246.285 140.565C245.399 141.848 244.992 143.402 245.133 144.955C245.142 145.275 245.211 145.59 245.337 145.885Z" fill="#455A64" />
            <path d="M259.875 152.029C259.875 152.029 259.848 147.572 259.848 147.599C259.848 147.626 263.768 147.156 264.854 142.155C265.39 139.666 265.491 135.537 265.46 132.153C265.438 129.11 262.843 124.964 259.073 124.951L257.403 124.76L250.993 127.298C250.547 127.318 250.124 127.507 249.81 127.825C249.496 128.144 249.314 128.57 249.301 129.017L249.106 150.421L259.83 165.41L259.892 152.029H259.875Z" fill="#A26760" />
            <path d="M257.318 140.968C257.357 140.711 257.487 140.476 257.686 140.308C257.869 140.152 258.098 140.06 258.338 140.046C258.578 140.033 258.815 140.098 259.015 140.233C259.222 140.391 259.383 140.602 259.481 140.843C259.579 141.085 259.611 141.348 259.573 141.606C259.462 141.76 259.32 141.889 259.156 141.984C258.992 142.078 258.809 142.137 258.62 142.155C258.562 142.16 258.502 142.16 258.443 142.155C258.143 142.157 257.855 142.043 257.637 141.836C257.521 141.725 257.432 141.59 257.377 141.439C257.322 141.289 257.302 141.127 257.318 140.968Z" fill="#7B4B45" />
            <path d="M262.971 135.599C262.964 135.769 262.891 135.928 262.767 136.044C262.644 136.16 262.48 136.223 262.311 136.22C262.228 136.222 262.145 136.208 262.068 136.178C261.991 136.147 261.92 136.102 261.861 136.044C261.801 135.986 261.754 135.917 261.722 135.841C261.689 135.764 261.673 135.682 261.673 135.599C261.68 135.43 261.753 135.27 261.876 135.155C262 135.039 262.164 134.976 262.333 134.979C262.416 134.977 262.498 134.991 262.576 135.021C262.653 135.051 262.723 135.097 262.783 135.155C262.842 135.212 262.89 135.282 262.922 135.358C262.954 135.434 262.971 135.516 262.971 135.599Z" fill="#263238" />
            <path d="M264.216 134.014C264.131 134.093 263.657 133.704 262.958 133.668C262.258 133.633 261.726 133.952 261.655 133.863C261.584 133.774 261.713 133.673 261.943 133.522C262.259 133.33 262.625 133.239 262.993 133.261C263.356 133.279 263.704 133.414 263.985 133.646C264.193 133.819 264.26 133.978 264.216 134.014Z" fill="#263238" />
            <path d="M259.83 139.298C260.205 139.205 260.59 139.157 260.977 139.156C261.154 139.156 261.327 139.121 261.362 139.001C261.388 138.818 261.357 138.631 261.274 138.465C261.126 138.025 260.978 137.565 260.831 137.083C260.202 135.112 259.759 133.491 259.838 133.469C259.918 133.447 260.49 135.024 261.119 137.012C261.269 137.495 261.411 137.96 261.561 138.403C261.662 138.624 261.681 138.874 261.615 139.108C261.586 139.169 261.543 139.223 261.49 139.265C261.437 139.307 261.375 139.337 261.309 139.351C261.209 139.374 261.106 139.383 261.003 139.378C260.61 139.403 260.216 139.376 259.83 139.298Z" fill="#263238" />
            <path d="M259.83 147.581C257.457 147.479 255.158 146.725 253.186 145.402C253.186 145.402 254.802 149.008 259.896 148.813L259.83 147.581Z" fill="#7B4B45" />
            <path d="M257.735 139.67C257.85 139.67 257.81 140.428 258.43 140.999C259.05 141.571 259.861 141.535 259.861 141.641C259.861 141.748 259.675 141.779 259.334 141.765C258.888 141.75 258.462 141.576 258.133 141.274C257.818 140.986 257.628 140.588 257.602 140.162C257.589 139.843 257.686 139.661 257.735 139.67Z" fill="#263238" />
            <path d="M264.078 131.382C263.95 131.546 263.418 131.405 262.793 131.422C262.169 131.44 261.637 131.577 261.509 131.422C261.451 131.347 261.509 131.183 261.753 131.024C262.063 130.831 262.42 130.727 262.785 130.723C263.151 130.718 263.51 130.813 263.826 130.997C264.051 131.143 264.131 131.303 264.078 131.382Z" fill="#263238" />
            <path d="M254.833 135.413C254.829 135.583 254.891 135.747 255.006 135.872C255.121 135.997 255.28 136.072 255.449 136.082C255.532 136.089 255.615 136.08 255.694 136.055C255.773 136.03 255.846 135.99 255.91 135.936C255.973 135.883 256.025 135.817 256.063 135.743C256.101 135.67 256.124 135.589 256.131 135.506C256.136 135.337 256.074 135.173 255.959 135.048C255.844 134.924 255.685 134.85 255.515 134.842C255.433 134.833 255.349 134.842 255.27 134.866C255.191 134.89 255.117 134.93 255.053 134.983C254.99 135.037 254.938 135.102 254.9 135.176C254.862 135.25 254.839 135.331 254.833 135.413Z" fill="#263238" />
            <path d="M254.634 133.761C254.714 133.85 255.21 133.495 255.91 133.509C256.61 133.522 257.119 133.881 257.195 133.797C257.27 133.712 257.155 133.606 256.933 133.438C256.634 133.225 256.277 133.107 255.91 133.101C255.545 133.095 255.187 133.203 254.887 133.411C254.67 133.571 254.594 133.726 254.634 133.761Z" fill="#263238" />
            <path d="M254.231 132.268C254.369 132.428 254.909 132.268 255.56 132.268C256.211 132.268 256.756 132.397 256.889 132.233C256.947 132.153 256.862 131.994 256.628 131.839C256.303 131.646 255.929 131.549 255.551 131.56C255.175 131.556 254.806 131.66 254.488 131.861C254.249 132.025 254.174 132.184 254.231 132.268Z" fill="#263238" />
            <path d="M250.169 137.495C250.169 137.119 249.802 136.206 249.434 136.127C248.446 135.918 246.648 135.901 246.493 138.421C246.28 141.867 249.793 141.367 249.806 141.269C249.819 141.172 250.085 138.793 250.169 137.495Z" fill="#A26760" />
            <path d="M248.929 139.954C248.929 139.954 248.867 139.989 248.76 140.033C248.618 140.089 248.46 140.089 248.317 140.033C247.958 139.878 247.688 139.28 247.71 138.66C247.719 138.364 247.794 138.073 247.932 137.81C247.968 137.702 248.031 137.605 248.115 137.528C248.198 137.45 248.3 137.395 248.41 137.367C248.481 137.352 248.554 137.363 248.617 137.397C248.68 137.431 248.729 137.486 248.756 137.553C248.796 137.65 248.756 137.717 248.787 137.726C248.818 137.735 248.867 137.673 248.849 137.526C248.83 137.442 248.787 137.365 248.725 137.305C248.68 137.265 248.628 137.235 248.572 137.216C248.516 137.197 248.456 137.19 248.397 137.194C248.254 137.214 248.119 137.27 248.003 137.357C247.888 137.444 247.796 137.559 247.737 137.69C247.572 137.98 247.479 138.305 247.467 138.638C247.467 139.334 247.764 140.007 248.255 140.18C248.346 140.213 248.443 140.225 248.539 140.215C248.635 140.205 248.727 140.173 248.809 140.122C248.92 140.038 248.942 139.958 248.929 139.954Z" fill="#7B4B45" />
            <path d="M249.292 136.14L250.178 137.048C253.969 133.429 261.371 124.366 260.959 124.907C259.232 124.262 257.371 124.061 255.546 124.322C252.729 124.8 247.387 126.204 247.467 129.522C247.546 132.84 249.292 136.14 249.292 136.14Z" fill="#263238" />
            <path d="M249.372 144.72C249.372 144.72 249.297 144.839 249.088 144.972C248.787 145.157 248.448 145.271 248.096 145.305C247.567 145.364 247.033 145.257 246.568 144.999C246.003 144.655 245.593 144.107 245.423 143.468C245.253 142.829 245.336 142.149 245.655 141.57C245.929 141.114 246.335 140.753 246.82 140.534C247.142 140.391 247.491 140.32 247.844 140.326C248.092 140.326 248.22 140.383 248.22 140.401C247.76 140.419 247.309 140.527 246.891 140.72C246.463 140.941 246.108 141.281 245.868 141.699C245.604 142.221 245.542 142.822 245.695 143.386C245.848 143.951 246.205 144.438 246.696 144.755C247.114 145 247.595 145.117 248.078 145.092C248.53 145.053 248.969 144.927 249.372 144.72Z" fill="#E0E0E0" />
            <path d="M244.273 135.369C244.284 135.301 244.302 135.234 244.327 135.17C244.391 134.982 244.471 134.8 244.566 134.625C244.922 133.986 245.413 133.433 246.005 133.004C246.888 132.378 247.871 131.906 248.911 131.608C250.067 131.236 251.37 130.944 252.65 130.395C253.856 129.893 255.001 129.254 256.06 128.49C256.924 127.865 257.73 127.162 258.466 126.39C258.961 125.866 259.427 125.314 259.861 124.738L260.207 124.264C260.244 124.207 260.287 124.153 260.335 124.105C260.311 124.168 260.278 124.228 260.238 124.282C260.162 124.397 260.06 124.57 259.914 124.778C259.501 125.373 259.048 125.94 258.559 126.475C257.828 127.265 257.022 127.983 256.154 128.618C255.091 129.403 253.937 130.054 252.716 130.559C251.486 131.026 250.232 131.428 248.96 131.763C247.933 132.044 246.96 132.493 246.081 133.092C245.493 133.504 244.997 134.034 244.623 134.647C244.371 135.108 244.291 135.369 244.273 135.369Z" fill="#455A64" />
            <path d="M245.279 135.918C245.269 135.66 245.316 135.403 245.416 135.165C245.674 134.506 246.127 133.94 246.714 133.544C247.59 132.981 248.568 132.598 249.593 132.415C250.824 132.172 252.024 131.793 253.172 131.285C254.313 130.763 255.386 130.102 256.366 129.318C257.164 128.676 257.897 127.956 258.554 127.17C258.995 126.637 259.403 126.078 259.777 125.496C259.892 125.301 259.989 125.141 260.069 125.013C260.101 124.955 260.138 124.901 260.18 124.849C260.16 124.912 260.134 124.973 260.1 125.03L259.834 125.531C259.482 126.132 259.087 126.706 258.651 127.25C257.199 129.047 255.349 130.483 253.247 131.444C252.088 131.959 250.874 132.338 249.628 132.574C248.62 132.743 247.654 133.107 246.785 133.646C246.208 134.023 245.754 134.561 245.478 135.192C245.391 135.428 245.324 135.671 245.279 135.918Z" fill="#455A64" />
            <path d="M243.259 167.297C243.105 165.022 243.105 162.738 243.259 160.462C243.412 162.738 243.412 165.022 243.259 167.297Z" fill="#263238" />
            <path d="M266.917 166.77C266.895 166.371 266.907 165.971 266.953 165.574C267.021 164.621 266.989 163.663 266.855 162.717C266.68 161.777 266.411 160.857 266.053 159.971C265.887 159.607 265.752 159.229 265.65 158.841C265.873 159.176 266.059 159.534 266.204 159.909C266.615 160.792 266.913 161.724 267.09 162.682C267.228 163.645 267.238 164.622 267.121 165.588C267.095 165.988 267.026 166.384 266.917 166.77Z" fill="#263238" />
            <path d="M287.985 132.083C288.523 128.787 286.286 125.679 282.991 125.142C279.695 124.605 276.587 126.841 276.05 130.137C275.513 133.432 277.749 136.54 281.045 137.077C284.34 137.615 287.448 135.378 287.985 132.083Z" fill="#5B9ECF" />
            <path d="M279.329 131.068L280.184 130.209L281.832 131.679L284.538 128.871L285.331 129.726L281.712 133.327L279.329 131.068Z" fill="white" />
            <path d="M195.26 52.8597C209.774 52.8597 221.541 41.0935 221.541 26.5792C221.541 12.0648 209.774 0.298584 195.26 0.298584C180.746 0.298584 168.979 12.0648 168.979 26.5792C168.979 41.0935 180.746 52.8597 195.26 52.8597Z" fill="white" />
            <path d="M203.649 13.9991C203.404 13.2058 203.004 12.469 202.472 11.8318C201.939 11.1945 201.286 10.6695 200.549 10.2871C198.887 9.51204 197.008 9.33976 195.233 9.7999C193.467 10.2623 191.788 11.0104 190.263 12.0147C189.366 12.51 188.569 13.1696 187.915 13.9593C186.795 15.452 185.701 17.8838 185.656 19.7487C185.612 21.2547 186.294 25.011 186.985 26.3576C187.342 26.9158 187.857 27.3553 188.465 27.62C189.189 28.9605 190.148 30.1606 191.295 31.1637C191.614 30.1449 191.933 29.1216 192.256 28.1028L203.649 13.9991Z" fill="#263238" />
            <path d="M202.728 12.161L199.747 11.718C201.479 15.5363 202.17 24.3556 203.215 28.4175C204.265 26.7962 206.666 21.8927 206.476 19.9703C206.285 18.0479 206.232 11.7313 202.728 12.161Z" fill="#263238" />
            <path d="M188.842 18.9913C188.865 17.9065 189.234 16.8575 189.897 15.9981C190.559 15.1387 191.48 14.5143 192.523 14.2162L199.309 12.2761C202.255 11.873 204.328 15.4521 204.625 18.411C204.935 21.6978 205.165 25.1661 204.85 27.6378C204.217 32.6033 200.447 33.3475 200.447 33.3475C200.447 33.3475 200.514 35.6996 200.554 37.1436L199.451 43.6108L190.503 35.9742L188.846 19.0533C188.824 18.9692 188.838 19.1065 188.842 18.9913Z" fill="#FFBE9D" />
            <path d="M202.715 22.6945C202.721 22.8601 202.663 23.0215 202.552 23.1447C202.441 23.2679 202.287 23.3432 202.121 23.3545C202.041 23.3631 201.96 23.3554 201.882 23.3321C201.805 23.3087 201.733 23.2701 201.67 23.2185C201.608 23.167 201.557 23.1034 201.519 23.0317C201.482 22.96 201.459 22.8815 201.453 22.8008C201.446 22.6365 201.504 22.4761 201.614 22.3538C201.724 22.2314 201.878 22.1567 202.042 22.1453C202.122 22.1361 202.204 22.1431 202.281 22.1659C202.359 22.1887 202.431 22.2268 202.494 22.278C202.557 22.3292 202.609 22.3924 202.647 22.4639C202.685 22.5355 202.708 22.6139 202.715 22.6945Z" fill="#263238" />
            <path d="M203.92 20.3645C203.844 20.453 203.353 20.112 202.67 20.1341C201.988 20.1563 201.501 20.5151 201.421 20.4309C201.342 20.3467 201.461 20.2449 201.674 20.081C201.968 19.8671 202.324 19.7538 202.688 19.7576C203.043 19.7438 203.392 19.8447 203.685 20.0455C203.88 20.2006 203.955 20.329 203.92 20.3645Z" fill="#263238" />
            <path d="M196.487 22.6945C196.493 22.8601 196.434 23.0215 196.324 23.1447C196.213 23.2679 196.058 23.3432 195.893 23.3545C195.813 23.3631 195.731 23.3554 195.654 23.3321C195.576 23.3087 195.504 23.2701 195.442 23.2185C195.38 23.167 195.328 23.1034 195.291 23.0317C195.254 22.96 195.231 22.8815 195.224 22.8008C195.217 22.6362 195.275 22.4752 195.385 22.3526C195.495 22.23 195.649 22.1556 195.813 22.1453C195.894 22.1361 195.975 22.1431 196.053 22.1659C196.131 22.1887 196.203 22.2268 196.266 22.278C196.329 22.3292 196.38 22.3924 196.418 22.4639C196.456 22.5355 196.48 22.6139 196.487 22.6945Z" fill="#263238" />
            <path d="M197.444 20.5505C197.364 20.6347 196.877 20.298 196.195 20.3202C195.512 20.3423 195.021 20.6967 194.945 20.617C194.87 20.5372 194.981 20.4309 195.198 20.2626C195.487 20.0513 195.833 19.9324 196.19 19.9215C196.546 19.9119 196.895 20.0125 197.191 20.2094C197.404 20.3645 197.479 20.5151 197.444 20.5505Z" fill="#263238" />
            <path d="M199.73 25.8482C200.085 25.7244 200.454 25.6455 200.828 25.6135C201.001 25.6135 201.165 25.5514 201.192 25.4318C201.203 25.2508 201.157 25.0707 201.059 24.918C200.882 24.5016 200.7 24.0631 200.505 23.5891C199.73 21.7243 199.176 20.1872 199.243 20.1562C199.309 20.1252 200.005 21.6136 200.78 23.4784L201.311 24.8073C201.428 25.0142 201.468 25.2561 201.422 25.4894C201.398 25.5511 201.36 25.6065 201.312 25.6518C201.264 25.697 201.206 25.7308 201.143 25.7508C201.048 25.7815 200.95 25.7994 200.851 25.8039C200.48 25.8639 200.104 25.8788 199.73 25.8482Z" fill="#263238" />
            <path d="M193.829 31.7751C193.829 31.7751 195.534 35.1239 200.473 34.5126L200.438 33.3476C198.132 33.4488 195.843 32.9042 193.829 31.7751Z" fill="#EB996E" />
            <path d="M197.161 18.0921C197.099 18.2825 196.412 18.2117 195.615 18.3357C194.817 18.4597 194.179 18.6945 194.069 18.5306C194.016 18.4509 194.126 18.2737 194.379 18.0876C194.72 17.8567 195.11 17.7067 195.519 17.6491C195.927 17.5915 196.343 17.6279 196.735 17.7554C197.032 17.8617 197.183 17.9857 197.161 18.0921Z" fill="#263238" />
            <path d="M203.636 18.2869C203.521 18.4464 203.047 18.2869 202.48 18.2869C201.913 18.2869 201.444 18.4242 201.329 18.2869C201.275 18.2072 201.329 18.0522 201.55 17.8971C201.827 17.715 202.151 17.6179 202.483 17.6179C202.814 17.6179 203.138 17.715 203.415 17.8971C203.619 18.0522 203.69 18.2116 203.636 18.2869Z" fill="#263238" />
            <path d="M203.131 13.9327C202.946 13.5745 202.665 13.275 202.319 13.0676C201.973 12.8603 201.576 12.7534 201.173 12.7589C200.365 12.7794 199.581 13.0332 198.914 13.4898C196.021 15.2616 194.626 19.4254 191.765 21.2681C191.065 21.711 190.121 21.3522 189.528 21.9414C188.934 22.5305 188.571 23.4607 188.925 24.2182C188.496 23.7115 188.141 23.1462 187.871 22.5394C187.736 22.2346 187.668 21.9042 187.673 21.5708C187.678 21.2375 187.754 20.909 187.898 20.6081C188.137 20.1651 188.571 19.8196 188.677 19.3279C188.784 18.8363 188.593 18.442 188.598 17.9991C188.633 17.5482 188.776 17.1123 189.014 16.7278C190.049 14.6416 191.633 12.8762 193.594 11.6205C194.89 10.8241 196.378 10.3939 197.9 10.3758C199.427 10.3689 200.907 10.9077 202.072 11.8951C202.741 12.4843 203.445 13.0911 203.131 13.9239" fill="#263238" />
            <path d="M189.621 22.5526C189.568 22.4109 186.963 21.556 187.007 24.2979C187.047 26.9556 189.718 26.3576 189.723 26.2823C189.727 26.207 189.621 22.5526 189.621 22.5526Z" fill="#FFBE9D" />
            <path d="M188.882 25.2902C188.882 25.2902 188.837 25.3212 188.758 25.3611C188.647 25.405 188.523 25.405 188.412 25.3611C188.239 25.2552 188.096 25.1051 188 24.926C187.903 24.747 187.856 24.5456 187.863 24.3423C187.852 24.1136 187.891 23.8852 187.978 23.6734C188.003 23.5906 188.047 23.5147 188.106 23.4516C188.165 23.3886 188.238 23.3402 188.319 23.3102C188.373 23.2951 188.43 23.2992 188.481 23.3219C188.532 23.3445 188.574 23.3843 188.598 23.4342C188.634 23.5095 188.62 23.5627 188.634 23.5671C188.647 23.5716 188.687 23.5184 188.665 23.4121C188.648 23.3462 188.611 23.2873 188.558 23.2438C188.484 23.1912 188.392 23.169 188.302 23.1818C188.19 23.2066 188.086 23.2607 188.002 23.3385C187.918 23.4164 187.856 23.5151 187.823 23.6247C187.714 23.8569 187.663 24.1126 187.677 24.3689C187.665 24.6083 187.725 24.8459 187.85 25.0506C187.975 25.2553 188.158 25.4175 188.377 25.5161C188.448 25.5361 188.523 25.5396 188.596 25.5266C188.669 25.5136 188.738 25.4842 188.798 25.4408C188.877 25.3522 188.891 25.2946 188.882 25.2902Z" fill="#EB996E" />
            <path d="M188.514 21.2149C188.571 21.1909 188.624 21.1596 188.673 21.1219C188.775 21.0554 188.93 20.9624 189.116 20.8251C189.628 20.4325 190.097 19.9871 190.516 19.4962C191.123 18.752 191.688 17.9741 192.208 17.1663C192.835 16.1758 193.543 15.2394 194.325 14.3668C194.746 13.9325 195.21 13.5436 195.712 13.2063C196.183 12.899 196.69 12.6506 197.222 12.4665C198.123 12.1413 199.078 11.991 200.035 12.0236C200.679 12.0443 201.318 12.1501 201.935 12.3381C202.152 12.4001 202.316 12.4665 202.431 12.5064C202.488 12.533 202.547 12.5524 202.609 12.564C202.555 12.5331 202.498 12.5064 202.44 12.4842C202.28 12.4115 202.116 12.348 201.949 12.2938C201.332 12.0842 200.69 11.9634 200.039 11.935C199.071 11.888 198.103 12.0263 197.187 12.3425C196.646 12.5269 196.13 12.7768 195.65 13.0867C195.138 13.4265 194.666 13.8216 194.241 14.2649C193.451 15.141 192.739 16.0852 192.115 17.0865C191.6 17.8942 191.044 18.6748 190.449 19.4254C190.041 19.9228 189.586 20.3797 189.09 20.7897C188.908 20.9358 188.762 21.0289 188.647 21.1042C188.599 21.1375 188.555 21.1746 188.514 21.2149Z" fill="white" />
            <path d="M189.214 21.9723C189.131 22.6986 189.243 23.434 189.538 24.103C189.892 24.7408 190.751 25.1572 191.376 24.7762C191.881 24.4662 192.018 23.8017 192.089 23.2126C192.293 21.5117 192.359 19.793 192.576 18.092L189.214 21.9723Z" fill="#263238" />
            <path d="M202.445 12.6704C202.445 12.6704 202.206 12.6704 201.803 12.6704C201.193 12.6749 200.591 12.8014 200.031 13.0425C199.648 13.2014 199.284 13.402 198.946 13.6404C198.557 13.9137 198.184 14.2095 197.829 14.5264C197.014 15.2592 196.29 16.0876 195.672 16.9936C195.344 17.4764 195.061 17.9726 194.751 18.4332C194.457 18.8845 194.109 19.298 193.714 19.6647C193.039 20.2711 192.296 20.7973 191.5 21.2327C190.831 21.6137 190.268 21.8839 189.878 22.0699L189.435 22.2737C189.384 22.3026 189.329 22.3235 189.271 22.3357C189.318 22.3008 189.369 22.2711 189.422 22.2471L189.865 22.0167C190.246 21.813 190.8 21.5339 191.46 21.1308C192.238 20.686 192.965 20.1556 193.626 19.5495C194.008 19.1876 194.345 18.7817 194.631 18.3402C194.937 17.8973 195.22 17.3879 195.553 16.8962C196.176 15.9912 196.91 15.1669 197.736 14.4422C198.095 14.1227 198.474 13.8268 198.87 13.5563C199.219 13.3178 199.594 13.1187 199.987 12.9627C200.555 12.7271 201.166 12.6139 201.781 12.6305C201.947 12.6357 202.113 12.6535 202.277 12.6837C202.332 12.6725 202.389 12.668 202.445 12.6704Z" fill="#455A64" />
            <path d="M189.094 20.307C189.025 20.3721 188.948 20.4273 188.864 20.4709L188.203 20.8873L187.978 21.0246C187.89 21.0795 187.792 21.1157 187.69 21.1309C187.626 21.1391 187.562 21.125 187.508 21.091C187.479 21.0718 187.454 21.0463 187.436 21.0165C187.417 20.9866 187.406 20.9531 187.402 20.9183C187.405 20.7949 187.447 20.6756 187.521 20.5772L188.266 19.3148L188.376 19.4078C188.122 19.6033 187.836 19.7532 187.53 19.8508C187.224 19.9501 186.904 19.9966 186.582 19.9881C186.5 19.9884 186.418 19.978 186.339 19.9571C186.293 19.9438 186.25 19.9212 186.214 19.8907C186.177 19.8602 186.147 19.8225 186.126 19.7799C186.111 19.7349 186.107 19.687 186.114 19.64C186.121 19.593 186.138 19.5484 186.166 19.5097C186.208 19.4479 186.257 19.3914 186.312 19.3414C186.658 18.9922 186.955 18.599 187.198 18.172C187.409 17.771 187.589 17.355 187.738 16.9272C187.732 17.051 187.708 17.1733 187.668 17.2905C187.574 17.6125 187.448 17.9242 187.291 18.2207C187.055 18.6654 186.757 19.0741 186.405 19.4344C186.308 19.5318 186.215 19.6426 186.254 19.7267C186.294 19.8109 186.441 19.8463 186.582 19.8419C186.883 19.848 187.183 19.8031 187.468 19.709C187.758 19.6095 188.028 19.4595 188.266 19.2661L188.576 19.0224L188.376 19.3591L187.628 20.6436C187.568 20.7198 187.532 20.8125 187.526 20.9094C187.526 20.9847 187.597 21.0246 187.681 21.0157C187.851 20.977 188.011 20.9064 188.155 20.8075L188.837 20.4266C188.918 20.3768 189.004 20.3366 189.094 20.307Z" fill="white" />
            <path d="M197.169 27.1771C197.212 26.9757 197.324 26.7955 197.486 26.6678C197.648 26.5401 197.849 26.4727 198.055 26.4773C198.207 26.4928 198.353 26.5452 198.481 26.6302C198.608 26.7151 198.713 26.8299 198.786 26.9645C198.855 27.1004 198.896 27.2487 198.908 27.4008C198.919 27.5529 198.9 27.7057 198.852 27.8504V27.9124C198.715 28.0705 198.532 28.1818 198.329 28.2311C198.126 28.2803 197.912 28.2649 197.718 28.1871C197.528 28.1028 197.371 27.9581 197.272 27.7754C197.173 27.5928 197.136 27.3825 197.169 27.1771Z" fill="#EB996E" />
            <path d="M199.349 27.9168C199.349 27.8149 198.658 27.8681 198.122 27.3144C197.586 26.7607 197.603 26.0254 197.51 26.0298C197.417 26.0342 197.386 26.2026 197.404 26.5126C197.43 26.9162 197.598 27.2975 197.878 27.589C198.149 27.8637 198.516 28.0225 198.901 28.032C199.189 28.0541 199.358 27.9655 199.349 27.9168Z" fill="#263238" />
            <path d="M196.394 26.0654C196.367 26.0654 196.323 25.9768 196.345 25.8218C196.36 25.7214 196.395 25.6251 196.448 25.5386C196.501 25.4521 196.571 25.3772 196.654 25.3185C196.737 25.2597 196.83 25.2183 196.929 25.1967C197.029 25.1751 197.131 25.1738 197.231 25.1928C197.381 25.2238 197.452 25.2947 197.443 25.3212C197.435 25.3478 197.085 25.2947 196.783 25.5073C196.482 25.7199 196.456 26.0654 196.394 26.0654Z" fill="#EB996E" />
            <path d="M193.254 22.0611C193.254 21.999 193.449 21.9282 193.697 21.9769C193.945 22.0256 194.104 22.1674 194.073 22.225C194.042 22.2825 193.865 22.225 193.653 22.225C193.44 22.225 193.258 22.1275 193.254 22.0611Z" fill="#EB996E" />
            <path d="M204.07 22.5705C204.03 22.5262 204.097 22.4066 204.238 22.3845C204.38 22.3623 204.486 22.4465 204.464 22.5085C204.442 22.5705 204.362 22.5971 204.278 22.6104C204.194 22.6237 204.11 22.6237 204.07 22.5705Z" fill="#EB996E" />
            <path d="M193.421 21.6669C193.421 21.6048 193.607 21.5738 193.798 21.6669C193.988 21.7599 194.086 21.9105 194.046 21.9548C194.006 21.9991 193.86 21.9548 193.705 21.8795C193.55 21.8042 193.412 21.7289 193.421 21.6669Z" fill="#EB996E" />
            <path d="M185.657 35.6242L190.392 35.23C190.392 35.23 193.005 38.6717 196.558 38.406C200.11 38.1402 200.496 35.7482 200.496 35.7482C200.496 35.7482 206.83 36.9664 210.272 40.6208L210.351 40.7226L213.208 45.7768C211.051 47.7125 208.845 49.3204 206.542 50.2949C200.212 53.4399 191.229 54.0202 183.761 50.2196L185.657 35.6242Z" fill="#5B9ECF" />
            <path d="M185.746 35.5933C185.746 35.5933 183.27 36.0008 181.786 36.8734C178.441 38.8401 176.665 45.1523 176.665 45.1523C179.007 47.5139 181.792 49.3909 184.86 50.6759C184.86 50.6759 187.433 47.2341 188.51 43.8943C188.762 43.1102 188.191 39.1945 188.191 39.2255C188.191 39.2565 185.75 35.673 185.746 35.5933Z" fill="#5B9ECF" />
            <path d="M208.287 49.4092C208.216 49.4092 208.216 48.6473 207.999 47.7525C207.782 46.8578 207.459 46.1667 207.525 46.1357C207.878 46.5945 208.116 47.1304 208.221 47.6994C208.391 48.2538 208.414 48.8432 208.287 49.4092Z" fill="#263238" />
            <path d="M184.855 50.6228C184.906 50.1886 184.985 49.7581 185.09 49.3338C185.289 48.3083 185.437 47.2734 185.533 46.2331C185.644 45.0061 185.666 43.8943 185.666 43.0881C185.643 42.645 185.654 42.2009 185.697 41.7593C185.786 42.1966 185.831 42.6418 185.83 43.0881C185.879 44.1427 185.857 45.1993 185.764 46.2509C185.671 47.3044 185.501 48.3497 185.254 49.3781C185.17 49.8072 185.037 50.2249 184.855 50.6228Z" fill="#263238" />
            <path d="M222.481 17.3637C224.465 14.9285 224.099 11.346 221.664 9.36193C219.228 7.37791 215.646 7.74369 213.662 10.1789C211.678 12.6142 212.044 16.1967 214.479 18.1808C216.914 20.1648 220.497 19.799 222.481 17.3637Z" fill="#5B9ECF" />
            <path d="M215.543 13.7246L216.345 12.9184L217.899 14.3004L220.442 11.6604L221.191 12.4666L217.784 15.8552L215.543 13.7246Z" fill="white" />
            <path d="M98.6248 122.151C97.8141 122.241 97.0527 122.585 96.4499 123.135C95.8607 123.653 95.7544 124.783 95.75 125.261C95.75 127.17 96.0822 128.782 97.3092 130.249L101.934 137.035V139.56L112.618 139.117L111.98 133.03C111.98 133.03 112.356 130.948 112.538 130.133C112.72 129.318 112.538 126.966 112.538 126.966C112.538 126.966 111.045 121.403 111.134 118.78C111.222 116.158 109.327 109.146 108.6 107.157C107.874 105.168 106.115 106.524 106.066 108.243C106.018 109.961 107.2 113.669 106.881 116.924C106.881 116.924 106.7 118.01 106.066 117.332C105.712 116.951 103.781 116.468 102.594 118.036C102.35 118.37 102.185 118.755 102.111 119.161C102.031 119.556 101.872 120.074 101.593 120.047C101.021 119.919 100.423 119.983 99.8916 120.229C99.0766 120.579 98.6248 122.151 98.6248 122.151Z" fill="#DB8A76" />
            <path d="M102.328 120.455L102.731 127.746C102.711 128.483 102.876 129.214 103.21 129.872C103.398 130.202 103.692 130.458 104.044 130.601C104.395 130.744 104.785 130.765 105.15 130.661C105.372 130.559 105.568 130.408 105.722 130.218C105.875 130.028 105.983 129.805 106.036 129.566C106.129 129.101 106.15 128.625 106.098 128.153C105.908 125.056 105.877 121.952 106.005 118.851" fill="#DB8A76" />
            <path d="M105.991 118.843C106.002 118.923 106.002 119.005 105.991 119.086V119.782C105.991 120.419 105.991 121.288 105.991 122.346C105.991 123.405 106.022 124.721 106.084 126.151C106.115 126.864 106.155 127.613 106.204 128.393C106.243 128.8 106.216 129.212 106.124 129.611C106.023 130.041 105.761 130.416 105.393 130.661C105.201 130.774 104.986 130.842 104.764 130.858C104.542 130.874 104.319 130.838 104.113 130.754C103.717 130.605 103.377 130.335 103.143 129.983C102.776 129.304 102.596 128.54 102.62 127.768C102.549 126.346 102.487 125.057 102.434 123.968C102.381 122.878 102.354 122.041 102.328 121.403C102.328 121.119 102.328 120.889 102.328 120.703C102.315 120.622 102.315 120.54 102.328 120.459C102.348 120.537 102.36 120.618 102.363 120.699L102.425 121.394C102.469 122.032 102.527 122.9 102.602 123.959C102.678 125.017 102.749 126.333 102.837 127.759C102.819 128.492 102.991 129.217 103.338 129.863C103.547 130.174 103.847 130.412 104.197 130.545C104.37 130.617 104.557 130.647 104.744 130.634C104.931 130.621 105.112 130.565 105.273 130.47C105.591 130.256 105.818 129.93 105.907 129.558C105.994 129.182 106.02 128.794 105.982 128.41C105.938 127.635 105.902 126.878 105.876 126.165C105.827 124.729 105.818 123.436 105.832 122.351C105.845 121.265 105.88 120.388 105.911 119.786C105.911 119.48 105.942 119.246 105.96 119.086C105.959 119.004 105.969 118.922 105.991 118.843Z" fill="#A45B4A" />
            <path d="M110.952 126.209C110.124 126.001 108.689 125.243 107.851 124.92C107.231 124.676 106.474 124.53 105.942 124.92C105.734 125.11 105.579 125.351 105.49 125.618C105.402 125.886 105.384 126.171 105.437 126.448C105.553 127.163 105.877 127.828 106.367 128.362C106.542 128.573 106.74 128.764 106.957 128.933C107.185 129.091 107.436 129.215 107.701 129.301C107.784 129.322 107.858 129.368 107.913 129.434C108.009 129.619 108.077 129.818 108.117 130.023C108.302 130.53 108.606 130.986 109.003 131.352C109.096 131.437 109.195 131.515 109.3 131.586" fill="#DB8A76" />
            <path d="M109.326 131.609C109.326 131.609 109.278 131.609 109.202 131.542C109.087 131.469 108.979 131.386 108.879 131.294C108.51 130.954 108.225 130.532 108.046 130.063C107.989 129.903 107.966 129.739 107.909 129.62C107.886 129.555 107.843 129.501 107.785 129.465C107.707 129.429 107.627 129.397 107.546 129.372C107.163 129.241 106.818 129.019 106.54 128.725C105.857 128.098 105.423 127.245 105.317 126.324C105.29 126.078 105.315 125.829 105.392 125.594C105.468 125.359 105.594 125.143 105.76 124.96C105.935 124.807 106.143 124.696 106.367 124.637C106.591 124.577 106.826 124.569 107.054 124.614C107.447 124.678 107.83 124.798 108.188 124.973C108.524 125.124 108.83 125.27 109.114 125.416C109.672 125.682 110.124 125.895 110.442 126.027C110.611 126.088 110.777 126.157 110.939 126.236C110.756 126.223 110.576 126.181 110.407 126.112C110.075 126.005 109.61 125.815 109.038 125.562L108.108 125.159C107.764 124.999 107.398 124.889 107.023 124.831C106.828 124.796 106.627 124.804 106.435 124.856C106.243 124.908 106.066 125.003 105.915 125.133C105.772 125.293 105.664 125.481 105.598 125.686C105.533 125.89 105.511 126.106 105.535 126.32C105.639 127.186 106.043 127.988 106.677 128.588C106.933 128.864 107.25 129.075 107.603 129.203C107.693 129.234 107.78 129.271 107.864 129.314C107.955 129.371 108.024 129.457 108.059 129.558C108.126 129.735 108.139 129.899 108.188 130.032C108.346 130.488 108.598 130.906 108.928 131.259C109.068 131.367 109.201 131.484 109.326 131.609Z" fill="#A45B4A" />
            <path d="M110.057 119.76C109.723 120.528 109.209 121.205 108.56 121.735C107.962 122.323 107.232 122.757 106.43 123.002C107.112 122.556 107.772 122.076 108.405 121.562C108.987 120.99 109.538 120.389 110.057 119.76Z" fill="#A45B4A" />
            <path d="M104.219 130.749C104.219 130.749 104.254 130.785 104.303 130.869C104.37 130.993 104.428 131.122 104.476 131.254C104.56 131.471 104.583 131.707 104.542 131.936C104.502 132.091 104.421 132.232 104.307 132.345C104.193 132.457 104.051 132.536 103.896 132.574C103.503 132.653 103.097 132.637 102.712 132.527C102.327 132.418 101.973 132.218 101.681 131.945C100.98 131.303 100.43 130.514 100.073 129.633C99.6584 128.791 99.3175 127.916 99.054 127.015C98.8385 126.276 98.7209 125.512 98.7041 124.743C98.6951 124.224 98.7502 123.706 98.8679 123.201C98.9033 123.014 98.9644 122.832 99.0496 122.661C98.9158 123.345 98.8549 124.041 98.8679 124.738C98.9068 125.49 99.0377 126.234 99.2578 126.953C99.53 127.838 99.8721 128.7 100.281 129.531C100.626 130.383 101.15 131.15 101.818 131.781C102.083 132.034 102.402 132.223 102.752 132.334C103.101 132.445 103.471 132.474 103.833 132.419C103.965 132.39 104.086 132.328 104.185 132.238C104.285 132.148 104.359 132.033 104.4 131.905C104.444 131.698 104.435 131.483 104.374 131.281C104.294 130.94 104.197 130.758 104.219 130.749Z" fill="#A45B4A" />
            <path d="M101.934 122.151C102.076 122.761 102.187 123.378 102.266 123.998C102.421 124.605 102.545 125.218 102.638 125.837C102.324 125.282 102.121 124.671 102.04 124.038C101.886 123.422 101.85 122.781 101.934 122.151Z" fill="#A45B4A" />
            <path d="M101.354 131.462C101.354 131.485 101.208 131.626 100.911 131.746C100.487 131.876 100.033 131.876 99.6086 131.746C99.1862 131.623 98.8031 131.393 98.4968 131.077C98.2664 130.829 98.1956 130.634 98.2177 130.634C98.2399 130.634 98.3683 130.776 98.6119 130.975C98.9238 131.234 99.2857 131.425 99.675 131.538C100.062 131.653 100.472 131.67 100.867 131.586C101.15 131.516 101.332 131.431 101.354 131.462Z" fill="#A45B4A" />
            <path d="M152.674 107.848L147.713 112.57L132.595 119.068V146.886L140.776 179.842L138.18 190.991H192.664L189.842 181.087C189.842 181.087 191.317 161.707 193.359 148.959C195.198 137.518 191.743 117.15 191.743 117.15L179.654 112.894L174.711 107.578L152.674 107.848Z" fill="#5B9ECF" />
            <path d="M136.975 117.208C136.975 117.208 128.435 120.163 126.313 125.075C125.95 125.921 117.477 147.568 117.477 147.568L103.466 167.847C103.466 167.847 107.102 179.864 119.355 179.341C119.355 179.341 123.372 179.341 128.059 172.059C132.209 165.623 134.739 153.929 134.739 153.929L136.975 117.208Z" fill="#5B9ECF" />
            <path d="M165.639 155.701C165.608 155.663 165.58 155.623 165.555 155.581L165.342 155.227L164.536 153.854L161.604 148.778C159.141 144.468 155.752 138.536 152.005 131.985C150.255 128.884 148.585 125.961 147.057 123.277L147.004 123.179L147.101 123.126L151.146 120.911L151.172 121.088L146.012 119.866H145.937V119.791C146.473 117.554 146.902 115.751 147.208 114.475C147.358 113.868 147.474 113.39 147.558 113.053C147.598 112.898 147.633 112.779 147.655 112.686C147.663 112.643 147.676 112.601 147.695 112.562C147.702 112.604 147.702 112.647 147.695 112.69C147.695 112.783 147.651 112.907 147.62 113.062C147.544 113.403 147.443 113.882 147.305 114.497C147.022 115.76 146.614 117.562 146.105 119.813L146.047 119.72L151.212 120.876L151.46 120.933L151.239 121.057L147.203 123.312L147.243 123.166C148.776 125.85 150.455 128.787 152.209 131.866C155.93 138.43 159.296 144.375 161.746 148.698C162.95 150.846 163.929 152.591 164.612 153.814L165.382 155.209C165.462 155.36 165.528 155.48 165.577 155.577C165.602 155.616 165.622 155.658 165.639 155.701Z" fill="#263238" />
            <path d="M165.48 155.976C165.487 155.93 165.502 155.887 165.525 155.847C165.565 155.75 165.618 155.63 165.684 155.479L166.318 154.075C166.88 152.844 167.686 151.094 168.679 148.933L176.554 132.003C178.171 128.552 179.704 125.274 181.086 122.333L181.139 122.475L177.666 121.035L177.467 120.951L177.666 120.858L182.313 118.705L182.277 118.807C181.391 116.871 180.656 115.321 180.156 114.236C179.916 113.709 179.713 113.297 179.593 113.004L179.456 112.681C179.437 112.646 179.423 112.609 179.416 112.57C179.439 112.602 179.458 112.636 179.473 112.672C179.518 112.752 179.571 112.854 179.633 112.982C179.779 113.275 179.978 113.682 180.231 114.2L182.419 118.741L182.45 118.812L182.379 118.847L177.764 121.031V120.854L181.241 122.28L181.343 122.324L181.298 122.422L176.785 132.1L168.847 149.003C167.824 151.147 166.995 152.893 166.415 154.111C166.132 154.704 165.906 155.165 165.746 155.497L165.565 155.856C165.539 155.897 165.511 155.937 165.48 155.976Z" fill="#263238" />
            <path d="M103.48 167.847L98.7002 138.709L115.918 135.786L122.611 168.259L103.48 167.847Z" fill="#5B9ECF" />
            <path d="M120.839 161.707C120.8 161.626 120.774 161.539 120.759 161.451C120.71 161.256 120.648 161.008 120.577 160.702C120.418 160.055 120.214 159.107 119.975 157.938C119.492 155.595 118.907 152.343 118.296 148.751C117.685 145.159 117.127 141.903 116.684 139.555C116.467 138.404 116.285 137.464 116.157 136.778C116.099 136.468 116.05 136.22 116.015 136.02C115.995 135.933 115.983 135.844 115.979 135.755C116.018 135.836 116.045 135.923 116.059 136.012C116.108 136.206 116.17 136.455 116.241 136.76C116.4 137.407 116.604 138.355 116.843 139.524C117.326 141.867 117.915 145.119 118.522 148.711C119.129 152.303 119.691 155.559 120.134 157.907C120.356 159.059 120.533 159.998 120.662 160.684C120.719 160.994 120.768 161.242 120.803 161.442C120.823 161.529 120.835 161.618 120.839 161.707Z" fill="#263238" />
            <path d="M130.832 134.413C130.849 134.449 130.86 134.488 130.863 134.528C130.863 134.621 130.908 134.732 130.934 134.873C130.992 135.197 131.076 135.64 131.178 136.202C131.377 137.363 131.705 139.033 132.104 141.097C132.919 145.225 134.137 150.908 135.545 157.167C136.954 163.426 138.283 169.092 139.244 173.189C139.722 175.222 140.13 176.874 140.382 178.039C140.511 178.597 140.613 179.04 140.684 179.368C140.715 179.51 140.737 179.621 140.759 179.714C140.763 179.753 140.763 179.794 140.759 179.833C140.741 179.797 140.728 179.758 140.719 179.718C140.693 179.63 140.662 179.519 140.622 179.377L140.276 178.07C139.988 176.91 139.576 175.262 139.067 173.233C138.066 169.149 136.71 163.488 135.302 157.229C133.893 150.97 132.702 145.269 131.926 141.137C131.545 139.068 131.244 137.389 131.072 136.224L130.872 134.895C130.85 134.754 130.837 134.639 130.823 134.545C130.82 134.501 130.823 134.456 130.832 134.413Z" fill="#263238" />
            <path d="M191.743 117.15C195.261 118.179 198.432 120.145 200.917 122.838C205.227 127.467 215.738 190.978 215.738 190.978H196.886L190.84 159.205L191.743 117.15Z" fill="#5B9ECF" />
            <path d="M194.52 129.385C194.525 129.429 194.525 129.474 194.52 129.518C194.52 129.62 194.52 129.748 194.52 129.912C194.52 130.275 194.48 130.798 194.454 131.423C194.392 132.751 194.272 134.634 194.099 136.977C193.758 141.664 193.134 148.126 192.376 155.254C191.619 162.381 190.954 168.835 190.529 173.512C190.325 175.842 190.161 177.729 190.046 179.054C189.989 179.696 189.944 180.201 189.913 180.56C189.913 180.724 189.882 180.852 189.873 180.954C189.87 180.999 189.862 181.043 189.851 181.087C189.846 181.041 189.846 180.995 189.851 180.95C189.851 180.848 189.851 180.719 189.851 180.56C189.873 180.196 189.904 179.674 189.94 179.049C190.02 177.72 190.161 175.842 190.352 173.499C190.737 168.817 191.379 162.359 192.124 155.227C192.868 148.095 193.532 141.646 193.895 136.964C194.09 134.625 194.232 132.729 194.338 131.418C194.387 130.776 194.423 130.271 194.449 129.908C194.449 129.748 194.476 129.62 194.485 129.518C194.489 129.472 194.501 129.427 194.52 129.385Z" fill="#263238" />
            <path d="M165.48 190.978C165.414 190.978 165.365 183.138 165.365 173.477C165.365 163.816 165.414 155.976 165.48 155.976C165.547 155.976 165.596 163.807 165.596 173.477C165.596 183.147 165.542 190.978 165.48 190.978Z" fill="#263238" />
            <path d="M177.267 171.94C177.18 171.953 177.092 171.953 177.006 171.94L176.266 171.838C175.628 171.749 174.702 171.594 173.564 171.395C170.601 170.831 167.67 170.117 164.78 169.255C161.889 168.393 159.043 167.386 156.253 166.239C155.186 165.796 154.326 165.419 153.742 165.145L153.068 164.821C152.987 164.789 152.91 164.747 152.838 164.697C152.924 164.714 153.007 164.743 153.086 164.781L153.777 165.06C154.375 165.309 155.239 165.663 156.315 166.088C158.463 166.934 161.467 168.028 164.846 169.038C168.226 170.048 171.336 170.77 173.599 171.253C174.729 171.488 175.646 171.669 176.284 171.785L177.014 171.931C177.099 171.928 177.183 171.931 177.267 171.94Z" fill="#263238" />
            <path d="M170.853 174.394C170.853 174.456 168.815 174.788 166.277 175.138C163.739 175.488 161.684 175.718 161.675 175.656C163.185 175.329 164.711 175.079 166.246 174.908C167.772 174.659 169.31 174.488 170.853 174.394Z" fill="#263238" />
            <g opacity="0.3">
                <path d="M194.427 134.426C193.001 143.63 192.607 153.389 191.464 162.828H191.588C195.178 153.814 196.162 143.972 194.427 134.426Z" fill="black" />
            </g>
            <g opacity="0.3">
                <path d="M118.163 148.388C120.949 152.552 122.616 157.363 123.005 162.359C123.073 162.781 123.019 163.215 122.85 163.608C122.804 163.703 122.739 163.789 122.66 163.859C122.581 163.93 122.489 163.984 122.389 164.019C122.289 164.054 122.184 164.069 122.078 164.063C121.972 164.057 121.869 164.03 121.773 163.984C121.542 163.782 121.399 163.497 121.375 163.191C120.344 158.319 119.31 153.446 118.274 148.574" fill="black" />
            </g>
            <path opacity="0.3" d="M181.519 119.157L181.945 120.021L179.619 121.075L183.296 122.426L167.327 152.02L181.183 122.377L177.71 120.947L181.519 119.157Z" fill="black" />
            <path d="M187.407 156.233C187.401 156.134 187.401 156.035 187.407 155.936C187.407 155.728 187.407 155.453 187.407 155.103C187.407 154.368 187.384 153.331 187.367 152.002L187.438 152.078C184.014 152.104 178.636 152.118 172.603 152.118L172.718 152.002V154.142C172.718 154.842 172.718 155.533 172.718 156.206L172.607 156.095L183.176 156.144L186.277 156.175H187.123H187.424C187.333 156.182 187.241 156.182 187.15 156.175H186.33L183.23 156.206L172.599 156.255H172.483V151.94L172.599 151.825C178.632 151.825 184.009 151.825 187.433 151.869H187.508V151.94C187.508 153.269 187.477 154.319 187.469 155.068C187.469 155.409 187.469 155.679 187.469 155.883C187.467 156.002 187.446 156.12 187.407 156.233Z" fill="#263238" />
            <path d="M129.92 132.667C129.392 131.405 128.935 130.114 128.551 128.8C128.092 127.511 127.707 126.196 127.399 124.862C127.928 126.124 128.385 127.415 128.768 128.729C129.226 130.019 129.61 131.334 129.92 132.667Z" fill="#263238" />
            <path d="M194.968 120.521C194.964 121.413 194.883 122.302 194.724 123.179C194.646 124.066 194.489 124.943 194.255 125.802C194.262 124.91 194.343 124.021 194.499 123.144C194.579 122.258 194.736 121.38 194.968 120.521Z" fill="#263238" />
            <path d="M178.423 88.8942C180.793 88.6373 183.185 89.8776 184.624 91.7823C186.008 93.7512 186.637 96.152 186.396 98.5462C186.157 101.598 184.704 104.615 182.219 106.404C179.734 108.194 176.208 108.584 173.555 107.051C171.15 105.66 169.732 102.883 169.613 100.105C169.493 97.3281 170.565 94.5951 172.199 92.3493C173.723 90.2585 176.244 88.4158 178.724 89.1511" fill="#263238" />
            <path d="M181.772 73.0231C180.248 67.3754 177.901 74.6443 175.978 75.2379C174.836 75.5923 174.034 76.6687 173.688 77.8159C173.408 78.9839 173.32 80.1898 173.427 81.3861C173.678 88.6742 173.932 95.9623 174.189 103.25C178.534 101.195 181.949 96.3226 183.216 91.6893C184.483 87.056 183.026 77.6653 181.772 73.0231Z" fill="#263238" />
            <path d="M163.08 57.1876C155.815 53.2808 149.986 55.5886 147.133 57.8078C144.281 60.027 142.606 63.3979 141.561 66.8441C140.516 70.2903 140.068 73.8826 139.222 77.3776C138.513 80.3188 137.526 83.2024 137.109 86.1968C136.693 89.1912 136.897 92.3982 138.438 94.9983C139.909 97.5055 142.425 99.1887 144.856 100.783C145.742 101.355 146.766 101.953 147.762 101.638C148.489 101.408 148.98 100.752 149.415 100.128C157.306 88.857 163.494 76.4853 167.779 63.4112L163.08 57.1876Z" fill="#263238" />
            <path d="M171.168 65.5816L158.583 60.5098L155.288 60.8774C147.877 60.8774 142.73 69.0145 142.65 75.0166C142.566 81.6875 142.725 89.829 143.762 94.7503C145.844 104.641 153.551 105.532 153.551 105.532C153.551 105.532 153.463 108.229 153.383 111.552C153.35 112.965 153.603 114.371 154.128 115.684C154.653 116.998 155.438 118.191 156.436 119.193C157.435 120.194 158.625 120.983 159.937 121.512C161.248 122.042 162.653 122.3 164.067 122.271C166.803 122.217 169.409 121.095 171.328 119.145C173.248 117.195 174.328 114.571 174.339 111.835L174.499 68.9791C174.471 68.0981 174.113 67.2598 173.496 66.6305C172.879 66.0011 172.048 65.6267 171.168 65.5816Z" fill="#DB8A76" />
            <path d="M145.861 81.7717C145.876 82.1046 146.02 82.4187 146.262 82.6476C146.504 82.8766 146.826 83.0024 147.159 82.9986C147.323 83.0034 147.485 82.9754 147.638 82.9164C147.79 82.8574 147.929 82.7686 148.047 82.6551C148.164 82.5417 148.258 82.4058 148.322 82.2557C148.387 82.1055 148.421 81.9439 148.422 81.7805C148.408 81.4468 148.265 81.1316 148.022 80.9017C147.78 80.6718 147.458 80.5454 147.124 80.5491C146.96 80.5444 146.797 80.5725 146.644 80.6317C146.492 80.691 146.352 80.7802 146.235 80.8942C146.117 81.0081 146.023 81.1444 145.959 81.2951C145.895 81.4459 145.862 81.6079 145.861 81.7717Z" fill="#263238" />
            <path d="M145.281 78.4228C145.445 78.5823 146.38 77.816 147.757 77.7495C149.135 77.6831 150.176 78.3209 150.318 78.1482C150.389 78.0685 150.211 77.7761 149.755 77.4793C149.14 77.0942 148.42 76.91 147.695 76.9522C146.979 76.9873 146.292 77.2477 145.733 77.6964C145.326 78.0419 145.197 78.3519 145.281 78.4228Z" fill="#263238" />
            <path d="M153.684 89.1955C153.684 89.1158 152.825 89.0139 151.43 88.9032C151.075 88.9032 150.739 88.8323 150.668 88.5975C150.622 88.2301 150.69 87.8576 150.863 87.53L151.749 84.8014C153.002 80.9167 153.888 77.7141 153.733 77.6831C153.578 77.6521 152.431 80.7439 151.182 84.6242L150.318 87.3661C150.119 87.8009 150.08 88.2918 150.207 88.7526C150.264 88.8741 150.348 88.9811 150.452 89.0655C150.557 89.1499 150.679 89.2095 150.81 89.2398C151.007 89.2838 151.21 89.3017 151.412 89.293C152.821 89.3018 153.684 89.2752 153.684 89.1955Z" fill="#263238" />
            <path d="M153.551 105.527C158.251 105.365 162.813 103.9 166.729 101.297C166.729 101.297 163.518 108.384 153.489 107.941L153.551 105.527Z" fill="#A45B4A" />
            <path d="M154.433 92.4822C154.68 92.14 155.009 91.8658 155.39 91.6853C155.771 91.5048 156.192 91.4239 156.613 91.4502C156.917 91.4625 157.217 91.5364 157.492 91.6673C157.768 91.7983 158.014 91.9835 158.216 92.212C158.413 92.4314 158.537 92.7064 158.571 92.9991C158.605 93.2917 158.548 93.5878 158.407 93.8465C158.199 94.1032 157.919 94.2921 157.604 94.3891C157.288 94.4861 156.951 94.4869 156.635 94.3914C155.973 94.1947 155.357 93.868 154.823 93.4302C154.662 93.3291 154.523 93.1983 154.411 93.0448C154.354 92.9721 154.322 92.8835 154.318 92.7914C154.314 92.6993 154.339 92.6083 154.389 92.531" fill="#A45B4A" />
            <path d="M157.781 89.9442C157.56 89.9442 157.631 91.437 156.404 92.5621C155.177 93.6872 153.582 93.603 153.582 93.8112C153.582 93.9042 153.95 94.0814 154.623 94.0681C155.499 94.0357 156.335 93.6953 156.984 93.1069C157.609 92.5438 157.989 91.7584 158.043 90.9187C158.069 90.2941 157.883 89.9309 157.781 89.9442Z" fill="#263238" />
            <path d="M145.477 74.5736C145.738 74.8748 146.668 74.5028 147.807 74.4319C148.945 74.361 149.924 74.5338 150.128 74.1971C150.221 74.0332 150.052 73.7276 149.614 73.453C149.029 73.1271 148.361 72.9822 147.694 73.0366C147.027 73.091 146.391 73.3423 145.866 73.7586C145.481 74.0997 145.366 74.4275 145.477 74.5736Z" fill="#263238" />
            <path d="M161.05 81.6743C161.058 82.0079 160.934 82.3311 160.707 82.5752C160.479 82.8193 160.165 82.9649 159.832 82.981C159.67 82.9955 159.506 82.9774 159.351 82.9277C159.195 82.878 159.052 82.7978 158.928 82.6917C158.804 82.5857 158.703 82.4559 158.63 82.3101C158.557 82.1642 158.514 82.0053 158.503 81.8426C158.496 81.509 158.619 81.1857 158.847 80.9416C159.074 80.6975 159.388 80.5519 159.721 80.5359C159.884 80.5213 160.047 80.5395 160.203 80.5891C160.358 80.6388 160.502 80.719 160.626 80.8251C160.749 80.9312 160.851 81.061 160.924 81.2068C160.997 81.3526 161.04 81.5116 161.05 81.6743Z" fill="#263238" />
            <path d="M163.646 78.2633C163.491 78.4316 162.512 77.7229 161.135 77.7451C159.757 77.7672 158.752 78.4671 158.601 78.3032C158.526 78.2279 158.685 77.9267 159.124 77.5989C159.713 77.1782 160.419 76.9508 161.144 76.9477C161.861 76.9357 162.563 77.1513 163.15 77.5634C163.575 77.8868 163.708 78.1836 163.646 78.2633Z" fill="#263238" />
            <path d="M163.048 73.8914C162.809 74.2103 161.852 73.8914 160.714 73.8914C159.576 73.8914 158.605 74.1261 158.38 73.8028C158.282 73.6433 158.428 73.3288 158.849 73.0276C159.408 72.6561 160.07 72.4688 160.741 72.4916C161.411 72.4918 162.063 72.7042 162.605 73.0985C163.013 73.4174 163.15 73.7408 163.048 73.8914Z" fill="#263238" />
            <path d="M160.037 59.4467C163.226 58.1622 168.098 58.5962 170.831 60.6737C173.565 62.7512 175.261 65.9892 175.903 69.3822C176.546 72.7753 175.691 77.763 174.796 81.0807C169.64 74.4364 164.329 66.7732 159.354 59.996L160.037 59.4467Z" fill="#263238" />
            <path d="M157.596 56.4256C140.117 53.9628 141.734 80.4028 141.734 80.4028C143.479 70.6578 158.566 60.5097 158.566 60.5097L159.775 60.0047L157.596 56.4256Z" fill="#263238" />
            <path d="M173.401 85.8291C173.365 85.0894 174.136 83.2954 174.862 83.1448C176.807 82.7373 180.346 82.7018 180.621 87.6939C181.006 94.4889 174.1 93.4524 174.078 93.2796C174.056 93.1069 173.525 88.416 173.401 85.8291Z" fill="#DB8A76" />
            <path d="M175.114 90.5863C175.141 90.5642 175.234 90.6617 175.442 90.7414C175.73 90.8482 176.048 90.837 176.328 90.7104C177.032 90.4092 177.568 89.2309 177.533 88.0039C177.511 87.4199 177.359 86.8481 177.09 86.3295C177.013 86.121 176.888 85.934 176.723 85.7844C176.559 85.6349 176.361 85.5271 176.146 85.4702C176.008 85.4429 175.865 85.4642 175.741 85.5303C175.616 85.5965 175.519 85.7036 175.464 85.8334C175.385 86.0283 175.438 86.1656 175.402 86.1789C175.367 86.1922 175.247 86.0726 175.287 85.7891C175.317 85.6186 175.403 85.4629 175.531 85.3462C175.62 85.2706 175.722 85.2134 175.833 85.1776C175.944 85.1419 176.061 85.1284 176.177 85.138C176.459 85.177 176.726 85.288 176.953 85.4602C177.18 85.6325 177.358 85.8602 177.471 86.1214C177.795 86.6923 177.973 87.3344 177.989 87.9906C178.025 89.3638 177.396 90.6838 176.43 91.0249C176.25 91.0882 176.059 91.1103 175.869 91.0896C175.68 91.0689 175.498 91.0059 175.336 90.9053C175.132 90.7502 175.088 90.5996 175.114 90.5863Z" fill="#A45B4A" />
            <path d="M181.396 69.5681C179.655 64.9082 176.142 61.059 172.045 58.2418C169.83 56.7313 167.398 55.4644 164.75 55.1411C162.101 54.8177 159.217 55.584 157.458 57.5906L156.967 58.0911C159.974 64.7355 162.087 70.392 165.959 76.5934C167.381 78.8746 170.251 83.0119 171.133 85.55C173.369 91.9994 175.119 97.7401 175.119 97.7401C185.879 97.7534 183.132 74.2413 181.396 69.5681Z" fill="#263238" />
            <path d="M183.739 86.064C183.768 86.3183 183.78 86.5743 183.774 86.8303C183.786 87.5291 183.747 88.2278 183.659 88.921C183.535 89.9505 183.321 90.9672 183.021 91.9597C182.871 92.5267 182.636 93.0937 182.419 93.6961C182.202 94.2985 181.905 94.8876 181.568 95.4679C180.974 96.5931 180.245 97.6417 179.398 98.5908L179.11 98.9185L178.809 99.2153C178.605 99.4058 178.414 99.6007 178.224 99.7735C177.857 100.092 177.471 100.388 177.068 100.659L176.536 101.005C176.368 101.116 176.195 101.186 176.04 101.271C175.775 101.415 175.5 101.539 175.216 101.643C174.98 101.74 174.735 101.817 174.485 101.873C175.355 101.51 176.189 101.065 176.975 100.544C177.365 100.27 177.739 99.9742 178.095 99.6583C178.281 99.4811 178.468 99.2906 178.662 99.1002L178.959 98.8034L179.238 98.48C180.064 97.5318 180.778 96.4913 181.364 95.3793C181.697 94.7991 181.945 94.1922 182.211 93.6075C182.476 93.0228 182.653 92.4558 182.817 91.8977C183.124 90.9178 183.352 89.915 183.5 88.8989C183.598 88.2116 183.66 87.5197 183.686 86.8258C183.681 86.5708 183.699 86.3159 183.739 86.064Z" fill="#455A64" />
            <path d="M180.744 97.0402C180.754 97.0015 180.769 96.9642 180.789 96.9295L180.944 96.615C181.006 96.4777 181.103 96.3138 181.192 96.11L181.484 95.3968C182.049 93.8628 182.33 92.2387 182.312 90.6041C182.272 88.1797 181.732 85.7897 180.727 83.5832C179.458 80.8999 177.748 78.4488 175.668 76.332C173.586 74.2113 171.385 72.2104 169.077 70.3388C166.968 68.567 165.09 66.9724 163.589 65.5283C162.395 64.4003 161.278 63.1943 160.244 61.9182C159.885 61.4753 159.611 61.1032 159.438 60.8507L159.239 60.5672C159.214 60.5359 159.193 60.5017 159.177 60.4653C159.177 60.4653 159.208 60.4919 159.257 60.5539L159.474 60.8241C159.66 61.0678 159.943 61.4177 160.315 61.8606C161.372 63.1081 162.502 64.2914 163.699 65.4043C165.21 66.8262 167.097 68.4164 169.21 70.1661C171.54 72.0341 173.758 74.0367 175.854 76.1637C177.954 78.2996 179.678 80.7752 180.952 83.4858C181.957 85.7225 182.487 88.1433 182.507 90.5952C182.517 92.2459 182.217 93.8838 181.621 95.4234C181.502 95.6848 181.395 95.924 181.307 96.1277C181.218 96.3315 181.108 96.4954 181.037 96.6283L180.864 96.9295C180.829 96.9717 180.789 97.009 180.744 97.0402Z" fill="#455A64" />
            <path d="M180.895 77.0276C180.823 76.9707 180.756 76.9069 180.696 76.8372L180.151 76.2613C179.681 75.7608 178.995 75.0388 178.131 74.1661C173.627 69.6125 168.609 65.5988 163.177 62.2063C162.136 61.5552 161.285 61.0414 160.687 60.6959L160.01 60.2883C159.926 60.2473 159.849 60.1952 159.779 60.1333C159.867 60.1644 159.951 60.203 160.032 60.2485L160.732 60.6161C161.343 60.9395 162.207 61.4312 163.261 62.069C168.758 65.3928 173.796 69.4225 178.246 74.0554C179.097 74.9413 179.77 75.6811 180.217 76.2037L180.731 76.8062C180.796 76.8715 180.852 76.9461 180.895 77.0276Z" fill="#455A64" />
            <path d="M141.561 66.8441C141.58 66.7674 141.609 66.6931 141.645 66.6226C141.702 66.4764 141.8 66.2727 141.924 66.0069C142.185 65.4798 142.579 64.7268 143.115 63.8231C143.74 62.7623 144.427 61.7388 145.171 60.7579C146.065 59.532 147.132 58.4414 148.338 57.5199C149.563 56.5858 151.058 56.0731 152.599 56.0581C153.272 56.0799 153.932 56.2461 154.535 56.5454C155.055 56.8044 155.536 57.1371 155.961 57.5331C156.565 58.1115 157.088 58.7685 157.516 59.4866C157.666 59.7346 157.777 59.9295 157.848 60.0757C157.889 60.1434 157.92 60.2164 157.941 60.2928C157.941 60.2928 157.764 60.0181 157.441 59.5309C156.989 58.8436 156.455 58.2143 155.85 57.6572C155.431 57.2798 154.961 56.9622 154.455 56.7137C153.873 56.4378 153.239 56.2869 152.595 56.2707C151.102 56.2992 149.658 56.8019 148.471 57.7059C147.284 58.6152 146.23 59.6856 145.339 60.8863C144.591 61.8563 143.895 62.8647 143.253 63.9073C142.703 64.7932 142.287 65.5329 142.004 66.0468L141.685 66.6403C141.653 66.7137 141.611 66.7823 141.561 66.8441Z" fill="#455A64" />
            <path d="M153.8 54.9905C153.8 54.9905 153.915 54.9905 154.119 54.9905C154.241 54.9965 154.363 55.0128 154.482 55.0392C154.643 55.0669 154.803 55.1038 154.96 55.1499C155.371 55.272 155.766 55.4446 156.134 55.6638C156.584 55.9343 156.996 56.2618 157.361 56.6383C157.719 57.0215 158.027 57.4487 158.278 57.9096C158.474 58.2888 158.622 58.6904 158.721 59.1055C158.763 59.264 158.792 59.4254 158.81 59.5884C158.832 59.7097 158.842 59.8328 158.841 59.956C158.841 60.1598 158.841 60.2705 158.841 60.2705C158.841 60.2705 158.783 59.8276 158.584 59.1498C158.464 58.7534 158.301 58.3715 158.097 58.0114C157.602 57.1266 156.892 56.3814 156.032 55.8454C155.682 55.6267 155.309 55.4455 154.921 55.305C154.243 55.0702 153.795 55.0348 153.8 54.9905Z" fill="#455A64" />
            <path d="M152.674 111.631L164.705 117.797L174.986 110.09L165.639 155.697L152.674 111.631Z" fill="white" />
            <path d="M172.567 122.276C172.477 122.354 172.379 122.422 172.275 122.479L171.42 123.011L168.284 124.911L168.204 124.96L168.155 124.876L164.492 118.364H164.696L164.496 118.807L161.329 125.451L161.289 125.536L161.205 125.496L157.542 123.724L156.532 123.219C156.41 123.165 156.294 123.098 156.187 123.02C156.314 123.058 156.439 123.107 156.559 123.166L157.591 123.609L161.285 125.314L161.161 125.358L164.284 118.683L164.488 118.24L164.585 118.032L164.691 118.231C166.06 120.694 167.314 122.953 168.315 124.765L168.191 124.729L171.367 122.9L172.253 122.408C172.353 122.355 172.459 122.311 172.567 122.276Z" fill="#263238" />
            <path d="M165.64 155.701C165.617 155.577 165.606 155.451 165.609 155.324C165.609 155.059 165.596 154.7 165.569 154.248C165.538 153.291 165.498 151.949 165.449 150.297C165.334 146.939 165.175 142.324 165.006 137.256C164.838 132.189 164.688 127.551 164.626 124.211C164.599 122.541 164.581 121.19 164.581 120.256C164.581 119.813 164.581 119.445 164.581 119.179C164.571 119.054 164.571 118.928 164.581 118.803C164.599 118.927 164.608 119.053 164.608 119.179C164.608 119.445 164.63 119.804 164.648 120.251C164.679 121.208 164.719 122.555 164.767 124.207C164.882 127.564 165.042 132.18 165.21 137.247C165.379 142.315 165.534 146.953 165.591 150.292C165.622 151.962 165.635 153.313 165.635 154.248C165.635 154.691 165.635 155.059 165.635 155.324C165.645 155.45 165.647 155.576 165.64 155.701Z" fill="#263238" />
            <path d="M283.307 190.978C283.307 191.04 226.981 191.093 157.508 191.093C88.0343 191.093 31.708 191.04 31.708 190.978C31.708 190.916 88.021 190.858 157.508 190.858C226.994 190.858 283.307 190.911 283.307 190.978Z" fill="#263238" />
            <path d="M67.5602 107.215C84.4818 107.215 98.1994 93.4971 98.1994 76.5755C98.1994 59.6539 84.4818 45.9363 67.5602 45.9363C50.6386 45.9363 36.9209 59.6539 36.9209 76.5755C36.9209 93.4971 50.6386 107.215 67.5602 107.215Z" fill="white" />
            <path d="M66.4038 54.636C66.6295 54.2055 66.9836 53.8561 67.4171 53.6362C67.8505 53.4163 68.3417 53.3369 68.8223 53.409C69.386 52.969 70.0806 52.73 70.7957 52.73C71.5108 52.73 72.2054 52.969 72.7691 53.409C73.1897 53.1531 73.6694 53.0103 74.1615 52.9944C74.6537 52.9785 75.1415 53.0901 75.5778 53.3183C76.0142 53.5464 76.3841 53.8835 76.6518 54.2968C76.9195 54.71 77.0758 55.1855 77.1056 55.6769C77.5987 55.5921 78.106 55.6646 78.5556 55.8842C79.0052 56.1038 79.3743 56.4593 79.6105 56.9004C79.8468 57.3415 79.9382 57.8458 79.8718 58.3417C79.8054 58.8377 79.5846 59.3001 79.2407 59.6635C79.7778 59.8337 80.2334 60.1959 80.5203 60.6809C80.8071 61.166 80.9051 61.7397 80.7954 62.2924C80.6858 62.8451 80.3762 63.338 79.9259 63.6768C79.4756 64.0155 78.9162 64.1764 78.3547 64.1285L66.8822 57.2937C66.538 56.9542 66.3073 56.5165 66.2216 56.0407C66.136 55.5649 66.1996 55.0742 66.4038 54.636Z" fill="#E0E0E0" />
            <path d="M78.6827 64.4563C78.4493 62.0263 77.31 59.7732 75.4913 58.1449C73.6726 56.5166 71.3077 55.6324 68.8668 55.668H68.3663C62.9445 56.0667 60.2558 60.8905 60.8006 66.299L61.8504 84.3494L61.908 85.2353L74.4614 87.5653L74.3772 82.963C74.3772 82.963 79.5775 82.0771 79.5642 76.859C79.5465 74.3298 79.1257 69.1472 78.6827 64.4563Z" fill="#FFBE9D" />
            <path d="M74.4175 82.9408C71.997 82.9772 69.617 82.3176 67.5605 81.0405C67.5605 81.0405 69.2216 84.7658 74.4131 84.2209L74.4175 82.9408Z" fill="#EB996E" />
            <path d="M77.3759 69.2844C77.3807 69.4788 77.3093 69.6674 77.1768 69.8098C77.0443 69.9522 76.8614 70.037 76.6671 70.0462C76.5726 70.0553 76.4772 70.0454 76.3866 70.0169C76.296 69.9884 76.2121 69.942 76.1398 69.8804C76.0675 69.8188 76.0083 69.7434 75.9657 69.6585C75.9231 69.5736 75.8981 69.481 75.892 69.3862C75.8859 69.1922 75.9564 69.0036 76.0881 68.8611C76.2199 68.7186 76.4024 68.6336 76.5963 68.6244C76.691 68.6153 76.7866 68.6252 76.8775 68.6537C76.9683 68.6821 77.0526 68.7284 77.1252 68.79C77.1979 68.8515 77.2575 68.9269 77.3006 69.0118C77.3436 69.0967 77.3692 69.1894 77.3759 69.2844Z" fill="#263238" />
            <path d="M77.598 67.0255C77.505 67.1229 76.938 66.7154 76.1362 66.7287C75.3345 66.742 74.7542 67.1716 74.6656 67.052C74.577 66.9324 74.7144 66.835 74.9668 66.6445C75.3091 66.3987 75.7193 66.2656 76.1407 66.2636C76.5574 66.2552 76.9658 66.381 77.3056 66.6224C77.5581 66.804 77.6423 66.9812 77.598 67.0255Z" fill="#263238" />
            <path d="M69.9257 69.4794C69.9294 69.6735 69.8576 69.8615 69.7253 70.0036C69.5931 70.1458 69.4108 70.231 69.2169 70.2413C69.1225 70.2498 69.0274 70.2394 68.937 70.2106C68.8467 70.1819 68.763 70.1355 68.6908 70.074C68.6186 70.0126 68.5595 69.9373 68.5167 69.8527C68.4739 69.7681 68.4484 69.6759 68.4418 69.5813C68.4357 69.3873 68.5062 69.1987 68.6379 69.0562C68.7697 68.9137 68.9522 68.8286 69.1461 68.8194C69.2408 68.8103 69.3365 68.8203 69.4273 68.8487C69.5182 68.8772 69.6024 68.9235 69.6751 68.985C69.7477 69.0465 69.8073 69.122 69.8504 69.2069C69.8934 69.2918 69.919 69.3845 69.9257 69.4794Z" fill="#263238" />
            <path d="M69.7264 67.2868C69.6334 67.3886 69.0619 66.9767 68.2646 66.99C67.4673 67.0033 66.8826 67.4329 66.794 67.3178C66.7054 67.2026 66.8383 67.0963 67.0952 66.9102C67.4375 66.6639 67.8474 66.5293 68.2691 66.5249C68.6855 66.5187 69.0932 66.6443 69.434 66.8837C69.6821 67.0697 69.7707 67.2425 69.7264 67.2868Z" fill="#263238" />
            <path d="M72.721 73.1868C73.1417 73.05 73.5775 72.9652 74.0188 72.9343C74.2226 72.9078 74.4131 72.8635 74.4618 72.7262C74.4743 72.5138 74.4235 72.3026 74.3156 72.1193L73.6866 70.5734C72.8007 68.3586 72.1806 66.5513 72.2692 66.5159C72.3578 66.4805 73.1551 68.2434 74.01 70.4494C74.2182 70.9942 74.4219 71.5125 74.6124 72.0086C74.7437 72.254 74.7859 72.5373 74.732 72.8103C74.7027 72.8822 74.6576 72.9466 74.6001 72.9987C74.5426 73.0508 74.4742 73.0894 74.3998 73.1115C74.2864 73.1464 74.1685 73.1644 74.0498 73.1647C73.6097 73.2293 73.163 73.2367 72.721 73.1868Z" fill="#263238" />
            <path d="M70.3681 73.7981C70.501 73.7981 70.5143 74.6574 71.254 75.2643C71.9938 75.8711 72.9328 75.7604 72.9373 75.8844C72.9373 75.9376 72.7335 76.0527 72.3437 76.0705C71.836 76.0704 71.3442 75.8934 70.9528 75.5699C70.5692 75.2653 70.3184 74.824 70.2529 74.3385C70.2175 73.9886 70.3105 73.7715 70.3681 73.7981Z" fill="#263238" />
            <path d="M69.8854 64.1461C69.8101 64.3632 68.9995 64.2746 68.0693 64.3986C67.1391 64.5226 66.3772 64.7929 66.2488 64.6024C66.1912 64.5094 66.3196 64.3012 66.6253 64.0841C67.0237 63.8187 67.4789 63.6503 67.9541 63.5924C68.433 63.5272 68.9205 63.5774 69.376 63.7386C69.7437 63.8715 69.9209 64.0531 69.8854 64.1461Z" fill="#E0E0E0" />
            <path d="M77.27 64.7087C77.1327 64.8947 76.5746 64.7087 75.9146 64.7087C75.2546 64.7087 74.6876 64.8504 74.5592 64.6599C74.4971 64.5714 74.5857 64.3853 74.8294 64.217C75.1532 63.999 75.5377 63.8889 75.9279 63.9025C76.3177 63.9002 76.698 64.0229 77.0131 64.2524C77.2523 64.434 77.3321 64.6201 77.27 64.7087Z" fill="#E0E0E0" />
            <path d="M78.1469 62.0732C78.1469 62.0732 76.7206 58.8308 75.1392 58.0866C73.2168 57.2007 72.4283 59.2959 70.6034 58.813C68.7784 58.3302 66.949 57.4842 65.1151 58.9061C63.1041 60.4697 64.0698 61.5372 63.9812 63.8495C63.6091 73.2357 61.9347 75.9112 61.381 71.5037C61.3234 71.0209 59.9724 70.6533 59.7465 68.6112C59.4497 65.9535 59.3036 62.7465 59.8617 60.8551C60.3401 59.2649 61.226 58.1575 62.8295 56.9216C65.3632 54.9815 68.8758 54.8885 71.2678 55.0922C73.6598 55.296 78.3019 57.9847 78.1469 62.0732Z" fill="#E0E0E0" />
            <path d="M61.713 71.3619C61.702 71.1716 61.6527 70.9855 61.568 70.8147C61.4834 70.6439 61.3651 70.4919 61.2204 70.3679C61.0757 70.2438 60.9074 70.1502 60.7257 70.0927C60.5439 70.0352 60.3524 70.0149 60.1627 70.0331C59.1926 70.1394 58.1206 70.7064 58.2137 72.713C58.3776 76.2566 61.9212 75.3707 61.9212 75.2733C61.9212 75.1758 61.7928 72.8503 61.713 71.3619Z" fill="#FFBE9D" />
            <path d="M61.0837 73.9576C61.0837 73.9576 61.0261 74.0019 60.9243 74.055C60.7794 74.1264 60.6122 74.1375 60.4592 74.086C60.0738 73.9664 59.7327 73.3817 59.684 72.7572C59.6638 72.4557 59.7091 72.1535 59.8169 71.8713C59.8448 71.7588 59.8998 71.6548 59.977 71.5684C60.0542 71.482 60.1512 71.4156 60.2598 71.3751C60.3303 71.3527 60.4064 71.3554 60.4751 71.3829C60.5438 71.4104 60.6008 71.4609 60.6363 71.5257C60.6895 71.6232 60.6673 71.6941 60.6895 71.7029C60.7116 71.7118 60.7604 71.6365 60.7249 71.4903C60.7002 71.4032 60.6472 71.3269 60.5743 71.2733C60.4726 71.2061 60.3488 71.1807 60.2288 71.2024C60.0843 71.2388 59.9513 71.311 59.8421 71.4125C59.733 71.5139 59.6512 71.6412 59.6043 71.7827C59.4673 72.0976 59.4095 72.4413 59.4359 72.7837C59.4935 73.5013 59.8789 74.1569 60.4149 74.2809C60.5116 74.3041 60.6123 74.3054 60.7096 74.2847C60.807 74.264 60.8984 74.2219 60.9774 74.1613C61.0837 74.0417 61.097 73.962 61.0837 73.9576Z" fill="#EB996E" />
            <path d="M61.6818 64.4563C62.1254 64.8019 62.6472 65.0331 63.2012 65.1296C63.4791 65.1678 63.7614 65.104 63.996 64.9501C64.2305 64.7962 64.4013 64.5625 64.4769 64.2924C64.5167 64.0443 64.4503 63.7564 64.6142 63.5615C64.7781 63.3666 65.1635 63.3976 65.4647 63.4242C65.9861 63.458 66.5032 63.3107 66.9285 63.0072C67.3538 62.7037 67.6614 62.2627 67.7991 61.7587C67.9615 61.9335 68.1836 62.041 68.4215 62.0599C68.6594 62.0787 68.8957 62.0076 69.0836 61.8606C69.4494 61.5563 69.7713 61.2031 70.0404 60.8107C70.1756 60.6144 70.357 60.4543 70.5687 60.3446C70.7803 60.2349 71.0157 60.179 71.2541 60.1817C71.5061 60.2624 71.7659 60.3158 72.0293 60.3412C72.4988 60.2925 72.7203 59.7388 72.7823 59.2737C72.8443 58.8086 72.893 58.2637 73.2873 58.0068C73.6815 57.7499 74.1289 57.8961 74.5586 57.9537C74.9882 58.0113 75.5464 57.9005 75.6527 57.4797C75.6689 57.3658 75.6619 57.2499 75.6322 57.1387C75.6025 57.0276 75.5507 56.9236 75.4799 56.833C75.1838 56.4065 74.7525 56.0923 74.2558 55.9412C73.7591 55.7901 73.2259 55.8109 72.7424 56.0002C72.559 55.4087 72.2041 54.8849 71.7227 54.4953C71.2412 54.1057 70.6549 53.8679 70.0381 53.8119C69.4213 53.7559 68.8018 53.8843 68.2581 54.1808C67.7144 54.4774 67.2709 54.9287 66.984 55.4776C66.6009 55.1388 66.1416 54.8978 65.6451 54.7749C65.1487 54.6521 64.63 54.6511 64.1331 54.772C63.6362 54.893 63.176 55.1323 62.7916 55.4696C62.4072 55.8068 62.11 56.2321 61.9255 56.709C61.4874 56.6013 61.0274 56.6246 60.6026 56.776C60.1777 56.9275 59.8066 57.2003 59.5353 57.5607C59.2641 57.9211 59.1046 58.3532 59.0768 58.8034C59.0489 59.2536 59.1538 59.702 59.3785 60.0932C59.0491 60.2658 58.7685 60.5186 58.5626 60.8283C58.3567 61.138 58.2321 61.4945 58.2002 61.865C58.2002 62.6047 58.9266 63.3267 59.6398 63.123C59.4977 63.4148 59.4725 63.7499 59.5695 64.0597C59.6665 64.3694 59.8783 64.6303 60.1615 64.7889C60.4447 64.9475 60.7779 64.9918 61.0927 64.9126C61.4075 64.8335 61.6801 64.6369 61.8546 64.3632" fill="#E0E0E0" />
            <path d="M59.3832 63.1052C59.3694 63.2289 59.3828 63.354 59.4226 63.4719C59.4624 63.5897 59.5276 63.6974 59.6135 63.7874C59.7932 64.0121 60.0511 64.1608 60.3356 64.2038C60.5164 64.2299 60.7007 64.2157 60.8753 64.1621C61.05 64.1085 61.2105 64.0168 61.3455 63.8937H61.1949C61.3867 64.2257 61.6305 64.5248 61.9169 64.7796C62.1097 64.9496 62.3319 65.0832 62.5725 65.1738C62.8028 65.2546 63.0504 65.2729 63.2901 65.227C63.4944 65.2077 63.6919 65.1438 63.8687 65.0396C64.0456 64.9355 64.1973 64.7938 64.3133 64.6246C64.4535 64.3957 64.5468 64.1412 64.5879 63.876C64.6145 63.7825 64.6235 63.6848 64.6145 63.588C64.6145 63.588 64.5702 63.6855 64.5126 63.8582C64.4431 64.1011 64.3355 64.3314 64.1937 64.5404C64.0632 64.6944 63.902 64.8195 63.7205 64.9077C63.539 64.996 63.341 65.0454 63.1393 65.0529C62.9376 65.0604 62.7366 65.0258 62.549 64.9513C62.3614 64.8768 62.1914 64.764 62.0498 64.6201C61.7799 64.3842 61.5483 64.1077 61.3632 63.8007L61.2968 63.6944L61.2082 63.7785C61.0945 63.8848 60.9594 63.9654 60.8119 64.0151C60.6645 64.0648 60.5081 64.0823 60.3533 64.0664C60.1049 64.0366 59.8754 63.9188 59.7066 63.7342C59.5551 63.55 59.4448 63.3356 59.3832 63.1052Z" fill="white" />
            <path d="M65.9961 54.8708C65.9961 54.8708 66.0891 54.8708 66.2486 54.9594C66.4772 55.0456 66.6737 55.2003 66.8111 55.4023L66.8554 55.4687L66.9042 55.4023C67.3414 54.7918 67.9268 54.3025 68.6051 53.9804C69.0446 53.7774 69.5209 53.6659 70.0048 53.6526C70.5382 53.6472 71.0626 53.7898 71.5198 54.0646C71.8786 54.2774 72.1893 54.5625 72.4322 54.9018C72.6487 55.2213 72.7882 55.5867 72.8398 55.9693V56.0845L72.9505 56.0535C73.447 55.9115 73.9794 55.9687 74.4344 56.2129C74.7903 56.405 75.077 56.7041 75.2539 57.0678C75.405 57.3991 75.4864 57.758 75.4931 58.1221C75.5318 58.0369 75.5543 57.9453 75.5595 57.8519C75.5602 57.5724 75.5013 57.2961 75.3868 57.0412C75.2134 56.6415 74.915 56.3088 74.5363 56.0933C74.0456 55.8184 73.4668 55.7468 72.9239 55.894L73.0435 55.9737C72.992 55.5554 72.84 55.1557 72.6006 54.8087C72.3429 54.4471 72.0106 54.1449 71.6261 53.9228C71.1386 53.6244 70.5763 53.4707 70.0048 53.4799C69.4939 53.4916 68.9918 53.6156 68.5342 53.8431C67.835 54.1855 67.2412 54.7105 66.8156 55.3624H66.9086C66.75 55.1511 66.5281 54.9959 66.2752 54.9195C66.1883 54.8781 66.0918 54.8612 65.9961 54.8708Z" fill="white" />
            <path d="M69.1503 74.312C69.2079 74.312 69.2256 73.9355 69.5224 73.621C69.8191 73.3065 70.1868 73.2445 70.1824 73.1781C70.1779 73.1116 70.0805 73.1249 69.9166 73.1559C69.7068 73.2043 69.5167 73.3156 69.3718 73.4748C69.2253 73.6326 69.1322 73.8326 69.106 74.0463C69.0883 74.2412 69.1281 74.312 69.1503 74.312Z" fill="#EB996E" />
            <path d="M68.8224 73.3553C68.8711 73.3819 68.9996 73.2579 69.1856 73.1693C69.3716 73.0807 69.5488 73.0586 69.5577 73.001C69.5665 72.9434 69.3451 72.8637 69.1147 73.001C68.8844 73.1383 68.7692 73.311 68.8224 73.3553Z" fill="#EB996E" />
            <path d="M64.6856 81.7316C64.6856 81.7316 64.6369 81.705 64.5881 81.6385C64.5601 81.5909 64.5453 81.5366 64.5453 81.4813C64.5453 81.426 64.5601 81.3717 64.5881 81.3241C64.6413 81.1912 64.8008 81.0671 64.9071 80.8368C64.9412 80.7727 64.9591 80.7012 64.9591 80.6286C64.9591 80.556 64.9412 80.4845 64.9071 80.4204C64.8359 80.3147 64.7574 80.2141 64.6723 80.1192C64.3977 79.7693 65.1994 79.5168 65.1153 79.065C65.103 78.9173 65.033 78.7804 64.9204 78.684C64.8362 78.622 64.7786 78.6176 64.7742 78.6309C64.8915 78.7495 64.9608 78.9072 64.9691 79.0738C64.9651 79.1629 64.9396 79.2497 64.8949 79.3268C64.8502 79.4039 64.7875 79.4691 64.7122 79.5168C64.6127 79.5981 64.5313 79.6991 64.473 79.8136C64.4425 79.8859 64.4317 79.965 64.4418 80.0428C64.452 80.1207 64.4826 80.1944 64.5306 80.2565C64.6147 80.3761 64.7077 80.4514 64.752 80.5356C64.7719 80.5739 64.7822 80.6165 64.7822 80.6596C64.7822 80.7028 64.7719 80.7453 64.752 80.7836C64.6856 80.9608 64.5261 81.107 64.4686 81.2886C64.4355 81.3625 64.4263 81.4449 64.4421 81.5242C64.458 81.6036 64.4982 81.6761 64.5571 81.7316C64.6192 81.7714 64.69 81.7404 64.6856 81.7316Z" fill="#EB996E" />
            <path d="M63.6005 81.5766C63.6005 81.5766 63.6005 81.5234 63.6448 81.4481C63.6924 81.3375 63.7583 81.2357 63.8397 81.1469C63.8969 81.0749 63.9473 80.9978 63.9903 80.9166C64.0456 80.8088 64.0628 80.6854 64.039 80.5666C63.977 80.3141 63.7644 80.1547 63.6625 79.9686C63.5606 79.7826 63.6625 79.6364 63.7998 79.4769C63.8754 79.3994 63.9396 79.3114 63.9903 79.2156C64.0303 79.1235 64.0485 79.0235 64.0434 78.9233C64.008 78.5512 63.7555 78.4449 63.7555 78.4803C63.8397 78.6135 63.8884 78.7659 63.8972 78.9233C63.8919 79.0095 63.8682 79.0937 63.8277 79.1701C63.7872 79.2465 63.7309 79.3134 63.6625 79.3662C63.5686 79.4484 63.4931 79.5496 63.441 79.663C63.4134 79.7352 63.403 79.8128 63.4107 79.8897C63.4184 79.9666 63.4439 80.0407 63.4853 80.1059C63.627 80.3407 63.8131 80.4825 63.8529 80.6508C63.8613 80.7297 63.8539 80.8096 63.8311 80.8856C63.8082 80.9617 63.7705 81.0324 63.7201 81.0937C63.6432 81.2025 63.5904 81.3263 63.565 81.457C63.5429 81.5278 63.5695 81.581 63.6005 81.5766Z" fill="#EB996E" />
            <path d="M87.4089 93.0446C85.5263 89.4522 80.5918 87.4058 80.5918 87.4058L82.1333 103.538C85.0435 101.797 87.6658 99.9946 89.4509 98.0146C89.4509 98.0146 89.2915 96.6325 87.4089 93.0446Z" fill="#5B9ECF" />
            <path d="M82.8641 89.7888C82.8641 89.7888 82.7755 90.0502 82.6205 90.471C82.4655 90.8918 82.2617 91.472 82.058 92.1232C81.8542 92.7743 81.6992 93.3679 81.5884 93.802C81.5431 94.0394 81.4809 94.2733 81.4024 94.5019C81.4017 94.4362 81.4092 94.3708 81.4245 94.307C81.4245 94.1829 81.4733 94.0057 81.5176 93.7843C81.6106 93.3413 81.7567 92.7433 81.9605 92.0922C82.1643 91.441 82.4035 90.8563 82.5541 90.4444C82.6427 90.2362 82.7135 90.0723 82.7667 89.9571C82.8198 89.842 82.8553 89.7844 82.8641 89.7888Z" fill="#263238" />
            <path d="M80.5923 87.4057L74.2315 85.7845C70.0101 87.4678 65.8419 86.4888 61.7091 83.8887L50.7991 88.5087C47.2555 90.6393 46.7859 93.5495 46.4006 94.9626L45.5146 97.8196C48.0816 100.704 51.2697 102.968 54.8389 104.442C62.8608 107.95 72.0876 108.707 82.0984 103.556L80.5923 87.4057Z" fill="#5B9ECF" />
            <path d="M55.6581 101.262C55.6315 101.262 55.6094 100.265 55.6094 99.0468C55.6094 97.8287 55.6094 96.832 55.6581 96.832C55.7068 96.832 55.7113 97.8287 55.7113 99.0468C55.7113 100.265 55.6891 101.262 55.6581 101.262Z" fill="#263238" />
            <path d="M54.3913 94.5817C54.3581 94.5013 54.3315 94.4183 54.3116 94.3336C54.2717 94.1698 54.2008 93.9394 54.1078 93.6559C53.6218 92.1743 52.8226 90.8147 51.7646 89.6693C51.5652 89.4523 51.3969 89.2795 51.2773 89.1644C51.212 89.1056 51.1513 89.0419 51.0957 88.9739C51.1712 89.0186 51.2411 89.0721 51.3039 89.1333C51.484 89.2862 51.6555 89.4489 51.8177 89.6206C52.3644 90.1816 52.8418 90.8062 53.2396 91.481C53.6344 92.157 53.9495 92.8764 54.1787 93.6249C54.2628 93.9128 54.3204 94.152 54.3514 94.3159C54.3745 94.4028 54.3879 94.4919 54.3913 94.5817Z" fill="#263238" />
        </svg>

    )
}

export default pana