import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import axios from "axios";
import cookie from "js-cookie";
import jwt from "jwt-decode";
import Pana from "assets/svg/Pana";
import { AuthContext } from "Context/AuthProvider";
import { AlertMessageContext } from 'Context/AlertMessageProvider';
import PrimaryButton from "Components/Buttons/PrimaryButton";
import Eye from "assets/svg/Eye";
import EyeCrossed from "assets/svg/EyeCrossed";
import { LuLoader2 } from "react-icons/lu";
import { FcGoogle } from "react-icons/fc";
import { Helmet } from "react-helmet";  

function Connexion() {
  const { value } = useContext(AuthContext);
  const { alert } = useContext(AlertMessageContext);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [isEyeCrossed, setisEyeCrossed] = useState(false);
  const [isLoadingNatif, setIsLoadingNatif] = useState(false)
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Function de connexion
  const onSubmit = async (data) => {
    try {
      setIsLoadingNatif(true)
      const response = await axios.post(
        process.env.REACT_APP_API + "login",
        data
      );
      setIsLoadingNatif(false)
      if (response.data.token) {
        const token = response.data.token;
        cookie.set("token", token, {
          secure: true,
          expires: 2,
          sameSite: 'none'
        });
        cookie.set("StatusConnexion", false, {
          secure: true,
          expires: 2,
          sameSite: 'none'
        });
        const decodedToken = jwt(token);
        // Rafraîchir la page
        // Rediriger l'utilisateur vers la page accueil
        value.setUser(decodedToken);
        value.setIsLoggedIn(true);
        //  kpi()

      }
      navigate("/mon-espace/Dashboard");
    } catch (error) {
      setIsLoadingNatif(false)
      if (error.response && error.response.status === 401) {
        setErrorMessage("Adresse mail ou mot de passe incorrect");
      } else {
        setErrorMessage("Une erreur s'est produite. Veuillez réessayer.");
      }
    }
  };

  function toggleEye() {
    const inputPassword = document.getElementById("password");
    if (inputPassword.type === "password") {
      inputPassword.type = "text"
      setisEyeCrossed(true);
    } else {
      inputPassword.type = "password"
      setisEyeCrossed(false);
    }
  }

  function loginGoogleSuccess(credentialResponse) {
    setIsLoadingGoogle(true)
    axios.post(process.env.REACT_APP_API + "login_google",
      { "credential": credentialResponse.credential }
    ).then((res) => {
      setIsLoadingGoogle(false)
      if (res.data.token) {
        const token = res.data.token;
        cookie.set("token", token, {
          secure: true,
          expires: 2,
          sameSite: 'none'
        });
        cookie.set("StatusConnexion", false, {
          secure: true,
          expires: 2,
          sameSite: 'none'
        });
        const decodedToken = jwt(token);
        value.setUser(decodedToken);
        value.setIsLoggedIn(true);
        navigate("/mon-espace/Dashboard");
      }
      if (res.data.message && res.data.message === "inscription") {
        navigate("/Inscription-google", {
          state: {
            "email": res.data.email,
            "firstName": res.data.given_name,
            "lastName": res.data.family_name
          }
        })
      }
    }).catch((error) => {
      setIsLoadingGoogle(false)
      if (error.response.status === 401) {
        alert.errorAlert(error.response.data.error)
      } else {
        alert.errorAlert("Une erreur s'est produite. Veuillez réessayer.")
      }
    });
  }

  function loginGoogleError() {
    console.log('Login Failed');
  }

  return (
    <div className="containerxl pb-4">
      <Helmet>  
      <title>MyFinPlace - Connexion </title>  
     </Helmet>  
      {/* ----------------- Titre ----------------- */}
      <div className="text-center pt-28 pb-[30px] robotoSlab">
        <h3>Bienvenue</h3>
      </div>
      {/* ----------------- BG ----------------- */}
      <div className="">
        <div className="sm:w-[390px] h-auto bg-[#EFF5FA] mx-auto rounded-[20px]">
          <div className="pt-[27px] flex  justify-center  ">
            <Pana />
          </div>
          {/* ----------------- Formulaire ----------------- */}
          <form
            className="pt-[37px] grid grid-col"
            method="post"
            onSubmit={handleSubmit(onSubmit)}
          >
            <span className="mb-2 ml-[45px]">Adresse mail</span>
            <label className="px-[26px] flex justify-center">
              <input
                className="indent-5 focus:outline-none rounded-[25px] w-full sm:w-[300px] h-[35px] border border-[#BDD8EC]  bg-[#FFFFFF]"
                type="text"
                {...register("email", { required: true })}
              />
            </label>
            {errors.email && (
              <span className="ml-[45px] text-red-500 block">
                Ce champ est requis
              </span>
            )}
            <span className="mt-[20px] mb-2 ml-[45px]">Mot de passe</span>
            <label className="relative px-[26px] flex justify-center">
              <input
                className="indent-5 focus:outline-none rounded-[25px] w-full sm:w-[300px] h-[35px] border border-[#BDD8EC] bg-[#FFFFFF]"
                type="password"
                id="password"
                {...register("password", { required: true })}
              />
              <div className="hover:cursor-pointer absolute right-[60px] top-[5px] w-[25px] h-[25px]" onClick={() => {
                toggleEye();
              }}>
                {isEyeCrossed ? (<Eye color={"#5B9ECF"} />) : (<EyeCrossed color={"#5B9ECF"} />)}
              </div>
            </label>
            {errors.password && (
              <span className="ml-[45px] text-red-500 block">
                Ce champ est requis
              </span>
            )}
            {errorMessage && (
              <span className="mt-4 ml-[45px] text-red-500">
                {errorMessage}
              </span>
            )}
            <div className="pt-8 w-fit mx-auto">
              <PrimaryButton isLoading={isLoadingNatif} text={"Connexion"} type="submit" />
            </div>
          </form>
          {/* ----------------- Google connexion ----------------- */}
          <div>
            <div className="flex justify-center mb-2 mt-8">
              <div className="flex items-center">
                <hr className="border-top-3 border-[#B7DAF3] w-[126px] -ml-0.5" />
              </div>
              <div className="mx-4">
                <p className="text-[14px]">ou</p>
              </div>
              <div className="flex items-center">
                <hr className="border-[#B7DAF3] w-[126px] -mr-0.5" />
              </div>
            </div>
            <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
              <div className="w-fit mx-auto mt-4">
                <div className={`${isLoadingGoogle && "hidden"}`}>
                  <GoogleLogin
                    onSuccess={credentialResponse => {
                      loginGoogleSuccess(credentialResponse);
                    }}
                    onError={() => {
                      loginGoogleError();
                    }}
                  />
                </div>
                {isLoadingGoogle &&
                  <div className="grid grid-cols-3 items-center justify-between border w-[150px] px-4 h-[40px] bg-white">
                    <div className="col-span-2">
                      <LuLoader2 className='animate-spin mx-auto' />
                    </div>
                    <FcGoogle size={20} className="ml-auto" />
                  </div>
                }
              </div>
            </GoogleOAuthProvider>
          </div>
          {/* ----------------- La fin ----------------- */}
          <div>
            <div className="flex justify-center mb-2 mt-8">
              <div className="flex items-center">
                <hr className="border-top-3 border-[#B7DAF3] w-[126px] -ml-0.5" />
              </div>
              <div className="mx-4">
                <p className="text-[14px]">ou</p>
              </div>
              <div className="flex items-center">
                <hr className="border-[#B7DAF3] w-[126px] -mr-0.5" />
              </div>
            </div>
            <Link to="/Inscription">
              <p className="text-[#5B9ECF] pb-[55px] hover:text-[#497EA6] text-center">
                {" "}
                Inscrivez-vous !
              </p>
            </Link>
          </div>
        </div>
        <div className="mt-[5px] mx-auto max-w-sm">
          <Link to="/oublier-password">
            <p className="text-center sm:text-left hover:underline hover:text-[#497EA6] text-[14px] text-[#5B9ECF]">
              Mot de passe oublié ?
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Connexion;
