import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate,redirect } from "react-router-dom";
import { AlertMessageContext } from "Context/AlertMessageProvider";
import cookie from "js-cookie";
import { AuthContext } from "Context/AuthProvider";
import { isUser } from "utils/roles";

function PopupDeleteEspace({ setIsActive, isActive }) {

  const { value } = useContext(AuthContext);
  const { alert } = useContext(AlertMessageContext);
  const token = cookie.get("token");
  const redirection = useNavigate();

  const deleteProfil = async () => {
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    if (isUser(value.user)) {
      
      await fetch(process.env.REACT_APP_API +"user/delete", options)
      .then(response => {
      })
      .then(()=> {
        value.setIsLoggedIn(false);
        value.setPatchKpi(false);
        value.setId();
        cookie.remove("token");
        cookie.remove("StatusConnexion");
        cookie.remove("id");
        redirection("/")
        setTimeout(()=> {
          alert.setSuccessMessage("Votre compte a bien été supprimé !");
        }, 1000)
        setTimeout(() => {
          alert.setSuccessMessage("");
        }, 5000); //supprimer l'alert dans 5 seconde
      })
      .catch(err => {
        console.error(err);
        alert.setErrorMessage(
          "Une erreur est survenue lors de la suppression de votre compte."
        );
        setTimeout(() => {
          alert.setErrorMessage("");
        }, 5000); //supprimer l'alert dans 5 seconde
      });
    } else {
      await fetch(process.env.REACT_APP_API +"client/delete", options)
      .then(response => {
      })
      .then(()=> {
        value.setIsLoggedIn(false);
        value.setPatchKpi(false);
        value.setId();
        cookie.remove("token");
        cookie.remove("StatusConnexion");
        cookie.remove("id");
        redirection("/")
        setTimeout(()=> {
          alert.setSuccessMessage("Votre compte a bien été supprimé .");
        }, 1000)
        setTimeout(() => {
          alert.setSuccessMessage("");
        }, 5000); //supprimer l'alert dans 5 seconde
      })
      .catch(err => {
        console.error(err);
        alert.setErrorMessage(
          "Une erreur est survenue lors de la suppression de votre compte ."
        );
        setTimeout(() => {
          alert.setErrorMessage("");
        }, 5000); //supprimer l'alert dans 5 seconde
      });
    }
  }

  return (
    <div className="fixed z-[50]">
      {/* ARRIERE PLAN */}
      <div
        className={
          isActive.create
            ? "fixed top-0 left-0 w-full h-screen backdrop-blur brightness-75"
            : "invisible"
        }
        onClick={() => {
          setIsActive({
            create: (isActive.create = false),
          });
        }}
      ></div>
      <div
        className={
          isActive.create
            ? "fixed flex flex-col border-[1.5px]  border-[#EBBDB5]  gap-[12px] top-[320px] left-[10%] md:left-[20%] lg:left-[30%] w-[80%] md:w-[60%] lg:w-[40%] h-[277px] rounded-[20px] overscroll-none overflow-y-scroll smaller-scroll-bar bg-white drop-shadow-lg transition duration-400 ease-out "
            : "invisible"
        }
      >
        {/* ********** ××××× ************** */}
        <div className="flex justify-end mt-5 mr-5">
          <button
            className={`flex items-center justify-center w-[38px] h-[38px]
                     bg-[#FFE7E7] border-[1px] border-[#EBBDB5] rounded-full hover:bg-[#FFCBCB]`}
            onClick={() => {
              setIsActive({
                create: (isActive.create = false),
              });
            }}
          >
            <div className="absolute w-[1.81px] h-[13.31px] bg-[#AF4636] rotate-45"></div>
            <div className="w-[1.81px] h-[13.31px] bg-[#AF4636] rotate-[135deg]"></div>
          </button>
        </div>
        {/* FORM */}
        <div className="flex justify-center mt-10 text-center">
          <p>Confirmez-vous la suppression de votre compte ?</p>
        </div>
        <div className="flex justify-center mt-10">
          <button className="bg-[#FFE7E7]  border border-[#EBBDB5] w-[163px] h-[39px] rounded-[100px]"
                  onClick={() => {
                    deleteProfil();
                  }}  
          >
            <p className="text-[#AF4636]">Oui, je confirme</p>
          </button>
        </div>
      </div>
    </div>
  );
}

export default PopupDeleteEspace;
