import React from 'react'
import { Helmet } from "react-helmet";  

function Cgu() {
    return (
        <div >
            <Helmet>  
<title>MyFinPlace - Conditions générales d'utilisation (CGU)</title>  
</Helmet>  
            <section className="containerxl flex flex-col my-12">
                <div className='text-center pt-12 pb-12 '>
                    <h2 className=' robotoSlab bigscreen:text-[50px]'>
                        Conditions générales d'utilisation
                    </h2>
                    <p className='bigscreen:text-[25px]'>
                        Merci de lire ces conditions attentivement avant d&#39;utiliser MyFinPlace.
                        <br />
                        En utilisant MyFinPlace, vous acceptez d&#39;être soumis aux présentes conditions.
                    </p>
                </div>
                <div className='pb-[85px]'>
                    <div className=''>
                        <h5 className='bigscreen:text-[30px]'>1. COMMUNICATIONS ELECTRONIQUES</h5>
                    </div>
                    <div className='py-9 flex'>
                        <p className='bigscreen:text-[20px] text-justify'>
                            Quand vous utilisez un quelconque service MyFinPlace ou que vous nous envoyez des courriers électroniques, SMS ou autres communications depuis vos équipements fixes ou mobiles, vous communiquez avec nous électroniquement. Nous communiquerons avec vous électroniquement par divers moyens, tels que par courrier électronique, SMS, messages de notification dans l'application ou en postant des courriers électroniques ou des communications sur la plateforme ou à travers les autres services MyFinPlace, tels que notre Gestionnaire de communication. A des fins contractuelles, vous acceptez que tous les accords, informations, divulgations et autres communications que nous vous enverrons électroniquement remplissent toutes les obligations légales des communications par écrit, à moins qu'une loi impérative spécifique impose un autre mode de communication.
                        </p>
                    </div>
                </div>
                <div className='pb-[85px]'>
                    <div className=''>
                        <h5 className='bigscreen:text-[30px]'>2. PROPRIETE INTELLECTUELLE, DROIT D'AUTEUR, ET PROTECTION DES BASES DE DONNEES
                        </h5>
                    </div>
                    <div className='py-9 flex'>
                        <p className='bigscreen:text-[20px] text-justify'>
                            Tout le contenu présent ou rendu disponible à travers les services MyFinPlace, tels que les textes, les graphiques, les logos, les boutons, les images et les compilations de données, est la propriété de MyFinPlace de ses sociétés affiliées ou de ses fournisseurs de contenu et est protégé par le droit français et international de la propriété intellectuelle, d'auteur et de protection des bases de données. La compilation de tout le contenu présent ou rendu disponible à travers les services MyFinPlace est la propriété exclusive de MyFinPlace et est protégé par le droit français et international de la propriété intellectuelle et de protection des bases de données.
                            <br /><br />
                            Vous ne devez pas extraire et/ou réutiliser de façon systématique des parties du contenu de tout MyFinPlace sans notre autorisation expresse et écrite. En particulier, vous ne devez pas utiliser de robot d'aspiration de données, ou tout autre outil similaire de collecte ou d'extraction de données pour extraire (en une ou plusieurs fois), pour réutiliser une partie substantielle d'un quelconque service MyFinPlace, sans notre accord express et écrit. Vous ne devez pas non plus créer et/ou publier vos propres bases de données qui comporteraient des parties substantielles d'un service MyFinPlace sans notre accord express et écrit.
                        </p>
                    </div>
                </div>
                <div className='pb-[85px]'>
                    <div className=''>
                        <h5 className='bigscreen:text-[30px]'>3. MARQUES DEPOSEES </h5>
                    </div>
                    <div className='py-9 flex'>
                        <p className='bigscreen:text-[20px] text-justify'>
                            Tout lien ou avec MyFinPlace ou avec ce site doit faire l’objet d’une autorisation préalable et écrite de MyFinPlace et ne pourra être réalisé que vers l’adresse de la page d’accueil du site <a href="https://myfinplace.com/">https://myfinplace.com/</a> et sous réserve de l’intégration du logo. MyFinPlace se réserve le droit de supprimer tout lien, quelle que soit sa nature, susceptible de porter atteinte à son image.
                            <br /><br />
                            Par ailleurs, les graphiques, logos, en-têtes de page, boutons, scripts et noms de services inclus ou rendus disponibles à travers un service MyFinPlace sont des marques ou visuels de MyFinPlace. Les marques et visuels de MyFinPlace ne peuvent pas être utilisés pour des produits ou services qui n'appartiennent pas à MyFinPlace, ou d'une quelconque manière qui pourrait provoquer une confusion parmi les clients, ou d'une quelconque manière qui dénigre ou discrédite MyFinPlace. Toutes les autres marques qui n'appartiennent pas à MyFinPlace et qui apparaissent sur un quelconque service MyFinPlace sont la propriété de leurs propriétaires respectifs, qui peuvent, ou non, être affiliés, liés ou parrainés par MyFinPlace.
                        </p>
                    </div>
                    <div className='flex'>
                        <p className='bigscreen:text-[20px] text-justify'>
                            En utilisant la plateforme comme client, vous autorisez MyFinPlace à exploiter votre logo à des fins strictement publicitaires et promotionnelles sur son site internet ainsi que sur ses réseaux sociaux. <br />
                            Cette autorisation est sans limite de temps à compter du début de la relation contractuelle. <br />
                            Toutefois, dans un souci de maintien d’une bonne relation client et de sérénité pour votre communication, cette autorisation est révocable à tout moment sur simple demande adressée par mail à l'adresse suivante : <br /><br />

                            <a href="mailto:iris@myfinplace.com">iris@myfinplace.com</a>
                        </p>
                    </div>
                </div>
                <div className='pb-[85px]'>
                    <div className=''>
                        <h5 className='bigscreen:text-[30px]'>4. LICENCE ET ACCES </h5>
                    </div>
                    <div className='py-9 flex'>
                        <p className='bigscreen:text-[20px] text-justify'>

                            Sous réserve du respect des présentes conditions générales d'utilisation et des conditions des services et du paiement de tous les sommes applicables, MyFinPlace ou ses fournisseurs de contenu vous accorde une licence limitée, non exclusive, non transférable, non sous licenciable pour l'accès et à l'utilisation personnelle et non commerciale des services MyFinPlace. Cette licence n'inclut aucun droit d'utilisation d'un service MyFinPlace ou de son contenu pour la vente ou tout autre utilisation commerciale ; de collecte et d'utilisation d'un listing produit, descriptions, ou prix de produits ; de toute utilisation dérivée d'un service MyFinPlace ou de son contenu ; de tout téléchargement ou copie des informations d'un compte pour un autre commerçant ; ou de toute utilisation de robot d'aspiration de données, ou autre outil similaire de collecte ou d'extraction de données.
                            <br /><br />
                            Tous les droits qui ne vous ont pas été expressément accordés dans ces conditions générales d'utilisation ou dans les conditions d'un service sont réservés et restent à MyFinPlace ou à ses licenciés, fournisseurs, éditeurs, titulaires de droits, ou tout autre fournisseur de contenu. Aucun service MyFinPlace ou tout ou partie de celui-ci ne doit être reproduit, copié, vendu, revendu, visité ou exploité pour des raisons commerciales sans notre autorisation expresse et écrite.
                            <br /><br />
                            Vous ne devez pas cadrer ou utiliser des techniques de cadrage (framing) pour insérer toute marque, logo ou autre information commerciale (y compris des images, textes, mises en pages ou formes) de MyFinPlace sans notre autorisation expresse et écrite. Vous ne devez pas utiliser de méta tags ou tout autre « texte caché » utilisant le nom ou les marques déposées de MyFinPlace sans notre autorisation expresse et écrite.
                            <br /><br />
                            Vous ne devez pas faire un mauvais usage d'un service MyFinPlace. Vous devez utiliser les services MyFinPlace comme autorisé par la loi. Les licences accordées par MyFinPlace prendront fin si vous ne respectez pas ces conditions générales d'utilisation.
                        </p>
                    </div>
                </div>
                <div className='pb-[85px]'>
                    <div className=''>
                        <h5 className='bigscreen:text-[30px]'>5. UTILISATION DE LA PLATEFORME ET DE VOTRE COMPTE </h5>
                    </div>
                    <div className='py-9 flex '>
                        <p className='bigscreen:text-[20px] text-justify'>
                            Vous pouvez avoir besoin d'un compte personnel MyFinPlace pour utiliser certain services MyFinPlace. Les services disponibles sur MyFinPlace sont fournis à titre gratuits. En tant que client, vous acceptez néanmoins de verser une commission à MyFinPlace pour tout contrat établit entre les différents partis. Le paiement des prestations dues à MyFinPlace en cas d’accord se fera après signature du contrat de service.
                            <br /><br />
                            En d’utilisation des services fournis par MyFinPlace, vous êtes informé et acceptez de vous acquitter des règles qui suivent :
                            <br /><br />
                            - Paiement des services rendus par les profils disponibles sur la plateforme et avec lesquels vous avez conclu un contrat ;
                            <br /><br />

                            - Obligation de non-concurrence : si la mise en relation s’effectue par la plateforme créée par MyFinPlace, vous vous engagez, quel que soit votre rôle, à ne pas tenter de contacter l’entreprise ou le profil pour toute mission, qu’elle soit postée sur le site ou analogue à celle-ci ;
                            <br /><br />

                            - Obligation d’honnêteté et d’exactitude d’information : le client ainsi que le candidat s’engagent à fournir sur la platforme MyFinPlace ainsi que dans le cadre de mise en relation des informations exactes, et de modifier ces informations dès que possible en cas de changement ;
                            <br /><br />

                            - Interdiction de porter préjudice à MyFinPlace ou à son site, de porter atteinte à la sécurité de sa plateforme de mise en relation,
                            <br /><br />

                            - Interdiction de porter atteinte à tout autre utilisateur ou client du site ;
                            <br /><br />

                            - Obligation de respecter l’ensemble des points listées dans ces CGU.
                            <br /><br />

                            - Interdiction d’utiliser un moyen automatisé pour naviguer sur la platforme MyFinPlace ;
                            <br /><br />

                            - Interdiction d’usurper l’identité d’un tiers ;
                            <br /><br />

                            - Interdiction de postuler massivement à des opportunités présentes sur la platforme de MyFinPlace, particulièrement si celles-ci ne correspondent pas à votre orientation professionnelle ou vos choix de carrière.
                            <br /><br />

                            La plateforme MyFinPlace fonctionne selon le processus suivant :
                            <br /><br />
                            - Mise en relation entre les usagers « client » et les usagers « profils »;
                            <br /><br />
                            - Validation du profil par le client;
                            <br /><br />
                            - Création d’un contrat ainsi que d’un devis après échange sur les modalités de la mission / du poste;
                            <br /><br />
                            - Paiement de la commission due à MyFinPlace en cas de validation de la période d’essai de 15 jours par le client;
                            <br /><br />
                            - Facturation de la mission et paiement du profil selon le mode de collaboration et les termes du contrat établis;
                            <br /><br />
                            Si vous utilisez un quelconque service MyFinPlace, vous êtes responsable du maintien de la confidentialité de votre compte et mot de passe, des restrictions d'accès à votre ordinateur et autres équipements, et dans la limite de ce qui est autorisé par la loi applicable, vous acceptez d'être responsable de toutes les activités qui ont été menées depuis de votre compte ou avec votre mot de passe. Vous devez prendre toutes les mesures nécessaires pour vous assurer que votre mot de passe reste confidentiel et sécurisé, et devez nous informer immédiatement si vous avez des raisons de croire que votre mot de passe est connu de quelqu'un d'autre, ou si le mot de passe est utilisé ou susceptible d'être utilisé de manière non autorisée. Vous êtes responsable de la validité et du caractère complets des informations que vous nous avez fournies, et devez nous informer de tout changement concernant ces informations.
                            <br /><br />
                            Vous ne devez pas utiliser un quelconque service MyFinPlace d'une façon qui causerait, ou serait susceptible de causer une interruption, un dommage, ou une altération d'un service MyFinPlace, ou dans un but frauduleux, ou en relation avec un crime ou une activité illégale, ou dans le but provoquer des troubles, nuisances ou causes d'anxiétés. En tant qu’utilisateur de MyFinPlace, vous êtes également responsable des données présentes sur votre profil et sur les opportunités d’emploi que vous mettez en ligne. Vous ne devez donc en aucun être à l’origine d’un contenu qui :
                            <br /><br />
                            - Incite à la haine raciale, à la discrimination ou à la haine de personnes en raison de leur sexe, de leur orientation ou identité sexuelle, à la discrimination ou à la haine des personnes en situation de handicap ;
                            <br /><br />

                            - Incite à la mise en œuvre d’actes terroristes et de crimes contre l’humanité, ou à leur apologie ;
                            <br /><br />

                            - Diffuse de la pornographie ou pédopornographie ;
                            <br /><br />

                            - Incite à la violence ;
                            <br /><br />

                            - Contient ou met en avant des propos injurieux ;
                            <br /><br />

                            - Met en avant des propos diffamatoires concernant un usager ou une personne extérieure à MyFinPlace ;
                            <br /><br />

                            Le contenu que vous mettez en ligne doit également correspondre aux services proposés par MyFinPlace, et ne doit en aucun cas être porteur d’opinions politiques ou religieuses.
                            <br /><br />

                            Nous nous réservons le droit de refuser de fournir un quelconque service MyFinPlace ou de clôturer les comptes si votre comportement nous donne une raison justifiée de le faire. Ce sera notamment le cas si vous enfreignez les lois applicables, les dispositions contractuelles applicables, nos lignes directrices ou nos politiques, toutes accessibles depuis la plateforme. Nonobstant une telle limitation ou résiliation, vous continuerez à avoir accès au contenu et aux services que vous avez achetés jusqu'à ce moment-là.
                        </p>
                    </div>
                </div>
                <div className='pb-[85px]'>
                    <div className=''>
                        <h5 className='bigscreen:text-[30px]'>6. REVENDICATIONS DE PROPRIETE INTELLECTUELLE </h5>
                    </div>
                    <div className='py-9 flex'>
                        <p className='bigscreen:text-[20px] text-justify'>
                            MyFinPlace respecte la propriété intellectuelle d'autrui. Si vous pensez qu'un de vos droits de propriété intellectuelle a été utilisé d'une manière qui puisse faire naître une crainte de violation desdits droits, merci de nous en informer.
                        </p>
                    </div>
                </div>
                <div className='pb-[85px]'>
                    <div className=''>
                        <h5 className='bigscreen:text-[30px]'>7. AUTRES ENTREPRISES </h5>
                    </div>
                    <div className='py-9 flex'>
                        <p className='bigscreen:text-[20px] text-justify'>
                            Des tiers autres que MyFinPlace gèrent des annonces, proposent des services et vendent des prestations sur cette plateforme. De surcroît, nous fournissons des liens vers des sites internet de société affiliées et d'un certain nombre d'entreprises. Nous ne sommes pas responsables de l'examen ou de l'évaluation, et nous ne garantissons pas les opportunités de ces entreprises ou de ces particuliers, ou le contenu de leurs sites internet. Sauf mention contraire explicite dans les présentes conditions générales d'utilisation, MyFinPlace n'assume aucune responsabilité ou obligation pour les actes, produits ou contenus de ces entreprises ou de ces particuliers ou d'autres tiers. Vous êtes informé quand un tiers est impliqué dans votre transaction, et nous pouvons partager vos informations en lien avec cette transaction avec ce tiers. Vous devez examiner leurs politiques de confidentialité et autres conditions générales d'utilisation avec attention.
                            <br /><br />
                            Les comptes « client » s’engagent à :
                            <br /><br />
                            - Respecter les règles d’utilisation de la plateforme listées plus haut au point 5 ainsi que l’ensemble ce ces CGU ;
                            <br /><br />
                            - Ne pas écarter un candidat sur la base de critères discriminatoires ;
                            <br /><br />
                            - Diffuser uniquement des opportunités d’emploi correspondant à un poste existant et disponible ;
                            <br /><br />
                            - Respecter le consentement libre et éclairé du candidat selon la réglementation imposée par la CNIL;
                            <br /><br />
                            MyFinPlace se réserve le droit de refuser la mise en ligne ou de supprimer toute opportunité qui ne correspondrait pas aux règles citées plus haut. En cas de récidive, MyFinPlace se réserve également le droit de supprimer votre compte.
                        </p>
                    </div>
                </div>
                <div className='pb-[85px]'>
                    <div className=''>
                        <h5 className='bigscreen:text-[30px]'>8. DESCRIPTION DES SERVICES, TIERS ET ROLE DE MYFINPLACE  </h5>
                    </div>
                    <div className='py-9 flex'>
                        <p className='bigscreen:text-[20px] text-justify'>
                            MyFinPlace est une plateforme créée par la société MyFinPlace, dédiée à la mise en relation entre les clients et les personnes en recherche d’emploi sur le secteur de la finance. MyFinPlace propose différents services à ses usagers tels que :
                            <br /><br />
                            - La possibilité de créer un compte;
                            <br /><br />
                            - La possibilité de créer un profil et de le mettre en ligne pour les usagers « profil »;
                            <br /><br />
                            - La possibilité de mettre en ligne des opportunités d’emploi / de mission pour les usagers « client »;
                            <br /><br />
                            - La mise en relation entre les comptes « client » et les comptes « profil » via le hub;
                            <br /><br />
                            - L’établissement et l’émission de factures relatives aux missions réalisées par les usagers « profil » (service non-intégré au site);
                            <br /><br />
                            MyFinPlace permet à des sociétés tierces de lister et de proposer leurs services sur MyFinPlace. Dans chacun de ces cas, ceci est indiqué sur la page respective de détail du la prestation. Bien que MyFinPlace, en tant qu'hébergeur, facilite les transactions réalisées sur la place de marché de MyFinPlace, MyFinPlace n'est ni l'acheteur ni le vendeur des prestations des vendeurs tiers. MyFinPlace fournit un lieu de rencontre dans lequel les acheteurs et vendeurs complètent et finalisent leurs transactions. En conséquence, pour la vente de ces prestations de tiers, un contrat de vente est formé uniquement entre l'acheteur et le vendeur tiers. MyFinPlace n'est pas partie à un tel contrat et n'assume aucune responsabilité ayant pour origine un tel contrat ou découlant de ce contrat de vente. MyFinPlace n'est ni l'agent ni le mandataire des vendeurs tiers. Sauf dans les cas mentionnés ci-dessous dans cette section, le vendeur tiers est responsable des ventes de prestations et des réclamations ou tout autre problème survenant ou lié au contrat de vente entre lui et l'acheteur.
                        </p>
                    </div>
                </div>
                <div className='pb-[85px]'>
                    <div className=''>
                        <h5 className='bigscreen:text-[30px]'>9. NOTRE RESPONSABILITE </h5>
                    </div>
                    <div className='py-9 flex'>
                        <p className='bigscreen:text-[20px] text-justify'>
                            Nous ferons de notre mieux pour assurer la disponibilité des services MyFinPlace et que les transmissions se feront sans erreurs. Toutefois, du fait de la nature d'internet, ceci ne peut être garanti. De plus, votre accès aux services MyFinPlace peut occasionnellement être suspendu ou limité pour permettre des réparations, la maintenance, ou ajouter une nouvelle fonctionnalité ou un nouveau service. Nous nous efforcerons de limiter la fréquence et la durée de ces suspensions ou limitations.
                            <br /><br />
                            Dans le cadre de ses relations avec des professionnels, MyFinPlace n'est pas responsable (i) des pertes qui n'ont pas été causées par une faute de notre part, ou (ii) des pertes commerciales (y compris les pertes de profit, bénéfice, contrats, économies espérées, données, clientèle ou dépenses superflues), ou (iii) toute perte indirecte ou consécutive qui n'étaient pas prévisibles par vous et nous quand vous avez commencé à utiliser le service MyFinPlace.
                            <br /><br />
                            Nous ne serons pas tenus pour responsables des délais ou de votre impossibilité à respecter vos obligations en application de ces conditions si le délai ou l'impossibilité résulte d'une cause en dehors de notre contrôle raisonnable.
                            <br /><br />
                            Rien dans ces conditions ne vise à limiter ou n'exclure notre responsabilité en cas de dol, ou en cas de décès ou de préjudice corporel causé(e) par notre négligence ou une faute lourde.
                        </p>
                    </div>
                </div>
                <div className='pb-[85px]'>
                    <div className=''>
                        <h5 className='bigscreen:text-[30px]'>10. DROIT APPLICABLE </h5>
                    </div>
                    <div className='py-9 flex'>
                        <p className='bigscreen:text-[20px] text-justify'>
                            Les présentes conditions générales d'utilisation sont soumises au droit français.
                        </p>
                    </div>
                </div>
                <div className='pb-[85px]'>
                    <div className=''>
                        <h5 className='bigscreen:text-[30px]'>11. MODIFICATION DU SERVICE OU DES CONDITIONS GENERALES D'UTILISATION </h5>
                    </div>
                    <div className='py-9 flex'>
                        <p className='bigscreen:text-[20px] text-justify'>
                            Nous nous réservons le droit de faire des modifications sur tout service MyFinPlace, à nos procédures, à nos termes et conditions, y compris les présentes conditions générales d'utilisation à tout moment. Vous êtes soumis aux termes et conditions, procédures et conditions générales d'utilisation en vigueur au moment où vous utilisez le service MyFinPlace. Si une stipulation de ces conditions générales d'utilisation est réputée non valide, nulle, ou inapplicable, quelle qu'en soit la raison, cette stipulation sera réputée divisible et n'affectera pas la validité et l'opposabilité des stipulations restantes.
                        </p>
                    </div>
                </div>
                <div className='pb-[85px]'>
                    <div className=''>
                        <h5 className='bigscreen:text-[30px]'>12. RENONCIATION </h5>
                    </div>
                    <div className='py-9 flex'>
                        <p className='bigscreen:text-[20px] text-justify'>
                            Si vous enfreignez ces conditions générales d'utilisation et que nous ne prenons aucune action, nous serions toujours en droit d'utiliser nos droits et voies de recours dans toutes les autres situations où vous violeriez ces conditions générales d'utilisation.
                        </p>
                    </div>
                </div>
                <div className='pb-[85px]'>
                    <div className=''>
                        <h5 className='bigscreen:text-[30px]'>13. NOS COORDONNEES </h5>
                    </div>
                    <div className='py-9 flex'>
                        <p className='bigscreen:text-[20px] text-justify'>
                            La platforme MyFinPlace.com appartient à, et sa maintenance est effectuée par la SAS MyFinPlace.
                            <br /><br />
                            SAS MyFinPlace <br></br>55 Avenue Marceau - 75116 Paris <br></br>Capital social : 10 000,00 € <br></br> Siret : 915 141 428 00028 <br /> TVA intracommunautaire : FR25915141428
                        </p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Cgu