import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useSearchParams } from "react-router-dom";
import DetailsOffre from "./Components/DetailsOffre";
import OffreCard from "./Components/OffreCard";
import Filtres from "Pages/Statics/Filtres/FiltreMenue";
// import Filtres from "./Components/ZoneFiltres";
import { CardContext } from "./Context/CardProvider";
import { CheckboxContext } from "./Context/CheckboxProvider";
import { AuthContext } from "../../../Context/AuthProvider";
import { isClient, isUser } from "utils/roles";
import { verifComptable } from "utils/verifComptable";
import Pagination from "Components/Pagination/Pagination";
import { Helmet } from "react-helmet";  

import MobileFilter2 from "Components/MobileFilter/MobileFilter2";
import { LuLoader2 } from "react-icons/lu";


const accents = require("remove-accents");

export default function Opportunite(props) {
  const { CardContextValue } = useContext(CardContext);
  const { checkBoxValues, setCheckboxes, resetFilters } = useContext(CheckboxContext);
  const { value } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [consultHistory, setConsultHistory] = useState();
  const [offers, setOffers] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);
  const [search, setSearch] = useState([]);
  const [mergeTerm, setmergeTerm] = useState([]);
  const [filterBase, setFilterBase] = useState();
  const [filterArray, setFilterArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [btnIsActive, setBtnIsActive] = useState({})
  const [nbResult, setNbResult] = useState();
  const [searchParams] = useSearchParams();
  const [selectedOffer, setSelectedOffer] = useState(null)
  const [historyId, setHistoryId] = useState(null)
  const [isComptable, setIsComtable] = useState(false);

  const [currentPage, setCurentPage] = useState(1);
  const [nbPage, setNbPage] = useState(1);
  const [timer, setTimer] = useState();

  let localisation = searchParams.get('localisation')
  let searchTermQuery = searchParams.get('searchTerm')

  const token = Cookies.get("token");
  
  useEffect(() => {
    if (searchTermQuery !== "" && !checkBoxValues.checkboxes["champLibre"]) {
      checkBoxValues.checkboxes["champLibre"] = searchTermQuery;
    }
  }, [searchTermQuery])

  useEffect(() => {
    if (localisation && localisation.length > 0) {
      if (!checkBoxValues.checkboxes["localisation"].includes(localisation)) {
        let filterSelected = checkBoxValues.checkboxes["localisation"]
        filterSelected.push(+localisation)
        setCheckboxes(prevData => ({
          ...prevData,
          ["localisation"]: filterSelected
        }))
      }
    }
  }, [localisation])

  async function getOffers() {
    setIsLoading(true); // Définir l'état de chargement à true
    if (searchTerm.length < 3) {
      await axios.post(process.env.REACT_APP_API + `Lesoffres/${currentPage}`, checkBoxValues.checkboxes, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.data.nbResult > 0) {
            setOffers(res.data.offre ?? [])
          } else {
            setOffers([])
          }
          setNbResult(res.data)
          setNbPage(Math.ceil(res.data.nbResult / 20));
          setIsLoading(false); // Définir l'état de chargement à false après avoir récupéré les données
          setNbResult("Opportunités en cours : " + res.data.nbResult)
        })
        .catch((error) => {
          setIsLoading(false); // Définir l'état de chargement à false après avoir récupéré les données
        });
    } else if (searchTerm.length >= 3) {
      await axios.get(process.env.REACT_APP_API + "Lesoffres", {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          // window.scrollTo(0, 0,'smooth');
          const keywords = searchTerm.split(" ");
          // data filtré avec barre de recherche
          const filteredData = res.data.offre.filter((e) => {
            const values = JSON.stringify(e).toLowerCase();
            return keywords.every((word) =>
              accents
                .remove(values.replace(/[-']/g, " ").toLowerCase())
                .includes(accents.remove(word.replace(/[-']/g, " ").toLowerCase()))
            );
          });
          if (filterArray.length > 0) {
            // setData(filteredJobOffers(filteredData))
          } else {
            // setData(filteredData);
          }

          setIsLoading(false); // Définir l'état de chargement à false après avoir récupéré les données

        })
        .catch((error) => {
          setIsLoading(false); // Définir l'état de chargement à false après avoir récupéré les données
        });
    }
  }

  useEffect(() => {
    // Si l'utilisateur est un user
    if (isUser(value.user)) {
      // On récupère son historique
      axios.get(process.env.REACT_APP_API + "consult/userOffre",
        {
          headers:
            { Authorization: `Bearer ${token}` }
        }
      ).then(res => {
        setConsultHistory(res.data)
      })
      // On récupère ses information pour vérifier s'il est un comptable
      axios.get(process.env.REACT_APP_API + `skillsportfolio/${value.user.id}`,
        {
          headers:
            { 'Content-Type': 'application/json' }
        }
      ).then((res) => {
        setIsComtable(verifComptable(res.data.jobTitle, res.data.inscritOrdre, res.data.inscritCncc, res.data.expertComptable))
      })
    }
  }, [value])

  const handleReset = () => {
    setSearch("")
    setSearchTerm([])
    setFilterArray([])
    setmergeTerm({})
    setBtnIsActive({})
    checkBoxValues.setCheckboxes({
      contract: [],
      others: []
    });
  }

  // récupère la data des boutons
  const handleBase = (e) => {
    setFilterBase(e.filtre);
  }

  const handleSearchTerm = (e) => {
    setSearchTerm(e);
  }

  const handleFilter = (e) => {
    setFilterArray(e);
  }


  const onPageChangeHandler = (page) => {
    window.scrollTo(0, 0)
    setCurentPage(page)
  }

  useEffect(() => {
    setCurentPage(1)
    //On met un timeout pour éviter les spams de requetes quand le user écrit vite
    clearTimeout(timer);
    setTimer(setTimeout(() => {
      getOffers()
    }, 500));//500 ms
  }, [checkBoxValues.checkboxes]);


  useEffect(() => {
    getOffers()
  }, [currentPage]);


  return (
    <main className="containerxl">
           <Helmet>  
                  <title>MyFinPlace - les opportunités</title>  
                </Helmet>  
      <div className="flex gap-5 items-start py-4">
        <div className="bg-white rounded border sticky top-[114px] hidden sm:w-[268px] md:block">
        <Filtres handleSearchTerm={handleSearchTerm} search={search} searchTermQuery={searchTermQuery} onFilter={handleFilter} onEmpty={handleReset} resetFilters={resetFilters} base={filterBase} />
        </div>
        <div className="flex gap-8 items-start flex-1">
          <div className="grid grid-cols-1 gap-8 flex-1" >
            {isLoading ? (
              <LuLoader2 className='animate-spin text-[#5B9ECF]' size={26} />
            ) : offers.length === 0 ? (
              <p>Il n'y a pas d'opportunités</p>
            ) : (
              offers.map((card, i) => {
                if (isComptable) {
                  if (card.contract.filter((x) => x.name === "Mission").length === 0 || card.contract.length >= 2) {
                    return (
                      <OffreCard key={i} card={card} setHistoryId={setHistoryId} historyId={historyId} consultHistory={consultHistory} isComptable={isComptable} />
                    )
                  }
                } else {
                  return (
                    <OffreCard key={i} card={card} setHistoryId={setHistoryId} historyId={historyId} consultHistory={consultHistory} isComptable={isComptable} />
                  )
                }
              })
            )}
            <Pagination totalPages={nbPage} currentPage={currentPage} onPageChange={onPageChangeHandler} MaxSlot={10} />
          </div>
          <div className="flex-1 hidden sticky top-[114px] xl:block">
            <DetailsOffre historyId={historyId} isComptable={isComptable} />
          </div>
        </div>
      </div>
      <MobileFilter2 resetFilters={resetFilters} onFilter={handleFilter} onEmpty={handleReset} base={filterBase} />
    </main>
  );
}