import React from "react";

export default function Expertises() {
    return (
        <div>
            <div className="pb-20">
                <h2 className="text-center">
                    Les domaines d'expertise couverts
                </h2>
            </div>
            <section className="grid md:grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-8 justify-center">
                {/* Card 1 */}
                <div className="bg-greylight pb-16 pt-8 px-8 rounded-xl border-2 border-lightblue">
                    <div className="flex pb-2 mb-6 border-b border-lightblue">
                        <h5 className="bigscreen:text-[30px]">
                            AUDIT, CONFORMITE & CONTROLE INTERNE
                        </h5>
                    </div>
                    <ul className="">
                        <li className="flex items-center">
                            <span className="text-middleblue text-2xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Abus de marché</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-2xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Audit</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Cartographie des risques</p>
                        </li >
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Conformité</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Contrôle interne</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Cybersécurité</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-2xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">FATCA</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-2xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Finance durable RSE / ESG/ ISR</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Inspection générale</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">KYC/KYT</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">LCB/FT</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-2xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Loi Sapin 2</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">MiFID II</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-2xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Règlement européen PRIIPs</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">RGPD</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Risque opérationnel</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Sécurité financière</p>
                        </li>
                    </ul>
                </div>
                {/* Card 2 */}
                <div className="bg-greylight pb-16 pt-8 px-8 rounded-xl border-2 border-lightblue">
                    <div className="flex pb-2 mb-6 border-b border-lightblue">
                        <h5 className="bigscreen:text-[30px]">
                            COMPTABILITE, CONTROLLING & DIRECTION FINANCIERE
                        </h5>
                    </div>
                    <ul className="">
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Comptabilité assurantielle (PCA)</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Comptabilité bancaire (PCEC)</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Comptabilité générale (PCG)</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Comptabilité immobilière (OPCI/SCPI/SCI)</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Comptabilité technique</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Communication financière</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Consolidation statutaire</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Contrôle comptable</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Contrôle de gestion</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Contrôle financier</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Direction financière</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Fiscalité</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Gestion de la paie</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Normes IFRS</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Normes Locales (US, LUX, UK, French gaap)</p>
                        </li>
                        
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Reporting</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Restructuring</p>
                        </li>
                    </ul>
                </div>
                {/* Card 3 */}
                <div className="bg-greylight pb-16 pt-8 px-8 rounded-xl border-2 border-lightblue">
                    <div className="flex pb-2 mb-6 border-b border-lightblue">
                        <h5 className="bigscreen:text-[30px]">
                            FINANCE QUANTITATIVE
                        </h5>
                    </div>
                    <ul className="">
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Actuariat</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Analyse financière</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Asset Management</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Fund Management</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Gestion de portefeuille</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Ingénierie financière</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Modélisation financière</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Trading</p>
                        </li>
                    </ul>
                </div>
                {/* Card 4 */}
                <div className="bg-greylight pb-16 pt-8 px-8 rounded-xl border-2 border-lightblue">
                    <div className="flex pb-2 mb-6 border-b border-lightblue">
                        <h5 className="bigscreen:text-[30px]">
                            IT FOR FINANCE
                        </h5>
                    </div>
                    <ul className="">
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">BA Finance</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Chefferie de projet Finance</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Conduite du changement</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">MOA/AMOA Finance</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">MOE/AMOE Finance</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">PM/PMO Finance</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Process/Orga</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Transformation digitale</p>
                        </li>
                    </ul>
                </div>
                {/* Card 5 */}
                <div className="bg-greylight pb-16 pt-8 px-8 rounded-xl border-2 border-lightblue">
                    <div className="flex pb-2 mb-6 border-b border-lightblue">
                        <h5 className="bigscreen:text-[30px]">
                            OPERATIONS FINANCIERES
                        </h5>
                    </div>
                    <ul className="">
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Front Office Finance</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Middle Office Finance</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Back Office Finance</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">M&A / Fusions-acquisitions</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Property Management</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Trade Finance</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Transactions services</p>
                        </li>

                    </ul>
                </div>
                {/* Card 6 */}
                <div className="bg-greylight pb-16 pt-8 px-8 rounded-xl border-2 border-lightblue">
                    <div className="flex pb-2 mb-6 border-b border-lightblue">
                        <h5 className="bigscreen:text-[30px]">
                            REGLEMENTAIRE & PRUDENTIEL
                        </h5>
                    </div>
                    <ul className="">
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">BCBS 239</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Consolidation prudentielle</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Directive CRD V & Règlement CRR II</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Règlement EMIR</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Reporting Banque de France</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Reporting Bâle III/Bâle IV</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Reporting Solvency II</p>
                        </li>
                    </ul>
                </div>
                {/* Card 7 */}
                <div className="bg-greylight pb-16 pt-8 px-8 rounded-xl border-2 border-lightblue">
                    <div className="flex pb-2 mb-6 border-b border-lightblue">
                        <h5 className="bigscreen:text-[30px]">
                            RISQUES

                        </h5>
                    </div>
                    <ul className="">
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Risque de contrepartie</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Risque de crédit</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Risque de liquidité</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Risque de marché</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Risque de taux</p>
                        </li>
                    </ul>
                </div>
                {/* Card 8 */}
                <div className="bg-greylight pb-16 pt-8 px-8 rounded-xl border-2 border-lightblue">
                    <div className="flex pb-2 mb-6 border-b border-lightblue">
                        <h5 className="bigscreen:text-[30px]">
                            TRESORERIE & ALM
                        </h5>
                    </div>
                    <ul className="">
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">ALM / Gestion actif-passif</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Cash Management</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Financement</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Gestion de trésorerie</p>
                        </li>
                        <li className="flex items-center">
                            <span className="text-middleblue text-3xl pr-4">
                                &bull;
                            </span>
                            <p className="bigscreen:text-[25px]">Recouvrement</p>
                        </li>
                    </ul>
                </div>
            </section>
        </div>

    );
}
