import React from 'react'

function pen(props) {
    return (
        <svg  className={props.classProps ? props.classProps : ""} width={props.width ? props.width : "18"} height={props.height ? props.height : "18"} viewBox="0 0 18 18" 
        fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 16.5H15" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M13.7525 6.08325L14.309 5.52825C14.5282 5.30915 14.702 5.04904 14.8206 4.76276C14.9392 4.47648 15.0003 4.16964 15.0004 3.85976C15.0004 3.54988 14.9394 3.24303 14.8208 2.95672C14.7023 2.67042 14.5285 2.41027 14.3094 2.19112C14.0903 1.97198 13.8302 1.79814 13.5439 1.67952C13.2576 1.5609 12.9508 1.49983 12.6409 1.49979C12.331 1.49976 12.0242 1.56076 11.7379 1.67931C11.4516 1.79787 11.1914 1.97165 10.9723 2.19075L10.4158 2.74725C10.4158 2.74725 10.4855 3.92925 11.528 4.97175C12.5705 6.01425 13.7525 6.084 13.7525 6.084L8.63977 11.1967C8.29327 11.5432 8.12077 11.7157 7.92952 11.865C7.70452 12.0405 7.46077 12.1912 7.20277 12.3142C6.98452 12.4185 6.75202 12.4957 6.28777 12.6502L4.31827 13.3072M10.4158 2.7465L5.30302 7.86C4.95652 8.2065 4.78327 8.379 4.63477 8.57025C4.45905 8.79545 4.30841 9.03914 4.18552 9.297C4.08127 9.51525 4.00402 9.74775 3.84877 10.212L3.19252 12.1815M3.19252 12.1815L3.03277 12.6622C2.99532 12.7743 2.98985 12.8947 3.01697 13.0097C3.04408 13.1247 3.10271 13.2299 3.18628 13.3135C3.26985 13.3971 3.37505 13.4557 3.49008 13.4828C3.60511 13.5099 3.72543 13.5044 3.83752 13.467L4.31827 13.3072M3.19252 12.1815L4.31827 13.3072" stroke="black" strokeWidth="1.5" />
        </svg>
    )
}

export default pen