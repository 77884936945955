import React, { useState, useEffect } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { add } from "date-fns";
import { Link } from 'react-router-dom';
import { useCreatePath } from "react-admin";
import AddCalendarEvent from "Components/AddCalendarEvent/AddCalendarEvent";

const formatTime = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}h${formattedMinutes}`;
};

function EntretiensPromgrammes() {
  const [data, setData] = useState(null);
  const createPath = useCreatePath();
  const CreateLink = (resource, id, text) => {
    return <Link to={createPath({ resource: resource, type: 'show', id: id })} className="text-[#0091FF] hover:underline">{text}</Link>
  };
  useEffect(() => {
    axios.get(process.env.REACT_APP_API + `GetStatusEntretien?status=programmés`, {
      headers: {
        Authorization: `Bearer ${cookie.get("token")}`
      }
    })
      .then((response) => {
        setData(response.data["hydra:member"].filter(item => add(new Date(item.date_choice), { hours: 2 }) > new Date()));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  if (data === null) {
    return (<p>Chargement</p>);
  } else if (data.length === 0) {
    return (<p>Aucun entretiens programmés</p>);
  } else {
    return (
      <>
        <h3 className="text-center mb-4">Entretien programmés</h3>
        {[...data].reverse().slice(0).map((e, index) => (
          <div className="border border-[#D6D3D3] rounded-[25px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] mb-[20px]">
            <div className="text-center bg-[#3D88F8] text-white py-2 rounded-t-[25px] text-[20px]">
              <b>{new Date(e.date_choice).toLocaleDateString("fr-FR", {
                day: "2-digit",
                month: "long",
                year: "numeric",
              })} à {formatTime(new Date(e.date_choice))}</b>
            </div>
            <div className="flex flex-row">
              <div className="w-1/3 flex justify-center items-center gap-4">
                <AddCalendarEvent appointment={e} />
                <a href={window.location.origin + "/Visio/" + e.id} target="_blank" className="hover:cursor-pointer inline-block bg-[#030F30] hover:bg-white text-white hover:text-[#082677] border-2 border-[#030F30] rounded-full py-[10px] px-[25px] text-base font-semibold p-4 shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)]">
                  Accéder à la visioconférence
                </a>
              </div>
              <span className="border border-[#D6D3D3]"></span>
              <div className="w-1/3 flex flex-col py-2 items-center">
                <div>
                  <p><b>Client ID :</b> {CreateLink('clients', e.id_client["@id"], e.id_client.id)}</p>
                  <p>{e.id_client.first_name} {e.id_client.last_name}</p>
                  <p>{e.id_client.roles[0]}</p>
                  <p>{e.id_client.email}</p>
                  <p>{e.id_client.mobile}</p>
                </div>
              </div>
              <span className="border border-[#D6D3D3]"></span>
              <div className="w-1/3 flex flex-col py-2 items-center">
                <div>
                  <p><b>Profil ID :</b> {CreateLink('users', e.id_user["@id"], e.id_user.anonymous)}</p>
                  <p>{e.id_user.first_name} {e.id_user.last_name}</p>
                  <p>{e.id_user.roles[0]}</p>
                  <p>{e.id_user.email}</p>
                  <p>{e.id_user.mobile}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* <tr>
          <td className="py-2 px-4 border-b">{CreateLink('appointments', e["@id"], e.id)}</td>
          <td className="py-2 px-4 border-b">{CreateLink('clients', e.id_client["@id"], e.id_client.id)}</td>
          <td className="py-2 px-4 border-b">{e.id_client.first_name}</td>
          <td className="py-2 px-4 border-b">{e.id_client.last_name}</td>
          <td className="py-2 px-4 border-b">{e.id_client.roles[0]}</td>
          <td className="py-2 px-4 border-b">{e.id_client.email}</td>
          <td className="py-2 px-4 border-b">{e.id_client.mobile}</td>
          <td className="py-2 px-4 border-b border-l">{CreateLink('users', e.id_user["@id"], e.id_user.anonymous)}</td>
          <td className="py-2 px-4 border-b">{e.id_user.first_name}</td>
          <td className="py-2 px-4 border-b">{e.id_user.last_name}</td>
          <td className="py-2 px-4 border-b">{e.id_user.roles[0]}</td>
          <td className="py-2 px-4 border-b">{e.id_user.email}</td>
          <td className="py-2 px-4 border-b">{e.id_client.mobile}</td>
          <td className="py-2 px-4 border-b">{new Date(e.date_choice).toLocaleDateString("fr-FR", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          })} à {formatTime(new Date(e.date_choice))}</td>
        </tr> */}
      </>
    );
  }
}

export default EntretiensPromgrammes;