import { useState } from "react";
import mockup from "assets/img/Hub/Mockup.png"
import Card1 from "./Card1";
import Card2 from "./Card2";
import Card3 from "./Card3";
import Card4 from "./Card4";
import Card5 from "./Card5";
import { Helmet } from "react-helmet";  

export default function Hub() {
  const [isActive, setActive] = useState('');

  return (
    <div className="containerxl">
      <Helmet>  
<title>MyFinPlace - Le HUB </title>  
</Helmet>  
      <h1 className="text-center font-medium text-[64px] pt-8 ">Le HUB</h1>
      <p className="text-center font-medium text-[24px] pt-8 mb-16">Le HUB de MyFinPlace est l’outil qui vous permettra de trouver un profil, d’entrer en relation avec lui et de contractualiser en vue de travailler avec lui.</p>
      <div className="flex flex-col md:flex-row justify-center gap-16  items-center text-center">
        <img className="w-1/3" src={mockup} alt="" srcSet="" />
      </div>
      <span className="inline-block mt-16" />
      <p className="text-center text-[32px] py-16 text-black font-bold">Le rôle du HUB ?  <span className="text-[#5B9ECF]">Digitaliser et automatiser entièrement</span> la rencontre entre une entreprise et un profil</p>
      <Card1 isActive={isActive} setActive={setActive} />
      <Card2 isActive={isActive} setActive={setActive} />
      <Card3 isActive={isActive} setActive={setActive} />
      <Card4 isActive={isActive} setActive={setActive} />
      <Card5 isActive={isActive} setActive={setActive} />
      <span className="inline-block mt-16" />
    </div>
  )
}