import { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import axios from "axios";
import Cookies from "js-cookie";
import { format } from "date-fns";
import { fr } from 'date-fns/locale'
import { isClient, isUser } from "utils/roles";

import { AlertMessageContext } from "Context/AlertMessageProvider";
import { AuthContext } from "Context/AuthProvider";
import { ModalContext } from 'Context/ModalProvider';

import PrimaryButton from 'Components/Buttons/PrimaryButton'
import AvaibilityBadge from "Components/AvaibilityBadge/AvaibilityBadge";
import ShareModal from "Modals/ShareModal";

import { IoShareSocialSharp } from "react-icons/io5";
import OffreBadge from "Components/Badge/OffreBadge";
import { parseIriToId } from "utils/parseIritoId";

function DetailOffreHeader({ title, sector, date_time_create, dateModify, details }) {

    const dispatch = useDispatch()

    const [appling, setAppling] = useState(false)
    const [canApply, setCanApply] = useState(false)
    const [isUserOrAnonymous, setIsUserOrAnonymous] = useState(false)

    const { alert } = useContext(AlertMessageContext);
    const { value } = useContext(AuthContext);
    const { openModal } = useContext(ModalContext);

    const token = Cookies.get("token");

    const navigate = useNavigate()

    const ShareHandler = () => {
        openModal("Partager", <ShareModal url={window.location.origin + "/les-opportunites/" + details.id} />)
    }

    const handleApply = async () => {
        if (!canApply) {
            return
        }

        if (!token) {
            alert.errorAlert("Vous devez être connecté pour pouvoir postuler à cette opportunité.")
            navigate("/Connexion")
        } else {

            if (appling) {
                return
            }

            try {
                setAppling(true)

                const { data } = await axios.get(`${process.env.REACT_APP_API}user/pourcentage`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        }
                    }
                )

                const profilCompleted = data.count
                const completedNeeded = data.need

                if (profilCompleted < completedNeeded) {
                    alert.errorAlert("Vous devez remplir votre profil à 100 % pour postuler.")
                    setAppling(false)
                    return
                }

                const body = {
                    idOffre: `/api/offres/${details.id}`,
                };

                await axios.post(process.env.REACT_APP_API + `user/candidature/create`, body, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                })


                alert.successAlert("Vous avez bien postulé à l'opportunité.")
                dispatch({type:'socket/apply',payload:parseIriToId(details.id_client.idOffre[0].id_client)})
                setCanApply(false)
                setAppling(false)

            } catch (err) {
                console.error(err)
                alert.errorAlert("Une erreur est survenue. Veuillez rafraîchir la page.")
                setAppling(false)
            }


        }
    }


    const getOffresPostuler = () => {

        if (!token) {
            setCanApply(true);
            return
        }

        setCanApply(false)

        const options = {
            method: 'GET',
            url: process.env.REACT_APP_API + `user/candidature`,
            headers: {
                Authorization: `Bearer ${token}`
            },
        };

        axios.request(options)
            .then((res) => {
                const data = res.data;
                let test = data.filter(post => post.id_offre.id === parseInt(details.id))

                if (test.length === 0) {
                    setCanApply(true)
                } else {
                    setCanApply(false)
                }
            })
            .catch(function (error) {
            });
    }

    useEffect(() => {
        if (value.user !== undefined) {
            setIsUserOrAnonymous(value.user?.roles?.includes("ROLE_USER"));
        } else {
            setIsUserOrAnonymous(true)
        }
    }, [value])

    useEffect(() => {
        isUser(value.user) && getOffresPostuler()
        !value.isLoggedIn && setCanApply(true);
        !value.isLoggedIn && setIsUserOrAnonymous(true);
    }, [details.id])

    return (
        <div className="flex flex-col md:flex-row justify-between items-start border-b-2 p-4 sticky top-[0px] bg-white">
            <div className="w-full">
                <div className="flex justify-between w-full">
                    <p className="text-[#5B9ECF] text-[24px] font-bold">{title}</p>
                </div>
                <div className="flex gap-2 items-center mb-4">
                    <p>Secteur</p>
                    <div className={`flex flex-col gap-1 px-4 py-2 rounded bg-sectoriellepastel`}>
                        <span className={`text-[12px] text-sectorielledark`}>{sector.tag.name}</span>
                        <div className={`flex flex-row gap-[4px]`}>
                            <hr className={`w-[14px] h-[2px] font-medium ${sector.level >= 1 ? "bg-sectoriellelevel" : "bg-[#D9D9D9]"}`} />
                            <hr className={`w-[14px] h-[2px] font-medium ${sector.level >= 2 ? "bg-sectoriellelevel" : "bg-[#D9D9D9]"}`} />
                            <hr className={`w-[14px] h-[2px] font-medium ${sector.level >= 3 ? "bg-sectoriellelevel" : "bg-[#D9D9D9]"}`} />
                            <hr className={`w-[14px] h-[2px] font-medium ${sector.level >= 4 ? "bg-sectoriellelevel" : "bg-[#D9D9D9]"}`} />
                        </div>
                    </div>
                </div>
                <div className="flex gap-2 items-center mb-4">
                    <p>Date de début</p>
                    <AvaibilityBadge avaibility={{ "disponibility": details.availability[0], "availability_date": details.availability_date }} />
                </div>
            </div>
            <div className="shrink-0 flex flex-col items-end">
                {
                    isUserOrAnonymous ?
                        <PrimaryButton className={"m-1 my-4 md:my-0"} isLoading={appling} disabled={!canApply} text={canApply ? "Postuler" : "Candidature envoyée"} onClick={() => handleApply()} />
                        :
                        null
                }
                <div className="mb-4 h-[35px] w-[35px] hover:bg-[#e2e8f0] hover:cursor-pointer flex items-center justify-center rounded-full">
                    <IoShareSocialSharp onClick={ShareHandler} size={26} className="srink-0" />
                </div>
                <small>Mise en ligne :
                    <span className="text-black bg-greylight rounded p-2">
                        {dateModify ?
                            format(Date.parse(dateModify), "LLLL yyyy", { locale: fr })
                            :
                            format(Date.parse(date_time_create), "LLLL yyyy", { locale: fr })
                        }
                    </span>
                </small>

            </div>
        </div>
    )
}

export default DetailOffreHeader