import React from "react";

function SearchHome() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.875 3.03117C7.21018 3.03117 6.55188 3.16211 5.93766 3.41653C5.32345 3.67094 4.76537 4.04384 4.29527 4.51394C3.82518 4.98404 3.45227 5.54212 3.19786 6.15633C2.94345 6.77054 2.8125 7.42885 2.8125 8.09367C2.8125 8.75848 2.94345 9.41679 3.19786 10.031C3.45227 10.6452 3.82518 11.2033 4.29527 11.6734C4.76537 12.1435 5.32345 12.5164 5.93766 12.7708C6.55188 13.0252 7.21018 13.1562 7.875 13.1562C9.21766 13.1562 10.5053 12.6228 11.4547 11.6734C12.4041 10.724 12.9375 9.43633 12.9375 8.09367C12.9375 6.75101 12.4041 5.46334 11.4547 4.51394C10.5053 3.56454 9.21766 3.03117 7.875 3.03117ZM1.6875 8.09367C1.68763 7.09999 1.92708 6.12096 2.38559 5.23939C2.8441 4.35782 3.50819 3.59965 4.32168 3.02901C5.13516 2.45836 6.07412 2.09204 7.05912 1.96101C8.04412 1.82998 9.04619 1.93812 9.98057 2.27626C10.9149 2.6144 11.7541 3.17261 12.4272 3.90366C13.1002 4.63471 13.5872 5.5171 13.8471 6.47619C14.107 7.43528 14.1321 8.44285 13.9202 9.41368C13.7084 10.3845 13.2658 11.29 12.63 12.0537L16.1475 15.5712C16.2028 15.6227 16.2471 15.6848 16.2778 15.7538C16.3086 15.8228 16.3251 15.8972 16.3264 15.9728C16.3278 16.0483 16.3139 16.1233 16.2856 16.1934C16.2573 16.2634 16.2152 16.327 16.1618 16.3804C16.1084 16.4339 16.0447 16.476 15.9747 16.5043C15.9047 16.5325 15.8296 16.5464 15.7541 16.5451C15.6786 16.5438 15.6041 16.5272 15.5351 16.4965C15.4661 16.4658 15.404 16.4214 15.3525 16.3662L11.835 12.8487C10.9314 13.6013 9.83207 14.0808 8.66574 14.2311C7.49942 14.3814 6.31441 14.1962 5.24956 13.6972C4.1847 13.1982 3.28409 12.4061 2.65322 11.4137C2.02236 10.4213 1.68737 9.26963 1.6875 8.09367Z"
        fill="#455A64"
      />
    </svg>
  );
}

export default SearchHome;
