
import { IoChevronDownOutline } from "react-icons/io5";
import inscription1 from "assets/img/Hub/Card5/Publier une opportunité 1.png";
import inscription2 from "assets/img/Hub/Card5/Publier une opportunité 2.png";
import inscription3 from "assets/img/Hub/Card5/Publier une opportunité 3.png";
import { Link } from "react-router-dom";

export default function Card5({ isActive, setActive }) {
  return (
    <div className="border rounded-[8px] border-[#2632384D] border-[4px] py-4 px-6 mt-16">
      <h1 onClick={() => setActive({ Question5: !isActive.Question5 })} className="font-semibold text-[#263238] cursor-pointer flex gap-2  "  >
        <div className="flex justify-between w-full items-center">
          <p className="text-[32px] text-black font-bold">Vous souhaitez publier <span className="text-[#5B9ECF]">une opportunité</span> ?</p>
          <div className={isActive.Question5 ? "rotate-180 transition duration-500" : "transition duration-500"}>
            <IoChevronDownOutline className="text-[#5B9ECF]" />
          </div>
        </div>
      </h1>
      <div className={isActive.Question5 ? "animation-box-visible" : "animation-box-hidden "} id="box1">
      <div className="pr-5 text-justify animation-content" id="content2">
        <p className="font-semibold text-lg my-8">Seule une entreprise peut publier une opportunité.</p>
              <div className="flex flex-row my-16 items-center justify-between "> 
                  <div>
                      <h3>1. Connectez-vous à votre espace personnel et rendez-vous dans l’onglet “Mes opportunités” dans le menu situé à gauche de votre espace personnel ;</h3>
                  </div>
                  <div className="contents">
                      <img className="w-1/3" src={inscription1} alt="" />
                  </div>
              </div>
              <div className="flex flex-row my-16 items-center justify-between"> 
                  <div>
                      <h3>2. Cliquez sur l’encadré “créer une opportunité”</h3>
                  </div>
                  <div className="contents">
                      <img className="w-1/3" src={inscription2} alt="" />
                  </div>
              </div>
              <div className="flex flex-row my-16 items-center justify-between"> 
                  <div>
                      <h3>3. Complétez l’ensemble des informations relatives à votre offre (titre du poste, contrat, date de début, descriptif, etc.) puis rendez-vous sur l’onglet récapitulatif pour publier votre offre</h3>
                       <br />
                      <h3 className="font-semibold">NB : Une fois publiée, votre offre sera analysée par notre équipe. Si celle-ci est conforme à notre politique d’utilisation, elle sera validée sous 24H et visible sur la page ‘Les opportunités”.</h3>
                  </div>
                  <div className="contents">
                      <img className="w-1/3" src={inscription3} alt="" />
                  </div>
              </div>
              <div className="my-8">
              <Link to="/mon-espace/mes-offres">
            <a href="#" target="_blank" className="bg-[#5B9ECF] hover:bg-[#2D77AD] text-[19px] text-white py-2 px-8 rounded-[5px] inline-block">Je souhaite publier une opportunité</a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}