import React from 'react'
import "./style.css"
import clsx from "clsx";

function Checkbox({ id, name, label, level, value, checked, onChange, handleLevel, tagLevel, defaultDesign }) {
    if (tagLevel) {
        var colors = {}
        switch (tagLevel) {
            case "sectorialSkills":
                colors = {
                    bgColor: "bg-sectoriellepastel",
                    textColor: "text-sectorielledark",
                    bgLevel: "bg-sectoriellelevel",
                    hover: "hover:bg-sectoriellelevel"
                }
                break;
            case "fonctionalSkills":
                colors = {
                    bgColor: "bg-fonctionnellepastel",
                    textColor: "text-fonctionnelledark",
                    bgLevel: "bg-fonctionnellelevel",
                    hover: "hover:bg-fonctionnellelevel"
                }
                break;
            case "computerSkills":
                colors = {
                    bgColor: "bg-informatiquepastel",
                    textColor: "text-informatiquedark",
                    bgLevel: "bg-informatiquelevel",
                    hover: "hover:bg-informatiquelevel"
                }
                break;
            case "languageSkills":
                colors = {
                    bgColor: "bg-linguistiquepastel",
                    textColor: "text-linguistiquedark",
                    bgLevel: "bg-linguistiquelevel",
                    hover: "hover:bg-linguistiquelevel"
                }
                break;
        }
    }
    return (
        <div className='flex flex-col gap-1 mb-2'>
            <label
                className={clsx(
                    'px-2 py-0.5 rounded-lg m-1 hidde-checkbox inline-block cursor-pointer text-center',
                    { "checkbox-button": defaultDesign },
                    { "checked  ": checked && defaultDesign },
                    { "border border-sectorielledark text-sectorielledark hover:bg-sectorielledark hover:text-white": tagLevel == "sectorialSkills" || tagLevel == "secteur" },
                    { "bg-sectoriellepastel": checked && tagLevel == "sectorialSkills" || checked && tagLevel == "secteur" },
                    { "border border-fonctionnelledark text-fonctionnelledark hover:bg-fonctionnelledark hover:text-white": tagLevel == "fonctionalSkills" || tagLevel == "fonctionnelle" },
                    { "bg-fonctionnellepastel": checked && tagLevel == "fonctionalSkills" || checked && tagLevel == "fonctionnelle" },
                    { "border border-informatiquedark text-informatiquedark hover:bg-informatiquedark hover:text-white": tagLevel == "computerSkills" || tagLevel == "informatique" },
                    { "bg-informatiquepastel": checked && tagLevel == "computerSkills" || checked && tagLevel == "informatique" },
                    { "border border-linguistiquedark text-linguistiquedark hover:bg-linguistiquedark hover:text-white": tagLevel == "languageSkills" || tagLevel == "linguistique" },
                    { "bg-linguistiquepastel": checked && tagLevel == "languageSkills" || checked && tagLevel == "linguistique" },
                )}>
                <input
                    type="checkbox"
                    id={id}
                    name={name}
                    value={value}
                    checked={checked}
                    onChange={(e) => onChange(e)}
                />
                <span className="checkbox-label text-sm">{label}</span>
            </label>
            {tagLevel && !defaultDesign && checked &&
                <div className="flex flex-row gap-2 px-2">
                    <hr className={`w-[20px] h-[7px] rounded hover:cursor-pointer ${colors.hover} ${level >= 1 ? colors.bgLevel : "bg-[#D9D9D9]"}`} onClick={() => { handleLevel(value, 1) }} />
                    <hr className={`w-[20px] h-[7px] rounded hover:cursor-pointer ${colors.hover} ${level >= 2 ? colors.bgLevel : "bg-[#D9D9D9]"}`} onClick={() => { handleLevel(value, 2) }} />
                    <hr className={`w-[20px] h-[7px] rounded hover:cursor-pointer ${colors.hover} ${level >= 3 ? colors.bgLevel : "bg-[#D9D9D9]"}`} onClick={() => { handleLevel(value, 3) }} />
                    <hr className={`w-[20px] h-[7px] rounded hover:cursor-pointer ${colors.hover} ${level >= 4 ? colors.bgLevel : "bg-[#D9D9D9]"}`} onClick={() => { handleLevel(value, 4) }} />
                </div>}
        </div>
    )
}

export default Checkbox