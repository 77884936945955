
export default function location(props) {
    return (

        <svg className={props.classProps ? props.classProps : ""} width={props.width ? props.width : "12"} height={props.height ? props.height : "12"} viewBox="0 0 12 12" 
        fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.95117 2.99048C5.6545 2.99048 5.36449 3.07845 5.11782 3.24327C4.87114 3.4081 4.67888 3.64236 4.56535 3.91645C4.45182 4.19054 4.42212 4.49214 4.47999 4.78311C4.53787 5.07409 4.68073 5.34136 4.89051 5.55114C5.10029 5.76092 5.36757 5.90378 5.65854 5.96166C5.94951 6.01953 6.25111 5.98983 6.5252 5.8763C6.79929 5.76277 7.03356 5.57051 7.19838 5.32383C7.3632 5.07716 7.45117 4.78715 7.45117 4.49048C7.45072 4.09279 7.29254 3.71152 7.01133 3.43032C6.73013 3.14911 6.34886 2.99093 5.95117 2.99048ZM5.95117 5.24048C5.80284 5.24048 5.65783 5.19649 5.53449 5.11408C5.41116 5.03167 5.31503 4.91454 5.25826 4.77749C5.2015 4.64045 5.18664 4.48965 5.21558 4.34416C5.24452 4.19867 5.31595 4.06504 5.42084 3.96015C5.52573 3.85526 5.65937 3.78383 5.80485 3.75489C5.95034 3.72595 6.10114 3.7408 6.23818 3.79757C6.37523 3.85433 6.49236 3.95046 6.57477 4.0738C6.65719 4.19714 6.70117 4.34214 6.70117 4.49048C6.70094 4.68932 6.62185 4.87995 6.48125 5.02056C6.34065 5.16116 6.15001 5.24025 5.95117 5.24048Z" 
            fill={props.color ? props.color : "#263238"} />
            <path d="M8.83332 1.60591C8.12065 0.893422 7.16932 0.470173 6.16294 0.417855C5.15656 0.365536 4.16646 0.687856 3.38376 1.32261C2.60105 1.95736 2.0812 2.85954 1.92456 3.85503C1.76791 4.85052 1.98557 5.86875 2.53552 6.7132L5.36928 11.0635C5.43234 11.1603 5.51857 11.2398 5.62014 11.2949C5.72171 11.3499 5.83541 11.3788 5.95094 11.3788C6.06647 11.3788 6.18018 11.3499 6.28175 11.2949C6.38332 11.2398 6.46954 11.1603 6.5326 11.0635L9.36645 6.7132C9.87674 5.92985 10.1023 4.9949 10.0052 4.06505C9.90813 3.13521 9.49439 2.26699 8.83332 1.60591ZM8.73805 6.30382L5.95095 10.5823L3.16384 6.30382C2.31071 4.99415 2.49369 3.2415 3.59891 2.13623C3.90778 1.82735 4.27446 1.58233 4.67803 1.41517C5.0816 1.248 5.51414 1.16196 5.95095 1.16196C6.38777 1.16196 6.82031 1.248 7.22388 1.41517C7.62744 1.58233 7.99413 1.82735 8.303 2.13623C9.40822 3.2415 9.59117 4.99415 8.73805 6.30382Z" 
            fill={props.color ? props.color : "#263238"} />
        </svg>
    )
}
