import { useState, useEffect, useContext } from 'react'

import { AuthContext } from "Context/AuthProvider";

import axios from "axios";
import cookie from "js-cookie";

import { FaHeart } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";

function LikeButton({ id, countFavoris, isFavoris }) {

    const token = cookie.get("token");


    const { value } = useContext(AuthContext);

    const [favoritescount, setFavoriteCount] = useState(countFavoris)
    const [isBookmarkedByCurrentClient, setIsBookmarkedByCurrentClient] = useState(isFavoris)
    const [isClient, setIsClient] = useState(false)

    const getFavoriteCounter = async () => {
        const res = await axios.get(
            process.env.REACT_APP_API + `favoritescounter/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token ? `Bearer ${token}` : ""
                }
            }
        )
        setFavoriteCount(res.data.count)
        setIsBookmarkedByCurrentClient(res.data.isBookmarkedByCurrentClient)
    }


    const favoriteHandler = () => {
        if (!isClient) {
            return
        }
        if (!isBookmarkedByCurrentClient) {
            const body = JSON.stringify({ idUser: `api/users/${id}` });
            axios.post(process.env.REACT_APP_API + "client/favoris/create", body, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    getFavoriteCounter();
                })
                .catch((error) => {
                })
                .finally(() => {
                });
        } else {
            axios.delete(
                process.env.REACT_APP_API + `removefavorite/${id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
                .then((res) => {
                    getFavoriteCounter();
                })
                .catch((error) => {
                });
        }
    }

    useEffect(() => {
        if (value.user != undefined) {
            setIsClient(value.user?.roles?.includes("ROLE_CLIENT"));
        }
    }, [value])

    if (favoritescount === undefined) {
        getFavoriteCounter()
    }

    return (
        <div className={`flex items-center gap-1 text-[#497EA6] ${isClient ? "hover:bg-[#e2e8f0] cursor-pointer" : ""} p-2 rounded`} onClick={() => favoriteHandler()}>
            {
                isBookmarkedByCurrentClient ?
                    <FaHeart />
                    :
                    <FaRegHeart />
            }
            <small className='text-black'>{favoritescount}</small>
        </div>
    )
}

export default LikeButton