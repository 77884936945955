import React, { useState, useRef, useEffect, useContext } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop, convertToPixelCrop } from 'react-image-crop'
import axios from "axios";
import cookie from "js-cookie";

import { useOutletContext } from 'react-router-dom'
import { AlertMessageContext } from "Context/AlertMessageProvider";
import PrimaryButton from 'Components/Buttons/PrimaryButton'
import SecondaryButton from 'Components/Buttons/SecondaryButton'
import 'react-image-crop/dist/ReactCrop.css'

export default function CropImageModal({ src, setPopupCrop, setPhotoUser }) {
  const [userInformation] = useOutletContext();
  const token = cookie.get("token");
  const { alert } = useContext(AlertMessageContext);
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null)
  const [completedCrop, setCompletedCrop] = useState({})
  const [crop, setCrop] = useState({})

  function onImageLoad(e) {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget

    const cenCrop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        16 / 9,
        width,
        height
      ),
      width,
      height
    )

    setCrop(cenCrop)
  }

  async function canvasPreview(image, canvas, crop, scale = 1, rotate = 0) {
    const ctx = canvas.getContext('2d')

    if (!ctx) {
      throw new Error('No 2d context')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY

    const rotateRads = rotate * (Math.PI / 180)
    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 3) Rotate around the origin
    ctx.rotate(rotateRads)
    // 2) Scale the image
    ctx.scale(scale, scale)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
    )

    ctx.restore()
  }

  function useDebounceEffect(fn, waitTime, deps,) {
    useEffect(() => {
      const t = setTimeout(() => {
        fn.apply(undefined, deps)
      }, waitTime)

      return () => {
        clearTimeout(t)
      }
    }, deps)
  }

  useDebounceEffect(async () => {
    if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
      // We use canvasPreview as it's much faster than imgPreview.
      canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop)
    }
  }, 100, [completedCrop])

  async function Save() {
    if (completedCrop.unit !== "px") {
      setError("Veuillez sélectionner la zone de votre photo")
      return
    }
    setError("")
    setIsLoading(true);
    const canva = document.getElementById("canva");
    var dataURL = canva.toDataURL("image/png");

    const formData = new FormData();
    formData.append('file', dataURL);
    axios.post(process.env.REACT_APP_API + `user/${userInformation.id}/image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      setIsLoading(false);
      setPhotoUser(res.data.fileUrl);
      alert.successAlert("Votre photo a été modifiée avec succès !")
      setPopupCrop(false)
    }).catch((error) => {
      setIsLoading(false);
      alert.errorAlert("Erreur lors de la modification de votre photo !")
    });
  }

  return (
    <>
      <div className="fixed h-full w-full top-0 left-0 bg-black opacity-75 z-30"></div>
      <div className="fixed max-w-[80vw] -translate-x-2/4 top-[5vh] left-[50%] max-h-[85vh] bg-white rounded-[5px] z-40 py-8 px-12 overflow-y-scroll">
        <div className='grid grid-cols-2 items-center gap-4'>
          <div>
            {src && <ReactCrop crop={crop} style={{ maxHeight: "70vh" }} onChange={(_, percentCrop) => setCrop(percentCrop)} onComplete={(c) => setCompletedCrop(c)} aspect={16 / 9} keepSelection={true}>
              <img src={src} ref={imgRef} onLoad={onImageLoad} />
            </ReactCrop>}
          </div>
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              maxWidth: "100%",
              margin: "0 auto"
            }}
            id='canva'
          />
        </div>
        <div className='flex items-center justify-end gap-2 mt-4'>
          <SecondaryButton onClick={() => setPopupCrop(false)} text={"Annuler"} />
          <PrimaryButton isLoading={isLoading} onClick={() => Save()} text={"Changer ma photo de profil"} />
        </div>
        <div className='text-right text-red-500'>{error && error}</div>
      </div>
    </>
  )
}