import { Link } from "react-router-dom";

export default function FreelanceCard1() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 border-[4px] rounded-[8px] px-4 py-6">
      <div className="col-span-2 font-medium">
        <h1 className="text-[32px]">Vous êtes freelance et souhaitez intégrer notre programme d’apport d’affaires ?</h1>
        <h1 className="text-[24px] mt-4">Rejoignez notre club - VIP (Very Important Profil)</h1>
        <p className="text-[16px] font-medium mt-4">Vous avez connaissance d’une opportunité et souhaitez en faire part aux équipes de MyFinPlace ?</p>
        <p className="text-[16px] font-medium mt-4">Grâce à notre programme d’apport d’affaires, MyFinPlace vous rémunèrera pour chaque contractualisation.</p>
        <Link to="mailto:contact@myfinplace.com" className="bg-[#5B9ECF] hover:bg-[#2D77AD] text-white py-2 px-4 rounded-[5px] inline-block mt-6">Je souhaite informer MyFinPlace d’un LEAD commercial</Link>
      </div>
      <div className="mt-8 md:mt-0 text-center md:text-right">
        <h1 className="text-[#5B9ECF] text-[48px]">1 500€</h1>
        <p className="font-medium">pour chaque LEAD commercial contractualisé</p>
        <p className="text-[36px] italic">ou</p>
        <h1 className="text-[#5B9ECF] text-[48px]">5%</h1>
        <p className="font-medium">pour chaque LEAD commercial contractualisé</p>
        <p className="text-[12px] mt-4">*HT pour 60 jours de mission facturés à minima</p>
        <p className="text-[12px]">Paiement à J+30</p>
      </div>
    </div>
  )
}