import { useState, useEffect } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import CreateFormation from 'Components/Formation/CreateFormation'
import IndexFormation from 'Components/Formation/IndexFormation'
import PrimaryButton from 'Components/Buttons/PrimaryButton'
import SecondaryButton from 'Components/Buttons/SecondaryButton'
import { v4 as uuidv4 } from 'uuid';



function Formations() {

    let formationDefaultValue = {
        id: uuidv4(),
        bac: "",
        dateEnd: "",
        date_end: "",
        dateStart: "",
        date_start: "",
        diplome: "",
        school: "",
        description: ""
    }




    const [data, setData, formations, setFormations, experiences, setExperiences] = useOutletContext()
    const [createMode, setCreateMode] = useState((formations.length === 0))
    const [formation, setFormation] = useState(formationDefaultValue)
    const [formIsNotValid, setFormIsNotValid] = useState(true)
    const maxCaracteres = 1500;

    useEffect(() => {
        setFormIsNotValid((formations.length === 0))
    }, [formations])


    const prev = () => {
        if (data.workExperience.length === 1 && data.workExperience.includes("Stage")) {
            navigate("/parcours-inscription/competences")
        } else {
            navigate("/parcours-inscription/remuneration")
        }
    }

    const [formationErr, setFormationErr] = useState({
        bacErr: "",
        dateEndErr: "",
        dateStartErr: "",
        schoolErr: "",
        descriptionErr: "",
        diplomeErr: "",
        datErr: ""
    })

    const navigate = useNavigate();



    const formationFormValidation = () => {

        let newState = { ...formationErr }

        let isValid = false

        if (formation.description.length > maxCaracteres) {
            newState.descriptionErr = `Votre présentation de doit pas excéder ${maxCaracteres} caractères`
            isValid = false
            setFormationErr(newState)
            return
        }

        if (formation.diplome === "") {
            newState.diplomeErr = "Veuillez renseigner l'intitulé du diplôme"
            isValid = false
            setFormationErr(newState)
            return

        } else {
            newState.diplomeErr = ""

            isValid = true
        }

        if (formation.school === "") {
            newState.schoolErr = "Veuillez renseigner le nom de l'ecole"
            isValid = false
            setFormationErr(newState)
            return

        } else {
            newState.schoolErr = ""

            isValid = true
        }

        if (formation.bac === "") {
            newState.bacErr = "Veuillez renseigner le niveau du diplôme"
            isValid = false
            setFormationErr(newState)
            return

        } else {
            newState.bacErr = ""

            isValid = true
        }

        if (formation.date_start === "") {
            newState.dateStartErr = "Veuillez renseigner la date de début"
            isValid = false
            setFormationErr(newState)
            return

        } else {
            newState.dateStartErr = ""
            isValid = true
        }

        if (formation.date_end === "") {
            newState.dateEndErr = "Veuillez renseigner la date de fin"
            isValid = false
            setFormationErr(newState)
            return

        } else {
            newState.dateEndErr = ""
            isValid = true
        }

        if (formation.date_start !== "" && formation.date_end !== "") {
            if (!compareDate(formation.dateStart, formation.dateEnd)) {
                newState.dateErr = "La date de début doit être antérieur à la date de fin"
                isValid = false
                setFormationErr(newState)
                return


            } else {
                newState.dateErr = ""
                isValid = true
            }
        }


        setFormationErr(newState)


        return isValid
    }

    const addFormation = () => {
        if (formationFormValidation()) {
            setFormations(state => state.filter(x => x.id !== formation.id))
            setFormations(state => [...state, formation])
            setFormation(formationDefaultValue)
            setCreateMode(false)
        }
    }


    const compareDate = (date1, date2) => {
        console.table(date1, date2)
        let startDate = new Date(date1)
        let endDate = new Date(date2)
        return Date.parse(startDate) < Date.parse(endDate)
    }

    const selectFormation = (id) => {
        let selectedFormation = formations.filter(x => x.id === id)[0]
        setFormation(selectedFormation)
        setCreateMode(true);

    }

    const deleteFormation = (id) => {
        let newData = formations.filter(x => x.id !== id)
        setFormations(newData)
    }

    return (
        <div className='flex-1 max-w-[750px] bg-white p-4'>
            <div className='flex flex-col sm:flex-row justify-between sm:items-center mb-[20px]'>
                <h2>Formation</h2>
                {
                    !createMode ?
                        < PrimaryButton onClick={() => setCreateMode(true)} text={"Nouvelle formation"} />
                        :
                        null
                }
            </div>
            {createMode ?
                <div>
                    <CreateFormation err={formationErr} setFormation={setFormation} formation={formation} maxCaracteres={maxCaracteres} />
                    <div className='flex justify-end gap-2 bg-white pb-4 pr-4'>
                        <SecondaryButton onClick={() => setCreateMode(false)} text={"Annuler"} />
                        <PrimaryButton onClick={() => addFormation()} text={"Sauvegarder"} />
                    </div>
                </div>
                :
                <>
                    <IndexFormation deleteFormation={deleteFormation} selectFormation={selectFormation} formations={formations} />
                    <div className='flex justify-between'>
                        <SecondaryButton onClick={() => prev()} text={"précédent"} />
                        <PrimaryButton disabled={formIsNotValid} onClick={() => navigate("/parcours-inscription/experiences")} text={"suivant"} />
                    </div>
                </>

            }
        </div>
    )
}

export default Formations