import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import cookie from "js-cookie";
import RadioNumber from "./components/RadioNumber";
import jwtDecode from "jwt-decode";
import { Radar} from 'react-chartjs-2'

// import SkillPartOne from "./SkillPartOne";
// import SkillPartTwo from "./SkillPartTwo";
// import SkillPartThree from "./SkillPartThree";
// import SkillPartFow from "./SkillPartFow";
// import SkillPartFive from "./SkillPartFive";

function AppSkill() {
    const [data, setData] = useState([]);
    const [questionIndex, setQuestionIndex] = useState(1);

    const token = cookie.get("token");
    const tokenDecode = jwtDecode(token)

    const sections = [
        {
            categoryName: "Communiquer",
            categoryId: 1,
            questions: [
                {
                    title:"1 - Aisance en public",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre degré d'aisance en public ?",
                    label:"Aisance en public",
                    number_question:1,

                },
                {
                    title:"2 - Ouverture aux autres",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre degré d'ouverture aux autres ?",
                    label:"Ouverture aux autres",
                    number_question:2,
                },
                {
                    title:"3 - Diplomatie",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre degré de diplomatie ?",
                    label:"Diplomatie",
                    number_question:3,
                },
                {
                    title:"4 - Persuasion",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre capacité de persuasion ?",
                    label:"Persuasion",
                    number_question:4,

                }
            ]
        },
        {
            categoryName: "Manager",
            categoryId: 2,
            questions: [
                {
                    title:"5 - Diriger",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre capacité à diriger une équipe ?",
                    label:"Diriger",
                    number_question:5,

                },
                {
                    title:"6 - Prise de responsabilité",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre capacité à prendre des responsabilités ?",
                    label:"Prise de responsabilité",
                    number_question:6,

                },
                {
                    title:"7 - Organisation",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre capacité à être organisé ?",
                    label:"Organisation",
                    number_question:7,
                },
                {
                    title:"8 - Vision",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre capacité à être visionnaire ?",
                    label:"Vision",
                    number_question:8,
                }
            ]
        },
        {
            categoryName: "Oser",
            categoryId: 3,
            questions: [
                {
                    title:"9 - Confiance en soi",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre degré de confiance en vous ?",
                    label:"Confiance en soi",
                    number_question:9,

                },
                {
                    title:"10 - Indépendance d'esprit",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre degré d'indépendance d'esprit ?",
                    label:"Indépendance d'esprit",
                    number_question:10,

                },
                {
                    title:"11 - Créativité",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre degré de créativité ?",
                    label:"Créativité",
                    number_question:11,
                },
                {
                    title:"12 - Autonomie",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre degré d'autonomie ?",
                    label:"Autonomie",
                    number_question:12,            
                }
            ]
        },
        {
            categoryName: "S'adapter",
            categoryId: 4,
            questions: [
                {
                    title:"13 - Gestion du stress",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre capacité à gérer votre stress ?",
                    label:"Gestion du stress",
                    number_question:13,

                },
                {
                    title:"14 - Réactivité",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre degré de réactivité ?",
                    label:"Réactivité",
                    number_question:14,

                },
                {
                    title:"15 - Patience",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre degré de patience ?",
                    label:"Patience",
                    number_question:15,
                },
                {
                    title:"16 - Respect de la hiérarchie",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre capacité à respecter votre hiérarchie ?", 
                    label:"Respect de la hiérarchie",
                    number_question:16,                   
                }
            ]
        },
        {
            categoryName: "Se dépasser",
            categoryId: 5,
            questions: [
                {
                    title:"17 - Détermination",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre capacité à être déterminé ?",
                    label:"Détermination",
                    number_question:17,

                },
                {
                    title:"18 - Ambition",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre degré d'ambition ?",
                    label:"Ambition",
                    number_question:18,

                },
                {
                    title:"19 - Gout de l'effort",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre capacité à vous surpasser ?",
                    label:"Gout de l'effort",
                    number_question:19,
                },
                {
                    title:"20 - Esprit de compétition",
                    description:"Sur une échelle de 1 à 10 (10 étant la valeur la plus forte), à combien évaluez-vous votre état d'esprit de compétiteur ?",
                    label:"Esprit de compétition",
                    number_question:20,                  
                }
            ]
        }
    ]

    const getUserSoftSkill = async () => {
        try {
            const softskill = await axios.get(`${process.env.REACT_APP_API}user/softskill/get/${tokenDecode.id}`,
            )
            setData(softskill.data)
            console.log(softskill.data)
        } catch (error) {
            console.log(error)
        }
    }


    const updateSoftSkill = (newSoftSkill) => {
        setData([...data,newSoftSkill])
    }

    const softSkillHandler = async (newSoftSkill) => {

        let softSkill = data.filter(x => x.label === newSoftSkill.label)[0]
        if(softSkill) {
            try {
                const resultPatch = await axios.patch(`${process.env.REACT_APP_API}user/softskill/patch/${softSkill.id}`,newSoftSkill,{headers:{"Content-Type": "application/merge-patch+json",Authorization: `Bearer ${cookie.get("token")}`}});         
                const data = resultPatch.data
                const dataSkills = {
                    id: data.id,
                    numberQuestion: data.number_question,
                    value: data.value ,
                    label : data.label,
                    categorie: data.categorie
                }
                setData((prevState) =>
                    prevState.map((item) =>
                        item.label === dataSkills.label ? dataSkills : item
                    )
                );
            } catch (err) {
                console.log(err)
            } 
            
        } else {
            try {
                const result = await axios.post(`${process.env.REACT_APP_API}user/softskill/post`,newSoftSkill,{headers:{Authorization: `Bearer ${cookie.get("token")}`}});
                updateSoftSkill(result.data)
                
            } catch (err) {
                console.log(err)
            } 
        }
    }


    useEffect(() => {
        getUserSoftSkill()
    },[])
    


    return (
        <>
            <main className="flex flex-col xl:flex-row p-8 mb-8">
                <div className="flex flex-col max-h-[1000px] overflow-y-scroll">
                    <h5 className="mb-6">Veuillez remplir ce questionnaire afin de rendre visible votre cartographie de softskills</h5>
                {
                    sections.map((section, key) => (
                        <div key={key}>
                            <h2>{section.categoryName}</h2>
                            {
                                section.questions.map((question, key) => (
                                    <div key={key}>
                                        <section className="my-6">
                                            <div className="mb-4">
                                                <h5>{ question.title }</h5>
                                                <p>{ question.description }</p>
                                            </div>
                                            <div className="flex flex-row flex-wrap lg:grid w-[40rem] lg:grid-cols-5 gap-2 rounded-xl bg-gray-200 p-2">
                                                {
                                                    Array.from({ length: 10 }, (_, index) => {
                                                        let id = `${section.categoryId}-${question.number_question}-${index + 1}`
                                                        return (
                                                            <RadioNumber 
                                                                checked={data.filter(x => x.label === question.label && x.value === index + 1).length > 0} 
                                                                key={index} 
                                                                question={`question${question.number_question}`} 
                                                                id={id} 
                                                                value={index + 1} 
                                                                label={question.label} 
                                                                categorie={section.categoryId}
                                                                updateSoftSkill={updateSoftSkill}
                                                                softSkillHandler={softSkillHandler}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>
                                        </section>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
                </div>
               
                <div className="flex flex-col">
                <div className='dataCard customerCard  h-max'>
              <Radar
              height={600}
              width={600}
              data={{
                labels: data.map((data) => data.label),
                datasets: [
                  {
                    label:"Communiquer",
                    data: data.map((data) => data.categorie ==  1 ?  data.value : "" ), 
                    backgroundColor:'rgba(3, 15, 48, 0.5)',
                    borderColor:"#030f30",
                    borderRadius: 10 ,
                },
                {
                    label:"Manager",
                    data: data.map((data) => data.categorie ==  2 ?  data.value : "" ), 
                    backgroundColor:'rgba(91, 158, 207, 0.5)',
                    borderColor:"#5b9ecf",
                    borderRadius: 10 ,
                },
                {
                    label:"Oser",
                    data: data.map((data) => data.categorie ==  3 ?  data.value : "" ), 
               
                    borderColor:"#A65E2E",
                    borderRadius: 10 ,
                },
                {
                    label:"S’adapter",
                    data: data.map((data) => data.categorie ==  4 ?  data.value : "" ), 
                    borderColor:"#497E76",
                    borderRadius: 10 ,
                },
                {
                    label:"Se dépasser",
                    data: data.map((data) => data.categorie ==  5 ?  data.value : "" ), 
                    backgroundColor:'rgba(184, 183, 153, 0.5)',

                    borderColor:"#B8B799",
                    borderRadius: 10 ,
                },
           
              ],
              }}
              />
           </div>
                </div>
            </main>
        </>
    )
}

export default AppSkill