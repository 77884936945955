import React from 'react'
import { useNavigate } from 'react-router-dom'
import NotFoundImg from '../../../assets/img/Homme_rouages_reparation.png'
import PrimaryButton from 'Components/Buttons/PrimaryButton'


function NotFound() {
    const navigate = useNavigate()
  return (
    <div className='containerxl py-[50px] flex justify-center flex-col items-center gap-4'>
        <p className='font-bold'>Il semble que la page que vous cherchez n'existe pas ou a été déplacée.</p>
        <img src={NotFoundImg} alt="" srcset="" />
        <PrimaryButton onClick={() => 
            navigate("/")} text={"Retournez à notre page d'accueil"}/>
    </div>
  )
}

export default NotFound