import React, { useEffect } from 'react'
import { createContext } from "react";
import { useState, useMemo } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState({});
    const [id, setId] = useState();
    const [patchKpi, setPatchKpi] = useState(false);

    const value = useMemo(() => ({
        isLoggedIn,
        setIsLoggedIn,
        user,
        setUser,
        id,
        setId,
        patchKpi,
        setPatchKpi
    }),
        [
            isLoggedIn,
            setIsLoggedIn,
            user,
            setUser,
            id,
            setId,
            patchKpi,
            setPatchKpi
        ]);

    return (
        <AuthContext.Provider value={{value}}>
            {children}
        </AuthContext.Provider>
    )
}