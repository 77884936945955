import React from 'react'

function socialmedia(props) {
    return (
        <svg className={props.classProps ? props.classProps : ""} width={props.width ? props.width : "40"} height={props.height ? props.height : "40"}  viewBox="0 0 40 40" 
        fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3087_894)">
                <path d="M20 23.243C22.8106 23.243 25.0972 25.5297 25.0972 28.3402V29.8072C25.7053 29.4898 26.2806 29.1181 26.816 28.6976V28.3402C26.816 24.5819 23.7583 21.5243 20 21.5243C16.2416 21.5243 13.184 24.5819 13.184 28.3402V28.6976C13.7194 29.1181 14.2947 29.4898 14.9027 29.8072V28.3402C14.9027 25.5297 17.1894 23.243 20 23.243Z" 
                fill={props.color ? props.color : "#263238"} />
                <path d="M26.3051 31.1056C26.3614 31.0806 26.4142 31.0494 26.4633 31.0134C26.4108 31.0444 26.3581 31.0753 26.3051 31.1056Z" 
                fill={props.color ? props.color : "#263238"} />
                <path d="M13.5366 31.0134C13.5858 31.0494 13.6386 31.0805 13.6949 31.1056C13.6419 31.0753 13.5892 31.0444 13.5366 31.0134Z" 
                fill={props.color ? props.color : "#263238"} />
                <path d="M19.9999 20.8604C17.5553 20.8604 15.5666 18.8715 15.5666 16.427C15.5666 13.9825 17.5554 11.9937 19.9999 11.9937C22.4445 11.9937 24.4333 13.9825 24.4333 16.427C24.4333 18.8715 22.4446 20.8604 19.9999 20.8604ZM19.9999 13.7124C18.5031 13.7124 17.2853 14.9302 17.2853 16.427C17.2853 17.9238 18.5031 19.1416 19.9999 19.1416C21.4968 19.1416 22.7146 17.9238 22.7146 16.427C22.7146 14.9302 21.4968 13.7124 19.9999 13.7124Z" 
                fill={props.color ? props.color : "#263238"} />
                <path d="M11.6009 10.3866L8.11523 6.90082C8.58945 6.1952 8.86672 5.34652 8.86672 4.43426C8.86672 1.9898 6.87789 0.000976562 4.43336 0.000976562C1.98883 0.000976562 0 1.9898 0 4.43434C0 6.87887 1.98883 8.8677 4.43336 8.8677C5.34562 8.8677 6.19422 8.59051 6.89992 8.11621L10.3856 11.602C10.7632 11.1703 11.1693 10.7643 11.6009 10.3866ZM4.43336 7.14895C2.93648 7.14895 1.71875 5.93113 1.71875 4.43434C1.71875 2.93754 2.93648 1.71973 4.43336 1.71973C5.93016 1.71973 7.14797 2.93754 7.14797 4.43434C7.14797 5.93113 5.93016 7.14895 4.43336 7.14895Z" 
                fill={props.color ? props.color : "#263238"} />
                <path d="M35.5666 31.1343C34.6544 31.1343 33.8058 31.4114 33.1001 31.8857L29.6144 28.4C29.2368 28.8317 28.8307 29.2377 28.399 29.6154L31.8847 33.1011C31.4105 33.8068 31.1332 34.6554 31.1332 35.5677C31.1332 38.0122 33.122 40.0011 35.5666 40.0011C38.0112 40.001 40 38.0122 40 35.5676C40 33.1231 38.0112 31.1343 35.5666 31.1343ZM35.5666 38.2822C34.0698 38.2822 32.852 37.0644 32.852 35.5676C32.852 34.0708 34.0698 32.853 35.5666 32.853C37.0635 32.853 38.2813 34.0708 38.2813 35.5676C38.2813 37.0644 37.0635 38.2822 35.5666 38.2822Z" 
                fill={props.color ? props.color : "#263238"} />
                <path d="M10.3856 28.4L6.89992 31.8857C6.1943 31.4114 5.34562 31.1343 4.43336 31.1343C1.98883 31.1343 0 33.1231 0 35.5676C0 38.0122 1.98883 40.001 4.43336 40.001C6.87789 40.001 8.86672 38.0122 8.86672 35.5676C8.86672 34.6554 8.58953 33.8068 8.11523 33.1011L11.6009 29.6153C11.1693 29.2377 10.7632 28.8317 10.3856 28.4ZM4.43336 38.2822C2.93648 38.2822 1.71875 37.0644 1.71875 35.5676C1.71875 34.0708 2.93648 32.853 4.43336 32.853C5.93016 32.853 7.14797 34.0708 7.14797 35.5676C7.14797 37.0644 5.93016 38.2822 4.43336 38.2822Z" 
                fill={props.color ? props.color : "#263238"} />
                <path d="M29.6144 11.602L33.1001 8.11621C33.8057 8.59051 34.6544 8.8677 35.5666 8.8677C38.0112 8.8677 40 6.87887 40 4.43434C40 1.9898 38.0112 0.000976562 35.5666 0.000976562C33.1221 0.000976562 31.1333 1.9898 31.1333 4.43434C31.1333 5.3466 31.4105 6.1952 31.8848 6.9009L28.399 10.3867C28.8307 10.7643 29.2368 11.1703 29.6144 11.602ZM35.5666 1.71973C37.0635 1.71973 38.2812 2.93754 38.2812 4.43434C38.2812 5.93113 37.0635 7.14895 35.5666 7.14895C34.0698 7.14895 32.852 5.93113 32.852 4.43434C32.852 2.93754 34.0698 1.71973 35.5666 1.71973Z" 
                fill={props.color ? props.color : "#263238"} />
                <path d="M20 32.7736C12.9572 32.7736 7.22736 27.0438 7.22736 20.001C7.22736 12.9582 12.9572 7.22839 20 7.22839C27.0428 7.22839 32.7727 12.9582 32.7727 20.001C32.7727 27.0438 27.0428 32.7736 20 32.7736ZM20 8.94714C13.9049 8.94714 8.94611 13.9059 8.94611 20.001C8.94611 26.0961 13.9049 31.0548 20 31.0548C26.0952 31.0548 31.0539 26.0961 31.0539 20.001C31.0539 13.9058 26.0952 8.94714 20 8.94714Z" 
                fill="#5B9ECF" />
            </g>
            <defs>
                <clipPath id="clip0_3087_894">
                    <rect width="40" height="40" fill="white" transform="translate(0 0.000976562)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default socialmedia