import { createContext } from "react";
import { useState, useMemo } from 'react';
import AppointmentModal from 'Modals/AppointmentModal';

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {


    const [isOpen, setIsOpen] = useState(false);
    const [content, setContent] = useState();
    const [title, setTitle] = useState("Modal");

    const close = () => {
        setContent(null)
        setTitle(null)
        setIsOpen(false)
    }

    const openModal = (title, content) => {
        setTitle(title)
        setContent(content)
        setIsOpen(true)
    }

    return (
        <ModalContext.Provider value={{ isOpen, setIsOpen, content, setContent, title, setTitle, openModal, close }}>
            {children}
        </ModalContext.Provider>
    )
}