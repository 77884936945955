import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";

import PortfolioHeader from "./Component/PortfolioHeader";
import SkillSidebar from "./Component/SkillSidebar";
import Tabs from "./Component/Tabs";
import { verifComptable } from "utils/verifComptable";

function DossierCompetence(props) {

	const token = cookie.get("token");
	const { userId } = props;
	const { id } = useParams();
	const fetchId = id || userId;
	const [skillPortfolio, setSkillPortfolio] = useState()
	const [isComptable, setComtable] = useState(false);


	const dayToDate = (totalDay) => {
		var string = "";
		if (totalDay > 365) {
			const year = Math.floor(totalDay / 365.25);
			string += year + " années ";
			totalDay -= year * 365.25;
		}
		if (totalDay > 30) {
			const month = Math.floor(totalDay / 30);
			string += month + " mois";
			totalDay -= month * 30;
		}
		return string;
	}

	const getSkillPortfolio = async () => {
		const res = await axios.get(
			process.env.REACT_APP_API + `skillsportfolio/${fetchId}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: token ? `Bearer ${token}` : ""
				}
			}
		)
		setSkillPortfolio(res.data);
		setComtable(verifComptable(res.data.jobTitle, res.data.inscritOrdre, res.data.inscritCncc, res.data.expertComptable))
	}

	useEffect(() => {
		getSkillPortfolio();
	}, [])


	return (
		<>
			<div className="max-w-[1200px] m-auto px-4 py-4 gap-4">
				<PortfolioHeader skillPortfolio={skillPortfolio} isComptable={isComptable} />
				<div className="grid grid-cols-1 gap-y-4 md:grid-cols-6 md:gap-4">
					<SkillSidebar skillPortfolio={skillPortfolio} />
					<Tabs skillPortfolio={skillPortfolio} isComptable={isComptable} />
				</div>
			</div>
		</>
	);
}

export default DossierCompetence;