import { Link } from "react-router-dom";
import Consulting from "./Components/Consulting";
import Ecole from "./Components/Ecole";
import Expertise from "./Components/Expertise";
import Recrutement from "./Components/Recrutement";
import GrillAchat from "./Components/GrilleAchat";
import FreelanceCard1 from "./Components/FreelanceCard1";
import FreelanceCard2 from "./Components/FreelanceCard2";
import FreelanceCard3 from "./Components/FreelanceCard3";
import FreelanceCard4 from "./Components/FreelanceCard4";
import { Helmet } from "react-helmet";  

export default function TarifHonoraires() {
  return (
    <div className="containerxl">
      <Helmet>  
<title>MyFinPlace - Tarification et honoraires </title>  
</Helmet>  
      <h1 className="text-center font-medium text-[64px] pt-16 mb-4">Tarification et honoraires</h1>
      <p className="text-center font-medium text-[16px]">Les prétentions des profils (prétentions salariales ou Taux Journalier Moyen) s'entendent hors honoraires de MyFinPlace et sont indiquées à titre indicatif.</p>
      <p className="text-center font-medium text-[16px] mb-8">Dans le cas ou un client souhaite travailler avec un profil, MyFinPlace adressera une proposition financière écrite aux clients.</p>
      <p className="text-center font-medium text-[16px]">MyFinPlace propose des prestations de <span className="text-[#5B9ECF] text-[20px]">consulting</span> en finance, de <span className="text-[#5B9ECF] text-[20px]">recrutement, d’audit</span> et <span className="text-[#5B9ECF] text-[20px]">d’expertise comptable.</span></p>
      <p className="text-center font-medium text-[16px]">Notre engagement : Compétences, réactivité, flexibilité et <span className="text-[#5B9ECF] text-[20px]">transparence</span> dans le cadre de nos prestations</p>
      <h1 className="text-center font-medium text-[48px] mt-16 mb-8">Entreprises et cabinets</h1>
      <p className="text-center font-medium text-[16px]">Des offres claires et adaptées aux exigences des métiers de la finance</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 justify-center mt-16 gap-[40px]">
        <Ecole />
        <Recrutement />
        <Consulting />
        <Expertise />
      </div>
      <p className="text-center font-medium text-[16px] mt-8">Entreprise ou Cabinet, vous souhaitez référencer MyFinPlace et bénéficier d’une tarification plus avantageuse ?</p>
      <p className="text-center font-medium text-[16px]">Ou vous avez un projet complexe à nous confier ? Prenez rendez-vous.</p>
      <div className="text-center mt-8" id="grilleAchat">
        <Link to="/RendezVous" className="bg-[#5B9ECF] hover:bg-[#2D77AD] text-white py-2 px-4 rounded-[5px] inline-block">Je souhaite prendre rendez-vous</Link>
      </div>
      <h1 className="text-center font-medium text-[48px] mt-16 mb-8">Grille achat des TJM 2024</h1>
      <p className="text-center font-medium text-[16px]">MyFinPlace propose un référentiel de place concernant les prestations de conseil. Cette grille achat a été conçu selon des pratiques de place en accord auprès de directions achats et de cabinets partenaires.</p>
      <GrillAchat />
      <p className="text-right">*Taux journalier moyen</p>
      <p className="text-center font-medium text-[16px] mt-12">Cette grille n’est en aucun cas un engagement pris par MyFinPlace dans le cadre de ses prestations. Toutefois MyFinPlace s’engage dans la mesure du possible à appliquer ces honoaires.</p>
      <h1 className="text-center font-medium text-[48px] mt-16 mb-8">Nos services dédiés aux freelances</h1>
      <FreelanceCard1 />
      <FreelanceCard2 />
      <FreelanceCard3 />
      <FreelanceCard4 />
    </div>
  )
}