import React from 'react'

function plus(props) {
    return (
        <svg  className={props.classProps ? props.classProps : ""} width={props.width ? props.width : "29"} height={props.height ? props.height : "30"} viewBox="0 0 29 30" 
        fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.8438 16.2754H8.15625C7.656 16.2754 7.25 15.8694 7.25 15.3691C7.25 14.8689 7.656 14.4629 8.15625 14.4629H20.8438C21.344 14.4629 21.75 14.8689 21.75 15.3691C21.75 15.8694 21.344 16.2754 20.8438 16.2754Z" 
            fill={props.color ? props.color : "#5B9ECF"} />
            <path d="M14.5 22.6191C13.9997 22.6191 13.5938 22.2131 13.5938 21.7129V9.02539C13.5938 8.52514 13.9997 8.11914 14.5 8.11914C15.0003 8.11914 15.4062 8.52514 15.4062 9.02539V21.7129C15.4062 22.2131 15.0003 22.6191 14.5 22.6191Z" 
            fill={props.color ? props.color : "#5B9ECF"}  />
        </svg>
    )
}

export default plus