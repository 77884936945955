import React, { useEffect } from 'react'
import { createContext } from "react";
import { useState, useMemo } from 'react';

export const AlertMessageContext = createContext();

export const AlertMessageProvider = ({ children }) => {

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [infoMessage, setInfoMessage] = useState('');


    const successAlert = (msg) => {
        setSuccessMessage(msg);
        setTimeout(() => {
            setSuccessMessage("");
        }, 5000); //supprimer l'alert dans 5 seconde
    }


    const errorAlert = (msg) => {
        setErrorMessage(msg);
        setTimeout(() => {
            setErrorMessage("");
        }, 5000); //supprimer l'alert dans 5 seconde
    }

    const alert = useMemo(() => ({
        successMessage, setSuccessMessage, errorMessage, setErrorMessage, infoMessage, setInfoMessage,successAlert,errorAlert
    }),
        [
            successMessage, setSuccessMessage, errorMessage, setErrorMessage, infoMessage, setInfoMessage,successAlert,errorAlert
        ]);

    return (
        <AlertMessageContext.Provider value={{ alert }}>
            {children}
        </AlertMessageContext.Provider>
    )
}