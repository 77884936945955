import { useState, useEffect } from 'react'
import { FaChevronDown } from "react-icons/fa6";


function MobilitySelect({ filters, mobilities, setMobilities }) {
    const [isOpen, setIsOpen] = useState(false)
    const [text, setText] = useState("")

    useEffect(() => {
        mobilities && setText(mobilities.join(", "))
    }, [filters])

    const mobilityHandler = (e) => {
        if (mobilities && mobilities.includes(e.target.value)) {
            setMobilities(prev => prev.filter(el => el !== e.target.value))
        } else {
            if (mobilities && mobilities.length === 5) {
                return
            }
            setMobilities(prev => [...prev, e.target.value])
        }
    }

    useEffect(() => {
        mobilities && setText(mobilities.join(", "))
    }, [mobilities])

    return (
        <div>
            <div className='py-1 px-1 ring-1 ring-gray-300 border-lightblue rounded-md w-full hover:ring-[#2563EB] hover:ring-2 cursor-pointer flex items-center' onClick={() => setIsOpen(prev => !prev)}>
                <input value={text} className='outline-none w-full cursor-pointer' type="text" />
                <span className='text-[12px] ml-1'>
                    <FaChevronDown />
                </span>
            </div>
            {
                isOpen ?
                    <div className='border absolute bg-white md:w-[370px] p-2 rounded'>
                        {/* <p>{filters.localisation ? JSON.stringify(filters.localisation) : null}</p> */}
                        {
                            filters ?
                                filters?.localisation.map(el => (
                                    <div className='flex gap-2'>
                                        <input checked={mobilities && mobilities.includes(el.nom)} onChange={(e) => mobilityHandler(e)} value={`${el.nom}`} className='cursor-pointer' type={"checkbox"} key={el.id} id={el.nom} />
                                        <label className='cursor-pointer text-sm' htmlFor={el.nom}>{el.nom}</label>
                                    </div>
                                ))
                                :
                                null
                        }
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default MobilitySelect