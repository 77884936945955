
import { useEffect, useState } from 'react'

function Pagination({ totalPages, currentPage, onPageChange, MaxSlot = 5 }) {



    const [pageNumbers, setPageNumbers] = useState([])
    const widthByCase = 50
    var widthEcran = window.innerWidth;
    var restWidth = widthEcran - (widthByCase * (4 + 1));
    var rest = Math.floor(restWidth / widthByCase);
    if (rest < MaxSlot) {
        if (rest === 1) {
            MaxSlot = 2
        } else {
            MaxSlot = rest            
        }
    }

    useEffect(() => {
        if (totalPages > MaxSlot) {
            const array = [];
            //Sélection du user au milieu de la pagination
            if ((currentPage > Math.ceil(MaxSlot / 2)) && (currentPage < (totalPages - Math.floor(MaxSlot / 2)))) {
                for (let i = 0; i < MaxSlot; i++) {
                    array[i] = currentPage - (Math.ceil(MaxSlot / 2) - i - 1);
                }
                setPageNumbers(array);
            }
            //Sélection du user à la fin de la pagniation
            if (currentPage >= (totalPages - Math.floor(MaxSlot / 2))) {
                for (let i = 0; i < MaxSlot; i++) {
                    array[i] = totalPages - (MaxSlot - i - 1);
                }
                setPageNumbers(array);
            }
            //Sélection du user au début de la pagination
            if (currentPage <= Math.ceil(MaxSlot / 2)) {
                initPagination();
            }
        } else {
            //Si le nombre de pages est inférieur au nombre MaxSlot
            initPagination();
        }
    }, [totalPages, currentPage])

    const initPagination = () => {
        setPageNumbers([]);

        let tab = []

        for (let i = 1; i <= totalPages; i++) {
            if (i <= MaxSlot) {
                tab.push(i);

            }
        }
        setPageNumbers(tab);
    }

    const firstHandler = () => {
        if (currentPage === 1) {
            return
        }
        onPageChange(1)
    }

    const prevHandler = () => {
        if (currentPage === 1) {
            return
        }

        onPageChange(currentPage - 1);
    }

    const nextHandler = () => {
        if (currentPage === totalPages) {
            return
        }

        onPageChange(currentPage + 1);
    }

    const lastHandler = () => {
        if (currentPage === totalPages) {
            return
        }
        onPageChange(totalPages)
    }

    return (
        <div>
            <span className='px-4 py-2 border font-semibold hover:text-white cursor-pointer rounded-l-[5px] hover:bg-[#5B9ECF] hover:border-[#5B9ECF]' onClick={() => firstHandler()} >{"<<"}</span>
            <span className='px-4 py-2 border font-semibold hover:text-white cursor-pointer rounded-l-[5px] hover:bg-[#5B9ECF] hover:border-[#5B9ECF]' onClick={() => prevHandler()} >{"<"}</span>
            {
                pageNumbers.map((page, key) => (
                    <span key={key} className={`${currentPage === page ? "bg-[#5B9ECF] border-[#5B9ECF] text-white" : ""} px-4 py-2 cursor-pointer hover:bg-[#5B9ECF] hover:border-[#5B9ECF] font-semibold border border-r-0`} onClick={() => onPageChange(page)}>{page}</span>
                ))
            }
            <span className='px-4 py-2 border font-semibold hover:text-white cursor-pointer rounded-r-[5px] hover:bg-[#5B9ECF] hover:border-[#5B9ECF]' onClick={() => nextHandler()}>{">"}</span>
            <span className='px-4 py-2 border font-semibold hover:text-white cursor-pointer rounded-r-[5px] hover:bg-[#5B9ECF] hover:border-[#5B9ECF]' onClick={() => lastHandler()}>{">>"}</span>

        </div>
    )
}

export default Pagination