import { useEffect, useState, useContext } from "react";
import { AlertMessageContext } from "Context/AlertMessageProvider";


export default function AlertPopup() {

    const { alert } = useContext(AlertMessageContext);

    return (
        <>
            {alert.successMessage && (
                <div
                    className="fixed z-50 bottom-16 left-2 bg-[#E7EED8] border-t-4 border-[#5EB0AB] rounded px-4 py-3 hover:cursor-pointer"
                    role="alert"
                    onClick={() => alert.setSuccessMessage("")}
                >
                    <div className="flex items-center">
                        <div className="py-1">
                            <svg
                                className="h-6 w-6 mr-4"
                                fill="#5EB0AB"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                            </svg>
                        </div>
                        <div>
                            <div className="text-sm sm:text-base">{alert.successMessage}</div>
                        </div>
                    </div>
                </div>
            )}
            {alert.errorMessage && (
                <div
                    className="fixed z-50 bottom-16 left-2 bg-[#FFE7E7] border-t-4 border-[#EBBDB5] rounded px-4 py-3 hover:cursor-pointer"
                    role="alert"
                    onClick={() => alert.setErrorMessage("")}
                >
                    <div className="flex items-center">
                        <div className="py-1">
                            <svg
                                className="h-6 w-6 mr-4"
                                fill="#EBBDB5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                            </svg>
                        </div>
                        <div>
                            <div className="text-sm sm:text-base">{alert.errorMessage}</div>
                        </div>
                    </div>
                </div>
            )}
            {alert.infoMessage && (
                <div
                    className="fixed bottom-16 left-2 bg-greylight border-t-4 border-middleblue rounded-b px-4 py-3 hover:cursor-pointer"
                    role="alert"
                    onClick={() => alert.setErrorMessage("")}
                >
                    <div className="flex items-center">
                        <div className="py-1">
                            <svg
                                className="h-6 w-6 mr-4"
                                fill="#5b9ecf"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                            </svg>
                        </div>
                        <div>
                            <div className="text-sm sm:text-base">{alert.infoMessage}</div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
