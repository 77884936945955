import React, { useState, useEffect } from "react";
import axios from "axios";
import cookie from "js-cookie";
import LayoutEntretien from "./LayoutEntretien"

function EntretiensRealises() {
  const [data, setData] = useState(null);
  useEffect(() => {
    axios.get(process.env.REACT_APP_API + `GetStatusEntretien?status=réaliser`, {
      headers: {
        Authorization: `Bearer ${cookie.get("token")}`
      }
    })
      .then((response) => {
        setData(response.data["hydra:member"].reverse().slice(0));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  return (
    <>
      <h3 className="text-center mb-4">Entretien réalisés</h3>
      <LayoutEntretien data={data} />
    </>
  );
}

export default EntretiensRealises;