import React from 'react'
import { Outlet } from 'react-router-dom'
import ResponsiveNavBar from 'Components/ResponsiveNavBar/ResponsiveNavBar'
import Footer from 'Components/Footer/Footer'

function PublicLayout() {
    return (
        <>
            <ResponsiveNavBar />
            <div className='mt-[97px]'>
                <Outlet />
            </div>
            <Footer />
        </>
    )
}

export default PublicLayout