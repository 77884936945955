import React from "react";
import { FaCalendarWeek } from "react-icons/fa";


function Programmes() {
 

    return (
        <div>
        <span className='text-sm text-[#2563EB]'><FaCalendarWeek /> </span>
        </div>
    );
}

export default Programmes;
