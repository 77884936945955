
import { React } from 'react'
function RdvSvg(props) {


  return (
    <>
      <svg width="13" height="9" viewBox="0 0 13 9" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.07677 4.61553C5.19276 4.61553 6.096 3.66928 6.096 2.50015C6.096 1.33102 5.19276 0.384766 4.07677 0.384766C2.96079 0.384766 2.05754 1.33102 2.05754 2.50015C2.05754 3.66928 2.96079 4.61553 4.07677 4.61553ZM5.46139 5.21993C5.363 5.21993 5.26646 5.24407 5.1765 5.28392C4.83751 5.43411 4.46764 5.52213 4.07677 5.52213C3.68574 5.52213 3.31714 5.43404 2.97713 5.28374C2.88713 5.24396 2.79056 5.21993 2.69216 5.21993C1.54552 5.21993 0.615234 6.19452 0.615234 7.39575V7.93971C0.615234 8.44023 1.00285 8.8463 1.48062 8.8463H6.67293C7.15069 8.8463 7.53831 8.44023 7.53831 7.93971V7.39575C7.53831 6.19452 6.60802 5.21993 5.46139 5.21993ZM9.26908 4.61553C10.2246 4.61553 10.9998 3.80338 10.9998 2.80235C10.9998 1.80132 10.2246 0.989161 9.26908 0.989161C8.31355 0.989161 7.53831 1.80132 7.53831 2.80235C7.53831 3.80338 8.31355 4.61553 9.26908 4.61553ZM10.1345 5.21993C10.0891 5.21993 10.0442 5.22806 10.0013 5.24253C9.76986 5.32049 9.52626 5.37103 9.26908 5.37103C9.0119 5.37103 8.7683 5.32049 8.53688 5.24253C8.49392 5.22806 8.44902 5.21993 8.4037 5.21993C7.85292 5.21993 7.74964 5.89706 7.94038 6.41375C8.05295 6.71868 8.11523 7.04933 8.11523 7.39575V8.12103C8.11523 8.18718 8.15969 8.24191 8.22584 8.24191H11.2883C11.7661 8.24191 12.1537 7.83583 12.1537 7.33531C12.1537 6.16619 11.2505 5.21993 10.1345 5.21993Z" fill="currentColor" />
      </svg>
    </>
  )
}

export default RdvSvg