import { useState } from 'react'
import { useOutletContext, useNavigate } from 'react-router-dom'
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import Wysiwig from 'Components/wysiwig/wysiwig';


function JobDescription() {
  const [step, setStep, newOffer, setNewOffer, filters, formulaire] = useOutletContext();
  const [description, setDescription] = useState()
  const [descriptionErr, setDescriptionErr] = useState(null)
  const navigate = useNavigate();
  const handleDescription = (e) => {
    setDescriptionErr(null)
    setNewOffer(p => ({ ...p, description: e }))
  }

  const prev = () => {
      navigate(`/mon-espace/mes-offres/${formulaire}/profil`)
  }


  const next = () => {
    if (description !== "") {
      setNewOffer(p => ({ ...p, description: description }))
      setStep(p => ({ ...p, description: true }))
      navigate(`/mon-espace/mes-offres/${formulaire}/recapitulatif`)
    } else {
      setDescriptionErr("Veuillez remplir ce champ")
    }
  }

  return (
    <div className='p-4 space-y-4'>
      <div>
        <Wysiwig text={newOffer.description} setText={setDescription} />
        {descriptionErr && <span className='col-span-2 text-red-500 text-xs font-bold'>{descriptionErr}</span>}
      </div>
      <div className='flex justify-between'>
        <SecondaryButton type={"submit"} text={"Précedent"} onClick={prev} />
        <PrimaryButton onClick={next} type={"submit"} text={"Suivant"} />
      </div>
    </div>
  )
}

export default JobDescription