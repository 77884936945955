import React from "react";
import DernierClient from "./HomeComponents/dernierClient";
import DernierUser from "./HomeComponents/dernierUser";
import OffreNeedValidation from "./HomeComponents/offreNeedValidation";
import LastCandidature from "./HomeComponents/lastCandidature";
import LastRecommandation from "./HomeComponents/lastRecommandation";
import LastFavoris from "./HomeComponents/lastFavoris";
import EntretienProfilRetenu from "./HomeComponents/entretienProfilRetenu";
import ProfilRefuser from "./HomeComponents/profilRefuser";
import EntretiensPromgrammes from "./HomeComponents/entretienProgrammer";
import EntretiensRealiser from "./HomeComponents/entretienRealiser";

function HomeIris() {
  return (
    <>
      <h3>Home</h3>
      <EntretiensPromgrammes />
      <EntretiensRealiser />
      <EntretienProfilRetenu />
      <ProfilRefuser />
      <div className="flex gap-4 pb-6">
        <DernierClient />
        <DernierUser />
      </div>
      <div className="flex gap-4 pb-6">
        <OffreNeedValidation />
        <LastCandidature />
      </div>
      <LastRecommandation />
      <LastFavoris />
    </>
  );
}

export default HomeIris;