import { useContext } from 'react'
import SmallSecondaryButton from 'Components/Buttons/SmallSecondaryButton'
import SmallPrimaryButton from 'Components/Buttons/SmallPrimaryButton'
import Cookies from 'js-cookie'
import axios from "axios"
import { AlertMessageContext } from 'Context/AlertMessageProvider';

function DeleteExperienceModal({ id, close, setUserInformation, getPourcentage }) {

  const token = Cookies.get("token");
  const { alert } = useContext(AlertMessageContext);

  const deleteHandler = async () => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API}user/experience/delete/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      setUserInformation(prev => ({ ...prev, experience: prev.experience.filter(e => e.id !== id) }))
      getPourcentage()
      alert.successAlert("Votre expérience a été supprimée.");
      close()
    } catch (error) {
    }
  }
  return (
    <div>
      <p className=''>Confirmez-vous la <span className='text-red-500 font-medium'>suppression</span> de cette expérience ?</p>
      <div className='flex items-center gap-2 justify-end'>
        <SmallSecondaryButton onClick={() => close()} text={"Annuler"} />
        <SmallPrimaryButton onClick={() => deleteHandler()} text={"Oui"} />
      </div>
    </div>
  )
}

export default DeleteExperienceModal