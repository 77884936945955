import { Text, View } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import School from "./Svg/School";
import { styles, SizeText } from "./../Css/styles";

const Formation = ({ data }) => {
  //Calcul du nombre total de jour des formation
  var maxBac = 0;
  data.formation.forEach((formation) => {
    if (formation.bac > maxBac) {
      maxBac = formation.bac
    }
  })
  return (
    <View>
      <Text style={styles.TitleGeneral}>Formation (Bac +{maxBac})</Text>
      <View style={{ display: "flex", flexDirection: "column", gap: 12 }}>
        {data.formation && data.formation.map(
          (formation, key) =>
            <View style={{ display: "flex", flexDirection: "row", gap: 6 }} key={key} wrap={false}>
              <School />
              <View>
                <Text style={styles.TitreExpForma}>Ecole/université : {formation.school}</Text>
                <Text style={{ fontSize: SizeText, color: "#5B9ECF", marginVertical: 2 }}>Diplôme : {formation.diplome}</Text>
                <Text style={{ fontSize: SizeText, marginBottom: 12 }}>
                  {/* Affichage des date de début et de fin */}
                  {new Date(formation.date_start).toLocaleDateString(
                    "fr-FR",
                    { month: "long", year: "numeric" }
                  )}
                  {" - "}
                  {new Date(formation.date_end).toLocaleDateString(
                    "fr-FR",
                    { month: "long", year: "numeric" }
                  )}
                  {/* Affichage de la duréer de la formation */}
                  {formation.bac === 0 ?
                    " (Certification)" : " (Bac + " + formation.bac + ")"
                  }
                </Text>
                <Text style={{ fontSize: SizeText, marginBottom: 2, textDecoration: "underline" }}>Description de la formation : </Text>
                {formation.description != null ?
                  <Html style={{ fontSize: SizeText }}>
                    {"<div style='max-width:90%'>" + formation.description.replaceAll("\n", "<br/>") + "</div>"}
                  </Html>
                  : <Html style={{ fontSize: SizeText }}>
                    {"<div style='max-width:90%'> Non donner</div>"}
                  </Html>
                }
              </View>
            </View>
        )}
      </View>
    </View>
  );
}

export default Formation;