import { ReactComponent as LogoValide } from "../../../../assets/img/LogoValide.svg";

export default function CabinetDeConseil() {
    return (
        <div className="px-4">
            <p className="font-bold text-justify">
            Vous représentez un cabinet de conseil et souhaitez trouver, rencontrer et travailler avec un profil qui a des compétences en finance ?
            </p>
            <div className="flex mt-8">
                <LogoValide className="shrink-0 h-8  p-1 bg-middleblue rounded-full mr-6" />
                <p className="text-justify">
                Il vous suffit de vous inscrire sur MyFinPlace
                </p>
            </div>
            <div className="flex mt-8">
                <LogoValide className="shrink-0 h-8  p-1 bg-middleblue rounded-full mr-6" />
                <p className="text-justify">
                Puis vous pourrez consulter et rencontrer les profils disponibles sur la page Les profils
                </p>
            </div>
            <div className="flex mt-8">
                <LogoValide className="shrink-0 h-8  p-1 bg-middleblue rounded-full mr-6" />
                <p className="text-justify">
                Vous aurez également la possibilité de diffuser des offres d'emplois ou des opportunités de mission</p>
            </div>
        </div>
    );
}
