import { useState } from "react";
import Vector from "assets/svg/VectorFull";

export default function FirstMarket() {
    const [isActive, setActive] = useState({ model: true });

    return (
        <section className="lg:mx-20 xl:mx-40 pt-4 mb-8 flex flex-col">
            <div className="pt-4">
                <h2 className="text-center">
                Une rencontre 100 % digitale                
                </h2>
            </div>
            <div className="py-6 text-center">
                <h4>
                    Permettant une mise en relation confidentielle des
                    professionnels des métiers de la finance
                </h4>
            </div>
            <div className="p-10 border-b cursor-pointer" onClick={() => setActive({ model: !isActive.model })} >
                <div className="flex gap-4 ">
                    <h5 className="text-center w-full bigscreen:text-[30px]">Le modèle</h5>
                    <div className={(isActive.model ? "rotate-180 transition duration-700" : "transition duration-700")}>
                        <Vector alt="+" />
                    </div>
                </div>
                <div className={isActive.model ? "animation-box-visible" : "animation-box-hidden"} id="DependBox1" >
                    <p className=" text-[#5F5F5F] animation-content text-center bigscreen:text-[20px]" id="DependContent1" >
                        <br />
                        MyFinPlace est une plateforme
                        <strong>
                            {" "}
                            100 % française fondée par des experts du conseil et
                            du recrutement
                        </strong>
                        , qui permet de mettre en relation des professionnels (
                        entreprises, cabinets de conseil, d'audit et/ou
                        d'expertise-comptable, cabinets de recrutement ) et des
                        profils ayant des compétences en finance ( tous métiers
                        ).
                        <br />
                        <br />
                        Cette mise en relation automatisée est facilitée par un
                        important vivier de candidats/consultants disponibles
                        partout en France et en Europe, et ce quelque soit le
                        mode de collaboration : stage, alternance, CDD, CDI et
                        mission.
                    </p>
                </div>
            </div>
            <div className="p-10 border-b cursor-pointer" onClick={() => setActive({ fonctionnement: !isActive.fonctionnement })}>
                <div className="flex gap-4 ">
                    <h5 className="text-center w-full bigscreen:text-[30px]">
                        Fonctionnement
                    </h5>
                    <div className={isActive.fonctionnement ? "rotate-180  transition duration-700" : "transition duration-700"}>
                        <Vector />
                    </div>
                </div>
                <div className={isActive.fonctionnement ? "animation-box-visible" : "animation-box-hidden"} >
                    <p className=" text-[#5F5F5F] animation-content text-center bigscreen:text-[20px]"  >
                        <br />
                        MyFinPlace fonctionne simplement et intuitivement,
                        assure une véritable confidentialité des intervenants,
                        et génère des opportunités réelles et immédiates.
                    </p>
                </div>
            </div>
            <div className="p-10 border-b cursor-pointer" onClick={() => setActive({ inscription: !isActive.inscription })}>
                <div className="flex gap-4 ">
                    <h5 className="text-center w-full bigscreen:text-[30px]">
                        Inscription
                    </h5>
                    <div className={isActive.inscription ? "rotate-180 transition duration-700" : "transition duration-700"}>
                        <Vector alt="+" />
                    </div>
                </div>
                <div
                    className={isActive.inscription ? "animation-box-visible" : "animation-box-hidden"} >
                    <p className=" text-[#5F5F5F] animation-content text-center bigscreen:text-[20px]" >
                        <br />
                        L’inscription sur MyFinPlace est à la fois gratuite,
                        rapide et confidentielle.
                    </p>
                </div>
            </div>
        </section>
    );
}
