import React from 'react'

function Eye(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={props.color ? props.color : "#B8B8B8"} d="M22.8516 11.0441C17.3633 11.6007 11.4648 14.5988 7.80273 18.7003C5.85938 20.8878 4.6875 23.2511 4.6875 24.9991C4.6875 27.2062 6.55273 30.3409 9.32617 32.8214C14.3457 37.2941 20.8594 39.5597 27.0117 38.9542C32.627 38.4074 38.4766 35.4484 42.2168 31.2687C44.1406 29.1202 45.3125 26.7374 45.3125 24.9991C45.3125 22.0109 42.2852 17.9581 37.832 14.9796C33.3398 11.9718 27.8711 10.5363 22.8516 11.0441ZM26.8359 14.1691C30.4395 14.5597 33.9746 15.9659 37.0508 18.2413C38.3691 19.2081 40.3418 21.2003 41.0449 22.2843C42.5 24.4718 42.5 25.5265 41.0449 27.714C40.3223 28.8175 38.3496 30.7999 36.9922 31.796C33.1738 34.6085 28.584 36.0929 24.3164 35.9074C20.2441 35.7218 16.4551 34.3351 12.8906 31.7081C10.8691 30.2238 8.68164 27.7042 8.08594 26.1808C7.44141 24.5206 8.25195 22.7042 10.7324 20.2042C15.0684 15.8585 21.2109 13.5538 26.8359 14.1691Z" />
      <path fill={props.color ? props.color : "#B8B8B8"} d="M23.6817 17.2949C20.2735 17.9688 17.8517 20.4492 17.2755 23.8672C16.8849 26.1523 17.7735 28.7988 19.4825 30.5176C20.9864 32.0215 22.8028 32.7637 25.0001 32.7637C27.1974 32.7637 29.0138 32.0215 30.5177 30.5176C32.0216 29.0137 32.7638 27.1973 32.7638 25C32.7638 23.3008 32.4122 22.0996 31.5431 20.7715C30.4493 19.082 28.8966 17.9688 26.8556 17.4121C26.1915 17.2363 24.336 17.168 23.6817 17.2949ZM26.1231 20.459C27.8028 20.8984 29.1017 22.207 29.5411 23.8965C30.1759 26.3281 28.6231 28.8867 26.1231 29.541C23.672 30.1758 21.1134 28.623 20.4591 26.123C19.5899 22.7734 22.7735 19.5898 26.1231 20.459Z" />
    </svg>
  )
}

export default Eye