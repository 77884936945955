import React from 'react'
import parse from "html-react-parser";
import Pen from "assets/svg/Pen";
import Trash from "assets/svg/Trash";


export default function IndexExperience({ selectExperience, experiences, deleteExperience }) {
  return (
    <div className='mb-[50px] flex flex-col gap-[30px]'>
      {
        experiences.map((experience, key) => (
          <>
            <div key={key} className='bg-greylight rounded p-3'>
              <div className='flex justify-between items-center'>
                <div>
                  <h3>{experience.name}</h3>
                  <p>{experience.date_start} - {experience.date_end}</p>
                  <h5>{experience.poste}</h5>
                </div>
                <div className='flex gap-6'>
                  <p className='cursor-pointer' onClick={() => selectExperience(experience.id)}><Pen /></p>
                  <p className='cursor-pointer' onClick={() => deleteExperience(experience.id)}><Trash /></p>
                </div>
              </div>
              <div className='my-4'>
                <h5 className='break-words'>{parse(experience.description)}</h5>
              </div>
            </div>
          </>
        ))
      }
    </div>
  )
}
