export function longDateFormatter(UTCDate) {


    let config = {
        day: "numeric",
        month: "long",
        year: "numeric",
    }

    return new Date(UTCDate).toLocaleDateString("fr-FR", config)
}



export function getInterventionType(tags) {

    if (tags) {
        let tagArray = []
        tags.forEach(tag => {
            if (tag.tag.id_tag_level.level === "Type d'intervention") {
                tagArray.push(tag.tag)
            }
        });

        if (tagArray.length) {
            return tagArray
        } else {
            return [{ tag: { "name": "Non définie" }, level: undefined }]
        }
    }

    return null
}

export function getSectorialSkillTag(tags) {

    if (tags) {
        let tagArray = []
        tags.forEach(tag => {
            if (tag.tag.id_tag_level.level === "Compétences sectorielles") {
                tagArray.push(tag)
            }
        });

        if (tagArray.length) {
            return tagArray
        } else {
            return [{ tag: { "name": "Non définie" }, level: undefined }]
        }
    }

    return null
}

export function getFonctionalSkillTag(tags) {

    if (tags) {
        let tagArray = []
        tags.forEach(tag => {
            if (tag.tag.id_tag_level.level === "Compétences fonctionnelles") {
                tagArray.push(tag)
            }
        });

        if (tagArray.length) {
            return tagArray
        } else {
            return [{ tag: { "name": "Non définie" }, level: undefined }]
        }
    }

    return null
}

export function getComputerSkillTag(tags) {

    if (tags) {
        let tagArray = []
        tags.forEach(tag => {
            if (tag.tag.id_tag_level.level === "Compétences informatiques") {
                tagArray.push(tag)
            }
        });

        if (tagArray.length) {
            return tagArray
        } else {
            return [{ tag: { "name": "Non définie" }, level: undefined }]
        }
    }

    return null
}

export function getLanguageSkillTag(tags) {

    if (tags) {
        let tagArray = []
        tags.forEach(tag => {
            if (tag.tag.id_tag_level.id === 5) {
                tagArray.push(tag)
            }
        });

        if (tagArray.length) {
            return tagArray
        } else {
            return [{ tag: { "name": "Non définie" }, level: undefined }]
        }
    }

    return null
}


export function avaibilityDateFormatter(avaibilityDate, availability_date) {
    if (Array.isArray(avaibilityDate)) {

        let value = avaibilityDate[0]
        if (["Immédiate"].includes(value)) {
            return (
                <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
                    <div className="rounded-full bg-[#00B553] h-2 w-2 mr-2"></div>
                    <p className="text-xs">{value}</p>
                </span>
            );
        }

        if (["Date connue"].includes(value)) {
            return (
                <span className='inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20'>
                    <div className="rounded-full bg-[#FFD600] h-2 w-2 mr-2"></div>
                    <p className="text-xs">{longDateFormatter(availability_date)}</p>
                </span>
            );
        }

        return (
            <div className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                <div className="rounded-full bg-[#00B553] h-2 w-2"></div>
                <p className="text-sm">{"dsqsdqsd"}</p>
            </div>
        )
    } else {
        return <p>Err</p>
    }

}