import { useState, useEffect } from 'react';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useForm } from 'react-hook-form'

function JobTitleStep() {
  const [step, setStep, newOffer, setNewOffer] = useOutletContext();

  const navigate = useNavigate()

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: "onSubmit"
  });

  const submit = (formData) => {
    setNewOffer(prev => ({ ...prev, intitule: formData.intitule }))
     setNewOffer(prev => ({ ...prev, entrepriseOffre: formData.entrepriseOffre }))
    setStep(prev => ({ ...prev, intitule: true }))
    setStep(prev => ({ ...prev, entrepriseOffre: true }))
    navigate("modalite")
  }

  useEffect(() => {
    setValue("intitule", newOffer.intitule)
   setValue("entrepriseOffre",newOffer.entrepriseOffre)
  }, [])



  return (
    <div className='p-4'>
      <form action="" className='' onSubmit={handleSubmit(submit)}>
        <div className='flex flex-col justify-between gap-4'>
          <div className=''>
            <label htmlFor="intitule" className='block font-medium leading-6 text-gray-900'>Intitulé de l'opportunité*</label>
            <input name='intitule' id='intitule' className={`${errors.intitule} py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2`} type="text" {...register("intitule", { required: "Champ requis" })} />
            {errors.intitule && (
              <span className="text-red-500 text-xs font-bold mt-1">
                {errors.intitule.message}
              </span>
            )}
          </div>
          <div className=''>
            <label htmlFor="entrepriseOffre" className='block font-medium leading-6 text-gray-900'>Nom de la société (visible que par vous)</label>
            <input name='entrepriseOffre' id='entrepriseOffre' className={`${errors.entrepriseOffre} py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2`} type="text" {...register("entrepriseOffre", { required: "Champ requis" })} />
            {errors.entrepriseOffre && (
              <span className="text-red-500 text-xs font-bold mt-1">
                {errors.entrepriseOffre.message}
              </span>
            )}
          </div> 
          <div className='flex justify-end'>
            <PrimaryButton type={"submit"} text={"Suivant"} />
          </div>
        </div>
      </form>
    </div>
  )
}

export default JobTitleStep