import DateInput from 'Components/Forms/DateInput/DateInput'
import Input from 'Components/Forms/Input/Input'
import Wysiwig from 'Components/wysiwig/wysiwig'
import DiplomaSelect from 'Components/Forms/DiplomaSelect/DiplomaSelect'
import { useState, useEffect } from 'react';


function CreateFormation({ formation, setFormation, err, maxCaracteres }) {


    const [text, setText] = useState("")

    const [startDate, setStartDate] = useState(formation.date_start)
    const [endDate, setEndDate] = useState(formation.date_end)


    const regexDate = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/

    useEffect(() => {
        setFormation((state) => ({ ...state, description: text }))
    }, [text])


    useEffect(() => {
        setText(formation.description)
    }, [])

    const handleFormation = (e) => {
        let newData = { ...formation }
        if (e.target.name === "bac") {
            newData[e.target.name] = parseInt(e.target.value)
        } else {
            newData[e.target.name] = e.target.value
        }
        setFormation(newData)
    }

    const handleStartDateFormation = (date) => {
        let newData = { ...formation }
        date = new Date(date.target.value)
        newData["dateStart"] = toIsoString(date)
        newData["date_start"] = dateFormatter(date)
        setStartDate(dateFormatter(date))
        setFormation(newData)
    }

    const handleEndDateFormation = (e) => {
        let newData = { ...formation }
        e = new Date(e.target.value)
        newData["dateEnd"] = toIsoString(e)
        newData["date_end"] = dateFormatter(e)
        setEndDate(dateFormatter(e))
        setFormation(newData)
    }


    const handleInputStartDate = (e) => {
        let value = e.target.value
        setStartDate(value)
        if (regexDate.test(value)) {
            let test = value.replace(/-/g, '/')
            let parts = value.split("/");
            let day = parts[0];
            let month = parts[1];
            let year = parts[2];
            let date = new Date(`${month}-${day}-${year}`)
            handleStartDateFormation(date)
        }
    }

    const handleInputEndDate = (e) => {
        let value = e.target.value
        setEndDate(value)
        if (regexDate.test(value)) {
            let test = value.replace(/-/g, '/')
            let parts = value.split("/");
            let day = parts[0];
            let month = parts[1];
            let year = parts[2];
            let date = new Date(`${month}-${day}-${year}`)
            handleEndDateFormation(date)
        }
    }

    const dateFormatter = (date) => {
        const dateOrigine = new Date(date);

        const jour = dateOrigine.getDate();
        const mois = dateOrigine.getMonth() + 1;
        const annee = dateOrigine.getFullYear();

        const dateFormatee = `${jour.toString().padStart(2, '0')}/${mois.toString().padStart(2, '0')}/${annee}`;

        return dateFormatee;
    }

    function toIsoString(date) {
        var tzo = -date.getTimezoneOffset(),
            dif = tzo >= 0 ? '+' : '-',
            pad = function (num) {
                return (num < 10 ? '0' : '') + num;
            };

        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            dif + pad(Math.floor(Math.abs(tzo) / 60)) +
            ':' + pad(Math.abs(tzo) % 60);
    }

    return (
        <div className='bg-white'>
            <h5 className='text-3xl mb-2'>Ajouter une formation</h5>
            <div className='my-4 grid grid-cols-1 lg:grid-cols-2 gap-8'>
                <div>
                    <div className='mb-4'>
                        <label htmlFor="">Diplôme  *</label>
                        <div className=''>
                            <Input value={formation.diplome} name="diplome" onChange={handleFormation} />
                            {
                                err.diplomeErr && <p className='mt-1 p-2 bg-red-50 text-red-700'>{err.diplomeErr}</p>
                            }
                        </div>
                    </div>
                    <div className='mb-4'>
                        <label htmlFor="">Ecole / Université *</label>
                        <div className=''>
                            <Input value={formation.school} name="school" onChange={handleFormation} />
                        </div>
                        {
                            err.schoolErr && <p className='mt-1 p-2 bg-red-50 text-red-700'>{err.schoolErr}</p>
                        }
                    </div>
                    <div className='mb-4'>
                        <label htmlFor="">Niveau *</label>
                        <div className=''>
                            <DiplomaSelect onChange={handleFormation} value={formation.bac} name="bac" />
                        </div>
                        {
                            err.bacErr && <p className='mt-1 p-2 bg-red-50 text-red-700'>{err.bacErr}</p>
                        }
                    </div>
                </div>
                <div>
                    <div className='mb-4'>
                        <label htmlFor="">Date de début *</label>
                        <div className=''>
                            <input onChange={handleStartDateFormation} className='focus:outline-none py-[10px] px-[10px]  border-[1.5px] border-lightblue rounded-[3px] w-full' type="date" />
                            {/* <DateInput date={formation.dateStart} setDate={handleInputStartDate} dateString={startDate} name="dateStart" onChange={handleStartDateFormation} /> */}
                            {
                                err.dateStartErr && <p className='mt-1 p-2 bg-red-50 text-red-700'>{err.dateStartErr}</p>
                            }
                        </div>
                    </div>
                    <div className='mb-4'>
                        <label htmlFor="">Date de fin *</label>
                        <div className=''>
                            <input onChange={handleEndDateFormation} className='focus:outline-none py-[10px] px-[10px]  border-[1.5px] border-lightblue rounded-[3px] w-full' type="date" />
                            {/* <DateInput date={formation.dateEnd} dateString={endDate} setDate={handleInputEndDate} name="dateEnd" onChange={handleEndDateFormation} /> */}
                            {
                                err.dateEndErr && <p className='mt-1 p-2 bg-red-50 text-red-700'>{err.dateEndErr}</p>
                            }
                            {
                                err.dateErr && <p className='mt-1 p-2 bg-red-50 text-red-700'>{err.dateErr}</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center mb-[20px]">
                <p className="">Présentation </p>
                <div className="min-w-[250px]">
                    <Wysiwig text={text} setText={setText} />
                    {maxCaracteres &&
                        <p className={`text-right ${text.length > maxCaracteres && "text-red-500"}`}>
                            {text ? text.length : 0} / {maxCaracteres}
                        </p>
                    }
                </div>
                {
                    err.descriptionErr && <p className='mt-1 p-1 bg-red-50 text-red-700'>{err.descriptionErr}</p>
                }
            </div>
        </div>
    )
}

export default CreateFormation