import { useEffect, useState, useContext } from 'react'
import { AuthContext } from "../Context/AuthProvider";
import NavBarUser from 'Components/NavUser/NavBarUser';
import SideBar from 'Components/NavUser/SideBar';
import { Outlet, useNavigate } from 'react-router-dom';
import AlertPopup from 'Components/AlertPopup/AlertPopup';
import Cookies from "js-cookie"
import axios from "axios";
import { isClient, isUser } from 'utils/roles';
import Footer from 'Components/Footer/Footer';


function PrivateLayout() {

  const token = Cookies.get("token");
  const { value } = useContext(AuthContext);
  const redirection = useNavigate();


  const [userInformation, setUserInformation] = useState();
  const [filters, setFilters] = useState();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [pourcentage, setPourcentage] = useState(0)

  const getProfile = async () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : ""
    }
    if (isUser(value.user)) {
      const res = await axios.get(process.env.REACT_APP_API + `user/infoPerso`, { headers: headers })
      setUserInformation(res.data)
    }
    if (isClient(value.user)) {
      const res = await axios.get(process.env.REACT_APP_API + `client/infoPerso`, { headers: headers })
      setUserInformation(res.data)
    }
  }

  function getPourcentage() {
    axios.get(process.env.REACT_APP_API + `user/pourcentage`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        setPourcentage(Math.round((response.data.count / response.data.need) * 100));
      })
      .catch((error) => {
      });
  }

  const getFilters = async () => {
    try {
      // Obtenir les valeurs pour les formulaires
      const res = await axios.get(process.env.REACT_APP_API + "filtres");
      setFilters(res.data)

    } catch (error) {
      // Gérer les erreurs ici
    }
  };

  useEffect(() => {
    if (value.user.roles && value.user.roles === "guest") {
      redirection("/Connexion")
    }
    getProfile();
    isUser(value.user) && getPourcentage();
  }, [value])

  useEffect(() => {
    getFilters();
  }, [])





  return (
    <>
      <div className='flex'>
        <SideBar userContext={value} setMenuIsOpen={setMenuIsOpen} menuIsOpen={menuIsOpen} />
        <div className="w-full flex flex-col">
          <AlertPopup />
          <NavBarUser dataUser={userInformation} setMenuIsOpen={setMenuIsOpen}/>
          <Outlet context={[userInformation, setUserInformation, filters, getProfile, pourcentage, getPourcentage]} />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PrivateLayout