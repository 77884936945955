import { useEffect, useState } from 'react'
import { FaBuilding } from "react-icons/fa";
import parse from "html-react-parser";
import { IoIosMore } from "react-icons/io";


function TabEducationCard({ education }) {

    const [experienceDetailOpen, setExperienceDetailOpen] = useState(false)


    const detailHandler = () => {
        setExperienceDetailOpen(!experienceDetailOpen)
    }



    const dateFormatter = (education) => {
        return education.in_progress
            ? new Date(education.date_start).toLocaleDateString(
                "fr-FR",
                { month: "long", year: "numeric" }
            ) + " - Aujourd'hui"
            : new Date(education.date_start).toLocaleDateString(
                "fr-FR",
                { month: "long", year: "numeric" }
            ) ==
                new Date(education.date_end).toLocaleDateString(
                    "fr-FR",
                    { month: "long", year: "numeric" }
                )
                ? new Date(education.date_start).toLocaleDateString(
                    "fr-FR",
                    { month: "long", year: "numeric" }
                )
                : new Date(education.date_start).toLocaleDateString(
                    "fr-FR",
                    { month: "long", year: "numeric" }
                ) +
                " - " +
                new Date(education.date_end).toLocaleDateString(
                    "fr-FR",
                    { month: "long", year: "numeric" }
                )
    }


    return (
        <div className="border rounded p-2">
            <div className="flex items-start gap-2">
                <span className="text-black text-[30px] bg-[#EBF4FF] p-4 rounded-full"><FaBuilding /></span>
                <div>
                    <p className="font-bold text-[#5B9ECF] text-[24px]">{education.school}</p>
                    <p className="font-medium">{education.diplome} <span className='italic text-[14px]'>
                        {education.bac === 0 ?
                            "Certification" : "Bac + " + education.bac
                        }
                    </span></p>
                    <p className="">{dateFormatter(education)}</p>
                    {
                        experienceDetailOpen ?
                            education.description != null ?
                                <p className="break-word mt-2">{parse(education.description)}</p>
                                :
                                <p className="break-word mt-2">...</p>
                            :
                            null

                    }
                </div>

            </div>
            <div className='cursor-pointer flex justify-center text-[1.8rem] ' onClick={detailHandler}><IoIosMore className='text-[#497EA6]' /></div>
        </div>
    )
}

export default TabEducationCard