import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import Arroba from "assets/svg/At";
import Contact from "assets/svg/Contact";
import axios from "axios";
import Quote from "assets/svg/Quote";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";  

import { AlertMessageContext } from "Context/AlertMessageProvider";

function NousContacter() {

    const { alert } = useContext(AlertMessageContext);

    const [captchaValidated, setCaptchaValidated] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const handleCaptchaResponse = (response) => {
        setCaptchaValidated(true);
    };

    // Function de contact
    const onSubmit = async (data) => {
        if (!captchaValidated) {
            alert.setErrorMessage("Veuillez valider le captcha.");
            setTimeout(() => {
                alert.setErrorMessage("");
            }, 5000); //supprimer l'alert dans 5 seconde
            return;
        }

        try {
            const response = await axios.post(
                process.env.REACT_APP_API + "nousContacter",
                data
            );
            // alert
            alert.setSuccessMessage("Votre message a été envoyé avec succès !");
            setTimeout(() => {
                alert.setSuccessMessage("");
            }, 5000); //supprimer l'alert dans 5 seconde
            // Reset form fields
            reset();

        } catch (error) {
            if (error.response.status === 401) {
                alert.setErrorMessage("Adresse mail incorrect");
            } else {
                alert.setErrorMessage(
                    "Une erreur s'est produite. Veuillez réessayer."
                );
            }
            setTimeout(() => {
                alert.setErrorMessage("");
            }, 5000); //supprimer l'alert dans 5 seconde
        }
    };
    return (
        <div className="bg-white pb-4">
                  <Helmet>  
                  <title>MyFinPlace - Nous contacter</title>  
                </Helmet>  
            <div className="containerxl flex flex-col xl:flex-row justify-around items-center">
                <div>
                    <div>
                        <h1 className="robotoSlab pb-[60px]">Nous contacter</h1>
                    </div>
                    <div>
                        <form method="post" onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex flex-col gap-4 md:flex-row gap-x-[76px] pb-10">
                                <div className="flex flex-col ">
                                    <span>Prénom</span>
                                    <label className=" ">
                                        <input
                                            placeholder="Prénom"
                                            type="text"
                                            {...register("prenom", {
                                                required: true,
                                            })}
                                            className="w-[300px] focus:outline-none pt-[4px] pb-[3px] pl-[15px] rounded-[25px] bg-[#EFF5FA] border border-[#BDD8EC]"
                                        />
                                        {errors.prenom && (
                                            <span className="ml-[45px] text-red-500 block">
                                                Ce champ est requis
                                            </span>
                                        )}
                                    </label>
                                </div>
                                <div className="flex flex-col">
                                    <span>Nom</span>
                                    <label className=" ">
                                        <input
                                            placeholder="Nom"
                                            {...register("nom", { required: true })}
                                            type="text"
                                            className="w-[300px] focus:outline-none pt-[4px] pb-[3px] pl-[15px] rounded-[25px] bg-[#EFF5FA] border border-[#BDD8EC]"
                                        />
                                        {errors.nom && (
                                            <span className="ml-[45px] text-red-500 block">
                                                Ce champ est requis
                                            </span>
                                        )}
                                    </label>
                                </div>
                            </div>
                            <div className="flex flex-col gap-4 md:flex-row gap-x-[76px]  pb-10">
                                <div className="flex flex-col">
                                    <span>Entreprise</span>
                                    <label className=" ">
                                        <input
                                            placeholder="Entreprise"
                                            {...register("entreprise", {
                                                required: false,
                                            })}
                                            type="text"
                                            className="w-[300px] focus:outline-none pt-[4px] pb-[3px] pl-[15px] rounded-[25px] bg-[#EFF5FA] border border-[#BDD8EC]"
                                        />
                                    </label>
                                    {errors.entreprise && (
                                        <span className="ml-[45px] text-red-500 block">
                                            Ce champ est requis
                                        </span>
                                    )}
                                </div>
                                <div className="flex flex-col">
                                    <span>Adresse mail</span>
                                    <label className=" ">
                                        <input
                                            placeholder="exemple@mail.com"
                                            {...register("email", {
                                                required: true,
                                            })}
                                            type="text"
                                            className="w-[300px] focus:outline-none pt-[4px] pb-[3px] pl-[15px] rounded-[25px] bg-[#EFF5FA] border border-[#BDD8EC]"
                                        />
                                        {errors.email && (
                                            <span className="ml-[45px] text-red-500 block">
                                                Ce champ est requis
                                            </span>
                                        )}
                                    </label>
                                </div>
                            </div>
                            <div className="flex flex-col  pb-6">
                                <span>Objet</span>
                                <label className=" ">
                                    <input
                                        placeholder="Objet"
                                        type="text"
                                        {...register("sujet", { required: true })}
                                        className="focus:outline-none pt-[4px] pb-[3px] pl-[15px] rounded-[25px] w-full bg-[#EFF5FA] border border-[#BDD8EC]"
                                    />
                                    {errors.sujet && (
                                        <span className="ml-[45px] text-red-500 block">
                                            Ce champ est requis
                                        </span>
                                    )}
                                </label>
                            </div>
                            <div className="flex flex-col">
                                <span>Message</span>
                                <div className="relative">
                                    <i className="absolute top-4 left-3.5">
                                        <Quote
                                            classProps={"scale-y-[-1]"}
                                            color={"#BDD8EC"}
                                        />
                                    </i>
                                    <textarea
                                        placeholder="Votre message..."
                                        className="text-[#263238] py-12 px-3.5 w-full lg:w-[676px] h-[200px] rounded-[10px] bg-[#EFF5FA] border border-[#BDD8EC] focus:outline-none"
                                        {...register("message", { required: true })}
                                    ></textarea>
                                    {errors.sujet && (
                                        <span className="ml-[45px] text-red-500 block">
                                            Ce champ est requis
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col items-center xl:items-start">
                                <div>
                                    <ReCAPTCHA
                                        className="flex mt-[50px] gap-x-[35px]"
                                        type="image"
                                        sitekey="6LfjpDImAAAAAK8YSm3-JWKFIEwYWnD6X0OJVhcR"
                                        onChange={handleCaptchaResponse}
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="mt-[25px] text-center bg-[#5B9ECF] hover:bg-[#497EA6] w-[217px] h-[39px] pt-2 pb-2.5 rounded"
                                >
                                    <p className="font-medium text-white">
                                        Envoyer votre message
                                    </p>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div>
                    <div className="hidden md:block">
                        <Contact />
                    </div>
                    <div className="pt-[60px]">
                        <div className="flex pb-[55px] gap-x-[10px]">
                            <Arroba
                                classProps={"color-[#5B9ECF]"}
                                height={24}
                                width={24}
                            />
                            <p>contact@myfinplace.com</p>
                        </div>
                        <div className="flex pb-[55px] gap-x-[40px]">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NousContacter;
