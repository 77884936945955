import React from 'react'

export default function Quote(props) {
    return (
        <svg className={props.classProps ? props.classProps : ""} width={props.width ? props.width : "32"} height={props.height ? props.height : "32"} viewBox="0 0 32 32" 
        fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.4 12.7543C26.06 11.0943 27.1 9.45427 28.49 7.87424C28.93 7.37424 28.99 6.65428 28.63 6.09428C28.35 5.65428 27.89 5.41429 27.39 5.41429C27.25 5.41429 27.11 5.42423 26.97 5.47429C24.03 6.33427 17.16 9.38426 16.97 19.1643C16.9 22.9342 19.66 26.1742 23.25 26.5442C25.24 26.7442 27.22 26.0943 28.69 24.7743C30.16 23.4443 31 21.5442 31 19.5643C31 16.2643 28.66 13.3742 25.4 12.7543Z" 
            fill={props.color ? props.color : "#5B9ECF"} />
            <path d="M7.28999 26.5442C9.27003 26.7442 11.25 26.0943 12.72 24.7743C14.19 23.4443 15.03 21.5442 15.03 19.5643C15.03 16.2643 12.69 13.3743 9.43 12.7543C10.09 11.0943 11.13 9.45426 12.52 7.87425C12.96 7.37425 13.02 6.65428 12.66 6.09428C12.38 5.65428 11.92 5.41429 11.42 5.41429C11.28 5.41429 11.14 5.42424 11 5.47429C8.06001 6.33428 1.19001 9.38426 1.00001 19.1643V19.3042C1.00001 23.0143 3.72999 26.1742 7.28999 26.5442Z" 
            fill={props.color ? props.color : "#5B9ECF"} />
        </svg>
    )
}

