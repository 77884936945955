import React from 'react'
import { LuLoader2 } from "react-icons/lu";

function PrimaryButton({ onClick = () => { }, text, type, disabled, isLoading, className }) {


    const disabledStyle = "border border-[#f0f0f0] bg-[#f0f0f0] text-[#999] cursor-not-allowed"


    return (
        <button
            type={type}
            disabled={disabled || isLoading}
            className={`font-medium py-2.5 px-3 flex justify-center rounded-[5px] min-w-[150px] ${disabled === true ? disabledStyle : "border border-[#5B9ECF] bg-[#5B9ECF] text-white hover:bg-[#497EA6]"} ${className} `}
            onClick={() => onClick()}>
            {isLoading ? <LuLoader2 className='animate-spin' /> : text}
        </button>
    )
}

export default PrimaryButton