export default function GrillAchat() {
  return (
    <table className="w-full text-[20px] text-center mt-16">
      <tr>
        <td className="bg-[#5B9ECF] text-white p-4 border-r rounded-tl-[20px]">Grade</td>
        <td className="bg-[#5B9ECF] text-white p-4 border-r">Années d'expérience</td>
        <td className="bg-[#5B9ECF] text-white p-4 rounded-tr-[20px]">TJM* HT</td>
      </tr>
      <tr>
        <td className="p-4 border-2 border-t-none">Consultant Jeune diplomé</td>
        <td className="p-4 border-b-2 border-t-2">Jeune diplomé</td>
        <td className="p-4 border-2 border-t-none">Entre 200 € et 400 €</td>
      </tr>
      <tr>
        <td className="p-4 border-2 border-t-none">Consultant Junior</td>
        <td className="p-4 border-b-2">1 à 3 ans</td>
        <td className="p-4 border-2 border-t-none">Entre 250 € et 500 €</td>
      </tr>
      <tr>
        <td className="p-4 border-2 border-t-none">Consultant Senior</td>
        <td className="p-4 border-b-2">4 à 8 ans</td>
        <td className="p-4 border-2 border-t-none">Entre 400 € et 700 €</td>
      </tr>
      <tr>
        <td className="p-4 border-2 border-t-none">Manager</td>
        <td className="p-4 border-b-2">9 à 13 ans</td>
        <td className="p-4 border-2 border-t-none">Entre 500 € et 900 €</td>
      </tr>
      <tr>
        <td className="p-4 border-2 border-t-none">Directeur / Associé / expert-comptable</td>
        <td className="p-4 border-b-2">14 à 19 ans</td>
        <td className="p-4 border-2 border-t-none">Entre 600 € et 1 100 €</td>
      </tr>
      <tr>
        <td className="p-4 border-2 border-t-none rounded-[20px]">Manager de transition (DG/CFO)</td>
        <td className="p-4 border-b-2">Plus de 20 ans</td>
        <td className="p-4 border-2 border-t-none rounded-br-[20px]">Entre 800 € et 1 500 €</td>
      </tr>
    </table>
  )
}