
export default function TagForCard({ forCard, level, usertag, type, name, handleFavTag }) {
  var colors = {}
  switch (type) {
    case "sectorialSkills":
      colors = {
        bgColor: "bg-sectoriellepastel",
        textColor: "text-sectorielledark",
        border: "border-sectorielledark",
        bgLevel: "bg-sectoriellelevel",
        hover: "hover:bg-sectorielledark"
      }
      break;
    case "fonctionalSkills":
      colors = {
        bgColor: "bg-fonctionnellepastel",
        textColor: "text-fonctionnelledark",
        border: "border-fonctionnelledark",
        bgLevel: "bg-fonctionnellelevel",
        hover: "hover:bg-fonctionnelledark"
      }
      break;
    case "computerSkills":
      colors = {
        bgColor: "bg-informatiquepastel",
        textColor: "text-informatiquedark",
        border: "border-informatiquedark",
        bgLevel: "bg-informatiquelevel",
        hover: "hover:bg-informatiquedark"
      }
      break;
    case "languageSkills":
      colors = {
        bgColor: "bg-linguistiquepastel",
        textColor: "text-linguistiquedark",
        border: "border-linguistiquedark",
        bgLevel: "bg-linguistiquelevel",
        hover: "hover:bg-linguistiquedark"
      }
      break;
  }

  return (
    <div className={`flex flex-col gap-1 mb-2 px-4 pt-1 pb-2 rounded border ${forCard && colors.bgColor} ${colors.border} ${colors.textColor} hover:text-white ${colors.hover} hover:cursor-pointer`}
      onClick={() => handleFavTag(usertag)}>
      <label className='text-sm font-bold hover:cursor-pointer'>
        {name}
      </label>
      <div className={`flex flex-row gap-[4px]`}>
        <hr className={`w-[12px] h-[4px] border-none font-medium ${level >= 1 ? colors.bgLevel : "bg-[#D9D9D9]"}`} />
        <hr className={`w-[12px] h-[4px] border-none font-medium ${level >= 2 ? colors.bgLevel : "bg-[#D9D9D9]"}`} />
        <hr className={`w-[12px] h-[4px] border-none font-medium ${level >= 3 ? colors.bgLevel : "bg-[#D9D9D9]"}`} />
        <hr className={`w-[12px] h-[4px] border-none font-medium ${level >= 4 ? colors.bgLevel : "bg-[#D9D9D9]"}`} />
      </div>
    </div>
  )
}