import { FaUserTie } from "react-icons/fa";
import DetailOffreTitle from "./DetailOffreTitle";
import OffreBadge from "Components/Badge/OffreBadge"
import TagLevel from "Components/TagLevel/TagLevel";

function DetailOffreRequirement({ computerSkillTag, sectorialSkillTag, fonctionalSkillTag, languageSkillTag, seniority, timeExperience }) {
    return (
        <>
            <DetailOffreTitle title={"Profil recherché"} icon={<FaUserTie />} fontSize={"text-[1.5rem]"} />
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <DetailOffreTitle title={"Compétences sectorielles"} />
                    <div className="flex flex-wrap gap-2">
                        {
                            sectorialSkillTag.map((tag, key) =>
                                <OffreBadge key={key} name={tag.tag.name} level={tag.level} type={"sectorielle"} />
                            )
                        }
                    </div>
                </div>
                <div>
                    <DetailOffreTitle title={"Compétences fonctionnelles"} />
                    <div className="flex flex-wrap flex-1 gap-2">
                        {
                            fonctionalSkillTag.map((tag, key) =>
                                <OffreBadge key={key} name={tag.tag.name} level={tag.level} type={"fonctionnelle"} />
                            )
                        }
                    </div>
                </div>
                <div>
                    <DetailOffreTitle title={"Compétences informatiques"} />
                    <div className="flex flex-wrap gap-2">
                        {
                            computerSkillTag.map((tag, key) =>
                                <OffreBadge key={key} name={tag.tag.name} level={tag.level} type={"informatique"} />
                            )
                        }
                    </div>
                </div>
                <div>
                    <DetailOffreTitle title={"Compétences linguistiques"} />
                    <div className="flex flex-wrap gap-2">
                        {
                            languageSkillTag.map((tag, key) => (
                                <OffreBadge key={key} name={tag.tag.name} level={tag.level} type={"linguistique"} />
                            ))
                        }
                    </div>
                </div>
                <div>
                    <DetailOffreTitle title={"Années d'expérience"} />
                    <div className="flex gap-2 flex-wrap">
                        {
                            timeExperience.map((el, key) => (
                                <OffreBadge key={key} name={el.name} />
                            ))
                        }
                        {!timeExperience.length && <OffreBadge key={"timeExperience_empty"} name={"Non définie"} />}
                    </div>
                </div>
                <div>
                    <DetailOffreTitle title={"Séniorité"} />
                    <div className="flex flex-wrap gap-2">
                        {
                            seniority.map((el, key) => (
                                <OffreBadge key={key} name={el.grade_type} />
                            ))
                        }
                        {!seniority.length && <OffreBadge key={"grade_empty"} name={"Non définie"} />}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailOffreRequirement