import React, { useState } from "react";
import { FaFile } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaCalendarWeek } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import Replanifier from "./popup/Replanifier";
import Annuler from "./popup/Annuler";
import { useNavigate } from "react-router-dom";
import { sub, add } from "date-fns";
import AddCalendarEvent from "Components/AddCalendarEvent/AddCalendarEvent";

export default function AppointmentProgramme(props) {
  const [popupReplanifier, setPopupReplanifier] = useState(false);
  const [popupAnnuler, setPopupAnnuler] = useState(false);
  const appointment = props.data;
  const navigate = useNavigate()
  var thrityMinutesBefore = sub(new Date(appointment.date_choice), { minutes: 15 });
  var hourAnd15After = add(new Date(appointment.date_choice), { hours: 1, minutes: 15 });

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}h${formattedMinutes}`;
  };

  function openReplanifier() {
    setPopupReplanifier(true)
  }
  function openAnnuler() {
    setPopupAnnuler(true)
  }

  return (
    <>
      <button onClick={() => navigate("/mon-espace/mes-entretiens")} className="block text-[#2563EB] font-extrabold hover:underline hover:cursor-pointer">{"<"} Retour</button>
      <span className='text-[#2563EB] bg-[#F7F9FC] inline-block p-2 rounded-full my-2'>
        <FaCalendarWeek size={28} />
      </span>
      <p className="font-medium">Entretien programmé {appointment.apply && " suite à votre candidature"}</p>
      {appointment &&
        <div className="flex items-center flex-wrap gap-x-6 mb-4">
          <p className="text-2xl font-extrabold">{new Date(appointment.date_choice).toLocaleDateString("fr-FR", {
            weekday: "long",
            day: "2-digit",
            month: "long",
            year: "numeric",
          })} à {formatTime(new Date(appointment.date_choice))}</p>
          {/* <AddCalendarEvent appointment={appointment} /> */}
        </div>
      }
      {appointment.offre &&
        <div className="flex gap-2 text-[#B7B7B7] items-center">
          <FaFile />
          <p className="font-medium w-[120px]">Poste à pouvoir</p>
          <a className="text-black font-medium flex items-center gap-2 underline" href={`/les-opportunites/${appointment.offre.id}`} target="_blank">{appointment.offre.intitule}
            <FaExternalLinkAlt />
          </a>
        </div>
      }
      <div className="flex gap-2 text-[#B7B7B7] items-center">
        {appointment.id_client &&
          <>
            <FaBuilding />
            <p className="font-medium w-[120px]">Entreprise</p>
            <p className="text-[#0F6FDE] font-medium">{appointment.id_client.company}</p>
          </>
        }
        {appointment.id_user &&
          <>
            <FaUser />
            <p className="font-medium w-[120px]">Profil</p>
            <a className="text-[#0F6FDE] font-medium flex items-center gap-2 underline" href={`/dossier-de-competences/${appointment.id_user.id}`} target="_blank">{appointment.id_user.first_name + " " + appointment.id_user.last_name}
              <FaExternalLinkAlt />
            </a>
          </>
        }
      </div>
      <div className="flex flex-col min-w-[225px] w-[50%] mx-auto gap-2 mt-12">
        {new Date().toDateString() === new Date(appointment.date_choice).toDateString() &&
          <button onClick={() => window.open(`/visio/${appointment.id}`, "_blank")} disabled={!(thrityMinutesBefore < new Date() && new Date() < hourAnd15After)} className="w-full text-center bg-[#030F30] hover:bg-[#0B3DBC] text-white py-2 rounded-[5px] hover:cursor-pointer disabled:bg-[#ADADAD] disabled:cursor-not-allowed">
            Accéder à la visioconférence
          </button>
        }
        <div onClick={openReplanifier} className="w-full text-center bg-[#FFA500] hover:bg-[#D88900] text-white py-2 rounded-[5px] hover:cursor-pointer">
          Replanifier
        </div>
        <div onClick={openAnnuler} className="w-full text-center bg-[#FF0000] hover:bg-[#D80000] text-white py-2 rounded-[5px] hover:cursor-pointer">
          Annuler le rendez-vous
        </div>
      </div>
      {/* Popup */}
      {popupReplanifier && <Replanifier appointment={appointment} setPopupReplanifier={setPopupReplanifier} getAppoitment={props.getAppoitment} />}
      {popupAnnuler && <Annuler appointment={appointment} setPopupAnnuler={setPopupAnnuler} getAppoitment={props.getAppoitment} />}
    </>
  )
}