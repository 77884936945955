import React from "react";


function Rdv() {
 

    return (
        <div className='my-2 bg-[#FDF5E1] rounded-full' >
        <p className='p-2 font-bold text-[#FFA500] text-xs whitespace-nowrap'>Entretien en cours</p>
        </div>
    );
}

export default Rdv;
