import React from 'react'
import { NavLink } from 'react-router-dom'
import { FaCheck } from "react-icons/fa";


function CreateOrEditOfferNav({step}) {
  return (
    <div className='bg-white flex-col rounded hidden md:flex sm:min-w-[250px] divide-y'>
        <NavLink to={""} end>
			  {({ isActive }) => (
    			<div className={`p-5  border-l-4 ${step.intitule ? "border-[#13b8a6]" : isActive ? "border-[#2563EB]" : ""}`}>
					<div className='flex items-center gap-4'>
		 				<span className={`p-0.5 h-[25px] w-[25px] rounded-full text-white text-center flex justify-center items-center ${step.intitule ? "bg-[#13b8a6]" : isActive ? "bg-[#2563EB]" : "bg-[#e2e8f0]"}`}>{step.intitule ?<FaCheck /> : <span>1</span>}</span>
		     			<span className='font-medium'>Intitulé</span>
		 			</div>
				</div>
  			)}
		</NavLink>
		<NavLink to={"modalite"}>
			  {({ isActive }) => (
    			<div className={`p-5  border-l-4 ${step.modalite ? "border-[#13b8a6]" : isActive ? "border-[#2563EB]" : ""}`}>
					<div className='flex items-center gap-4'>
		 				<span className={`p-0.5 h-[25px] w-[25px] rounded-full text-white text-center flex justify-center items-center ${step.modalite ? "bg-[#13b8a6]" : isActive ? "bg-[#2563EB]" : "bg-[#e2e8f0]"}`}>{step.modalite ?<FaCheck /> : <span>2</span>}</span>
		     			<span className='font-medium'>Modalité</span>
		 			</div>
				</div>
  			)}
		</NavLink>
		<NavLink to={"profil"}>
			  {({ isActive }) => (
    			<div className={`p-5  border-l-4 ${step.profil ? "border-[#13b8a6]" : isActive ? "border-[#2563EB]" : ""}`}>
					<div className='flex items-center gap-4'>
		 				<span className={`p-0.5 h-[25px] w-[25px] rounded-full text-white text-center flex justify-center items-center ${step.profil ? "bg-[#13b8a6]" : isActive ? "bg-[#2563EB]" : "bg-[#e2e8f0]"}`}>{step.profil ?<FaCheck /> : <span>3</span>}</span>
		     			<span className='font-medium'>Profil</span>
		 			</div>
				</div>
  			)}
		</NavLink>
		<NavLink to={"description"}>
			  {({ isActive }) => (
    			<div className={`p-5  border-l-4 ${step.description ? "border-[#13b8a6]" : isActive ? "border-[#2563EB]" : ""}`}>
					<div className='flex items-center gap-4'>
		 				<span className={`p-0.5 h-[25px] w-[25px] rounded-full text-white text-center flex justify-center items-center ${step.description ? "bg-[#13b8a6]" : isActive ? "bg-[#2563EB]" : "bg-[#e2e8f0]"}`}>{step.description ?<FaCheck /> : <span>4</span>}</span>
		     			<span className='font-medium'>Description</span>
		 			</div>
				</div>
  			)}
		</NavLink>
		<NavLink to={"recapitulatif"}>
			  {({ isActive }) => (
    			<div className={`p-5  border-l-4 ${step.summary ? "border-[#13b8a6]" : isActive ? "border-[#2563EB]" : ""}`}>
					<div className='flex items-center gap-4'>
		 				<span className={`p-0.5 h-[25px] w-[25px] rounded-full text-white text-center flex justify-center items-center ${step.summary ? "bg-[#13b8a6]" : isActive ? "bg-[#2563EB]" : "bg-[#e2e8f0]"}`}>{step.summary ?<FaCheck /> : <span>5</span>}</span>
		     			<span className='font-medium'>Récapitulatif</span>
		 			</div>
				</div>
  			)}
		</NavLink>
    </div>
  )
}

export default CreateOrEditOfferNav