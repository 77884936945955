import React, { useState, useEffect } from "react";
import { fr } from 'date-fns/locale'
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";

import ListAppointment from "./ListAppointment";
import AppointmentAPlanifier from "./AppointmentAPlanifier";
import AppointmentProgramme from "./AppointmentProgramme";
import AppointmentRealiser from "./AppointmentRealiser";
import AppointmentCancel from "./AppointmentCancel";
import AppointmentAccept from "./AppointmentAccept";
import AppointmentRefuser from "./AppointmentRefuser";

function SelectedDay(props) {

  const [data, setData] = useState();
  const navigate = useNavigate()
  const { id } = useParams();
  const dateOfDay = Date.parse(props.value)
  const day = format(dateOfDay, " iiii d LLLL yyyy", { locale: fr })

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      if (id) {
        setData(props.data.filter(x => x.id == id))
      }
    }
  }, [props.data, id]);
  return (
    <>
      <div className="flex flex-col xl:mt-16 items-center">
        <div className="w-full max-w-[650px] xl:max-w-none border-2 border-middleblue rounded">
          <div className="bg-middleblue flex flex-col items-center justify-center text-center  text-white py-5 ">
            <div className="justify-center col-span-3 font-bold">
              {day}
            </div>
          </div>
          <div className="p-6">
            {id === undefined ?
              <ListAppointment data={data} />
              :
              data && data.length > 0 && (
                data[0].status === "planifier" ? <AppointmentAPlanifier data={data[0]} getAppoitment={props.getAppoitment} />
                  :
                  data[0].status === "programmés" ? <AppointmentProgramme data={data[0]} getAppoitment={props.getAppoitment} />
                    :
                    data[0].status === "réaliser" ? <AppointmentRealiser data={data[0]} getAppoitment={props.getAppoitment} />
                      :
                      data[0].status === "cancel" ? <AppointmentCancel data={data[0]} getAppoitment={props.getAppoitment} />
                        :
                        data[0].status === "archiver_win" ? <AppointmentAccept data={data[0]} getAppoitment={props.getAppoitment} />
                          :
                          data[0].status === "archiver" ? <AppointmentRefuser data={data[0]} getAppoitment={props.getAppoitment} />
                            : null
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}
export default SelectedDay;