
import { Link } from "react-router-dom";
import { IoChevronDownOutline } from "react-icons/io5";
import Sandrine_Cohen from "assets/img/Sandrine_Cohen.png";
import Florian_Leveque from "assets/img/Florian_Leveque.png";
import logoLinkeden from "assets/logoLinkeden.png";

export default function Card4({ isActive, setActive }) {
  return (
    <div className="border rounded-[8px] border-[#2632384D] border-[4px] py-4 px-6 mt-16">
      <h1 onClick={() => setActive({ Question4: !isActive.Question4 })} className="font-semibold text-[#263238] cursor-pointer flex gap-2  "  >
        <div className="flex justify-between w-full items-center">
          <p className="text-[32px] text-black font-bold">Les <span className="text-[#5B9ECF]">associés fondateurs</span></p>
          <div className={isActive.Question4 ? "rotate-180 transition duration-500" : "transition duration-500"}>
            <IoChevronDownOutline className="text-[#5B9ECF]" />
          </div>
        </div>
      </h1>
      <div className={isActive.Question4 ? "animation-box-visible" : "animation-box-hidden "} id="box1">
        <div className="pr-5 text-justify animation-content" id="content2">
          <div className="flex flex-col md:flex-row justify-around w-full mt-16">
            <div>
              <img className="h-[214px] mx-auto" src={Sandrine_Cohen} alt="" srcSet="" />
              <p className="text-center text-[20px] font-medium mt-4">Sandrine COHEN-SOLAL</p>
              <p className="text-center text-[20px] font-medium mt-4">Expert-comptable élu à l’Ordre</p>
              <p className="text-center text-[20px] font-medium">Commissaire aux comptes</p>
              <p className="text-center text-[20px] font-medium">DAF externalisé</p>
              <p className="text-center text-[20px] font-medium">Présidente associée de MyFinPlace Audit & Expertise</p>
              <a href="https://www.linkedin.com/in/sandrine-cohen-solal-61074b45/" target="_blank">
                <img className="h-[48px] mx-auto mt-8" src={logoLinkeden} alt="" srcSet="" />
              </a>
            </div>
            <div className="mt-8 md:mt-0">
              <img className="h-[214px] mx-auto" src={Florian_Leveque} alt="" srcSet="" />
              <p className="text-center text-[20px] font-medium mt-4">Florian LEVÊQUE</p>
              <p className="text-center text-[20px] font-medium mt-4">Associé de MyFinPlace Audit & Expertise</p>
              <p className="text-center text-[20px] font-medium">Président et fondateur de MyFinPlace</p>
              <a href="https://www.linkedin.com/in/florian-leveque-a3516868/" target="_blank">
                <img className="h-[48px] mx-auto mt-20" src={logoLinkeden} alt="" srcSet="" />
              </a>
            </div>
          </div>
          <p className="text-[20px] font-bold mt-8">Notre histoire</p>
          <p className="text-[20px] font-medium mt-4">La naissance du Cab est le fruit de la collaboration entre MyFinPlace le Hub et Sandrine Cohen Solal, expert comptable. Il s'agit de fusionner la technologie de mise en relation avec les professionnels réglementés, en adaptant une plateforme de mise en relation aux besoins des experts-comptables.</p>
          <p className="text-[20px] font-bold mt-4 italic">“J'ai trouvé dans le modèle de MFP une solution novatrice aux défis de recrutement qui entravent la croissance des cabinets. J’ai perçu tout de suite l’opportunité et l’agilité du Hub pour améliorer le service aux clients grâce à la co-traitance. Le Cabinet nouvelle génération favorise un cercle vertueux de croissance durable tout en respectant nos textes réglementaires”</p>
          <p className="text-[20px] font-bold mt-4 italic">Sandrine COHEN-SOLAL, Présidente associée de MyFinPlace Audit & Expertise</p>

        </div>
      </div>
    </div>
  )
}