import React from 'react'

export default function DiplomaSelect({ onChange, value, name }) {
    return (
        <div>
            <select
                onChange={(e) => onChange(e)}
                name={name}
                className="focus-visible:outline-none w-full border border-lightblue bg-greylight text-[#455A64] cursor-pointer p-[10px]"
            >
                <option value="" className="hidden"></option>
                <option selected={value == "1"} value={1} className="">
                    Bac +1
                </option>
                <option selected={value == "2"}  value={2} className="">
                    Bac +2
                </option>
                <option selected={value == "3"}  value={3} className="">
                    Bac +3
                </option>
                <option selected={value == "4"}  value={4} className="">
                    Bac +4
                </option>
                <option selected={value == "5"}  value={5} className="">
                    Bac +5
                </option>
                <option selected={value == "6"}  value={6} className="">
                    Bac +6
                </option>
                <option selected={value == "7"}  value={7} className="">
                    Bac +7
                </option>
                <option selected={value == "8"}  value={8} className="">
                    Bac +8
                </option>
            </select>
        </div>
    )
}
