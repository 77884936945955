import React from 'react'
import { createContext } from "react";
import { useState, useMemo } from 'react';

export const CheckboxContext = createContext();

export const CheckboxProvider = ({ children }) => {


    const [checkboxes, setCheckboxes] = useState({
        champLibre: "",
        contrat: [],
        tag: [],
        grade: [],
        disponibilite: [],
        localisation: [],
        jtm: null,
        salaire: null,
        timeExperience: [],
        remote: [],
        workTime: [],
        levelCompetence: []
    });

    const checkBoxValues = useMemo(() => ({
        checkboxes,
        setCheckboxes
    }),
        [
            checkboxes,
            setCheckboxes
        ]
    );

    const resetFilters = () => {
        setCheckboxes({
            champLibre: "",
            contrat: [],
            tag: [],
            grade: [],
            disponibilite: [],
            localisation: [],
            jtm: null,
            salaire: null,
            timeExperience: [],
            remote: [],
            workTime: [],
            levelCompetence: []
        })
    }

    return (
        <CheckboxContext.Provider value={{ checkBoxValues, setCheckboxes, resetFilters }}>
            {children}
        </CheckboxContext.Provider>
    )
}