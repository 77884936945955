import React from 'react'

function Badge({ name, mode = null }) {

    let style = ""

    switch (mode) {
        case "success":
            style = "text-[#2C9E6D] bg-[#EFFAF6]"
            break;
        case "info":
            style = "text-[#263FA9] bg-[#C4DAFB]"
            break;
        case "danger":
            style = "text-[#E45E87] bg-[#FEF3F5]"
            break;
        case "warning":
            style = "bg-yellow-100 text-yellow-800"
            break
        case "desactivate":
            style = "bg-gray-50 text-gray-600"
            break;
        default:
            break;
    }
    return (
        <p className={`w-fit text-sm ${style} px-[5px] rounded-lg`}>{name}</p>
    )
}

export default Badge