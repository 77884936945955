import React from 'react'

function idea() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3087_872)">
                <path d="M25.6981 16.4276C26.0363 16.0945 26.0404 15.5503 25.7073 15.2122C25.3743 14.8741 24.8301 14.87 24.492 15.203L21.5735 18.0778C21.4095 18.2393 21.3172 18.4599 21.3172 18.6901V24.8164C21.9231 24.8165 22.506 24.8166 23.0359 24.8168V19.0498L25.6981 16.4276Z" fill="#5B9ECF" />
                <path d="M18.4227 18.0822L15.548 15.2074C15.2124 14.8719 14.6682 14.8719 14.3326 15.2074C13.997 15.543 13.997 16.0872 14.3326 16.4228L16.9556 19.0458V24.8166C17.4853 24.8165 18.0684 24.8163 18.6744 24.8162V18.6899C18.6745 18.462 18.5839 18.2434 18.4227 18.0822Z" fill="#5B9ECF" />
                <path d="M32.9591 12.9847C32.9591 5.83903 27.1457 0.0255127 20 0.0255127C12.8543 0.0255127 7.04083 5.83903 7.04083 12.9847C7.04083 17.1962 9.11185 20.9682 12.7412 23.4042V25.4825C11.2844 26.5946 11.1767 28.9411 12.5251 30.188C11.9228 30.7626 11.5552 31.591 11.5756 32.4831C11.5751 34.3492 13.1918 35.7208 15.0247 35.5639C15.3223 38.0457 17.4393 39.9763 20 39.9763C22.5607 39.9763 24.6777 38.0457 24.9752 35.5639C26.8083 35.7207 28.4248 34.3492 28.4243 32.4831C28.4446 31.591 28.0771 30.7626 27.4748 30.188C28.8236 28.9407 28.715 26.5942 27.2586 25.4825V23.4041C30.8882 20.9682 32.9591 17.1963 32.9591 12.9847ZM25.8988 26.6506C27.2202 27.2486 26.8329 29.3216 25.3436 29.3287H14.6563C12.8415 29.2829 12.859 26.5739 14.6563 26.5311H25.3436C25.5415 26.5311 25.729 26.5744 25.8988 26.6506ZM20 38.2577C18.3888 38.2577 17.0451 37.0945 16.762 35.564H23.2379C22.9549 37.0945 21.6111 38.2577 20 38.2577ZM26.7056 32.4832C26.7056 33.2342 26.0947 33.8452 25.3436 33.8452H14.6563C12.842 33.7859 12.8433 31.1062 14.6563 31.0475H25.3436C26.123 31.0452 26.7437 31.7074 26.7056 32.4832ZM14.46 24.8193V22.937C14.46 22.6406 14.3072 22.3651 14.0558 22.2081C12.4147 21.1833 11.1193 19.8836 10.2058 18.3454C9.24615 16.7293 8.75958 14.9258 8.75958 12.9848C8.75958 6.78676 13.802 1.74434 20 1.74434C26.1979 1.74434 31.2404 6.78676 31.2404 12.9848C31.2404 14.9259 30.7538 16.7294 29.7942 18.3454C28.8807 19.8836 27.5853 21.1833 25.9442 22.2081C25.6928 22.3651 25.54 22.6406 25.54 22.937V24.8193C25.475 24.8151 14.525 24.8151 14.46 24.8193Z" fill="#263238" />
                <path d="M35.8837 8.14131C35.559 8.14131 35.2484 7.95647 35.1032 7.64248C34.9039 7.2117 35.0916 6.70092 35.5223 6.5017L37.7751 5.45959C38.2058 5.26038 38.7166 5.44803 38.9159 5.87874C39.1152 6.30944 38.9275 6.82022 38.4968 7.01952L36.244 8.06163C36.1271 8.11569 36.0044 8.14131 35.8837 8.14131Z" fill="#5B9ECF" />
                <path d="M39.1406 13.1764H36.6541C36.1794 13.1764 35.7947 12.7916 35.7947 12.317C35.7947 11.8424 36.1794 11.4576 36.6541 11.4576H39.1406C39.6152 11.4576 40 11.8424 40 12.317C40 12.7916 39.6152 13.1764 39.1406 13.1764Z" fill="#5B9ECF" />
                <path d="M38.1002 19.254C37.9795 19.254 37.8568 19.2283 37.74 19.1743L35.4871 18.1321C35.0563 17.9328 34.8687 17.4221 35.068 16.9913C35.2673 16.5606 35.7781 16.373 36.2088 16.5722L38.4616 17.6144C38.8924 17.8137 39.08 18.3244 38.8807 18.7551C38.7355 19.069 38.4248 19.254 38.1002 19.254Z" fill="#5B9ECF" />
                <path d="M1.86456 19.2539C1.53995 19.2539 1.22933 19.0692 1.08409 18.7551C0.884795 18.3243 1.07245 17.8136 1.50323 17.6143L3.75604 16.5721C4.18675 16.373 4.69753 16.5605 4.89683 16.9913C5.09612 17.422 4.90847 17.9327 4.47769 18.132L2.22479 19.1742C2.10808 19.2282 1.98534 19.2539 1.86456 19.2539Z" fill="#5B9ECF" />
                <path d="M3.34594 13.1763H0.859375C0.384766 13.1763 0 12.7916 0 12.317C0 11.8423 0.384766 11.4576 0.859375 11.4576H3.34594C3.82055 11.4576 4.20531 11.8423 4.20531 12.317C4.20531 12.7916 3.82055 13.1763 3.34594 13.1763Z" fill="#5B9ECF" />
                <path d="M4.15157 8.14127C4.03079 8.14127 3.90814 8.11565 3.79134 8.06159L1.53845 7.01956C1.10767 6.82026 0.920012 6.30956 1.11931 5.87878C1.31861 5.44799 1.82947 5.26049 2.26009 5.45963L4.5129 6.50174C4.94368 6.70096 5.13134 7.21174 4.93204 7.64252C4.78689 7.95643 4.47611 8.14127 4.15157 8.14127Z" fill="#5B9ECF" />
            </g>
            <defs>
                <clipPath id="clip0_3087_872">
                    <rect width="40" height="40" fill="white" transform="translate(0 0.000976562)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default idea