import React from "react";
import { RxCross2 } from "react-icons/rx";


function Delete() {
 

    return (
        <div>
        <span className='text-sm text-[#FF0000]'><RxCross2 /> </span>  
        </div>  
    );
}

export default Delete;
