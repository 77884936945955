import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useCreatePath } from "react-admin";
import cookie from "js-cookie";
import axios from "axios";

function DernierClient() {
  const [data, setData] = useState([]);
  const [nb, setNb] = useState();

  useEffect(() => {
    axios.get(process.env.REACT_APP_API + `iris/lastClient`, {
      headers: {
        Authorization: `Bearer ${cookie.get("token")}`
      }
    })
      .then((response) => {
        setData(response.data["hydra:member"]);
        setNb(response.data["hydra:totalItems"])
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const createPath = useCreatePath();
  const CreateLink = (resource, id, text) => {
    return <Link to={createPath({ resource: resource, type: 'show', id: id })} className="text-[#497EA6] hover:underline">{text}</Link>
  };

  return (
    <>
      <section className="max-h-[50vh] overflow-y-auto smaller-scroll-bar mb-6 w-1/2">
        <p className="text-red-500 pb-4">Les derniers clients inscrits ({nb})</p>
        {nb === undefined ? (
          <p>Chargement</p>
        ) : (
          nb === 0 ? (
            <p className="">Aucun client</p>
          ) : (
            <table className="bg-white drop-shadow w-full">
              <tr className="text-[14px]">
                <td className="py-2 px-4 font-medium">ID</td>
                <td className="py-2 px-4 font-medium">Entreprise</td>
                <td className="py-2 px-4 font-medium">Prénom</td>
                <td className="py-2 px-4 font-medium">Nom</td>
                <td className="py-2 px-4 font-medium">Identité</td>
                <td className="py-2 px-4 font-medium">Adresse mail</td>
                <td className="py-2 px-4 font-medium">Date de création</td>
              </tr>
              {data.map((e, index) => (
                <tr className="text-[12px]">
                  <td className="py-2 px-4 border-b">{CreateLink('clients', e["@id"], e.id)}</td>
                  <td className="py-2 px-4 border-b">{e.company}</td>
                  <td className="py-2 px-4 border-b">{e.first_name}</td>
                  <td className="py-2 px-4 border-b">{e.last_name}</td>
                  <td className="py-2 px-4 border-b">{e.roles[0]}</td>
                  <td className="py-2 px-4 border-b">{e.email}</td>
                  <td className="py-2 px-4 border-b">{new Date(e.date_time_creation).toLocaleDateString("fr-FR", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  })}</td>
                </tr>
              ))}
            </table>
          )
        )}
      </section>
    </>
  );
}

export default DernierClient;