import { Svg, Path } from '@react-pdf/renderer';

const Group = () => (
  <Svg
    width="18"
    height="18"
    viewBox="0 0 20 20"
    fill="#263238"
    xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M14.9159 18.0154C14.8319 17.832 10.27 15.2473 10.2647 15.2439C9.62128 14.7932 9.54286 14.3548 10.062 14.1029C10.5072 13.8876 10.8901 13.1936 11.1789 12.4507C11.2305 12.4733 11.2888 12.4774 11.3433 12.4623C12.555 12.1248 12.5767 9.79574 11.7662 10.207C12.5239 3.91168 3.37214 3.90856 4.12978 10.2073C3.31833 9.79512 3.34064 12.1248 4.55208 12.4623C4.60665 12.4773 4.66503 12.4733 4.7168 12.4511C5.00522 13.1936 5.38748 13.8876 5.83275 14.1029C6.35611 14.3567 6.25275 14.7736 5.61717 15.2536C5.4052 15.4139 2.45175 16.982 1.61798 17.5126C1.61634 17.5129 1.61339 17.5148 1.61339 17.5148H1.61437C1.27017 17.7345 1.02539 17.917 0.979781 18.0157C0.742219 18.5373 0.65625 19.3748 0.65625 19.3748H15.2394C15.2394 19.3748 15.1541 18.5373 14.9159 18.0154Z"
      fill="black" />
    <Path
      d="M11.3349 6.20843C11.9304 6.84468 12.586 7.9403 12.5384 9.65499C12.7694 9.81968 12.9624 10.1016 13.028 10.5384C13.1487 11.3453 12.8039 12.6672 11.7024 13.0787C11.4041 13.7491 11.0783 14.2222 10.7163 14.5131H20.3439C20.3439 14.5131 20.2579 13.6756 20.0203 13.1537C19.9363 12.9706 15.3744 10.3859 15.3695 10.3822C14.7254 9.93187 14.6473 9.49343 15.1664 9.24155C15.6116 9.02593 15.9942 8.33218 16.2833 7.58937C16.3358 7.61124 16.3916 7.61624 16.4477 7.6003C17.6591 7.26343 17.6811 4.93437 16.8706 5.34562C17.6125 -0.816882 8.86473 -0.945945 9.20499 4.95937C10.0266 5.17812 10.7692 5.60468 11.3349 6.20843Z"
      fill="black" />
  </Svg>
)

export default Group