import { useEffect, useState, useContext } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Wysiwig from "Components/wysiwig/wysiwig";
import Cookies from "js-cookie";
import axios from "axios";
import { isClient, isUser } from 'utils/roles';
import { AuthContext } from 'Context/AuthProvider';

import PrimaryButton from 'Components/Buttons/PrimaryButton'

import { AlertMessageContext } from 'Context/AlertMessageProvider';
import { regex } from 'react-admin';


function GeneralsInformation() {
  const [userInformation, setUserInformation, filters, getProfile] = useOutletContext();
  const token = Cookies.get("token");
  const [isLoading, setIsLoading] = useState(false);
  const maxDescription = 500;

  const { alert } = useContext(AlertMessageContext);
  const { value } = useContext(AuthContext);

  const [presentation, setPresentation] = useState("")
  const [presentationErr, setPresentationErr] = useState("")
  const [mobileErr, setMobileErr] = useState("")

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: "onSubmit"
  });

  useEffect(() => {
    if (userInformation) {
      setValue("firstName", userInformation.first_name)
      setValue("lastName", userInformation.last_name)
      setValue("email", userInformation.email)
      setValue("genre", userInformation.genre)
      setValue("mobile", userInformation.mobile)
      setPresentation(userInformation.presentation)
      if (isClient(value.user)) {
        setValue("title", userInformation.title)
        setValue("company", userInformation.company)
      }
    }
  }, [userInformation])


  const handleDescription = (e) => {
    setPresentation(e);
  };

  const onSubmit = async (formData) => {
    try {
      const payload = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        mobile: formData.mobile,
        genre: formData.genre,
      }
      if (isUser(value.user) ) {
        if (presentation.length > maxDescription) {
          setPresentationErr(`Votre description doit être maximale de ${maxDescription} caractères.`)
          return
        }
        const regexPres = /^(.*[@]).*$/
        if(regexPres.test(presentation) == true){
          setPresentationErr(`Votre description ne doit pas contenir votre email, numéro de téléphone, nom et prénom`)
          return
        } else{
          setPresentationErr(``)
        }
        const regexMobile = /^!*([0-9]!*){10,10}$/
        if(regexMobile.test(formData.mobile) == false){
          setMobileErr(`Votre numéro doit contenir 10 chiffres sans espace`)
          return
        }else{
          setMobileErr(``)
        }
        payload.presentation = presentation
        payload.tags = userInformation.tags.map(el => ({
          user: `api/users/${userInformation.id}`,
          tag: `api/tags/${el.tag.id}`,
          level: el.level,
          forCard: el.for_card
        }))

        await editUser(payload)
      } else {
        payload.title = formData.title
        payload.company = formData.company
        await editClient(payload)
      }

      alert.successAlert("Votre profil a été mis à jour !");
      getProfile()
    } 
    catch (error) {
      alert.errorAlert(error.response.data.detail)
      setIsLoading(false)
    }
  }
  const editUser = async (data) => {
    setIsLoading(true)
    const url = `${process.env.REACT_APP_API}user/infoPerso/modify/${userInformation.id}`
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/merge-patch+json"
      }
    }
    await axios.patch(url, data, header)
    setIsLoading(false)
  }
  const editClient = async (data) => {
    setIsLoading(true)
    const url = `${process.env.REACT_APP_API}client/infoPerso/modify/${userInformation.id}`
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/merge-patch+json"
      }
    }

    const res = await axios.patch(url, data, header)
    setIsLoading(false)
    return res
  }


  return (
    <div className='h-full'>
      <div className='flex flex-col h-full'>
        <div className='bg-white p-4 overflow-auto border'>
          <div className='max-w-[800px] m-auto gap-4'>
            <form action="" onSubmit={handleSubmit(onSubmit)}>
              <div className='mb-4'>
                <div className='col-span-6 sm:col-span-3'>
                  <label htmlFor="genre" className='block font-medium leading-6 text-gray-900'>Genre</label>
                  <select id="genre" name="genre" className='w-full bg-white ring-1 ring-gray-300 py-2 px-2 rounded-md focus:ring-2 focus:ring-[#2563EB] outline-none' {...register('genre')}>
                    <option value="Autre">Autre</option>
                    <option value="Homme">Homme</option>
                    <option value="Femme">Femme</option>
                  </select>
                </div>
                <div className='mt-2 grid  gap-x-4 gap-y-4 sm:grid-cols-6'>

                  <div className='col-span-6 sm:col-span-3'>
                    <label htmlFor="firstname" className='block font-medium leading-6 text-gray-900'>Prénom</label>
                    <input name='firstname' id='firstname' className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" {...register("firstName", { required: "Champ requis" })} />
                    {errors.firstName && (
                      <span className="text-red-500 text-xs font-bold mt-1">
                        {errors.firstName.message}
                      </span>
                    )}
                  </div>
                  <div className='col-span-6 sm:col-span-3'>
                    <label htmlFor="lastName" className='block font-medium leading-6 text-gray-900'>Nom</label>
                    <input name='lastName' id='lastName' className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" {...register("lastName", { required: "Champ requis" })} />
                    {errors.lastName && (
                      <span className="text-red-500 text-xs font-bold mt-1">
                        {errors.lastName.message}
                      </span>
                    )}
                  </div>
                  {isClient(value.user) ?
                    <div className='col-span-6 sm:col-span-3'>
                      <label htmlFor="firstname" className='block font-medium leading-6 text-gray-900'>Entreprise</label>
                      <input name='company' id='company' className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" {...register("company", { required: "Champ requis" })} />
                    </div>
                    : null
                  }
                  {isClient(value.user) ?
                    <div className='col-span-6 sm:col-span-3'>
                      <label htmlFor="lastname" className='block font-medium leading-6 text-gray-900'>Fonction</label>
                      <input name='title' id='title' className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" {...register("title", { required: "Champ requis" })} />
                    </div>
                    : null
                  }
                  <div className='col-span-6 sm:col-span-3'>
                    <label htmlFor="" className='block font-medium leading-6 text-gray-900'>Email</label>
                    <input readOnly className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" {...register("email", { required: "Champ requis" })} />
                  </div>
                  <div className='col-span-6 sm:col-span-3'>
                    <label htmlFor="mobile" className='block font-medium leading-6 text-gray-900'>Téléphone</label>
                    <input id="mobile" name="mobile" className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" {...register("mobile", { required: "Champ requis", pattern: { value: /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g, message: "Numéro de téléphone invalide" } })} />
                    {errors.mobile && (
                      <span className="text-red-500 text-xs font-bold mt-1">
                        {errors.mobile.message}
                      </span>
                    )}
                       {mobileErr && (
                            <span className="text-red-500 text-xs font-bold mt-1">
                              {mobileErr}
                            </span>
                          )}
                  </div>

                  {
                    userInformation
                      ?
                      isUser(value.user) ?
                        <div className='col-span-6'>
                          <Wysiwig text={presentation} setText={handleDescription} />
                          {presentationErr && (
                            <span className="text-red-500 text-xs font-bold mt-1">
                              {presentationErr}
                            </span>
                          )}
                          <div className={`text-right ${presentation && presentation.length > maxDescription && "text-[#FF0000]"}`}>
                            {presentation ? (`${presentation.length} / ${maxDescription}`) : (`0 / ${maxDescription}`)}
                          </div>
                        </div>
                        :
                        null
                      : null
                  }
                </div>
                <div className='flex justify-end mt-2'>
                  <PrimaryButton type={"submit"} text={"Sauvegarder"} isLoading={isLoading} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralsInformation