import { Svg, Path } from '@react-pdf/renderer';

const Group = () => (
  <Svg
    width="16"
    height="18"
    viewBox="0 0 12 21"
    fill="#263238"
    xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M7.49538 9.44037H12V7.70642H8.65846L6.81231 4.49862C6.53538 4.01697 6.03692 3.68945 5.46462 3.68945C5.29846 3.68945 5.15077 3.71835 5.00308 3.76651L0 5.3945L0 10.4037H1.66154V6.86835L3.60923 6.23257L0 21H1.66154L4.31077 13.1876L6.46154 16.1835V21H8.12308V14.8252L5.82462 10.4518L6.49846 7.68716M7.38462 3.46789C8.30769 3.46789 9.04615 2.69725 9.04615 1.73395C9.04615 0.770642 8.30769 0 7.38462 0C6.46154 0 5.72308 0.770642 5.72308 1.73395C5.72308 2.69725 6.46154 3.46789 7.38462 3.46789Z"
      fill="black" />
  </Svg>
)

export default Group