
import { IoChevronDownOutline } from "react-icons/io5";

export default function Card2({ isActive, setActive }) {
  return (
    <div className="border rounded-[8px] border-[#2632384D] border-[4px] py-4 px-6 mt-16">
      <h1 onClick={() => setActive({ Question2: !isActive.Question2 })} className="font-semibold text-[#263238] cursor-pointer flex gap-2  "  >
        <div className="flex justify-between w-full items-center">
          <p className="text-[32px] text-black font-bold">Le CAB : un cabinet <span className="text-[#5B9ECF]">“Nouvelle génération”</span></p>
          <div className={isActive.Question2 ? "rotate-180 transition duration-500" : "transition duration-500"}>
            <IoChevronDownOutline className="text-[#5B9ECF]" />
          </div>
        </div>
      </h1>
      <div className={isActive.Question2 ? "animation-box-visible" : "animation-box-hidden "} id="box1">
        <div className="pr-5 text-justify animation-content" id="content2">
          <p className="text-[20px] font-medium mt-8">Découvrez Le CAB, le cabinet d'expertise-comptable et de commissariat aux comptes nouvelle génération, inscrit à l'ordre de Paris IDF. </p>
          <p className="text-[20px] font-medium mt-4">Notre plateforme entièrement digitale facilite la mise en relation pour des missions sur mesure. </p>
          <p className="text-[20px] font-medium mt-4">Grâce à nos meilleurs profils du monde de l'expertise, nous sommes la solution à vos difficultés de recrutement. En tant que premier HUB de la profession d'expertise comptable, Le CAB offre un panel d'interventions plus large tout en respectant l'ordonnance de 45. </p>
          <p className="text-[20px] font-medium mt-4">Notre agilité à mobiliser des compétences adaptées garantit des missions de qualité pour les entrepreneurs et les professionnels inscrits à l'Ordre. Avec Le CAB, trouvez la compétence qui vous manque pour vos missions et bénéficiez de l'émulation de nos cabinets partenaires. </p>
          <p className="text-[20px] font-medium mt-4">Soyez assurés que malgré notre approche innovante, nous restons attachés au respect de l'ordonnance de 45.</p>
          <p className="text-[20px] font-bold mt-8">Ayez le réflexe Le CAB</p>
          <ul className="pl-8">
            <li className="text-[20px] font-medium">Vous avez besoin de consolider votre équipe comptable avec l’intervention d’un cadre comptable : directeur comptable, directeur financier, collaborateur comptable. Ne confiez plus ces missions à des acteurs non inscrits à l’Ordre. Ces missions peuvent vous mettre en défaut par rapport à la loi.</li>
            <li className="text-[20px] font-medium">Il vous manque une compétence dans un domaine d’expertise particulier, ne refusez pas la mission, restez connectés et à l’écoute des besoins de votre client et contactez MyFinPlace Audit & Expertise. Grâce à notre réseau de cabinets, nous avons certainement l’expertise qu’il vous faut et que nous mettons à votre service dans le cadre d’une mission de cotraitance.</li>
            <li className="text-[20px] font-medium">Vous peinez à recruter, vous refusez des missions... Changez d’attitude, contactez MyFinPlace Audit & Expertise.</li>
          </ul>
          <p className="text-[20px] font-medium mt-16">Le Cab, est un reflexe vertueux qui participe au développement de votre croissance et de votre CA , grace à l’externalisation de compétences.</p>
        </div>
      </div>
    </div>
  )
}