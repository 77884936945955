import { useState, useContext, useEffect } from 'react'
import { ModalContext } from 'Context/ModalProvider';
import PrimaryButton from "Components/Buttons/PrimaryButton"
import SecondaryButton from "Components/Buttons/SecondaryButton"
import { useNavigate } from "react-router-dom";
import { AuthContext } from "Context/AuthProvider";
import { isUser } from "utils/roles";
import cookie from "js-cookie";
import { AlertMessageContext } from "Context/AlertMessageProvider";


function DeleteModal() {
  const { close } = useContext(ModalContext);
  const { value } = useContext(AuthContext);
  const { alert } = useContext(AlertMessageContext);
  const token = cookie.get("token");
  const redirection = useNavigate();

  const deleteProfil = async () => {
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    if (isUser(value.user)) {

      await fetch(process.env.REACT_APP_API + "user/delete", options)
        .then(response => {
        })
        .then(() => {
          value.setIsLoggedIn(false);
          value.setPatchKpi(false);
          value.setId();
          cookie.remove("token");
          cookie.remove("StatusConnexion");
          cookie.remove("id");
          redirection("/")
          close()
          setTimeout(() => {
            alert.setSuccessMessage("Votre compte a bien été supprimé .");
          }, 1000)
          setTimeout(() => {
            alert.setSuccessMessage("");
          }, 5000); //supprimer l'alert dans 5 seconde
        })
        .catch(err => {
          console.error(err);
          alert.setErrorMessage(
            "Une erreur est survenue lors de la suppression de votre compte ."
          );
          setTimeout(() => {
            alert.setErrorMessage("");
          }, 5000); //supprimer l'alert dans 5 seconde
        });
    } else {
      await fetch(process.env.REACT_APP_API + "client/delete", options)
        .then(response => {
        })
        .then(() => {
          value.setIsLoggedIn(false);
          value.setPatchKpi(false);
          value.setId();
          cookie.remove("token");
          cookie.remove("StatusConnexion");
          cookie.remove("id");
          close()
          redirection("/")
          setTimeout(() => {
            alert.setSuccessMessage("Votre compte a bien été supprimé .");
          }, 1000)
          setTimeout(() => {
            alert.setSuccessMessage("");
          }, 5000); //supprimer l'alert dans 5 seconde
        })
        .catch(err => {
          console.error(err);
          alert.setErrorMessage(
            "Une erreur est survenue lors de la suppression de votre compte ."
          );
          setTimeout(() => {
            alert.setErrorMessage("");
          }, 5000); //supprimer l'alert dans 5 seconde
        });
    }
  }

  return (
    <div>
      <p>Confirmez-vous la suppression de votre compte ?</p>
      <div className='flex items-center justify-end gap-2'>
        <SecondaryButton onClick={() => close()} text={"Annuler"} />
        <PrimaryButton onClick={() => deleteProfil()} text={"Confirmer"} />
      </div>
    </div>
  )
}

export default DeleteModal