// ------------------NOUVEAU-------------
import React, { useEffect, useState, useContext } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AuthContext } from "Context/AuthProvider";
import { AlertMessageContext } from "Context/AlertMessageProvider";
import cookie from "js-cookie";
import jwt from "jwt-decode";
import Eye from "assets/svg/Eye";
import EyeCrossed from "assets/svg/EyeCrossed";
import PrimaryButton from "Components/Buttons/PrimaryButton";

export default function Formulaire({ setSideText }) {
    const { value } = useContext(AuthContext);
    const { alert } = useContext(AlertMessageContext);
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm({ mode: "onChange" });
    const [maiErr, setMailErr] = useState();
    const [roles, setRoles] = useState(null);
    const [identity, setIdentity] = useState(null);
    const [password, setPassword] = useState("");
    const [isEyeCrossed, setisEyeCrossed] = useState(false);
    const [isEyeCrossedVerif, setisEyeCrossedVerif] = useState(false);
    const [didReadLegal, setDidReadLegal] = useState(false)

    // variables pour la gestion des conditions de validation du password
    const [lowerValidated, setLowerValidated] = useState(false);
    const [upperValidated, setUpperValidated] = useState(false);
    const [specialValidated, setSpecialValidated] = useState(false);
    const [numberValidated, setNumberValidated] = useState(false);
    const [lengthValidate, setLengthValidate] = useState(false);

    // regex
    const lower = new RegExp("(?=.*[a-z])");
    const upper = new RegExp("(?=.*[A-Z])");
    const special = new RegExp("(?=.*[\\W_])");
    const number = new RegExp("(?=.*[0-9])");
    const minLength = new RegExp("(?=.{8,})");

    // Envoie du formulaire
    const onSubmit = (data) => {
        setIsLoading(true)
        // on change le role en tableau car la requete veut un tableau et pas une string
        data.roles = [data.roles];
        var requestOptions = {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-Type": "application/json" },
        };

        fetch(
            process.env.REACT_APP_API + "inscription/" + roles,
            requestOptions
        )
            .then((res) => res.json())
            .then((res) => {
                if (res.error) {
                    setIsLoading(false)
                    setMailErr(res.error);
                } else {
                    setMailErr('')
                    fetch(
                        process.env.REACT_APP_API + "login",
                        requestOptions
                    )
                        .then(res => res.json())
                        .then(res => {
                            setIsLoading(false)
                            cookie.set("token", res.token, {
                                secure: true,
                                expires: 2,
                                sameSite: 'none'
                            });
                            const decodedToken = jwt(res.token);
                            value.setUser(decodedToken);
                            value.setIsLoggedIn(true);
                            alert.setSuccessMessage(
                                "Votre inscription a bien été validée."
                            );
                            setTimeout(() => {
                                alert.setSuccessMessage("");
                            }, 5000); //supprimer l'alert dans 5 seconde

                            if (roles === "user") {
                                navigate("/parcours-inscription")
                            }

                            if (roles === "client") {
                                navigate("/mon-espace/Dashboard")
                            }
                        })
                        .catch((error) => {
                            setIsLoading(false)
                        });
                }
            })
            .catch((error) => setIsLoading(false));


    };

    // variable qui permet de garder le roles du select dans le form
    const handleroles = (e) => {
        setSideText(e.target.value);
        setIdentity(e.target.value)
        if (
            ["Cabinet-conseil", "Cabinet-daudit-dexpertise-comptable", "entreprise"].includes(
                e.target.value
            )
        ) {
            setRoles("client");
        } else if (["Candidat", "Consultant", "Etudiant"].includes(e.target.value)) {
            setRoles("user");
        }
    };
    const handlePassword = (e) => {
        setPassword(e.target.value);
    };

    // ckeck password
    useEffect(() => {
        {
            minLength.test(password)
                ? setLengthValidate(true)
                : setLengthValidate(false);
        }

        {
            lower.test(password)
                ? setLowerValidated(true)
                : setLowerValidated(false);
        }

        {
            upper.test(password)
                ? setUpperValidated(true)
                : setUpperValidated(false);
        }

        {
            special.test(password)
                ? setSpecialValidated(true)
                : setSpecialValidated(false);
        }

        {
            number.test(password)
                ? setNumberValidated(true)
                : setNumberValidated(false);
        }
    }, [password, errors.password]);

    function toggleEye(input) {
        const inputPassword = document.getElementById(input);
        if (inputPassword.type === "password") {
            inputPassword.type = "text"
            input === "password" ? setisEyeCrossed(true) : setisEyeCrossedVerif(true)
        } else {
            inputPassword.type = "password"
            input === "password" ? setisEyeCrossed(false) : setisEyeCrossedVerif(false)
        }
    }

    function toogleReadLegal(e) {
        setDidReadLegal(e.target.checked)
    }

    return (
        <div className="">
            <form
                action="submit"
                className="flex flex-col"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="form-input text-black">
                    <div className="flex flex-col pt-4">
                        <label htmlFor="roles">Identité</label>
                        <select
                            name="roles"
                            className={`px-4 py-2 border rounded-full bg-white w-full  ${errors.roles && ""
                                }`}
                            onInput={handleroles}
                            {...register("roles", { required: "Champ requis" })}
                        >
                            <option value="" className="hidden"></option>
                            <option value="entreprise">Entreprise</option>
                            <option value="Cabinet-conseil">
                                {" "}
                                Cabinet de conseil {" "}
                            </option>
                            <option value="Cabinet-daudit-dexpertise-comptable">
                                {" "}
                                Cabinet d'audit et/ou d'expertise comptable{" "}
                            </option>
                            <option value="Etudiant">Etudiant</option>
                            <option value="Candidat">Candidat</option>
                            <option value="Consultant">Consultant</option>
                        </select>
                        {errors.roles && (
                            <span className="text-red-500">
                                {" "}
                                {errors.roles.message}{" "}
                            </span>
                        )}
                    </div>
                    {roles === "client" ? (
                        <>
                            <div className="flex flex-col pt-4">
                                <label htmlFor="Entreprise">Entreprise</label>
                                <input
                                    type="text"
                                    name="Entreprise"
                                    placeholder=""
                                    className={`px-4 py-2 border rounded-full flex ${errors.company && ""
                                        }`}
                                    {...register("company", {
                                        required: "Champ requis",
                                    })}
                                />
                                {errors.company && (
                                    <span className="text-red-500">
                                        {" "}
                                        {errors.company.message}{" "}
                                    </span>
                                )}
                            </div>
                            <div className="flex flex-col pt-4">
                                <label htmlFor="title">Fonction</label>
                                <input
                                    type="text"
                                    name="title"
                                    placeholder=""
                                    className={`px-4 py-2 border rounded-full flex ${errors.title && ""
                                        }`}
                                    {...register("title", {
                                        required: "Champ requis",
                                    })}
                                />
                                {errors.title && (
                                    <span className="text-red-500">
                                        {" "}
                                        {errors.title.message}{" "}
                                    </span>
                                )}
                            </div>
                        </>
                    ) : null}

                    {identity === "Cabinet-daudit-dexpertise-comptable" &&
                        <div className="flex flex-col pt-4">
                            <label htmlFor="numberSupra">Numéro d'inscription à l'ordre (SUPRA)</label>
                            <input
                                type="text"
                                name="numberSupra"
                                placeholder=""
                                className={`px-4 py-2 border rounded-full flex ${errors.numberSupra && ""
                                    }`}
                                {...register("numberSupra", {
                                    required: "Champ requis",
                                })}
                            />
                            {errors.numberSupra && (
                                <span className="text-red-500">
                                    {" "}
                                    {errors.numberSupra.message}{" "}
                                </span>
                            )}
                        </div>}

                    <div className="flex flex-col xl:flex-row gap-4">
                        <div className="flex flex-col pt-4">
                            <label htmlFor="firstName">Prénom</label>
                            <input
                                type="text"
                                name="firstName"
                                placeholder=""
                                className={`px-4 py-2 border rounded-full w-full ${errors.firstName && ""
                                    }`}
                                {...register("firstName", {
                                    required: "Champ requis",
                                })}
                            />
                            {errors.firstName && (
                                <span className="text-red-500">
                                    {" "}
                                    {errors.firstName.message}{" "}
                                </span>
                            )}
                        </div>

                        <div className="flex flex-col pt-4">
                            <label htmlFor="lastName">Nom</label>
                            <input
                                type="text"
                                name="lastName"
                                placeholder=""
                                className={`px-4 py-2 border rounded-full w-full ${errors.lastName && ""
                                    }`}
                                {...register("lastName", {
                                    required: "Champ requis",
                                })}
                            />
                            {errors.lastName && (
                                <span className="text-red-500">
                                    {" "}
                                    {errors.lastName.message}{" "}
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-col pt-4">
                        <label htmlFor="email">Adresse mail</label>
                        <input
                            type="email"
                            name="email"
                            placeholder=""
                            className={`px-4 py-2 border rounded-full  ${errors.email && ""
                                }`}
                            {...register("email", {
                                required: "Champ requis",
                                pattern: {
                                    value: /^[\w\-\.]+@([\w\-]+\.)+[\w-]{2,4}$/,
                                    message: "Email non valide",
                                },
                            })}
                        />
                        {errors.email && (
                            <span className="text-red-500">
                                {errors.email.message}
                            </span>
                        )}
                        {maiErr ? (
                            <span className="text-red-500">{maiErr}</span>
                        ) : (
                            ""
                        )}
                    </div>

                    <div className="relative flex flex-col pt-4">
                        <label htmlFor="password">Mot de passe</label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            placeholder=""
                            autoComplete="new-password"
                            className={`px-4 py-2 border rounded-full w-full ${errors.password && ""
                                }`}
                            onInput={handlePassword}
                            {...register("password", {
                                required: "Champ requis",
                                pattern: {
                                    value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).*$/,
                                    message:
                                        "Votre mot de passe doit contenir obligatoirement une majuscule, un chiffre et un caractère spécial",
                                },
                                minLength: {
                                    value: 8,
                                    message: "Minimum 8 caractères",
                                },
                            })}
                        />
                        <div className="hover:cursor-pointer absolute right-[17px] top-[52px] w-[25px] h-[25px]" onClick={() => {
                            toggleEye("password");
                        }}>
                            {isEyeCrossed ? (<Eye color={"#5B9ECF"} />) : (<EyeCrossed color={"#5B9ECF"} />)}
                        </div>
                        <ul className="list-none mdpCondition grid grid-cols-2 sm:grid-cols-4 grid-flow-row-dense gap-x-2 gap-y-2 pt-2">
                            {!lengthValidate ? (
                                <li className="text-[11px] italic">
                                    <span>{">"}</span> 8 caractères
                                </li>
                            ) : (
                                <li className="text-[11px] italic line-through">
                                    <span>{">"}</span> 8 caractères
                                </li>
                            )}
                            {!upperValidated ? (
                                <li className="text-xs italic">1 majuscule</li>
                            ) : (
                                <li className="text-xs italic line-through">
                                    1 majuscule
                                </li>
                            )}
                            {!lowerValidated ? (
                                <li className="text-xs italic">1 minuscule</li>
                            ) : (
                                <li className="text-xs italic line-through">
                                    1 minuscule
                                </li>
                            )}
                            {!numberValidated ? (
                                <li className="text-xs italic">1 chiffre</li>
                            ) : (
                                <li className="text-xs italic line-through">
                                    1 chiffre
                                </li>
                            )}
                            {!specialValidated ? (
                                <li className="text-xs italic col-start-1 col-end-7">
                                    {`1 caractère spécial (#, -, +, !, $, @, %, _ , ?  , : , ~, <, >, |,)`}
                                </li>
                            ) : (
                                <li className="text-xs italic  col-start-1 col-end-7 line-through">
                                    {`1 caractère spécial (#, -, +, !, $, @, %, _ , ?  , : , ~, <, >, |,)`}
                                </li>
                            )}
                        </ul>
                    </div>

                    <div className="relative flex flex-col pt-4">
                        <label htmlFor="verifPassword">
                            Confirmation du mot de passe
                        </label>
                        <input
                            type="password"
                            name="verifPassword"
                            id="verifPassword"
                            placeholder=""
                            className={`px-4 py-2 border rounded-full  ${errors.verifPassword && ""
                                }`}
                            {...register("verifPassword", {
                                required: "Champ requis",
                                validate: (value) =>
                                    value === getValues("password") ||
                                    "Les mots de passes ne correspondent pas",
                            })}
                        />
                        <div className="hover:cursor-pointer absolute right-[17px] ms:top-[75px] mm:top-[52px] w-[25px] h-[25px]" onClick={() => {
                            toggleEye("verifPassword");
                        }}>
                            {isEyeCrossedVerif ? (<Eye color={"#5B9ECF"} />) : (<EyeCrossed color={"#5B9ECF"} />)}
                        </div>
                        {errors.verifPassword && (
                            <span className="text-red-500">
                                {errors.verifPassword.message}
                            </span>
                        )}
                    </div>

                    <div className="inline-flex items-center mt-4">
                        <label className="switch inline-flex relative" >
                            <input type="checkbox" value={setLengthValidate} onClick={toogleReadLegal} />
                            <span className="slider hover:cursor-pointer"></span>
                        </label>
                        <p className="text-[14px] italic ml-4 self-center">
                            J'ai lu et accepte les <Link to="/CGU" target="_blank" className="text-middleblue hover:text-[#497EA6]">conditions générales d'utilisation (CGU)                             </Link>
                        </p>
                    </div>

                </div>
                <div className="pt-8 text-center">
                    <div className="mb-8 w-fit mx-auto">
                        <PrimaryButton isLoading={isLoading} disabled={!didReadLegal || roles === null} text={"Je m'inscris"} type="submit" />
                    </div>
                    {/* <button disabled={!didReadLegal} className="disabled:bg-[#ADADAD] disabled:text-white disabled:cursor-not-allowed disabled:border-[#ADADAD] bg-middleblue text-white font-bold px-8 py-2 mb-8 rounded border-2 border-middleblue hover:bg-white hover:text-[#5b9ecf] hover:cursor-pointer ">
                        Je m'inscris
                    </button> */}
                    <div>
                        <p>
                            Vous avez déjà un compte ?
                            <Link to="/Connexion">
                                <span className="text-middleblue hover:text-[#497EA6]">
                                    {" "}
                                    Connectez-vous !
                                </span>
                            </Link>
                        </p>
                    </div>
                </div>
            </form>
        </div>
    );
}
