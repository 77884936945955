import React from 'react'
import "./style.css"

function RadioButton({ id, name, label, value, checked, onChange }) {
    return (
        <label className={`radio-button ${checked ? "checked2" : ""}`}>
            <input
                type="radio"
                id={id}
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
            />
            <span className="radio-label">{label}</span>
        </label>
    )
}

export default RadioButton