import { useState, useEffect, useContext, useRef } from "react";
import { NavLink, Outlet, useOutletContext, useNavigate } from 'react-router-dom'
import { AuthContext } from "../../../Context/AuthProvider"
import { isClient, isUser } from 'utils/roles';
import { AlertMessageContext } from "Context/AlertMessageProvider";
import axios from "axios";
import cookie from "js-cookie";
import CropImageModal from "Modals/CropImageModal";
import  ProfilNoPicture from "assets/img/profilnodata.png";

function PersonalInformation() {

	const [userInformation, setUserInformation, filters, getProfile, pourcentage, getPourcentage] = useOutletContext();
	const { value } = useContext(AuthContext);
	const { alert } = useContext(AlertMessageContext);
	const [popupCrop, setPopupCrop] = useState(false)
	const [nonCrop, setNonCrop] = useState(null);
	const [photoUser, setPhotoUser] = useState("");
	const inputPhotoRef = useRef(null);
	const token = cookie.get("token");
	const navigation = useNavigate()


	// Téléchargement de la photo
	const handleFileUpload = (event) => {
		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader()
			reader.addEventListener('load', () =>
				setNonCrop(reader.result?.toString() || ''),
				setPopupCrop(true)
			)
			reader.readAsDataURL(event.target.files[0])
		}
		// // get the selected file from the input
		// const file = event.target.files[0];
		// // create a new FormData object and append the file to it
		// const formData = new FormData();
		// formData.append("file", file);
		// // make a POST request to the File Upload API with the FormData object and Rapid API headers
		// axios.post(process.env.REACT_APP_API + `user/${userInformation.id}/image`, formData, {
		// 	headers: {
		// 		"Content-Type": "multipart/form-data",
		// 	},
		// }).then(() => {
		// 	setPhotoUser(file);
		// 	alert.successAlert("Votre photo a été modifiée avec succès !")
		// }).catch((error) => {
		// 	alert.errorAlert("Erreur lors de la modification de votre photo !")
		// });
	};

	// Pour l'affichage de la photo
	useEffect(() => {
		if (value.isLoggedIn === true && value.user.roles[1] && token) {
			axios
				.get(process.env.REACT_APP_API + "user/infoPerso", {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				})
				.then((response) => {
					setPhotoUser(response.data.fileUrl);
					const resData = response.data.fileUrl
					setUserInformation(prev => ({
						...prev,
						fileUrl: resData,
					}))
				})
				.catch((error) => {
				});
		}
	}, [photoUser])

	const navigateHandler = (e) => {
		navigation(`/mon-espace/mon-profil/${e.target.value}`)
	}


	const handleDivRef = () => {
		const inputElement = inputPhotoRef.current;
		if (inputElement) {
			inputElement.click();
		}
	}

	function HandleVisibility(e) {
		if (pourcentage !== 100) {
			alert.errorAlert("Veuillez remplir votre profil à 100 % pour le rendre visible.")
			return;
		}
		axios.patch(process.env.REACT_APP_API + `user/visibility/${userInformation.id}`,
			{ "visibility": e.target.checked },
			{
				headers: {
					"Content-Type": "application/merge-patch+json",
					Authorization: `Bearer ${token}`,
				},
			}).then((res) => {
				if (!e.target.checked) {
					alert.successAlert("Votre profil est maintenant visible !")
				} else {
					alert.successAlert("Votre profil n'est plus visible !")
				}
				getProfile();
			}).catch((error) => {
				alert.errorAlert("Une erreur s'est produite. Veuillez réessayer.")
			})
	}

	function HandleConfidentiel(e) {
		axios.patch(process.env.REACT_APP_API + `user/confidentiel/${userInformation.id}`,
			{ "confidentiel": e.target.checked },
			{
				headers: {
					"Content-Type": "application/merge-patch+json",
					Authorization: `Bearer ${token}`,
				},
			}).then((res) => {
				if (!e.target.checked) {
					alert.successAlert("Votre profil est maintenant confidentiel !")
				} else {
					alert.successAlert("Votre profil n'est plus confidentiel !")
				}
				getProfile();
			}).catch((error) => {
				alert.errorAlert("Une erreur s'est produite. Veuillez réessayer.")
			})
	}

	return (
		<div className='p-6'>
			<div className='h-full flex flex-col lg:flex-row gap-4'>
				<div className='p-2 rounded sm:w-[240px] xl:border-r'>
					{isUser(value.user) &&
						<>
							<div className="grid justify-center hover:cursor-pointer" onClick={handleDivRef}>
								<div className="flex justify-center">
									{userInformation && userInformation.fileUrl ? (
										<img
											src={userInformation.fileUrl}
											alt="photo de profil"
											className='rounded-full object-cover h-[100px]' width={100} />
									) : (
										<img
											className='rounded-full object-cover h-[100px] w-[100px]' src={ProfilNoPicture} width={100} alt="" srcset=""
										/>
									)}
								</div>
								<label htmlFor="file" className="hover:cursor-pointer text-[#497EA6]">Changer ma photo de profil</label>
								<input
									type="file"
									ref={inputPhotoRef}
									id="file"
									name="file"
									accept="image/png, image/jpeg"
									onChange={handleFileUpload}
									className="hidden"
								></input>
								<br />
							</div>
							{popupCrop && <CropImageModal src={nonCrop} setPopupCrop={setPopupCrop} setPhotoUser={setPhotoUser} />}
						</>
					}
					<div className='flex-col gap-2 hidden sm:flex'>
						{userInformation && isUser(value.user) ?
							<>
								<NavLink
									target="_blank"
									className="text-[#fff] bg-[#5B9ECF]  rounded p-2 my-4 text-center  font-medium text-[14px] hover:text-[#2563EB] hover:bg-white"
									to={`/dossier-de-competences/${userInformation.id}`}>
									<span className=''>Aperçu de mon profil en ligne</span>
								</NavLink>
							</>
							:
							null
						}
						<NavLink
							className={({ isActive }) => isActive ? "text-[#2563EB] bg-[#DCEDF8] font-medium rounded p-2" : "text-[#9C9B9F] font-medium hover:text-[#636363] hover:bg-white rounded p-2"}
							to={"/mon-espace/mon-profil/informations-generales"}>
							<span className=''>Informations personnelles</span>
						</NavLink>
						{
							isUser(value.user) ?
								<>
									<NavLink
										className={({ isActive }) => isActive ? "text-[#2563EB] bg-[#DCEDF8] font-medium rounded p-2" : "text-[#9C9B9F] font-medium hover:text-[#636363] hover:bg-white rounded p-2"}
										to={"/mon-espace/mon-profil/modalites"}>
										<span className=''>Modalités</span>
									</NavLink>

									<NavLink
										className={({ isActive }) => isActive ? "text-[#2563EB] bg-[#DCEDF8] font-medium rounded p-2" : "text-[#9C9B9F] font-medium hover:text-[#636363] hover:bg-white rounded p-2"}
										to={"/mon-espace/mon-profil/competences"}>
										<span className=''>Compétences</span>
									</NavLink>
									<NavLink
										className={({ isActive }) => isActive ? "text-[#2563EB] bg-[#DCEDF8] font-medium rounded p-2" : " text-[#9C9B9F] font-medium hover:text-[#636363] hover:bg-white rounded p-2"}
										to={"/mon-espace/mon-profil/experiences"}>
										<span className=''>Expériences</span>
									</NavLink>
									<NavLink
										className={({ isActive }) => isActive ? "text-[#2563EB] bg-[#DCEDF8] font-medium rounded p-2" : "text-[#9C9B9F] font-medium hover:text-[#636363] hover:bg-white rounded p-2"}
										to={"/mon-espace/mon-profil/formations"}>
										<span className=''>Formations</span>
									</NavLink>
								</>
								:
								null
						}
						<NavLink
							className={({ isActive }) => isActive ? "text-[#2563EB] bg-[#DCEDF8] font-medium rounded p-2" : "text-[#9C9B9F] font-medium hover:text-[#636363] hover:bg-white rounded p-2"}
							to={"/mon-espace/mon-profil/confidentialite"}>
							<span className=''>Confidentialité</span>
						</NavLink>
						{isUser(value.user) &&
							<>
								<div className="min-w-[145px] max-w-[200px]">
									<label className="text-[14px]">
										Profil complété à : {!isNaN(pourcentage) ? pourcentage : null}%
									</label>
									<div className="border border-[#BDD8EC] w-full h-[13px] rounded-[50px] bg-white">
										<div
											style={{ width: `${pourcentage}%` }}
											className="bg-[#BDD8EC] h-[13px] rounded-[50px] w-1"
										>
										</div>
									</div>
								</div>
								<div className="inline-flex mt-2">
									<label className="switch inline-flex relative" >
										<input type="checkbox" onClick={HandleVisibility} checked={userInformation && userInformation.visibility} />
										<span className="slider hover:cursor-pointer"></span>
									</label>
									<p className="text-[14px] italic ml-4 self-center mt-[-5px]">
										Rendre ma vignette visible
									</p>
								</div>
								<div className="inline-flex mt-2">
									<label className="switch inline-flex relative" >
										<input type="checkbox" onClick={HandleConfidentiel} checked={userInformation && userInformation.confidentiel} />
										<span className="slider hover:cursor-pointer"></span>
									</label>
									<p className="text-[14px] italic ml-4 self-center mt-[-5px]">
										Garder mon profil confidentiel
									</p>
								</div>
							</>
						}
					</div>
					<div className="max-sm:flex flex-col items-center mb-4  hidden">
						{isUser(value.user) &&
							<>
								<div className="min-w-[145px] max-w-[200px]">
									<label className="text-[14px]">
										Profil complété à : {!isNaN(pourcentage) ? pourcentage : null}%
									</label>
									<div className="border border-[#BDD8EC] w-full h-[13px] rounded-[50px] bg-white">
										<div
											style={{ width: `${pourcentage}%` }}
											className="bg-[#BDD8EC] h-[13px] rounded-[50px] w-1"
										>
										</div>
									</div>
								</div>
								<div className="inline-flex mt-2">
									<label className="switch inline-flex relative" >
										<input type="checkbox" onClick={HandleVisibility} checked={userInformation && userInformation.visibility} />
										<span className="slider hover:cursor-pointer"></span>
									</label>
									<p className="text-[14px] italic ml-4 self-center mt-[-5px]">
										Rendre ma vignette visible
									</p>
								</div>
								<div className="inline-flex mt-2">
									<label className="switch inline-flex relative" >
										<input type="checkbox" onClick={HandleConfidentiel} checked={userInformation && userInformation.confidentiel} />
										<span className="slider hover:cursor-pointer"></span>
									</label>
									<p className="text-[14px] italic ml-4 self-center mt-[-5px]">
										Garder mon profil confidentiel
									</p>
								</div>
							</>
						}
					</div>
					<select onChange={navigateHandler} id="genre" name="genre" className='w-full bg-white ring-1 ring-gray-300 py-2 px-2 rounded-md focus:ring-2 focus:ring-[#2563EB] outline-none sm:hidden'>
						<option value="informations-generales">Informations personnelles</option>
						{isUser(value.user) && <>
							<option value="modalites">Modalités</option>
							<option value="competences">Compétences</option>
							<option value="experiences">Expériences</option>
							<option value="formations">Formations</option>
						</>}
						<option value="confidentialite">Confidentialité</option>
					</select>
				</div>
				<div className='xl:min-w-[800px]'>
					<div className='h-full'>
						<Outlet context={[userInformation, setUserInformation, filters, getProfile, pourcentage, getPourcentage]} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default PersonalInformation


