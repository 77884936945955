import { Svg, Path } from '@react-pdf/renderer';

const FluentWork = () => (
  <Svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="#263238"
    xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M18 3.375V14.625H0V3.375H5.625V2.25C5.625 2.0918 5.6543 1.94531 5.71289 1.81055C5.77148 1.67578 5.85059 1.55859 5.9502 1.45898C6.0498 1.35937 6.16992 1.27734 6.31055 1.21289C6.45117 1.14844 6.59766 1.11914 6.75 1.125H11.25C11.4082 1.125 11.5547 1.1543 11.6895 1.21289C11.8242 1.27148 11.9414 1.35059 12.041 1.4502C12.1406 1.5498 12.2227 1.66992 12.2871 1.81055C12.3516 1.95117 12.3809 2.09766 12.375 2.25V3.375H18ZM6.75 3.375H11.25V2.25H6.75V3.375ZM1.125 4.5V6.12598L6.75 8.92969V7.875H11.25V8.92969L16.875 6.12598V4.5H1.125ZM7.875 9V10.125H10.125V9H7.875ZM16.875 13.5V7.37402L11.25 10.1953V11.25H6.75V10.1953L1.125 7.37402V13.5H16.875Z"
      fill="black" />
  </Svg>
)

export default FluentWork