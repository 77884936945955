import React from 'react'
import { Helmet } from "react-helmet";  

function MentionsLegales() {
    return (
        <div >
            <Helmet>  
<title>MyFinPlace - Mentions légales </title>  
</Helmet>  
            <section className="containerxl my-12">
                <div className='text-center pt-12 '>
                    <h1 className=' robotoSlab bigscreen:text-[40px]'>
                        Mentions légales
                    </h1>
                </div>
                <div>
                    <h6 className='py-8 pt-[60px] text-justify bigscreen:text-[20px]'>
                        La platforme MyFinPlace.com («&nbsp;le Site&nbsp;») est édité par MyFinPlace, société par actions simplifiées
                        au capital de 10&nbsp;000,00&nbsp;€, dont le siège social est situé 55 Avenue Marceau - 75116 Paris, immatriculée
                        au Registre du Commerce et des Sociétés de Paris sous le numéro 915&nbsp;141&nbsp;428.
                        <br /><br />
                        Le responsable de la publication est Monsieur Alexis Gueudré.
                        <br /><br />
                        Le Site est hébergé par OVH, société par actions simplifiées au capital de 10&nbsp;714&nbsp;560,00 €, dont le siège
                        social est situé au 2, rue Kellermann – 59100 Roubaix, immatriculée au Registre du Commerce et des
                        Sociétés de Lille Métropole sous le numéro 424&nbsp;761&nbsp;419.
                        <br /><br />
                        Pour toute demande concernant le Site, il est nécessaire d’écrire à l’adresse mail suivante :
                        contact@myfinplace.com
                    </h6>
                </div>
                <div className='py-8'>
                    <div className='bigscreen:text-[30px] py-3'>
                        <h5 className='text-justify bigscreen:text-[20px]'>Informations sur le contenu et le fonctionnement du Site :</h5>
                    </div>
                    <div className='py-3'>
                        <p className='text-justify bigscreen:text-[20px]'>
                            Le Site est destiné à des utilisateurs personnes physiques ou personnes morales exerçant dans
                            les métiers de la finance et cherchant à être mises en relation avec des entreprises en recherche
                            de ressources compétentes (les «&nbsp;Utilisateurs&nbsp;»).
                            <br /><br />
                            Les services proposés aux Utilisateurs sur le Site à cet effet (les «&nbsp;Services&nbsp;») comprennent
                            l’insertion, sous conditions, des informations professionnelles fournies par les Utilisateurs dans
                            une CVthèque produite par MyFinPlace, à laquelle pourront avoir accès des entreprises en recherche
                            de ressources compétentes, inscrites sur le Site sous les conditions applicables figurant dans les
                            conditions générales de vente du Site.
                            <br /><br />
                            L’accès par les Utilisateurs au Site et aux Services proposés est gratuit.
                            <br /><br />
                            MyFinPlace n’intervient pas dans le choix des contenus mis en ligne par les Utilisateurs. Les
                            conditions régissant l’accès au Site et à son utilisation figurent dans les conditions générales
                            d’utilisation du Site. MyFinPlace se réserve le droit de modifier et de corriger le contenu du Site
                            à tout moment.
                        </p>
                    </div>
                </div>
                <div className='py-8'>
                    <div className='py-3'>
                        <h5 className='bigscreen:text-[30px]'>Droits d’auteur :
                        </h5>
                    </div>
                    <div className='py-3'>
                        <p className='text-justify bigscreen:text-[20px]'>
                            Le Site, ses contenus et sa présentation sont protégés par le droit de la propriété intellectuelle,
                            et plus particulièrement par la réglementation applicable aux droits d’auteur (article L111-1 et
                            suivants du Code de la Propriété Intellectuelle). Tous droits de reproduction sont réservés.
                            MyFinPlace ne garantit pas l’exactitude, l’exhaustivité ou la pertinence des informations mises
                            à disposition sur son Site.
                        </p>
                    </div>
                </div>
                <div className='py-8'>
                    <div className='py-3'>
                        <h5 className='bigscreen:text-[30px]'>Liens hypertextes :</h5>
                    </div>
                    <div className='py-3'>
                        <p className='text-justify bigscreen:text-[20px]'>
                            Le Site contient des liens hypertextes ou autres liens informatiques pouvant être utilisés à partir
                            du Site et permettant aux Utilisateurs d’avoir accès à des sites Internet tiers. MyFinPlace ne donne
                            aucune garantie quant au contenu de tels sites, à leur exactitude, à l’exhaustivité ou à la pertinence
                            des informations mises à disposition sur de tels sites.
                        </p>
                    </div>
                </div>
                <div className='py-8'>
                    <div className='py-3'>
                        <h5 className='bigscreen:text-[30px]'>Cookies :</h5>
                    </div>
                    <div className='py-3'>
                        <p className='text-justify bigscreen:text-[20px]'>
                            Un cookie peut s’installer automatiquement sur le disque dur ou dans la mémoire du terminal de
                            l’Utilisateur lors de toute connexion au Site et pourra y être conservé pour une période de temps
                            limitée, ce dont l’Utilisateur est informé et qu’il accepte. L’Utilisateur peut cependant désactiver
                            ce cookie, au moyen des paramètres de son logiciel de navigation.
                        </p>
                    </div>
                </div>
                <div className='py-8'>
                    <div className='py-3'>
                        <h5 className='bigscreen:text-[30px]'>Informations à caractère personnel :</h5>
                    </div>
                    <div className='py-3'>
                        <p className='text-justify bigscreen:text-[20px]'>
                            La collecte et le traitement de données à caractère personnel via le Site respectent les dispositions
                            du Règlement Général pour la Protection des Données à caractère personnel et de la Loi 78-17 du 6
                            janvier 1978 modifiée par la loi 2018-493 du 20 juin 2018 et l’Ordonnance 2018-1125 du 12 décembre
                            2018 (la «&nbsp;Réglementation&nbsp;»).L’Utilisateur bénéficie d’un droit d’accès, de rectification et
                            d’opposition. Pour l’exercer, comme pour toutes demandes concernant ses données à caractère personnel,
                            il peut contacter MyFinPlace à l’adresse mail suivante : contact@myfinplace.com
                        </p>
                    </div>
                </div>
                <p className='py-8 bigscreen:text-[20px]'><i>Date de dernière mise à jour : <strong>17 octobre 2022</strong> </i></p>
            </section>
        </div>
    )
}

export default MentionsLegales