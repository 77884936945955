//Dépendances
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie"
//Components
import GetRoomData from './GetRoomData';


function Visio() {
  const [data, setData] = useState(null);
  const [textError, setTextError] = useState(null);
  const { id } = useParams();
  const token = Cookies.get("token");
  const redirection = useNavigate()

  useEffect(() => {
    axios.get(process.env.REACT_APP_API + `VisioContent/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : ""
      }
    }).then((response) => {
      if (response.status === 200) {
        setData(response.data);
      }
    })
      .catch((error) => {
        if (error.response.status === 404) {
          //ID de l'entretien non retrouver
          redirection("/");
        }
        if (error.response.status === 401) {
          switch (error.response.data.error) {
            case "Forbidden":
              //L'utilisateur n'a pas les accès
              redirection("/")
              break;
            case "No MeetingId":
              //L'utilisateur n'a pas les accès
              setTextError("Erreur lors de la génération de la visioconférence")
              break;
            case "Too early":
              //L'utilisateur est venu trop en avance
              setTextError("La visioconférence n'est pas encore disponible")
              break;
            case "Wrong day":
              //L'utilisateur est venu trop en avance
              setTextError("La visioconférence n'est pas aujourd'hui")
              break;
            default:
              redirection("/")
              break;
          }
        }
      })
  }, [])

  return (
    <>
      {data?.url.length > 0 ? (
        <GetRoomData data={data} id={id} />
      ) : (
        textError !== null ? (
          <div className='text-center'>
            <p className='mb-4'>{textError}</p>
            <a href={window.location.origin + "/mon-espace"} className='hover:cursor-pointer inline-block bg-[#5B9ECF] border-2 border-[#5B9ECF] rounded-full py-[10px] px-[25px] text-base text-white font-semibold p-4 shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)]'>Retourner sur mon espace</a>
          </div>
        ) : (
          <p className='text-center'>Chargement</p>
        )
      )}
    </>
  )
}

export default Visio