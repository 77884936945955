export default await function TagLevel({ name, level, type, isSmall = false }) {
  // Problème : Tailwind a du mal à gérer les colors custom quand utilise des variables
  // Solution : On est obligé de les écrire en brute
  var colors = {}
  switch (type) {
    case "sectorielle":
      colors = {
        bgColor: "bg-sectoriellepastel",
        textColor: "text-sectorielledark",
        bgLevel: "bg-sectoriellelevel"
      }
      break;
    case "fonctionnelle":
      colors = {
        bgColor: "bg-fonctionnellepastel",
        textColor: "text-fonctionnelledark",
        bgLevel: "bg-fonctionnellelevel"
      }
      break;
    case "informatique":
      colors = {
        bgColor: "bg-informatiquepastel",
        textColor: "text-informatiquedark",
        bgLevel: "bg-informatiquelevel"
      }
      break;
    case "linguistique":
      colors = {
        bgColor: "bg-linguistiquepastel",
        textColor: "text-linguistiquedark",
        bgLevel: "bg-linguistiquelevel"
      }
      break;
  }
  return (
    <div className={`flex flex-col gap-1 ${isSmall ? "py-[5.5px] px-2.5 text-xs" : "py-[8px] px-[12px]"} rounded ${colors.bgColor}`}>
      <span className={`font-medium ${colors.textColor}`}>{name}</span>
      <div className={`flex flex-row ${isSmall ? "gap-[4px]" : "gap-2"}`}>
        <hr className={`${isSmall ? "w-[10px]" : "w-[18px]"} h-[2px] font-medium ${level >= 1 ? colors.bgLevel : "bg-[#D9D9D9]"}`} />
        <hr className={`${isSmall ? "w-[10px]" : "w-[18px]"} h-[2px] font-medium ${level >= 2 ? colors.bgLevel : "bg-[#D9D9D9]"}`} />
        <hr className={`${isSmall ? "w-[10px]" : "w-[18px]"} h-[2px] font-medium ${level >= 3 ? colors.bgLevel : "bg-[#D9D9D9]"}`} />
        <hr className={`${isSmall ? "w-[10px]" : "w-[18px]"} h-[2px] font-medium ${level >= 4 ? colors.bgLevel : "bg-[#D9D9D9]"}`} />
      </div>
    </div>
  )
}