import React from 'react'
import { useState, useEffect } from 'react'

function RegionSelector({ handleLocalisation, handleEnterKeyPress }) {

    const [regions, setRegions] = useState(null)



    useEffect(() => {

        fetch(process.env.REACT_APP_API + "filtres")
            .then((response) => response.json())
            .then((data) => setRegions(data.localisation));
    }, [])


    return (
        <div className='w-full p-[10px] '>
            <select className='focus-visible:outline-none w-full bg-[#FFFFFF] text-[#455A64]' name="regions" id="regions" onChange={handleLocalisation} onKeyUp={handleEnterKeyPress}>
                <option>Localisation (ex: Île-de-France...)</option>
                {
                    regions ?
                        regions.map((region, key) => (
                            <option key={key} value={region.code}>{region.nom}</option>
                        ))
                        :
                        <></>
                }
            </select>
        </div>
    )
}

export default RegionSelector