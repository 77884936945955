import React from "react";


function Refuser() {
 

    return (
        <div className='my-2 bg-[#FFE7E7] rounded-full' >
        <p className='p-2 font-bold text-[#AF4636] text-xs whitespace-nowrap'>Non retenue</p>
        </div>
    );
}

export default Refuser;
