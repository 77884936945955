import { React, useContext, useState } from "react";
import clsx from 'clsx';
import { AuthContext } from "Context/AuthProvider";
import { useNavigate, Link } from "react-router-dom";
import cookie from "js-cookie";
import ProfilNoPicture from "assets/img/profilnodata.png";

import { IoMdMenu } from "react-icons/io";




function NavBarUser({ dataUser, setMenuIsOpen, menuIsOpen }) {
  const [display, setDisplay] = useState(false);
  const redirection = useNavigate();
  const { value } = useContext(AuthContext);
  // Fonction pour effectuer une déconnexion
  function handleLogout() {
    value.setIsLoggedIn(false);
    value.setPatchKpi(false);
    value.setId();
    value.setUser();
    cookie.remove("token");
    cookie.remove("StatusConnexion");
    cookie.remove("id");
    redirection("/")
  }

  return (
    <header className="sticky top-0 w-full items-center bg-white justify-between p-2 flex z-20 border-b">
      <div className="lg:hidden cursor-pointer" onClick={() => { setMenuIsOpen(prev => !prev); window.scrollTo({ top: 0 }) }}>
        <IoMdMenu size={42} />
      </div>
      <div className="flex-row gap-4 hidden sm:flex">
        <Link to="/" className="block px-4 py-2 account-link hover:text-middleblue">
          Accueil
        </Link>
        <Link to="/les-profils" className="block px-4 py-2 account-link hover:text-middleblue">
          Les profils
        </Link>
        <Link to="/les-opportunites" className="block px-4 py-2 account-link hover:text-middleblue">
          Les opportunités
        </Link>
        <Link to="/contact" className="block px-4 py-2 account-link hover:text-middleblue">
          Nous contacter
        </Link>
      </div>
      <div className="relative flex justify-end">
        <button className="relative z-10 w-12 h-12 rounded-full overflow-hidden border-4 border-gray-400 hover:border-gray-300 focus:border-gray-300 focus:outline-none" onClick={() => { display == false ? setDisplay(true) : setDisplay(false) }}>
          {dataUser && dataUser.fileUrl ?
            <img className='w-12 h-12 rounded-full object-cover' src={dataUser.fileUrl} />
            :
            <img
              className='w-12 h-12 rounded-full object-cover' src={ProfilNoPicture} width={100} alt="" srcSet=""
            />
          }
        </button>
        <div className={clsx("absolute w-32 bg-white rounded-lg shadow-lg py-2 mt-16 hidden", { "!block": display == true },)}>
          <Link to="/" className="block px-4 py-2 account-link hover:text-middleblue">
            Accueil
          </Link>
          {/* <a href="#" className="block px-4 py-2 account-link hover:text-middleblue">Support</a> */}
          <a href="#" onClick={() => { handleLogout() }} className="block px-4 py-2 account-link hover:text-middleblue">Déconnexion</a>
        </div>
      </div>
    </header>
  )
}
export default NavBarUser;