import { useState, useEffect } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import PrimaryButton from 'Components/Buttons/PrimaryButton'
import SecondaryButton from 'Components/Buttons/SecondaryButton'
import CreateOrEditExperience from 'Components/Experience/CreateOrEditExperience'
import IndexExperience from 'Components/Experience/IndexExperience'
import { v4 as uuidv4 } from 'uuid';






function Experiences() {

    const [data, setData, formations, setFormations, experiences, setExperiences] = useOutletContext()


    let experienceDefaultValue = {
        id: uuidv4(),
        dateEnd: "",
        date_end: "",
        dateStart: "",
        date_start: "",
        name: "",
        poste: "",
        inProgress: false,
        description: ""
    }



    const navigate = useNavigate();

    const [createMode, setCreateMode] = useState((experiences.length === 0))
    const [experience, setExperience] = useState(experienceDefaultValue)
    const [formIsNotValid, setFormIsNotValid] = useState(true)
    const maxCaracteres = 1500;


    useEffect(() => {
        setFormIsNotValid((experiences.length === 0))
    }, [experiences])




    const [experienceErr, setExperienceErr] = useState({
        posteErr: "",
        nameErr: "",
        dateStartErr: "",
        descriptionErr: "",
        dateErr: ""
    })

    const addExperience = () => {
        if (experienceFormValidation()) {
            setExperiences(state => state.filter(x => x.id !== experience.id))
            setExperiences(state => [...state, experience])
            setExperience(experienceDefaultValue)
            setCreateMode(false)
        }
    }


    const compareDate = (date1, date2) => {
        let startDate = new Date(date1)
        let endDate = new Date(date2)
        return Date.parse(startDate) < Date.parse(endDate)
    }

    const experienceFormValidation = () => {

        setExperienceErr({
            posteErr: "",
            nameErr: "",
            dateStartErr: "",
            descriptionErr: "",
            dateErr: ""
        })

        let newState = { ...experienceErr }

        let isValid = false

        if (experience.description.length > maxCaracteres) {
            newState.descriptionErr = `Votre présentation de doit pas excéder ${maxCaracteres} caractères`
            isValid = false
            setExperienceErr(newState)
            return
        }

        if (experience.name === "") {
            newState.nameErr = "Veuillez renseigner le nom de l'entreprise"
            isValid = false
            setExperienceErr(newState)
            return

        } else {
            newState.nameErr = ""
            isValid = true
        }

        if (experience.poste === "") {
            newState.posteErr = "Veuillez renseigner le poste occupé"
            isValid = false
            setExperienceErr(newState)
            return

        } else {
            newState.posteErr = ""
            isValid = true
        }

        if (experience.description === "") {
            newState.descriptionErr = "Veuillez remplir la présentation"
            isValid = false
            setExperienceErr(newState)
            return

        } else {
            newState.descriptionErr = ""

            isValid = true
        }

        if (experience.date_start === "") {
            newState.dateStartErr = "Veuillez renseigner la date de début"
            isValid = false
            setExperienceErr(newState)
            return

        } else {
            newState.dateStartErr = ""

            isValid = true
        }

        if (experience.date_end === "" && !experience.inProgress) {
            newState.dateEndErr = "Veuillez renseigner la date de fin"
            isValid = false
            setExperienceErr(newState)
            return
        } else {
            newState.dateEndErr = ""
        }

        if (experience.date_end !== "" && experience.data_start !== "" && !experience.inProgress) {
            if (!compareDate(experience.dateStart, experience.dateEnd)) {
                newState.dateErr = "La date de début doit être antérieur à la date de fin"
                isValid = false
                setExperienceErr(newState)
                return
            } else {
                newState.dateErr = ""
                isValid = true
            }
        }

        setExperienceErr(newState)


        return isValid
    }


    const selectExperience = (id) => {
        let selectedExperience = experiences.filter(x => x.id === id)[0]
        setExperience(selectedExperience)
        setCreateMode(true);

    }

    const deleteExperience = (id) => {
        let newData = experiences.filter(x => x.id !== id)
        setExperiences(newData)
    }

    return (
        <div className='flex-1 max-w-[750px] bg-white p-4'>
            <div className='flex flex-col sm:flex-row justify-between sm:items-center mb-[20px]'>
                <h2>Expérience *</h2>
                {
                    !createMode ?
                        <PrimaryButton onClick={() => setCreateMode(true)} text={"Nouvelle experience"} />
                        :
                        null
                }
            </div>
            {
                createMode ?
                    <>
                        <CreateOrEditExperience err={experienceErr} experience={experience} setExperience={setExperience} maxCaracteres={maxCaracteres} />
                        <div className='flex justify-end gap-2 flex-wrap bg-white pb-4 pr-4 mb-4'>
                            <SecondaryButton onClick={() => setCreateMode(false)} text={"Annuler"} />
                            <PrimaryButton onClick={() => addExperience()} text={"Sauvegarder"} />
                        </div>
                    </>
                    :

                    <>
                        <IndexExperience selectExperience={selectExperience} deleteExperience={deleteExperience} experiences={experiences} />
                        <div className='flex justify-between'>
                            <SecondaryButton onClick={() => navigate("/parcours-inscription/formations")} text={"précédent"} />
                            <PrimaryButton disabled={formIsNotValid} onClick={() => navigate("/parcours-inscription/supra")} text={"suivant"} />
                        </div>
                    </>
            }

        </div>
    )
}

export default Experiences