import React, { useState, useEffect, useContext } from "react";
import { Radar} from 'react-chartjs-2'
import axios from "axios";
import cookie from "js-cookie";
import { useParams } from "react-router-dom";

function UserSkill() {

	const { id } = useParams();
	const fetchId = id 
  const [data, setData] = useState([]);
  console.log(fetchId)
  const getUserSoftSkill = async () => {
    try {
        const softskill = await axios.get(`${process.env.REACT_APP_API}user/softskill/get/${fetchId}`)
        setData(softskill.data)
        console.log(softskill.data)
    } catch (error) {
        console.log(error)
    }
}
useEffect(() => {
  getUserSoftSkill()
},[])
    
      return (
        <>
           <div className='dataCard customerCard  h-max'>
              <Radar
              height={300}
              data={{
                labels: data.map((data) => data.label),
                datasets: [
                  {
                    label:"Communiquer",
                    data: data.map((data) => data.categorie ==  1 ?  data.value : "" ), 
                    backgroundColor:'rgba(3, 15, 48, 0.5)',
                    borderColor:"#030f30",
                    borderRadius: 10 ,
                },
                {
                    label:"Manager",
                    data: data.map((data) => data.categorie ==  2 ?  data.value : "" ), 
                    backgroundColor:'rgba(91, 158, 207, 0.5)',
                    borderColor:"#5b9ecf",
                    borderRadius: 10 ,
                },
                {
                    label:"Oser",
                    data: data.map((data) => data.categorie ==  3 ?  data.value : "" ), 
               
                    borderColor:"#A65E2E",
                    borderRadius: 10 ,
                },
                {
                    label:"S’adapter",
                    data: data.map((data) => data.categorie ==  4 ?  data.value : "" ), 
                    borderColor:"#497E76",
                    borderRadius: 10 ,
                },
                {
                    label:"Se dépasser",
                    data: data.map((data) => data.categorie ==  5 ?  data.value : "" ), 
                    backgroundColor:'rgba(184, 183, 153, 0.5)',

                    borderColor:"#B8B799",
                    borderRadius: 10 ,
                },
           
              ],
              }}
              />
           </div>
        </>
      )
}

export default UserSkill