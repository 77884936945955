import { ReactComponent as LogoValide } from "../../../../assets/img/LogoValide.svg";
export default function All() {
    return (
        <div className="px-4">
            <p className="font-bold text-justify">
            Vous représentez une entreprise ou un cabinet de conseil à la recherche de profils ?
                        <br />
                <br />
                Vous êtes étudiant, candidat ou consultant (Freelance / Manager de transition) à la recherche d'opportunités en finance ?                <br />
                <br />
            </p>

            <div className="flex mt-5">
                <p className="text-justify ">
                    Afin de bénéficier de toutes les fonctionnalités du  <i>hub</i>, nous vous invitons à créer votre profil.
                </p>
            </div>
            <p > <br/> Votre inscription est :</p>
            <div className="flex mt-8">
                <LogoValide className="shrink-0 h-8  p-1 bg-middleblue rounded-full mr-6" />
                <p className="text-justify ">Gratuite.</p>
            </div>
            <div className="flex mt-8">
                <LogoValide className="shrink-0 h-8 p-1 bg-middleblue rounded-full mr-6" />
                <p className="text-justify ">
                    Totalement confidentielle et pleinement sécurisée.
                </p>
            </div>
        </div>
    );
}
