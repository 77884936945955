
import { IoChevronDownOutline } from "react-icons/io5";

import inscription1 from "assets/img/Hub/Card4/Dossier de compétences 1.png";
import inscription2 from "assets/img/Hub/Card4/Dossier de compétences 2.png";

export default function Card4({ isActive, setActive }) {
  return (
    <div className="border rounded-[8px] border-[#2632384D] border-[4px] py-4 px-6 mt-16">
      <h1 onClick={() => setActive({ Question4: !isActive.Question4 })} className="font-semibold text-[#263238] cursor-pointer flex gap-2  "  >
        <div className="flex justify-between w-full items-center">
          <p className="text-[32px] text-black font-bold">Comment accéder au  <span className="text-[#5B9ECF]">dossier de compétences</span>  d’un profil ?</p>
          <div className={isActive.Question4 ? "rotate-180 transition duration-500" : "transition duration-500"}>
            <IoChevronDownOutline className="text-[#5B9ECF]" />
          </div>
        </div>
      </h1>
      <div className={isActive.Question4 ? "animation-box-visible" : "animation-box-hidden "} id="box1">
        <div className="pr-5 text-justify animation-content" id="content2">
        <div className="flex flex-row my-16 items-center justify-between "> 
                  <div>
                      <h3>1. Rendez-vous sur la page profil et sélectionnez le profil choisi ;</h3>
                      <br />
                      <h3 className="font-semibold">NB : Vous pouvez filtrer les profils selon différents critères (compétences, contrat recherché, localisation, etc.)</h3>
                  </div>
                  <div className="contents">
                      <img className="w-1/3" src={inscription1} alt="" />
                  </div>
              </div>
              <div className="flex flex-row my-16 items-center justify-between"> 
                  <div>
                      <h3>2. Cliquez sur le bouton “Dossier de compétences” pour télécharger le document</h3>
                      <br />
                      <h3 className="font-semibold">NB : Vous pouvez également partager ce dossier de compétences sur les réseaux sociaux ou par mail.</h3>
                  </div>
                  <div className="contents">
                      <img className="w-1/3" src={inscription2} alt="" />
                  </div>
              </div>
           
        </div>
      </div>
    </div>
  )
}