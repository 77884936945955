import io from 'socket.io-client';
import cookie from "js-cookie";
import jwt from "jwt-decode";

const socketMiddleware = (store) => {
    let socket;


  return (next) => (action) => {
    if (action.type === 'socket/connect') {

        const token = cookie.get("token");
        const decodedToken = jwt(token)
        const userId = decodedToken.id

        socket = io('http://localhost:4001',{ query: {userId} });

        socket.on('connect', () => {
            console.log('Socket connected',userId);
        });


        socket.on('disconnect', () => {
            console.log('Socket disconnected');
        });

        socket.on('apply', () => {
            alert("someone apply")
        });
    }


    if (action.type === 'socket/apply') {
        socket.emit('apply', action.payload);
    }

    // if (action.type === 'socket/disconnect') {
    //   socket.disconnect();
    // }

    return next(action);
  };
};

export default socketMiddleware;