import { useState } from "react";
import { FaFile } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import { FaExternalLinkAlt } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Retenir from "./popup/Retenir";
import Refuser from "./popup/Refuser";
import Replanifier from "./popup/Replanifier";

export default function AppointmentRealiser(props) {
  const [popupRetenir, setPopupRetenir] = useState(false);
  const [popupRefuser, setPopupRefuser] = useState(false);
  const [popupReplanifier, setPopupReplanifier] = useState(false);
  const appointment = props.data;
  const navigate = useNavigate()

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}h${formattedMinutes}`;
  };

  function openRetenir() {
    setPopupRetenir(true)
  }
  function openRefuser() {
    setPopupRefuser(true)
  }

  return (
    <>
      <button onClick={() => navigate("/mon-espace/mes-entretiens")} className="block text-[#2563EB] font-extrabold hover:underline hover:cursor-pointer">{"<"} Retour</button>
      <span className='text-[#00C455] bg-[#DCFCE6] inline-block p-2 rounded-full my-2'>
        <IoIosCheckmarkCircle size={28} />
      </span>
      <p className="font-medium">Entretien réalisé {appointment.apply && " suite à votre candidature"}</p>
      {appointment &&
        <p className="text-2xl font-extrabold mb-4">{new Date(appointment.date_choice).toLocaleDateString("fr-FR", {
          weekday: "long",
          day: "2-digit",
          month: "long",
          year: "numeric",
        })} à {formatTime(new Date(appointment.date_choice))}</p>
      }
      {appointment.offre &&
        <div className="flex gap-2 text-[#B7B7B7] items-center">
          <FaFile />
          <p className="font-medium w-[120px]">Poste à pouvoir</p>
          <a className="text-black font-medium flex items-center gap-2 underline" href={`/les-opportunites/${appointment.offre.id}`} target="_blank">{appointment.offre.intitule}
            <FaExternalLinkAlt />
          </a>
        </div>
      }
      <div className="flex gap-2 text-[#B7B7B7] items-center">
        {appointment.id_client &&
          <>
            <FaBuilding />
            <p className="font-medium w-[120px]">Entreprise</p>
            <p className="text-[#0F6FDE] font-medium">{appointment.id_client.company}</p>
          </>
        }
        {appointment.id_user &&
          <>
            <FaUser />
            <p className="font-medium w-[120px]">Profil</p>
            <a className="text-[#0F6FDE] font-medium flex items-center gap-2 underline" href={`/dossier-de-competences/${appointment.id_user.id}`} target="_blank">{appointment.id_user.first_name + " " + appointment.id_user.last_name}
              <FaExternalLinkAlt />
            </a>
          </>
        }
      </div>

      {appointment.id_client &&
        <div className="flex gap-2 my-8">
          <p className="text-xl">En attente d'une réponse de la part de <span className="text-[#0F6FDE] font-medium">{appointment.id_client.company}</span> {appointment.apply && " suite à votre candidature"} </p>
        </div>
      }

      {appointment.id_user &&
        <div className="flex flex-col min-w-[225px] w-[50%] mx-auto gap-2 mt-12">
          <div onClick={openRetenir} className="w-full text-center bg-[#DCFCE6] hover:bg-[#C5F7D3] text-[#00C455] py-2 rounded-[5px] hover:cursor-pointer">
            Retenir ce profil
          </div>
          <div onClick={openRefuser} className="w-full text-center bg-[#FFCBB8] hover:bg-[#FFBBA3] text-[#E0182D] py-2 rounded-[5px] hover:cursor-pointer">
            Refuser ce profil
          </div>
          <div onClick={() => { setPopupReplanifier(true) }} className="w-full text-center bg-[#FFF2CC] hover:bg-[#FFEAB2] text-[#FF8225] py-2 rounded-[5px] hover:cursor-pointer">
            Replanfier un nouvel entretien
          </div>
        </div>
      }
      {/* Popup */}
      {popupReplanifier && <Replanifier appointment={appointment} setPopupReplanifier={setPopupReplanifier} getAppoitment={props.getAppoitment} />}
      {popupRetenir && <Retenir appointment={appointment} setPopupRetenir={setPopupRetenir} getAppoitment={props.getAppoitment} />}
      {popupRefuser && <Refuser appointment={appointment} setPopupRefuser={setPopupRefuser} getAppoitment={props.getAppoitment} />}
    </>
  )
}