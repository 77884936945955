//DEpendances
import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
//Import des icons
import { BiMicrophone } from "react-icons/bi";
import { BiMicrophoneOff } from "react-icons/bi";
import { BsCameraVideo } from "react-icons/bs";
import { BsCameraVideoOff } from "react-icons/bs";
import { LuScreenShare } from "react-icons/lu";
import { LuScreenShareOff } from "react-icons/lu";
import { ImPhoneHangUp } from "react-icons/im";


function Buttons(props) {
  const { toggleCamera, toggleMicrophone, startScreenshare, stopScreenshare } = props.actions;
  const { screenshares } = props.state;
  const redirection = useNavigate()
  const { id } = useParams();


  return (
    <div className='fixed left-[50vw] -translate-x-2/4 bottom-[2%] flex justify-center gap-4 lg:gap-12'>
      {props.localParticipant?.isAudioEnabled ?
        <div className="bg-[#D1E6F4] hover:cursor-pointer hover:bg-[#BED0DB] p-2 w-[60px] h-[60px] flex justify-center items-center rounded-full" onClick={() => {
          toggleMicrophone();
        }}>
          <BiMicrophone color={"#5A9ECF"} size={40} />
        </div>
        :
        <div className="bg-[#FFB9B9] hover:cursor-pointer hover:bg-[#E2A5A5] p-2 w-[60px] h-[60px] flex justify-center items-center rounded-full" onClick={() => {
          toggleMicrophone();
        }}>
          <BiMicrophoneOff color={"#FF0000"} size={40} />
        </div>
      }

      {props.localMedia.state.currentCameraDeviceId ?
        props.localParticipant?.isVideoEnabled ?
          <div className="bg-[#D1E6F4] hover:cursor-pointer hover:bg-[#BED0DB] p-2 w-[60px] h-[60px] flex justify-center items-center rounded-full" onClick={() => {
            toggleCamera();
          }}>
            <BsCameraVideo color={"#5A9ECF"} size={40} />
          </div>
          :
          <div className="bg-[#FFB9B9] hover:cursor-pointer hover:bg-[#E2A5A5] p-2 w-[60px] h-[60px] flex justify-center items-center rounded-full" onClick={() => {
            toggleCamera();
          }}>
            <BsCameraVideoOff color={"#FF0000"} size={40} />
          </div>
        :
        <div className="hover:cursor-pointer bg-[#AFAFAF] p-2 w-[60px] h-[60px] flex justify-center items-center rounded-full opacity-70">
          <BsCameraVideoOff color={"#000000"} size={40} />
        </div>
      }
      {screenshares.filter((item) => item.isLocal === false).length > 0 ?
        <div className="hover:cursor-pointer bg-[#AFAFAF] p-2 w-[60px] h-[60px] flex justify-center items-center rounded-full opacity-70">
          <LuScreenShareOff color={"#000000"} size={40} />
        </div>
        :
        props.isLocalScreenActive ?
          <div className="bg-[#FFB9B9] hover:cursor-pointer hover:bg-[#E2A5A5] p-2 w-[60px] h-[60px] flex justify-center items-center rounded-full" onClick={() => {
            stopScreenshare();
          }}>
            <LuScreenShareOff color={"#FF0000"} size={40} />
          </div>
          :
          <div className="bg-[#D1E6F4] hover:cursor-pointer hover:bg-[#BED0DB] p-2 w-[60px] h-[60px] flex justify-center items-center rounded-full" onClick={() => {
            startScreenshare();
          }}>
            <LuScreenShare color={"#5A9ECF"} size={40} />
          </div>
      }
      <div className="bg-[#FFB9B9] hover:cursor-pointer hover:bg-[#E2A5A5] p-2 w-[60px] h-[60px] flex justify-center items-center rounded-full" onClick={() => {
        redirection("/mon-espace/mes-entretiens/" + id)
      }}>
        <ImPhoneHangUp color={"#FF0303"} size={40} />
      </div>
    </div>
  );
}

export default Buttons;