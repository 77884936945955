import { Svg, Path } from '@react-pdf/renderer';

const DateTime = () => (
  <Svg
    width="18"
    height="18"
    viewBox="0 0 21 21"
    fill="#263238"
    xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M18.813 3.5H16.9171V4.66667H18.6671V17.5H2.33381V4.66667H4.08381V3.5H2.18798C2.05161 3.50228 1.91704 3.5314 1.79193 3.5857C1.66683 3.63999 1.55364 3.71841 1.45884 3.81645C1.36405 3.9145 1.28949 4.03026 1.23944 4.15713C1.18939 4.28399 1.16482 4.41947 1.16714 4.55583V17.6108C1.16482 17.7472 1.18939 17.8827 1.23944 18.0095C1.28949 18.1364 1.36405 18.2522 1.45884 18.3502C1.55364 18.4483 1.66683 18.5267 1.79193 18.581C1.91704 18.6353 2.05161 18.6644 2.18798 18.6667H18.813C18.9493 18.6644 19.0839 18.6353 19.209 18.581C19.3341 18.5267 19.4473 18.4483 19.5421 18.3502C19.6369 18.2522 19.7115 18.1364 19.7615 18.0095C19.8116 17.8827 19.8361 17.7472 19.8338 17.6108V4.55583C19.8361 4.41947 19.8116 4.28399 19.7615 4.15713C19.7115 4.03026 19.6369 3.9145 19.5421 3.81645C19.4473 3.71841 19.3341 3.63999 19.209 3.5857C19.0839 3.5314 18.9493 3.50228 18.813 3.5Z"
      fill="black" />
    <Path
      d="M4.66699 8.16699H5.83366V9.33366H4.66699V8.16699Z"
      fill="black" />
    <Path
      d="M8.16699 8.16699H9.33366V9.33366H8.16699V8.16699Z"
      fill="black" />
    <Path
      d="M11.667 8.16699H12.8337V9.33366H11.667V8.16699Z"
      fill="black" />
    <Path
      d="M15.167 8.16699H16.3337V9.33366H15.167V8.16699Z"
      fill="black" />
    <Path
      d="M4.66699 11.083H5.83366V12.2497H4.66699V11.083Z"
      fill="black" />
    <Path
      d="M8.16699 11.083H9.33366V12.2497H8.16699V11.083Z"
      fill="black" />
    <Path
      d="M11.667 11.083H12.8337V12.2497H11.667V11.083Z"
      fill="black" />
    <Path
      d="M15.167 11.083H16.3337V12.2497H15.167V11.083Z"
      fill="black" />
    <Path
      d="M4.66699 14H5.83366V15.1667H4.66699V14Z"
      fill="black" />
    <Path
      d="M8.16699 14H9.33366V15.1667H8.16699V14Z"
      fill="black" />
    <Path
      d="M11.667 14H12.8337V15.1667H11.667V14Z"
      fill="black" />
    <Path
      d="M15.167 14H16.3337V15.1667H15.167V14Z"
      fill="black" />
    <Path
      d="M5.83333 5.83366C5.98804 5.83366 6.13642 5.7722 6.24581 5.6628C6.35521 5.55341 6.41667 5.40504 6.41667 5.25033V1.75033C6.41667 1.59562 6.35521 1.44724 6.24581 1.33785C6.13642 1.22845 5.98804 1.16699 5.83333 1.16699C5.67862 1.16699 5.53025 1.22845 5.42085 1.33785C5.31146 1.44724 5.25 1.59562 5.25 1.75033V5.25033C5.25 5.40504 5.31146 5.55341 5.42085 5.6628C5.53025 5.7722 5.67862 5.83366 5.83333 5.83366Z"
      fill="black" />
    <Path
      d="M15.1663 5.83366C15.3211 5.83366 15.4694 5.7722 15.5788 5.6628C15.6882 5.55341 15.7497 5.40504 15.7497 5.25033V1.75033C15.7497 1.59562 15.6882 1.44724 15.5788 1.33785C15.4694 1.22845 15.3211 1.16699 15.1663 1.16699C15.0116 1.16699 14.8633 1.22845 14.7539 1.33785C14.6445 1.44724 14.583 1.59562 14.583 1.75033V5.25033C14.583 5.40504 14.6445 5.55341 14.7539 5.6628C14.8633 5.7722 15.0116 5.83366 15.1663 5.83366Z"
      fill="black" />
    <Path
      d="M7.58301 3.5H13.4163V4.66667H7.58301V3.5Z"
      fill="black" />
  </Svg>
)

export default DateTime