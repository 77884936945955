import React from 'react'
import { createContext } from "react";
import { useState, useMemo } from 'react';

export const CardContext = createContext();

export const CardProvider = ({ children }) => {


    const [ContextIdCard, setContextIdCard] = useState(null);
    const [entreprise, setEntreprise] = useState("Nom entreprise");

    const CardContextValue = useMemo (() => ({
        entreprise,
        setEntreprise,
        ContextIdCard,
        setContextIdCard
    }),
    [
        entreprise,
        setEntreprise,
        ContextIdCard,
        setContextIdCard
    ]);
 
    return (
        <CardContext.Provider value={{CardContextValue}}>
            {children}
        </CardContext.Provider>
    )
}
