import React from 'react'

function ConsultantSvg(props) {
    return (
        <>
            <svg className={props.classProps ? props.classProps : "34"} width={props.width ? props.width : "34"} height={props.width ? props.width : "34"} viewBox="0 0 537 400" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M212.088 150.251C287.132 128.22 316.571 -35.6892 425.172 51.4272C502.211 113.232 451.481 268.457 353.676 252.322C294.487 242.556 303.674 241.269 252.805 271.566C201.26 302.324 144.826 318.255 91.3188 266.549C63.6272 239.747 53.7964 173.493 81.8203 138.554C116.856 94.8675 166.246 163.705 212.088 150.251Z" fill="#5B9ECF" />
                <path opacity="0.9" d="M212.088 150.251C287.132 128.22 316.571 -35.6892 425.172 51.4272C502.211 113.232 451.481 268.457 353.676 252.322C294.487 242.556 303.674 241.269 252.805 271.566C201.26 302.324 144.826 318.255 91.3188 266.549C63.6272 239.747 53.7964 173.493 81.8203 138.554C116.856 94.8675 166.246 163.705 212.088 150.251Z" fill="white" />
                <path d="M268.017 399.41C382.817 399.41 475.881 393.977 475.881 387.275C475.881 380.572 382.817 375.139 268.017 375.139C153.217 375.139 60.1537 380.572 60.1537 387.275C60.1537 393.977 153.217 399.41 268.017 399.41Z" fill="#F5F5F5" />
                <path d="M263.161 193.091L264.962 195.321L93.7205 331.72L101.021 340.758L102.018 341.99L273.26 205.591L275.061 207.821L278.267 193.058L263.161 193.091Z" fill="#5B9ECF" />
                <path opacity="0.7" d="M263.161 193.091L264.962 195.321L93.7205 331.72L101.021 340.758L102.018 341.99L273.26 205.591L275.061 207.821L278.267 193.058L263.161 193.091Z" fill="white" />
                <path d="M68.5162 341.261C68.519 339.767 69.1141 338.334 70.171 337.277C71.2279 336.22 72.6606 335.625 74.1553 335.622C74.7043 335.621 75.2503 335.704 75.7741 335.869C75.6878 335.294 75.6448 334.713 75.6455 334.132C75.6455 332.564 75.9545 331.012 76.5548 329.563C77.1551 328.115 78.035 326.799 79.1441 325.691C80.2532 324.583 81.5699 323.704 83.0188 323.105C84.4677 322.506 86.0205 322.199 87.5883 322.2C88.3079 322.2 89.0258 322.268 89.7325 322.404C90.9172 320.491 92.6886 319.012 94.7826 318.189C96.8765 317.365 99.1806 317.24 101.351 317.834C103.522 318.427 105.442 319.706 106.826 321.48C108.21 323.255 108.983 325.429 109.03 327.678H109.351C110.68 327.569 112.017 327.737 113.278 328.171C114.539 328.605 115.696 329.296 116.677 330.199C117.657 331.103 118.44 332.2 118.975 333.421C119.511 334.642 119.787 335.961 119.787 337.295C119.787 338.628 119.511 339.947 118.975 341.168C118.44 342.39 117.657 343.487 116.677 344.39C115.696 345.294 114.539 345.984 113.278 346.418C112.017 346.852 110.68 347.02 109.351 346.911H73.973C72.5065 346.866 71.1154 346.251 70.0961 345.196C69.0768 344.14 68.5099 342.729 68.5162 341.261Z" fill="#5B9ECF" />
                <path opacity="0.8" d="M68.5162 341.261C68.519 339.767 69.1141 338.334 70.171 337.277C71.2279 336.22 72.6606 335.625 74.1553 335.622C74.7043 335.621 75.2503 335.704 75.7741 335.869C75.6878 335.294 75.6448 334.713 75.6455 334.132C75.6455 332.564 75.9545 331.012 76.5548 329.563C77.1551 328.115 78.035 326.799 79.1441 325.691C80.2532 324.583 81.5699 323.704 83.0188 323.105C84.4677 322.506 86.0205 322.199 87.5883 322.2C88.3079 322.2 89.0258 322.268 89.7325 322.404C90.9172 320.491 92.6886 319.012 94.7826 318.189C96.8765 317.365 99.1806 317.24 101.351 317.834C103.522 318.427 105.442 319.706 106.826 321.48C108.21 323.255 108.983 325.429 109.03 327.678H109.351C110.68 327.569 112.017 327.737 113.278 328.171C114.539 328.605 115.696 329.296 116.677 330.199C117.657 331.103 118.44 332.2 118.975 333.421C119.511 334.642 119.787 335.961 119.787 337.295C119.787 338.628 119.511 339.947 118.975 341.168C118.44 342.39 117.657 343.487 116.677 344.39C115.696 345.294 114.539 345.984 113.278 346.418C112.017 346.852 110.68 347.02 109.351 346.911H73.973C72.5065 346.866 71.1154 346.251 70.0961 345.196C69.0768 344.14 68.5099 342.729 68.5162 341.261Z" fill="white" />
                <path d="M427.488 279.499L431.766 284.795L352.165 349.087L369.468 370.507L371.837 373.445L451.428 309.142L455.705 314.438L463.317 279.424L427.488 279.499Z" fill="#5B9ECF" />
                <path opacity="0.5" d="M427.488 279.499L431.766 284.795L352.165 349.087L369.468 370.507L371.837 373.445L451.428 309.142L455.705 314.438L463.317 279.424L427.488 279.499Z" fill="white" />
                <path d="M384.455 346.783C382.813 344.148 380.522 341.979 377.802 340.483C375.082 338.988 372.023 338.216 368.918 338.242C365.814 338.268 362.769 339.091 360.073 340.631C357.378 342.171 355.124 344.378 353.526 347.04C352.079 346.616 350.554 346.537 349.071 346.809C347.589 347.081 346.19 347.697 344.989 348.607C343.787 349.517 342.815 350.696 342.151 352.049C341.487 353.402 341.149 354.892 341.165 356.399H336.04C335.383 356.368 334.726 356.471 334.109 356.701C333.492 356.932 332.929 357.285 332.452 357.739C331.976 358.194 331.597 358.74 331.338 359.346C331.078 359.951 330.945 360.602 330.945 361.261C330.945 361.919 331.078 362.571 331.338 363.176C331.597 363.781 331.976 364.328 332.452 364.782C332.929 365.237 333.492 365.59 334.109 365.82C334.726 366.051 335.383 366.154 336.04 366.123H353.783C355.248 368.436 357.217 370.387 359.544 371.831C361.87 373.275 364.493 374.173 367.216 374.459C369.939 374.745 372.691 374.41 375.266 373.481C377.841 372.551 380.173 371.051 382.086 369.092C382.393 369.109 382.701 369.109 383.008 369.092C385.862 369.098 388.611 368.017 390.697 366.069C392.783 364.12 394.05 361.452 394.239 358.604C394.429 355.756 393.528 352.943 391.718 350.735C389.909 348.528 387.328 347.091 384.498 346.718L384.455 346.783Z" fill="#5B9ECF" />
                <path opacity="0.8" d="M384.455 346.783C382.813 344.148 380.522 341.979 377.802 340.483C375.082 338.988 372.023 338.216 368.918 338.242C365.814 338.268 362.769 339.091 360.073 340.631C357.378 342.171 355.124 344.378 353.526 347.04C352.079 346.616 350.554 346.537 349.071 346.809C347.589 347.081 346.19 347.697 344.989 348.607C343.787 349.517 342.815 350.696 342.151 352.049C341.487 353.402 341.149 354.892 341.165 356.399H336.04C335.383 356.368 334.726 356.471 334.109 356.701C333.492 356.932 332.929 357.285 332.452 357.739C331.976 358.194 331.597 358.74 331.338 359.346C331.078 359.951 330.945 360.602 330.945 361.261C330.945 361.919 331.078 362.571 331.338 363.176C331.597 363.781 331.976 364.328 332.452 364.782C332.929 365.237 333.492 365.59 334.109 365.82C334.726 366.051 335.383 366.154 336.04 366.123H353.783C355.248 368.436 357.217 370.387 359.544 371.831C361.87 373.275 364.493 374.173 367.216 374.459C369.939 374.745 372.691 374.41 375.266 373.481C377.841 372.551 380.173 371.051 382.086 369.092C382.393 369.109 382.701 369.109 383.008 369.092C385.862 369.098 388.611 368.017 390.697 366.069C392.783 364.12 394.05 361.452 394.239 358.604C394.429 355.756 393.528 352.943 391.718 350.735C389.909 348.528 387.328 347.091 384.498 346.718L384.455 346.783Z" fill="white" />
                <path d="M264.951 129.238L263.408 127.576C263.203 127.361 262.948 127.201 262.664 127.111C262.381 127.021 262.08 127.004 261.789 127.062L259.441 127.576C259.074 127.649 258.739 127.835 258.483 128.108C258.228 128.381 258.064 128.728 258.015 129.099L256.836 138.747L260.417 140.13C264.791 138.222 264.565 132.454 264.565 132.454L265.187 131.307C265.372 130.982 265.448 130.606 265.406 130.234C265.364 129.862 265.204 129.514 264.951 129.238Z" fill="#FFB573" />
                <path d="M221.672 158.28C221.672 158.28 225.113 158.484 227.129 158.441C229.144 158.398 231.181 158.377 233.164 158.259C235.148 158.141 237.11 157.991 238.911 157.744C239.734 157.634 240.55 157.477 241.355 157.273L242.127 157.026C242.363 156.919 242.127 157.101 242.234 157.026C242.792 156.469 243.311 155.874 243.789 155.247C244.378 154.496 244.968 153.692 245.558 152.877C246.737 151.237 247.895 149.532 249.063 147.817L256.064 137.429L261.542 140.334C260.738 142.339 259.859 144.269 258.991 146.22C258.122 148.171 257.157 150.079 256.214 151.998C255.271 153.917 254.241 155.825 253.148 157.734C252.601 158.688 252.076 159.642 251.411 160.618C250.714 161.741 249.917 162.799 249.031 163.78C248.316 164.525 247.488 165.151 246.576 165.635C245.886 166.005 245.169 166.324 244.432 166.589C243.229 167.032 241.997 167.391 240.744 167.661C238.508 168.167 236.241 168.525 233.958 168.733C229.588 169.178 225.195 169.35 220.803 169.248L221.672 158.28Z" fill="#5B9ECF" />
                <path opacity="0.7" d="M261.585 140.355C260.781 142.35 259.912 144.279 259.044 146.23C258.176 148.182 257.211 150.09 256.257 152.02C255.303 153.949 254.295 155.836 253.201 157.744C252.655 158.699 252.129 159.653 251.454 160.628C250.765 161.754 249.971 162.813 249.085 163.791C248.352 164.536 247.505 165.158 246.576 165.635C245.886 166.01 245.17 166.332 244.432 166.6C243.231 167.037 242.002 167.395 240.755 167.672C238.517 168.172 236.251 168.53 233.968 168.744C233.014 168.84 232.071 168.915 231.138 168.99C227.697 169.237 224.309 169.323 220.814 169.248L220.986 166.996L221.661 158.345C221.661 158.345 225.092 158.559 227.107 158.506C229.123 158.452 231.16 158.441 233.154 158.323C235.148 158.205 237.099 158.066 238.9 157.809C239.724 157.707 240.541 157.549 241.344 157.337C241.607 157.272 241.865 157.19 242.116 157.09C242.352 156.983 242.116 157.176 242.223 157.09C242.781 156.533 243.301 155.939 243.778 155.311C244.367 154.56 244.957 153.756 245.536 152.942C246.715 151.301 247.884 149.597 249.052 147.881L256.053 137.493L261.585 140.355Z" fill="white" />
                <path opacity="0.1" d="M231.149 168.926C227.708 169.173 224.32 169.258 220.825 169.183L220.996 166.932L221.833 164.959C225.334 165.029 228.672 166.451 231.149 168.926Z" fill="black" />
                <path d="M164.338 350.567C164.338 350.567 154.689 343.888 155.225 339.407C155.245 339.303 155.243 339.197 155.22 339.095C155.197 338.992 155.152 338.895 155.09 338.811C155.028 338.726 154.948 338.655 154.857 338.603C154.766 338.551 154.665 338.518 154.56 338.506L146.338 337.263C146.137 337.224 145.929 337.244 145.739 337.32C145.549 337.396 145.385 337.525 145.265 337.691L140.441 344.542L142.585 345.324L143.754 344.51L144.311 345.957C156.158 350.245 161.325 351.971 163.941 352.079C164.713 351.157 164.338 350.567 164.338 350.567Z" fill="black" />
                <path d="M218.992 224.491C218.992 224.491 218.499 225.778 217.63 228.008C216.698 230.42 215.325 233.936 213.674 238.117C212.602 240.905 211.348 243.992 210.029 247.241C203.972 262.25 196.146 280.915 190.968 290.317C181.051 308.306 155.214 342.473 155.214 342.473L144.89 338.699C144.89 338.699 163.48 303.514 172.861 285.685C176.417 278.486 179.552 271.087 182.252 263.526C183.806 259.377 185.35 255.056 186.84 250.779C187.516 248.849 188.18 246.919 188.824 245.022C190.528 240.004 192.126 235.169 193.53 230.86C193.702 230.324 193.873 229.787 194.045 229.273C194.613 227.504 195.117 225.832 195.631 224.288C197.647 217.941 198.901 213.76 198.944 213.567L208.153 218.584L218.992 224.491Z" fill="#263238" />
                <path opacity="0.3" d="M217.63 228.008C216.697 230.42 215.325 233.936 213.674 238.117C212.602 240.905 211.348 243.992 210.029 247.241C201.603 248.935 193.026 250.082 186.862 250.779C187.537 248.849 188.202 246.919 188.845 245.022C190.549 240.004 192.147 235.169 193.551 230.86C193.723 230.323 193.894 229.787 194.066 229.273C194.634 227.504 195.138 225.832 195.653 224.288C201.849 225.135 210.125 226.4 217.63 228.008Z" fill="black" />
                <path d="M186.004 164.027C187.488 169.417 188.44 174.939 188.845 180.515C189.22 184.964 189.36 189.338 189.381 193.273C189.402 197.207 189.274 200.724 189.145 203.404C188.974 206.856 188.77 208.968 188.77 208.968L221.339 210.651C231.117 175.273 221.661 158.291 221.661 158.291C218.76 158.037 215.845 157.969 212.934 158.087C212.334 158.087 211.712 158.152 211.09 158.205C206.188 158.769 201.323 159.621 196.521 160.757C195.653 160.95 194.774 161.175 193.916 161.4C189.671 162.558 186.004 164.027 186.004 164.027Z" fill="#5B9ECF" />
                <path opacity="0.7" d="M221.35 210.64L188.781 208.968C188.781 208.968 188.984 206.824 189.156 203.393C189.156 202.385 189.242 201.249 189.285 199.995C189.349 197.99 189.392 195.706 189.392 193.262C189.392 192.072 189.392 190.839 189.327 189.574C189.327 189.37 189.327 189.167 189.327 188.963C189.274 186.958 189.177 184.879 189.038 182.767C188.974 182.016 188.92 181.266 188.856 180.515C188.856 179.926 188.759 179.325 188.706 178.736C188.545 177.235 188.373 175.734 188.159 174.254C188.105 173.847 188.041 173.44 187.977 173.043C187.88 172.367 187.773 171.713 187.655 171.049C187.239 168.673 186.695 166.322 186.025 164.005C186.025 164.005 189.703 162.569 193.959 161.4C194.806 161.175 195.685 160.95 196.553 160.757C201.357 159.628 206.221 158.776 211.123 158.205C211.755 158.205 212.366 158.109 212.977 158.098C215.887 157.974 218.802 158.038 221.704 158.291C221.704 158.291 231.127 175.208 221.35 210.64Z" fill="white" />
                <path d="M241.494 295.398C241.494 295.398 232.189 288.173 233.046 283.734C233.071 283.632 233.075 283.526 233.058 283.422C233.04 283.318 233.002 283.219 232.945 283.13C232.887 283.042 232.813 282.966 232.725 282.908C232.637 282.85 232.539 282.81 232.435 282.791L224.288 281.075C224.092 281.031 223.887 281.043 223.698 281.109C223.508 281.176 223.341 281.294 223.215 281.451L217.973 287.98L220.117 288.891L221.329 288.14L221.8 289.609C233.389 294.573 238.45 296.599 241.098 296.856C241.837 295.977 241.494 295.398 241.494 295.398Z" fill="black" />
                <path d="M204.358 238.31H205.119L244.174 243.992L221.918 280.882L233.046 285.171C233.046 285.171 273.302 241.773 264.308 231.878C256.911 223.741 220.675 218.788 220.675 218.788L221.865 210.297L188.373 203.693C174.04 235.019 199.565 238 204.358 238.31Z" fill="#263238" />
                <path d="M187.977 206.985L187.269 206.845L187.912 204.594L188.545 204.712L187.977 206.985Z" fill="#263238" />
                <path d="M214.339 212.088L200.027 209.322L200.584 207.049L214.843 209.815L214.339 212.088Z" fill="#263238" />
                <path d="M215.947 210.029L221.993 211.198L221.586 213.492L215.465 212.302L215.947 210.029Z" fill="#263238" />
                <path d="M189.649 204.926L199.491 206.834L198.912 209.097L189.102 207.199L189.649 204.926Z" fill="#263238" />
                <g opacity="0.2">
                    <path d="M187.977 206.985L187.269 206.845L187.912 204.594L188.545 204.712L187.977 206.985Z" fill="white" />
                    <path d="M214.339 212.088L200.027 209.322L200.584 207.049L214.843 209.815L214.339 212.088Z" fill="white" />
                    <path d="M215.947 210.029L221.993 211.198L221.586 213.492L215.465 212.302L215.947 210.029Z" fill="white" />
                    <path d="M189.649 204.926L199.491 206.834L198.912 209.096L189.102 207.199L189.649 204.926Z" fill="white" />
                </g>
                <path d="M209.632 211.423H209.504L205.923 210.78C205.823 210.764 205.727 210.727 205.642 210.672C205.557 210.616 205.485 210.543 205.43 210.458C205.373 210.374 205.334 210.279 205.318 210.179C205.301 210.079 205.306 209.977 205.333 209.879L205.687 208.443C205.731 208.258 205.845 208.097 206.005 207.995C206.165 207.893 206.359 207.857 206.545 207.896L210.093 208.582C210.192 208.598 210.286 208.635 210.37 208.69C210.453 208.746 210.523 208.818 210.576 208.904C210.63 208.986 210.667 209.08 210.683 209.178C210.7 209.275 210.696 209.376 210.672 209.472L210.351 210.855C210.312 211.016 210.22 211.16 210.09 211.263C209.96 211.366 209.798 211.423 209.632 211.423ZM206.416 208.496C206.392 208.497 206.368 208.506 206.349 208.522C206.33 208.537 206.316 208.558 206.309 208.582L205.955 210.019C205.949 210.032 205.946 210.047 205.946 210.061C205.946 210.076 205.949 210.091 205.955 210.104C205.967 210.109 205.98 210.112 205.993 210.112C206.006 210.112 206.018 210.109 206.03 210.104L209.611 210.748C209.639 210.749 209.666 210.742 209.69 210.726C209.713 210.711 209.73 210.688 209.74 210.662L210.061 209.279C210.068 209.265 210.071 209.251 210.071 209.236C210.071 209.221 210.068 209.206 210.061 209.193C210.051 209.187 210.04 209.184 210.029 209.184C210.018 209.184 210.007 209.187 209.997 209.193L206.448 208.496H206.416Z" fill="white" />
                <path d="M207.403 209.826H207.349L206.277 209.633C206.236 209.626 206.196 209.611 206.161 209.59C206.126 209.568 206.095 209.539 206.071 209.505C206.047 209.471 206.03 209.432 206.021 209.392C206.013 209.351 206.012 209.309 206.02 209.268C206.025 209.226 206.038 209.186 206.06 209.15C206.081 209.114 206.11 209.083 206.145 209.058C206.179 209.034 206.218 209.017 206.259 209.009C206.301 209.001 206.343 209.001 206.384 209.011L207.456 209.204C207.497 209.21 207.537 209.225 207.572 209.247C207.607 209.269 207.638 209.298 207.662 209.332C207.686 209.366 207.703 209.404 207.712 209.445C207.721 209.485 207.721 209.527 207.713 209.568C207.699 209.64 207.66 209.705 207.603 209.752C207.547 209.799 207.476 209.825 207.403 209.826Z" fill="white" />
                <path d="M199.909 145.962L208.41 153.017C207.585 156.469 209.032 159.653 210.705 160.242C210.705 160.242 211.948 163.201 209.558 163.694C204.701 164.702 199.169 160.007 199.169 160.007C201.742 157.251 200.809 150.369 199.909 145.962Z" fill="#EBB376" />
                <path opacity="0.2" d="M203.371 148.836L208.41 153.006C208.197 153.807 208.053 154.625 207.981 155.45C206.094 155.204 203.489 153.188 203.275 151.216C203.168 150.423 203.2 149.617 203.371 148.836Z" fill="black" />
                <path d="M207.424 132.025C209.482 133.312 212.27 137.182 208.807 138.115C205.344 139.047 203.875 129.817 207.424 132.025Z" fill="#263238" />
                <path d="M196.789 145.255C200.391 149.993 202.257 152.963 206.438 153.831C212.731 155.118 217.298 148.707 215.186 142.961C213.277 137.793 207.681 130.9 201.581 132.24C200.23 132.534 198.976 133.164 197.933 134.072C196.89 134.98 196.093 136.136 195.615 137.433C195.138 138.73 194.995 140.127 195.2 141.494C195.405 142.861 195.952 144.155 196.789 145.255Z" fill="#FFB573" />
                <path d="M197.85 140.077L193.562 135.038C193.562 135.038 187.848 139.24 190.442 141.781C188.738 145.801 195.524 151.43 201.463 150.272C202.16 146.638 196.864 140.441 196.864 140.441C197.199 140.336 197.528 140.214 197.85 140.077Z" fill="#263238" />
                <path d="M197.625 150.647C198.204 151.109 198.869 151.451 199.581 151.654C200.294 151.856 201.039 151.915 201.774 151.827C203.736 151.58 203.918 149.618 202.793 148.149C201.667 146.681 199.426 145.373 197.754 146.359C197.395 146.585 197.097 146.895 196.887 147.263C196.676 147.631 196.559 148.045 196.547 148.469C196.534 148.893 196.626 149.313 196.814 149.693C197.002 150.073 197.281 150.401 197.625 150.647Z" fill="#FFB573" />
                <path d="M205.183 141.513C205.451 141.91 205.891 142.092 206.148 141.921C206.405 141.749 206.405 141.267 206.148 140.849C205.891 140.43 205.451 140.27 205.183 140.441C204.915 140.613 204.915 141.149 205.183 141.513Z" fill="#263238" />
                <path d="M209.718 138.469C209.997 138.876 210.426 139.058 210.683 138.876C210.94 138.694 210.94 138.222 210.683 137.804C210.426 137.386 209.986 137.214 209.718 137.397C209.45 137.579 209.45 138.061 209.718 138.469Z" fill="#263238" />
                <path d="M208.41 139.648C209.701 140.506 211.104 141.181 212.581 141.653C212.302 142.725 210.887 143.271 210.887 143.271L208.41 139.648Z" fill="#ED893E" />
                <path d="M207.156 146.005C206.968 146.104 206.789 146.219 206.62 146.348C206.595 146.374 206.581 146.409 206.581 146.445C206.581 146.481 206.595 146.515 206.62 146.541C206.632 146.555 206.647 146.566 206.663 146.573C206.68 146.581 206.698 146.585 206.716 146.585C206.735 146.585 206.753 146.581 206.769 146.573C206.786 146.566 206.801 146.555 206.813 146.541C207.291 146.174 207.849 145.925 208.442 145.817C209.035 145.709 209.645 145.744 210.222 145.919C210.24 145.926 210.258 145.929 210.277 145.929C210.296 145.928 210.314 145.924 210.332 145.916C210.349 145.908 210.364 145.896 210.376 145.882C210.389 145.868 210.398 145.852 210.404 145.834C210.419 145.8 210.419 145.761 210.405 145.727C210.39 145.693 210.363 145.666 210.329 145.651C209.804 145.49 209.252 145.438 208.706 145.499C208.16 145.56 207.632 145.732 207.156 146.005Z" fill="#263238" />
                <path d="M202.085 140.227C202.135 140.23 202.186 140.219 202.231 140.197C202.276 140.174 202.314 140.14 202.342 140.098C202.531 139.797 202.787 139.543 203.09 139.357C203.393 139.171 203.735 139.058 204.09 139.026C204.128 139.028 204.166 139.021 204.201 139.007C204.236 138.993 204.268 138.972 204.294 138.944C204.321 138.917 204.341 138.884 204.354 138.848C204.367 138.813 204.372 138.774 204.368 138.737C204.368 138.663 204.339 138.592 204.287 138.539C204.235 138.487 204.164 138.458 204.09 138.458C203.637 138.492 203.198 138.633 202.81 138.869C202.423 139.106 202.097 139.432 201.86 139.819C201.819 139.884 201.806 139.961 201.822 140.035C201.838 140.109 201.882 140.174 201.946 140.216C201.99 140.234 202.038 140.237 202.085 140.227Z" fill="#263238" />
                <path d="M209.772 134.813C209.788 134.757 209.788 134.697 209.772 134.641C209.75 134.569 209.7 134.509 209.634 134.473C209.568 134.437 209.491 134.428 209.418 134.448C208.982 134.558 208.579 134.772 208.244 135.07C207.908 135.369 207.649 135.744 207.488 136.164C207.467 136.236 207.474 136.314 207.508 136.382C207.542 136.449 207.6 136.501 207.671 136.528C207.706 136.54 207.743 136.544 207.78 136.541C207.817 136.539 207.853 136.528 207.886 136.511C207.919 136.494 207.948 136.471 207.972 136.443C207.996 136.414 208.013 136.381 208.024 136.346C208.153 136.013 208.361 135.716 208.63 135.48C208.899 135.245 209.221 135.079 209.568 134.995C209.615 134.985 209.658 134.963 209.694 134.931C209.73 134.899 209.756 134.858 209.772 134.813Z" fill="#263238" />
                <path d="M196.832 140.441C196.832 140.441 208.153 135.681 208.85 130.256C209.547 124.832 204.755 127.04 204.755 127.04C204.755 127.04 206.974 126.89 206.759 128.498C206.759 128.498 202.932 125.893 199.255 128.134C199.753 128.134 200.245 128.245 200.695 128.458C201.146 128.672 201.544 128.982 201.86 129.367C199.196 130.039 196.698 131.246 194.516 132.915C190.668 135.81 183.688 146.112 196.832 140.441Z" fill="#263238" />
                <path opacity="0.3" d="M220.696 218.702L202.246 216.558C208.496 215.732 214.807 215.474 221.104 215.786L220.696 218.702Z" fill="black" />
                <path opacity="0.1" d="M189.027 182.767C188.963 182.016 188.909 181.266 188.845 180.515C188.845 179.926 188.748 179.325 188.695 178.736C188.534 177.235 188.362 175.734 188.148 174.254C188.094 173.847 188.03 173.44 187.966 173.043C188.474 172.641 189.016 172.282 189.585 171.971C190.173 175.568 189.983 179.249 189.027 182.767Z" fill="black" />
                <path d="M158.291 209.054L155.075 216.558L162.011 218.273C162.011 218.273 164.992 213.331 162.086 209.558L158.291 209.054Z" fill="#EBB376" />
                <path d="M155.579 222.422L161.057 222.465L162.022 218.338L155.086 216.622L155.579 222.422Z" fill="#EBB376" />
                <path d="M189.499 169.194C189.981 173.911 188.362 175.83 188.362 175.83C188.362 175.83 183.795 178.703 181.812 180.172C180.129 181.448 178.478 182.734 176.913 184.021C176.141 184.675 175.39 185.329 174.704 185.983C174.094 186.556 173.521 187.168 172.989 187.816C172.792 188.089 172.61 188.371 172.442 188.663C172.249 188.974 172.046 189.445 171.831 189.831C171.413 190.689 170.995 191.611 170.598 192.565C169.805 194.484 169.055 196.478 168.347 198.504C166.857 202.535 165.474 206.695 164.059 210.78L157.723 209.268C158.366 204.83 159.234 200.488 160.242 196.103C160.767 193.959 161.314 191.729 162.032 189.499C162.365 188.427 162.74 187.258 163.169 186.101C163.426 185.489 163.641 184.943 163.962 184.289C164.275 183.601 164.633 182.935 165.034 182.295C165.799 181.157 166.652 180.082 167.586 179.078C168.433 178.157 169.28 177.31 170.137 176.484C171.842 174.855 173.589 173.364 175.369 171.928C178.918 169.044 182.477 166.407 186.293 163.855C186.326 163.887 189.07 164.927 189.499 169.194Z" fill="#5B9ECF" />
                <path opacity="0.7" d="M189.499 169.194C189.981 173.911 188.362 175.83 188.362 175.83C188.362 175.83 183.795 178.703 181.812 180.172C180.129 181.448 178.478 182.734 176.913 184.021C176.141 184.675 175.39 185.329 174.704 185.983C174.094 186.556 173.521 187.168 172.989 187.816C172.792 188.089 172.61 188.371 172.442 188.663C172.249 188.974 172.046 189.445 171.831 189.831C171.413 190.689 170.995 191.611 170.598 192.565C169.805 194.484 169.055 196.478 168.347 198.504C166.857 202.535 165.474 206.695 164.059 210.78L157.723 209.268C158.366 204.83 159.234 200.488 160.242 196.103C160.767 193.959 161.314 191.729 162.032 189.499C162.365 188.427 162.74 187.258 163.169 186.101C163.426 185.489 163.641 184.943 163.962 184.289C164.275 183.601 164.633 182.935 165.034 182.295C165.799 181.157 166.652 180.082 167.586 179.078C168.433 178.157 169.28 177.31 170.137 176.484C171.842 174.855 173.589 173.364 175.369 171.928C178.918 169.044 182.477 166.407 186.293 163.855C186.326 163.887 189.07 164.927 189.499 169.194Z" fill="white" />
                <path d="M203.897 162.869L206.277 166.61C206.277 166.61 197.968 176.688 182.059 180.011L180.987 183.228L184.374 184.117C184.374 184.117 201.528 179.668 208.41 166.782L211.948 162.837L207.778 161.432L203.897 162.869Z" fill="#5B9ECF" />
                <path d="M196.189 160.617L200.07 157.23L207.778 161.454L208.678 156.833L212.752 159.17L213.921 165.163L208.228 162.697L203.147 167.243L196.189 160.617Z" fill="#5B9ECF" />
                <path opacity="0.6" d="M196.189 160.617L200.07 157.23L207.778 161.454L208.678 156.833L212.752 159.17L213.921 165.163L208.228 162.697L203.147 167.243L196.189 160.617Z" fill="white" />
                <path d="M302.527 93.5594C302.527 93.5594 295.312 102.5 288.001 101.761C280.689 101.021 276.948 103.991 276.948 103.991L276.229 107.561L280.153 107.025C280.153 107.025 280.153 104.194 287.786 104.516C295.42 104.838 301.144 98.2765 303.374 95.2962L302.527 93.5594Z" fill="#5B9ECF" />
                <path d="M354.308 78.7005L354.158 77.993C354.094 77.6475 353.993 77.3097 353.858 76.9852C353.476 76.0167 352.878 75.1478 352.111 74.4444C351.159 73.5862 350.043 72.9297 348.83 72.5147C347.298 71.972 345.708 71.6122 344.092 71.4426C342.666 71.2818 341.304 71.2068 339.964 71.1746C337.295 71.0996 334.722 71.1746 332.181 71.389C327.121 71.7251 322.082 72.3299 317.086 73.2008L318.93 83.7607C323.551 83.0317 328.375 82.3563 333.039 81.8739C335.376 81.6273 337.702 81.4879 339.943 81.4129C341.015 81.4129 342.162 81.4129 343.159 81.4129C343.809 81.4477 344.457 81.5193 345.099 81.6273C345.122 82.0201 345.122 82.4138 345.099 82.8066C344.961 84.8713 344.576 86.912 343.952 88.8852C342.594 93.3437 340.862 97.6796 338.774 101.847L343.866 105.234C347.122 101.159 349.855 96.6932 352.003 91.9406C353.179 89.3441 353.994 86.5993 354.426 83.7821C354.657 82.0927 354.617 80.3774 354.308 78.7005Z" fill="#5B9ECF" />
                <path opacity="0.7" d="M354.362 83.7714C354.019 86.1231 353.389 88.4239 352.486 90.6219C352.314 91.0615 352.132 91.501 351.95 91.9298C349.798 96.6795 347.06 101.142 343.802 105.213L338.774 101.846C340.883 97.7104 342.647 93.4076 344.049 88.9816C344.474 87.5768 344.8 86.1439 345.024 84.6934C345.116 84.1112 345.173 83.5241 345.196 82.9352C345.218 82.5424 345.218 82.1487 345.196 81.7559C344.553 81.6479 343.906 81.5763 343.255 81.5415C342.183 81.4771 341.111 81.4771 340.039 81.5415H339.792C337.648 81.6165 335.386 81.7666 333.124 81.9917C328.6 82.4635 323.947 83.0638 319.455 83.825L319.026 83.8893L317.182 73.3187C322.176 72.453 327.21 71.8483 332.267 71.5069C334.818 71.3461 337.391 71.2174 340.061 71.2925C341.401 71.2925 342.751 71.3997 344.188 71.5605C345.803 71.7352 347.393 72.095 348.927 72.6326C350.137 73.0516 351.253 73.7076 352.207 74.5623C352.975 75.2657 353.572 76.1345 353.955 77.1031C354.084 77.4296 354.184 77.7668 354.255 78.1109L354.405 78.8184C354.645 80.4618 354.631 82.1324 354.362 83.7714Z" fill="white" />
                <path opacity="0.1" d="M339.696 81.4771C337.552 81.5521 335.29 81.7022 333.028 81.9274C328.504 82.3991 323.851 82.9994 319.359 83.7606C320.999 79.7296 322.682 75.6558 322.682 75.6558C328.659 76.5507 334.424 78.5231 339.696 81.4771Z" fill="black" />
                <path opacity="0.1" d="M352.486 90.6219C352.314 91.0615 352.132 91.501 351.95 91.9298C349.797 96.6795 347.06 101.142 343.802 105.213L338.774 101.846C340.883 97.7104 342.647 93.4076 344.048 88.9816C344.474 87.5768 344.8 86.1439 345.024 84.6934C347.664 86.4699 350.159 88.4522 352.486 90.6219Z" fill="black" />
                <path d="M283.97 80.8232C284.27 81.1877 284.302 81.6487 284.066 81.8953C283.83 82.1418 283.391 81.8953 283.091 81.5736C282.791 81.252 282.758 80.7374 283.005 80.5552C283.252 80.3729 283.68 80.4051 283.97 80.8232Z" fill="#263238" />
                <path d="M284.345 81.6165C284.798 83.1014 285.043 84.6419 285.074 86.1942C285.47 86.1722 285.858 86.0651 286.209 85.8801C286.561 85.6951 286.868 85.4366 287.111 85.1221L284.345 81.6165Z" fill="#DE5753" />
                <path d="M283.702 77.6928C283.726 77.667 283.744 77.6365 283.755 77.6032C283.766 77.5699 283.77 77.5347 283.766 77.4998C283.765 77.4632 283.756 77.4272 283.741 77.3939C283.726 77.3606 283.704 77.3307 283.677 77.3058C283.65 77.2809 283.618 77.2615 283.584 77.2488C283.55 77.2361 283.513 77.2303 283.477 77.2318C283.037 77.2419 282.606 77.3572 282.22 77.5679C281.834 77.7787 281.504 78.0788 281.257 78.4432C281.221 78.5077 281.211 78.5836 281.229 78.6554C281.247 78.7272 281.292 78.7894 281.354 78.8292C281.417 78.8649 281.491 78.8748 281.561 78.8568C281.631 78.8388 281.691 78.7943 281.729 78.7327C281.929 78.445 282.195 78.2092 282.504 78.0451C282.814 77.881 283.158 77.7933 283.509 77.7893C283.546 77.7881 283.583 77.7789 283.616 77.7622C283.649 77.7456 283.678 77.7219 283.702 77.6928Z" fill="#263238" />
                <path d="M294.648 75.0447C298.196 77.5962 304.972 81.6379 308.82 80.1049C308.82 80.1049 312.283 82.9887 308.96 90.2145C305.304 98.1692 303.267 92.2085 303.267 92.2085C304.221 87.3842 300.951 86.0227 297.574 85.3902L294.648 75.0447Z" fill="#E4897B" />
                <path d="M295.72 70.274C299.032 75.0447 301.219 77.6713 300.758 81.8202C300.083 88.0704 292.814 90.3968 288.14 86.741C283.927 83.4176 279.21 76.1704 282.233 70.8422C282.895 69.6626 283.847 68.6718 285 67.9639C286.152 67.256 287.467 66.8545 288.818 66.7976C290.169 66.7407 291.513 67.0302 292.721 67.6386C293.929 68.2471 294.961 69.1543 295.72 70.274Z" fill="#E4897B" />
                <path d="M301.048 78.143C300.544 80.8553 291.399 84.5754 287.315 78.7219C287.315 78.7219 282.126 78.7219 279.006 75.5057C275.886 72.2895 276.562 68.9982 279.349 67.1543C281.815 65.5247 288.183 53.5497 295.602 62.0941C303.653 61.8154 301.659 74.7767 301.048 78.143Z" fill="#263238" />
                <path d="M285.664 65.9106C285.664 65.9106 280.882 65.6748 278.159 67.8618C275.436 70.0488 276.176 76.7814 277.237 78.0464C277.487 78.3952 277.807 78.6885 278.176 78.908C278.545 79.1275 278.955 79.2685 279.381 79.3222C279.381 79.3222 277.762 78.4753 277.762 77.1781C277.762 77.1781 278.47 78.8398 281.718 79.3222C281.465 79.1231 281.261 78.8669 281.125 78.5744C280.989 78.2819 280.924 77.9614 280.936 77.6391C280.936 77.6391 282.694 79.9226 286.768 77.6391C290.842 75.3555 285.664 65.9106 285.664 65.9106Z" fill="#263238" />
                <path d="M289.727 77.7142C290.544 78.9062 290.947 80.3338 290.874 81.7773C290.756 83.6749 289.169 83.825 287.915 82.6242C286.661 81.4235 285.503 79.2687 286.243 77.682C286.982 76.0953 288.719 76.299 289.727 77.7142Z" fill="#E4897B" />
                <path d="M299.247 93.5593C299.247 93.5593 298.357 82.7636 305.4 76.7064L313.795 77.4783C313.795 77.4783 308.96 89.2818 299.247 93.5593Z" fill="#5B9ECF" />
                <path opacity="0.6" d="M299.247 93.5593C299.247 93.5593 298.357 82.7636 305.4 76.7064L313.795 77.4783C313.795 77.4783 308.96 89.2818 299.247 93.5593Z" fill="white" />
                <path d="M323.443 117.842C323.443 117.842 310.75 105.373 296.727 96.6576C296.727 96.6576 306.065 74.7123 317.901 72.7504C317.901 72.7504 348.487 81.9916 357.364 107.057L323.443 117.842Z" fill="#5B9ECF" />
                <path d="M325.051 193.852L319.584 196.178L313.655 183.731L319.134 181.405L325.051 193.852Z" fill="#E4897B" />
                <path d="M357.363 107.025L354.147 141.331C351.697 142.873 348.975 143.935 346.128 144.461C339.438 146.016 330.519 146.723 322.811 147.141C314.159 147.613 307.051 147.699 306.408 148.213C304.907 149.393 323.958 188.738 323.958 188.738L314.867 189.874C314.867 189.874 290.97 157.09 287.872 145.705C286.735 141.502 285.588 136.238 293.307 131.318C300.104 127.029 325.405 123.738 325.405 123.738L323.454 117.895L357.363 107.025Z" fill="#263238" />
                <path opacity="0.2" d="M346.118 144.429C339.428 145.984 330.508 146.691 322.8 147.109C321.728 134.459 328.16 122.323 328.16 122.323L337.209 120.007L346.118 144.429Z" fill="black" />
                <path d="M404.803 188.813L405.778 194.677L392.281 197.507L391.305 191.632L404.803 188.813Z" fill="#E4897B" />
                <path d="M406.057 194.891C406.035 194.876 406.009 194.867 405.982 194.867C405.955 194.867 405.929 194.876 405.907 194.891C405.882 194.902 405.86 194.92 405.845 194.943C405.829 194.966 405.821 194.993 405.821 195.02C405.821 195.127 405.907 197.518 406.647 198.236C406.783 198.373 406.968 198.45 407.161 198.451C407.272 198.461 407.383 198.436 407.479 198.381C407.575 198.325 407.651 198.241 407.697 198.14C407.858 197.743 407.558 197.068 407.161 196.414C406.84 195.874 406.471 195.364 406.057 194.891ZM406.121 195.395C406.84 196.253 407.622 197.539 407.44 197.968C407.44 198.033 407.354 198.108 407.161 198.118C407.04 198.116 406.924 198.066 406.84 197.979C406.775 197.916 406.721 197.844 406.679 197.765C406.344 197.017 406.155 196.213 406.121 195.395Z" fill="#5B9ECF" />
                <path d="M406.014 194.859C405.993 194.847 405.969 194.841 405.944 194.841C405.92 194.841 405.896 194.847 405.875 194.859C405.875 194.859 404.535 195.878 404.406 196.843C404.366 197.124 404.44 197.409 404.61 197.636C404.846 197.947 405.071 197.936 405.221 197.872C405.853 197.615 406.196 195.728 406.1 194.945C406.1 194.945 406.1 194.945 406.1 194.891C406.089 194.88 406.075 194.871 406.061 194.866C406.046 194.86 406.03 194.858 406.014 194.859ZM404.685 196.907C404.885 196.256 405.287 195.685 405.832 195.277C405.832 196.124 405.489 197.422 405.114 197.636C405.114 197.636 404.985 197.69 404.835 197.486C404.829 197.461 404.829 197.436 404.835 197.411C404.726 197.266 404.672 197.087 404.685 196.907Z" fill="#5B9ECF" />
                <path d="M405.039 194.816L401.629 189.284C401.571 189.181 401.547 189.061 401.563 188.943C401.578 188.825 401.632 188.715 401.715 188.63L405.596 184.857C405.69 184.766 405.804 184.697 405.929 184.656C406.053 184.615 406.186 184.603 406.316 184.621C406.446 184.638 406.57 184.685 406.679 184.757C406.789 184.83 406.88 184.926 406.947 185.039C408.148 187.055 409.809 190.003 411.353 192.544C413.154 195.481 414.88 197.904 416.992 201.356C418.279 203.436 416.531 205.301 415.802 204.572C412.49 201.142 410.442 197.968 406.154 195.846C405.695 195.61 405.31 195.254 405.039 194.816Z" fill="#263238" />
                <path d="M357.364 107.025C357.364 107.025 368.267 135.038 349.859 145.909C349.859 145.909 349.623 178.285 354.276 182.08C359.926 186.69 401.34 189.038 401.34 189.038L400.986 199.33C400.986 199.33 356.645 202.953 343.223 198.14C327.496 192.501 324.001 118.324 324.001 118.324L357.364 107.025Z" fill="#263238" />
                <path opacity="0.2" d="M344.767 124.221L351.381 122.226C351.853 125.119 352.154 128.036 352.282 130.964L349.216 133.601L346.311 132.679C345.958 129.832 345.442 127.008 344.767 124.221Z" fill="black" />
                <path d="M325.63 117.863L326.488 120.586L324.033 121.347L323.175 118.646L325.63 117.863Z" fill="#263238" />
                <path d="M336.919 114.24L337.841 117.059L327.71 120.2L326.81 117.477L336.919 114.24Z" fill="#263238" />
                <path d="M349.538 110.198L350.459 113.146L339.128 116.662L338.227 113.821L349.538 110.198Z" fill="#263238" />
                <path d="M358.757 110.573L351.778 112.739H351.767L350.835 109.78L357.76 107.561L358.757 110.573Z" fill="#263238" />
                <g opacity="0.2">
                    <path d="M325.63 117.863L326.488 120.586L324.033 121.347L323.175 118.646L325.63 117.863Z" fill="white" />
                    <path d="M336.919 114.24L337.841 117.059L327.71 120.2L326.81 117.477L336.919 114.24Z" fill="white" />
                    <path d="M349.537 110.198L350.459 113.146L339.128 116.662L338.227 113.821L349.537 110.198Z" fill="white" />
                    <path d="M358.757 110.573L351.778 112.739H351.767L350.835 109.78L357.76 107.561L358.757 110.573Z" fill="white" />
                </g>
                <path d="M317.901 194.441C317.928 194.433 317.952 194.418 317.971 194.398C317.99 194.377 318.003 194.351 318.008 194.323C318.014 194.293 318.009 194.263 317.996 194.236C317.982 194.208 317.96 194.187 317.933 194.173C317.847 194.173 315.735 192.994 314.717 193.283C314.531 193.335 314.373 193.458 314.277 193.626C314.214 193.718 314.18 193.826 314.18 193.937C314.18 194.048 314.214 194.157 314.277 194.248C314.535 194.58 315.274 194.666 316.036 194.645C316.662 194.636 317.287 194.568 317.901 194.441ZM317.44 194.237C316.368 194.42 314.835 194.452 314.545 194.077C314.545 194.023 314.47 193.937 314.545 193.766C314.571 193.713 314.609 193.667 314.655 193.632C314.702 193.597 314.756 193.573 314.813 193.562C314.902 193.546 314.993 193.546 315.081 193.562C315.904 193.631 316.705 193.861 317.44 194.237Z" fill="#5B9ECF" />
                <path d="M317.954 194.42C317.976 194.404 317.992 194.383 318.001 194.358C318.011 194.333 318.013 194.306 318.008 194.28C317.927 193.443 317.586 192.652 317.032 192.018C316.922 191.93 316.794 191.865 316.658 191.826C316.522 191.788 316.38 191.776 316.239 191.793C315.853 191.793 315.746 192.04 315.724 192.201C315.639 192.876 317.086 194.13 317.815 194.43H317.869L317.954 194.42ZM316.882 192.276C317.339 192.784 317.627 193.42 317.708 194.098C316.968 193.669 315.971 192.683 316.025 192.276C316.025 192.276 316.025 192.147 316.303 192.115H316.389C316.555 192.105 316.719 192.146 316.861 192.233L316.882 192.276Z" fill="#5B9ECF" />
                <path d="M318.48 193.594L324.977 193.444C325.095 193.439 325.212 193.476 325.306 193.549C325.4 193.621 325.466 193.725 325.491 193.841L326.799 199.094C326.828 199.221 326.828 199.353 326.799 199.479C326.771 199.606 326.715 199.725 326.635 199.828C326.555 199.93 326.453 200.014 326.337 200.073C326.221 200.131 326.093 200.163 325.963 200.166C323.615 200.166 320.227 200.166 317.29 200.166C313.838 200.241 310.857 200.498 306.837 200.595C304.393 200.659 303.621 198.204 304.693 197.958C309.324 196.821 313.119 196.628 317.086 194.055C317.502 193.781 317.983 193.622 318.48 193.594Z" fill="#263238" />
                <path opacity="0.7" d="M357.364 107.035L323.443 117.842C323.443 117.842 317.461 111.978 309.174 105.47C307.598 104.237 305.958 102.983 304.232 101.739C301.82 99.9811 299.29 98.2551 296.727 96.6577C296.727 96.6577 306.076 74.7124 317.912 72.7612C317.901 72.7612 348.487 81.9917 357.364 107.035Z" fill="white" />
                <path opacity="0.1" d="M309.174 105.47C307.598 104.237 305.958 102.983 304.232 101.739L304.896 99.7346C306.701 101.332 308.157 103.285 309.174 105.47Z" fill="black" />
                <path d="M260.17 127.855L257.833 128.412C257.547 128.479 257.288 128.628 257.088 128.842C256.887 129.056 256.755 129.324 256.707 129.613L256.182 132.829C256.126 133.163 256.186 133.505 256.352 133.8C256.518 134.094 256.779 134.323 257.093 134.448L258.808 135.124C258.808 135.124 263.74 138.683 267.91 136.389L266.109 131.618L262.582 128.402C262.254 128.123 261.863 127.927 261.443 127.832C261.023 127.737 260.586 127.745 260.17 127.855Z" fill="#E4897B" />
                <path d="M302.742 97.5582C303.647 98.1872 304.403 99.0062 304.959 99.958C305.514 100.91 305.855 101.971 305.958 103.069C305.958 103.069 303.599 109.501 302.238 112.642C301.305 114.786 300.34 116.931 299.215 119.075C298.053 121.427 296.66 123.657 295.055 125.732L294.68 126.172L294.251 126.643C293.912 126.991 293.554 127.32 293.179 127.63C292.95 127.822 292.71 128.001 292.461 128.166C292.225 128.327 291.882 128.552 291.785 128.595L290.874 129.066C289.695 129.656 288.59 130.138 287.486 130.578C285.278 131.478 283.101 132.272 280.914 133.022C276.519 134.491 272.156 135.831 267.674 136.957L265.53 131.221C269.55 129.163 273.646 127.212 277.645 125.207C279.649 124.21 281.643 123.213 283.552 122.205C284.495 121.701 285.439 121.133 286.253 120.683L286.832 120.318C286.907 120.254 286.907 120.243 287.004 120.104L287.132 119.943L287.282 119.718C288.32 118.1 289.223 116.401 289.984 114.636C290.831 112.707 291.689 110.702 292.471 108.654C294.026 104.58 295.516 100.335 296.91 96.2181C298.952 95.9393 301.026 96.4158 302.742 97.5582Z" fill="#5B9ECF" />
                <path opacity="0.7" d="M302.742 97.5582C303.647 98.1872 304.403 99.0062 304.959 99.958C305.514 100.91 305.855 101.971 305.958 103.069C305.958 103.069 303.599 109.501 302.238 112.642C301.305 114.786 300.34 116.931 299.215 119.075C298.053 121.427 296.66 123.657 295.055 125.732L294.68 126.172L294.251 126.643C293.912 126.991 293.554 127.32 293.179 127.63C292.95 127.822 292.71 128.001 292.461 128.166C292.225 128.327 291.882 128.552 291.785 128.595L290.874 129.066C289.695 129.656 288.59 130.138 287.486 130.578C285.278 131.478 283.101 132.272 280.914 133.022C276.519 134.491 272.156 135.831 267.674 136.957L265.53 131.221C269.55 129.163 273.646 127.212 277.645 125.207C279.649 124.21 281.643 123.213 283.552 122.205C284.495 121.701 285.439 121.133 286.253 120.683L286.832 120.318C286.907 120.254 286.907 120.243 287.004 120.104L287.132 119.943L287.282 119.718C288.32 118.1 289.223 116.401 289.984 114.636C290.831 112.707 291.689 110.702 292.471 108.654C294.026 104.58 295.516 100.335 296.91 96.2181C298.952 95.9393 301.026 96.4158 302.742 97.5582Z" fill="white" />
                <path d="M106.682 382H287.733L383.555 304.597L387.757 309.796H411.353L435.4 199.126L411.782 199.169L411.793 199.126L298.539 199.351L312.058 216.086L106.682 382Z" fill="#5B9ECF" />
                <path opacity="0.2" d="M106.682 382H264.137L374.228 293.061L387.757 309.796L411.793 199.126L298.539 199.351L312.058 216.086L106.682 382Z" fill="white" />
                <path d="M102.994 375.61H232.993C232.929 375.055 232.897 374.497 232.896 373.938C232.895 371.953 233.285 369.988 234.043 368.154C234.802 366.32 235.914 364.654 237.317 363.25C238.72 361.846 240.385 360.733 242.219 359.973C244.052 359.213 246.017 358.822 248.002 358.822H248.559C249.657 355.887 251.75 353.431 254.474 351.883C257.198 350.334 260.379 349.792 263.462 350.35C266.545 350.907 269.334 352.53 271.342 354.935C273.35 357.34 274.45 360.374 274.45 363.507C277.609 363.657 280.6 364.975 282.843 367.205C285.085 369.436 286.419 372.42 286.586 375.578H287.197C288.101 375.542 289.004 375.689 289.85 376.01C290.697 376.332 291.47 376.821 292.122 377.448C292.775 378.075 293.295 378.828 293.649 379.661C294.004 380.494 294.187 381.39 294.187 382.295C294.187 383.2 294.004 384.096 293.649 384.929C293.295 385.762 292.775 386.515 292.122 387.142C291.47 387.769 290.697 388.258 289.85 388.579C289.004 388.901 288.101 389.048 287.197 389.011H102.994C102.089 389.048 101.187 388.901 100.34 388.579C99.4937 388.258 98.7209 387.769 98.0681 387.142C97.4153 386.515 96.8959 385.762 96.5411 384.929C96.1863 384.096 96.0034 383.2 96.0034 382.295C96.0034 381.39 96.1863 380.494 96.5411 379.661C96.8959 378.828 97.4153 378.075 98.0681 377.448C98.7209 376.821 99.4937 376.332 100.34 376.01C101.187 375.689 102.089 375.542 102.994 375.578V375.61Z" fill="#5B9ECF" />
                <path opacity="0.7" d="M102.994 375.61H232.993C232.929 375.055 232.897 374.497 232.896 373.938C232.895 371.953 233.285 369.988 234.043 368.154C234.802 366.32 235.914 364.654 237.317 363.25C238.72 361.846 240.385 360.733 242.219 359.973C244.052 359.213 246.017 358.822 248.002 358.822H248.559C249.657 355.887 251.75 353.431 254.474 351.883C257.198 350.334 260.379 349.792 263.462 350.35C266.545 350.907 269.334 352.53 271.342 354.935C273.35 357.34 274.45 360.374 274.45 363.507C277.609 363.657 280.6 364.975 282.843 367.205C285.085 369.436 286.419 372.42 286.586 375.578H287.197C288.101 375.542 289.004 375.689 289.85 376.01C290.697 376.332 291.47 376.821 292.122 377.448C292.775 378.075 293.295 378.828 293.649 379.661C294.004 380.494 294.187 381.39 294.187 382.295C294.187 383.2 294.004 384.096 293.649 384.929C293.295 385.762 292.775 386.515 292.122 387.142C291.47 387.769 290.697 388.258 289.85 388.579C289.004 388.901 288.101 389.048 287.197 389.011H102.994C102.089 389.048 101.187 388.901 100.34 388.579C99.4937 388.258 98.7209 387.769 98.0681 387.142C97.4153 386.515 96.8959 385.762 96.5411 384.929C96.1863 384.096 96.0034 383.2 96.0034 382.295C96.0034 381.39 96.1863 380.494 96.5411 379.661C96.8959 378.828 97.4153 378.075 98.0681 377.448C98.7209 376.821 99.4937 376.332 100.34 376.01C101.187 375.689 102.089 375.542 102.994 375.578V375.61Z" fill="white" />
            </svg>

        </>
    )
}

export default ConsultantSvg