import { React, } from "react";
import { Link, NavLink } from 'react-router-dom';
import { FaUser } from "react-icons/fa";
import NavItem from "./NavItem/NavItem"
import { IoClose } from "react-icons/io5";


import Logo from "../../assets/img/Logo1.png";
import ProfilSvg from "./assets/ProfilSvg";
import CandidatureSvg from "./assets/CandidatureSvg";
import RdvSvg from "./assets/RdvSvg";
import DonnerSvg from "./assets/DonnerSvg";
import DashboardSvg from "./assets/DashboardSvg";
import { isClient, isUser } from "utils/roles";
import { PiFlaskFill } from "react-icons/pi";



function SideBar({ userContext, menuIsOpen, setMenuIsOpen }) {

  return (
    <aside className={`top-0 z-50 bg-sidebar absolute lg:sticky h-screen w-full lg:w-64 ${menuIsOpen ? "" : "hidden"} lg:block bg-white border-r`}>
      <div className="p-6 flex items-center justify-between">
        <Link to="/">
          <img
            className="w-20 lg:w-20"
            src={Logo}
            alt="MyFinPlace.com"
          />
        </Link>
        {
          menuIsOpen ?
            <div className="cursor-pointer lg:hidden" onClick={() => setMenuIsOpen(false)}>
              <IoClose size={42} />
            </div>
            :
            null
        }
      </div>
      <nav className="text-base p-3 flex flex-col gap-2">
        <NavItem href={"/mon-espace/Dashboard"} icon={<DashboardSvg />} text={"Tableau de bord"} setMenuIsOpen={setMenuIsOpen} />
        <NavItem href={"/mon-espace/mon-profil/informations-generales"} icon={<FaUser />} setMenuIsOpen={setMenuIsOpen} text={"Mon Profil"} />
        {
          isUser(userContext.user) ?
          <>
                  <NavItem href={"/mon-espace/mes-softskill"} icon={<PiFlaskFill />} setMenuIsOpen={setMenuIsOpen} text={"Mes softskills"}/>
            <NavItem href={"/mon-espace/mes-candidatures"} icon={<CandidatureSvg />} setMenuIsOpen={setMenuIsOpen} text={"Mes candidatures"} />
            </>
            :
            null
        }
        <NavItem href={"/mon-espace/mes-entretiens"} icon={<RdvSvg />} setMenuIsOpen={setMenuIsOpen} text={"Mes entretiens"} />
        {
          isClient(userContext.user) ?
            <>
              <NavItem href={"/mon-espace/mes-offres"} icon={<RdvSvg />} text={"Mes opportunités"} setMenuIsOpen={setMenuIsOpen} />
              <NavItem href={"/mon-espace/favoris-recommendation"} icon={<RdvSvg />} text={"Favoris et Recommandations"} setMenuIsOpen={setMenuIsOpen} />
            </>
            :
            null
        }
        {/* <Link to="/mon-espace/mes-donner">
    <a href="#" className="flex items-center active-nav-link text-white mx-6  nav-item">
        <div tabIndex={1} className="flex flex-row items-center active-nav-link  p-2 rounded nav-item focus:bg-white focus:text-[#0F6FDE]">
        <div className="mr-3">
        <DonnerSvg/></div>
        Mes données
        </div>
      </a>
    </Link> */}

      </nav>
    </aside>
  )
}
export default SideBar;