//Dépendances
import { HydraAdmin, ResourceGuesser } from "@api-platform/admin";
import { CustomRoutes, BasenameContextProvider } from "react-admin";
import { Route } from 'react-router-dom';
// Pour le menu custom
import { MyLayout } from './MyLayout';
import authProvider from './authProvider';
import dataProvider from "./dataProvider";
//Import des pages customs
import HomeIris from "./Pages/Home";
import EntretiensPlanifier from './Pages/EntretienCustom/entretiensPlanifier';
import EntretiensArchiver from './Pages/EntretienCustom/entretiensArchives'
import EntretiensPromgrammes from "./Pages/EntretienCustom/entretiensProgrammes";
import EntretiensNonRealises from "./Pages/EntretienCustom/entretiensNonRealises";
import EntretiensRealises from "./Pages/EntretienCustom/entretiensRealises";
import InDeveloppment from "./Pages/InDeveloppment";
//Import pour les ResourceGuesser
import { AppointmentList, AppointmentShow, AppointmentEdit, AppointmentSuperEdit, AppointmentSuperCreate } from "./RessourceComponents/appointmentsComponents";
import { OffresList, OffresShow, OffresEdit, OffreSuperCreate } from "./RessourceComponents/offresComponents";
import { ClientList, ClientShow, ClientSuperCreate, ClientEdit } from "./RessourceComponents/clientComponents";
import { TagsList, TagsShow, TagsSuperCreate, TagsSuperEdit, NotAllowedPlaceholder } from "./RessourceComponents/tagsComponents";
import { UserList, UserShow, UserEdit, UserSuperCreate } from "./RessourceComponents/usersComponents";
import Kpi from "./Pages/Kpi";
import EntretiensRefuses from "./Pages/EntretienCustom/entretiensRefuses";


function IsAuthorized(role) {
    switch (role) {
        case "ROLE_OPERATEUR":
            return true;
        case "ROLE_ADMIN":
            return true;
        case "ROLE_SUPER_ADMIN":
            return true;
        default:
            return false;
    }
}

const StoreAdmin = () => {
    return (
        <section className="overflow-x-auto smaller-scroll-bar">
            <>
                <HydraAdmin entrypoint={process.env.REACT_APP_API} layout={MyLayout} basename="/Iris" authProvider={authProvider} dataProvider={dataProvider()}>
                    {permissions => [
                        //Seul les personnes ayant un role admin peuvent avoir accès à ce module, on vérifie donc leur role
                        IsAuthorized(permissions) ? (
                            <>
                                {/* ------------------ Appointment ------------------ */}
                                <ResourceGuesser name={"appointments"}
                                    list={AppointmentList}
                                    edit={
                                        permissions === 'ROLE_SUPER_ADMIN' ?
                                            AppointmentSuperEdit
                                            :
                                            AppointmentEdit
                                    }
                                    show={AppointmentShow}
                                    create={
                                        permissions === 'ROLE_SUPER_ADMIN' ?
                                            AppointmentSuperCreate
                                            :
                                            NotAllowedPlaceholder
                                    } />
                                {/* ------------------ Client  ------------------ */}
                                <ResourceGuesser name={"clients"}
                                    list={ClientList}
                                    show={ClientShow}
                                    edit={ClientEdit}
                                    create={
                                        permissions === 'ROLE_SUPER_ADMIN' ?
                                            ClientSuperCreate
                                            :
                                            NotAllowedPlaceholder
                                    } />
                                {/* ------------------ Offre ------------------ */}
                                <ResourceGuesser name={"offres"}
                                    list={OffresList}
                                    edit={
                                        permissions === 'ROLE_ADMIN' || permissions === 'ROLE_SUPER_ADMIN' ?
                                            OffresEdit
                                            :
                                            null
                                    }
                                    show={OffresShow}
                                    create={
                                        permissions === 'ROLE_SUPER_ADMIN' ?
                                            OffreSuperCreate
                                            :
                                            NotAllowedPlaceholder
                                    } />
                                {/* ------------------ Tag ------------------ */}
                                <ResourceGuesser name={"tags"}
                                    list={TagsList}
                                    edit={permissions === 'ROLE_ADMIN' || permissions === 'ROLE_SUPER_ADMIN' ?
                                        TagsSuperEdit
                                        :
                                        NotAllowedPlaceholder}
                                    show={TagsShow}
                                    create={
                                        permissions === 'ROLE_ADMIN' || permissions === 'ROLE_SUPER_ADMIN' ?
                                            TagsSuperCreate
                                            :
                                            NotAllowedPlaceholder
                                    } />
                                {/* ------------------ User ------------------ */}
                                <ResourceGuesser name={"users"}
                                    list={UserList}
                                    edit={
                                        permissions === 'ROLE_ADMIN' || permissions === 'ROLE_SUPER_ADMIN' ?
                                            UserEdit
                                            : false
                                    }
                                    show={UserShow}
                                    create={
                                        permissions === 'ROLE_SUPER_ADMIN' ?
                                            UserSuperCreate
                                            :
                                            NotAllowedPlaceholder
                                    } />
                                {/*-------------------------------------------------------
                                           Routes customs
                             --------------------------------------------------------*/}
                                <CustomRoutes>
                                    <Route
                                        path="/Iris/appointments/*" element={
                                            <BasenameContextProvider basename={'/Iris'}>
                                                <ResourceGuesser name="appointments" list={AppointmentList} edit={AppointmentEdit} show={AppointmentShow} />
                                            </BasenameContextProvider>
                                        }
                                    />
                                    <Route path="/Iris/clients/*" element={
                                        <BasenameContextProvider basename={'/Iris'}>
                                            <ResourceGuesser name="clients" list={ClientList} show={ClientShow} />
                                        </BasenameContextProvider>}
                                    />
                                    <Route path="/Iris/offres/*" element={
                                        <BasenameContextProvider basename={'/Iris'}>
                                            <ResourceGuesser name="offres" list={OffresList} edit={OffresEdit} show={OffresShow} />
                                        </BasenameContextProvider>}
                                    />
                                    <Route path="/Iris/tags/*" element={
                                        <BasenameContextProvider basename={'/Iris'}>
                                            <ResourceGuesser name="tags" list={TagsList} edit={TagsSuperEdit} show={TagsShow} />
                                        </BasenameContextProvider>}
                                    />
                                    <Route path="/Iris/users/*" element={
                                        <BasenameContextProvider basename={'/Iris'}>
                                            <ResourceGuesser name="users" list={UserList} edit={UserEdit} show={UserShow} />
                                        </BasenameContextProvider>}
                                    />
                                    {/*-------------------------------------------------------
                                           Pages customs
                         --------------------------------------------------------*/}
                                    <Route path="/Home" element={<HomeIris />} />
                                    <Route path="/Kpi" element={<Kpi />} />
                                    <Route path="/RDV à planifier" element={<EntretiensPlanifier />} />
                                    <Route path="/RDV programmés" element={<EntretiensPromgrammes />} />
                                    <Route path="/RDV réalisés" element={<EntretiensRealises />} />
                                    <Route path="/RDV non réalisés" element={<EntretiensNonRealises />} />
                                    <Route path="/RDV archivés" element={<EntretiensArchiver />} />
                                    <Route path="/RDV refusés" element={<EntretiensRefuses />} />
                                    <Route path="/Mission gagnées" element={<InDeveloppment />} />
                                    <Route path="/Mission en cours" element={<InDeveloppment />} />
                                    <Route path="/Mission cloturées" element={<InDeveloppment />} />
                                </CustomRoutes>
                            </>
                        ) : (
                            window.location.replace('/')
                        )
                    ]}
                </HydraAdmin>
            </>
        </section>
    );
};

export default StoreAdmin