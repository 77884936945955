// Import dépendance
import React from "react"
import { Document, Page, Text, View, PDFViewer, pdf, Link } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import { saveAs } from 'file-saver';
// Import components
import TopLeft from "./ComponentPdf/TopLeft";
import TopRight from "./ComponentPdf/TopRight";
import Modalite from "./ComponentPdf/Modalite";
import Competence from "./ComponentPdf/Competence";
import Formation from "./ComponentPdf/Formation";
import Experience from "./ComponentPdf/Experience";
// Import CSS
import { styles, SizeText } from "./Css/styles";

const Footer = () => (
  <View style={{ position: "absolute", bottom: 10, left: 0, right: 0, fontSize: 8 }} fixed>
    <Link src='mailto:contact@myfinplace.com' style={{ fontFamily: 'Poppins', fontSize: 12, textDecoration: "underline", fontWeight: 800, textAlign: "center", color: "#263238" }}>
      contact@myfinplace.com
    </Link>
  </View>
);

const CustomDocument = ({ data }) => (
  <Document>
    <Page style={{ padding: 16 }}>
      {/*******************************************************************************************
         *                                         Top
        *  *****************************************************************************************/}
      <View style={{ display: "flex", flexDirection: "row" }}>
        {/* Partie de gauche */}
        <TopLeft data={data}></TopLeft>
        {/* Partie de droite */}
        <TopRight data={data}></TopRight>
      </View>
      {/***************************************** Border *********************************************/}
      <View style={{ borderBottomWidth: 1, borderStyle: "solid", borderColor: "#BDD8EC", marginTop: 10, marginBottom: 0 }} />
      {/*******************************************************************************************
         *                                         Modalité
         *  *****************************************************************************************/}
      <Modalite data={data} />
      {/***************************************** Border *********************************************/}
      <View style={{ borderBottomWidth: 1, borderStyle: "solid", borderColor: "#BDD8EC", marginTop: 40, marginBottom: 20 }} />
      {/*******************************************************************************************
         *                                         Compétences
         *  *****************************************************************************************/}
      <Competence data={data} />
      {/*******************************************************************************************
         *                                               Footer
         *  *****************************************************************************************/}
      <Footer />
    </Page>
    {/*******************************************************************************************
         *                                         Formations
         *  *****************************************************************************************/}
    <Page style={{ padding: 24 }}>
      {/*******************************************************************************************
         *                                         Présentation
         *  *****************************************************************************************/}
      <View>
        <Text style={styles.TitrePresentation}>Présentation</Text>
        {data.presentation ? (
          <Html style={{ fontSize: SizeText }}>
            {"<div style='max-width:90%;'>" + data.presentation.replaceAll("\n", "<br/>") + "</div>"}
          </Html>
        ) : (
          <Text style={{ fontSize: SizeText }}>Non indiqué</Text>
        )}
      </View>
      {/***************************************** Border *********************************************/}
      <View style={{ borderBottomWidth: 1, borderStyle: "solid", borderColor: "#BDD8EC", marginTop: 10, marginBottom: 20 }} />
      <Formation data={data} />
      <Footer />
    </Page>
    {/*******************************************************************************************
         *                                         Expérience
         *  *****************************************************************************************/}
    <Page style={{ padding: 24 }}>
      <Experience data={data} />
      <Footer />
    </Page>
  </Document>
);

// ************************** Pour télécharger ************************************/
const PdfTemplate = async (data) => {
  const blob = await pdf((
    <CustomDocument data={data[0]} />
  )).toBlob();
  saveAs(blob, "dossier de compétence.pdf");
};

// ***************** Pour visionner le PDF sans le télécharger ********************/
// Mettre la balise <PdfTemplate data={data}/> dans la div principal de DossierCompetence.jsx
// const PdfTemplate = ({ data }) => {
//   if (data !== undefined) {
//     return (
//       <PDFViewer className="w-full h-[100vh]">
//         <CustomDocument data={data[0]} />
//       </PDFViewer>
//     )
//   }
// };

export default PdfTemplate