import React from 'react'
import Team from "assets/svg/Team";


export default function PartieHaute() {
    return (

        <section className='containerxl flex flex-col lg:flex-row items-center justify-around'>
            <article className='lg:w-[50%]'>
                <h1 className='mb-14 robotoSlab bigscreen:text-[60px]'>Qui sommes-nous ?</h1>
                <div className='text-justify'>
                    <p className='mb-7 bigscreen:text-[20px]'>
                        <strong className='text-[#5B9ECF]'>MyFinPlace</strong> est une plateforme 100 % française fondée par des
                        professionnels du recrutement et du consulting qui permet de mettre
                        en relation des établissements financiers (&nbsp;Tous secteurs d’activité&nbsp;)
                        avec des profils ayant des compétences en Finance (&nbsp;Tous métiers&nbsp;).

                        Cette mise en relation automatisée est facilitée par un important vivier
                        de candidats / consultants disponibles partout en France et en
                        Europe.
                    </p>
                    <p className='bigscreen:text-[20px]'>
                        MyFinPlace permet à chaque société ou cabinet de trouver ses
                        talents et de travailler avec eux ; quel que soit le format de
                        collaboration (&nbsp;stage / alternance / intérim / CDD / CDI / Portage
                        salarial / Consulting / Freelance&nbsp;).
                    </p>
                </div>
            </article>
            <article className='hidden lg:block p-6'>
                <Team />
            </article>
        </section>

    )
}