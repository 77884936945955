import {
  FacebookShareButton, FacebookIcon,
  FacebookMessengerShareButton, FacebookMessengerIcon,
  TwitterShareButton, XIcon,
  WhatsappShareButton, WhatsappIcon,
  LinkedinShareButton, LinkedinIcon,
  EmailShareButton, EmailIcon,
} from "react-share";

export default function ShareModal({ url }) {
  const sizeIcon = 32;

  return (
    <>
      <div className="flex items-center justify-center gap-6">
        <FacebookShareButton url={url} >
          <FacebookIcon size={sizeIcon} round />
        </FacebookShareButton>

        <FacebookMessengerShareButton url={url} >
          <FacebookMessengerIcon size={sizeIcon} round />
        </FacebookMessengerShareButton>

        <TwitterShareButton url={url} >
          <XIcon size={sizeIcon} round />
        </TwitterShareButton>

        <WhatsappShareButton url={url} >
          <WhatsappIcon size={sizeIcon} round />
        </WhatsappShareButton>

        <LinkedinShareButton url={url} >
          <LinkedinIcon size={sizeIcon} round />
        </LinkedinShareButton>

        <EmailShareButton url={url} >
          <EmailIcon size={sizeIcon} round />
        </EmailShareButton>
      </div>
    </>
  )
}