import React, { useState, useEffect, useContext } from "react";
import AppoitmentCalendar from "./calendar/AppoitmentCalendar";
import SelectedDay from "./calendar/dayselected/SelectedDay";
import { useParams } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { add, differenceInDays, endOfMonth, format, setDate, startOfMonth, sub } from "date-fns";
import { fr } from 'date-fns/locale'
import { isClient, isUser } from "utils/roles";
import { AuthContext } from "../../../Context/AuthProvider";

function AppTest() {
  const [data, setData] = useState([]);
  const [dataDaySelect, setDataDaySelect] = useState([]);
  const [currentDate, setCurrentDate] = useState((new Date()));
  const token = cookie.get("token");
  const { id } = useParams();
  const { value } = useContext(AuthContext);

  const getAppoitment = () => {
    const type = isUser(value.user) ? "user" : isClient(value.user) ? "client" : null;
    if (type === null) {
      // Si le type n'est pas définie, on ne fait pas de request
      return
    }
    axios
      .get(process.env.REACT_APP_API + `${type}/entretien`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(response.data);
        if (id !== undefined) {
          const appointmentParam = response.data.filter(x => x.id == id);
          if (appointmentParam.length > 0) {
            setCurrentDate(new Date(appointmentParam[0].date_choice))
          }
        }
        setDataDaySelect(response.data.filter(x => format(Date.parse(x.date_choice), "iiii d LLLL yyyy", { locale: fr }) == format(Date.parse(currentDate), "iiii d LLLL yyyy", { locale: fr })))
      })
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(() => {
    getAppoitment();
  }, [currentDate, value]);

  return (
    <div className="flex flex-col xl:flex-row justify-center gap-3">
      <div className="m-2 md:m-6 xl:w-1/2">
        <AppoitmentCalendar value={currentDate} data={data} onChange={setCurrentDate} />
      </div>
      <div className="m-2 md:m-6 xl:w-1/2">
        <SelectedDay data={dataDaySelect} value={currentDate} onChange={setCurrentDate} getAppoitment={getAppoitment} />
      </div>
    </div>
  )
}
export default AppTest;