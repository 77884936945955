import React from 'react'
import Vectors from 'assets/svg/Vectors'
import ConsultantSvg from 'assets/svg/ConsultantSvg'
import Fleche from 'assets/svg/fleche'
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";  

function Consultant() {
  return (
    <>
      <section className="py-20 bg-white">
      <Helmet>  
      <title>MyFinPlace - Devenir consultant</title>  
      </Helmet>  
        <article className="w-fit xl:mx-[125px]  md:w-auto ">
          <div className=" flex  mr-10 font-medium	robotoSlab">
            <Vectors classProps={"-mt-3.5 -mr-1"} />
            <h1>Comment devenir consultant en finance ?</h1>
          </div>
        </article>
        <div className='xl:flex items-end mt-3 lg:mt-1  xl:mt-1 3xl:-mt-36 md:mt-1'>
          <div className='flex flex-col xl:mx-[90px] mx-[30px] gap-y-32 pt-14'>
            <article className=''>
              <div className=' text-lg flex flex-col gap-6'>
                <p className='text-justify text-lg font-bold'>Option n°1 : <span className=' text-[#5B9ECF]' >intégrer un cabinet de conseil, d'expertise-comptable et/ou d'audit</span></p>
                <p className='text-justify text-lg'>Si vous souhaitez découvrir un cabinet de conseil original qui propose un écosystème résolument tourné vers la gestion de votre carrière, nous vous invitons à réserver un rendez-vous téléphonique gratuit avec un de nos experts en recrutement.</p>
              </div>
              <div className='pt-10'>
                <button className='flex items-center gap-x-4 bg-[#EFF5FA] text-[#5B9ECF] py-2 px-[20px] rounded-[100px] '>
                  <a href='mailto:contact@myfinplace.com' className='text-lg'>Réserver un appel gratuit avec un expert en recrutement</a>
                  <Fleche color={"#5B9ECF"} />
                </button>
              </div>
            </article>
            <article className=''>
              <div className='text-justify text-lg flex flex-col gap-6'>
                <p className='text-justify text-lg font-bold'>Option n°2 : <span className=' text-[#5B9ECF]' > Devenir consultant indépendant (freelance)</span></p>
                <p className='text-justify text-lg'>Si vous souhaitez devenir consultant en finance à votre compte, voici les étapes à suivre : </p>
                <ul className='text-justify text-lg mx-8'>
                  <p className='text-lg'>1. Créer votre société (SASu ou EURL, par exemple) ;</p>
                  <p className='text-lg'>2. Trouver une première mission ;</p>
                  <p className='text-lg'>3. Gérer et administrer votre société.</p>
                </ul>
                <p className='text-lg text-justify'>
                  MyFinPlace est là pour vous accompagner tout au long de ce processus. Pour cela, il suffit que vous réserviez un rendez-vous téléphonique gratuit avec un de nos associés, qui pourra ainsi vous apporter des conseils personnalisés.
                </p>
                <article>
                  <div className='flex justify-start items-center'>
                    <Link to="/RendezVous">
                      <button className='flex items-center gap-x-2.5 bg-[#EFF5FA] text-[#5B9ECF] py-2.5 px-[20px] rounded-[100px] '>
                        <p className='text-lg'>Réserver un appel gratuit avec un associé</p>
                        <Fleche color={"#5B9ECF"} />
                      </button>
                    </Link>
                  </div>
                </article>
              </div>
            </article>
          </div>
          <div className='self-center'>
            <ConsultantSvg classProps={"hidden md:block "} width={"800px"} />
          </div>
        </div>
      </section>
    </>

  )
}

export default Consultant