function CurrencyInput({ onChange, value, icon }) {

    return (
        <>
            <div className='flex'>
                <input style={{ textAlign: "right" }} onChange={onChange} value={value} type="number" min={0} className='focus:outline-none p-[10px]  border-[1.5px] border-lightblue rounded-[3px] w-full' />
                <div className='font-bold cursor-pointer  p-3 bg-greylight border flex items-center'>
                    {icon}
                </div>
            </div>
        </>
    )
}


export default CurrencyInput