import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import BroMdp from "assets/svg/BroMdp";
import Eye from "assets/svg/Eye";
import EyeCrossed from "assets/svg/EyeCrossed";

export default function MdpReinitialiser() {
    const navigate = useNavigate();
    const [isEyeCrossed, setisEyeCrossed] = useState(false);
    const [isEyeCrossedVerif, setisEyeCrossedVerif] = useState(false);
    const [successMessage, setSuccessMessage] = useState(""); // State for success message

    //Token
    const { token } = useParams();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        getValues,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            "newPassword": "",
            "verifPassword": "",
        },
    });
    const [password, setPassword] = useState("");
    // variable pour définir l'input du password comme étant "error" dès le début quand l'input est vide
    const [err, setErr] = useState(true);

    // variables pour la gestion des conditions de validation du password
    const [lowerValidated, setLowerValidated] = useState(false);
    const [upperValidated, setUpperValidated] = useState(false);
    const [specialValidated, setSpecialValidated] = useState(false);
    const [numberValidated, setNumberValidated] = useState(false);
    const [lengthValidate, setLengthValidate] = useState(false);


    // Envoie du formulaire
    const onSubmit = async (data) => {
        await axios.post(
            process.env.REACT_APP_API + "reset_mdp/" + token,
            JSON.stringify(data),
            { headers: { "Content-Type": "application/json" }, }
        )
            .then(res => {
                //faire la modal avec le res
                reset();
                setSuccessMessage({
                    message: "Votre mot de passe a bien été modifié.",
                    thankYouMessage: "Vous allez être redirigé vers la page de connexion."
                });
                reset();
                setTimeout(() => {
                    setSuccessMessage("");
                    navigate("/Connexion")
                }, 5000);
            })
            .catch((error) => error);
    };

    // ckeck password
    // regex
    const lower = new RegExp('(?=.*[a-z])');
    const upper = new RegExp('(?=.*[A-Z])');
    const special = new RegExp('(?=.*[\\W_])');
    const number = new RegExp('(?=.*[0-9])');
    const minLength = new RegExp('(?=.{8,})');

    const handlePassword = (e) => {
        const objectNewContent = e.target.value
        setPassword(objectNewContent);
    }

    // validation erreur mdp
    // taille
    useEffect(() => {
        if (errors.password || password !== "") {
            if (minLength.test(password)) {
                setLengthValidate(true);
            }
            else {
                setLengthValidate(false);
            }
            setErr(false);
        }
        else
            setErr(true);
    }, [password, minLength, errors.password])
    // minuscule
    useEffect(() => {
        if (errors.password || password !== "") {
            if (lower.test(password)) {
                setLowerValidated(true);
            }
            else {
                setLowerValidated(false);
            }
            setErr(false);
        }
        else
            setErr(true);
    }, [password, lower, errors.password, lowerValidated])
    // majuscule
    useEffect(() => {
        if (errors.password || password !== "") {
            if (upper.test(password)) {
                setUpperValidated(true);
            }
            else {
                setUpperValidated(false);
            }
            setErr(false);

        }
        else
            setErr(true);
    }, [password, upper, errors.password, upperValidated])
    // special
    useEffect(() => {
        if (errors.password || password !== "") {
            if (special.test(password)) {
                setSpecialValidated(true);
            }
            else {
                setSpecialValidated(false);
            }
            setErr(false);

        }
        else
            setErr(true);
    }, [password, special, errors.password, specialValidated])
    // number
    useEffect(() => {
        if (errors.password || password !== "") {
            if (number.test(password)) {
                setNumberValidated(true);
            }
            else {
                setNumberValidated(false);
            }
            setErr(false);

        }
        else
            setErr(true);
    }, [password, number, errors.password, numberValidated])

    function toggleEye(input) {
        const inputPassword = document.getElementById(input);
        if (inputPassword.type === "password") {
            inputPassword.type = "text"
            input === "password" ? setisEyeCrossed(true) : setisEyeCrossedVerif(true)
        } else {
            inputPassword.type = "password"
            input === "password" ? setisEyeCrossed(false) : setisEyeCrossedVerif(false)
        }
    }

    return (
        <div className="containerxl flex flex-col items-center py-8">
            <div className="mb-[30px]">
                <h3 className="robotoSlab font-medium">Réinitialisation du mot de passe</h3>
            </div>
            <div className=" flex flex-col items-center bg-[#EFF5FA] rounded w-[390px]">
                <div className="mt-[20px]">
                    <BroMdp />
                </div>
                <div >
                    <form
                        action="submit"
                        className="flex flex-col "
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className="text-black flex flex-col items-center">
                            <div className="flex flex-col pt-4">
                                <label htmlFor="password">Nouveau mot de passe</label>
                                <div className="relative flex flex-row">
                                    <input
                                        type="password"
                                        name="newPassword"
                                        id="password"
                                        placeholder=""
                                        autoComplete="newPassword"
                                        className={`px-4 py-2 border-[1.5px] w-[300px] border-[#BDD8EC] rounded-[25px]
                                    ${errors.password && ""}`}
                                        onInput={handlePassword}
                                        {...register("newPassword", {
                                            required: "Champ requis",
                                            pattern: {
                                                value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-+!*$@%_;.?:,])(?=.*[\W_]).*$/,
                                                message:
                                                    "Votre mot de passe doit contenir obligatoirement une majuscule, un chiffre et un caractère spécial",
                                            },
                                            minLength: {
                                                value: 8,
                                                message: "Minimum 8 caractères",
                                            },
                                        })}
                                    />
                                    <div className="hover:cursor-pointer absolute right-5 top-2 w-[25px] h-[25px]" onClick={() => {
                                        toggleEye("password");
                                    }}>
                                        {isEyeCrossed ? (<Eye color={"#5B9ECF"} />) : (<EyeCrossed color={"#5B9ECF"} />)}
                                    </div>
                                </div>
                                <ul className=" list-none mdpCondition grid grid-cols-3 grid-flow-row-dense gap-x-2 gap-y-2 pt-2">
                                    {!lengthValidate ? (
                                        <li className="text-xs italic">
                                            <span>{">"}</span> 8 caractères
                                        </li>
                                    ) : (
                                        <li className="text-xs italic line-through">
                                            <span>{">"}</span> 8 caractères
                                        </li>
                                    )}
                                    {!upperValidated ? (
                                        <li className="text-xs italic">1 majuscule</li>
                                    ) : (
                                        <li className="text-xs italic line-through">
                                            1 majuscule
                                        </li>
                                    )}
                                    {!lowerValidated ? (
                                        <li className="text-xs italic">1 minuscule</li>
                                    ) : (
                                        <li className="text-xs italic line-through">
                                            1 minuscule
                                        </li>
                                    )}
                                    {!numberValidated ? (
                                        <li className="text-xs italic">1 chiffre</li>
                                    ) : (
                                        <li className="text-xs italic line-through">
                                            1 chiffre
                                        </li>
                                    )}
                                    {!specialValidated ? (
                                        <li className="text-xs italic col-span-2">
                                            1 caractère spécial (?=.*)
                                        </li>
                                    ) : (
                                        <li className="text-xs italic col-span-2 line-through">
                                            1 caractère spécial (?=.*)
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <div className="flex flex-col pt-4">
                                <label htmlFor="verifPassword">
                                    Confirmation du mot de passe
                                </label>
                                <div className="relative flex flex-row">
                                    <input
                                        type="password"
                                        name="verifPassword"
                                        id="verifPassword"
                                        placeholder=""
                                        className={`w-[300px] px-4 py-2 border-[1.5px] border-[#BDD8EC] rounded-[25px]
                                    ${errors.verifPassword && ""}`}
                                        {...register("verifPassword", {
                                            required: "Champ requis",
                                            validate: (value) =>
                                                value === getValues("newPassword") ||
                                                "Les mots de passe ne sont pas identiques.",
                                        })}
                                    />
                                    <div className="hover:cursor-pointer absolute right-5 top-2 w-[25px] h-[25px]" onClick={() => {
                                        toggleEye("verifPassword");
                                    }}>
                                        {isEyeCrossedVerif ? (<Eye color={"#5B9ECF"} />) : (<EyeCrossed color={"#5B9ECF"} />)}
                                    </div>
                                </div>
                                {errors.verifPassword && (
                                    <span className="text-red-500 mt-3">
                                        {errors.verifPassword.message}
                                    </span>
                                )}
                            </div>
                        </div>
                        {successMessage && (
                            <div
                                className="mt-6 bg-[#E7EED8] border-t-4 border-[#5EB0AB] rounded-b px-4 py-3 shadow-md"
                                role="alert"
                            >
                                <div className="flex">
                                    <div className="py-1">
                                        <svg
                                            className="h-6 w-6  mr-4"
                                            fill="#5EB0AB"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                                        </svg>
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="text-sm font-bold robotoSlab">{successMessage.message}</div>
                                        <div className="text-sm">{successMessage.thankYouMessage}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className=" text-center mt-[40px] mb-[65px]">
                            <button className=" bg-[#5B9ECF] text-white font-bold px-6 py-2 rounded border-1 border-[#5B9ECF]  hover:cursor-pointer ">
                                <p className=""> Réinitialiser</p>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}