//Dépendances
import React, { useState, useEffect } from 'react';
import SVG from "../../assets/img/refcomerciale/Banque/7.webp"


function RefCom() {


    return (
        <>
            <section className="containerxl py-16">
                <section className='text-center '>
                    <div>
                        <h1 className='pb-16'>Références commerciales</h1>
                        <p className='font-bold'>Au travers de ses associés-fondateurs, MyFinPlace est un acteur reconnu qui intervient sur différents secteurs d’activité. <br /> Les associés tiennent à disposition les fiches de mission pour chaque références commerciales.</p>
                    </div>
                </section>
                {/* BANQUES & ASSURANCES */}
                <section className='py-4 mt-32' >
                    <div className='text-center'>
                        <h2>
                        BANQUES & ASSURANCES
                        </h2>
                    </div>
                    <section className=' flex flex-row flex-wrap items-center '>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/1.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/2.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/3.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/4.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/5.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/6.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={SVG} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/8.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/9.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/10.jpg")} alt=""  />
                    </div>
                     <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/11.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/12.png")} alt=""  />
                    </div>
                   
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/13.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/14.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/15.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/16.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/17.jpg")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/18.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/19.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/20.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/21.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/22.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/23.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/24.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/25.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/26.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/27.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/28.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/29.jpg")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Banque/30.png")} alt=""  />
                    </div>
                    </section>
                </section>


                  {/* IMMOBILIER & SOCIETES DE GESTION*/}
                  <section className='py-8 mt-8' >
                    <div className='text-center'>
                        <h2>
                        IMMOBILIER & SOCIETES DE GESTION
                        </h2>
                    </div>
                    <section className=' flex flex-row flex-wrap items-center '>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/1.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/2.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/3.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/4.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/5.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/6.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/7.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/8.webp")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/9.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/10.png")} alt=""  />
                    </div>
                     <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/11.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/12.jpg")} alt=""  />
                    </div>
                   
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/13.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/14.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/15.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/16.jpg")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/17.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/18.jpg")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/19.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/20.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/21.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/22.jpg")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/23.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/24.jpg")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/25.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/26.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/27.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/28.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/29.jpg")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/30.jpg")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/31.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Immobilier/32.png")} alt=""  />
                    </div>
                    </section>
                </section>



                
                    {/* INDUSTRIE, SERVICE, LUXE & RETAIL*/}
                    <section className='py-8 mt-8' >
                    <div className='text-center'>
                        <h2>
                        INDUSTRIE, SERVICE, LUXE & RETAIL
                        </h2>
                    </div>
                    <section className=' flex flex-row flex-wrap items-center '>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Industrie/1.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Industrie/2.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Industrie/3.jpg")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Industrie/5.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Industrie/6.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Industrie/7.png")} alt=""  />
                    </div>
                   
                    </section>
                </section>


                 {/* CABINETS*/}
                 <section className='py-8 mt-8' >
                    <div className='text-center'>
                        <h2>
                        CABINETS
                        </h2>
                    </div>
                    <section className=' flex flex-row flex-wrap items-center '>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Cabinets/1.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Cabinets/2.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Cabinets/3.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Cabinets/4.jpg")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Cabinets/5.jpg")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Cabinets/6.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Cabinets/7.jpg")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Cabinets/8.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Cabinets/9.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Cabinets/10.jpg")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Cabinets/11.jpg")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Cabinets/12.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Cabinets/13.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Cabinets/14.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Cabinets/15.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Cabinets/16.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Cabinets/17.png")} alt=""  />
                    </div>
                    <div className='m-3'>
                        <img width={150} height={150} src={require("../../assets/img/refcomerciale/Cabinets/18.png")} alt=""  />
                    </div>
                   
                    </section>
                </section>
            </section>
        </>
    )
}

export default RefCom