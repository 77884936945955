import ChatBot from "react-chatbotify";
import React from 'react'
import logo from "../../assets/img/chatbot2.jpg"
function ChatRobot() {
    const [form, setForm] = React.useState({});

    //partie configuration du style et du chatbot 
    const Styles = {
    }
    const settings = {
        general: {
            primaryColor: '#5b9ecf',
            secondaryColor: '#5b9ecf',
            fontFamily: 'Roboto, sans-serif',
        },
        tooltip: {
            mode: "NEVER",
            text: "",
        },
        voice: {
            disabled: false,
            language: "fr-FR"
        },
        chatButton: {
            icon: logo,
        },
        header: {
            title: (
                <div style={{ cursor: "pointer", margin: 0, fontSize: 20, fontWeight: "bold" }} onClick={
                    () => window.open("https://github.com/tjtanjin/")
                }>
                    Iris
                </div>
            ),
            showAvatar: true,
            avatar: logo,
        },
        footer: {
            text: (
                <div style={{ cursor: "pointer", display: "flex", flexDirection: "row", alignItems: "center", columnGap: 3 }}
                    onClick={() => window.open("https://react-chatbotify.com")}
                >
                </div>
            ),
        },
    }






    //partie process text et dialogue chat bot
    const helpOptions = ["Rechercher un profil", "Rechercher une opportunité", "S'inscrire", "FAQ", "Devenir consultant"];
    const flow = {
        start: {
            message: "Bonjour et bienvenue sur MyFinPlace ! Je m'appelle Iris ",
            transition: { duration: 1000 },
            path: "show_options"
        },
        show_options: {
            message: "En quoi puis-je vous aider ?",
            options: helpOptions,
            path: "process_options"
        },
        prompt_again: {
            message: "Avez-vous encore besoin d'aide ?",
            options: helpOptions,
            path: "process_options"
        },
        unknown_input: {
            message: "Pardon, je ne comprends pas votre demande 😢!",
            options: helpOptions,
            path: "process_options"
        },
        process_options: {
            transition: { duration: 0 },
            chatDisabled: true,
            path: async (params) => {
                let link = "";
                switch (params.userInput) {
                    case "Rechercher un profil":
                        return "search_profil";
                    case "Rechercher une opportunité":
                        return "search_offre";
                    case "S'inscrire":
                        link = "https://www.myfinplace.com/Inscription";
                        break;
                    case "FAQ":
                        link = "https://www.myfinplace.com/FAQ";
                        break;
                    case "Devenir consultant":
                        link = "https://www.myfinplace.com/devenir-consultant";
                        break;
                    default:
                        return "unknown_input";
                }
                await params.injectMessage("Je vous renvoie sur la page demandée");
                setTimeout(() => {
                    window.open(link);
                }, 1000)
                return "repeat"
            },
        },
        repeat: {
            transition: { duration: 3000 },
            path: "prompt_again"
        },
        search_profil: {
            message: `Quel type de profil recherchez-vous ?  `,
            function: (params) => setForm({ ...form, search: params.userInput }),
            path: "recap_search_profil"
        },
        recap_search_profil: {
            message: (params) => `Vous recherchez bien un profil avec les termes suivants ? ${params.userInput} `,
            options: ["oui", "non"],
            function: (params) => setForm({ ...form, response: params.userInput }),
            path: "check_response_profil"
        },
        check_response_profil: {
            transition: (params) => {
                if (params.prevPath === "non") {
                    return;
                }
                return { duration: 0 };
            },
            path: (params) => {
                if (params.userInput === "non") {
                    return "search_profil"
                } else {
                    return "send_search_profil";
                }
            },

        },
        send_search_profil: {
            message: `Préparation de votre recherche en cours `,
            transition: (params) => {
                if (params.prevPath === "non") {
                    return;
                }
                return { duration: 0 };
            },
             path: async (params) => {
                await params.injectMessage("Je vous renvoie sur la page demandée");
                setTimeout(() => {
                    window.open(`https://www.myfinplace.com/les-profils?searchTerm=${form.search}`);
                }, 1000)
                return "repeat"
             } 
        },
        search_offre: {
            message: `Quel type d'opportunités recherchez-vous ?  `,
            function: (params) => setForm({ ...form, search: params.userInput }),
            path: "recap_search_offre"
        },
        recap_search_offre: {
            message: (params) => `Vous recherchez bien une opportunité avec les termes suivants ? ${params.userInput} `,
            options: ["oui", "non"],
            function: (params) => setForm({ ...form, response: params.userInput }),
            path: "check_response_offre"
        },
        check_response_offre: {
            transition: (params) => {
                if (params.prevPath === "non") {
                    return;
                }
                return { duration: 0 };
            },
            path: (params) => {
                if (params.userInput === "non") {
                    return "search_offre"
                } else {
                    return "send_search_offre";
                }
            },

        },
        send_search_offre: {
            message: `Préparation de votre recherche en cours `,
            transition: (params) => {
                if (params.prevPath === "non") {
                    return;
                }
                return { duration: 0 };
            },
             path: async (params) => {
                await params.injectMessage("Je vous renvoie sur la page demandée");
                setTimeout(() => {
                    window.open(`https://www.myfinplace.com/les-opportunites?searchTerm=${form.search}`);}, 1000)
                return "repeat"
             } 
        }
    }

    return (
        <ChatBot settings={settings} flow={flow} styles={Styles} />
    )
}

export default ChatRobot