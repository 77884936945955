import { useState } from 'react'
import parse from "html-react-parser";
import { GiPositionMarker } from "react-icons/gi";
import { FaWalking } from "react-icons/fa";
import { PiUsersFill } from "react-icons/pi";
import { RiMoneyEuroCircleFill } from "react-icons/ri";
import { FaBriefcase } from "react-icons/fa";
import { VscRemoteExplorer } from "react-icons/vsc";
import { MdOutlineTimelapse } from "react-icons/md";
import TabExperiencesCard from './TabExperiencesCard';
import TabEducationCard from './TabEducationCard';
import UserSkill from './UserSkill';

function Tabs({ skillPortfolio, isComptable }) {
    const [tabActiveId, setTabActiveId] = useState(1)

    const tabHandler = (id) => {
        setTabActiveId(id);
    }

    const pricingFormatter = (experienceTime) => {
        switch (experienceTime) {
            case "Jeune diplomé":
                return <span className="bg-[#EFF5FA] rounded-full px-3 py-0.5">Entre 200 € et 400 €</span>
            case "Entre 1 et 3 ans":
                return <span className="bg-[#EFF5FA] rounded-full px-3 py-0.5">Entre 250 € et 500 €</span>
            case "Entre 4 et 8 ans":
                return <span className="bg-[#EFF5FA] rounded-full px-3 py-0.5">Entre 400 € et 700 €</span>
            case "Entre 9 et 13 ans":
                return <span className="bg-[#EFF5FA] rounded-full px-3 py-0.5">Entre 500 € et 900 €</span>
            case "Entre 14 et 19 ans":
                return <span className="bg-[#EFF5FA] rounded-full px-3 py-0.5">Entre 600 € et 1 100 €</span>
            case "Supérieure à 20 ans":
                return <span className="bg-[#EFF5FA] rounded-full px-3 py-0.5">Entre 800 € et 1 500 €</span>
            default:
                return ""
        }
    }

    return (
        <div className="col-span-4 rounded">
            <div className="flex flex-wrap bg-white border rounded-t">
                <span className={`border-b-4 p-5 cursor-pointer font-semibold hover:border-b-4 hover:border-[#5B9ECF] hover:text-[#5B9ECF] ${tabActiveId === 1 ? "border-[#5B9ECF] text-[#5B9ECF]" : ""}`} onClick={() => tabHandler(1)}>Expériences</span>
                <span className={`border-b-4 p-5 cursor-pointer font-semibold hover:border-b-4 hover:border-[#5B9ECF] hover:text-[#5B9ECF] ${tabActiveId === 2 ? "border-[#5B9ECF] text-[#5B9ECF]" : ""}`} onClick={() => tabHandler(2)}>Formations</span>
                <span className={`border-b-4 p-5 cursor-pointer font-semibold hover:border-b-4 hover:border-[#5B9ECF] hover:text-[#5B9ECF] ${tabActiveId === 3 ? "border-[#5B9ECF] text-[#5B9ECF]" : ""}`} onClick={() => tabHandler(3)}>Modalités</span>
                <span className={`border-b-4 p-5 cursor-pointer font-semibold hover:border-b-4 hover:border-[#5B9ECF] hover:text-[#5B9ECF] ${tabActiveId === 4 ? "border-[#5B9ECF] text-[#5B9ECF]" : ""}`} onClick={() => tabHandler(4)}>Présentation</span>
                <span className={`border-b-4 p-5 cursor-pointer font-semibold hover:border-b-4 hover:border-[#5B9ECF] hover:text-[#5B9ECF] ${tabActiveId === 5 ? "border-[#5B9ECF] text-[#5B9ECF]" : ""}`} onClick={() => tabHandler(5)}>Soft Skills</span>

            </div>
            <div className="bg-white border-b border-r border-l rounded p-4 text-[#051259]">
                <div className={`${tabActiveId === 1 ? "" : "hidden"}`}>
                    <p className="text-[1.5rem] font-bold text-[#051259] mb-4">Expériences</p>
                    <div className="flex flex-col gap-4">
                        {
                            skillPortfolio?.experiences.map((experience, key) => (
                                <TabExperiencesCard key={key} experience={experience} />
                            ))
                        }

                    </div>
                </div>
                <div className={`${tabActiveId === 2 ? "" : "hidden"}`}>
                    <p className="text-[1.5rem] font-bold text-[#051259] mb-4">Formations</p>
                    <div className="flex flex-col gap-4">
                        {
                            skillPortfolio?.educations.map((education, key) => (
                                <TabEducationCard key={key} education={education} />
                            ))
                        }
                    </div >
                </div>
                <div className={`${tabActiveId === 3 ? "" : "hidden"}`}>
                    <p className="text-[1.5rem] font-bold text-[#051259] mb-4">Modalités</p>
                    <div className="grid grid-cols-1 sm:grid-cols-2  md:grid-cols-2 gap-8">
                        <div className="">
                            <div className="flex items-center text-[18px] font-semibold gap-2 mb-2 border-b-2 pb-2"><span className=""><GiPositionMarker /></span> <span className="">Localisation</span></div>
                            <div>
                                <span className="bg-[#EFF5FA] rounded-full px-3 py-0.5">{skillPortfolio?.localisation}</span>
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center text-[18px] font-semibold gap-2 mb-2 border-b-2 pb-2"><span className=""><FaWalking /></span><span>Mobilité</span></div>
                            <div className="flex gap-2 flex-wrap">
                                {
                                    skillPortfolio?.mobility?.map((el, key) => <span key={key} className="bg-[#EFF5FA] rounded-full px-3 py-0.5">{el}</span>)

                                }
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center text-[18px] font-semibold gap-2 mb-2 border-b-2 pb-2"><span className=""><FaBriefcase /></span><span>Type d'intervention</span></div>
                            <div className="mt-2 flex gap-2 flex-wrap">
                                {
                                    skillPortfolio?.interventionType.map((el, key) => <span key={key} className="bg-[#EFF5FA] rounded-full px-3 py-0.5">{el.name}</span>)
                                }
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center text-[18px] font-semibold gap-2 mb-2 border-b-2 pb-2"><span className=""><PiUsersFill /></span><span>Séniorité</span></div>
                            <span className="bg-[#EFF5FA] rounded-full px-3 py-0.5">{skillPortfolio?.seniority.grade_type}</span>
                        </div>
                        <div>
                            <div className="flex items-center text-[18px] font-semibold gap-2 mb-2 border-b-2 pb-2"><span className=""><VscRemoteExplorer /></span><span>Présentiel / télétravail</span></div>
                            <span className="bg-[#EFF5FA] rounded-full px-3 py-0.5">{skillPortfolio && skillPortfolio.remote ? skillPortfolio.remote : "Non indiqué"}</span>
                        </div>
                        <div>
                            <div className="flex items-center text-[18px] font-semibold gap-2 mb-2 border-b-2 pb-2"><span className=""><MdOutlineTimelapse /></span><span>Temps plein / partiel / à l'heure</span></div>
                            {skillPortfolio && skillPortfolio.workTime && skillPortfolio.workTime.length > 0 ? skillPortfolio.workTime.map(item => {
                                return <span className="bg-[#EFF5FA] rounded-full px-3 py-0.5">{item}</span>
                            }) : <span className="bg-[#EFF5FA] rounded-full px-3 py-0.5">Non indiqué</span>}

                        </div>
                        <div>
                            <div className="flex items-center text-[18px] font-semibold gap-2 mb-2 border-b-2 pb-2">
                                <span>Expert Comptable</span>
                            </div>
                            <span className="bg-[#EFF5FA] rounded-full px-3 py-0.5">{skillPortfolio?.expertComptable ? "Oui" : "Non"}</span>
                        </div>
                        <div>
                            <div className="flex items-center text-[18px] font-semibold gap-2 mb-2 border-b-2 pb-2">
                                <span>Commissaires aux Comptes</span>
                            </div>
                            <span className="bg-[#EFF5FA] rounded-full px-3 py-0.5">{skillPortfolio?.commissaireCompte ? "Oui" : "Non"}</span>
                        </div>
                        <div>
                            <div className="flex items-center text-[18px] font-semibold gap-2 mb-2 border-b-2 pb-2"><span className=""><RiMoneyEuroCircleFill /></span><span>Prétentions</span></div>
                            <div className="flex flex-col gap-2 items-start">
                                {
                                    skillPortfolio && skillPortfolio.salary && (skillPortfolio.contracts.includes("CDD") || skillPortfolio.contracts.includes("CDI")) &&
                                    <span className="bg-[#EFF5FA] rounded-full px-3 py-0.5">{skillPortfolio?.salary} € / an</span>
                                }
                                {
                                    skillPortfolio && skillPortfolio.averageDailyRate && !isComptable && skillPortfolio.contracts.includes("Mission") &&
                                    pricingFormatter(skillPortfolio.timeExperiences)
                                }
                            </div>
                        </div>

                    </div>
                </div>
                <div className={`${tabActiveId === 4 ? "" : "hidden"} `}>
                    <p className="text-[1.5rem] font-bold text-[#051259] mb-4">Présentation</p>
                    <p>
                        {

                            skillPortfolio?.description ? parse(skillPortfolio?.description) : null
                        }

                    </p>
                </div>
                <div className={`${tabActiveId === 5 ? "" : "hidden"}`}>
                    <p className="text-[1.5rem] font-bold text-[#051259] mb-4">Cartographie des soft Skills du profil</p>
                    <p className="text-[14px] font-bold text-[#051259] mb-4">Voici les soft skills du profil.</p>
                    <p className="text-[14px] font-bold text-[#051259] mb-4">Cette cartographie a été produite suite à la réalisation d’un test permettant au
                        profil de déclarer des aptitudes dans le cadre d’une activité professionnelle. Les
                        résultats sont à modérer mais donne une indication sur la personnalité et l’état
                        d’esprit du profil.</p>
                    <p className="text-[14px] font-bold text-[#051259] mb-4">Afin d’avoir une opinion « éclairée » sur les soft skills du profil, nous vous invitons
                        à le rencontrer afin de vous forger votre propre conviction</p>
                    <div className="flex flex-col gap-4">
                        <UserSkill />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Tabs