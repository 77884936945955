import React from 'react'

function at(props) {
  return (
    <svg className={props.classProps ? props.classProps : "34"} width={props.width ? props.width : "34"} height={props.height ? props.height : "35"} viewBox="0 0 34 35" 
    fill={props.color ? props.color : "#5B9ECF"} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2486_1672)">
        <path d="M17 15.8223C15.6889 15.8223 14.875 16.8401 14.875 18.4785C14.875 20.1169 15.6889 21.1348 17 21.1348C18.3111 21.1348 19.125 20.1169 19.125 18.4785C19.125 16.8401 18.3111 15.8223 17 15.8223Z"
          fill={props.color ? props.color : "#5B9ECF"} />
        <path d="M17 0.947266C7.61175 0.947266 0 8.55902 0 17.9473C0 27.3355 7.61175 34.9473 17 34.9473C26.3883 34.9473 34 27.3355 34 17.9473C34 8.55902 26.3883 0.947266 17 0.947266ZM27.625 19.0098C27.625 21.3536 25.7189 23.2598 23.375 23.2598C22.0915 23.2598 20.9525 22.6754 20.1726 21.7723C19.4183 22.703 18.3196 23.2598 17 23.2598C14.4967 23.2598 12.75 21.2941 12.75 18.4785C12.75 15.6629 14.4967 13.6973 17 13.6973C17.8394 13.6973 18.5831 13.9353 19.2164 14.3348C19.38 13.9608 19.7519 13.6973 20.1875 13.6973C20.774 13.6973 21.25 14.1733 21.25 14.7598V19.0098C21.25 20.1828 22.202 21.1348 23.375 21.1348C24.548 21.1348 25.5 20.1828 25.5 19.0098V18.3914C25.5 13.8758 22.3359 10.0635 18.1369 9.52164C15.3467 9.16464 12.6629 10.1251 10.7674 12.1651C8.874 14.2073 8.11325 16.9761 8.687 19.762C9.33512 22.9113 11.8702 25.508 14.9919 26.2178C16.9936 26.6725 18.9911 26.4473 20.7655 25.5654C21.2946 25.3083 21.9279 25.5186 22.1914 26.0456C22.4506 26.5726 22.236 27.2101 21.7111 27.4715C20.2364 28.2025 18.6426 28.5744 17.0085 28.5744C16.1861 28.5744 15.3531 28.4809 14.5201 28.2896C10.5379 27.3844 7.43113 24.2054 6.6045 20.1891C5.89262 16.7233 6.8425 13.2723 9.20975 10.7201C11.5813 8.16802 14.9324 6.97164 18.4089 7.41152C23.664 8.09152 27.625 12.8111 27.625 18.3914V19.0098Z"
          fill={props.color ? props.color : "#5B9ECF"} />
      </g>
      <defs>
        <clipPath id="clip0_2486_1672">
          <rect  width="34" height="34" fill="white" transform="translate(0 0.947266)" />
        </clipPath>
      </defs>
    </svg>


  )
}

export default at