import React from 'react'

function vectors(props) {
    return (
        <svg className={props.classProps ? props.classProps : ""} 
        width={props.width ? props.width : "40"} height={props.height ? props.height : "43"} viewBox="0 0 40 43" 
        fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.5571 15.1762C33.3267 14.7416 32.3366 13.0154 28.3615 7.8057C24.15 2.29387 23.0177 1.23813 22.3174 2.04093C21.3675 3.15651 31.2955 15.8472 32.5571 15.1762Z" 
            fill={props.color ? props.color : "#263238"} />
            <path d="M9.89088 36.0886C11.2718 38.2643 11.8264 38.2952 17.5552 36.5278C23.474 34.7085 24.4967 34.1656 24.2806 33.0293C23.8805 30.9707 22.1736 30.9506 14.7514 32.8361C9.27613 34.2304 8.8716 34.4827 9.89088 36.0886Z" 
            fill={props.color ? props.color : "#263238"} />
            <path d="M9.58339 19.1372C10.5608 20.7265 11.2404 21.0915 14.3024 21.7025C23.3932 23.4799 29.727 25.4812 27.7619 22.4278C26.9685 21.1759 25.3171 20.6686 14.7618 18.2308C8.9197 16.9112 8.28983 17.0019 9.58339 19.1372Z" 
            fill={props.color ? props.color : "#263238"} />
        </svg>
    )
}

export default vectors