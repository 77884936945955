import React, { useState } from "react";
import All from "./Components/All";
import CabinetDeConseil from "./Components/CabinetDeConseil";
import CabinetDeRecrutement from "./Components/CabinetDeRecrutement";
import Candidat from "./Components/Candidat";
import Consultant from "./Components/Consultant";
import Entreprise from "./Components/Entreprise";
import Formulaire from "./Components/Formulaire";
import Etudiant from "./Components/Etudiant";
import { Helmet } from "react-helmet";  

export default function Inscription() {
    const [sideText, setSideText] = useState("");

    return (
        <div className="flex flex-col gap-4 lg:gap-0 lg:items-start lg:flex-row pt-24 lg:pt-24 mx-8 lg:mx-24 xl:mx-44 pb-8">
            <Helmet>  
<title>MyFinPlace - Inscription</title>  
</Helmet>  
            <div className="relative hidden lg:block lg:w-[70%] bg-greylight border-l-2 border-t-2 border-b-2 border-lightblue rounded-l-lg px-14 py-16 mt-20">
                {
                    sideText === "entreprise" ? <Entreprise /> :
                        sideText === "Cabinet-conseil" ? <CabinetDeConseil /> :
                            sideText === "Cabinet-daudit-dexpertise-comptable" ? <CabinetDeRecrutement /> :
                                sideText === "Candidat" ? <Candidat /> :
                                    sideText === "Consultant" ? <Consultant /> : 
                                        sideText === "Etudiant" ? <Etudiant /> :
                                                                                 <All />
                }
            </div>
            <div className="lg:min-w-[470px] px-4 md:px-0">
                <div className="bg-white border-2 border-lightblue px-3 sm:px-12 py-8 rounded-xl">
                    <h3 className="robotoSlab font-bold text-center">Inscrivez-vous !</h3>
                    <Formulaire setSideText={setSideText} />
                </div>
            </div>
        </div>
    )
}


