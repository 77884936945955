import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_CHECK } from 'react-admin';
import cookie from "js-cookie";
import jwt from "jwt-decode";

function GetRoles() {
  if (cookie.get("token") !== undefined) {
    const roles = jwt(cookie.get("token")).roles;
    if (roles.includes("ROLE_SUPER_ADMIN")) {
      return "ROLE_SUPER_ADMIN"
    }
    if (roles.includes("ROLE_ADMIN")) {
      return "ROLE_ADMIN"
    }
    if (roles.includes("ROLE_OPERATEUR")) {
      return "ROLE_OPERATEUR"
    }
  } else {
    return "user";
  }
}

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    // ...
    return Promise.resolve();
  }
  if (type === AUTH_LOGOUT) {
    // ...
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    // ...
  }
  if (type === AUTH_CHECK) {
    return GetRoles() ? Promise.resolve() : Promise.reject();
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = GetRoles();
    return Promise.resolve(role)
  }
  return Promise.reject('Unknown method');
};