
import { IoChevronDownOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

export default function Card3({ isActive, setActive }) {
  return (
    <div className="border rounded-[8px] border-[#2632384D] border-[4px] py-4 px-6 mt-16">
      <h1 onClick={() => setActive({ Question3: !isActive.Question3 })} className="font-semibold text-[#263238] cursor-pointer flex gap-2  "  >
        <div className="flex justify-between w-full items-center">
          <p className="text-[32px] text-black font-bold">Travailler dans <span className="text-[#5B9ECF]">la légalité</span></p>
          <div className={isActive.Question3 ? "rotate-180 transition duration-500" : "transition duration-500"}>
            <IoChevronDownOutline className="text-[#5B9ECF]" />
          </div>
        </div>
      </h1>
      <div className={isActive.Question3 ? "animation-box-visible" : "animation-box-hidden "} id="box1">
        <div className="pr-5 text-justify animation-content" id="content2">
          <p className="text-[20px] font-medium mt-8">Tenir, réviser, surveiller, consolider, apprécier, redresser les comptabilités des entreprises est une mission réservée exclusivement aux professionnels inscrits auprès de l’Ordre des experts-comptables.</p>
          <p className="text-[20px] font-medium mt-8">Ainsi toutes sociétés commerciales qui exercent une activité de conseil ou de management de transition portant sur la comptabilité au sens large s'exposent à des poursuites judiciaires devant un tribunal correctionnel.</p>
          <p  className="text-[20px] font-medium mt-8">Au-delà des sanctions prévues par la loi, cette même société verra son image et sa réputation fortement détériorées vis-à-vis de ses actionnaires, de ses clients, de ses salariés, de ses partenaires commerciaux, de l'ordre des experts comptables ainsi que de l'administration.
          Son activité pouvant être remise en cause ... </p>
          <p className="text-[20px] font-medium mt-8">Ne vous mettez pas en dehors du cadre réglementaire, au risque d'être lourdement sanctionné et d'être non indemnisé par votre assurance           </p>
          <p className="text-[20px] font-medium mt-8">Nous invitons donc ces sociétés à se conformer aux règles en vigueur en sollicitant MyFinPlace Le CAB.          </p>
          <p className="text-[20px] font-medium mt-8">Sanctions pénales pour le client et le professionnel « illégal » :</p>
          <ul className="pl-8">
            <li className="text-[20px] font-medium">L'accès à la profession d'expert-comptable est strictement réglementée et contrôlée, c'est pourquoi l'exercice illégal de la profession fait l'objet de sanctions pénales prévues aux articles 433-17 et 433-25 du Code pénal.</li>
            <li className="text-[20px] font-medium">Les peines peuvent être prononcées jusqu'à 1 an de prison ainsi que 15 000€ d'amende. </li>
          </ul>
          <div className="text-center my-8">
            <a href="https://www.legifrance.gouv.fr/loda/id/JORFTEXT000000698851" target="_blank" className="bg-[#5B9ECF] hover:bg-[#2D77AD] text-[19px] text-white py-2 px-8 rounded-[5px] inline-block">Je souhaite consulter l’ordonnance du 19 septembre 1945</a>
          </div>
        </div>
      </div>
    </div>
  )
}