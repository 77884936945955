import { useContext } from 'react';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import { IoTrashOutline } from "react-icons/io5";
import { IoPencil } from "react-icons/io5";
import SmallPrimaryButton from 'Components/Buttons/SmallPrimaryButton';
import { IoSchool } from "react-icons/io5";
import { ModalContext } from 'Context/ModalProvider';
import CreateOrEditFormationModal from 'Modals/Formation/CreateOrEditFormationModal';
import DeleteFormationModal from "Modals/Formation/DeleteFormationModal"
import { format } from "date-fns";
import { fr } from 'date-fns/locale'

function PersonalFormations() {

  const [userInformation, setUserInformation, filters, getProfile, pourcentage, getPourcentage] = useOutletContext();

  const { openModal, close } = useContext(ModalContext);

  const openModalHandler = (exp = null) => {
    openModal(exp ? "Modifier une formation" : " Ajouter une formation ",
      <CreateOrEditFormationModal
        test={userInformation}
        setUserInformation={setUserInformation}
        experience={exp}
        close={close}
        getPourcentage={getPourcentage}
      />
    )
  }


  const deleteHandler = (id) => {
    openModal("Suprimer une formation",
      <DeleteFormationModal
        id={id}
        close={close}
        setUserInformation={setUserInformation}
        getPourcentage={getPourcentage}
      />
    )
  }

  return (
    <div className=''>
      <div className='mb-4 p-4 flex justify-end items-center'>
        <SmallPrimaryButton text={"Ajouter une formation"} onClick={() => openModalHandler()} />
      </div>
      <div className='p-4 bg-white'>
        {/* {
          userInformation ? JSON.stringify(userInformation.formation) : ""
        } */}
        <div class="overflow-x-auto rounded">
          <table class="table-auto min-w-full">
            <thead className='border-b'>
              <tr class="">
                <th class="px-4 py-2 text-left">Ecole/Université</th>
                <th class="px-4 py-2 text-left">Diplôme </th>
                <th class="px-4 py-2 text-left">Niveau</th>
                <th class="px-4 py-2 text-left"> Date de début</th>
                <th class="px-4 py-2 text-left">Date de fin</th>
                <th class="px-4 py-2 text-left"></th>
              </tr>
            </thead>
            <tbody>
              {
                userInformation?.formation.map(exp => (
                  <tr className='rounded border' key={exp.id}>
                    <td class="px-6 py-4 font-bold"><div className='flex gap-2 items-center'><span className='p-2 bg-[#DCEDF8] rounded'><IoSchool /></span>{exp.school}</div></td>
                    <td class="px-6 py-4">{exp.diplome}</td>
                    <td class="px-6 py-4"><p className='bg-[#F7F9FC] p-1 rounded border-[#636363] text-center text-[#636363] text-sm no-wrap font-medium'>
                      {exp.bac === 0 ?
                        "Certification" : "Bac + " + exp.bac
                      }
                    </p></td>
                    <td class="px-6 py-4">{format(Date.parse(exp.date_start), "LLLL yyyy", { locale: fr })}</td>
                    <td class="px-6 py-4">{format(Date.parse(exp.date_end), "LLLL yyyy", { locale: fr })}</td>
                    <td class="px-6 py-6 flex gap-2"><span className='text-red-500 cursor-pointer bg-[#F7F9FC]  hover:bg-[#DCEDF8] border p-1 rounded' onClick={() => deleteHandler(exp.id)}><IoTrashOutline /></span> <span className='text-[#f97316] cursor-pointer bg-[#F7F9FC]  hover:bg-[#DCEDF8] border p-1 rounded' onClick={() => openModalHandler(exp)}><IoPencil /></span></td>
                  </tr>
                ))
              }

            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default PersonalFormations