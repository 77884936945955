import React from 'react'

function clock() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3087_942)">
                <path d="M20 0.0526733C8.97195 0.0526733 0 9.02463 0 20.0527C1.09859 46.5854 38.9055 46.5777 40 20.0525C40 9.02463 31.028 0.0526733 20 0.0526733ZM20 38.3339C9.91969 38.3339 1.71875 30.133 1.71875 20.0527C2.72297 -4.19991 37.2808 -4.19287 38.2812 20.0528C38.2812 30.133 30.0803 38.3339 20 38.3339Z" fill="#263238" />
                <path d="M20 5.1568C11.7864 5.1568 5.10414 11.8391 5.10414 20.0527C5.92242 39.8141 34.0805 39.8083 34.8958 20.0526C34.8959 11.8391 28.2136 5.1568 20 5.1568ZM20 33.2298C12.7341 33.2298 6.82289 27.3185 6.82289 20.0527C7.54672 2.57141 32.4559 2.57649 33.177 20.0527C33.1771 27.3185 27.2659 33.2298 20 33.2298Z" fill="#263238" />
                <path d="M26.3802 20.9121H20C19.5254 20.9121 19.1406 20.5273 19.1406 20.0527V11.1204C19.1848 9.98276 20.8134 9.97862 20.8594 11.1204V19.1933H26.3802C26.8548 19.1933 27.2396 19.5781 27.2396 20.0527C27.2396 20.5273 26.8548 20.9121 26.3802 20.9121Z" fill="#5B9ECF" />
            </g>
            <defs>
                <clipPath id="clip0_3087_942">
                    <rect width="40" height="40" fill="white" transform="translate(0 0.000976562)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default clock