import { Link } from 'react-router-dom';
import { useCreatePath } from "react-admin";

const formatTime = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}h${formattedMinutes}`;
};

function LayoutEntretien({ data }) {
  const createPath = useCreatePath();
  const CreateLink = (resource, id, text) => {
    return <Link to={createPath({ resource: resource, type: 'show', id: id })} className="text-[#497EA6] hover:underline">{text}</Link>
  };
  if (data === null) {
    return (<p>Chargement</p>);
  }
  if (data.length === 0) {
    return (<p>Aucun entretien</p>);
  }
  else {
    return (
      <table className="bg-white drop-shadow">
        <tr className="font-medium text-[15px]">
          <td className="py-2 px-4">ID de l'entretien</td>
          <td className="py-2 px-4">ID du client</td>
          <td className="py-2 px-4">Prénom</td>
          <td className="py-2 px-4">Nom</td>
          <td className="py-2 px-4">Identité</td>
          <td className="py-2 px-4">Adresse mail</td>
          <td className="py-2 px-4">Téléphone</td>
          <td className="py-2 px-4 border-l">ID Candidat/consultant</td>
          <td className="py-2 px-4">Prénom</td>
          <td className="py-2 px-4">Nom</td>
          <td className="py-2 px-4">Identité</td>
          <td className="py-2 px-4">Adresse mail</td>
          <td className="py-2 px-4">Téléphone</td>
          <td className="py-2 px-4">Date de l'entretien</td>
        </tr>
        {data.map((e, index) => (
          <tr>
            <td className="py-2 px-4 border-b">{CreateLink('appointments', e["@id"], e.id)}</td>
            <td className="py-2 px-4 border-b">{CreateLink('clients', e.id_client["@id"], e.id_client.id)}</td>
            <td className="py-2 px-4 border-b">{e.id_client.first_name}</td>
            <td className="py-2 px-4 border-b">{e.id_client.last_name}</td>
            <td className="py-2 px-4 border-b">{e.id_client.roles[0]}</td>
            <td className="py-2 px-4 border-b">
              <a className='text-[#497EA6] hover:underline' href={`mailto:${e.id_client.email}`}>{e.id_client.email}</a>
            </td>
            <td className="py-2 px-4 border-b">{e.id_client.mobile}</td>
            <td className="py-2 px-4 border-b border-l">{CreateLink('users', e.id_user["@id"], e.id_user.anonymous)}</td>
            <td className="py-2 px-4 border-b">{e.id_user.first_name}</td>
            <td className="py-2 px-4 border-b">{e.id_user.last_name}</td>
            <td className="py-2 px-4 border-b">{e.id_user.roles[0]}</td>
            <td className="py-2 px-4 border-b">
              <a className='text-[#497EA6] hover:underline' href={`mailto:${e.id_user.email}`}>{e.id_user.email}</a>
            </td>
            <td className="py-2 px-4 border-b">{e.id_user.mobile}</td>
            <td className="py-2 px-4 border-b">{new Date(e.date_choice).toLocaleDateString("fr-FR", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            })} à {formatTime(new Date(e.date_choice))}</td>
          </tr>
        ))}
      </table>
    );
  }
}

export default LayoutEntretien;