import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useCreatePath } from "react-admin";
import cookie from "js-cookie";
import axios from "axios";

const LimitDescription = (description) => {
  if (description.length > 100) {
    return description.substring(0, 100) + "...";
  } else {
    return description;
  }
};

function OffreNeedValidation() {
  const [data, setData] = useState([]);
  const [nb, setNb] = useState();

  useEffect(() => {
    axios.get(process.env.REACT_APP_API + `iris/lastPubliees?valider=false&exists[archiver]=false`, {
      headers: {
        Authorization: `Bearer ${cookie.get("token")}`
      }
    })
      .then((response) => {
        setData(response.data["hydra:member"]);
        setNb(response.data["hydra:totalItems"])
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const createPath = useCreatePath();
  const CreateLink = (resource, id, text, type) => {
    return <Link to={createPath({ resource: resource, type: type, id: id })} className="text-[#497EA6] hover:underline">{text}</Link>
  };

  return (
    <>
      <section className="max-h-[50vh] overflow-y-auto smaller-scroll-bar mb-6 w-1/2">
        <p className="text-red-500 pb-4">Les dernières opportunités publiées à valider ({nb})</p>
        {nb === undefined ? (
          <p>Chargement</p>
        ) : (
          nb === 0 ? (
            <p className="">Toutes les offres ont été validées</p>
          ) : (
            <table className="bg-white drop-shadow w-full">
              <tr className="text-[14px]">
                <td className="py-2 px-4 font-medium text-[15px]">Id de l'offre</td>
                <td className="py-2 px-4 font-medium text-[15px]">Intitulé</td>
                <td className="py-2 px-4 font-medium text-[15px]">Client</td>
                <td className="py-2 px-4 font-medium text-[15px]">Prénom</td>
                <td className="py-2 px-4 font-medium text-[15px]">Nom</td>
                <td className="py-2 px-4 font-medium text-[15px]">Entreprise</td>
                <td className="py-2 px-4 font-medium text-[15px]">Description</td>
                <td className="py-2 px-4 font-medium text-[15px]">Date de création</td>
              </tr>
              {data.map((e, index) => (
                <tr className="text-[12px]">
                  <td className="py-2 px-4 border-b">{CreateLink('offres', e["@id"], e.id, 'edit')}</td>
                  <td className="py-2 px-4 border-b">{e.intitule}</td>
                  <td className="py-2 px-4 border-b">{CreateLink('clients', e.id_client["@id"], e.id_client.id, "show")}</td>
                  <td className="py-2 px-4 border-b">{e.id_client.first_name}</td>
                  <td className="py-2 px-4 border-b">{e.id_client.last_name}</td>
                  <td className="py-2 px-4 border-b">{e.id_client.company}</td>
                  <td className="py-2 px-4 border-b">{LimitDescription(e.description)}</td>
                  <td className="py-2 px-4 border-b">{new Date(e.date_time_create).toLocaleDateString("fr-FR", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  })}</td>
                </tr>
              ))}
            </table>
          )
        )}
      </section>
    </>
  );
}

export default OffreNeedValidation;