import TagLevel from "Components/TagLevel/TagLevel";
import { FaUserTie } from "react-icons/fa";

export default function ProfilRechercher(props) {
  const offre = props.offre;
  const Tags = { "secteur": [], "info": [], "fonction": [], "langue": [], "intervention": [] };
  offre.tags.forEach(tag => {
    switch (tag.tag.id_tag_level.id) {
      case 1:
        Tags.secteur.push(tag);
        break;
      case 2:
        Tags.intervention.push(tag);
        break;
      case 3:
        Tags.fonction.push(tag);
        break;
      case 4:
        Tags.info.push(tag);
        break;
      case 5:
        Tags.langue.push(tag);
        break;
      default:
        break;
    }
  });

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-4">
        {/* Séniorité */}
        <div>
          <p className="font-medium text-[18px]">Séniorité</p>
          <div className="flex flex-wrap mt-2 gap-2">
            {offre.id_grade.map((grade) => {
              return <div className="bg-[#EFF5FA] rounded-[5px] py-1 px-2 text-[14px]">{grade.grade_type}</div>
            })}
          </div>
        </div>
        {/* Année d'expérience */}
        <div>
          <p className="font-medium text-[18px]">Année d'expérience</p>
          <div className="flex flex-wrap mt-2 gap-2">
            {offre.id_timeexperience.map((timexp) => {
              return <div className="bg-[#EFF5FA] rounded-[5px] py-1 px-2 text-[14px]">{timexp.name}</div>
            })}
          </div>
        </div>
        {/* Compétence sectorielles */}
        <div>
          <p className="font-medium text-[18px]">Compétences sectorielles</p>
          <div className="flex flex-wrap mt-2 gap-2">
            {Tags.secteur.map((tag, key) => {
              // return <div className="bg-sectoriellepastel text-sectorielledark rounded-[5px] py-1 px-2 text-[14px]">{tag.tag.name}</div>
              return <TagLevel name={tag.tag.name} level={tag.level} type={"sectorielle"} key={key} isSmall={true} />
            })}
          </div>
        </div>
        {/* Compétences informatique */}
        <div>
          <p className="font-medium text-[18px]">Compétences informatique</p>
          <div className="flex flex-wrap mt-2 gap-2">
            {Tags.info.map((tag, key) => {
              // return <div className="bg-informatiquepastel text-informatiquedark rounded-[5px] py-1 px-2 text-[14px]">{tag.tag.name}</div>
              return <TagLevel name={tag.tag.name} level={tag.level} type={"informatique"} key={key} isSmall={true} />
            })}
          </div>
        </div>
        {/* Compétences fonctionnelles */}
        <div>
          <p className="font-medium text-[18px]">Compétences fonctionnelles</p>
          <div className="flex flex-wrap mt-2 gap-2">
            {Tags.fonction.map((tag, key) => {
              // return <div className="bg-fonctionnellepastel text-fonctionnelledark rounded-[5px] py-1 px-2 text-[14px]">{tag.tag.name}</div>
              return <TagLevel name={tag.tag.name} level={tag.level} type={"fonctionnelle"} key={key} isSmall={true} />
            })}
          </div>
        </div>
        {/* Compétences linguistiques */}
        <div>
          <p className="font-medium text-[18px]">Compétences linguistiques</p>
          <div className="flex flex-wrap mt-2 gap-2">
            {Tags.langue.map((tag, key) => {
              // return <div className="bg-linguistiquepastel text-linguistiquedark rounded-[5px] py-1 px-2 text-[14px]">{tag.tag.name}</div>
              return <TagLevel name={tag.tag.name} level={tag.level} type={"linguistique"} key={key} isSmall={true} />
            })}
          </div>
        </div>
      </div>
    </>
  )
}