import React from 'react'

export default function CalendarHome() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3087_860)">
                <path d="M19.0303 3.25757H6.64081V4.97632H19.0303V3.25757Z" 
                fill="#263238" />
                <path d="M5.78143 7.51914C5.30682 7.51914 4.92206 7.13438 4.92206 6.65977V0.978516C4.92206 0.503906 5.30682 0.119141 5.78143 0.119141C6.25604 0.119141 6.64081 0.503906 6.64081 0.978516V6.65977C6.64081 7.13445 6.25604 7.51914 5.78143 7.51914Z" 
                fill="#5B9ECF" />
                <path d="M19.8897 7.51914C19.4151 7.51914 19.0303 7.13438 19.0303 6.65977V0.978516C19.0303 0.503906 19.4151 0.119141 19.8897 0.119141C20.3643 0.119141 20.7491 0.503906 20.7491 0.978516V6.65977C20.7491 7.13445 20.3643 7.51914 19.8897 7.51914Z" 
                fill="#5B9ECF" />
                <path d="M34.1157 7.4903C33.6411 7.4903 33.2563 7.10553 33.2563 6.63092V0.949829C33.2563 0.47522 33.6411 0.0904541 34.1157 0.0904541C34.5903 0.0904541 34.9751 0.47522 34.9751 0.949829V6.631C34.9751 7.10553 34.5903 7.4903 34.1157 7.4903Z" 
                fill="#5B9ECF" />
                <path d="M39.1406 3.25757H34.9751V4.97632H38.2812V10.2872H1.71875V4.97632H4.92203V3.25757H0.859375C0.384766 3.25757 0 3.64233 0 4.11694V39.0521C0 39.5267 0.384766 39.9115 0.859375 39.9115H39.1406C39.6152 39.9115 40 39.5267 40 39.0521V4.11694C40 3.64226 39.6152 3.25757 39.1406 3.25757ZM38.2812 38.1926H1.71875V12.0058H38.2812V38.1926Z" 
                fill="#263238" />
                <path d="M33.2563 3.25757H20.7491V4.97632H33.2563V3.25757Z" 
                fill="#263238" />
                <path d="M32.6004 16.3273H7.27911C6.8045 16.3273 6.41974 16.712 6.41974 17.1866V32.7052C6.41974 33.1798 6.8045 33.5646 7.27911 33.5646H32.6003C33.0749 33.5646 33.4597 33.1798 33.4597 32.7052V17.1866C33.4597 16.712 33.075 16.3273 32.6004 16.3273ZM8.13849 23.2188H12.7501V26.6729H8.13849V23.2188ZM14.4688 23.2188H19.0804V26.6729H14.4688V23.2188ZM19.0804 21.5001H14.4688V18.0459H19.0804V21.5001ZM20.7991 18.046H25.4107V21.5002H20.7991V18.046ZM19.0804 28.3916V31.8458H14.4688V28.3916H19.0804ZM20.7991 28.3916H25.4107V31.8458H20.7991V28.3916ZM20.7991 26.6729V23.2188H25.4107V26.6729H20.7991ZM27.1294 23.2188H31.741V26.6729H27.1294V23.2188ZM31.741 21.5001H27.1294V18.0459H31.741V21.5001ZM12.7501 18.046V21.5002H8.13849V18.046H12.7501ZM8.13849 28.3916H12.7501V31.8458H8.13849V28.3916ZM27.1294 31.8458V28.3916H31.741V31.8458H27.1294Z" 
                fill="#263238" />
            </g>
            <defs>
                <clipPath id="clip0_3087_860">
                    <rect width="40" height="40" fill="white" transform="translate(0 0.000976562)" />
                </clipPath>
            </defs>
        </svg>
    )
}
