import React from 'react'
import SkillsContainer from './SkillsContainer'

function SkillSidebar({ skillPortfolio }) {

    return (
        <div className="flex flex-col col-span-2 gap-4">
            <p className="text-[2.2rem] font-bold text-[#051259]">Compétences</p>
            {
                skillPortfolio ?
                    <>
                        <SkillsContainer title={"Compétences sectorielles"} skills={skillPortfolio.sectorSkills} type="sectorielle" />
                        <SkillsContainer title={"Compétences fonctionnelles"} skills={skillPortfolio.functionalSkills} type="fonctionnelle" />
                        <SkillsContainer title={"Compétences informatiques"} skills={skillPortfolio.computerSkills} type="informatique" />
                        <SkillsContainer title={"Compétences linguistiques"} skills={skillPortfolio.linguisticSkills} type="linguistique" />
                    </>
                    :
                    null
            }
        </div>
    )
}

export default SkillSidebar