import { useEffect, useContext, useState } from "react"
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../Context/AuthProvider"
import { isClient, isUser } from 'utils/roles';
import axios from "axios";
import cookie from "js-cookie";
import CardOffre from "./CardOffre";
//Icon
import { CgAdd } from "react-icons/cg";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import { LuLoader2 } from "react-icons/lu";

import Pagination from "Components/Pagination/Pagination";
import Homme_ordinateur_cadenas from "assets/img/missdata/Homme_ordinateur_cadenas.png";

export default function IndexOffre() {
  const { value } = useContext(AuthContext);
  const [offres, setOffres] = useState([]);
  const [status, setStatus] = useState("offreSmall")
  const [recherche, setRecherche] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurentPage] = useState(1);
  const [nbPage, setNbPage] = useState(1);
  const token = cookie.get("token");
  const redirection = useNavigate();

  function fetchOffre() {
    const intitule = recherche !== "" ? `&intitule=${recherche}` : ""
    axios.get(process.env.REACT_APP_API + `client/${status}?page=${currentPage}${intitule}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        setOffres(response.data.dataOffre);
        setNbPage(Math.ceil(response.data.nbOffre / 10));
      })
      .catch((error) => {
      })
      .finally(() => {
        setIsLoading(false)
      });
  }

  useEffect(() => {
    fetchOffre();
  }, [])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchOffre()
    }, 200);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [recherche])


  useEffect(() => {
    setIsLoading(true)
    setOffres([])
    fetchOffre()
  }, [status, currentPage])

  function handleRecherche(e) {
    setRecherche(e.target.value)
  }
  function handleStatus(e) {
    setStatus(e.target.value)
    setCurentPage(1)
  }
  const onPageChangeHandler = (page) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
    setCurentPage(page)
  }

  return (
    <div className="p-8 mb-8 min-h-[calc(100vh-100px)]">
      <div className="flex flex-col md:flex-row justify-between md:items-center">
        <h2>Mes opportunités</h2>
        <div className="flex flex-col md:flex-row gap-4">
          <input onChange={handleRecherche} name='firstname' id='firstname' className='py-2 px-2 ring-1 ring-gray-300 border-lightblue w-full md:w-[500px] rounded-md outline-none focus:ring-[#2563EB] focus:ring-2' placeholder="recherche" type="text" />
          <select onChange={handleStatus} id="genre" name="genre" className='bg-white ring-1 w-full ring-gray-300 py-2 px-2 rounded-md focus:ring-2 focus:ring-[#2563EB] outline-none'>
            <option value="offreSmall">Active</option>
            <option value="offreArchivees">Archivé</option>
          </select>
        </div>
      </div>
      <hr />
      <div className="flex-col lg:flex-row mt-4 flex gap-2 flex-wrap">
        {
          status === "offreSmall" ?
            <div className="border-2 lg:w-[320px] p-4 lg:h-[320px] rounded border-dotted flex justify-center items-center cursor-pointer hover:border-[#2563EB]" onClick={() => redirection("/mon-espace/mes-offres/nouvelle-offre")}>
              <div>
                <div className="text-[#2563EB] flex justify-center">
                  <FaPlus />
                </div>
                <p className="text-[#2563EB] font-medium">Creer une opportunité</p>
              </div>
            </div>
            :
            null
        }
        {offres.map((offre) => {
          return <CardOffre offre={offre} fetchOffre={fetchOffre} />
        })}
        {!isLoading && status === "offreArchivees" && offres.length === 0 &&
          <div className="flex flex-col items-center justify-around mt-16 my-2 mx-auto">
            <img className="w-auto max-h-[250px] opacity-70" src={Homme_ordinateur_cadenas} alt="MyFinPlace.com" />
            <div >
              <h3 className=" px-2 mx-2 font-bold">Aucune opportunité archivée pour le moment</h3>
            </div>
          </div>
        }
        {
          isLoading ?
            <div className="w-[280px] h-[280px] flex justify-center items-center cursor-pointer">
              <div className="text-[#2563EB]">
                <LuLoader2 size={48} className='animate-spin' />
              </div>
            </div>
            :
            null
        }
      </div>
      {
        nbPage > 1 ?
          <div className="mt-4">
            <Pagination totalPages={nbPage} currentPage={currentPage} onPageChange={onPageChangeHandler} MaxSlot={5} />
          </div>
          :
          null
      }
    </div>
  )
}