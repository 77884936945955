import { StyleSheet, Font } from '@react-pdf/renderer';
import Bold from "./Poppins-Bold.ttf";
import Italic from "./Poppins-Italic.ttf";
import Medium from "./Poppins-Medium.ttf";

/* Taille général */
const SizeText = 8;
const SizeTitle = 10;

/********************* Classes de css général ******************* */
const styles = StyleSheet.create({
  anonymous: {
    fontSize: SizeTitle,
  },
  bold: {
    fontFamily: 'Poppins',
  },
  Viewdisponibility: {
    display: 'flex',
    flexDirection: "row",
    gap: 8
  },
  Immediat: {
    borderRadius: 9999,
    backgroundColor: "#00B553",
    fontSize: SizeText,
    height: 8,
    width: 8,
    alignSelf: "center"
  },
  BgImmediat: {
    backgroundColor: '#ECFDF5',
    display: "flex",
    flexDirection: "row",
    color: "#047857",
    padding: "4px 6px",
    gap: 6,
    borderRadius: 6
  },
  Preavis: {
    borderRadius: 9999,
    backgroundColor: "#D80027",
    fontSize: SizeText,
    height: 8,
    width: 8,
    alignSelf: "center"
  },
  BgPreavis: {
    backgroundColor: '#FEF2F2',
    display: "flex",
    flexDirection: "row",
    color: "#B91C1C",
    padding: "4px 6px",
    gap: 6,
    borderRadius: 6
  },
  DateConnue: {
    borderRadius: 9999,
    backgroundColor: "#FFD600",
    fontSize: SizeText,
    height: 8,
    width: 8,
    alignSelf: "center"
  },
  BgDateConnue: {
    backgroundColor: '#FFFBEB',
    display: "flex",
    flexDirection: "row",
    color: "#92400E",
    padding: "4px 6px",
    gap: 6,
    borderRadius: 6
  },
  TextDisponibility: {
    fontSize: SizeTitle,
    alignSelf: "center",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 2
  },
  TitreUser: {
    fontSize: 12,
    textAlign: "left",
    fontFamily: 'Poppins',
    fontWeight: 800
  },
  TitrePdf: {
    textSize: 56,
    textAlign: "left",
    display: 'block',
    fontFamily: 'Poppins',
    color: "#5B9ECF",
  },
  ExpertComptable: {
    fontSize: 12,
    fontFamily: 'Poppins',
    fontStyle: "Italic",
    color: "#263238"
  },
  Tag: {
    backgroundColor: "#EFF5FA",
    textAlign: "center",
    padding: "4px 8px",
    borderRadius: 5,
    color: "#263238",
    fontSize: SizeText,
    fontFamily: 'Poppins',
    fontWeight: 800
  },
  ViewGeneral: {
    borderWidth: 1.5,
    borderStyle: "solid",
    borderColor: "#BDD8EC",
    marginBottom: 21,
    borderRadius: 10,
    padding: 10
  },
  TitreBold: {
    fontFamily: 'Poppins',
    fontSize: SizeTitle,
    fontWeight: 800
  },
  TitleGeneral: {
    fontFamily: 'Poppins',
    marginVertical: 15,
    fontSize: SizeTitle,
    fontWeight: 800
  },
  TitrePresentation: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 800,
    color: "#5B9ECF"
  },
  TitreCompetence: {
    fontFamily: 'Poppins',
    fontSize: SizeText + 1,
    fontWeight: "Medium"
  },
  TitreExpForma: {
    fontFamily: 'Poppins',
    fontSize: SizeText,
    fontWeight: 800
  }
});

// Front custom pour pouvoir utiliser certaines classe que react-pdf ne prend pas en compte
// Pour trouver de nouveau style, télécharger sur : https://github.com/webfontworld/Poppins/tree/main
Font.register({
  family: 'Poppins',
  fonts: [
    {
      src: Bold,
      fontWeight: 800
    },
    {
      src: Italic,
      fontStyle: "Italic"
    },
    {
      src: Medium,
      fontWeight: "Medium"
    }
  ]
});

export { styles, SizeText, SizeTitle };