import React from "react"
import { Text, View } from '@react-pdf/renderer';
import { styles } from "./../Css/styles";

const Competence = ({ data }) => {
  const colors = {
    sectoriellepastel: '#F1FEFD', sectorielledark: '#5F7372', sectoriellelevel: '#00B553',
    fonctionnellepastel: '#F2F8FC', fonctionnelledark: '#417294', fonctionnellelevel: '#2A2675',
    informatiquepastel: '#FDF6FB', informatiquedark: '#8E5F82', informatiquelevel: '#CF5C78',
    linguistiquepastel: '#FEF9EF', linguistiquedark: '#866736', linguistiquelevel: '#F6B12D'
  }
  return (
    <View wrap={false}>
      {/* Flex primaire */}
      <View style={{ display: "flex", flexDirection: "column", gap: 24 }}>
        {/* Flex 1 : Secteur + Fonctionnelle */}
        <View style={{ display: "flex", flexDirection: "row", gap: 12 }}>
          {/* Sectorielle */}
          <View style={{ width: "50%" }}>
            <Text style={styles.TitreBold}>Compétences sectorielles</Text>
            <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 4 }}>
              {data.tags && data.tags.map((usertag, key) =>
                usertag.tag.id_tag_level.level === "Compétences sectorielles" && (
                  <View style={{ marginTop: 4, padding: "4px 8px", gap: 2, display: "flex", flexDirection: "column", backgroundColor: colors.sectoriellepastel }} key={key}>
                    <Text style={{
                      textAlign: "center",
                      borderRadius: 5,
                      color: colors.sectorielledark,
                      fontSize: 8,
                      fontFamily: 'Poppins',
                      fontWeight: 800
                    }}>
                      {usertag.tag.name}
                    </Text>
                    <View style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                      <View style={{ width: 10, height: 1, backgroundColor: usertag.level >= 1 ? colors.sectoriellelevel : "#D9D9D9" }} />
                      <View style={{ width: 10, height: 1, backgroundColor: usertag.level >= 2 ? colors.sectoriellelevel : "#D9D9D9" }} />
                      <View style={{ width: 10, height: 1, backgroundColor: usertag.level >= 3 ? colors.sectoriellelevel : "#D9D9D9" }} />
                      <View style={{ width: 10, height: 1, backgroundColor: usertag.level >= 4 ? colors.sectoriellelevel : "#D9D9D9" }} />
                    </View>
                  </View>
                )
              )}
            </View>
          </View>
          {/* fonctionnelles */}
          <View style={{ width: "50%" }}>
            <Text style={styles.TitreBold}>Compétences fonctionnelles</Text>
            <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 4 }}>
              {data.tags && data.tags.map((usertag, key) =>
                usertag.tag.id_tag_level.level === "Compétences fonctionnelles" && (
                  <View style={{ marginTop: 4, padding: "4px 8px", gap: 2, display: "flex", flexDirection: "column", backgroundColor: colors.fonctionnellepastel }} key={key}>
                    <Text style={{
                      textAlign: "center",
                      borderRadius: 5,
                      color: colors.fonctionnelledark,
                      fontSize: 8,
                      fontFamily: 'Poppins',
                      fontWeight: 800
                    }}>
                      {usertag.tag.name}
                    </Text>
                    <View style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                      <View style={{ width: 10, height: 1, backgroundColor: usertag.level >= 1 ? colors.fonctionnellelevel : "#D9D9D9" }} />
                      <View style={{ width: 10, height: 1, backgroundColor: usertag.level >= 2 ? colors.fonctionnellelevel : "#D9D9D9" }} />
                      <View style={{ width: 10, height: 1, backgroundColor: usertag.level >= 3 ? colors.fonctionnellelevel : "#D9D9D9" }} />
                      <View style={{ width: 10, height: 1, backgroundColor: usertag.level >= 4 ? colors.fonctionnellelevel : "#D9D9D9" }} />
                    </View>
                  </View>
                )
              )}
            </View>
          </View>
        </View>
        {/* Flex 2 : Secteur + Fonctionnelle */}
        <View style={{ display: "flex", flexDirection: "row", gap: 12 }}>
          {/* informatiques */}
          <View style={{ width: "50%" }}>
            <Text style={styles.TitreBold}>Compétences informatiques</Text>
            <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 4 }}>
              {data.tags && data.tags.map((usertag, key) =>
                usertag.tag.id_tag_level.level === "Compétences informatiques" && (
                  <View style={{ marginTop: 4, padding: "4px 8px", gap: 2, display: "flex", flexDirection: "column", backgroundColor: colors.informatiquepastel }}>
                    <Text style={{
                      textAlign: "center",
                      borderRadius: 5,
                      color: colors.informatiquedark,
                      fontSize: 8,
                      fontFamily: 'Poppins',
                      fontWeight: 800
                    }} key={key}>
                      {usertag.tag.name}
                    </Text>
                    <View style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                      <View style={{ width: 10, height: 1, backgroundColor: usertag.level >= 1 ? colors.informatiquelevel : "#D9D9D9" }} />
                      <View style={{ width: 10, height: 1, backgroundColor: usertag.level >= 2 ? colors.informatiquelevel : "#D9D9D9" }} />
                      <View style={{ width: 10, height: 1, backgroundColor: usertag.level >= 3 ? colors.informatiquelevel : "#D9D9D9" }} />
                      <View style={{ width: 10, height: 1, backgroundColor: usertag.level >= 4 ? colors.informatiquelevel : "#D9D9D9" }} />
                    </View>
                  </View>
                )
              )}
            </View>
          </View>
          {/* linguistiques */}
          <View style={{ width: "50%" }}>
            <Text style={styles.TitreBold}>Compétences linguistiques</Text>
            <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 4 }}>
              {data.tags && data.tags.map((usertag, key) =>
                usertag.tag.id_tag_level.level === "Compétences linguistiques" && (
                  <View style={{ marginTop: 4, padding: "4px 8px", gap: 2, display: "flex", flexDirection: "column", backgroundColor: colors.linguistiquepastel }} key={key}>
                    <Text style={{
                      textAlign: "center",
                      borderRadius: 5,
                      color: colors.linguistiquedark,
                      fontSize: 8,
                      fontFamily: 'Poppins',
                      fontWeight: 800
                    }}>
                      {usertag.tag.name}
                    </Text>
                    <View style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                      <View style={{ width: 10, height: 1, backgroundColor: usertag.level >= 1 ? colors.linguistiquelevel : "#D9D9D9" }} />
                      <View style={{ width: 10, height: 1, backgroundColor: usertag.level >= 2 ? colors.linguistiquelevel : "#D9D9D9" }} />
                      <View style={{ width: 10, height: 1, backgroundColor: usertag.level >= 3 ? colors.linguistiquelevel : "#D9D9D9" }} />
                      <View style={{ width: 10, height: 1, backgroundColor: usertag.level >= 4 ? colors.linguistiquelevel : "#D9D9D9" }} />
                    </View>
                  </View>
                )
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default Competence;