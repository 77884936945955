import React from 'react'

function FiltreSvg(props) {
    return (
        <svg className={props.classProps ? props.classProps : ""} width={props.width ? props.width : "18"} height={props.height ? props.height : "18"} viewBox="0 0 17 18" 
        fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.578 5.48708C2.55072 3.80829 4.1004 2.54022 5.93848 1.91898C7.77657 1.29773 9.77779 1.36566 11.5695 2.11011C13.3613 2.85456 14.8214 4.22479 15.678 5.96568C16.5347 7.70656 16.7294 9.69944 16.2261 11.5732C15.7227 13.447 14.5556 15.0741 12.9419 16.1514C11.3282 17.2286 9.378 17.6828 7.45441 17.4292C5.53082 17.1756 3.76494 16.2316 2.48557 14.7729C1.20621 13.3142 0.500552 11.4403 0.5 9.50008" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.5 5.5H1.5V1.5" stroke="#263238" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default FiltreSvg