import CardFormation from 'Components/Formation/CardFormation'



function IndexFormation({ formations,selectFormation,deleteFormation }) {

    return (
        <div>
            <div className='mb-[50px] flex flex-col gap-[30px]'>
                {
                    formations.map((formation ,key) => (
                        <CardFormation key={key} deleteFormation={deleteFormation} selectFormation={selectFormation} formation={formation} />
                    ))
                }
            </div>
        </div >
    )
}

export default IndexFormation