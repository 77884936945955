import React, { useState, useContext } from "react";
import Logo from "../../assets/img/Logo1.png";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "Context/AuthProvider";
import ResponsiveBurger from "./ResponsiveBurger";
import France from "../../assets/svg/France";

import cookie from "js-cookie";
import PrimaryButton from "Components/Buttons/PrimaryButton";

function ResponsiveNavBar() {

    const [openMenu, setOpenMenu] = useState(false)

    const { value } = useContext(AuthContext);
    const redirection = useNavigate();

    // Fonction pour effectuer une déconnexion
    function handleLogout() {
        value.setIsLoggedIn(false);
        value.setPatchKpi(false);
        value.setId();
        value.setUser();
        cookie.remove("token");
        cookie.remove("StatusConnexion");
        cookie.remove("id");
        redirection("/")
    }

    const handleBurgerMenu = () => {
        setOpenMenu(!openMenu)
    }


    return (
        <nav id="navbar" className="fixed top-0 left-0 right-0 bg-white border-b z-50">
            <div className="containerxl py-1 flex justify-between items-center">

                <Link to="/">
                    <img
                        className="w-20 lg:w-20"
                        src={Logo}
                        alt="MyFinPlace.com"
                    />
                </Link>
                <div className="hidden xl:flex justify-between grow">
                    <ul className="list-none flex lg:items-center lg:space-x-8 xl:space-x-10 ml-10">
                        <li className="hover:text-hover">
                            <Link href="" to="/">
                                Accueil
                            </Link>
                        </li>
                        {value.isLoggedIn ? (
                            value.user && (value.user.roles.includes("ROLE_ADMIN") || value.user.roles.includes("ROLE_SUPER_ADMIN") || value.user.roles.includes("ROLE_OPERATEUR")) ? (
                                <li className=" hover:text-hover">
                                    <Link to="/Iris/Home">IRIS</Link>
                                </li>
                            ) : false
                        ) : false
                        }

                        <li className=" hover:text-hover">
                            <Link href="" to="/les-profils">
                                Les profils
                            </Link>
                        </li>
                        <li className=" hover:text-hover mx-4">
                            <Link href="" to="/les-opportunites">
                                Les opportunités
                            </Link>
                        </li>
                        <li className=" hover:text-hover mx-4">
                            <Link href="" to="/tarification-honoraires">
                                Tarifs
                            </Link>
                        </li>
                        <li className=" hover:text-hover mx-4">
                            <Link href="" to="/contact">
                                Nous contacter
                            </Link>
                        </li>
                    </ul>
                    <div className="flex gap-10 ">
                        <div className="px-7 py-9">
                            <Link href='' to="/devenir-consultant">
                                <p className="hover:text-hover not-italic">Devenir consultant</p>
                            </Link>
                        </div>
                        {!value.isLoggedIn ? (
                            <button
                                className="text-white bg-[#5B9ECF] hover:bg-[#497EA6] rounded px-6 py-2.5 self-center"
                                onClick={() => redirection("/Connexion")}
                            >
                                Inscription / Connexion
                            </button>

                        ) : (
                            <div className="flex gap-7">
                                {value.user &&
                                    value.user.roles.includes("ROLE_CLIENT") ? (
                                    <button
                                        className="text-white font-semibold bg-[#5B9ECF] hover:bg-[#497EA6] rounded px-6 py-2.5 self-center"
                                        onClick={() => redirection("/mon-espace/Dashboard")}
                                    >
                                        Mon espace
                                    </button>
                                ) : (
                                    <button
                                        className="text-white font-semibold bg-[#5B9ECF] hover:bg-[#497EA6] rounded px-6 py-2.5 self-center"
                                        onClick={() => redirection("/mon-espace/Dashboard")}
                                    >
                                        Mon espace
                                    </button>
                                )}

                                <button onClick={handleLogout} className="text-middleblue bg-white border border-middleblue hover:bg-middleblue hover:text-white hover:border-middleblue rounded px-6 py-2.5 self-center" >
                                    Déconnexion
                                </button>
                            </div>
                        )}
                        <div className="flex gap-3.5 items-center hidden xl:flex">
                            <France />
                            <p>FR</p>
                        </div>
                    </div>
                </div>
                <ResponsiveBurger setOpenMenu={setOpenMenu} openMenu={openMenu} />
            </div>
            {
                openMenu ?
                    <div className="py-4 containerxl">
                        <ul className="list-none">
                            <li className="hover:text-hover">
                                <Link href="" to="/">
                                    <h3 className="text-center" onClick={handleBurgerMenu}>Accueil</h3>
                                </Link>
                            </li>
                            {value.isLoggedIn ? (
                                value.user && (value.user.roles.includes("ROLE_ADMIN") || value.user.roles.includes("ROLE_SUPER_ADMIN") || value.user.roles.includes("ROLE_OPERATEUR")) ? (
                                    <li className=" hover:text-hover">
                                        <Link href="" to="/Iris/Home">
                                            <h3 className="text-center" onClick={handleBurgerMenu}>IRIS</h3>
                                        </Link>
                                    </li>
                                ) : false
                            ) : false
                            }
                            <li className=" hover:text-hover">
                                <Link href="" to="/les-profils">

                                    <h3 className="text-center" onClick={handleBurgerMenu}>Les profils</h3>
                                </Link>
                            </li>
                            <li className=" hover:text-hover">
                                <Link href="" to="/les-opportunites">

                                    <h3 className="text-center" onClick={handleBurgerMenu}>Les opportunités</h3>
                                </Link>
                            </li>
                            <li className=" hover:text-hover">
                                <Link href="" to="/tarification-honoraires">

                                    <h3 className="text-center" onClick={handleBurgerMenu}>Tarifs</h3>
                                </Link>
                            </li>
                            <li className=" hover:text-hover">
                                <Link href="" to="/contact">
                                    <h3 className="text-center" onClick={handleBurgerMenu}>Nous contacter</h3>
                                </Link>
                            </li>
                            <li className=" hover:text-hover">
                                <Link href='' to="/devenir-consultant">
                                    <h3 className="text-center" onClick={handleBurgerMenu}>Devenir consultant</h3>
                                </Link>
                            </li>
                        </ul>
                        <hr className="my-4" />
                        <div className="mt-4 flex justify-center">
                            {!value.isLoggedIn ? (
                                <button
                                    className="text-white bg-[#5B9ECF] hover:bg-[#497EA6] rounded px-6 py-2.5 self-center"
                                    onClick={() => { handleBurgerMenu(); redirection("/Connexion") }}
                                >
                                    Inscription / Connexion
                                </button>

                            ) : (
                                <div className="flex justify-center gap-7">
                                    <PrimaryButton
                                        text={"Mon espace"}
                                        onClick={() => { handleBurgerMenu(); redirection("/mon-espace/Dashboard") }}
                                    />



                                    <button onResize={() => { }} onClick={() => { handleBurgerMenu(); handleLogout() }} className="text-middleblue font-semibold bg-white border border-middleblue hover:bg-[#F4F4F4] hover:border-middleblue rounded px-6 py-2.5" >
                                        Déconnexion
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    :
                    null
            }
        </nav>
    )
}

export default ResponsiveNavBar