import { useEffect, useState, useContext } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import PrimaryButton from 'Components/Buttons/PrimaryButton'
import { AlertMessageContext } from 'Context/AlertMessageProvider';
import Badge from 'Components/Badge/Badge';
import RadioButton from 'Components/Forms/RadioButton/RadioButton'
import Input from 'Components/Forms/Input/Input'



import Cookies from "js-cookie";
import axios from "axios";
import MobilitySelect from 'Components/MobilitySelect/MobilitySelect';

function PersonalModality({ }) {

  const [contractSelected, setContractSelected] = useState([])
  const [interventionTypeSelected, setInterventionTypeSelected] = useState([])
  const [disponibility, setDisponibility] = useState(null)
  const [mobilities, setMobilities] = useState([])
  const [poste, setPoste] = useState("");
  const [remote, setRemote] = useState(null);
  const [workTime, setWorkTime] = useState([])
  const [daysWork, setDaysWork] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [supraInfo, setSupraInfo] = useState({})
  const [errorsCustom, setErrorsCustom] = useState({})
  const { alert } = useContext(AlertMessageContext);
  const limitPoste = 80;

  const token = Cookies.get("token");


  const [userInformation, setUserInformation, filters, getProfile] = useOutletContext();

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: "onSubmit"
  });

  const handleContract = (e) => {
    if (!contractSelected.includes(e.target.value)) {
      if (contractSelected.length >= 3) {
        return
      }
      setContractSelected(prev => [...prev, e.target.value])
    } else {
      setContractSelected(prevState => prevState.filter(el => el !== e.target.value))
    }
  }

  const handleInterventionType = (e) => {

    if (!interventionTypeSelected.includes(e.target.value)) {
      setInterventionTypeSelected(prev => [...prev, e.target.value])
    } else {
      setInterventionTypeSelected(prevState => prevState.filter(el => el !== e.target.value))
    }
  }

  function handleWorkTime(e) {
    if (!workTime.includes(e.target.value)) {
      setWorkTime(prev => [...prev, e.target.value])
    } else {
      setWorkTime(prevState => prevState.filter(el => el !== e.target.value))
    }
  }
  function handleDayWork(e) {
    if (!daysWork.includes(e.target.value)) {
      setDaysWork(prev => [...prev, e.target.value])
    } else {
      setDaysWork(prevState => prevState.filter(el => el !== e.target.value))
    }
  }

  const handleDisponibility = (e) => {
    setDisponibility(e.target.value)
  }

  function isSupraValid() {
    // Les deux choix principaux ont été sélectionner
    if (supraInfo.expertComptable === null && supraInfo.commissaireCompte === null) {
      setErrorsCustom(false)
      return false
    }
    // Verif inscritOrdre
    if (supraInfo.expertComptable === true && supraInfo.inscritOrdre === null) {
      setErrorsCustom(false)
      return false
    }
    // Verif ordreRegional + supra
    if (supraInfo.expertComptable === true && supraInfo.inscritOrdre === true && (supraInfo.ordreRegional === undefined || supraInfo.ordreRegional === "" || supraInfo.sUPRA === undefined || supraInfo.sUPRA === "")) {
      setErrorsCustom(false)
      return false
    }
    // Verif commissaire au compte
    if (supraInfo.commissaireCompte === true && supraInfo.inscritCNCC === null) {
      setErrorsCustom(false)
      return false
    }
    // Verif compagnieRattache + supra
    if (supraInfo.commissaireCompte === true && supraInfo.inscritCNCC === true && (supraInfo.compagnieRattache === undefined || supraInfo.compagnieRattache === "" || supraInfo.sUPRA === undefined || supraInfo.sUPRA === "")) {
      setErrorsCustom(false)
      return false
    }
    return true;
  }

  const onSubmit = async (formData) => {
    setIsLoading(true)
    try {
      const contract = [...new Set(contractSelected)]
      const tags = userInformation.tags.filter(el => !(el.tag.id === 10 || el.tag.id === 11 || el.tag.id === 12)).map(el => ({
        user: `api/users/${userInformation.id}`,
        tag: `api/tags/${el.tag.id}`,
        level: el.level,
        forCard: el.for_card
      }))
      const interventionTags = interventionTypeSelected.map(el => ({
        user: `api/users/${userInformation.id}`,
        tag: el,
        level: 1
      }))
      const data = {
        workExperience: contract,
        department: formData.department,
        disponibility: disponibility,
        idgrade: [formData.grade],
        jtm: parseInt(formData.jtm),       
        tauxHoraire: parseInt(formData.tauxHoraire),
        salaire: parseInt(formData.salaire),
        timeExperience: formData.timeExperience,
        titre: formData.titre,
        tags: [...tags, ...interventionTags],
        localisation: mobilities,
        remote: remote,
        workTime: workTime,
        daysWork: daysWork,
        expertComptable: supraInfo.expertComptable === true ? true : false,
        inscritOrdre: supraInfo.inscritOrdre === true ? true : false,
        ordreRegional: supraInfo.ordreRegional,
        commissaireCompte: supraInfo.commissaireCompte === true ? true : false,
        inscritCNCC: supraInfo.inscritCNCC === true ? true : false,
        compagnieRattache: supraInfo.compagnieRattache,
        sUPRA: supraInfo.sUPRA
      }

      if (!isSupraValid()) {
        setIsLoading(false)
        return
      }

      if (disponibility == "Date connue") {
        data.availabilityDate = formData.availability_date
      } else {
        data.availabilityDate = null
      }

      if (formData.titre.length >= limitPoste) {
        setIsLoading(false)
        return;
      }

      const res = await editUser(data)
      getProfile()
      alert.successAlert("Votre profil a été mis à jour !");
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }

  }

  const editUser = async (data) => {
    const url = `${process.env.REACT_APP_API}user/infoPerso/modify/${userInformation.id}`
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/merge-patch+json"
      }
    }
    const res = await axios.patch(url, data, header)
    return res
  }

  useEffect(() => {
    if (userInformation) {
      const interventionType = []
      setPoste(userInformation.titre)
      setValue("titre", userInformation.titre)
      setValue("grade", `api/grades/${userInformation.idgrade[0]?.id}`)
      setValue("department", userInformation.department)
      setValue("timeExperience", userInformation.time_experience)
      setValue("salaire", userInformation.salaire)
      setValue("jtm", userInformation.jtm)
      setValue("tauxHoraire", userInformation.tauxHoraire)
      setDisponibility(userInformation.disponibility)
      setRemote(userInformation.remote)
      setWorkTime(userInformation.workTime ? userInformation.workTime : [])
      setDaysWork(userInformation.daysWork ? userInformation.daysWork : []) 
      setValue("availability_date", userInformation.availability_date ? userInformation.availability_date.split('T')[0] : null)
      userInformation.tags.filter(el => el.tag.id === 10 || el.tag.id === 11 || el.tag.id === 12).map(el => {
        interventionType.push(`api/tags/${el.tag.id}`)
      })
      setInterventionTypeSelected(interventionType)
      setMobilities(userInformation.localisation ? userInformation.localisation : [])
      setSupraInfo({
        expertComptable: userInformation.expertComptable === true ? true : false,
        inscritOrdre: userInformation.inscritOrdre === true ? true : false,
        ordreRegional: userInformation.ordreRegional,
        commissaireCompte: userInformation.commissaireCompte === true ? true : false,
        inscritCNCC: userInformation.inscritCNCC === true ? true : false,
        compagnieRattache: userInformation.compagnieRattache,
        sUPRA: userInformation.sUPRA
      })
    }
  }, [userInformation])

  console.log(userInformation)

  useEffect(() => {
    userInformation?.work_experience && setContractSelected(userInformation.work_experience)
  }, [userInformation])

  const boolHandler = (type, value) => {
    let newData = { ...supraInfo };
    newData[type] = value;
    if (value === false) {
      switch (type) {
        case "expertComptable":
          newData["inscritOrdre"] = null;
          newData["ordreRegional"] = null;
          if (newData["compagnieRattache"] === null) {
            newData["sUPRA"] = null;
          }
          break;
        case "inscritOrdre":
          newData["ordreRegional"] = null;
          if (newData["compagnieRattache"] === null) {
            newData["sUPRA"] = null;
          }
          break;
        case "commissaireCompte":
          newData["inscritCNCC"] = null;
          newData["compagnieRattache"] = null;
          if (newData["inscritCNCC"] === null) {
            newData["sUPRA"] = null;
          }
          break;
        case "inscritCNCC":
          newData["compagnieRattache"] = null;
          if (newData["inscritCNCC"] === null) {
            newData["sUPRA"] = null;
          }
          break;
        default:
          break;
      }
    }
    setSupraInfo(newData);
  }

  function ordreRegionalHandler(e) {
    let newData = { ...supraInfo };
    newData["ordreRegional"] = e.target.value;
    setSupraInfo(newData);
  }

  function compagnieRattacheHandler(e) {
    let newData = { ...supraInfo };
    newData["compagnieRattache"] = e.target.value;
    setSupraInfo(newData);
  }

  function supraHandler(e) {
    let newData = { ...supraInfo };
    newData["sUPRA"] = e.target.value;
    setSupraInfo(newData);
  }

  return (
    <div className='bg-white p-4 overflow-auto border'>
      <div className='max-w-[800px] gap-4'>
        <form action="" onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-4'>
            <div className='grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <label htmlFor="" className='block font-medium leading-6 text-gray-900'>Poste</label>
                <input onInput={(e) => { setPoste(e.target.value) }} className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" {...register('titre', { required: "Champ requis" })} />
                {poste && poste.length != null ?
                  <div className={`text-right ${poste.length >= limitPoste && "text-red-500"}`}>{poste.length}/{limitPoste}</div>
                  : <div className="text-right">0/80</div>


                }

                {errors.titre && (
                  <span className="text-red-500 text-xs font-bold mt-1">
                    {errors.titre.message}
                  </span>
                )}
              </div>
              <div className='sm:col-span-3'>
                <label htmlFor="genre" className='block font-medium leading-6 text-gray-900'>Séniorité</label>
                <select id="genre" name="genre" className='w-full bg-white py-2 px-2 ring-1 ring-gray-300  rounded-md focus:ring-2 focus:ring-[#2563EB] outline-none' {...register('grade')}>
                  {
                    filters ?
                      filters.grade.map((grade, key) => (
                        <option key={key} value={`api/grades/${grade.id}`}>{grade.name}</option>
                      ))
                      :
                      null
                  }
                </select>
              </div>
              <div className='sm:col-span-3'>
                <label htmlFor="genre" className='block font-medium leading-6 text-gray-900'>Années d'expérience*</label>
                <select id="genre" name="genre" className='w-full bg-white  ring-1 ring-gray-300 py-2 px-2 rounded-md focus:ring-2 focus:ring-[#2563EB] outline-none' {...register('timeExperience')}>
                  {
                    filters ?
                      filters.timeExperience.map((time, key) => (
                        <option key={key} value={time.name} >{time.name}</option>
                      ))
                      :
                      null
                  }
                </select>
              </div>
              <div className='sm:col-span-3'>
                <label htmlFor="department" className='block font-medium leading-6 text-gray-900'>Localisation</label>
                <select id="department" name="department" className='w-full bg-white ring-1 ring-gray-300 py-2 px-2 rounded-md focus:ring-2 focus:ring-[#2563EB] outline-none' {...register("department")}>
                  <option></option>
                  {
                    filters ?
                      filters.localisation.map((loc, key) => (
                        <option key={key} value={loc.nom} >{loc.nom}</option>
                      ))
                      :
                      null
                  }
                </select>
              </div>
              <div className='sm:col-span-3'>
                <label htmlFor="" className='font-medium leading-6 text-gray-900 flex mb-1 justify-between items-center'>Mobilité <Badge mode={"info"} name={"Max : 5"} /></label>
                <MobilitySelect filters={filters} mobilities={mobilities} setMobilities={setMobilities} />
              </div>
              <div className='sm:col-span-3'>
                <label htmlFor="genre" className='block font-medium leading-6 text-gray-900'>Disponibilité</label>
                <select onChange={(e) => handleDisponibility(e)} id="genre" name="genre" className='w-full bg-white ring-1 ring-gray-300 py-2 px-2 rounded-md focus:ring-2 focus:ring-[#2563EB] outline-none'>
                  <option value="" className="hidden"  ></option>
                  <option selected={disponibility === "immédiate"} value="immédiate">Immédiate</option>
                  <option selected={disponibility === "Date connue"} value="Date connue" >Date connue</option>
                  <option selected={disponibility === "Préavis de 1 mois"} value="Préavis de 1 mois" >Préavis de 1 mois</option>
                  <option selected={disponibility === "Préavis de 2 mois"} value="Préavis de 2 mois" >Préavis de 2 mois</option>
                  <option selected={disponibility === "Préavis de 3 mois"} value="Préavis de 3 mois" >Préavis de 3 mois</option>
                </select>
              </div>
              <div className='sm:col-span-3' >
                {
                  disponibility === "Date connue" &&
                  <div className='sm:col-span-3'>
                    <label htmlFor="genre" className='block font-medium leading-6 text-gray-900'>Date de disponibilité</label>
                    <input name='dateEnd' id='dateEnd' className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="date" {...register("availability_date", { required: "Champ requis" })} />
                    {errors.availability_date && (
                      <span className="text-red-500 text-xs font-bold mt-1">
                        {errors.availability_date.message}
                      </span>
                    )}
                  </div>
                }
              </div>
              <div className='sm:col-span-3'>
                <label htmlFor="genre" className='block font-medium leading-6 text-gray-900'>Présentiel / télétravail</label>
                <div className='flex flex-col gap-2'>
                  <div className='flex flex-col md:flex-row gap-1'>
                    {["Présentiel", "Hybride", "Télétravail"].map((type) => {
                      return <label htmlFor={type} className={`inline-block px-4 py-1 border border-[#5B9ECF] rounded-[5px] text-center hover:cursor-pointer hover:bg-[#5B9ECF] hover:text-white ${remote === type ? "bg-[#5B9ECF] text-white" : "bg-white text-[#5B9ECF]"}`} >
                        <input
                          type="radio"
                          id={type}
                          name={type}
                          value={type}
                          checked={remote === type}
                          onChange={(e) => { setRemote(e.target.value) }}
                          className='hidden'
                        />
                        <span className="font-medium">{type}</span>
                      </label>
                    })}
                  </div>
                </div>
              </div>
              <div className='sm:col-span-3'>
                <label htmlFor="genre" className='block font-medium leading-6 text-gray-900'>Temps plein / partiel / à l'heure</label>
                <div className='flex flex-col gap-2'>
                  <div className='flex flex-col md:flex-row gap-1'>
                    {["Temps plein", "Temps partiel", "À l'heure"].map((type) => {
                      return <label htmlFor={type} className={`inline-block px-4 py-1 border border-[#5B9ECF] rounded-[5px] text-center hover:cursor-pointer hover:bg-[#5B9ECF] hover:text-white ${workTime && workTime.includes(type) ? "bg-[#5B9ECF] text-white" : "bg-white text-[#5B9ECF]"}`} >
                        <input
                          type="checkbox"
                          id={type}
                          name={type}
                          value={type}
                          checked={workTime && workTime.includes(type)}
                          onChange={handleWorkTime}
                          className='hidden'
                        />
                        <span className="font-medium">{type}</span>
                      </label>
                    })}
                  </div>
                </div>
              </div>

              <div className='sm:col-span-3'>
                <div className='flex items-center gap-4'>
                  <p className=' font-medium leading-6 text-gray-900'>Contrat recherché*</p>
                  <Badge mode={"info"} name={"Max : 3"} />

                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2'>
                  {
                    filters ?
                      filters.contrat.map((el, key) => (
                        <div className='flex gap-1 items-center'>
                          <input onChange={(e) => handleContract(e)} checked={contractSelected.includes(el.name)} value={el.name} className='cursor-pointer' type={"checkbox"} key={el.id} id={el.id} />
                          <label className='cursor-pointer' htmlFor={el.id}>{el.name}</label>
                        </div>
                      ))
                      :
                      null
                  }
                </div>
              </div>
              <div className='sm:col-span-3'>
                <label htmlFor="genre" className='block font-medium leading-6 text-gray-900'>Disponibilité</label>
                <div className='flex flex-col gap-2'>
                  <div className='flex flex-col flex-wrap md:flex-row gap-1'>
                    {["Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi","Dimanche"].map((type) => {
                      return <label htmlFor={type} className={`inline-block px-2 py-1 border border-[#5B9ECF] rounded-[5px] text-center hover:cursor-pointer hover:bg-[#5B9ECF] hover:text-white ${daysWork && daysWork.includes(type) ? "bg-[#5B9ECF] text-white" : "bg-white text-[#5B9ECF]"}`} >
                        <input
                          type="checkbox"
                          id={type}
                          name={type}
                          value={type}
                          checked={daysWork && daysWork.includes(type)}
                          onChange={handleDayWork}
                          className='hidden'
                        />
                        <span className="font-medium">{type}</span>
                      </label>
                    })}
                  </div>
                </div>
              </div>
              <div className='sm:col-span-3'>
                <p className=' font-medium leading-6 text-gray-900'>Type d'intervention</p>
                <div className='grid grid-cols-1'>
                  {
                    filters ?
                      filters.intervention.filter(el => el.id === 10 || el.id === 11 || el.id === 12).map((el, key) => (
                        <div className='flex gap-1 items-center'>
                          <input onChange={(e) => handleInterventionType(e)} checked={interventionTypeSelected && interventionTypeSelected.includes("api/tags/" + el.id)} value={`api/tags/${el.id}`} className='cursor-pointer' type={"checkbox"} key={el.id} id={el.id} />
                          <label className='cursor-pointer' htmlFor={el.id}>{el.name}</label>
                        </div>
                      ))
                      :
                      null
                  }
                </div>
                
              <label htmlFor="genre" className='block mt-6 font-medium leading-6 text-gray-900'>Rémunération</label>
              <div className='flex flex-row items-end justify-between gap-6 mb-[20px]'>
              
              <div className='sm:col-span-3'>
                <label htmlFor="" className='block font-medium leading-6 text-gray-900'>Salaire annuel</label>
                <input className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" {...register("salaire")} />
              </div>
              

           <div className='sm:col-span-3'>
                <label htmlFor="" className='block font-medium leading-6 text-gray-900'>Taux journalier moyen</label>
                <input className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" {...register("jtm")} />
              </div> 
            
               <div className='sm:col-span-3'>
                <label htmlFor="" className='block font-medium leading-6 text-gray-900'>Taux horaire</label>
                <input className='py-2 px-2 ring-1 ring-gray-300 border-lightblue rounded-md w-full outline-none focus:ring-[#2563EB] focus:ring-2' type="text" {...register("tauxHoraire")} />
              </div> 
            
          </div>

              </div>

              <div className='col-span-6 flex flex-col justify-between gap-6'>
                {/* expertComptable */}
                <div>
                  <h5 className='text-3xl mb-2'>Êtes vous diplômé d'expertise comptable ?</h5>
                  <div className='flex flex-row gap-6'>
                    <RadioButton onChange={() => boolHandler("expertComptable", true)} key="expertComptableTrue" label="Oui" value={true} checked={supraInfo.expertComptable === true} />
                    <RadioButton onChange={() => boolHandler("expertComptable", false)} key="expertComptableFalse" label="Non" value={false} checked={supraInfo.expertComptable === false} />
                  </div>
                </div>
                {/* inscritOrdre */}
                {supraInfo.expertComptable === true &&
                  <div>
                    <h5 className='text-xl mb-2'>Êtes vous inscrits à l'ordre ?</h5>
                    <div className='flex flex-row gap-6'>
                      <RadioButton onChange={() => boolHandler("inscritOrdre", true)} key="inscritOrdreTrue" label="Oui" value={true} checked={supraInfo.inscritOrdre == true} />
                      <RadioButton onChange={() => boolHandler("inscritOrdre", false)} key="inscritOrdreFalse" label="Non" value={false} checked={supraInfo.inscritOrdre === false} />
                    </div>
                  </div>
                }
                {/* ordreRegional + SUPRA */}
                {supraInfo.expertComptable === true && supraInfo.inscritOrdre === true &&
                  <div>
                    <h5 className='text-xl mb-2'>À quel ordre régional êtes-vous rattaché ?</h5>
                    <div className='flex flex-row gap-6'>
                      <Input name="ordreRegional" value={supraInfo.ordreRegional} onChange={ordreRegionalHandler} />
                    </div>
                    <h5 className='text-xl mb-2 mt-4'>Saisissez votre <b>numéro</b> d'inscription à l'ordre (SUPRA)</h5>
                    <div className='flex flex-row gap-6'>
                      <Input name="sUPRA" value={supraInfo.sUPRA} onChange={supraHandler} />
                    </div>
                  </div>
                }
                <span className='border w-full' />
                {/* commissaireCompte */}
                <div>
                  <h5 className='text-3xl mb-2'>Êtes vous commissaire aux compte ?</h5>
                  <div className='flex flex-row gap-6'>
                    <RadioButton onChange={() => boolHandler("commissaireCompte", true)} key="commissaireCompteTrue" label="Oui" value={true} checked={supraInfo.commissaireCompte === true} />
                    <RadioButton onChange={() => boolHandler("commissaireCompte", false)} key="commissaireCompteFalse" label="Non" value={false} checked={supraInfo.commissaireCompte === false} />
                  </div>
                </div>
                {/* inscritCNCC */}
                {supraInfo.commissaireCompte === true &&
                  <div>
                    <h5 className='text-xl mb-2'>Êtes vous inscrits à la CNCC ?</h5>
                    <div className='flex flex-row gap-6'>
                      <RadioButton onChange={() => boolHandler("inscritCNCC", true)} key="inscritCNCCrue" label="Oui" value={true} checked={supraInfo.inscritCNCC === true} />
                      <RadioButton onChange={() => boolHandler("inscritCNCC", false)} key="inscritCNCCFalse" label="Non" value={false} checked={supraInfo.inscritCNCC === false} />
                    </div>
                  </div>
                }
                {/* compagnieRattache */}
                {supraInfo.commissaireCompte === true && supraInfo.inscritCNCC === true &&
                  <div>
                    <h5 className='text-xl mb-2'>À quelle compagnie êtes-vous rattaché</h5>
                    <div className='flex flex-row gap-6'>
                      <Input name="compagnieRattache" value={supraInfo.compagnieRattache} onChange={compagnieRattacheHandler} />
                    </div>
                    <h5 className='text-xl mb-2 mt-4'>Saisissez votre <b>numéro</b> d'inscription à l'ordre (SUPRA)</h5>
                    <div className='flex flex-row gap-6'>
                      <Input name="sUPRA" value={supraInfo.sUPRA} onChange={supraHandler} />
                    </div>
                  </div>
                }
              </div>

              <div className='sm:col-span-6 flex justify-end'>
                <PrimaryButton isLoading={isLoading} type={"submit"} text={"Sauvegarder"} />
              </div>
            </div>
          </div>
        </form>
      </div >
    </div >
  )
}

export default PersonalModality