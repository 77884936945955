import React from 'react';
import Novaxia from "../../../../assets/img/logo_novaxia.png";
import DemathieuBard from "../../../../assets/img/logo_demathieu_bard.png";
import Icade from "../../../../assets/img/logo_icade.png";
import Stellanttis from "../../../../assets/img/logo_stellanttis.png";
import Paref from "../../../../assets/img/Logo-Paref.png";

export default function Partenaires() {
  return (
    <div className='flex flex-col items-center sm:flex-row flex-wrap sm:justify-between md:justify-center lg:justify-between'>
      <img src={Novaxia} alt="Novaxia" className='object-contain max-w-[180px] bigscreen:max-w-[240px]'  />
      <img src={Stellanttis} alt="Stellanttis" className='object-contain  max-w-[180px] bigscreen:max-w-[240px]' />
      <img src={DemathieuBard} alt="DemathieuBard" className='object-contain  max-w-[180px] bigscreen:max-w-[240px]' />
      <img src={Paref} alt="Paref" className='object-contain  max-w-[180px] bigscreen:max-w-[240px] bigscreen:max-w-[240px]' />
      <img src={Icade} alt="Icade" className='object-contain  max-w-[180px]' />
    </div>
  )
}
