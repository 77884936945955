import React from 'react'

function Input({ onChange, value, name,isReadOnly }) {
    return (
        <input name={name} readOnly={isReadOnly} onChange={(e) => onChange(e)} value={value} type="text" className='py-[10px] px-[10px] border-[1.5px] border-lightblue rounded-[3px] w-full outline-none' />
    )
}


export default Input

