import { useState,useEffect } from 'react'
import PrimaryButton from 'Components/Buttons/PrimaryButton'
import SecondaryButton from 'Components/Buttons/SecondaryButton'
import Filtres from "../../Pages/Statics/Filtres/FiltreMenue"

function MobileFilter2({ handleSearchTerm, search, searchTermQuery, onFilter, handleFilter, handleReset, resetFilters, filterBase }) {
    const [openMenu, setOpenMenu] = useState(false)

    const handleResize = () => {
        setOpenMenu(false);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={`md:hidden fixed bottom-[0px] ${openMenu ? "h-full " : ""} w-full z-[100] px-2 overflow-scroll`}>
            {
                openMenu ?
                    <div className='bg-white'>
                        <Filtres handleSearchTerm={handleSearchTerm} search={search} searchTermQuery={searchTermQuery} onFilter={onFilter} onEmpty={handleReset} resetFilters={resetFilters} base={filterBase} />
                        <div className='flex gap-2 justify-center'>
                            <SecondaryButton text={"Annuler"} onClick={() => setOpenMenu(false)} />
                            <PrimaryButton text={"Filtrer"} onClick={() => setOpenMenu(false)} />
                        </div>
                    </div>
                    :
                    <div className='flex justify-center w-full'>
                        <PrimaryButton text={"Filtres"} onClick={() => setOpenMenu(true)}/>
                    </div>

            }

        </div>
    )
}

export default MobileFilter2