import { FieldGuesser, ListGuesser, EditGuesser, InputGuesser, ShowGuesser, CreateGuesser } from "@api-platform/admin";
import { ReferenceField, TextField, useRecordContext, useCreatePath, DateTimeInput, ReferenceInput, SelectInput, usePermissions, DeleteWithConfirmButton } from "react-admin";
import { Link } from 'react-router-dom';

const ClickOnClient = ({ source }) => {
  const createPath = useCreatePath();
  const record = useRecordContext();
  if (!record) return null;
  return <Link to={createPath({ resource: 'clients', type: 'show', id: record.id })} className="text-[#497EA6] hover:underline">{record[source]}</Link>
};
const ClickOnUser = ({ source }) => {
  const createPath = useCreatePath();
  const record = useRecordContext();
  if (!record) return null;
  return <Link to={createPath({ resource: 'users', type: 'show', id: record.id })} className="text-[#497EA6] hover:underline">{record[source]}</Link>
};
const CustomStatus = () => {
  const record = useRecordContext();
  if (!record) return null;
  switch (record.status) {
    case "planifier":
      return "A planifier";
    case "réaliser":
      return "Réalisé";
    case "programmés":
      return "Programmé";
    case "cancel":
      return "Annuler";
    case "archiver":
      return "Le profil a été refusé";
    case "archiver_win":
      return "Le profil a été retenu";
    default:
      return record.status;
  }
}
const formatTime = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}h${formattedMinutes}`;
};
const FormatDateTime = () => {
  const record = useRecordContext();
  const datetime = new Date(record.date_choice).toLocaleDateString("fr-FR", { day: "2-digit", month: "long", year: "numeric", }) + " à " + formatTime(new Date(record.date_choice))
  return datetime;
}

const ListFilters = [
  <SelectInput name="status" source="status" alwaysOn choices={[
    { id: "planifier", name: "à planifier" },
    { id: "programmés", name: "Programmés" },
    { id: "réalisés", name: "Réalisés" },
    { id: "archiver_win", name: "Profil retenu" },
    { id: "archiver", name: "Profil refusé" },
  ]} />
];

const AppointmentList = (props) => {
  const { permissions } = usePermissions();
  return (
    <ListGuesser {...props} sort={{ field: 'date_choice', order: 'DESC' }} perPage={25} filters={ListFilters}>
      <ReferenceField label="Société" source="idClient" reference="clients">
        <ClickOnClient source="company" />
      </ReferenceField>
      <ReferenceField label="Prénom" source="idClient" reference="clients">
        <ClickOnClient source="first_name" />
      </ReferenceField>
      <ReferenceField label="Nom" source="idClient" reference="clients">
        <ClickOnClient source="last_name" />
      </ReferenceField>
      <ReferenceField label="Ttire" source="idClient" reference="clients">
        <ClickOnClient source="title" />
      </ReferenceField>
      <ReferenceField label="Téléphone" source="idClient" reference="clients">
        <ClickOnClient source="mobile" />
      </ReferenceField>
      <ReferenceField label="Mail" source="idClient" reference="clients">
        <ClickOnClient source="email" />
      </ReferenceField>
      <ReferenceField label="ID" source="idUser" reference="users">
        <ClickOnUser source="anonymous" />
      </ReferenceField>
      <ReferenceField label="Prénom" source="idUser" reference="users">
        <ClickOnUser source="first_name" />
      </ReferenceField>
      <ReferenceField label="Nom" source="idUser" reference="users">
        <ClickOnUser source="last_name" />
      </ReferenceField>
      <ReferenceField label="Téléphone" source="idUser" reference="users">
        <ClickOnUser source="mobile" />
      </ReferenceField>
      <ReferenceField label="Mail" source="idUser" reference="users">
        <ClickOnUser source="email" />
      </ReferenceField>
      <ReferenceField label="Rôles" source="idUser" reference="users">
        <ClickOnUser source="roles" />
      </ReferenceField>
      <FormatDateTime label="Horaire" source="date_choice" />
      <CustomStatus source="status" />
      <FieldGuesser source="validate" />
      {permissions === "ROLE_SUPER_ADMIN" &&
        <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" />
      }
    </ListGuesser>
  );
};
const AppointmentShow = (props) => {
  const { permissions } = usePermissions();
  return (
    <ShowGuesser {...props}>
      {permissions === "ROLE_SUPER_ADMIN" &&
        <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" />
      }
      <ReferenceField label="User" source="idUser" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField label="Client" source="idClient" reference="clients">
        <TextField source="email" />
      </ReferenceField>
      <FormatDateTime label="Horaire" source="date_choice" />
      <FieldGuesser source="validate" />
      <CustomStatus source="status" />
    </ShowGuesser>
  );
};
const AppointmentEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="urlSent" />
    <CustomStatus source="status" />
  </EditGuesser>
);
const AppointmentSuperEdit = (props) => (
  <EditGuesser {...props}>
    <ReferenceInput source="idClient" reference="clients">
      <SelectInput optionText="email" label="Client" />
    </ReferenceInput>
    <ReferenceInput source="idUser" reference="users">
      <SelectInput optionText="email" label="Candidat / Consultant" />
    </ReferenceInput>
    <DateTimeInput source="date_choice" />
    <InputGuesser source="validate" />
    <InputGuesser source="clientAsk" />
    <InputGuesser source="userAsk" />
    <InputGuesser source="urlSent" />
    <SelectInput source="status" choices={[
      { id: "planifier", name: "planifier" },
      { id: "programmés", name: "programmés" },
      { id: "réaliser", name: "réaliser" },
      { id: "archiver", name: "archiver" },
      { id: "archiver_win", name: "archiver_win" },
    ]} />
    <CustomStatus source="status" />
  </EditGuesser>
);
const AppointmentSuperCreate = (props) => (
  <CreateGuesser {...props}>
    <ReferenceInput source="idClient" reference="clients">
      <SelectInput optionText="email" label="Client" />
    </ReferenceInput>
    <ReferenceInput source="idUser" reference="users">
      <SelectInput optionText="email" label="Candidat / Consultant" />
    </ReferenceInput>
    <DateTimeInput source="date_choice" />
    <InputGuesser source="validate" />
    <InputGuesser source="clientAsk" />
    <InputGuesser source="userAsk" />
    <InputGuesser source="urlSent" />
    <SelectInput source="status" choices={[
      { id: "planifier", name: "planifier" },
      { id: "programmés", name: "programmés" },
      { id: "réaliser", name: "réaliser" },
      { id: "archiver", name: "archiver" },
      { id: "archiver_win", name: "archiver_win" },
    ]} />
  </CreateGuesser>
);

export { AppointmentList, AppointmentShow, AppointmentEdit, AppointmentSuperEdit, AppointmentSuperCreate }