import React from 'react'
import Icon1 from 'assets/svg/Socialmedia'
import Icon2 from 'assets/svg/Customer'
import Icon3 from 'assets/svg/Idea'
import Icon4 from 'assets/svg/Clock'
import Icon5 from 'assets/svg/Euro'
import Icon6 from 'assets/svg/Handshake'
import Icon7 from 'assets/svg/CalendarHome'
import Icon8 from 'assets/svg/Diagram'

export default function WhyMyFinPlace() {
    return (
        <section className="">
            <div className="text-center mb-28">
                <h2 className=" text-4xl md:text-5xl font-medium">Pourquoi choisir MyFinPlace ?</h2>
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-y-24 gap-x-8'>
                <div className='flex items-center'>
                    <div className='mr-9'>
                        <Icon1 className='' />
                    </div>
                    <p className="text-justify bigscreen:text-[25px]">L’assurance d’identifier des profils de qualité en France et en Europe et d’attirer des talents de tous secteurs d’activité ayant des compétences en finance</p>
                </div>
                <div className='flex items-center'>
                    <div className='mr-9'>
                        <Icon2 />
                    </div>
                    <p className="text-justify bigscreen:text-[25px]">La possibilité de trouver des profils en consultant ceux qui sont disponibles ou en diffusant des opportunités d’emploi et/ou de mission</p>
                </div>
                <div className='flex items-center'>
                    <div className='mr-9'>
                        <Icon3 />
                    </div>
                    <p className="text-justify  bigscreen:text-[25px]">Une smart platform qui filtre les profils selon des critères exigeants</p>
                </div>
                <div className='flex items-center'>
                    <div className='mr-9'>
                        <Icon4 />
                    </div>
                    <p className=" text-justify  bigscreen:text-[25px]">Des honoraires compétitifs et des pratiques de marché qualitatives et reconnues</p>
                </div>
                <div className='flex items-center'>
                    <div className='mr-9'>
                        <Icon5 />
                    </div>
                    <p className="text-justify bigscreen:text-[25px]">La gratuité pour les profils inscrits sur la plateforme</p>
                </div>
                <div className='flex items-center'>
                    <div className='mr-9'>
                        <Icon6 />
                    </div>
                    <p className=" text-justify bigscreen:text-[25px]">Une mise en relation totalement confidentielle et pleinement sécurisée permettant un gain de temps considérable</p>
                </div>
                <div className='flex items-center'>
                    <div className='mr-9'>
                        <Icon7 />
                    </div>
                    <p className=" text-justify bigscreen:text-[25px]">Un reporting envoyé chaque mois</p>
                </div>
                <div className='flex items-center'>
                    <div className='mr-9'>
                        <Icon8 />
                    </div>
                    <p className="text-justify bigscreen:text-[25px]">Des professionnels du conseil et du recrutement dont l’expertise est au service de la gestion opérationnelle de la plateforme</p>
                </div>
            </div>
            {/* <div className='grid gap-y-24 col-span-4'> */}
            {/* <!-- 1ere ligne --> */}
            {/* <div className="flex items-start gap-x-[225px]">
                    <div className="flex lg:w-1/2 ">
                        <div className='mr-9'>
                            <Icon1 className='' />
                        </div>
                        <div>
                            <p className="text-justify">L’assurance d’identifier des profils de qualité en France et en Europe et d’attirer des talents de tous secteurs d’activité ayant des compétences en finance
                            </p>
                        </div>
                    </div>
                    <div className="flex lg:w-1/2">
                        <div className='mr-9'>
                            <Icon2 className='' />
                        </div>
                        <p className="text-justify">La possibilité de trouver des profils en consultant ceux qui sont disponibles ou en diffusant des offres d’emploi et/ou de mission
                        </p>
                    </div>
                </div> */}
            {/* <!-- 2eme ligne --> */}
            {/* <div className=" flex items-start gap-x-[225px]">
                    <div className="flex lg:w-1/2">
                        <div className=' mr-9'>
                            <Icon3 className='' />
                        </div>
                        <p className=" text-justify">
                            Une smart platform qui filtre les profils selon des critères exigeants
                        </p>
                    </div>
                    <div className="flex lg:w-1/2">
                        <div className='mr-9'>
                            <Icon4 className='' />
                        </div>
                        <p className=" text-justify">Des honoraires compétitifs et des pratiques de marché qualitatives et reconnues</p>
                    </div>
                </div> */}
            {/* <!-- 3eme ligne --> */}
            {/* <div className="flex items-start gap-x-[224px]">
                    <div className="flex lg:w-1/2">
                        <div className='mr-9'>
                            <Icon5 className='' />
                        </div>
                        <p className="  text-justify">La gratuité pour les profils inscrits sur la plateforme</p>
                    </div>
                    <div className="flex lg:w-1/2">
                        <div className='mr-9'>
                            <Icon6 className='' />
                        </div>
                        <p className=" text-justify">Une mise en relation totalement confidentielle et pleinement sécurisée permettant un gain de temps considérable</p>
                    </div>
                </div> */}
            {/* <!-- 4eme ligne --> */}
            {/* <div className="flex items-start gap-x-[225px]">
                    <div className="flex lg:w-1/2">
                        <div className='mr-9'>
                            <Icon7 className='' />
                        </div>
                        <p className=" text-justify">Un reporting envoyé chaque mois</p>
                    </div>
                    <div className="flex lg:w-1/2">
                        <div className='mr-9'>
                            <Icon8 className='' />
                        </div>
                        <p className="text-justify">Des professionnels du conseil et du recrutement dont l’expertise est au service de la gestion opérationnelle de la plateforme</p>
                    </div>
                </div> */}
            {/* </div> */}
        </section>
    )
}