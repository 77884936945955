import React, { useState, useEffect } from "react";
import { Calendar } from "react-date-range";
import { fr } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import dateFormat from "dateformat"
import axios from "axios";

function CalendarComponentMinDate(props) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [holidayDates, setHolidayDates] = useState([]);
  const [weekendDates, setWeekendDates] = useState([]);
  const today = new Date()
  const tomorrow = new Date(today)

  useEffect(() => {
    // Fonction pour récupérer les dates des jours fériés
    const fetchHolidayDates = async () => {
      try {
        const response = await axios.get(
          "https://calendrier.api.gouv.fr/jours-feries/metropole.json"
        );
        const data = response.data;

        // Vérifier si les données sont un objet
        if (typeof data === "object" && data !== null) {
          const dates = Object.keys(data).map((key) => new Date(key));
          setHolidayDates(dates);
        } else {
          console.error(
            "Format de données incorrect. Les données ne sont pas un objet :",
            data
          );
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des jours fériés :",
          error
        );
      }
    };

    fetchHolidayDates();
  }, []);

  useEffect(() => {
    const getWeekendDates = () => {
      const dates = [];
      const startYear = new Date().getFullYear();
      // const startYear = new Date().getFullYear(); // Année de départ (année actuelle)
      const endYear = startYear + 3; // Année de fin (10 années à partir de l'année actuelle)

      for (let year = startYear; year <= endYear; year++) {
        for (let month = 0; month < 12; month++) {
          const firstDay = new Date(year, month, 1);
          const lastDay = new Date(year, month + 1, 0);

          const startDay = firstDay.getDay();

          const firstSaturdayOffset = 6 - startDay;
          const firstSundayOffset = 7 - startDay;

          let currentSaturday = new Date(firstDay);
          currentSaturday.setDate(firstDay.getDate() + firstSaturdayOffset);

          let currentSunday = new Date(firstDay);
          currentSunday.setDate(firstDay.getDate() + firstSundayOffset);

          while (currentSaturday <= lastDay) {
            dates.push(new Date(currentSaturday));
            currentSaturday.setDate(currentSaturday.getDate() + 7);
          }

          while (currentSunday <= lastDay) {
            dates.push(new Date(currentSunday));
            currentSunday.setDate(currentSunday.getDate() + 7);
          }
        }
      }

      return dates;
    };

    const weekendDates = getWeekendDates();
    setWeekendDates(weekendDates);
  }, []);


  const handleDateSelect = (date) => {
    props.onDate(date);
    setSelectedDate(date);
    if (props.id) {
      props.onDate({ date: date, id: props.id });
    }
  };


  return (
    <div className="absolute">
      <Calendar
        date={selectedDate}
        locale={fr}
        onChange={handleDateSelect} // Gérer la sélection de la date
        disabledDates={[...holidayDates, ...weekendDates]} // Bloquer les jours fériés et les weekends
        minDate={tomorrow}
        maxDate={new Date(new Date().getFullYear() + 1, 11, 31)}
        className="border border-2 shadow-md"
      />
    </div>
  );
}

export default CalendarComponentMinDate;