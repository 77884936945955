import React from "react"
import { Text, View } from '@react-pdf/renderer';
import { styles, SizeTitle } from "./../Css/styles";
import Mobile from "./Svg/Mobile";
import FluentWork from "./Svg/FluentWork";
import Group from "./Svg/Group";
import DateTime from "./Svg/DateTime";
import Teletravail from "./Svg/Teletravail";
import TempsPlein from "./Svg/TempsPlein";

const Modalite = ({ data }) => (
  <>
    <View style={{ display: "flex", flexDirection: "row", gap: 12, width: "100%", justifyContent: "space-around", marginTop: 24 }}>
      {/* Seniorité */}
      <View style={{ display: "flex", flexDirection: "column", width: "33%" }}>
        <View style={{ display: "flex", flexDirection: "row", gap: 8, justifyContent: "start" }}>
          <Group />
          <Text style={styles.TitreBold}>
            Seniorité
          </Text>
        </View>
        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "start", gap: 4 }}>
          {data.idgrade.map((value, key) => (
            <View style={{ marginTop: 4 }} key={key}>
              <Text style={styles.Tag}>
                {value.grade_type}
              </Text>
            </View>
          )
          )}
        </View>
      </View>
      {/* Années d’expérience */}
      <View style={{ display: "flex", flexDirection: "column", width: "33%" }}>
        <View style={{ display: "flex", flexDirection: "row", gap: 8, justifyContent: "start" }}>
          <DateTime />
          <Text style={styles.TitreBold}>
            Années d’expérience
          </Text>
        </View>
        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "start", gap: 4 }}>
          <View style={{ marginTop: 4 }}>
            <Text style={styles.Tag}>
              {data.time_experience}
            </Text>
          </View>
        </View>
      </View>
      {/* Présentiel / télétravail */}
      <View style={{ display: "flex", flexDirection: "column", width: "33%" }}>
        <View style={{ display: "flex", flexDirection: "row", gap: 8, justifyContent: "start" }}>
          <Teletravail />
          <Text style={styles.TitreBold}>
            Présentiel / télétravail
          </Text>
        </View>
        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "start", gap: 4 }}>
          <View style={{ marginTop: 4 }}>
            <Text style={styles.Tag}>
              {data.remote ? data.remote : "Non indiqué"}
            </Text>
          </View>
        </View>
      </View>
    </View>
    <View style={{ display: "flex", flexDirection: "row", gap: 12, width: "100%", justifyContent: "space-around", marginTop: 24 }}>
      {/* Type d'intervention */}
      <View style={{ display: "flex", flexDirection: "column", width: "33%" }}>
        <View style={{ display: "flex", flexDirection: "row", gap: 8, justifyContent: "start" }}>
          <FluentWork />
          <Text style={styles.TitreBold}>
            Type d'intervention
          </Text>
        </View>
        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "start", gap: 4 }}>
          {data.tags && data.tags.map((usertag, key) =>
            usertag.tag.id_tag_level.level === "Type d'intervention" && (
              <View style={{ marginTop: 4 }} key={key}>
                <Text style={styles.Tag}>
                  {usertag.tag.name}
                </Text>
              </View>
            )
          )}
        </View>
      </View>
      {/* Temps plein / temps partiel */}
      <View style={{ display: "flex", flexDirection: "column", width: "33%" }}>
        <View style={{ display: "flex", flexDirection: "row", gap: 8, justifyContent: "start" }}>
          <TempsPlein />
          <Text style={styles.TitreBold}>
            Temps plein / temps partiel
          </Text>
        </View>
        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "start", gap: 4 }}>
          {data.workTime && data.workTime.length > 0 ? (
            data.workTime.map(
              (value, key) =>
                <View style={{ marginTop: 4 }} key={key}>
                  <Text style={styles.Tag}>
                    {value}
                  </Text>
                </View>
            )) : (
            <Text style={{ fontSize: 10, marginTop: 5 }}>Non indiqué</Text>
          )
          }
        </View>
      </View>
      {/* Mobilité */}
      <View style={{ display: "flex", flexDirection: "column", width: "33%" }}>
        <View style={{ display: "flex", flexDirection: "row", gap: 8, justifyContent: "start" }}>
          <Mobile />
          <Text style={styles.TitreBold}>
            Mobilité
          </Text>
        </View>
        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "start", gap: 4 }}>
          {data.localisation && data.localisation.length > 0 ? (
            data.localisation.map(
              (value, key) =>
                <View style={{ marginTop: 4 }} key={key}>
                  <Text style={styles.Tag}>
                    {value}
                  </Text>
                </View>
            )) : (
            <Text style={{ fontSize: 10, marginTop: 5 }}>Non indiqué</Text>
          )
          }
        </View>
      </View>
    </View>
  </>
);

export default Modalite;