import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import BroMdp from "assets/svg/BroMdp";
import { Helmet } from "react-helmet";  

export default function MdpOubliee() {
  const [successMessage, setSuccessMessage] = useState(""); // State for success message

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: { email: "" },
  });

  const onSubmit = async (data) => {
    await axios
      .post(
        process.env.REACT_APP_API + "request_send_mail",
        data,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        setSuccessMessage({
          message: "Un mail vient de vous etre envoyé avec succès.",
          thankYouMessage: "Nous vous conseillons de consulter vos courriers indésirables."
        });
        reset();
        setTimeout(() => {
          setSuccessMessage("");
        }, 9000);
      })
      .catch((error) => error);
  };

  return (
    <div className="containerxl flex flex-col items-center py-8">
      <Helmet>  
<title>MyFinPlace - Réinitialisation mot de passe  </title>  
</Helmet>  
      <div className="mb-[30px] text-center">
        <h3 className="robotoSlab font-medium">
          Réinitialisation de votre mot de passe
        </h3>
      </div>
      <div className="flex flex-col items-center bg-[#EFF5FA] rounded-[20px] p-[10px] w-full sm:w-[390px]">
        <div className="mt-[20px]">
          <BroMdp />
        </div>
        <div>
          <form action="submit" className="" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col mt-[35px]">
              <span className="text-[15px] font-medium">
                {" "}
                Votre adresse mail
              </span>
              <input
                type="email"
                name="email"
                id="email"
                className={`md:w-[300px] px-4 py-1 border-[1.5px] border-[#BDD8EC] rounded-[25px]
                              ${errors.email && ""}
                            `}
                {...register("email", {
                  required: "Champ requis",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Email non valide",
                  },
                })}
              />
              <div className="flex flex-col">
                {errors.email && (
                  <span className="text-red-500">{errors.email.message}</span>
                )}
              </div>
            </div>
            <div className="text-center mt-[40px] mb-[65px]">
              <button className=" bg-[#5B9ECF] text-white font-bold px-6 pb-1.5 rounded border-1 border-[#5B9ECF]  hover:cursor-pointer ">
                <p className="mt-2"> Réinitialiser</p>
              </button>
              {successMessage && (
                <div
                  className="mt-6 bg-[#E7EED8] border-t-4 border-[#5EB0AB] rounded-b px-4 py-3 shadow-md"
                  role="alert"
                >
                  <div className="flex">
                    <div className="py-1">
                      <svg
                        className="h-6 w-6  mr-4"
                        fill="#5EB0AB"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                      </svg>
                    </div>
                    <div className="flex flex-col">
                      <div className="text-sm font-bold robotoSlab">{successMessage.message}</div>
                      <div className="text-sm">{successMessage.thankYouMessage}</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className="">
        <Link to={"/Connexion"}>
          <div className="text-[14px] text-[#5B9ECF] mt-[7px]">
            Se connecter
          </div>
        </Link>
      </div>
    </div>
  );
}