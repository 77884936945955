import { useNavigate, useOutletContext } from 'react-router-dom'
import PrimaryButton from 'Components/Buttons/PrimaryButton'
import SecondaryButton from 'Components/Buttons/SecondaryButton'
import RadioButton from 'Components/Forms/RadioButton/RadioButton'
import Input from 'Components/Forms/Input/Input'
import { useEffect, useState } from 'react'

export default function Supra() {
  const [data, setData] = useOutletContext()
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Les deux choix principaux ont été sélectionner
    if (data.expertComptable === null && data.commissaireCompte === null) {
      setIsValid(false)
      return
    }
    // Verif inscritOrdre
    if (data.expertComptable === true && data.inscritOrdre === null) {
      setIsValid(false)
      return
    }
    // Verif ordreRegional + supra
    if (data.expertComptable === true && data.inscritOrdre === true && (data.ordreRegional === null || data.ordreRegional === "" || data.supra === null || data.supra === "")) {
      setIsValid(false)
      return
    }
    // Verif commissaire au compte
    if (data.commissaireCompte === true && data.inscritCNCC === null) {
      setIsValid(false)
      return
    }
    // Verif compagnieRattache + supra
    if (data.commissaireCompte === true && data.inscritCNCC === true && (data.compagnieRattache === null || data.compagnieRattache === "" || data.supra === null || data.supra === "")) {
      setIsValid(false)
      return
    }
    setIsValid(true)
  }, [data])

  const boolHandler = (type, value) => {
    let newData = { ...data };
    newData[type] = value;
    if (value === false) {
      switch (type) {
        case "expertComptable":
          newData["inscritOrdre"] = null;
          newData["ordreRegional"] = null;
          if (newData["compagnieRattache"] === null) {
            newData["sUPRA"] = null;
          }
          break;
        case "inscritOrdre":
          newData["ordreRegional"] = null;
          if (newData["compagnieRattache"] === null) {
            newData["sUPRA"] = null;
          }
          break;
        case "commissaireCompte":
          newData["inscritCNCC"] = null;
          newData["compagnieRattache"] = null;
          if (newData["inscritCNCC"] === null) {
            newData["sUPRA"] = null;
          }
          break;
        case "inscritCNCC":
          newData["compagnieRattache"] = null;
          if (newData["inscritCNCC"] === null) {
            newData["sUPRA"] = null;
          }
          break;
        default:
          break;
      }
    }
    setData(newData);
  }

  function ordreRegionalHandler(e) {
    let newData = { ...data };
    newData["ordreRegional"] = e.target.value;
    setData(newData);
  }

  function compagnieRattacheHandler(e) {
    let newData = { ...data };
    newData["compagnieRattache"] = e.target.value;
    setData(newData);
  }

  function supraHandler(e) {
    let newData = { ...data };
    newData["supra"] = e.target.value;
    setData(newData);
  }

  return (
    <div className='flex-1 max-w-[750px] bg-white p-4'>
      <div className='flex flex-col justify-between gap-6 mb-[20px]'>
        {/* expertComptable */}
        <div>
          <h5 className='text-3xl mb-2'>Êtes vous diplômé d'expertise comptable ?</h5>
          <div className='flex flex-row gap-6'>
            <RadioButton onChange={() => boolHandler("expertComptable", true)} key="expertComptableTrue" label="Oui" value={true} checked={data.expertComptable === true} />
            <RadioButton onChange={() => boolHandler("expertComptable", false)} key="expertComptableFalse" label="Non" value={false} checked={data.expertComptable === false} />
          </div>
        </div>
        {/* inscritOrdre */}
        {data.expertComptable === true &&
          <div>
            <h5 className='text-xl mb-2'>Êtes vous inscrits à l'ordre ?</h5>
            <div className='flex flex-row gap-6'>
              <RadioButton onChange={() => boolHandler("inscritOrdre", true)} key="inscritOrdreTrue" label="Oui" value={true} checked={data.inscritOrdre == true} />
              <RadioButton onChange={() => boolHandler("inscritOrdre", false)} key="inscritOrdreFalse" label="Non" value={false} checked={data.inscritOrdre === false} />
            </div>
          </div>
        }
        {/* ordreRegional + SUPRA */}
        {data.expertComptable === true && data.inscritOrdre === true &&
          <div>
            <h5 className='text-xl mb-2'>À quel ordre régional êtes-vous rattaché ?</h5>
            <div className='flex flex-row gap-6'>
              <Input name="ordreRegional" value={data.ordreRegional} onChange={ordreRegionalHandler} />
            </div>
            <h5 className='text-xl mb-2 mt-4'>Saisissez votre <b>numéro</b> d'inscription à l'ordre (SUPRA)</h5>
            <div className='flex flex-row gap-6'>
              <Input name="sUPRA" value={data.supra} onChange={supraHandler} />
            </div>
          </div>
        }
        <span className='border w-full' />
        {/* commissaireCompte */}
        <div>
          <h5 className='text-3xl mb-2'>Commissaire aux comptes</h5>
          <div className='flex flex-row gap-6'>
            <RadioButton onChange={() => boolHandler("commissaireCompte", true)} key="commissaireCompteTrue" label="Oui" value={true} checked={data.commissaireCompte === true} />
            <RadioButton onChange={() => boolHandler("commissaireCompte", false)} key="commissaireCompteFalse" label="Non" value={false} checked={data.commissaireCompte === false} />
          </div>
        </div>
        {/* inscritCNCC */}
        {data.commissaireCompte === true &&
          <div>
            <h5 className='text-xl mb-2'>Êtes vous inscrit à la CNCC ?</h5>
            <div className='flex flex-row gap-6'>
              <RadioButton onChange={() => boolHandler("inscritCNCC", true)} key="inscritCNCCrue" label="Oui" value={true} checked={data.inscritCNCC === true} />
              <RadioButton onChange={() => boolHandler("inscritCNCC", false)} key="inscritCNCCFalse" label="Non" value={false} checked={data.inscritCNCC === false} />
            </div>
          </div>
        }
        {/* compagnieRattache */}
        {data.commissaireCompte === true && data.inscritCNCC === true &&
          <div>
            <h5 className='text-xl mb-2'>À quelle compagnie êtes-vous rattachée</h5>
            <div className='flex flex-row gap-6'>
              <Input name="compagnieRattache" value={data.compagnieRattache} onChange={compagnieRattacheHandler} />
            </div>
            <h5 className='text-xl mb-2 mt-4'>Saisissez votre <b>numéro</b> d'inscription à l'ordre (SUPRA)</h5>
            <div className='flex flex-row gap-6'>
              <Input name="sUPRA" value={data.supra} onChange={supraHandler} />
            </div>
          </div>
        }
      </div>
      <div className='flex justify-between mt-8'>
        <SecondaryButton onClick={() => navigate("/parcours-inscription/experiences")} text={"précédent"} />
        <PrimaryButton onClick={() => navigate("/parcours-inscription/visibilite")} disabled={!isValid} text={"suivant"} />
      </div>
    </div>
  )
}