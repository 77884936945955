import { CreateGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser } from "@api-platform/admin";
import { usePermissions, DeleteWithConfirmButton, SelectInput, EditGuesser, TextInput, useRecordContext } from "react-admin";

const MailTo = () => {
  const record = useRecordContext();
  return <a className='text-[#497EA6] hover:underline' href={`mailto:${record.email}`}>{record.email}</a>
}

const ListFilters = [
  <TextInput label="Company" source="company" alwaysOn />,
  <TextInput label="Prénom" source="first_name" alwaysOn />,
  <TextInput label="Nom" source="last_name" alwaysOn />,
  <TextInput label="Email" source="email" alwaysOn />,
  <TextInput label="Mobile" source="mobile" alwaysOn />
];

const ClientList = (props) => {
  const { permissions } = usePermissions();
  return (
    <ListGuesser {...props} sort={{ field: 'date_time_creation', order: 'DESC' }} perPage={25} filters={ListFilters}>
      <FieldGuesser label="Société" source="company" />
      <FieldGuesser label="Prénom" source="first_name" />
      <FieldGuesser label="Nom" source="last_name" />
      <FieldGuesser label="Titre" source="title" />
      <FieldGuesser label="Téléphone" source="mobile" />
      <MailTo label="Mail" source="email" />
      <FieldGuesser label="Rôles" source="roles" />
      <FieldGuesser label="date de création" source="date_time_creation" />
      {permissions === "ROLE_SUPER_ADMIN" &&
        <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" />
      }
    </ListGuesser>
  );
};

const ClientShow = (props) => {
  const { permissions } = usePermissions();
  return (
    <ShowGuesser {...props}>
      {permissions === "ROLE_SUPER_ADMIN" &&
        <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" />
      }
      <FieldGuesser label="adresse mail" source="email" />
      <FieldGuesser label="prénom" source="first_name" />
      <FieldGuesser label="nom" source="last_name" />
      <FieldGuesser label="téléphone" source="mobile" />
      <FieldGuesser label="titre" source="title" />
      <FieldGuesser label="compagnie" source="company" />
      <FieldGuesser label="date de création de l'utilisateur" source="date_time_creation" />
      <FieldGuesser label="Roles" source="roles" />
      <FieldGuesser label="SUPRA" source="number_supra" />
    </ShowGuesser>
  );
};
const ClientSuperCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source="email" />
    <InputGuesser source="password" />
    <InputGuesser source="first_name" />
    <InputGuesser source="last_name" />
    <InputGuesser source="company" />
    <SelectInput source="roles" choices={[
      { id: "entreprise", name: "entreprise" },
      { id: "Cabinet-conseil", name: "Cabinet-conseil" },
      { id: "Cabinet-daudit-dexpertise-comptable", name: "Cabinet-daudit-dexpertise-comptable" }
    ]} />
  </CreateGuesser>
);

const ClientEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="company" label="Société" />
  </EditGuesser>
);


export { ClientList, ClientShow, ClientSuperCreate, ClientEdit }