import React from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";


function Realiser() {
 

    return (
        <div>
        <span className='text-sm  text-[#00C455]'><IoIosCheckmarkCircle /> </span>
        </div>
    );
}

export default Realiser;
