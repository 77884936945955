import React from "react";

export default function Vector(props) {
    return (
        <svg
            className={props.classProps ? props.classProps : ""}
            width={props.width ? props.width : "13"} height={props.height ? props.height : "7"}
            viewBox="0 0 13 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.4498 1.59375L7.3748 5.66875C6.89355 6.15 6.10605 6.15 5.6248 5.66875L1.5498 1.59375"
                stroke={props.color ? props.color : `#263238`}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
