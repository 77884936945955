
import { IoChevronDownOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

export default function Card1({ isActive, setActive }) {
  return (
    <div className="border rounded-[8px] border-[#2632384D] border-[4px] py-4 px-6">
      <h1 onClick={() => setActive({ Question1: !isActive.Question1 })} className="font-semibold text-[#263238] cursor-pointer flex gap-2  "  >
        <div className="flex justify-between w-full items-center">
          <p className="text-[32px] text-black font-bold">Qu’est-ce que <span className="text-[#5B9ECF]">Le CAB</span> ?</p>
          <div className={isActive.Question1 ? "rotate-180 transition duration-500" : "transition duration-500"}>
            <IoChevronDownOutline className="text-[#5B9ECF]" />
          </div>
        </div>
      </h1>
      <div className={isActive.Question1 ? "animation-box-visible" : "animation-box-hidden "} id="box1">
        <div className="pr-5 text-justify animation-content" id="content2">
          <p className="text-[20px] font-medium mt-8">Le Cab est un cabinet d’expertise-comptable et de commissariat aux comptes dénommé « MyFinPlace Audit & Expertise », inscrit au tableau de l’ordre de Paris IDF sous le numéro 140002776201.</p>
          <p className="text-[20px] font-medium mt-4">Dans le cadre de nos prestations, nous proposons une offre en expertise comptable et en audit.</p>
          <p className="text-[24px] text-black font-bold mt-12">Nos <span className="text-[#5B9ECF]">missions</span></p>
          <p className="text-[20px] font-medium mt-4">Expertise :</p>
          <ul className="pl-8">
            <li className="text-[20px] font-medium">Toutes missions entrant dans le cadre de l’ordonnance de 1945 relative, à la tenue, l’analyse, l’organisation, les procédures de la comptabilité.</li>
            <li className="text-[20px] font-medium">Direction financière externalisée</li>
            <li className="text-[20px] font-medium">Mission de management de transition au sein d’une direction comptable</li>
          </ul>
          <p className="text-[20px] font-medium mt-8">Audit :</p>
          <ul className="pl-8">
            <li className="text-[20px] font-medium">Audit légal des comptes</li>
            <li className="text-[20px] font-medium">Audit contractuel</li>
            <li className="text-[20px] font-medium">Commissariat aux apports</li>
            <li className="text-[20px] font-medium">Commissariat à la transformation</li>
            <li className="text-[20px] font-medium">Due diligence, audit d’acquisition</li>
          </ul>
          <p className="text-[24px] text-black font-bold mt-12">Nos <span className="text-[#5B9ECF]">clients</span></p>
          <ul className="pl-8">
            <li className="text-[20px] font-medium">Les entreprises de toutes tailles soucieuses d’être bien accompagnées dans leur pilotage grâce à la vision d’un expert.</li>
            <li className="text-[20px] font-medium">Les cabinets d’expertise comptable et de commissariats aux comptes ayant besoin de renforcer leur équipe le temps d’une mission, ou cherchant l’intervention d’un expert sur un sujet particulier.</li>
          </ul>
          <div className="text-center my-8">
            <a href="mailto:sandrine.cohen-solal@myfinplace.com" className="bg-[#5B9ECF] hover:bg-[#2D77AD] text-[19px] text-white py-2 px-8 rounded-[5px] inline-block">Je souhaite prendre rendez-vous</a>
          </div>
        </div>
      </div>
    </div>
  )
}