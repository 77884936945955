import { Svg, Path } from '@react-pdf/renderer';

const School = () => (
  <Svg
    width="18"
    height="18"
    viewBox="0 0 32 32"
    fill="white"
    xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M16 9.6L24.5333 13.8667V30.9333H7.46667V13.8667L16 9.6ZM16 9.6V0M0 30.9333H32M3.2 30.9333V18.1333H7.46667M28.8 30.9333V18.1333H24.5333M13.8667 30.9333V24.5333H18.1333V30.9333M16 1.06667H22.4V5.33333H16M16 20.2667C15.4342 20.2667 14.8916 20.0419 14.4915 19.6418C14.0914 19.2418 13.8667 18.6991 13.8667 18.1333C13.8667 17.5675 14.0914 17.0249 14.4915 16.6248C14.8916 16.2248 15.4342 16 16 16C16.5658 16 17.1084 16.2248 17.5085 16.6248C17.9086 17.0249 18.1333 17.5675 18.1333 18.1333C18.1333 18.6991 17.9086 19.2418 17.5085 19.6418C17.1084 20.0419 16.5658 20.2667 16 20.2667Z"
      stroke="#BDD8EC"
    />
  </Svg>
)

export default School