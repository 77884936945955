import React, { useState } from "react";
import CabinetDeConseil from "./../Insription/Components/CabinetDeConseil";
import CabinetDeRecrutement from "./../Insription/Components/CabinetDeRecrutement";
import Candidat from "./../Insription/Components/Candidat";
import Consultant from "./../Insription/Components/Consultant";
import Entreprise from "./../Insription/Components/Entreprise";
import Etudiant from "./../Insription/Components/Etudiant";
import All from "./../Insription/Components/All";
import Formulaire from "./Formulaire";

export default function RegistrationGoogle() {
  const [sideText, setSideText] = useState("");
  return (
    <div className="flex flex-col gap-4 lg:gap-0 items-center lg:flex-row pt-24 lg:pt-24 mx-8 lg:mx-24 xl:mx-44 pb-8">
      <div className="relative hidden lg:block lg:w-[70%] bg-greylight border-2 border-lightblue rounded-lg px-14 py-16">
        {
          sideText === "entreprise" ? <Entreprise /> :
            sideText === "Cabinet-conseil" ? <CabinetDeConseil /> :
              sideText === "Cabinet-daudit-dexpertise-comptable" ? <CabinetDeRecrutement /> :
                sideText === "Candidat" ? <Candidat /> :
                  sideText === "Consultant" ? <Consultant /> :
                    sideText === "Etudiant" ? <Etudiant /> : <All />
        }
      </div>
      <div className="lg:min-w-[470px] px-4 md:px-0 -ml-[2px] z-10">
        <div className="bg-white border-2 border-lightblue px-3 sm:px-12 py-8 rounded-xl">
          <h3 className="robotoSlab font-bold text-center">Inscrivez-vous !</h3>
          <Formulaire setSideText={setSideText} />
        </div>
      </div>
    </div>
  )
}