import React from 'react'

function diagram() {
    return (

        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3087_926)">
                <path d="M36.5991 0.000976562H27.6245C25.7493 0.000976562 24.2237 1.5266 24.2237 3.40184V13.157C25.2892 13.8144 26.1903 14.7135 26.8502 15.7773H36.5992C38.4745 15.7773 40.0001 14.2517 40.0001 12.3764V3.40184C40 1.5266 38.4744 0.000976562 36.5991 0.000976562ZM38.2812 12.3764C38.2812 13.3039 37.5266 14.0586 36.5991 14.0586H25.9423V3.40184C25.9423 2.47434 26.697 1.71973 27.6245 1.71973H36.5991C37.5266 1.71973 38.2812 2.47434 38.2812 3.40184V12.3764Z" fill="#5B9ECF" />
                <path d="M13.1832 24.2246H3.40086C1.52562 24.2246 0 25.7503 0 27.6255V36.6002C0 38.4754 1.52562 40.001 3.40086 40.001H12.3755C14.2507 40.001 15.7763 38.4754 15.7763 36.6002V26.8241C14.7254 26.1686 13.8362 25.2773 13.1832 24.2246ZM14.0577 36.6002C14.0577 37.5277 13.303 38.2823 12.3755 38.2823H3.40086C2.47336 38.2823 1.71875 37.5277 1.71875 36.6002V27.6255C1.71875 26.698 2.47336 25.9434 3.40086 25.9434H14.0576V36.6002H14.0577Z" fill="#5B9ECF" />
                <path d="M9.83406 8.97571H6.0218C5.54719 8.97571 5.16242 9.36047 5.16242 9.83508C5.16242 10.3097 5.54719 10.6945 6.0218 10.6945H9.83406C10.3087 10.6945 10.6934 10.3097 10.6934 9.83508C10.6934 9.36047 10.3087 8.97571 9.83406 8.97571Z" fill="#263238" />
                <path d="M30.1659 10.6945H33.9782C34.4528 10.6945 34.8376 10.3097 34.8376 9.83508C34.8376 9.36047 34.4528 8.97571 33.9782 8.97571H30.1659C29.6913 8.97571 29.3066 9.36047 29.3066 9.83508C29.3066 10.3097 29.6913 10.6945 30.1659 10.6945Z" fill="#263238" />
                <path d="M36.5991 24.2246H26.8502C27.612 22.9965 28.0526 21.5493 28.0526 20.001C28.0526 15.5699 24.4477 11.965 20.0167 11.965C18.4613 11.965 17.008 12.4096 15.7764 13.1779V3.40184C15.7764 1.5266 14.2507 0.000976562 12.3755 0.000976562H3.40086C1.52562 0.000976562 0 1.5266 0 3.40184V12.3764C0 14.2517 1.52562 15.7773 3.40086 15.7773H13.1831C12.4213 17.0054 11.9807 18.4525 11.9807 20.0009C11.9807 24.432 15.5856 28.0368 20.0166 28.0368C21.558 28.0368 22.9991 27.6003 24.2236 26.8448V36.6C24.2236 38.4752 25.7492 40.0008 27.6245 40.0008H36.5991C38.4744 40.001 40 38.4753 40 36.6001V27.6255C40 25.7503 38.4744 24.2246 36.5991 24.2246ZM1.71875 12.3764V3.40184C1.71875 2.47434 2.47336 1.71973 3.40086 1.71973H12.3755C13.303 1.71973 14.0576 2.47434 14.0576 3.40184V14.0586H3.40086C2.47336 14.0586 1.71875 13.3039 1.71875 12.3764ZM13.6995 20.001C13.6995 16.5176 16.5334 13.6838 20.0167 13.6838C23.5 13.6838 26.3338 16.5176 26.3338 20.001C26.3338 23.4843 23.5 26.3182 20.0167 26.3182C16.5334 26.3182 13.6995 23.4843 13.6995 20.001ZM38.2812 36.6001C38.2812 37.5276 37.5266 38.2822 36.5991 38.2822H27.6245C26.697 38.2822 25.9424 37.5276 25.9424 36.6001V25.9433H36.5991C37.5266 25.9433 38.2812 26.6979 38.2812 27.6254V36.6001Z" fill="#263238" />
                <path d="M9.83406 33.1198H6.0218C5.54719 33.1198 5.16242 33.5045 5.16242 33.9791C5.16242 34.4537 5.54719 34.8385 6.0218 34.8385H9.83406C10.3087 34.8385 10.6934 34.4537 10.6934 33.9791C10.6934 33.5045 10.3087 33.1198 9.83406 33.1198Z" fill="#263238" />
                <path d="M33.9782 33.1198H30.1659C29.6913 33.1198 29.3066 33.5045 29.3066 33.9791C29.3066 34.4537 29.6913 34.8385 30.1659 34.8385H33.9782C34.4528 34.8385 34.8376 34.4537 34.8376 33.9791C34.8376 33.5045 34.4528 33.1198 33.9782 33.1198Z" fill="#263238" />
                <path d="M21.2708 21.0477H18.7293C18.2547 21.0477 17.8699 21.4325 17.8699 21.9071C17.8699 22.3817 18.2547 22.7665 18.7293 22.7665H21.2708C21.7454 22.7665 22.1302 22.3817 22.1302 21.9071C22.1302 21.4325 21.7454 21.0477 21.2708 21.0477Z" fill="#263238" />
                <path d="M7.92797 6.88251C8.40267 6.88251 8.7875 6.49769 8.7875 6.02298C8.7875 5.54828 8.40267 5.16345 7.92797 5.16345C7.45326 5.16345 7.06844 5.54828 7.06844 6.02298C7.06844 6.49769 7.45326 6.88251 7.92797 6.88251Z" fill="#263238" />
                <path d="M32.0719 6.88232C32.5465 6.88232 32.9312 6.49757 32.9312 6.02295C32.9312 5.54833 32.5465 5.16357 32.0719 5.16357C31.5972 5.16357 31.2125 5.54833 31.2125 6.02295C31.2125 6.49757 31.5972 6.88232 32.0719 6.88232Z" fill="#263238" />
                <path d="M32.072 31.0258C32.5465 31.0258 32.9312 30.6411 32.9312 30.1666C32.9312 29.6921 32.5465 29.3075 32.072 29.3075C31.5975 29.3075 31.2129 29.6921 31.2129 30.1666C31.2129 30.6411 31.5975 31.0258 32.072 31.0258Z" fill="#263238" />
                <path d="M7.92805 31.026C8.40267 31.026 8.78742 30.6412 8.78742 30.1666C8.78742 29.692 8.40267 29.3073 7.92805 29.3073C7.45343 29.3073 7.06867 29.692 7.06867 30.1666C7.06867 30.6412 7.45343 31.026 7.92805 31.026Z" fill="#263238" />
                <path d="M20 18.9542C20.4746 18.9542 20.8594 18.5695 20.8594 18.0948C20.8594 17.6202 20.4746 17.2355 20 17.2355C19.5254 17.2355 19.1406 17.6202 19.1406 18.0948C19.1406 18.5695 19.5254 18.9542 20 18.9542Z" fill="#263238" />
            </g>
            <defs>
                <clipPath id="clip0_3087_926">
                    <rect width="40" height="40" fill="white" transform="translate(0 0.000976562)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default diagram