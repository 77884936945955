import { Text, View, Image } from '@react-pdf/renderer';
import Logo from "assets/img/Logo1.png";
import { styles } from "./../Css/styles";

const TopLeft = ({ data }) => {

  function DisponibilityCard() {
    const disponibilityNormalized = data.disponibility.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    if (disponibilityNormalized === "immediate") {
      return (
        <View style={styles.BgImmediat}>
          <View style={styles.Immediat}></View>
          <View style={styles.TextDisponibility}>
            <Text>Immédiate</Text>
          </View>
        </View>
      )
    }
    if (data.disponibility.startsWith("Préavis")) {
      return (
        <View style={styles.BgPreavis}>
          <View style={styles.Preavis}></View>
          <View style={styles.TextDisponibility}>
            {data.disponibility.split(" ").map((word) => (
              <Text>{word}</Text>
            ))}
          </View>
        </View>
      )
    }
    if (data.disponibility === "Date connue") {
      if (data.availability_date === null) {
        return (
          <View>
            <View style={styles.DateConnue}></View>
            <View style={styles.TextDisponibility}>
              {"Date non indiqué".split(" ").map((word) => (
                <Text>{word}</Text>
              ))}
            </View>
          </View>
        )
      }
      if (new Date(data.availability_date) < new Date()) {
        return (
          <View style={styles.BgImmediat}>
            <View style={styles.Immediat} />
            <View style={styles.TextDisponibility}>
              <Text>Immédiate</Text>
            </View>
          </View>
        )
      } else {
        const date = new Date(data.availability_date)
          .toLocaleDateString("fr-FR", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          });
        return (
          <View style={styles.BgDateConnue}>
            <View style={styles.DateConnue}></View>
            <View style={styles.TextDisponibility}>
              {date.split(" ").map((word) => (
                <Text>{word}</Text>
              ))}
            </View>
          </View>
        )
      }
    }
  }

  return (
    <View style={{ display: 'flex', flexDirection: 'column', gap: 8, width: "20%" }}>
      <Image style={{ width: 70, height: 70 }} src={Logo} />
      <Text style={styles.anonymous}><Text style={styles.bold}>ID :</Text> {data.anonymous}</Text>
      {/* Disponibity */}
      <View style={styles.Viewdisponibility}>
        <DisponibilityCard />
      </View>
    </View>
  )
}

export default TopLeft;