import { add, format } from "date-fns";
import { gapi } from 'gapi-script';
import { FcCalendar } from "react-icons/fc";

export default function AddCalendarEvent({ appointment }) {
  const handleClick = () => {
    var CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
    var API_KEY = process.env.REACT_APP_GOOGLE_API
    var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
    var SCOPES = "https://www.googleapis.com/auth/calendar.events"

    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })

      gapi.client.load('calendar', 'v3', () => { })

      gapi.auth2.getAuthInstance().signIn()
        .then(() => {

          var output = format(add(new Date(appointment.date_choice), { hours: 1 }), "yyyy-MM-d HH:mm:ssXXX", { timeZone: "Europe/Berlin" });
          const dateAddHour = output.replace(" ", "T")

          var event = {
            'summary': 'Entretien MyFinPlace',
            'location': window.location.origin + "/Visio/" + appointment.id,
            'description': 'Entretien MyFinPlace',
            'start': {
              'dateTime': appointment.date_choice,
              'timeZone': 'Europe/Berlin'
            },
            'end': {
              'dateTime': dateAddHour,
              'timeZone': 'Europe/Berlin'
            },
            'reminders': {
              'useDefault': false,
              'overrides': [
                { 'method': 'email', 'minutes': 24 * 60 },
                { 'method': 'popup', 'minutes': 10 }
              ]
            }
          }

          var request = gapi.client.calendar.events.insert({
            'calendarId': 'primary',
            'resource': event,
          })

          request.execute(event => {
            console.log(event)
            window.open(event.htmlLink)
          })
        }).catch((error) => {
          // error
        })
    })
  }

  return (
    <div onClick={handleClick} className="flex items-center gap-2 bg-[#030F30] hover:bg-[#0B3DBC] text-white text-[14px] px-4 py-2 rounded-[5px] hover:cursor-pointer">
      <FcCalendar size={16} />
      Ajouter au calendrier
    </div>
  )
}