
import { IoChevronDownOutline } from "react-icons/io5";
import inscription1 from "assets/img/Hub/Card1/Inscription.png";
import inscription2 from "assets/img/Hub/Card1/Inscription partie 2.png";
import inscription3 from "assets/img/Hub/Card1/Inscription partie 3.png";
import { Link, useNavigate } from "react-router-dom";



export default function Card1({ isActive, setActive }) {
  return (
    <div className="border rounded-[8px] border-[#2632384D] border-[4px] py-4 px-6">
      <h1 onClick={() => setActive({ Question1: !isActive.Question1 })} className="font-semibold text-[#263238] cursor-pointer flex gap-2  "  >
        <div className="flex justify-between w-full items-center">
          <p className="text-[32px] text-black font-bold">Vous souhaitez vous  <span className="text-[#5B9ECF]">inscrire</span> ?</p>
          <div className={isActive.Question1 ? "rotate-180 transition duration-500" : "transition duration-500"}>
            <IoChevronDownOutline className="text-[#5B9ECF]" />
          </div>
        </div>
      </h1>
      <div className={isActive.Question1 ? "animation-box-visible" : "animation-box-hidden "} id="box1">
        <div className="pr-5 text-justify animation-content" id="content2">
              <div className="flex flex-row my-16 items-center justify-between "> 
                  <div>
                      <h3>1. Cliquez sur le bouton “Inscription/Connexion” situé dans le coin supérieur droit de l’écran ;</h3>
                  </div>
                  <div className="contents">
                      <img className="w-1/3" src={inscription1} alt="" />
                  </div>
              </div>
              <div className="flex flex-row my-16 items-center justify-between"> 
                  <div>
                      <h3>2. Cliquez sur le texte “Inscrivez-vous” en bas du formulaire de connexion ;</h3>
                  </div>
                  <div className="contents">
                      <img className="w-1/3" src={inscription2} alt="" />
                  </div>
              </div>
              <div className="flex flex-row my-16 items-center justify-between"> 
                  <div>
                      <h3>3. Complétez le formulaire d’inscription selon votre identité (entreprise, cabinet de conseil, cabinet d’audit et/ou d’expertise comptable, étudiant, candidat ou consultant).</h3>
                       <br />
                      <h3 className="font-semibold">NB : En cas d’inscription en tant qu’étudiant, candidat ou consultant, vous serez invité à compléter vos informations durant le parcours d’inscription. Une fois votre profil complété à 100%, vous pourrez choisir de rendre votre profil visible. Celui-ci sera alors visible sur la page “Les profils”.</h3>
                  </div>
                  <div className="contents">
                      <img className="w-1/3" src={inscription3} alt="" />
                  </div>
              </div>
              <div className="my-8">
              <Link to="/Inscription">
            <a href="" target="_blank" className="bg-[#5B9ECF] hover:bg-[#2D77AD] text-[19px] text-white py-2 px-8 rounded-[5px] inline-block">Je souhaite m’inscrire</a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}