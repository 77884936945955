import React from "react";


function Accept() {
 

    return (
      
        <div className='my-2 bg-[#DCFCE6] rounded-full' >
        <p className='p-2 font-bold text-[#00C455] rounded-full text-xs whitespace-nowrap'>Retenue</p>
        </div>
    );
}

export default Accept;
