import React, { useState, useContext } from "react";
import { GoClockFill } from "react-icons/go";
import { FaFile } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import Confirmer from "./popup/Confirmer";
import Replanifier from "./popup/Replanifier";
import Annuler from "./popup/Annuler";
import { useNavigate } from "react-router-dom";
import { isClient, isUser } from "utils/roles";
import { AuthContext } from "../../../../../Context/AuthProvider";

export default function AppointmentAPlanifier(props) {
  const [popupConfirmer, setPopupConfirmer] = useState(false);
  const [popupReplanifier, setPopupReplanifier] = useState(false);
  const [popupAnnuler, setPopupAnnuler] = useState(false);
  const appointment = props.data;
  const navigate = useNavigate()
  const { value } = useContext(AuthContext);

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}h${formattedMinutes}`;
  };

  function openConfirmer() {
    setPopupConfirmer(true)
  }
  function openReplanifier() {
    setPopupReplanifier(true)
  }
  function openAnnuler() {
    setPopupAnnuler(true)
  }

  return (
    <>
      <button onClick={() => navigate("/mon-espace/mes-entretiens")} className="block text-[#2563EB] font-extrabold hover:underline hover:cursor-pointer">{"<"} Retour</button>
      <span className='text-[#FFA500] bg-[#FDF5E1] inline-block p-2 rounded-full my-2'>
        <GoClockFill size={28} />
      </span>
      <p className="font-medium">Entretien à planifier {appointment.apply && " suite à votre candidature"}</p>
      {appointment &&
        <p className="text-2xl font-extrabold mb-4">{new Date(appointment.date_choice).toLocaleDateString("fr-FR", {
          weekday: "long",
          day: "2-digit",
          month: "long",
          year: "numeric",
        })} à {formatTime(new Date(appointment.date_choice))}</p>
      }
      {appointment.offre &&
        <div className="flex gap-2 text-[#B7B7B7] items-center">
          <FaFile />
          <p className="font-medium w-[120px]">Poste à pouvoir</p>
          <a className="text-black font-medium flex items-center gap-2 underline" href={`/les-opportunites/${appointment.offre.id}`} target="_blank">{appointment.offre.intitule}
            <FaExternalLinkAlt />
          </a>
        </div>
      }
      <div className="flex gap-2 text-[#B7B7B7] items-center">
        {appointment.id_client &&
          <>
            <FaBuilding />
            <p className="font-medium w-[120px]">Entreprise</p>
            <p className="text-[#0F6FDE] font-medium">{appointment.id_client.company}</p>
          </>
        }
        {appointment.id_user &&
          <>
            <FaUser />
            <p className="font-medium w-[120px]">Profil</p>
            <a className="text-[#0F6FDE] font-medium flex items-center gap-2 underline" href={`/dossier-de-competences/${appointment.id_user.id}`} target="_blank">{appointment.id_user.roles[0]}
              <FaExternalLinkAlt />
            </a>
          </>
        }
      </div>
      <div className="flex flex-col min-w-[225px] w-[50%] mx-auto gap-2 mt-8">
        {appointment && ((isUser(value.user) && appointment.client_ask) || (isClient(value.user) && appointment.user_ask)) && new Date(appointment.date_choice) > new Date() &&
          <div onClick={openConfirmer} className="w-full text-center bg-[#DCFCE6] hover:bg-[#C5F7D3] text-[#00C455] py-2 rounded-[5px] hover:cursor-pointer">
            Confirmer
          </div>
        }
        <div onClick={openReplanifier} className="w-full text-center bg-[#FFF2CC] hover:bg-[#FFEAB2] text-[#FF8225] py-2 rounded-[5px] border border-[#FF8225] hover:cursor-pointer">
          Replanifier
        </div>
        <div onClick={openAnnuler} className="w-full text-center bg-[#FFCBB8] hover:bg-[#FFBBA3] text-[#E0182D] py-2 rounded-[5px] hover:cursor-pointer">
          Annuler le rendez-vous
        </div>
      </div>
      {/* Popup */}
      {popupConfirmer && <Confirmer appointment={appointment} setPopupConfirmer={setPopupConfirmer} getAppoitment={props.getAppoitment} />}
      {popupReplanifier && <Replanifier appointment={appointment} setPopupReplanifier={setPopupReplanifier} getAppoitment={props.getAppoitment} />}
      {popupAnnuler && <Annuler appointment={appointment} setPopupAnnuler={setPopupAnnuler} getAppoitment={props.getAppoitment} />}
    </>
  )
}