export default function OffreBadge({ name, type, level }) {
  var colors = {}
  switch (type) {
    case "sectorielle":
      colors = {
        bgColor: "bg-sectoriellepastel",
        textColor: "text-sectorielledark",
        borderColor: "border-sectorielledark",
        bgLevel: "bg-sectoriellelevel"
      }
      break;
    case "fonctionnelle":
      colors = {
        bgColor: "bg-fonctionnellepastel",
        textColor: "text-fonctionnelledark",
        borderColor: "border-fonctionnelledark",
        bgLevel: "bg-fonctionnellelevel"
      }
      break;
    case "informatique":
      colors = {
        bgColor: "bg-informatiquepastel",
        textColor: "text-informatiquedark",
        borderColor: "border-informatiquedark",
        bgLevel: "bg-informatiquelevel"
      }
      break;
    case "linguistique":
      colors = {
        bgColor: "bg-linguistiquepastel",
        textColor: "text-linguistiquedark",
        borderColor: "border-linguistiquedark",
        bgLevel: "bg-linguistiquelevel"
      }
      break;
    default:
      colors = {
        bgColor: "bg-greylight",
        textColor: "text-black",
        borderColor: "border-[#ADAEB0]"
      }
      break;
  }
  return (
    <small className={`flex flex-col gap-1 ${colors.bgColor} ${colors.borderColor} border rounded py-[5.5px] px-3.5`}>
      <span className={`font-medium ${colors.textColor}`}>{name}</span>
      {level &&
        <div className={`flex flex-row gap-[4px]`}>
          <hr className={`w-[12px] h-[2px] font-medium ${level >= 1 ? colors.bgLevel : "bg-[#D9D9D9]"}`} />
          <hr className={`w-[12px] h-[2px] font-medium ${level >= 2 ? colors.bgLevel : "bg-[#D9D9D9]"}`} />
          <hr className={`w-[12px] h-[2px] font-medium ${level >= 3 ? colors.bgLevel : "bg-[#D9D9D9]"}`} />
          <hr className={`w-[12px] h-[2px] font-medium ${level >= 4 ? colors.bgLevel : "bg-[#D9D9D9]"}`} />
        </div>
      }
    </small>
  )
}