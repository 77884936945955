import React, { useContext, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { AlertMessageContext } from "Context/AlertMessageProvider";
import { AuthContext } from "Context/AuthProvider";
import { isClient, isUser } from "utils/roles";
import { IoIosWarning } from "react-icons/io";
import { LuLoader2 } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

export default function PopupSupprimer(props) {
  const token = cookie.get("token");
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState("");
  const { alert } = useContext(AlertMessageContext);
  const navigate = useNavigate()

  function onSubmit() {
    setIsLoading(true);
    axios.delete(process.env.REACT_APP_API + `user/candidature/delete/${props.postuler.id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        "reason": reason
      }
    })
      .then((response) => {
        props.getCandidatures()
        navigate('/mon-espace/mes-candidatures')
        setIsLoading(false);
        props.setPopupSupprimer(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  return (
    <>
      <div className="fixed h-full w-full top-0 left-0 bg-black opacity-50 z-30"></div>
      <div className="fixed w-[80vw] md:w-[60vw] xl:w-[40vw] top-[25vh] left-[10vw] md:left-[20vw] xl:left-[30vw] bg-white rounded-[5px] z-40 py-8 px-4 md:px-12">
        <div className="flex w-full justify-center">
          <span className='text-[#FF0000] bg-[#FCDCDC] inline-block rounded-full p-4'>
            <IoIosWarning size={72} />
          </span>
        </div>
        <p className="font-medium text-black text-2xl text-center mt-4">Supprimer la candidature</p>
        <p className="text-[#6B7280] text-center mt-2">Confirmez-vous la suppression de votre candidature pour l'opportunité <span className="text-[#5B9ECF]">{props.postuler.id_offre.intitule} ?</span>
        </p>
        <div className="flex flex-col items-center mt-4 mb-6">
          <p className="text-[#6B7280] w-full md:w-[80%]">Raison de votre suppression (optionnel)</p>
          <textarea className="border rounded-[5px] px-4 py-2 w-full md:w-[80%] outline-none" onChange={(e) => setReason(e.target.value)}></textarea>
        </div>
        <div className="flex w-full justify-center gap-2 md:gap-6 mt-4">
          <div onClick={() => { props.setPopupSupprimer(false) }} className="text-center max-w-[250px] border border-[#2563EB] text-[#2563EB] hover:bg-[#2563EB] hover:text-white hover:cursor-pointer py-2 w-full">
            Annuler
          </div>
          <button onClick={onSubmit} disabled={isLoading} className="text-center max-w-[250px] flex justify-center items-center border border-[#FF0000] bg-[#FF0000] text-white hover:bg-white hover:text-[#FF0000] hover:cursor-pointer disabled:bg-[#ADADAD] disabled:cursor-wait disabled:text-white disabled:border-none py-2 w-full">
            {isLoading ? <LuLoader2 className='animate-spin' /> : "Confirmer"}
          </button>
        </div>
      </div>
    </>
  )
}