class Point {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  toSvgPath() {
    return `${this.x} ${this.y}`
  }

  static fromAngle(angle) {
    return new Point(Math.cos(angle), Math.sin(angle))
  }
}

function PieChart({ data, colors, texts, pourcentage, showTotal, dimension = 100 }) {
  const total = data.reduce((acc, v) => acc + v, 0);
  let angle = 0;
  let start = new Point(1, 0);
  const arrayText = [];
  let numberZeroValue = -1;
  return (
    <>
      <div className="relative w-[400px] h-[300px] flex items-center justify-center">
        {/************************************************************
                                Création du SVG
        ********************************************************** */}
        <svg viewBox="-1 -1 2 2" width={dimension} height={dimension}>
          {data.map((value, key) => {
            const ratio = value / total;
            //Angle pour le text associé
            const angleText = angle + (ratio * Math.PI);
            //Angle pour la taille sur le diagramme
            angle += ratio * 2 * Math.PI;
            //Calcul des coordoné en fonction de l'angle
            const end = Point.fromAngle(angle);
            const MiddlePoint = Point.fromAngle(angleText);
            //Sauvegarde des points pour l'écriture des texts
            arrayText.push({ "x": Math.floor((MiddlePoint.x) * 100), "y": Math.floor(MiddlePoint.y * 100) })
            //Ecriture des paths du diagramme
            const LargeFlag = ratio > 0.5 ? '1' : '0';
            const path = `M 0 0 L ${start.toSvgPath()} A 1 1 0 ${LargeFlag} 1 ${end.toSvgPath()} L 0 0`;
            start = end;
            return (<path fill={colors[key]} d={path} key={key}></path>);
          })}
        </svg>
        {/************************************************************
                    écriture du text associé à la couleur
        ********************************************************** */}
        <div className="absolute" style={{ bottom: 170, left: 150 }}>
          {texts && arrayText.map((value, key) => {
            if (data[key] !== 0) {
              //On s'assure que la valeur se soit pas null
              return (
                <div className="absolute w-[100px] text-center" style={{ transform: `translate(${(value.x * 1.1) * (dimension * 0.01)}%, ${(value.y * 1.1) * (dimension * 0.015)}%)`, color: colors[key] }} key={key}>
                  <p>{texts[key]}</p>
                  <p>{data[key]}</p>
                </div>);
            } else {
              // Si la valeur du champ est null, il faut l'afficher autre part pour éviter qu'elle se mette à l'endroit que le champs précédent
              numberZeroValue++;
              return (
                <div className="absolute w-[100px] -left-[200px] text-center" style={{ top: 50 - (50 * numberZeroValue), color: colors[key] }} key={key}>
                  <p>{texts[key]}</p>
                  <p>{data[key]}</p>
                </div>
              )
            }
          })}
        </div>
        {/************************************************************
                    Cercle blanc au centre du digrame
        ********************************************************** */}
        <div className="absolute flex items-center justify-center bg-white rounded-full" style={{ width: dimension * 0.75, height: dimension * 0.75 }}>
          {/* Afficher le total des valeurs */}
          {texts !== undefined && showTotal ? (
            <span className="font-bold">{total}</span>
          ) : null}
          {/* Afficher le pourcentage entré en paramètre */}
          {pourcentage !== undefined ? (
            <span className="font-bold text-[#67E53A]" style={{ fontSize: dimension * 0.25 }}>{pourcentage}%</span>
          ) : null}
        </div>

      </div>
    </>
  )
}

export default PieChart;