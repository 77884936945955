import React, { useState, useEffect, useContext } from "react";
import { fr } from 'date-fns/locale';
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { isClient, isUser } from "utils/roles";
import { AuthContext } from "Context/AuthProvider";
import { FaCalendarWeek } from "react-icons/fa";
import { GoClockFill } from "react-icons/go";
import { RxCross2 } from "react-icons/rx";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FaHandshakeSimple } from "react-icons/fa6";
import { FaHandshakeSimpleSlash } from "react-icons/fa6";
import missingData from "../../../../../assets/img/missdata/Femme meeting.png";

export default function ListAppointment(props) {
  const [data, setData] = useState();
  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);
  const navigate = useNavigate()
  const { value } = useContext(AuthContext);

  function dislayClientRole(roles) {
    switch (roles[0]) {
      case "Cabinet-conseil":
        return "un cabinet de conseil"
      case "Cabinet-daudit-dexpertise-comptable":
        return "un cabinet d'audit et/ou d'expertise comptable"
      case "entreprise":
        return "une entreprise"
      default:
        return "un client"
    }
  }

  return (
    <>
      {/* Rendez-vous programmer */}

      {data && data.length >= 1 ?
        <div className=" my-3">
          {/* Rendez-vous à planifier */}
          <div div className=" my-3" >
            <div>
              <p className="font-bold">Entretien à planifier</p>
            </div>
            {
              data && data.map((data, id) => (
                data.status == "planifier" ?
                  <div className="bg-[#FDF5E1] hover:bg-[#F9E3AE] rounded" key={id} >
                    <div className="flex flex-col my-2 ">
                      <div onClick={() => navigate(`/mon-espace/mes-entretiens/${data.id}`)} className="flex flex-row text-sm items-center gap-2 px-2 hover:cursor-pointer font-bold">
                        <span className='text-[#FFA500]'><GoClockFill /></span>
                        <div>
                          {format(Date.parse(data.date_choice), "HH:mm", { locale: fr })} - Entretien à planifier avec
                          <span className="text-[#0F6FDE] ml-1">
                            {data.id_client && dislayClientRole(data.id_client.roles)}
                            {data.id_user && <><span className='text-black'>un</span> {data.id_user.roles[0]}</>}
                          </span>
                          {data.apply && " suite à votre candidature"}
                        </div>
                      </div>
                    </div>
                  </div>
                  : null
              ))
            }
          </div>
          <div>
            <div>
              <p className="font-bold">Entretien programmé</p>
            </div>
            {data && data.map((data, id) => (
              data.status == "programmés" ?
                <div className="bg-[#EBF4FF] hover:bg-[#D6E9FF] rounded" key={id}>
                  <div className="flex flex-col my-2">
                    <div onClick={() => navigate(`/mon-espace/mes-entretiens/${data.id}`)} className="flex flex-row text-sm items-center gap-2 px-2 hover:cursor-pointer font-bold">
                      <span className='text-[#2563EB]'><FaCalendarWeek /> </span>
                      <div>
                        {format(Date.parse(data.date_choice), "HH:mm", { locale: fr })} - Entretien programmé avec
                        <span className="text-[#0F6FDE] ml-1">
                          {data.id_client && data.id_client.company}
                          {data.id_user && data.id_user.first_name + " " + data.id_user.last_name}
                        </span>
                        {data.apply && " suite à votre candidature"}
                      </div>
                    </div>
                  </div>
                </div>
                : null
            ))}
          </div >
          {/* Rendez-vous réaliser */}
          < div className=" my-3" >
            <div>
              <p className="font-bold">Entretien réalisé</p>
            </div>
            {
              data && data.map((data, id) => (
                data.status == "réaliser" ?
                  <div className="bg-[#DCFCE6] hover:bg-[#BBF9CE] rounded" key={id}>
                    <div className="flex flex-col my-2 ">
                      <div onClick={() => navigate(`/mon-espace/mes-entretiens/${data.id}`)} className="flex flex-row text-sm items-center gap-2 px-2 hover:cursor-pointer font-bold">
                        <span className=' text-[#00C455]'><IoIosCheckmarkCircle /> </span>
                        <div>
                          {format(Date.parse(data.date_choice), "HH:mm", { locale: fr })} - Entretien réalisé avec
                          <span className="text-[#0F6FDE] ml-1">
                            {data.id_client && data.id_client.company}
                            {data.id_user && data.id_user.first_name + " " + data.id_user.last_name}
                          </span>
                          {data.apply && " suite à votre candidature"}
                        </div>
                      </div>
                    </div>
                  </div>
                  : null
              ))
            }
          </div >
          {/* Rendez-vous cancel  */}
          < div className=" my-3" >
            <div >
              <p className="font-bold">Entretien annulé</p>
            </div>
            {
              data && data.map((data, id) => (
                data.status == "cancel" ?
                  <div className="bg-[#FCDCDC] hover:bg-[#F4BCBC] hover:cursor-pointer rounded" key={id}>
                    <div className="flex flex-col my-2 ">
                      <div onClick={() => navigate(`/mon-espace/mes-entretiens/${data.id}`)} className="flex flex-row text-sm items-center gap-2 px-2 hover:cursor-pointer font-bold">
                        <span className='bg-[#FF0000] text-white'><RxCross2 /></span>
                        <div>
                          {format(Date.parse(data.date_choice), "HH:mm", { locale: fr })} - Entretien annulé avec
                          <span className="text-[#0F6FDE] ml-1">
                            {data.id_client && dislayClientRole(data.id_client.roles)}
                            {data.id_user && <><span className='text-black'>un </span>{data.id_user.roles[0]}</>}
                          </span>
                          {data.apply && " suite à votre candidature"}
                        </div>
                      </div>
                    </div>
                  </div>
                  : null
              ))
            }
          </div >
          {/* Profil retenu */}
          < div className=" my-3" >
            <div>
              <p className="font-bold">
                {isClient(value.user) && "Candidatures retenues"}
                {isUser(value.user) && "Candidatures retenues"}
              </p>
            </div>
            {
              data && data.map((data, id) => (
                data.status == "archiver_win" ?
                  <div className="bg-[#DCFCE6] hover:bg-[#BBF9CE] rounded" key={id}>
                    <div className="flex flex-col my-2 ">
                      <div onClick={() => navigate(`/mon-espace/mes-entretiens/${data.id}`)} className="flex flex-row text-sm items-center gap-2 px-2 hover:cursor-pointer font-bold">
                        <span className='text-[#00C455]'><FaHandshakeSimple /> </span>
                        <div>
                          {format(Date.parse(data.date_choice), "HH:mm", { locale: fr })} -
                          {data.id_client &&
                            <span>
                              <span className="text-[#0F6FDE] ml-1">{data.id_client.company}</span> a retenu votre profil
                            </span>
                          }
                          {data.id_user &&
                            <span>
                              Vous avez retenu le {data.id_user.roles[0]} <span className="text-[#0F6FDE]">{data.id_user.first_name} {data.id_user.last_name}</span>
                            </span>
                          }
                          {data.apply && " suite à votre candidature"}
                        </div>
                      </div>
                    </div>
                  </div>
                  : null
              ))
            }
          </div >
          {/* Profil refuser  */}
          < div className=" my-3" >
            <div >
              <p className="font-bold">
                {isClient(value.user) && "Candidatures non retenues"}
                {isUser(value.user) && "Candidatures non retenues"}
              </p>
            </div>
            {
              data && data.map((data, id) => (
                data.status == "archiver" ?
                  <div className="bg-[#FCDCDC] hover:bg-[#F4BCBC] hover:cursor-pointer rounded" key={id}>
                    <div className="flex flex-col my-2 ">
                      <div onClick={() => navigate(`/mon-espace/mes-entretiens/${data.id}`)} className="flex flex-row text-sm items-center gap-2 px-2 hover:cursor-pointer font-bold">
                        <span className='text-[#FF0000]'><FaHandshakeSimpleSlash /></span>
                        <div>
                          {format(Date.parse(data.date_choice), "HH:mm", { locale: fr })}
                          {data.id_client &&
                            <span className="ml-1">
                              - <span className="text-[#0F6FDE]">{data.id_client.company}</span> a retenu votre profil
                            </span>
                          }
                          {data.id_user &&
                            <span className="ml-1">
                              - Vous avez refuser le {data.id_user.roles[0]} <span className="text-[#0F6FDE]">{data.id_user.first_name} {data.id_user.last_name}</span>
                            </span>
                          }
                          {data.apply && " suite à votre candidature"}
                        </div>
                      </div>
                    </div>
                  </div>
                  : null
              ))
            }
          </div >

        </div >
        :
        <div className="flex flex-col items-center justify-around my-2">
          <div className='w-1/3'>
            <img
              className="w-full opacity-70"
              src={missingData}
              alt="MyFinPlace.com"

            />
          </div>
          <div >
            <h3 className=" px-2 mx-2 font-bold">Aucun entretien pour le moment</h3>
          </div>
        </div>
      }
    </>
  )
}