import React from 'react'
import PartieHaute from "./Components/PartieHaute";
import PartieCentral from "./Components/PartieCentral";
import PartieBasse from "./Components/PartieBasse";
import { Helmet } from "react-helmet";  

function QuiSommesNous() {

    return (
        <div className="text-[#263238]">
            <Helmet>  
<title>MyFinPlace - Qui sommes-nous </title>  
</Helmet>  
            <PartieHaute />
            <PartieCentral />
            <PartieBasse />
        </div >
    )
}

export default QuiSommesNous