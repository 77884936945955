import React, { useState } from 'react';
import { AiOutlineSend } from "react-icons/ai";


function ChatDisplay(props) {
  const [showChat, setShowChat] = useState(false);
  const [text, setText] = useState("");

  const { remoteParticipants, localParticipant, chatMessages } = props.state;
  const { sendChatMessage } = props.actions;

  function getDisplayName(id) {
    if (localParticipant.id == id) {
      return localParticipant.displayName.split("|")[1];
    }
    return remoteParticipants.find((p) => p.id === id)?.displayName.split("|")[1] || "(administrateur)";
  }

  return (
    <div className='border border-[#BFBFBF] rounded-[5px]'>
      <h4 className="p-2 font-bold">Discussion</h4>
      <span className="border-t border-[#BFBFBF] block w-full" />
      <div className='p-4 flex flex-col gap-4 h-[40vh] overflow-y-auto smaller-scroll-bar'>
        {chatMessages.map((message, key) => {
          return (
            <>
              <div key={key} className='border border-[#030F30] rounded-[10px] px-4 py-2 w-full'>
                <div className='text-sm font-bold'>{getDisplayName(message.senderId)}</div>
                <div className=''>{message.text}</div>
              </div>
            </>
          )
        })}
      </div>
      <div className='w-full p-4 mx-auto'>
        <form onSubmit={(e) => { e.preventDefault(); sendChatMessage(text); setText(""); }}>
          <div className='w-full border border-[#030F30] rounded-[5px] pr-1 flex flex-row w-full items-center gap-6'>
            <input className="px-4 py-2 w-[90%] rounded-full border-none outline-none" value={text} onChange={(e) => setText(e.target.value)} placeholder='Message...' />
            <button type='submit' className='shrink-0'>
              <AiOutlineSend color={"#030F30"} size={20} />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ChatDisplay;