import React, { useState } from 'react'
import Plus2 from 'assets/svg/Plus'


export default function QuestionBottom() {
    const [isActive, setActive] = useState({});

    return (

        <section className="pt-24 px-4 md:px-12 lg:w-[70%] m-auto">
            <div className="">

                {/*********************************************/}
                {/*              Block de gauche              */}
                {/*********************************************/}

                {/* <div className="lg:pl-12 md:pr-7 my-5 md:pl-6"> */}

                {/* <!-- Question gauche 1 --> */}
                <div className="bg-[#EFF5FA] border-2 border-[#BDD8EC] rounded-[10px] px-5 py-7">
                    <h1 onClick={() => setActive({ Question1: !isActive.Question1 })} className=" font-semibold text-[#263238] cursor-pointer flex gap-2 "  >
                        <p className="w-full text-xl font-medium pr-[10px] md:pr-[70px]" >La page FAQ ne répond pas à une question que je me pose</p>
                        <div>
                            <div className="" id="plus1">
                                <div className={isActive.Question1 ? "rotate-45 transition duration-500" : "transition duration-500"}>

                                    <Plus2 />
                                </div>
                            </div>
                        </div>
                    </h1>
                    <div className={isActive.Question1 ? "animation-box-visible" : "animation-box-hidden "} id="box1">
                        <div className="pr-5 text-justify animation-content">
                            <p className="pt-9 pr-[0] md:pr-[120px]">  Nous vous invitons à poser votre
                                question à <a href="mailto: contact@myfinplace.com" className="text-ligthblue underline">contact@myfinplace.com</a> en
                                indiquant "Utilisation de la plateforme" en objet de votre mail. <br />
                            </p>
                            <p className="py-2.5 pr-[120px]">Le support utilisateur vous apportera une réponse dans les plus brefs délais.</p>
                        </div>

                    </div>
                </div>


                {/* <!-- Question gauche 2 --> */}
                <div className="bg-[#EFF5FA] border-2 border-[#BDD8EC] rounded-[10px] px-5 py-7 mt-9">
                    <h1 onClick={() => setActive({ Question2: !isActive.Question2 })} className="font-semibold text-[#263238] cursor-pointer flex gap-2  "  >
                        <p className="w-full text-xl font-medium pr-[10px] md:pr-[70px]">La plateforme m'assure-t-elle une totale confidentialité ?</p>
                        <div>
                            <div className="" id="plus1">
                                <div className={isActive.Question2 ? "rotate-45 transition duration-500" : "transition duration-500"}>
                                    <Plus2 />
                                </div>
                            </div>
                        </div>
                    </h1>
                    <div className={isActive.Question2 ? "animation-box-visible" : "animation-box-hidden "} id="box1">
                        <div className="pr-5 text-justify animation-content" id="content2">
                            <p className="pt-9 pr-[0] md:pr-[120px]" >Oui, tout à fait : au-delà de la protection de vos données personnelles, le
                                modèle sous-jacent à la plateforme transforme votre identité en identifiant aléatoire composé de
                                chiffres et de lettres, vous assurant ainsi une totale confidentialité vis-à-vis de toutes les
                                personnes susceptibles de consulter votre profil sur la <i> hub</i>.</p>
                            <p className='py-2.5 pr-[120px]'>Par ailleurs, une fois votre profil créé, vous avez la possibilité de le rendre invisible, ce qui signifie que vous seul pouvez le consulter.</p>
                        </div>
                    </div>
                </div>


                {/* <!-- Question gauche 3 --> */}
                <div className="bg-[#EFF5FA] border-2 border-[#BDD8EC] rounded-[10px] px-5 py-7 mt-9">
                    <h1 onClick={() => setActive({ Question3: !isActive.Question3 })} className="font-semibold text-[#263238] cursor-pointer flex gap-2 " >
                        <p className="w-full text-xl font-medium pr-[10px] md:pr-[70px]">Je suis chasseur de tête à mon compte. Puis je recruter via la plateforme ?</p>
                        <div>
                            <div className="" id="plus1">
                                <div className={isActive.Question3 ? "rotate-45 transition duration-500" : "transition duration-500"} >
                                    <Plus2 />
                                </div>
                            </div>
                        </div>
                    </h1>
                    <div className={isActive.Question3 ? "animation-box-visible" : "animation-box-hidden "} id="box1">
                        <div className="pr-5 text-justify animation-content" id="content3">
                            <p className="pt-9" >Bien évidemment.</p>
                            <p className='py-2.5  pr-[0] md:pr-[120px]'>
                                <strong>MyFinPlace </strong>
                                est une plateforme ouverte aux utilisateurs quelque soit leur origine professionnelle et leur démarche. Un recruteur peut interagir avec les profils visibles pour leur soumettre des opportunités.</p>
                        </div>
                    </div>
                </div>


                {/* <!-- Question gauche 4 --> */}
                <div className="bg-[#EFF5FA] border-2 border-[#BDD8EC] rounded-[10px] px-5 py-7 mt-9">
                    <h1 onClick={() => setActive({ Question4: !isActive.Question4 })} className="font-semibold text-[#263238] cursor-pointer flex gap-2 " >
                        <p className="w-full text-xl font-medium pr-[10px] md:pr-[70px]">Je représente un cabinet de conseil, d'audit et/ou d'expertise-comptable reconnu, et je souhaite trouver des profils via la plateforme. Comment dois-je procéder ?</p>
                        <div>
                            <div className="" id="plus1">
                                <div className={isActive.Question4 ? "rotate-45 transition duration-500" : "transition duration-500"} >
                                    <Plus2 />
                                </div>
                            </div>
                        </div>
                    </h1>
                    <div className={isActive.Question4 ? "animation-box-visible" : "animation-box-hidden "} id="box1">
                        <div className="pr-5 text-justify animation-content" id="content4">
                            <p className="pt-9 py-2.5 pr-[0] md:pr-[120px]">La démarche est simple et intuitive : vous vous inscrivez en tant que cabinet de conseil, d'audit et/ou d'expertise-comptable, et vous aurez ainsi accès aux fonctionnalités de recherche de profils et de diffusion des opportunités de votre cabinet.</p>
                        </div>
                    </div>
                </div>
                {/* </div> */}

                {/*********************************************/}
                {/*              Block de gauche              */}
                {/*********************************************/}

                {/* <div className='lg:pl-12 md:pr-9 my-5 md:pl-6'> */}


                {/* <!-- Question droite 1 --> */}
                <div className="bg-[#EFF5FA] border-2 border-[#BDD8EC] rounded-[10px] px-5 py-7 mt-9">
                    <h1 onClick={() => setActive({ Question5: !isActive.Question5 })} className="font-semibold text-[#263238] cursor-pointer flex gap-2 "  >
                        <p className="w-full text-xl font-medium pr-[10px] md:pr-[70px]" >Puis-je refuser une opportunité ?</p>
                        <div>
                            <div className="" id="plus1">
                                <div className={isActive.Question5 ? "rotate-45 transition duration-500" : "transition duration-500"} >
                                    <Plus2 />
                                </div>
                            </div>
                        </div>
                    </h1>
                    <div className={isActive.Question5 ? "animation-box-visible" : "animation-box-hidden "} id="box1">
                        <div className="pr-5 text-justify animation-content" id="content5">
                            <p className="pt-9 py-2.5 pr-[0] md:pr-[120px]">Oui, vous en avez parfaitement le droit. Si vous déclinez une proposition d'entretien adressée par une entreprise, un cabinet de conseil, d'audit et/ou d'expertise-comptable ou un cabinet de recrutement, il faudra simplement que vous motiviez votre refus.</p>
                        </div>
                    </div>
                </div>


                {/* <!-- Question droite 2 --> */}
                <div className="bg-[#EFF5FA] border-2 border-[#BDD8EC] rounded-[10px] px-5 py-7 mt-9">
                    <h1 onClick={() => setActive({ Question6: !isActive.Question6 })} className="font-semibold text-[#263238] cursor-pointer flex gap-2 "  >
                        <p className="w-full text-xl font-medium pr-[10px] md:pr-[70px]" >La plateforme est-elle gratuite ?</p>
                        <div>
                            <div className="" id="plus1">
                                <div className={isActive.Question6 ? "rotate-45 transition duration-500" : "transition duration-500"} >
                                    <Plus2 />
                                </div>
                            </div>
                        </div>
                    </h1>
                    <div className={isActive.Question6 ? "animation-box-visible" : "animation-box-hidden "} id="box1">
                        <div className="pr-5 text-justify animation-content" id="content6">
                            <p className="pt-9 pr-[0] md:pr-[120px]">Elle est totalement gratuite pour tous les profils qui s’y inscrivent et qui l'utilisent.</p>
                            <p className='py-2.5 pr-[0] md:pr-[120px]'>La plateforme n'est rémunérée que par les clients qui contractualisent avec elle, et donc jamais par les profils.</p>
                        </div>
                    </div>
                </div>


                {/* <!-- Question droite 3 --> */}
                <div className="bg-[#EFF5FA] border-2 border-[#BDD8EC] rounded-[10px] px-5 py-7 mt-9">
                    <h1 onClick={() => setActive({ Question7: !isActive.Question7 })} className="font-semibold text-[#263238] cursor-pointer flex gap-2 "  >
                        <p className="w-full text-xl font-medium pr-[10px] md:pr-[70px]" >Je suis salarié d'un cabinet de conseil, d'audit et/ou d'expertise-comptable. Puis-je me créer un profil ?</p>
                        <div>
                            <div className="" id="plus1">
                                <div className={isActive.Question7 ? "rotate-45 transition duration-500" : "transition duration-500"}>
                                    <Plus2 />
                                </div>
                            </div>
                        </div>
                    </h1>
                    <div className={isActive.Question7 ? "animation-box-visible" : "animation-box-hidden "} id="box1">
                        <div className="pr-5 text-justify animation-content" id="content7">
                            <p className="pt-9 py-2.5 pr-[0] md:pr-[120px]">Oui, vous en avez parfaitement le droit car aucune clause de votre contrat de travail ne peut interdire une telle démarche, et en créant votre profil sur la plateforme, vous optimisez vos chances de trouver de nouvelles opportunités professionnelles.</p>
                        </div>
                    </div>
                </div>


                {/* <!-- Question droite 4 --> */}
                <div className="bg-[#EFF5FA] border-2 border-[#BDD8EC] rounded-[10px] px-5 py-7 mt-9">
                    <h1 onClick={() => setActive({ Question8: !isActive.Question8 })} className="font-semibold text-[#263238] cursor-pointer flex gap-2 " >
                        <p className="w-full text-xl font-medium pr-[10px] md:pr-[70px]" >Je souhaite devenir apporteur d'affaires pour MyFinPlace. Comment serai-je rémunéré ?</p>
                        <div>
                            <div className="" id="plus8">
                                <div className={isActive.Question8 ? "rotate-45 transition duration-500" : "transition duration-500"}>
                                    <Plus2 />
                                </div>
                            </div>
                        </div>
                    </h1>
                    <div className={isActive.Question8 ? "animation-box-visible" : "animation-box-hidden "} id="box1">
                        <div className="pr-5 text-justify animation-content" id="content8">
                            <p className="pt-9 py-2.5 pr-[0] md:pr-[120px]">
                                Nous vous invitons à écrire
                                à <a href="mailto: contact@myfinplace.com" className="text-ligthblue underline">contact@myfinplace.com</a> en
                                indiquant "Apport d'affaires" en objet de votre mail et en détaillant votre demande. Un des responsables de la plateforme vous répondra dans les plus brefs délais pour discuter de votre demande et vous proposer un projet de commissionnement adapté.
                            </p>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                {/* <!-- Fin de la partie de gauche --> */}
            </div>

        </section>
    )
}

