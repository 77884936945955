import { useContext } from 'react'
import SmallSecondaryButton from 'Components/Buttons/SmallSecondaryButton'
import SmallPrimaryButton from 'Components/Buttons/SmallPrimaryButton'
import Cookies from 'js-cookie'
import axios from "axios"
import { AlertMessageContext } from 'Context/AlertMessageProvider';

function DeleteFormationModal({ id, setUserInformation, close, getPourcentage }) {

    const token = Cookies.get("token");
    const { alert } = useContext(AlertMessageContext);

    const deleteHandler = async () => {
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_API}user/formation/delete/${id}`,
                { headers: { Authorization: `Bearer ${token}` } }
            )
            setUserInformation(prev => ({ ...prev, formation: prev.formation.filter(f => f.id !== id) }))
            close()
            getPourcentage();
            alert.successAlert("Votre formation a été supprimée.");
        } catch (error) {

        }
    }

    return (
        <div>
            <p className=''>Confirmez-vous la <span className='text-red-500 font-medium'>suppression</span> de cette formation ?</p>
            <div className='flex items-center gap-2 justify-end'>
                <SmallSecondaryButton onClick={() => close()} text={"Annuler"} />
                <SmallPrimaryButton text={"Oui"} onClick={() => deleteHandler()} />
            </div>
        </div>
    )
}

export default DeleteFormationModal