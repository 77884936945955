import { ReactComponent as LogoValide } from "../../../../assets/img/LogoValide.svg";

export default function Consultant() {
    return (
        <div className="px-4">
            <p className="font-bold text-justify">Vous êtes consultant FREELANCE ou Manager de transition en finance ?

                <br /><br />
                MyFinPlace vous permet de trouver l'opportunité (mission) qui vous correspond, que ce soit en entreprise ou en cabinet.</p>
                <div className="flex mt-8">
                <LogoValide className="shrink-0 h-8  p-1 bg-middleblue rounded-full mr-6" />
                <p className="text-justify">Il vous suffit de vous inscrire sur MyFinPlace</p>
            </div>
            <div className="flex mt-8">
                <LogoValide className="shrink-0 h-8  p-1 bg-middleblue rounded-full mr-6" />
                <p className="text-justify">Nous vous invitons à compléter votre profil à 100% afin d'être visible. Les entreprises ou cabinets prendront directement contact avec vous via une visioconférence</p>
            </div>
            <div className="flex mt-8">
                <LogoValide className="shrink-0 h-8  p-1 bg-middleblue rounded-full mr-6" />
                <p className="text-justify">Vous pourrez également postuler aux opportunités en ligne (il faut être connecté)</p>
            </div>
            <div className="flex mt-8">
                <LogoValide className="shrink-0 h-8  p-1 bg-middleblue rounded-full mr-6" />
                <p className="text-justify">Les mises en relation s’effectueront de manière totalement gratuite, sécurisée et confidentielle.
</p>
            </div>
        </div>
    );
}