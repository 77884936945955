import React, { useState, useEffect } from "react";
import Location from "assets/svg/Location";
import Mobile from "assets/svg/Mobile";
import { Link } from "react-router-dom";
import cookie from "js-cookie";
import LikeButton from "../DossierCompetences/Component/LikeButton";
import FavoriteButton from "../DossierCompetences/Component/FavoriteButton";
import ProfilNoPicture from "assets/img/profilnodata.png";
import { verifComptable } from "utils/verifComptable";
import TagLevel from "Components/TagLevel/TagLevel";

function ProfileCard(props) {
  const e = props.data;
  const key = props.data.id;
  const current = new Date();

  current.setDate(current.getDate() - 7);

  const disponibilityFormatter = (e) => {
    switch (e.disponibility) {
      case "immédiate":
        return (
          <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
            <div className="rounded-full bg-[#00B553] h-2 w-2 mr-2"></div>
            <p className="text-xs">{e.disponibility}</p>
          </span>
        );
      case "Immédiate":
        return (
          <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
            <div className="rounded-full bg-[#00B553] h-2 w-2 mr-2"></div>
            <p className="text-xs">{e.disponibility}</p>
          </span>
        );
      case "Préavis de 3 mois":
        return (
          <span className='inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10'>
            <div className="rounded-full bg-[#D80027] h-2 w-2 mr-2"></div>
            <p className="text-xs">{e.disponibility}</p>
          </span>
        );
      case "Préavis de 2 mois":
        return (
          <span className='inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10'>
            <div className="rounded-full bg-[#D80027] h-2 w-2 mr-2"></div>
            <p className="text-xs">{e.disponibility}</p>
          </span>
        );
      case "Préavis de 1 mois":
        return (
          <span className='inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10'>
            <div className="rounded-full bg-[#D80027] h-2 w-2 mr-2"></div>
            <p className="text-xs">{e.disponibility}</p>
          </span>
        );
      default:
        let date = "";
        if (e.disponibility === "Date connue") {
          if (e.availability_date !== null) {
            //La date connu est dans le passé, il est maintenant disponible
            if (new Date(e.availability_date.date) < new Date()) {
              return (
                <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
                  <div className="rounded-full bg-[#00B553] h-2 w-2 mr-2"></div>
                  <p className="text-xs">{"Immédiate"}</p>
                </span>
              );
            } else {
              //La date connu est dans le future, il faut affiché la date
              date = new Date(e.availability_date.date)
                .toLocaleDateString("fr-FR", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                });
              return (
                <span className='inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20'>
                  <div className="rounded-full bg-[#FFD600] h-2 w-2 mr-2"></div>
                  <p className="text-xs">{date}</p>
                </span>
              );
            }
          } else {
            //La valeur de la date connu est null
            return (
              <>
                <div className="rounded-full bg-[#FFD600] h-2 w-2 mr-2"></div>
                <p className="text-xs">Date non indiqué</p>
              </>
            );
          }
        }
        return (
          <>
            <div className="rounded-full bg-[#FFD600] h-2 w-2 mr-2"></div>
            <p className="text-xs">{e.disponibility}</p>
          </>
        );
    }
  }


  function colorContrat(contrat) {
    // const colorContrat = { "Stage": "#B3D8F2", "Alternance": "#B3D8F2", "CDD": "#B3E7FC", "CDI": "#B3E7FC", "Mission": "#7CC8FF" }
    switch (contrat) {
      case "Stage":
        return "bg-[#B3D8F2]"
      case "Alternance":
        return "bg-[#B3D8F2]"
      case "CDD":
        return "bg-[#B3E7FC]"
      case "CDI":
        return "bg-[#B3E7FC]"
      case "Mission":
        return "bg-[#7CC8FF]"
     case "Portage salarial":
                return "bg-[#7CC8FF]"
      default:
        break;
    }
  }

  return (
    <article className="bg-white rounded text-darkerblue " key={key}>
      <section className="p-4 flex flex-col justify-between h-full gap-4">
        <div className="flex flex-col  gap-4">
          <div className="flex flex-col justify-start p-2 rounded bg-no-repeat bg-cover bg-center h-[250px]"
            style={
              e.confidentiel ? { backgroundImage: `url('${ProfilNoPicture}') ` } :
                e.fileUrl ? { backgroundImage: `url('${e.fileUrl}') ` } :
                  { backgroundImage: `url('${ProfilNoPicture}') ` }}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                {
                  disponibilityFormatter(e)
                  // <AvaibilityBadge avaibility={e}/>
                }
              </div>
              {new Date(e.date_time_creation.date) > current ? (
                <p className="bg-darkerblue text-white text-[11px] rounded-[10px] px-[29px] py-[3px]">
                  New
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="flex flex-col items-end justify-end ">
              <LikeButton id={e.id} countFavoris={e.countFavoris} isFavoris={e.isFavoris} />
              <FavoriteButton id={e.id} countRecommandation={e.countRecommandation} isRecommander={e.isRecommander} />
            </div>
          </div>
          <div className="flex flex-col items-start gap-2">
            <div className="flex flex-row justify-end items-end w-full">
              <small className="flex items-center justify-self-end gap-1 underline decoration-dotted"><Location /> {e.department}</small>
            </div>
            <p className="font-bold">{e.titre}</p>
            {e.grades.slice(0, 1).map((grade) => (<small key={grade.id} className="font-bold bg-[#d4e4f9] p-1 rounded">{grade.grade_type}</small>))}
            <p className="text-[11px]">{e.time_experience}</p>
            <div className="flex flex-wrap gap-2.5">
              {e.work_experience.length > 0
                ? e.work_experience.map((experience, key) => {
                  if (experience === "Mission" && verifComptable(e.titre, e.inscritOrdre, e.inscritCncc, e.expertComptable)) {
                    return
                  }
                  return (
                    <small
                      key={key}
                      className={colorContrat(experience) + ` font-bold rounded py-[5.5px] px-3.5`}
                    >
                      {experience}
                    </small>
                  );
                })
                : ""}
            </div>
          </div>
        </div>
        <hr className="mx-6" />
        <section className="overflow-hidden">
          <div className="flex flex-wrap gap-1">
            {
              e.tags.map((tag, key) => {
                return <TagLevel name={tag.name} level={tag.level} type={tag.type} key={key} isSmall={true} />
              })
            }
          </div>
        </section>
        <div className="justify-self-end mt-auto">
          <Link to={`/dossier-de-competences/${e.id}`} target="_blank" >
            <p className="border border-[#5B9ECF] text-center rounded p-2 text-[#5B9ECF] cursor-pointer hover:text-white hover:bg-[#5B9ECF]">voir profil</p>
          </Link>
        </div>
      </section>

    </article>

  );
}

export default ProfileCard;
