import React from "react";
import { Helmet } from "react-helmet";  

function Politique() {
    return (
        <section className="containerxl pt-12 text-justify">
            <Helmet>  
<title>MyFinPlace - Politique de confidentialité</title>  
</Helmet>  
            <div className="text-center mb-16">
                <h1 className="robotoSlab bigscreen:text-[50px]">Politique de confidentialité</h1>
            </div>
            <div className="pb-8">
                <h6 className="letter-space-3 text-gray-600 bigscreen:text-[20px]">
                    La présente Politique de confidentialité régit la collecte
                    et le traitement, par la société MyFinPlace, des données à
                    caractère personnel (ci-après les «&nbsp;Données Personnelles&nbsp;»)
                    des utilisateurs (ci-après les «&nbsp;Utilisateurs&nbsp;») de la platforme MyFinPlace.com (ci-après le «&nbsp;Site&nbsp;»).
                    <br />
                    <br />
                    Elle est applicable à raison de toute utilisation par
                    l’Utilisateur du Site et de ses différents services,
                    incluant notamment la navigation sur le Site, la prise de
                    contact et les commandes réalisées.
                    <br />
                    <br />
                    La présente Politique de confidentialité vient compléter les
                    dispositions applicables à la relation entre la société
                    MyFinPlace et l’Utilisateur, et doit donc être lue en
                    parallèle avec les autres conditions applicables, et en
                    particulier avec les Conditions Générales de Vente
                    accessibles depuis le Site.
                </h6>
            </div>
            {/* <br />
            <br /> */}
            <div className="py-8">
                <div className="py-3">
                    <h5 className="text-black-500">
                        1 - RESPONSABLE DE TRAITEMENT
                    </h5>
                </div>
                <div className="py-3">
                    <p className="letter-space-3 text-gray-600 bigscreen:text-[20px]">
                        {/* {#La société MyFinPlace, société par actions simplifiée, immatriculée au RCS de Paris sous le numéro 915 141 428 et dont le siège social est situé au 55, avenue Marceau - 75016 Paris, est#} */}
                        Le responsable du traitement des données personnelles
                        réalisé via le site est Alexis Gueudré.
                        <br />
                        <br />
                        La société MyFinPlace s’engage à respecter les
                        dispositions issues de la Loi n°78-17 du 6 janvier 1978
                        (dite «&nbsp;Informatique et Libertés&nbsp;») modifiée, et du
                        règlement général sur la protection des données
                        personnelles (dit «&nbsp;RGPD&nbsp;»).
                        <br />
                        <br />
                        La société MyFinPlace peut être contactée par courrier à
                        l’adresse indiquée ci-avant, et par mail à l’adresse
                        suivante&nbsp;: support@myfinplace.com
                    </p>
                </div>
            </div>
            <div className="py-8">
                <div className="py-3">
                    <h5 className="text-black-500">2 - FINALITES</h5>
                </div>
                <div className="py-3">
                    <p className="letter-space-3 text-gray-600 bigscreen:text-[20px]">
                        Les Données Personnelles communiquées par l’Utilisateur
                        à la société MyFinPlace font l’objet d’un traitement
                        informatique et sont conservées et utilisées par la
                        société MyFinPlace pour les finalités suivantes&nbsp;:
                        <br />
                        – Enregistrer et gérer les commandes et fournir les
                        services correspondants ;
                        <br />
                        – Communiquer avec les Utilisateurs afin de répondre à
                        leurs demandes et sollicitations ;
                        <br />
                        – Gérer les réclamations et litiges éventuels ;
                        <br />
                        – Adresser à l’Utilisateur, après avoir recueilli son
                        accord préalable exprès, des newsletters, messages
                        d’information et opportunités promotionnelles.
                        <br />
                        Par ailleurs, lorsque l’Utilisateur se rend sur le Site,
                        des données de navigation sont susceptibles d’être
                        collectées et traitées par la société MyFinPlace ou par
                        tout prestataire de son choix à des fins statistiques,
                        afin d’améliorer l’utilisation du Site, et en
                        particulier de mesurer le nombre de pages vues, le
                        nombre de visites et le parcours de l’Utilisateur sur le
                        Site.
                    </p>
                </div>
            </div>
            {/* </div> */}
            <div className="py-8">
                <div className="py-3">
                    <h5 className=" text-black-500">
                        3 - FONDEMENTS JURIDIQUES DES TRAITEMENTS
                    </h5>
                </div>
                <div className="py-3">
                    <p className="letter-space-3 text-gray-600 bigscreen:text-[20px]">
                        Certains des traitements réalisés par la société
                        MyFinPlace conformément aux finalités énoncées
                        ci-avant sont nécessaires pour permettre à la
                        société MyFinPlace de :
                        <br />
                        – Remplir ses obligations contractuelles vis-à-vis
                        de certains Utilisateurs ;
                        <br />
                        – Respecter les obligations légales qui lui
                        incombent ;
                        <br />
                        – Répondre à certains intérêts légitimes relatifs
                        notamment à sa gestion.
                        <br />
                        Dans tous les autres cas, les traitements réalisés
                        par la société MyFinPlace sont possibles dans la
                        mesure où l’Utilisateur y a expressément consenti.
                    </p>
                </div>
            </div>
            {/* </div> */}
            <div className="py-8">
                <div className="py-3">
                    <h5 className=" text-black-500 ">
                        4 - SECURITE DES DONNES PERSONNELLES
                    </h5>
                </div>
                <div className="py-3">
                    <p className="letter-space-3 text-gray-600 bigscreen:text-[20px]">
                        La société MyFinPlace prend toutes les mesures
                        adéquates sur le plan technique et organisationnel
                        pour assurer la sécurité des Données Personnelles de
                        l’Utilisateur, en particulier, contre la perte,
                        l’altération, la diffusion ou l’usage illégal de ces
                        données.
                        <br />
                        <br />
                        La société MyFinPlace s’assure que de telles mesures
                        sont mises en place sur l’ensemble des opérations
                        réalisées dans le cadre des traitements, et
                        notamment lors de la collecte des Données
                        Personnelles, de leur stockage et de leur
                        hébergement.
                        <br />
                        <br />
                        A cet égard, la société MyFinPlace s’assure
                        également que les tiers auxquels elle est
                        susceptible de faire appel respectent cette exigence
                        de protection des Données Personnelles de
                        l’Utilisateur par la mise en place des mesures
                        appropriées. Les mesures techniques et
                        organisationnelles mises en place peuvent inclure le
                        recours à des formulaires d’inscription sécurisés,
                        le cryptage de certaines données (comme les données
                        bancaires) et l’accès restreint aux Données
                        Personnelles.
                        <br />
                        <br />
                        La société MyFinPlace invite l’Utilisateur à la plus
                        grande prudence dans la communication de ses Données
                        Personnelles, par ses soins, à des tiers. Certains
                        messages ou sollicitations reçues peuvent provenir
                        de personnes mal intentionnées cherchant à obtenir
                        des informations personnelles concernant
                        l’Utilisateur en vue d’une utilisation frauduleuse
                        (pratique du <i>phishing</i>). Si l’Utilisateur reçoit un
                        message qui paraît être une tentative de <i>phishing</i>,
                        il est invité à ne pas y répondre et à ne pas ouvrir
                        les pièces jointes, les images ou les liens contenus
                        dans le message. L’Utilisateur dispose de la
                        possibilité de le signaler par mail à l’adresse
                        support@myfinplace.com et sur www.signal-spam.fr.
                    </p>
                </div>
            </div>
            {/* </div> */}
            <div className="py-8">
                <div className="py-3">
                    <h5 className=" text-black-500">
                        5 - DESTINATAIRES DES DONNEES PERSONNELLES
                    </h5>
                </div>
                <div className="py-3">
                    <p className="letter-space-3 text-gray-600 bigscreen:text-[20px]">
                        Les Données Personnelles de l’Utilisateur pourront
                        être communiquées à des tiers sous-traitants de la
                        société MyFinPlace pour assurer l’accès et
                        l’utilisation du Site et de ses services.
                        <br />
                        <br />
                        Les Données Personnelles de l’Utilisateur ne font
                        pas l’objet de communication ou de transfert à des
                        tiers à des fins commerciales ou promotionnelles,
                        sauf accord préalable exprès de l’Utilisateur.
                        <br />
                        <br />
                        Le choix par l’Utilisateur de recevoir des opportunités ou
                        messages de prospection commerciale de la part de la
                        société MyFinPlace et/ou de ses partenaires est
                        subordonné à son accord préalable et exprès au moyen
                        d’une case à cocher correspondante accessible
                        lorsqu’il est invité à fournir des Données
                        Personnelles (lors d’une commande). Si l’Utilisateur
                        ne souhaite plus les recevoir, il dispose de la
                        possibilité de se désinscrire à tout moment via le
                        lien situé au bas de chacun des messages reçus.
                        <br />
                        <br />
                        Dans certains cas, la société MyFinPlace est
                        susceptible de communiquer les Données Personnelles
                        de l’Utilisateur à toute autorité administrative ou
                        judiciaire qui lui en ferait la demande,
                        conformément à la législation en vigueur.
                    </p>
                </div>
            </div>
            {/* </div> */}
            <div className="py-8">
                <div className="py-3">
                    <h5 className=" text-black-500">
                        6 - DUREE DE CONSERVATION DES DONNEES PERSONNELLES
                    </h5>
                </div>
                <div className="py-3">
                    <p className="letter-space-3 text-gray-600 bigscreen:text-[20px]">
                        La société MyFinPlace ne conservera les Données
                        Personnelles de l’Utilisateur que pour une durée
                        limitée, déterminée en fonction de la nature des
                        données en cause, et du traitement concerné. Sur
                        cette base, les Données Personnelles de
                        l’Utilisateur seront conservées pour les durées
                        suivantes :
                        <br />
                        – Données d’identification : trente six (36) mois à
                        compter de la dernière connexion ;
                        <br />
                        – Données relatives aux commandes : trente six (36)
                        mois à compter de la date de la commande ;
                        <br />
                        – Données bancaires : treize (13) mois au maximum à
                        compter de leur fourniture ;
                        <br />
                        – Données prospects : douze (12) mois à compter de
                        la dernière activité sur le Site.
                        <br />
                        Une fois ces durées atteintes, certaines des Données
                        Personnelles en cause peuvent faire l’objet d’une
                        procédure d’archivage à des fins de gestion de la
                        comptabilité et de gestion des litiges ou
                        réquisitions, pour une durée ne pouvant excéder les
                        prescriptions légales dans ces matières.
                    </p>
                </div>
            </div>
            {/* </div> */}
            <div className="py-8">
                <div className="py-3">
                    <h5 className=" text-black-500">
                        7 - DROITS DE L’UTILISATEUR
                    </h5>
                </div>
                <div className="py-3">
                    <p className="letter-space-3 text-gray-600 bigscreen:text-[20px]">
                        Conformément à la Loi « Informatique et Libertés »
                        modifiée et au RGPD, l’Utilisateur bénéficie d’un
                        droit d’accès, de rectification, d’effacement ou de
                        suppression de tout ou partie des Données
                        Personnelles le concernant, d’un droit d’opposition
                        et d’un droit à la limitation du traitement et à la
                        portabilité des Données Personnelles, qu’il peut
                        exercer par courrier envoyé à la société MyFinPlace
                        – 55 Avenue Marceau – 75116 Paris, ou par mail à
                        l’adresse support@myfinplace.com.
                        <br />
                        <br />
                        Pour les traitements fondés sur le recueil du
                        consentement de l’Utilisateur, celui-ci bénéficie en
                        outre du droit de retirer son consentement à tout
                        moment.
                        <br />
                        <br />
                        L’Utilisateur est averti que l’exercice de ces
                        droits ne porte pas atteinte à la validité des
                        opérations de traitement réalisées préalablement, ni
                        au droit de la société MyFinPlace de procéder à
                        l’archivage de certaines des Données Personnelles en
                        cause, conformément aux principes énoncés ci-avant.
                        <br />
                        <br />
                        De telles demandes devront être présentées par
                        l’Utilisateur personnellement et par écrit, signées
                        et accompagnées de la photocopie d’une pièce
                        d’identité portant la signature de son titulaire.
                        Ces informations ne seront traitées qu’à des fins
                        d’identification de l’Utilisateur et ne seront pas
                        conservées pour une durée excédant celle nécessaire
                        au traitement de la réponse. La demande précisera
                        l’adresse mail ou l’adresse postale à laquelle devra
                        parvenir l’éventuelle réponse. La société MyFinPlace
                        disposera d’un délai d’un (1) mois pour répondre
                        suivant réception de la demande.
                        <br />
                        <br />
                        L’Utilisateur dispose par ailleurs du droit de
                        formuler une réclamation auprès des autorités, et en
                        particulier auprès de la CNIL.
                    </p>
                </div>
            </div>
            {/* </div> */}
            <div className="py-8">
                <div className="py-3">
                    <h5 className=" text-black-500">
                        8 - UTILISATION DE COOKIES
                    </h5>
                </div>
                <div className="py-3">
                    <p className="letter-space-3 text-gray-600 bigscreen:text-[20px]">
                        Un cookie est un fichier envoyé par un site Internet
                        lorsqu’il est visité, et conservé dans les
                        navigateurs Internet.
                        <br />
                        <br />
                        Généralement, les cookies sont utilisés afin de
                        mesurer et d’évaluer le comportement des
                        Utilisateurs qui se rendent sur un site Internet
                        (nombre de visiteurs, durée des visites, éléments
                        les plus consultés, etc.). Il existe plusieurs types
                        de cookies, parmi lesquels certains sont non
                        identifiants, et d’autres qui sont identifiants.
                        Certains cookies peuvent être associés à d’autres
                        données dans le but de personnaliser l’expérience de
                        l’Utilisateur sur un site Internet. Lorsque les
                        cookies sont identifiants, ils permettent la
                        collecte et le traitement de Données Personnelles.
                        <br />
                        <br />
                        La société MyFinPlace utilise des cookies lorsque
                        l’Utilisateur navigue sur le Site. Ces cookies sont
                        utilisés à des fins de mesure de fréquentation du
                        Site, afin de fournir à la société MyFinPlace des
                        informations sur la façon dont l’Utilisateur circule
                        sur le Site, et afin d’améliorer l’expérience de
                        l’Utilisateur sur le Site (par exemple, pour
                        conserver le contenu du panier d’achat de
                        l’Utilisateur d’une visite à l’autre).
                        <br />
                        <br />
                        Les cookies utilisés par la société MyFinPlace sont
                        stockés pendant un délai maximal de treize (13) mois
                        à compter de leur premier dépôt sur l’ordinateur de
                        l’Utilisateur.
                        <br />
                        <br />
                        Il est également possible que des tiers utilisent
                        des cookies afin de recueillir des données liées à
                        la navigation de l’Utilisateur. La société
                        MyFinPlace n’est en aucun cas responsable des
                        opérations liées à l’utilisation des cookies de
                        tiers.
                        <br />
                        <br />
                        L’Utilisateur a la possibilité de paramétrer le
                        fonctionnement des cookies installés sur son
                        ordinateur en configurant les paramètres de son
                        navigateur. Des informations supplémentaires sur la
                        façon dont l’Utilisateur peut configurer son
                        navigateur et limiter l’action des cookies sont
                        accessibles sur le site Internet de la CNIL
                        (www.cnil.fr).
                        <br />
                        <br />
                        Si l’Utilisateur choisit de désactiver ou de bloquer
                        certains cookies, les fonctionnalités qui y sont
                        associées (par exemple, la personnalisation de
                        l’expérience de navigation sur le Site) ne pourront
                        pas lui être appliquées.
                    </p>
                </div>
            </div>
            {/* </div> */}
            <div className="py-8">
                <div className="py-3">
                    <h5 className="text-black-500">
                        9 - MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITE
                    </h5>
                </div>
                <div className="py-3">
                    <p className="letter-space-3 text-gray-600 bigscreen:text-[20px]">
                        La présente Politique de confidentialité a été mise
                        à jour le 17 octobre 2022.
                        <br />
                        <br />
                        Elle peut être modifiée par la société MyFinPlace à
                        tout moment.
                        <br />
                        <br />
                        En cas de modification de la Politique de
                        confidentialité, la nouvelle version en vigueur sera
                        applicable à l’Utilisateur à compter de sa connexion
                        au Site.
                        <br />
                        <br />
                        En cas de litige entre l’Utilisateur et la société
                        MyFinPlace, la Politique de confidentialité à
                        prendre en compte sera celle en vigueur au moment
                        des faits à l’origine du litige.
                    </p>
                </div>
            </div>
            {/* </div> */}
            <p className="py-8 bigscreen:text-[20px]">Dernière mise à jour : 17 octobre 2022</p>
        </section>
    );
}

export default Politique;
