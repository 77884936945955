import React from "react";


function Waiting() {
 

    return (
        <div className='my-2 bg-[#FDF5E1] rounded-full' >
            <p className='p-2 font-bold text-[#FFA500]  text-xs whitespace-nowrap'>En cours d’analyse</p>
        </div>
    );
}

export default Waiting;
