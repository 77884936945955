import { useEffect, useState } from "react";
// import MacBook from "assets/svg/MacBook";
import MacBook from "assets/img/MacBook.png";



export default function Easy() {

    const [Case, setCase] = useState(0);

    useEffect(() => {
        setTimeout(function () {
            if (Case < 2)
                setCase(Case + 1)
            else
                setCase(0)
        }, 3000);
    }, [Case])


    return (
        <section className="containerxl bg-greylight py-20">
            <div className="w-[50%] mx-auto mb-16">
                <h2 className="text-center mb-8">Simplicité d'utilisation</h2>
                <h4 className="text-center">
                    MyFinPlace réinvente la façon dont les professionnels du
                    secteur de la finance entrent en relation. Les opportunités
                    existent, alors révélons-les&nbsp;!
                </h4>
            </div>
            <div className="flex flex-col lg:flex-row">
                <div className="flex flex-col lg:w-[30%] gap-y-12 ">
                    {/* Case 1 */}
                    <div className={"transition-opacity duration-300 hover:opacity-100 hover:duration-300" + (Case === 0 ? "" : " opacity-50 ")}>
                        <div className="flex">
                            <span className="text-middleblue text-3xl pr-6">
                                &bull;
                            </span>
                            <div id="Line1" className={"absolute bg-middleblue w-[1px] ml-[3px] mt-[30px] rounded-full " + (Case === 0 ? "active" : "")}></div>
                            <h5 className="pt-[3px] bigscreen:text-[30px]">Inscrivez-vous</h5>
                        </div>
                        <p className="pl-8 bigscreen:text-[20px]">
                            Lors de votre visite sur MyFinPlace, nous vous
                            invitons à vous y inscrire. C'est à la fois gratuit,
                            rapide et confidentiel.
                        </p>
                    </div>
                    {/* Case 2 */}
                    <div className={"transition-opacity duration-300 hover:opacity-100 hover:duration-300" + (Case === 1 ? "" : " opacity-50 ")}>
                        <div className="flex">
                            <span className="text-middleblue text-3xl pr-6">
                                &bull;
                            </span>
                            <div id="Line2" className={"absolute bg-middleblue w-[1px] ml-[3px] mt-[30px] rounded-full " + (Case === 1 ? "active" : "")}></div>
                            <h5 className="pt-[3px] bigscreen:text-[30px]">Complétez votre profil</h5>
                        </div>
                        <p className="text-justify pl-8 bigscreen:text-[20px]" >
                            Votre inscription étant effective, nous vous recommandons
                            de compléter votre profil (&nbsp;candidat/consultant&nbsp;) ou
                            de déposez une opportunité (&nbsp;d'emploi/de mission&nbsp;). La
                            plateforme vous permet de trouver ce que vous
                            recherchez.
                        </p>
                    </div>
                    {/* Case 3 */}
                    <div className={"transition-opacity duration-300 hover:opacity-100 hover:duration-300" + (Case === 2 ? "" : " opacity-50 ")}>
                        <div className="flex">
                            <span className="text-middleblue text-3xl pr-6">
                                &bull;
                            </span>
                            <div id="Line3" className={"absolute bg-middleblue w-[1px] ml-[3px] mt-[30px] rounded-full " + (Case === 2 ? "active" : "")}></div>

                            <h5 className="pt-[3px] bigscreen:text-[30px]">MyFinPlace vous met en relation</h5>
                        </div>
                        <p className="text-justify pl-8 bigscreen:text-[20px]">
                            Vous êtes maintenant visible (&nbsp;en toute
                            confidentialité&nbsp;) ou vous avez déposé
                            votre opportunités (&nbsp;d'emploi/de mission&nbsp;). La plateforme va vous
                            présenter les meilleures opportunités d’emploi/de mission
                            ou vos candidats/consultants les plus adéquats.
                        </p>
                    </div>
                </div>
                <div className="hidden lg:flex w-[70%] mx-auto lg:items-center lg:justify-end">
                    <img src={MacBook} alt="MacBook" />
                </div>
            </div>
        </section>
    );
}
