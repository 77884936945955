import React, { useState, useEffect, useContext } from "react";
import FiltreBase from "./Components/FiltreBase";
import FiltreCompetences from "./Components/FiltreCompetences";
import FiltreModalites from "./Components/FiltreModalites";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { CheckboxContext } from "../LesOpportunite/Context/CheckboxProvider";


// import { FiltreProfilContext } from "./Context/FiltresProvider"
import Undo from "assets/svg/FiltreSvg";

export default function Filtre(props) {
    // const { valueFiltreProfil } = useContext(FiltreProfilContext);
    const [field, setField] = useState({})
    const [status, setStatus] = useState(false)
    const [data, setData] = useState({})
    const [mergeData, setMergeData] = useState([])
    const [fixed, setFixed] = useState(false);
    const [limit, setLimit] = useState({});
    const [filterBase, setFilterBase] = useState();


    const { checkBoxValues, setCheckboxes } = useContext(CheckboxContext);
    const [value, setValue] = useState(props.searchTermQuery);


    function handleChange(e) {
        setValue(e.target.value)
    }

    useEffect(() => {
        setFilterBase(props.base)
    }, [props.base]);

    const isMobile = true

    useEffect(() => {
        fetch(process.env.REACT_APP_API + "filtres")
            .then((response) => response.json())
            .then((data) => setField(data));
    }, []);


    // fonction pour vider les filtre
    function handleReset() {
        setData([])
        setStatus(!status)
        props.onEmpty()
        setMergeData({})
    }


    useEffect(() => {
        // on utilise timeOut pour éviter de faire trop d'appel à l'api à cause du onChange
        const timeoutId = setTimeout(() => {
            setCheckboxes(prevState => ({
                ...prevState,
                champLibre: value
            }));
        }, 200);
        // return () => {
        //   clearTimeout(timeoutId);
        // };
    }, [value]);


    function handleChange(e) {
        setValue(e.target.value)
    }


    const resetHandler = () => {
        props.resetFilters();
        setValue("");
    }

    // fonction qui recupere la donner pour chaque catégorie suivie par celui qui le merge
    function handleField(e) {
        switch (e.type) {
            case "base":
                setMergeData({ ...mergeData, base: e.data })
                break;
            case "competence":
                setMergeData({ ...mergeData, competence: e.data })
                break;
            case "modalite":
                setMergeData({ ...mergeData, modalite: e.data })
                break;
        }

    }
    useEffect(() => {
        const concat = [...(mergeData?.base || []), ...(mergeData?.competence || []), ...(mergeData?.modalite || [])];
        setData(concat)
    }, [mergeData]);


    //  envoie de la data au parent qui va le filter
    useEffect(() => {
        props.onFilter(data)
    }, [data]);

    return (
        <main className={`transition-transform duration-300 w-full md:h-[80vh] md:overflow-auto p-4 rounded-xl smaller-scroll-bar`} >
            <section className="pb-2.5 border-b">
                <div className="flex justify-between items-center mb-2">
                    <h4 className="font-semibold">Filtres </h4>
                    <div onClick={resetHandler}>
                        <Undo classProps={"cursor-pointer"} />
                    </div>

                </div>
                <div className="border flex items-center p-2">
                    <input value={value} className="w-full outline-none" type="text" name="" id="" onChange={handleChange} />
                    <FaMagnifyingGlass />
                </div>
            </section>
            <section >
                <FiltreBase onField={handleField} field={field} empty={status} base={props.base} isComptable={props.isComptable} />
                <FiltreCompetences onField={handleField} field={field} empty={status} competence={props.competence} />
                <FiltreModalites onField={handleField} field={field} empty={status} />
            </section>
        </main>
    );
}