import React, { useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import ContextMenu from "Components/ContextMenu/ContextMenu";
import ContextItem from "Components/ContextMenu/ContextItem";
import PopupSupprimer from "./PopupSupprimer";
import Accept from "./StatusCase/Accept";
import Postuler from "./StatusCase/Postuler";
import Waiting from "./StatusCase/Waiting";
import Refuser from "./StatusCase/Refuser";
import Rdv from "./StatusCase/Rdv";
import { MdCancel } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { MdGroups } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaArchive } from "react-icons/fa";


export default function CardCandidature({ el, key, getCandidatures }) {
  const [popupSupprimer, setPopupSupprimer] = useState(false);
  const token = cookie.get("token");
  const navigate = useNavigate()

  const archiverCandidature = (id) => {
    axios
      .patch(process.env.REACT_APP_API + `user/candidature/archiver/${id}`,
        { "archiver": true },
        {
          headers: {
            "Content-Type": "application/merge-patch+json",
            Authorization: `Bearer ${token}`,
          },
        })
      .then((response) => {
        getCandidatures()
        navigate('/mon-espace/mes-candidatures')

      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <tr key={key} className={el.archiver ? `bg-greylight` : ""}>
      <td className="p-2 mr-2"><a target="_blank" className="font-medium max-lg:whitespace-nowrap hover:text-[#1954B7]" href={`/offre/${el.id_offre.id}`}>{el.id_offre.intitule}</a></td>
      <td className=" mr-2">
        {el.id_offre.tags
          .filter((tag) => tag.tag.id_tag_level.level === "Compétences sectorielles")
          .slice(0, 1)
          .map((tag) => (
            <span className="text-xs rounded-md bg-[#F7F9FC] px-2 py-1 text-xs font-medium text-[#1954B7] ring-1 ring-inset ring-blue-700/10 whitespace-nowrap" key={tag.id}>{tag.tag.name}</span>
          ))}
      </td>
      <td className="mr-2"><span className="rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{el.id_offre.localisation}</span></td>
      <td className="p-2 mr-2">
        <span className="rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
          {new Date(el.date_time_creation).toLocaleDateString("fr-FR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </span>
      </td>
      <td className="mr-2">
        <div className="flex">
          {
            el.status == "postuler" ? <Postuler /> : null
          }
          {
            el.status == "encours" ? <Waiting /> : null
          }
          {
            el.status == "accepter" ? <Accept /> : null
          }
          {
            el.status == "refuser" ? <Refuser /> : null
          }
          {
            el.status == "entretien" ? <Rdv /> : null
          }
        </div>
      </td>
      <td className="">
        <div className="flex p-2 gap-2">
          <div className="cursor-pointer bg-[#F7F9FC]  hover:bg-[#DCEDF8] border p-1 rounded" onClick={() => window.open(`/offre/${el.id_offre.id}`, '_blank', '')}>
            <FaEye />
          </div>
          {
            (el.status == "accepter" || el.status == "refuser") && el.archiver == false ?
              <div className="cursor-pointer bg-[#F7F9FC]  hover:bg-[#DCEDF8] border p-1 rounded" onClick={() => archiverCandidature(el.id)}>
                <FaArchive />
              </div>
              :
              null
          }
          {
            (el.status == "encours" || el.status == "entretien" || el.status == "postuler") && el.archiver == false ?
              <div className=" text-red-500 cursor-pointer bg-[#F7F9FC]  hover:bg-[#DCEDF8] border p-1 rounded" onClick={() => setPopupSupprimer(true)}>
                <MdCancel />
              </div>
              :
              null
          }
          {popupSupprimer && <PopupSupprimer getCandidatures={getCandidatures} postuler={el} setPopupSupprimer={setPopupSupprimer} />}
        </div>
      </td>
    </tr>
  )
}