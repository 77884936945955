import { useState, useEffect } from 'react';
import DateInput from 'Components/Forms/DateInput/DateInput'
import Input from 'Components/Forms/Input/Input'
import Wysiwig from 'Components/wysiwig/wysiwig'
import CalendarSvg from "assets/svg/CalendarComponent";
import Checkbox from 'Components/Forms/CheckBox/Checkbox';

function CreateOrEditExperience({ experience, setExperience, err, maxCaracteres }) {

    const [text, setText] = useState("")
    const [startDate, setStartDate] = useState(experience.date_start)
    const [endDate, setEndDate] = useState(experience.date_end)

    const regexDate = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/


    useEffect(() => {
        setExperience((state) => ({ ...state, description: text }))
    }, [text])


    useEffect(() => {
        setText(experience.description)
    }, [])

    const handleStartDateChange = (e) => {
        let newData = { ...experience }
        e = new Date(e.target.value)
        newData["dateStart"] = toIsoString(e)
        newData["date_start"] = dateFormatter(e)
        setStartDate(dateFormatter(e))
        setExperience(newData)
    }


    const handleEndDateChange = (e) => {
        let newData = { ...experience }
        e = new Date(e.target.value)
        newData["dateEnd"] = toIsoString(e)
        newData["date_end"] = dateFormatter(e)
        setEndDate(dateFormatter(e))
        setExperience(newData)

    }

    const handleFormation = (e) => {
        let newData = { ...experience }
        if (e.target.name == "inProgress") {
            newData[e.target.name] = e.target.checked
            newData["dateEnd"] = ""
            newData["date_end"] = ""

        } else {
            newData[e.target.name] = e.target.value
        }
        setExperience(newData)
    }

    const handleInputStartDate = (e) => {
        let value = e.target.value
        setStartDate(value)
        if (regexDate.test(value)) {
            let test = value.replace(/-/g, '/')
            let parts = value.split("/");
            let day = parts[0];
            let month = parts[1];
            let year = parts[2];
            let date = new Date(`${month}-${day}-${year}`)
            handleStartDateChange(date)
        }
    }

    const handleInputEndDate = (e) => {
        let value = e.target.value
        setEndDate(value)
        if (regexDate.test(value)) {
            let test = value.replace(/-/g, '/')
            let parts = value.split("/");
            let day = parts[0];
            let month = parts[1];
            let year = parts[2];
            let date = new Date(`${month}-${day}-${year}`)
            handleEndDateChange(date)
        }
    }

    const dateFormatter = (date) => {
        const dateOrigine = new Date(date);

        const jour = dateOrigine.getDate();
        const mois = dateOrigine.getMonth() + 1;
        const annee = dateOrigine.getFullYear();

        const dateFormatee = `${jour.toString().padStart(2, '0')}/${mois.toString().padStart(2, '0')}/${annee}`;

        return dateFormatee;
    }

    function toIsoString(date) {
        var tzo = -date.getTimezoneOffset(),
            dif = tzo >= 0 ? '+' : '-',
            pad = function (num) {
                return (num < 10 ? '0' : '') + num;
            };

        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            dif + pad(Math.floor(Math.abs(tzo) / 60)) +
            ':' + pad(Math.abs(tzo) % 60);
    }

    return (
        <div className='bg-white p-4 rounded'>
            <div className='my-4 grid grid-cols-1 lg:grid-cols-2 gap-8'>
                <div>
                    <div className='mb-4'>
                        <label htmlFor="">Poste *</label>
                        <div className='max-w-[300px]'>
                            <Input name="poste" value={experience.poste} onChange={handleFormation} />
                            {
                                err.posteErr && <p className='mt-1 p-2 bg-red-50 text-red-700'>{err.posteErr}</p>
                            }
                        </div>
                    </div>
                    <div className='mb-4'>
                        <label htmlFor="">Entreprise *</label>
                        <div className='max-w-[300px]'>
                            <Input name="name" value={experience.name} onChange={handleFormation} />
                            {
                                err.nameErr && <p className='mt-1 p-2 bg-red-50 text-red-700'>{err.nameErr}</p>
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <div className='mb-4'>
                        <label htmlFor="">Date de début *</label>
                        <div className='max-w-[300px]'>
                            <input onChange={handleStartDateChange} className='focus:outline-none py-[10px] px-[10px]  border-[1.5px] border-lightblue rounded-[3px] w-full' type="date" />
                            {/* <DateInput name="dateStart" value={experience.date_start} setDate={handleInputStartDate} dateString={startDate} date={experience.dateStart} onChange={handleStartDateChange} /> */}
                            {
                                err.dateStartErr && <p className='mt-1 p-2 bg-red-50 text-red-700'>{err.dateStartErr}</p>
                            }
                        </div>
                    </div>
                    {
                        !experience.inProgress ?
                            <div className='mb-4'>
                                <label htmlFor="">Date de fin *</label>
                                <div className='max-w-[300px]'>
                                    <input onChange={handleEndDateChange} className='focus:outline-none py-[10px] px-[10px]  border-[1.5px] border-lightblue rounded-[3px] w-full' type="date" />
                                    {/* <DateInput name="dateEnd" value={experience.date_end} date={experience.dateEnd} setDate={handleInputEndDate} dateString={endDate} onChange={handleEndDateChange} /> */}
                                    {
                                        err.dateEndErr && <p className='mt-1 p-2 bg-red-50 text-red-700'>{err.dateEndErr}</p>
                                    }
                                    {
                                        err.dateErr && <p className='mt-1 p-2 bg-red-50 text-red-700'>{err.dateErr}</p>
                                    }
                                </div>
                            </div>
                            :
                            null
                    }
                    <div className='mb-4'>
                        <input className='cursor-pointer' type="checkbox" onClick={handleFormation} name='inProgress' id='inProgress' />
                        <label htmlFor='inProgress' className='ml-2 cursor-pointer font-bold'>En cours</label>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center mb-[20px]">
                <p className="">Présentation *</p>
                <div className="min-w-[250px] overflow-auto">
                    <Wysiwig text={text} setText={setText} />
                    {maxCaracteres &&
                        <p className={`text-right ${text.length > maxCaracteres && "text-red-500"}`}>
                            {text ? text.length : 0} / {maxCaracteres}
                        </p>
                    }
                    {
                        err.descriptionErr && <p className='mt-1 p-2 bg-red-50 text-red-700'>{err.descriptionErr}</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default CreateOrEditExperience