import { createSlice } from "@reduxjs/toolkit";

export const socketSlice = createSlice({
    name:"socket",
    initialState:{ert:['cedric']},
    reducers: {
        test:(state) => {
            console.log(state)
        }
    }
})



export const { test } = socketSlice.actions

export default socketSlice.reducer
