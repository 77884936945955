import { useState } from "react";
import CNCC from "assets/img/CNCC.png";
import Ordre_des_comptables from "assets/img/Ordre_des_comptables.png";
import Card1 from "./Card1";
import Card2 from "./Card2";
import Card3 from "./Card3";
import Card4 from "./Card4";
import { Helmet } from "react-helmet";  

export default function Cab() {
  const [isActive, setActive] = useState({ "Question1": true });

  return (
    <div className="containerxl">
      <Helmet>  
<title>MyFinPlace - Le CAB </title>  
</Helmet>  
      <h1 className="text-center font-medium text-[64px] pt-16 mb-16">Le Cab</h1>
      <div className="flex flex-col md:flex-row justify-center gap-16">
        <img className="h-[110px]" src={Ordre_des_comptables} alt="" srcSet="" />
        <img className="h-[110px]" src={CNCC} alt="" srcSet="" />
      </div>
      <span className="inline-block mt-16" />
      <Card1 isActive={isActive} setActive={setActive} />
      <Card2 isActive={isActive} setActive={setActive} />
      <Card3 isActive={isActive} setActive={setActive} />
      <Card4 isActive={isActive} setActive={setActive} />
      <span className="inline-block mt-16" />
    </div>
  )
}