import { Menu } from 'react-admin';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import GavelIcon from '@mui/icons-material/Gavel';
import HomeIcon from '@mui/icons-material/Home';

export const MyMenu = () => (
  <Menu>
    <Menu.Item to="/" primaryText="Accueil MyFinPlace" leftIcon={<HomeIcon />} className='!border-none' />
    <Menu.Item to="/Iris/Home" primaryText="Tableau de bord" leftIcon={<DashboardIcon />} />
    <Menu.Item to="/Iris/Kpi" primaryText="KPIs" leftIcon={<DataThresholdingIcon />} />
    <Menu.Item to="/Iris/users" name="users" primaryText="Profils" leftIcon={<MenuIcon />} />
    <Menu.Item to="/Iris/clients" name="clients" primaryText="Clients" leftIcon={<MenuIcon />} />
    <Menu.Item to="/Iris/offres" name="offres" primaryText="Opportunités" leftIcon={<MenuIcon />} />
    <Menu.Item to="/Iris/tags" name="tags" primaryText="Tags" leftIcon={<MenuIcon />} />
    <Menu.Item to="/Iris/appointments" primaryText="Tous les entretiens" leftIcon={<MenuIcon />} />
    <Menu.Item to="/Iris/RDV à planifier" primaryText="Entretiens à planifier" leftIcon={<NewspaperIcon />} />
    <Menu.Item to="/Iris/RDV programmés" primaryText="Entretiens programmés" leftIcon={<NewspaperIcon />} />
    <Menu.Item to="/Iris/RDV non réalisés" primaryText="Entretiens non réalisés" leftIcon={<NewspaperIcon />} />
    <Menu.Item to="/Iris/RDV réalisés" primaryText="Entretiens réalisés" leftIcon={<NewspaperIcon />} />
    <Menu.Item to="/Iris/RDV refusés" primaryText="Profils refusé" leftIcon={<NewspaperIcon />} />
    <Menu.Item to="/Iris/RDV archivés" primaryText="Profils retenu" leftIcon={<NewspaperIcon />} />
    <Menu.Item to="/Iris/Mission gagnées" primaryText="Missions gagnées" leftIcon={<GavelIcon />} />
    <Menu.Item to="/Iris/Mission en cours" primaryText="Missions en cours" leftIcon={<GavelIcon />} />
    <Menu.Item to="/Iris/Mission cloturées" primaryText="Missions clôturées" leftIcon={<GavelIcon />} />
  </Menu>
);